import React, { useRef, useState } from "react";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import styles from "../SyncedDataTab.module.css";
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  Checkbox,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useAuthUser } from "react-auth-kit";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetContactList } from "../../../service/inbound.request";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useSelector } from "react-redux";
import { resetDeleteContactSuccess } from "../../../service/inboundSlice";
import { PuffLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import CourseMapModel from "../CourseMapModel";
import deleteIcon from "../../../../../../assets/images/delete.svg";
import ListPopover from "../../../../../../components/ListPopover/ListPopover";
import RemoveContactsModal from "../Remove/RemoveContactsModal";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../../../../../components/CommonFunctions/CommonFunctions";
import AddLeadPayment from "../../../../Nurture/components/AddLeadPayment";
import { resetAddEnquirySuccess } from "../../../service/inboundImportSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ZapierTab(props: any) {
  const {
    contactList,
    contactListLoading,
    GetContactLists,
    DeleteContactList,
    deleteContactLoading,
    deleteContactSuccess,
    addedEnquiryContactLoading,
    AddEnquiryForContact,
    page,
    AddEnquiryForZapier,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const branchList = useSelector((state: any) => state.branch.branchList);

  const { userById } = useSelector((state: any) => state.userDetails);

  const hasZapierAccess = getSubDomainAccessStatus(
    "INBOUND",
    "ZAPIER",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasContactsAccess = getSubDomainAccessStatus(
    "INBOUND",
    "CONTACTS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );
  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#667085",
      padding: hasZapierAccess === true ? "10px" : "19px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: hasZapierAccess === true ? "10px" : "19px",
    },
  }));

  const importedSuccess = useSelector(
    (state: any) => state.importData.saveImportedDataSuccess
  );
  const teamMembers = useSelector((state: any) => state.staff.staffList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter(
    (item: any) =>
      item?.batchConductType === "REGULAR_BATCH" && item?.status === "ACTIVE"
  );
  const leadBoard = useSelector((state: any) => state.nurture.leadBoard);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterArray, setFilterArray] = useState<any>();
  const importedContacts = filterArray?.returnData?.filter(
    (item: any) =>
      item?.enquirySource === "ZAPIER" || item?.enquirySource === "Zapier"
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const [openAddEnquiryModal, setOpenAddEnquiryModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState<any>();

  const [selectedCourseForMoving, setSelectedCourseForMoving] = useState<any>(
    []
  );
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [seletedAssignee, setSelectedAssignee] = useState<any>();
  const [selectedBatch, setSelectedBatch] = useState<any>();

  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );

  const combinedAssignees = getCombinedAssignees(
    teamMembers,
    selectedBranch?.id
  );
  const [openDifferentCourseMappingPopup, setOpenDifferentCourseMappingPopup] =
    useState(false);

  const [courseAnchorEl, setCourseAnchorEl] = useState(null);
  const openCourse = Boolean(courseAnchorEl);
  const [searchCourse, setSearchCourse] = useState<string>("");

  //branch
  const [branchAnchorEl, setBranchAnchorEl] = useState(null);
  const openBranch = Boolean(branchAnchorEl);
  const [searchBranch, setSearchBranch] = useState<string>("");

  //batch
  const [batchAnchorEl, setBatchAnchorEl] = useState(null);
  const openBatch = Boolean(batchAnchorEl);
  const [searchBatch, setSearchBatch] = useState<string>("");

  //assignee
  const [assigneeAnchorEl, setAssigneeAnchorEl] = useState<any>(null);
  const openAssignee = Boolean(assigneeAnchorEl);
  const [searchAssignee, setSearchAssignee] = useState<string>("");

  const [showAddLeadWideView, setAddLeadWideView] = useState(false);
  const {
    leadCreatedIdForZapier,
    addedEnquiryZapierSuccess,
    addedEnquiryContactSuccess,
  } = useSelector((state: any) => state.import);
  const leadLoading = useSelector(
    (state: any) => state.import.addedEnquiryZapierLoading
  );

  React.useEffect(() => {
    if (
      addedEnquiryZapierSuccess !== true &&
      addedEnquiryZapierSuccess !== false
    ) {
      if (
        leadCreatedIdForZapier === null ||
        leadCreatedIdForZapier === undefined
      ) {
        setOpenAddEnquiryModal(false);
      }
    }
  }, [addedEnquiryZapierSuccess, setOpenAddEnquiryModal]);

  React.useEffect(() => {
    if (
      leadCreatedIdForZapier &&
      addedEnquiryZapierSuccess === "Converted lead is created."
    ) {
      setOpenAddEnquiryModal(true);
      setAddLeadWideView(true);
      dispatch(
        setToastNotification({
          message: String("Please add the payment details for conversion"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  }, [addedEnquiryZapierSuccess, setAddLeadWideView, leadCreatedIdForZapier]);

  React.useEffect(() => {
    if (
      addedEnquiryZapierSuccess === "Contacts moved!" ||
      addedEnquiryContactSuccess === "Contacts moved!"
    ) {
      const userFromLocalStorage = authUser?.institute[0];
      const lastEvaluatedKey = "";
      const limit = 15;
      const lastEvaluatedSortKey = "";
      const enquirySource = "Zapier";
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
      dispatch(resetAddEnquirySuccess());
    }
  }, [addedEnquiryZapierSuccess, addedEnquiryContactSuccess]);

  const handleOpenCoursePopover = (event: any) => {
    setCourseAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseCoursePopover = () => {
    setCourseAnchorEl(null);
    setSearchCourse("");
  };

  const handleOpenBranchPopover = (event: any) => {
    setBranchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBranchPopover = () => {
    setBranchAnchorEl(null);
    setSearchBranch("");
  };
  const courseFieldRef = useRef<HTMLInputElement | null>(null);
  const branchFieldRef = useRef<HTMLInputElement | null>(null);
  const batchFieldRef = useRef<HTMLInputElement | null>(null);
  const assigneeFieldRef = useRef<HTMLInputElement | null>(null);

  const handleOpenBatchPopover = (event: any) => {
    setBatchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBatchPopover = () => {
    setBatchAnchorEl(null);
    setSearchBatch("");
  };

  const handleOpenAssigneePopover = (event: any) => {
    setAssigneeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseAssigneePopover = () => {
    setAssigneeAnchorEl(null);
    setSearchAssignee("");
  };

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const handleBatchSearch = (event: any) => {
    setSearchBatch(event.target.value);
  };
  const handleAssigneeSearch = (event: any) => {
    setSearchAssignee(event.target.value);
  };

  const handleCourseSelect = (emp: any) => {
    setSelectedCourse(emp);
    handleCloseCoursePopover();
    if (selectedBatch && selectedBatch?.courseId !== emp.id) {
      setSelectedBatch(null);
    }
    if (selectedBranch && selectedBranch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBranchSelect = (emp: any) => {
    setSelectedBranch(emp);
    setSelectedAssignee(null);
    handleCloseBranchPopover();
    if (selectedBatch && selectedBatch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBatchSelect = (emp: any) => {
    setSelectedBatch(emp);
    handleCloseBatchPopover();
  };

  const handleAssigneeSelect = (emp: any) => {
    setSelectedAssignee(emp);
    handleCloseAssigneePopover();
  };

  const sortedSwimlane =
    leadBoard &&
    [...leadBoard?.swimlaneData].sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    if (event.target.value.trim() === "") {
      queryParams.delete("zapierSearchTerm");
    } else {
      queryParams.set("zapierSearchTerm", event.target.value);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleCheckboxToggle = (contactId: string) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        const updatedContacts = prevSelectedContacts.filter(
          (id) => id !== contactId
        );
        setIsCheckboxChecked(updatedContacts.length > 0);
        return updatedContacts;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const handleCourseToggle = (row: any) => {
    setSelectedCourseForMoving((prevSelectedCourse: any) => {
      if (prevSelectedCourse.includes(row)) {
        const updatedCourses = prevSelectedCourse?.filter(
          (ds: any) => ds?.id !== row?.id
        );
        setIsCheckboxChecked(updatedCourses.length > 0);
        return updatedCourses;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedCourse, row];
      }
    });
  };

  const deleteContacts = () => {
    const userFromLocalStorage = authUser?.institute[0];
    if (selectedContacts?.length <= 25) {
      const data = {
        instituteId:
          userFromLocalStorage?.instituteId &&
          userFromLocalStorage?.instituteId,
        studentContactEntryIds: selectedContacts,
        enquirySource: "ZAPIER",
      };
      DeleteContactList(data);
    } else {
      dispatch(
        setToastNotification({
          message: String("You cannot Delete more than 25 contacts"),
          type: "error",
          snackOpen: true,
        })
      );
    }
  };

  const handleSelectedRow = (val: any) => {
    setSelectedRowDetails(val);
    setOpenAddEnquiryModal(true);
  };

  const handleMoveCourse = () => {
    const studentData = selectedCourseForMoving?.map((item: any) => {
      return {
        studentFirstName: item?.firstName?.replace(/[^a-zA-Z]/g, ""),
        studentEmail: item?.email,
        studentLastName: item?.lastName?.replace(/[^a-zA-Z]/g, ""),
        studentContact:
          item?.contactNumber?.toString()?.length === 10
            ? `91${item?.contactNumber}`
            : `${item?.contactNumber}`,
        contactCountryCode: "91",
        studentContactId: item?.id,
        otherDetails: [],
        assignedToId: seletedAssignee?.id,
        assignedToName: `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`,
      };
    });
    const userFromLocalStorage = authUser?.institute[0];
    const payloadForContactsAddEnquiry = {
      studentList: studentData,
      deleteContact: true,
      createdByName: `${authUser?.firstName} ${authUser?.lastName}`,
      status: "CREATED",
      createdById: authUser?.institute?.[0]?.id,
      messageId: "",
      courseId: selectedCourse?.id,
      courseName: selectedCourse?.courseName,
      instituteId: userFromLocalStorage?.instituteId,
      instituteName: userFromLocalStorage?.instituteName,
      assignedToId: seletedAssignee?.id,
      assignedToName: `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`,
      createdTime: 0,
      enquiryPriority: "NORMAL",
      enquirySource: "ZAPIER",
      enquiryStatus: "NEW_ENQUIRY",
      branchName: selectedBranch?.branchName,
      batchId: selectedBatch?.id,
      branchId: selectedBranch?.id,
      batchName: selectedBatch?.batchName,
    };
    AddEnquiryForContact(payloadForContactsAddEnquiry);
  };

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.GSI3SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    const enquirySource = "Zapier";

    if (lastEvaluatedKey) {
      GetContactList(
        id,
        lastEvaluatedKey,
        20,
        lastEvaluatedSortKey,
        enquirySource
      )
        .then((res) => {
          setHasMoreData(true);
          setFilterArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    setFilterArray(contactList);
  }, [contactList]);

  React.useEffect(() => {
    if (
      filterArray &&
      filterArray?.lastEvaluatedKeyReceived &&
      filterArray?.lastEvaluatedKeyReceived?.SK !== null &&
      searchTerm?.length === 0
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [filterArray, searchTerm]);

  const filteredContactList =
    contactList && filterArray?.returnData
      ? importedContacts?.filter((contact: any) => {
          const searchTermLower = searchTerm?.toLowerCase();
          const fullName = `${contact?.firstName} ${contact?.lastName}`;

          const nameMatch = fullName?.toLowerCase().includes(searchTermLower);
          const emailMatch = contact?.email
            .toLowerCase()
            .includes(searchTermLower);
          const phoneMatch =
            searchTermLower === "" ||
            (contact?.contactNumber &&
              contact?.contactNumber?.toString()?.includes(searchTermLower));

          return nameMatch || emailMatch || phoneMatch;
        })
      : [];

  const noMoveCourseButtonClick = () => {
    setOpenDifferentCourseMappingPopup(false);
  };

  const yesMoveCourseButtonClick = () => {
    handleMoveCourse();
    setOpenDifferentCourseMappingPopup(false);
  };

  const verifyChangeCourse = () => {
    const validationCOurses = selectedCourseForMoving?.map((d: any) => {
      return selectedCourse?.id === d?.courseId;
    });
    if (
      !validationCOurses?.includes(false) ||
      selectedCourseForMoving[0]?.courseId === "" ||
      selectedCourse?.id === selectedCourseForMoving[0]?.courseId
    ) {
      handleMoveCourse();
    } else {
      setOpenDifferentCourseMappingPopup(true);
    }
  };

  const filteredBatches = batchList?.filter((batch: any) => {
    const courseMatch =
      selectedCourse?.id === "" || batch.courseId === selectedCourse?.id;
    const branchMatch =
      selectedBranch?.id === "" || batch.branchId === selectedBranch?.id;
    const regularBatch =
      batch.batchConductType === "REGULAR_BATCH" &&
      batch.batchStatus !== "COMPLETED" &&
      batch.status !== "INACTIVE";

    return courseMatch && branchMatch && regularBatch;
  });

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const zapierSearchParam = urlSearchParams.get("zapierSearchTerm");

    if (zapierSearchParam) {
      setSearchTerm(zapierSearchParam);
    }
  }, [filterArray]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";
    const enquirySource = "Zapier";
    if (deleteContactSuccess) {
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
    }
  }, [deleteContactSuccess]);

  React.useEffect(() => {
    if (addedEnquiryZapierSuccess || addedEnquiryContactSuccess) {
      setIsCheckboxChecked(false);
      setSelectedBatch(null);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedAssignee(null);
      setSelectedCourseForMoving([]);
      setSelectedContacts([]);
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [addedEnquiryZapierSuccess, addedEnquiryContactSuccess]);

  React.useEffect(() => {
    if (deleteContactSuccess) {
      setOpenDeleteModal(false);
      setIsCheckboxChecked(false);
      setSelectedContacts([]);
      setIsCheckboxChecked(false);
      setSelectedCourseForMoving([]);
      setSelectedBatch(null);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedAssignee(null);
      dispatch(resetDeleteContactSuccess());
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [deleteContactSuccess]);

  React.useEffect(() => {
    if (selectedCourseForMoving?.length === 0) {
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedBatch(null);
      setSelectedAssignee(null);
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [selectedCourseForMoving]);

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "0px 20px 30px 20px",
        }}
      >
        <>
          {!isCheckboxChecked ? (
            <div
              className={styles.funnelHeadSplit}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.funnelHeadSplitTitle}>Zapier</div>
              {contactList &&
              contactList?.returnData &&
              contactList?.returnData?.length > 0 &&
              filterArray &&
              importedContacts?.length > 0 ? (
                <>
                  <div className={styles.funnelHeadSplitRight}>
                    <div className={styles.funnelHeadSplitSearch}>
                      <TextField
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{
                          style: {
                            background: "#ffffff",
                            padding: "8px 12px",
                            width: "300px",
                          },
                        }}
                        type="search"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className={styles.funnelHeadSplit}>
              <div className={styles.funnelHeadSplitRight}>
                <div style={{ color: "#667085", fontSize: "14px" }}>
                  Mapping:
                </div>
                <Box
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "4px",
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{
                      "&.MuiGrid-root": {
                        width: "calc(100% + 12px )",
                      },
                    }}
                  >
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenCoursePopover}
                          value={
                            selectedCourse ? selectedCourse?.courseName : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Course"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            textTransform: "capitalize",
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              textTransform: "capitalize",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={courseFieldRef}
                        />
                        <ListPopover
                          openState={openCourse}
                          anchorState={courseAnchorEl}
                          handleClosePopover={handleCloseCoursePopover}
                          handleListSearch={handleCourseSearch}
                          popupList={coursesList?.filter((item: any) =>
                            item?.courseName
                              ?.toLowerCase()
                              ?.includes(searchCourse?.toLowerCase())
                          )}
                          handleItemSelect={handleCourseSelect}
                          listType={"COURSE"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenBranchPopover}
                          value={
                            selectedBranch ? selectedBranch?.branchName : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Branch"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              textTransform: "capitalize",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={branchFieldRef}
                        />
                        <ListPopover
                          openState={openBranch}
                          anchorState={branchAnchorEl}
                          handleClosePopover={handleCloseBranchPopover}
                          handleListSearch={handleBranchSearch}
                          popupList={filteredBranchList?.filter((form: any) =>
                            form?.branchName
                              .toLowerCase()
                              .includes(searchBranch?.toLowerCase())
                          )}
                          handleItemSelect={handleBranchSelect}
                          listType={"BRANCH"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenBatchPopover}
                          value={selectedBatch ? selectedBatch?.batchName : ""}
                          variant="outlined"
                          size="small"
                          placeholder="Choose Batch"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          inputRef={batchFieldRef}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              textTransform: "capitalize",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                        />
                        <ListPopover
                          openState={openBatch}
                          anchorState={batchAnchorEl}
                          handleClosePopover={handleCloseBatchPopover}
                          handleListSearch={handleBatchSearch}
                          popupList={filteredBatches?.filter((item: any) =>
                            item?.batchName
                              .toLowerCase()
                              .includes(searchBatch.toLowerCase())
                          )}
                          handleItemSelect={handleBatchSelect}
                          listType={"BATCH"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenAssigneePopover}
                          value={
                            seletedAssignee
                              ? `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`
                              : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Assignee"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={assigneeFieldRef}
                        />
                        <ListPopover
                          openState={openAssignee}
                          anchorState={assigneeAnchorEl}
                          handleClosePopover={handleCloseAssigneePopover}
                          handleListSearch={handleAssigneeSearch}
                          popupList={
                            combinedAssignees?.filter((item: any) => {
                              const fullName = `${item?.firstName ?? ""} ${
                                item?.lastName ?? ""
                              }`.toLowerCase();
                              return fullName?.includes(
                                searchAssignee?.toLowerCase()
                              );
                            })
                            // ?.filter((user: any) => {
                            //   return (
                            //     user?.leadCardAccess === true &&
                            //     user?.status === "ACTIVE"
                            //   );
                            // })
                          }
                          handleItemSelect={handleAssigneeSelect}
                          listType={"ASSIGNEE"}
                        />
                      </>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={verifyChangeCourse}
                        disabled={
                          selectedCourse === undefined ||
                          selectedBranch === undefined ||
                          selectedCourse === null ||
                          selectedBranch === null ||
                          seletedAssignee === undefined ||
                          seletedAssignee === null ||
                          addedEnquiryContactLoading
                        }
                        sx={{ textTransform: "capitalize" }}
                      >
                        {addedEnquiryContactLoading ? (
                          <CircularProgress size={18} color={"inherit"} />
                        ) : (
                          "Move"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Tooltip
                  arrow
                  title={
                    (page === "ZAPIER" && hasZapierAccess === false) ||
                    (page === "CONTACTS" && hasContactsAccess === false)
                      ? "Oops! No permission."
                      : ""
                  }
                  sx={{ width: "100%" }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <button
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        border: "1px solid #D0D5DD",
                        padding: "4px",
                        borderRadius: "8px",
                        marginLeft: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        alignItems: "center",
                      }}
                      disabled={
                        (page === "ZAPIER" && hasZapierAccess === false) ||
                        (page === "CONTACTS" && hasContactsAccess === false)
                      }
                    >
                      <img src={deleteIcon} width="20px" />
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}

          <div style={{ marginTop: "20px" }}>
            {contactListLoading ? (
              <TableSkeleton />
            ) : (
              <>
                {contactList &&
                contactList?.returnData &&
                contactList?.returnData?.length > 0 &&
                filterArray &&
                importedContacts?.length > 0 ? (
                  <TableContainer
                    className={styles.zapierTableContainer}
                    sx={{
                      minWidth: 700,
                      borderRadius: "8PX",
                      border: "1px solid  #EAECF0",
                      maxHeight: "calc(100vh - 200px)",
                      minHeight: "calc(100vh - 200px)",
                      overflowY: "hidden",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={
                        filterArray?.returnData
                          ? importedContacts?.length
                          : contactList?.returnData?.length
                      }
                      next={handleNextPage}
                      hasMore={hasMoreData}
                      loader={
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <PuffLoader color="#36d7b7" />
                        </Box>
                      }
                      height={"calc(100vh - 215px)"}
                    >
                      <Table
                        sx={{
                          minWidth: 700,
                        }}
                        aria-label="customized table"
                      >
                        <TableHead
                          className={styles.batchPopupHead}
                          sx={{
                            position: "sticky",
                            top: 0,
                            zIndex:
                              openDeleteModal || openAddEnquiryModal ? 1 : 999,
                          }}
                        >
                          <TableRow>
                            <StyledTableCell
                              className={styles.batchPopupHeadCell}
                            >
                              <Checkbox
                                sx={{
                                  strokeWidth: 1,
                                  color: "rgba(102, 112, 133, 0.30)",
                                }}
                                checked={
                                  selectedContacts?.length ===
                                  importedContacts?.length
                                }
                                onChange={() => {
                                  if (
                                    selectedContacts?.length ===
                                    importedContacts?.length
                                  ) {
                                    setSelectedContacts([]);
                                    setIsCheckboxChecked(false);
                                    setSelectedCourseForMoving([]);
                                    setSelectedCourse(null);
                                    setSelectedBranch(null);
                                    setSelectedBatch(null);
                                  } else {
                                    const allEmailIds = importedContacts?.map(
                                      (item: any) => item.id
                                    );
                                    const allContacts = importedContacts?.map(
                                      (item: any) => item
                                    );
                                    setSelectedContacts(allEmailIds);
                                    setIsCheckboxChecked(true);
                                    setSelectedCourseForMoving(allContacts);
                                  }
                                }}
                              />
                            </StyledTableCell>

                            <StyledTableCell
                              className={styles.batchPopupHeadCell}
                            >
                              Name
                            </StyledTableCell>

                            <StyledTableCell
                              className={styles.cellEmail}
                              align="left"
                              sx={{ maxWidth: "370px" }}
                            >
                              Email Address
                            </StyledTableCell>
                            <StyledTableCell
                              className={styles.cellPhone}
                              align="left"
                            >
                              Phone number
                            </StyledTableCell>

                            <StyledTableCell
                              className={styles.batchPopupHeadCell}
                              align="left"
                            >
                              Source
                            </StyledTableCell>
                            <StyledTableCell
                              className={styles.cellImport}
                              align="left"
                              style={{ fontSize: "13px" }}
                            >
                              Imported by
                            </StyledTableCell>

                            <StyledTableCell
                              className={styles.cellDate}
                              align="left"
                            >
                              Date & Time
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filterArray?.returnData !== null &&
                            filteredContactList?.map((row: any, index: any) => (
                              <StyledTableRow
                                key={index}
                                className={styles.batchPopupColumnCells}
                                style={{ cursor: "pointer" }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <Checkbox
                                    sx={{
                                      strokeWidth: 1,
                                      color: "rgba(102, 112, 133, 0.30)",
                                    }}
                                    checked={selectedContacts?.includes(row.id)}
                                    onChange={() => {
                                      handleCheckboxToggle(row.id);
                                      handleCourseToggle(row);
                                    }}
                                  />
                                </StyledTableCell>

                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    minWidth: "110px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.firstName || row?.lastName
                                    ? row?.firstName + " " + row?.lastName
                                    : "-"}
                                  {row?.tag === "LEAD_EXISTS" && (
                                    <span
                                      style={{
                                        color: "#EBA418",
                                        fontSize: "10px",
                                        background: "#FBECD0",
                                        padding: "3px",
                                        borderRadius: "4px",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      Lead Exists
                                    </span>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.email ? row?.email : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.contactNumber?.toString()?.length > 10
                                    ? row?.contactNumber
                                    : row?.contactNumber?.toString()?.length ===
                                      10
                                    ? `+91 ${row?.contactNumber}`
                                    : "-"}
                                </StyledTableCell>

                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.enquirySource
                                    ? row?.enquirySource
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.createdByName
                                    ? row?.createdByName
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.createdTime
                                    ? getFormattedDate(row?.createdTime)
                                    : "-"}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {filteredContactList &&
                      filteredContactList?.length <= 0 ? (
                        <>
                          {searchTerm?.length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  color: " #667085",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2px",
                                  justifyContent: "center",
                                  marginTop: "50px",
                                }}
                              >
                                <div style={{ color: " #142C44" }}>
                                  No results found.
                                </div>
                                Try another search
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                color: " #667085",
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                                justifyContent: "center",
                                marginTop: "50px",
                              }}
                            >
                              <div style={{ color: " #142C44" }}>
                                No results found.
                              </div>
                              Try another Filter
                            </div>
                          )}
                        </>
                      ) : (
                        <> </>
                      )}
                    </InfiniteScroll>
                  </TableContainer>
                ) : (
                  <>
                    <Box sx={{ width: "100%" }}>
                      <Box
                        style={{
                          border: "1px solid #E3E3E3",
                          width: "100%",
                          padding: "30px",
                          boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                          borderRadius: "6px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#667085",
                            fontFamily: "Poppins",
                            textAlign: "center",
                          }}
                        >
                          No contacts from Zapier
                        </div>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </div>
        </>
        <RemoveContactsModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          deleteConfirm={deleteContacts}
          loading={deleteContactLoading}
        />

        <CourseMapModel
          open={openDifferentCourseMappingPopup}
          noButtonClick={noMoveCourseButtonClick}
          yesButtonClick={yesMoveCourseButtonClick}
          setOpenDifferentCourseMappingPopup={
            setOpenDifferentCourseMappingPopup
          }
        />
      </div>
      <AddLeadPayment
        show={openAddEnquiryModal}
        selectedValue={selectedRowDetails && selectedRowDetails?.email}
        messageId={selectedRowDetails && selectedRowDetails?.id}
        onHide={() => {
          setOpenAddEnquiryModal(false);
        }}
        nurtureAddEnquiry={AddEnquiryForZapier}
        isEmailDetail={false}
        leadCreateSuccess={
          addedEnquiryZapierSuccess && addedEnquiryZapierSuccess
        }
        isSourceWidth={false}
        isFromContacts={true}
        selectedRowDetails={selectedRowDetails}
        page={"ZAPIER"}
        showAddLeadWideView={showAddLeadWideView}
        setAddLeadWideView={setAddLeadWideView}
        leadCreatedId={leadCreatedIdForZapier}
        leadCreateLoading={leadLoading}
      />
    </>
  );
}

export default ZapierTab;
