import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import EmailSettings from "../pages/app/Inbound/components/Settings/EmailSettings/EmailSettings";
import ImportSettings from "../pages/app/Inbound/components/Settings/ImportSettings/ImportSettings";
import FilterSettings from "../pages/app/Inbound/components/Settings/ImportSettings/FilterSettings";
import { getSubDomainAccessStatus } from "../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import NoAccess from "../components/NoAccess/NoAccess";

export default function ManageInboundSettingsRouter(props: any) {
  const { isActive } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const importString: any = localStorage?.getItem("ImportedContacts")!;
  const result = importString && JSON.parse(importString);
  const { userById } = useSelector((state: any) => state.userDetails);

  const importAccess = getSubDomainAccessStatus(
    "INBOUND",
    "IMPORT",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const emailAccess = getSubDomainAccessStatus(
    "INBOUND",
    "EMAIL",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  // useEffect(() => {
  //   if (location.pathname.includes("/app/Inbound")) {
  //     if (result?.fileName) {
  //       navigate("/app/inbound/ImportPreview");
  //     } else if (location.pathname.includes("/app/Inbound/Email")) {
  //       navigate("Email", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Webforms")) {
  //       // navigate("Webforms", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Contacts")) {
  //       navigate("Contacts", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Import")) {
  //       navigate("Import", { replace: true });
  //     }
  //   }
  // }, [isActive, result]);

  return (
    <Routes>
      <Route
        path={"/"}
        element={emailAccess ? <EmailSettings /> : <NoAccess />}
      />
      <Route
        path={"/Email"}
        element={emailAccess ? <EmailSettings /> : <NoAccess />}
      />
      <Route
        path={"/Import"}
        element={importAccess ? <ImportSettings /> : <NoAccess />}
      />
      <Route
        path={"/ImportSetting"}
        element={importAccess ? <ImportSettings /> : <NoAccess />}
      />
      {/* <Route
        path={"/WhatsApp"}
        element={
          importAccess ? (
            <WhatsAppSettings
              whatsappConfigInstituteData={whatsappConfigInstituteData}
              {...props}
            />
          ) : (
            <NoAccess />
          )
        }
      /> */}
      {/* <Route path={"/ImportSetting/filter"} element={<FilterSettings />} />
      <Route path={"/Import/filter"} element={<FilterSettings />} /> */}
    </Routes>
  );
}
