import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  styled,
  Box,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import styles from "./Users.module.css";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import UserLimitPopup from "../../../../../Manage/ManageStaff/StaffListing/UserLimitPopup";
import { useAuthUser } from "react-auth-kit";
import { getSubDomainAccessStatus } from "../../../../../../../components/RolePermissionAccess/RolePermissionAccess";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Users(props: any) {
  const { roleById } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const instituteDetails = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { staffList } = useSelector((state: any) => state.staff);
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasTeamAccess = getSubDomainAccessStatus(
    "MANAGE",
    "TEAMS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasRolesAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "ROLES_&_PERMISSIONS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const userLicenseCount = instituteDetails?.totalUserLicense;
  const userCount = instituteDetails?.teamCount;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [userLimitPopup, setUserLimitPopup] = useState<boolean>(false);

  const closeUserLimitPopup = () => {
    setUserLimitPopup(false);
  };

  const openUserLimitPopup = () => {
    setUserLimitPopup(true);
  };

  return (
    <Box
      sx={{
        padding: "0px 20px 0px 20px",
      }}
    >
      <UserLimitPopup isOpen={userLimitPopup} onClose={closeUserLimitPopup} />
      <Box sx={{ mt: "15px" }}>
        <Accordion
          style={{
            background: "#ffffff",
            boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
            border: "1px solid #E3E3E3 ",
          }}
          expanded={expanded === "user"}
          onChange={handleChange("user")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="user-panel-content"
            id="user-panel-header"
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  color: "#142C44",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Users (
                {
                  staffList?.filter((d: any) => d.roleId === roleById?.id)
                    ?.length
                }
                )
              </Typography>
              <Tooltip
                arrow
                title={
                  hasTeamAccess === false || hasRolesAccess === false
                    ? "Oops! No permission."
                    : ""
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span>
                  <Button
                    component="label"
                    sx={{
                      textTransform: "capitalize",
                      gap: "4px",
                      color: "#11C89B",
                      fontSize: "13px",
                      fontWeight: "500",
                      padding: "2px 8px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      userLicenseCount > userCount
                        ? navigate(
                            `/app/Settings/Rolesandpermission/Create-Team?defaultRole=${roleById?.id}`
                          )
                        : openUserLimitPopup();
                    }}
                    disabled={
                      hasTeamAccess === false || hasRolesAccess === false
                    }
                  >
                    Add New User
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            {staffList?.filter((d: any) => d.roleId === roleById?.id)?.length >
            0 ? (
              <TableContainer
                sx={{
                  minWidth: 700,
                  borderRadius: "0px 0px 8px 8PX",
                  border: "1px solid  #EAECF0",
                }}
              >
                <Table
                  sx={{
                    minWidth: 700,
                  }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>

                      <StyledTableCell align="left" sx={{ maxWidth: "370px" }}>
                        Email
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Phone Number
                      </StyledTableCell>
                      <StyledTableCell align="left">Branch</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {staffList
                      ?.filter((d: any) => d.roleId === roleById?.id)
                      ?.map((item: any, index: any) => (
                        <TableRow key={index}>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color: "#667085",
                              textTransform: "capitalize",
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <Box className={styles.userCardImg}>
                              <Avatar
                                alt="avatar"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "50%",
                                  fontSize: "15px",
                                  background: "#4E647A",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  color: "#FFF",
                                }}
                                className={styles.userAvatar}
                              >
                                <Box>{item?.firstName?.charAt(0)}</Box>
                              </Avatar>
                            </Box>
                            {item?.firstName + " " + item?.lastName}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color: "#667085",
                            }}
                          >
                            {item?.email}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color: "#667085",
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.contact?.length > 8 ? item?.contact : "-"}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color: "#667085",
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.branchName}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                className={styles.noUser}
                style={{
                  borderRadius: "0px 0px 8px 8PX",
                  border: "1px solid  #EAECF0",
                  padding: "40px 10px",
                }}
              >
                No users has been added
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default Users;
