import { Button, Drawer, Skeleton, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import LeadMainDetailsBox from "../LeadMainDetailsCard/LeadMainDetailsBox";
import LeadCourseDetails from "./LeadCourseDetails";
import {
  getLeadListByInstituteAndContact,
  resetLeadById,
  setLeadModalState,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { checkSubDomainAccess } from "../RolePermissionAccess/RolePermissionAccess";
import { useDispatch } from "react-redux";

function LeadSidePopup(props: any) {
  const { show, onHide } = props;
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardView = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const {
    leadBoard,
    leadById,
    leadUpdateSuccess,
    leadByIdLoading,
    leadLostUpdateSuccess,
    leadStatusUpdateSuccess,
  } = useSelector((state: any) => state.nurture);
  const branchList = useSelector((state: any) => state.branch.branchList);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter(
    (item: any) => item?.batchConductType === "REGULAR_BATCH"
  );
  const sortedSwimlane =
    leadBoard &&
    [...leadBoard?.swimlaneData].sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });

  const [selectedLeadData, setSelectedLeadData] = React.useState<any>("");
  const [leadListArray, setLeadListArray] = React.useState<any>(null);

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  React.useEffect(() => {
    if (leadById) {
      setSelectedLeadData(leadById);
      setLeadListArray([leadById]);
    }
  }, [leadById]);

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={show}
        onClose={() => {
          onHide();
          dispatch(resetLeadById());
          setSelectedLeadData("");
          setLeadListArray(null);
        }}
        sx={{ height: "100%", zIndex: 99999 }}
      >
        <button
          style={{
            top: "10px",
            right: "405px",
            zIndex: 999,
            position: "fixed",
            width: "27px",
            height: "27px",
            border: "none",
            background: "#fff",
            borderRadius: "5px",
            padding: "0",
            overflow: "hidden",
            cursor: "pointer",
          }}
          onClick={() => {
            onHide();
            dispatch(resetLeadById());
            setSelectedLeadData("");
            setLeadListArray(null);
          }}
        >
          x
        </button>
        <div
          style={{
            width: "420px",

            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ padding: "14px", borderBottom: "1PX SOLID #ebebeb" }}>
              {!leadByIdLoading &&
              leadListArray?.length > 0 &&
              sortedSwimlane !== null ? (
                <LeadMainDetailsBox
                  leadById={leadListArray && selectedLeadData}
                />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: "6px",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  </div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    sx={{ marginBottom: "6px" }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={40}
                    sx={{ marginBottom: "6px" }}
                  />
                </>
              )}
            </div>
            <div
              style={{
                padding: "14px",
                maxHeight: "calc(100vh - 280px)",
                overflowY: "auto",
              }}
            >
              {!leadByIdLoading &&
              leadListArray?.length > 0 &&
              selectedLeadData &&
              sortedSwimlane !== null ? (
                <LeadCourseDetails
                  branchList={branchList}
                  batchList={batchList}
                  leadListByContact={leadListArray}
                  leadStages={sortedSwimlane}
                  isFromWebform={true}
                  //   setSelectedLeadId={setSelectedLeadId}
                  setSelectedLeadData={setSelectedLeadData}
                  //   selectedLeadId={selectedLeadId}
                  selectedLeadData={leadListArray && selectedLeadData}
                />
              ) : (
                Array.from(new Array(3)).map((_, index) => (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    <div>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={60}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",

              padding: "10px 20px",
              background: "#fff",
              borderTop: "1px solid #ebebeb",
            }}
          >
            {!leadByIdLoading &&
            leadListArray?.length > 0 &&
            sortedSwimlane !== null ? (
              <Tooltip
                arrow
                title={hasLeadCardView === false ? "Oops! No permission." : ""}
                sx={{ width: "100%" }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ textTransform: "capitalize" }}
                    onClick={() =>
                      openLeadExpandModal(
                        selectedLeadData?.instituteId,
                        selectedLeadData?.studentContact,
                        selectedLeadData?.studentEmail,
                        selectedLeadData?.id
                      )
                    }
                    disabled={!hasLeadCardView}
                  >
                    View Lead Card
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={30}
                  sx={{ marginBottom: "6px" }}
                />
              </>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default LeadSidePopup;
