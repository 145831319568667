import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import styles from "../components/Manegecourses.module.css";
import dummyimage from "../components/coursedummyimage.png";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormGroup,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import { useAuthUser } from "react-auth-kit";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import AddCoursePopup from "./AddCoursePopup";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";
import { useDispatch } from "react-redux";

interface CheckedCourseStatus {
  courseStatus: string;
}
interface CheckedCategory {
  id: string;
  category: string;
}
interface CheckedLevel {
  id: string;
  level: string;
}

export default function CourseListing(props: any) {
  const {
    GetAllCourseByInstituteRequest,
    courses,
    CourseConfig,
    loading_getAllCourseByInstituteRequest,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);
  const hasCourseAccess = getSubDomainAccessStatus(
    "MANAGE",
    "COURSES",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const CourseCategories = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseCategories
  );
  const courseLavel = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseLevel
  );
  const courseStatus = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseStatus
  );
  const { createCourseSuccess } = useSelector((state: any) => state.courses);
  const error = useSelector((state: any) => state.courses.error);
  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [categoryOption, setCategoryOption] = useState(CourseCategories);
  const [courseLevelOption, setCourseLevelOption] = useState(courseLavel);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchListTerm, setSearchListTerm] = useState<string>("");
  const [showAllCategory, setShowAllCategory] = useState<boolean>(false);
  const [showAllCaurseLevel, setShowAllCaurseLevel] = useState<boolean>(false);
  const [addCourseModal, setAddCourseModal] = useState<boolean>(false);

  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

  const [checkedCourseStatuses, setCheckedCourseStatuses] = useState<
    CheckedCourseStatus[]
  >([]);
  const [filterCheckedCourseStatuses, setFilterCheckedCourseStatuses] =
    useState<CheckedCourseStatus[]>([]);

  const [checkedCategory, setCheckedCategory] = useState<CheckedCategory[]>([]);
  const [filterCheckedCategory, setFilterCheckedCategory] = useState<
    CheckedCategory[]
  >([]);

  const [checkedCourseLevel, setCheckedCourseLevel] = useState<CheckedLevel[]>(
    []
  );
  const [filterCheckedCourseLevel, setFilterCheckedCourseLevel] = useState<
    CheckedLevel[]
  >([]);

  const openAddCoursePopup = () => {
    setAddCourseModal(true);
  };

  const closeAddCoursePopup = (type: any) => {
    setAddCourseModal(false);
  };

  const handleFilterCourseStatusChecked = (courseStatus: string) => {
    const courseStatusToAdd: CheckedCourseStatus = { courseStatus };

    if (
      checkedCourseStatuses.some(
        (item) => item.courseStatus.toLowerCase() === courseStatus.toLowerCase()
      )
    ) {
      setCheckedCourseStatuses(
        checkedCourseStatuses.filter(
          (item) =>
            item.courseStatus.toLowerCase() !== courseStatus.toLowerCase()
        )
      );
    } else {
      setCheckedCourseStatuses([...checkedCourseStatuses, courseStatusToAdd]);
    }
  };

  const clearCheckedCourseStatus = (courseStatus: string) => {
    setCheckedCourseStatuses(
      checkedCourseStatuses.filter((item) => item.courseStatus !== courseStatus)
    );
  };

  const handleFilterCategoryChecked = (id: string, category: string) => {
    const categoryToAdd: CheckedCategory = { id, category };
    if (checkedCategory.some((data) => data.id === id)) {
      setCheckedCategory(checkedCategory.filter((data) => data.id !== id));
    } else {
      setCheckedCategory([...checkedCategory, categoryToAdd]);
    }
  };

  const clearCheckedCategory = (id: string) => {
    setCheckedCategory(checkedCategory.filter((data) => data.id !== id));
  };

  const handleFilterLevelChecked = (id: string, level: string) => {
    const levelToAdd: CheckedLevel = { id, level };
    if (checkedCourseLevel.some((data) => data.id === id)) {
      setCheckedCourseLevel(
        checkedCourseLevel.filter((data) => data.id !== id)
      );
    } else {
      setCheckedCourseLevel([...checkedCourseLevel, levelToAdd]);
    }
  };

  const clearCheckedLevel = (id: string) => {
    setCheckedCourseLevel(checkedCourseLevel.filter((data) => data.id !== id));
  };

  const filteredCourseList =
    courses &&
    courses?.filter((course: any) => {
      const nameMatch = course?.courseName
        ?.toLowerCase()
        ?.includes(searchListTerm?.toLowerCase());
      const categoryIsChecked =
        filterCheckedCategory?.length === 0 ||
        filterCheckedCategory?.some(
          (item) => item?.id === course?.courseCategory?.id
        );

      const levelIsChecked =
        filterCheckedCourseLevel?.length === 0 ||
        filterCheckedCourseLevel?.some(
          (item) => item?.id === course?.courseLevel?.id
        );

      const courseStatusIsChecked =
        filterCheckedCourseStatuses.length === 0 ||
        filterCheckedCourseStatuses.some(
          (item) => item.courseStatus === course.status
        );

      return (
        nameMatch &&
        categoryIsChecked &&
        levelIsChecked &&
        courseStatusIsChecked
      );
    });

  const filteredCategory =
    categoryOption &&
    categoryOption?.filter((item: any) => {
      const searchTermMatch =
        item.role?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !== -1;
      return searchTermMatch;
    });

  const displayedCategory = showAllCategory
    ? filteredCategory
    : filteredCategory?.slice(0, 5);

  const handleShowMore = () => {
    setShowAllCategory(!showAllCategory);
  };

  const displayedCaurseLevel = showAllCaurseLevel
    ? courseLevelOption
    : courseLevelOption?.slice(0, 5);

  const handleShowMoreCourseLevel = () => {
    setShowAllCaurseLevel(!showAllCaurseLevel);
  };

  const filteredValue = (
    <>
      {filterCheckedCategory?.length > 0 &&
        filterCheckedCategory?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.category}
          </div>
        ))}
      {filterCheckedCourseLevel?.length > 0 &&
        filterCheckedCourseLevel?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.level}
          </div>
        ))}
      {filterCheckedCourseStatuses?.length > 0 &&
        filterCheckedCourseStatuses?.map((item) => (
          <div key={item.courseStatus} className="filteredDiv">
            {item.courseStatus}
          </div>
        ))}
    </>
  );

  const handleFilterCoursess = React.useCallback(
    (categoryArray: any, levelArray: any, courseStatusArray: any) => {
      setFilterCheckedCategory(categoryArray);
      setFilterCheckedCourseLevel(levelArray);
      setFilterCheckedCourseStatuses(courseStatusArray);

      const queryParams = new URLSearchParams();
      if (categoryArray && categoryArray.length > 0) {
        const categoryParam = categoryArray
          .map((item: any) => item.id)
          .join("|");
        queryParams.set("filteredCategory", categoryParam);
      } else {
        queryParams.delete("filteredCategory");
      }
      if (levelArray && levelArray.length > 0) {
        const levelParam = levelArray.map((item: any) => item.id).join("|");
        queryParams.set("filteredLevel", levelParam);
      } else {
        queryParams.delete("filteredLevel");
      }

      if (courseStatusArray && courseStatusArray.length > 0) {
        const courseStatusParam = courseStatusArray
          .map((item: any) => item.courseStatus)
          .join("|");
        queryParams.set("filteredStatus", courseStatusParam);
      } else {
        queryParams.delete("filteredStatus");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [
      setFilterCheckedCategory,
      setFilterCheckedCourseLevel,
      setFilterCheckedCourseStatuses,
    ]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterCoursess(
      checkedCategory,
      checkedCourseLevel,
      checkedCourseStatuses
    );

    setFilterApplied(
      checkedCategory?.length === 0 &&
        checkedCourseLevel?.length === 0 &&
        checkedCourseStatuses?.length === 0
        ? false
        : true
    );
  }, [
    handleFilterCoursess,
    checkedCategory,
    checkedCourseLevel,
    checkedCourseStatuses,
    setFilterApplied,
  ]);

  const handleResetClick = React.useCallback(() => {
    setFilterCheckedCategory([]);
    setFilterCheckedCourseLevel([]);
    setFilterCheckedCourseStatuses([]);
    setFilterApplied(false);
    setCheckedCategory([]);
    setCheckedCourseStatuses([]);
    setCheckedCourseLevel([]);
    handleFilterCoursess([], [], []);
  }, [
    setFilterApplied,
    setFilterCheckedCategory,
    setFilterCheckedCourseLevel,
    setFilterCheckedCourseStatuses,
    setCheckedCategory,
    setCheckedCourseStatuses,
    setCheckedCourseLevel,
    handleFilterCoursess,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedCategory(filterCheckedCategory);
    setCheckedCourseStatuses(filterCheckedCourseStatuses);
    setCheckedCourseLevel(filterCheckedCourseLevel);
  }, [
    filterCheckedCategory,
    filterCheckedCourseLevel,
    filterCheckedCourseStatuses,
  ]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const categoryParam = urlSearchParams.get("filteredCategory");
    const courseStatusParam = urlSearchParams.get("filteredStatus");
    const levelParam = urlSearchParams.get("filteredLevel");

    if (categoryParam && CourseCategories) {
      const categoryIds = categoryParam.split("|");

      const selectedCategories = categoryIds
        .map((id) => {
          const category = CourseCategories?.find(
            (category: any) => category.id === id
          );
          return category
            ? { id, category: category.courseCategoryName }
            : null;
        })
        .filter(Boolean) as CheckedCategory[];

      setFilterCheckedCategory(selectedCategories);
      setCheckedCategory(selectedCategories);
    }

    if (levelParam && courseLavel) {
      const levelIds = levelParam.split("|");

      const selectedLevels = levelIds
        .map((id) => {
          const level = courseLavel?.find((type: any) => type.id === id);
          return level
            ? {
                id,
                level: level.courseLevelName,
              }
            : null;
        })
        .filter(Boolean) as CheckedLevel[];
      setFilterCheckedCourseLevel(selectedLevels);
      setCheckedCourseLevel(selectedLevels);
    }

    if (courseStatusParam) {
      const statuses = courseStatusParam.split("|");

      const selectedStatuses = statuses
        .map((value) => {
          const status = [{ status: "ACTIVE" }, { status: "INACTIVE" }].find(
            (type: any) => type.status.toLowerCase() === value.toLowerCase()
          );
          return status
            ? {
                courseStatus: status.status,
              }
            : null;
        })
        .filter(Boolean) as CheckedCourseStatus[];

      setFilterCheckedCourseStatuses(selectedStatuses);
      setCheckedCourseStatuses(selectedStatuses);
    }

    if (categoryParam || levelParam || courseStatusParam) {
      setFilterApplied(true);
    }
  }, [courseLavel, CourseCategories]);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (instituteData?.id) {
      if (instituteData?.id && !instituteData?.isProfileCompleted) {
        dispatch<any>(getInstituteById(instituteData?.id));
      }
      GetAllCourseByInstituteRequest({
        id: instituteData?.id,
        loadExtraInfo: true,
      });
      props.GetBranchList(instituteData?.id);
    }
    CourseConfig();
  }, []);

  useEffect(() => {
    const validCourseCategories =
      CourseCategories &&
      CourseCategories?.filter(
        (category: any) => category?.courseCategoryName !== ""
      );
    setCategoryOption(validCourseCategories);
  }, [CourseCategories]);

  useEffect(() => {
    setCourseLevelOption(courseLavel);
  }, [courseLavel]);

  React.useEffect(() => {
    if (createCourseSuccess !== true && createCourseSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage?.id) {
        GetAllCourseByInstituteRequest({
          id: userFromLocalStorage?.id,
          loadExtraInfo: true,
        });
      }
    }
  }, [createCourseSuccess]);

  return (
    <Box sx={{ overflowY: "auto", height: "100vh" }}>
      <Box sx={{ padding: "6px 22px 0 22px" }}>
        <Box
          className={styles.addListbar}
          sx={{
            marginBottom: "20px",
            marginTop: "25px",
          }}
        >
          {loading_getAllCourseByInstituteRequest ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100px"
                height="38px"
                style={{
                  borderRadius: "2px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                height="38px"
                width="200px"
                style={{
                  borderRadius: "2px",
                }}
              />
            </>
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#142C44",
                    marginLeft: "5PX",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Courses
                </span>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <TextField
                  type="search"
                  value={searchListTerm}
                  onChange={(e) => {
                    setSearchListTerm(e.target.value);
                  }}
                  placeholder="Search Courses"
                  style={{
                    padding: "0px",
                    borderRadius: "4px",
                    border: "none",
                    outline: "none",
                    height: "38px",
                    marginRight: "8px",
                  }}
                  size="small"
                  inputProps={{
                    sx: {
                      width: "250px",
                      "&::placeholder": {
                        fontWeight: "400",
                        fontSize: "14px",
                      },

                      "& .MuiOutlinedInput-input": {
                        padding: "10.5px 14px",
                      },
                    },
                  }}
                />

                <FilterDrawer
                  handleFilterApply={handleFilterApply}
                  handleResetClick={handleResetClick}
                  activeFilter={filterApplied}
                  cancelClick={cancelClick}
                  closeSetFilter={handleFilterClose}
                >
                  <div
                    style={{
                      padding: "22px 32px",
                      overflowY: "auto",
                      maxHeight: "calc(100% - 110px)",
                    }}
                  >
                    <FilterChipsWrap>
                      {checkedCourseStatuses.length > 0 &&
                        checkedCourseStatuses.map((item) => (
                          <FilterChips
                            keyVal={item.courseStatus}
                            chipName={item.courseStatus.toLowerCase()}
                            clearValue={item.courseStatus}
                            closeFunction={clearCheckedCourseStatus}
                          />
                        ))}

                      {checkedCategory.length > 0 &&
                        checkedCategory.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.category}
                            clearValue={item.id}
                            closeFunction={clearCheckedCategory}
                          />
                        ))}

                      {checkedCourseLevel.length > 0 &&
                        checkedCourseLevel.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.level}
                            clearValue={item.id}
                            closeFunction={clearCheckedLevel}
                          />
                        ))}
                    </FilterChipsWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Status" />

                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {[{ status: "ACTIVE" }, { status: "INACTIVE" }].map(
                            (item: any) => (
                              <FilterCheckbox
                                keyVal={item.status}
                                checked={checkedCourseStatuses.some(
                                  (data) =>
                                    data.courseStatus.toLowerCase() ===
                                    item.status.toLowerCase()
                                )}
                                checkboxFunction={() =>
                                  handleFilterCourseStatusChecked(item.status)
                                }
                                label={item.status.toLowerCase()}
                              />
                            )
                          )}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Category" />
                      <FilterSearchField
                        value={searchTerm}
                        onChange={(e: any) => {
                          setSearchTerm(e.target.value);
                          const a = CourseCategories?.filter((obj: any) =>
                            obj.courseCategoryName
                              .toLocaleLowerCase()
                              .includes(e.target.value)
                          );
                          setCategoryOption(a);
                        }}
                        placeholder="Search Category"
                      />

                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {categoryOption &&
                            displayedCategory?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedCategory.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterCategoryChecked(
                                    item.id,
                                    item.courseCategoryName
                                  )
                                }
                                label={item.courseCategoryName}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMore}
                        showAll={showAllCategory}
                        filteredState={filteredCategory}
                      />
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue=" Course Level" />

                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {courseLevelOption &&
                            displayedCaurseLevel?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedCourseLevel.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterLevelChecked(
                                    item.id,
                                    item.courseLevelName
                                  )
                                }
                                label={item.courseLevelName}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>

                      <ShowAllBtn
                        clickFunction={handleShowMoreCourseLevel}
                        showAll={showAllCaurseLevel}
                        filteredState={displayedCaurseLevel}
                      />
                    </FilterTypeWrap>
                  </div>
                </FilterDrawer>

                {hasCourseAccess && (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      marginLeft: "8px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={openAddCoursePopup}
                  >
                    Add new
                  </Button>
                )}

                {/* <Tooltip
                  arrow
                  title={
                    hasCourseAccess === false ? "Oops! No permission." : ""
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    {hasCourseAccess === false ? (
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                          marginLeft: "8px",
                        }}
                        startIcon={<AddIcon />}
                        disabled={hasCourseAccess === false}
                      >
                        Add new
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                          marginLeft: "8px",
                        }}
                        startIcon={<AddIcon />}
                        onClick={openAddCoursePopup}
                      >
                        Add new
                      </Button>
                    )}
                  </span>
                </Tooltip> */}
              </Box>
            </>
          )}
        </Box>

        <Box className={styles.courseListWrapper}>
          {loading_getAllCourseByInstituteRequest ? (
            Array.from(new Array(4)).map((_, index) => (
              <div key={index} style={{ display: "flex", gap: "24px" }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height="250px"
                  width="230px"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
              </div>
            ))
          ) : (
            <>
              {courses && filteredCourseList?.length === 0 && (
                <div
                  className={styles.NoCourse}
                  onClick={() => {
                    openAddCoursePopup();
                  }}
                >
                  Create Courses
                </div>
              )}
              {courses &&
                filteredCourseList
                  ?.filter((item: any) => {
                    if (filterCheckedCourseStatuses.length === 0) {
                      return item.status !== "INACTIVE";
                    }
                    return true;
                  })
                  ?.map((item: any, index: number) => {
                    const key = moment().format("x") + Math.random();
                    return (
                      <div className={styles.courseCardWrap} key={key}>
                        <Link
                          to={`/app/manage/Courses/details/${item.id}`}
                          key={index}
                          className={styles.courseCard}
                        >
                          <div className={styles.courseCardImg}>
                            <img
                              src={
                                item.courseImage ? item.courseImage : dummyimage
                              }
                              alt={item.courseName}
                            />
                          </div>
                          <div className={styles.courseCardDetailsWrap}>
                            <div>
                              <div className={styles.courseCardDetailsTop}>
                                <div
                                  className={
                                    item.courseCategory.status ===
                                      "UNDER_REVIEW" ||
                                    item.courseCategory.status ===
                                      "REVIEW_REJECTED"
                                      ? styles.courseCategoryRed
                                      : styles.courseCategory
                                  }
                                >
                                  {item.courseCategory.courseCategoryName}
                                </div>
                                <div className={styles.courseStatus}>
                                  <Tooltip
                                    arrow
                                    title={
                                      item.status === "DRAFT"
                                        ? "Please add payment to make course active"
                                        : ""
                                    }
                                    PopperProps={{ style: { zIndex: 9999999 } }}
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: "#101828",
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          padding: "8px 12px",
                                          "& .MuiTooltip-arrow": {
                                            color: "#101828",
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <span>
                                      <div
                                        className={` ${
                                          item.status === "ACTIVE"
                                            ? `${styles.statusActive}`
                                            : item?.status === "DRAFT"
                                            ? `${styles.statusDraft}`
                                            : item?.status === "INACTIVE"
                                            ? `${styles.statusInActive}`
                                            : `${styles.statusDraft}`
                                        }`}
                                      >
                                        {item.status === "ACTIVE"
                                          ? "Active"
                                          : item.status === "DRAFT"
                                          ? "Draft"
                                          : item.status === "INACTIVE"
                                          ? "InActive"
                                          : ""}
                                      </div>
                                    </span>
                                  </Tooltip>
                                  {/* <div
                                    className={` ${
                                      item.status === "ACTIVE"
                                        ? `${styles.statusActive}`
                                        : item?.status === "DRAFT"
                                        ? `${styles.statusDraft}`
                                        : `${styles.statusInActive}`
                                    }`}
                                  >
                                    {item.status === "ACTIVE"
                                      ? "Active"
                                      : item.status === "DRAFT"
                                      ? "Draft"
                                      : "InActive"}
                                  </div> */}
                                </div>
                              </div>
                              <div className={styles.courseListingName}>
                                {item.courseName}
                              </div>
                              <div className={styles.batches}>
                                {item.batches} Batches
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
            </>
          )}
        </Box>
        <ClearFilter
          open={isOpenFilter}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
          onClose={closeClearFilter}
          filteredValue={filteredValue}
        />
        {addCourseModal && (
          <AddCoursePopup
            isOpen={addCourseModal}
            onClose={closeAddCoursePopup}
          />
        )}
      </Box>
    </Box>
  );
}
