import { Button, Dialog } from "@mui/material";
import React from "react";
import styles from "../../ManageBranch/components/Managebranches.module.css";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import closeIcon from "../../ManageBranch/components/close.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

function UserLimitPopup(props: any) {
  const { isOpen, onClose, handleClose } = props;
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          zIndex: "99999",
          minWidth: "414px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "414px",
            maxHeight: "290px",
            minHeight: "200px",
            padding: "16px",
            borderRadius: "8px",
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
            left: "10%",
            background: "#FFFFFF",
            overflow: "hidden",
          },
        }}
      >
        <div className={styles.branchLimitPopupWrap}>
          <div className={styles.closeModalBtnWrap}>
            <button onClick={onClose} className={styles.closeModalBtn}>
              <img src={closeIcon} alt="closeIcon" />
            </button>
          </div>
          <div className={styles.branchLimitTitle}>
            Oops! You don't have any Unused Team License.
          </div>
          <div className={styles.branchLimitDes}>
            {hasBillingAccess
              ? "Please add a new Team license to continue."
              : "Please contact your admin to add a new Team License."}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6px",
              marginTop: "24px",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#BFBFBF",
                border: "1px solid #BFBFBF",
              }}
              variant="outlined"
              onClick={onClose}
              fullWidth
            >
              Cancel
            </Button>
            {hasBillingAccess ? (
              <Link
                style={{ width: "100%", display: "block" }}
                className={styles.link}
                to="/app/manage/Billing/ManageSubscription"
              >
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="contained"
                  fullWidth
                  onClick={() => handleClose()}
                >
                  Add license
                </Button>
              </Link>
            ) : (
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                fullWidth
                onClick={onClose}
              >
                ok
              </Button>
            )}
          </Box>
        </div>
      </Dialog>
    </>
  );
}

export default UserLimitPopup;
