import React, { useState } from "react";
import styles from "./DemoBooking.module.css";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import batchDot from "./Assets/batch-dot.svg";
import demoDot from "./Assets/demo-dot.svg";
import PickDemo from "./components/PickDemo";
import { Button, Tooltip } from "@mui/material";
import DemoSelectedConfirm from "./components/DemoSelectedConfirm";
import DemoBookedScreen from "./components/DemoBookedScreen";
import AddNewDemo from "./components/AddNewDemo";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import PickTime from "./components/PickTime";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { resetBatchStatus } from "../../../Manage/ManageBatch/service/Batchslice";
import { useDispatch } from "react-redux";

function DemoBooking(props: any) {
  const {
    selectedLeadData,
    employees,
    CreateDemo,
    demoTypes,
    demoByCourseAndInstitute,
    createDemoLoading,
    CreateBatch,
    DeleteDemoBookedForLead,
    deleteDemoBookedLoading,
    deleteDemoBookedSuccess,
    EditDemo,
    createDemoSuccess,
    handleCancelModalOpen,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [ongoingBatches, setOngoingBatches] = useState<any>([]);
  const [ongoingDemos, setOngoingDemos] = useState<any>([]);

  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    dayjs().unix() * 1000
  );
  const { batchCreateSuccess, batchCreateLoading } = useSelector(
    (state: any) => state.batch
  );

  const filteredOngoingDemos = ongoingDemos?.filter((batch: any) => {
    // Assuming batch.batchCalendar is an array of date objects with a date field
    return batch?.batchCalendar.some((dateObj: any) => {
      const demoTimestamp = dateObj.date; // Assuming date is in Unix timestamp (milliseconds)

      if (demoTimestamp && selectedDate) {
        const demoDate = new Date(demoTimestamp);
        const selectedDateObj = new Date(selectedDate);

        // Compare the dates (year, month, and day)
        return (
          demoDate.getFullYear() === selectedDateObj.getFullYear() &&
          demoDate.getMonth() === selectedDateObj.getMonth() &&
          demoDate.getDate() === selectedDateObj.getDate()
        );
      }

      return false; // Handle null or undefined values
    });
  });

  const filteredOngoingBatches = ongoingBatches?.filter((batch: any) => {
    // Assuming batch.batchCalendar is an array of date objects with a date field
    return batch?.batchCalendar
      .filter((item: any) => item?.status === "TRUE")
      .some((dateObj: any) => {
        const demoTimestamp = dateObj.date; // Assuming date is in Unix timestamp (milliseconds)

        if (demoTimestamp && selectedDate) {
          const demoDate = new Date(demoTimestamp);
          const selectedDateObj = new Date(selectedDate);

          // Compare the dates (year, month, and day)
          return (
            demoDate.getFullYear() === selectedDateObj.getFullYear() &&
            demoDate.getMonth() === selectedDateObj.getMonth() &&
            demoDate.getDate() === selectedDateObj.getDate()
          );
        }

        return false;
      });
  });
  const [newDate, setNewDate] = useState<any>(dayjs());
  const [demoDetails, setDemoDetails] = useState<any>({
    courseId: "",
    courseName: "",
    branchId: "",
    branchName: "",
    studentEmail: "",
    enquiryDetail: "",
    fullName: "",
    contact: "",
    status: "",
    enquirySource: "",
    demoTiming: 0,
    instituteId: "",
    enquiryId: "",
    assignedToId: "",
    assignedToName: "",
    batchId: "",
    batchName: "",
    batchType: "",
    demoStartTime: dayjs().unix(),
    demoEndTime: dayjs().unix(),
    demoDuration: 0,
    demoName: "",
    demoType: "",
    createdById: "",
    createdByName: "",
    id: "",
    updatedById: "",
    updatedByName: "",
  });
  const [stage, setStage] = useState<number>(0);
  const [way, setWay] = useState<number>(0);
  const [dateDisable, setDateDisable] = useState<boolean>(false);
  const [reschedule, setReschedule] = useState<boolean>(false);

  React.useEffect(() => {
    if (authUser && selectedLeadData) {
      setDemoDetails({
        ...demoDetails,
        instituteId: selectedLeadData?.instituteId,
        enquiryId: selectedLeadData?.id,
        courseId: selectedLeadData?.courseId,
        courseName: selectedLeadData?.courseName,
        branchId: selectedLeadData?.branchId,
        branchName: selectedLeadData?.branchName,
        fullName:
          selectedLeadData?.studentFirstName +
          " " +
          selectedLeadData?.studentLastName,
        contact: selectedLeadData?.studentContact,
        enquirySource: selectedLeadData?.enquirySource,
        studentEmail: selectedLeadData?.studentEmail,
        createdById: authUser?.institute?.[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
      });
    }
  }, [selectedLeadData]);

  React.useEffect(() => {
    if (createDemoSuccess || batchCreateSuccess) {
      setStage(0);
      setWay(0);
      setDateDisable(false);
      dispatch(resetBatchStatus());
    }
  }, [createDemoSuccess, batchCreateSuccess]);

  React.useEffect(() => {
    if (demoByCourseAndInstitute) {
      const activeBatches = demoByCourseAndInstitute?.batches?.filter(
        (batch: any) =>
          batch?.status === "ACTIVE" &&
          batch?.courseId === selectedLeadData?.courseId &&
          batch?.batchStatus !== "COMPLETED"
      );
      const batches = activeBatches?.filter(
        (batch: any) => batch.batchConductType === "REGULAR_BATCH"
      );
      const demos = activeBatches?.filter(
        (batch: any) => batch.batchConductType === "DEMO_BATCH"
      );
      setOngoingBatches(batches);
      setOngoingDemos(demos);
    }
  }, [demoByCourseAndInstitute, selectedLeadData]);

  const [editBatchId, setEditBatchId] = useState<string>("");
  const [editBatchDate, setEditBatchDate] = useState<string>("");

  const handleDemoEdit = React.useCallback(
    (demo: any) => {
      setReschedule(true);
      setEditBatchId(demo.batchId);
      setEditBatchDate(demo?.demoTiming);
      setStage(1);
      setDemoDetails({
        ...demoDetails,
        createdById: demo?.createdById,
        createdByName: demo?.createdByName,
        id: demo?.id,
        updatedById: authUser?.institute?.[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      });
    },
    [demoDetails]
  );

  const handleDateChange = (date: any) => {
    if (date !== null) {
      const dateUnix = date.unix() * 1000;
      setSelectedDate(dateUnix);
    }
  };

  const handleWaySelect = React.useCallback(() => {
    setDateDisable(true);
  }, []);

  const [demoSelected, setDemoSelected] = useState<any>(null);
  const [batchSelected, setBatchSelected] = useState<any>(null);

  const handleBatchSelect = React.useCallback(
    (batch: any, startTime: number, endTime: number, date: any) => {
      setBatchSelected(batch);
      setDemoSelected(null);
      setDemoDetails({
        ...demoDetails,
        batchId: batch.id,
        batchName: batch.batchName,
        batchType: batch.batchType[0]?.batchType?.courseTypeName,
        demoEndTime: endTime,
        demoStartTime: startTime,
        demoType: batch.batchType[0]?.batchType?.courseTypeName,
        demoName: batch.batchName,
        demoTiming: date,
      });
    },
    [demoDetails]
  );

  const handleDemoSelect = React.useCallback(
    (demo: any, startTime: number, endTime: number, date: any) => {
      setDemoSelected(demo);
      setBatchSelected(null);
      setDemoDetails({
        ...demoDetails,
        batchId: demo.id,
        batchName: demo.batchName,
        batchType: demo.batchType[0]?.batchType?.courseTypeName,
        demoEndTime: endTime,
        demoStartTime: startTime,
        demoName: demo.batchName,
        demoType: demo.batchType[0]?.batchType?.courseTypeName,
        demoDuration: (endTime - startTime) / 3600,
        demoTiming: date,
      });
    },
    [demoDetails]
  );

  const handleLastStageBack = React.useCallback(() => {
    setDemoDetails({
      ...demoDetails,
      batchId: "",
      batchName: "",
      batchType: "",
      demoEndTime: dayjs().unix(),
      demoStartTime: dayjs().unix(),
      demoName: "",
      demoType: "",
      demoDuration: 0,
      demoTiming: 0,
      assignedToId: "",
      assignedToName: "",
    });
  }, [demoDetails]);

  const handlePickDemoBack = React.useCallback(() => {
    if (reschedule) {
      setDemoDetails({
        ...demoDetails,
        id: "",
      });
    }
  }, [demoDetails, reschedule]);

  const [monthString, setMonthString] = useState(dayjs().format("YYYY-MM"));
  const [demoDates, setDemoDates] = useState<any[]>([]);
  const [batchDates, setBatchDates] = useState<number[]>([]);

  const handleMonthChange = (newMonth: any) => {
    setMonthString(newMonth.format("YYYY-MM"));
  };

  React.useEffect(() => {
    // Filter and map batch dates when monthString or ongoingBatches change
    const filteredDemoDates =
      ongoingDemos &&
      ongoingDemos?.reduce((dates: number[], batch: any) => {
        const batchDates = batch?.batchCalendar
          .filter((dateObj: any) => {
            const date = dayjs(dateObj.date);

            return (
              date.format("YYYY-MM") === monthString &&
              date.isAfter(dayjs().subtract(1, "day"), "day")
            );
          })
          // .filter((item: any) => item?.status === "TRUE")
          .map((dateObj: any) => Number(dayjs(dateObj.date).format("D")));

        return [...dates, ...batchDates];
      }, []);
    setDemoDates(filteredDemoDates);
  }, [monthString, newDate, way, ongoingDemos]);

  React.useEffect(() => {
    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10); // Convert day to a number
        if (
          demoDates &&
          !isNaN(dayNumberAsNumber) &&
          demoDates?.includes(dayNumberAsNumber)
        ) {
          dayElement.classList.add("demo-day");
        } else {
          dayElement.classList.remove("demo-day");
        }
      }
    });
  }, [demoDates]);

  React.useEffect(() => {
    // Filter and map batch dates when monthString or ongoingBatches change
    const filteredBatchDates =
      ongoingBatches &&
      ongoingBatches?.reduce((dates: number[], batch: any) => {
        const batchDates = batch?.batchCalendar
          .filter((dateObj: any) => {
            const date = dayjs(dateObj.date);
            return (
              date.format("YYYY-MM") === monthString &&
              date.isAfter(dayjs().subtract(1, "day"), "day")
            );
          })
          .filter((item: any) => item?.status === "TRUE")
          .map((dateObj: any) => Number(dayjs(dateObj.date).format("D")));

        return [...dates, ...batchDates];
      }, []);
    setBatchDates(filteredBatchDates);
  }, [monthString, newDate, way, ongoingBatches]);

  React.useEffect(() => {
    // Add the "batch-day" class based on batchDates
    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10); // Convert day to a number
        if (
          batchDates &&
          !isNaN(dayNumberAsNumber) &&
          batchDates?.includes(dayNumberAsNumber)
        ) {
          dayElement.classList.add("batch-day");
        } else {
          dayElement.classList.remove("batch-day");
        }
      }
    });
  }, [batchDates]);

  const demosByCourse = demoByCourseAndInstitute?.demos;
  return (
    <div className={styles.demoBookingWrapper}>
      <div className={styles.demoBookingLeft}>
        <div className={styles.demoBookingLeftHead}>
          <div className={styles.demoBookingTitle}>Book a Demo</div>
          <div className={styles.demoBookingLegends}>
            <div className={styles.demoBookingLegend}>
              <img src={demoDot} alt="demoDot" />
              Demo
            </div>
            <div className={styles.demoBookingLegend}>
              <img src={batchDot} alt="batchDot" />
              Batch
            </div>
          </div>
        </div>
        <div className={styles.demoBookingCalendar}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={dayjs(newDate)}
              onChange={(date: any) => {
                setNewDate(date);
                handleDateChange(date);
              }}
              displayStaticWrapperAs="desktop"
              disablePast
              disabled={dateDisable}
              onMonthChange={(newMonth) => {
                handleMonthChange(newMonth);
              }}
              className={`${styles.demoCalander} custom-calendar-button`}
              sx={{
                "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "#fff",
                  border: "1px solid #11C89B",
                  color: "#11C89B",
                },
                "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled":
                  {
                    backgroundColor: "#fff",
                    border: "1px solid #11C89B",
                    color: "#11C89B",
                  },
                "& .MuiButtonBase-root.MuiPickersDay-today": {
                  border: "0px",
                  color: "#11C89B",
                },
                "& .MuiButtonBase-root.MuiPickersDay-today.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.38)",
                },
                "& .MuiButtonBase-root.MuiPickersDay-today.Mui-selected": {
                  border: "1px solid #11C89B",
                  color: "#11C89B",
                },
                "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.batch-day":
                  {
                    color: "#11C89B",
                    backgroundColor: "rgba(17, 200, 155, 0.16)",
                  },
                "& .MuiButtonBase-root.MuiPickersDay-root.batch-day": {
                  backgroundColor: "rgba(17, 200, 155, 0.16)",
                },
                "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled":
                  {
                    backgroundColor: "#fff",
                    color: "rgba(0, 0, 0, 0.38)",
                  },
                "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled.Mui-selected":
                  {
                    backgroundColor: "#fff",
                    border: "1px solid #11C89B",
                    color: "#11C89B",
                  },
                "& .MuiButtonBase-root.MuiPickersDay-root.demo-day": {
                  position: "relative",
                },
                "& .MuiButtonBase-root.MuiPickersDay-root.demo-day.Mui-selected::after":
                  {
                    position: "absolute",
                    width: "3px",
                    height: " 3px",
                    background: "#11c89b",
                    borderRadius: "50%",
                    bottom: "4px",
                    content: '""',
                  },
                "& .MuiButtonBase-root.MuiPickersDay-root.demo-day::after": {
                  position: "absolute",
                  width: "3px",
                  height: " 3px",
                  background: "#11c89b",
                  borderRadius: "50%",
                  bottom: "4px",
                  content: '""',
                },
                "& .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled.demo-day::after":
                  {
                    display: "none",
                  },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className={styles.demoBookingRight}>
        {stage === 0 && (
          <>
            {selectedLeadData?.overViewData?.demos?.length === 0 ? (
              <div className={styles.noDemoScreen}>
                <div className={styles.noDemoScreenDesc}>
                  Elevate your lead nurturing strategy with a Demo class!
                </div>

                <Tooltip
                  arrow
                  title={hasLeadAccess === false ? "Oops! No permission." : ""}
                  PopperProps={{ style: { zIndex: 9999999 } }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        zIndex: "9999999",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                          zIndex: "9999999",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: 14,
                        textTransform: "capitalize",
                        boxShadow: "none",
                        height: "38px",
                      }}
                      size={"small"}
                      onClick={() => setStage(1)}
                      disabled={hasLeadAccess === false ? true : false}
                    >
                      Book a Demo
                    </Button>
                  </span>
                </Tooltip>
              </div>
            ) : (
              <DemoBookedScreen
                setStage={setStage}
                demos={selectedLeadData?.overViewData?.demos}
                DeleteDemoBookedForLead={DeleteDemoBookedForLead}
                deleteDemoBookedLoading={deleteDemoBookedLoading}
                deleteDemoBookedSuccess={deleteDemoBookedSuccess}
                handleDemoEdit={handleDemoEdit}
                handleCancelModalOpen={handleCancelModalOpen}
                batchList={demoByCourseAndInstitute?.batches}
                demosByCourse={demosByCourse}
                hasLeadAccess={hasLeadAccess}
              />
            )}
          </>
        )}

        {stage === 1 && (
          <>
            {ongoingBatches &&
            ongoingDemos &&
            (filteredOngoingDemos?.length > 0 ||
              filteredOngoingBatches?.length > 0) ? (
              <PickDemo
                employees={employees}
                setWay={setWay}
                setStage={setStage}
                handleWaySelect={handleWaySelect}
                demos={filteredOngoingDemos}
                batches={filteredOngoingBatches}
                selectedDate={selectedDate}
                handleBatchSelect={handleBatchSelect}
                handleDemoSelect={handleDemoSelect}
                setReschedule={setReschedule}
                reschedule={reschedule}
                editDemoId={demoDetails?.id}
                editDemoBatchId={editBatchId}
                editDemoDate={editBatchDate}
                handlePickDemoBack={handlePickDemoBack}
                batchList={demoByCourseAndInstitute?.batches}
                demosByCourse={demosByCourse}
                hasLeadAccess={hasLeadAccess}
              />
            ) : (
              <PickTime
                setWay={setWay}
                setStage={setStage}
                handleWaySelect={handleWaySelect}
                hasLeadAccess={hasLeadAccess}
              />
            )}
          </>
        )}
        {stage === 2 && (
          <>
            {way === 1 && (
              <DemoSelectedConfirm
                employees={employees}
                setWay={setWay}
                setStage={setStage}
                demoSelected={demoSelected}
                batchSelected={batchSelected}
                selectedDate={selectedDate}
                setDateDisable={setDateDisable}
                demoDetails={demoDetails}
                CreateDemo={CreateDemo}
                createDemoLoading={createDemoLoading}
                setDemoDetails={setDemoDetails}
                EditDemo={EditDemo}
                reschedule={reschedule}
                createDemoSuccess={createDemoSuccess}
                handleLastStageBack={handleLastStageBack}
                demosByCourse={demosByCourse}
              />
            )}
            {way === 2 && (
              <AddNewDemo
                selectedLeadData={selectedLeadData}
                employees={employees}
                setWay={setWay}
                setStage={setStage}
                demoDetails={demoDetails}
                setDemoDetails={setDemoDetails}
                demoTypes={demoTypes?.filter(
                  (item: any) =>
                    item.courseTypeName?.toLowerCase() === "classroom"
                )}
                setDateDisable={setDateDisable}
                CreateBatch={CreateBatch}
                selectedDate={selectedDate}
                batchCreateLoading={batchCreateLoading}
                handleLastStageBack={handleLastStageBack}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DemoBooking;
