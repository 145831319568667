import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import styles from "./AddTask.module.css";
import React, { useState, useEffect } from "react";
import TaskTypeSelect from "../TaskTypeSelect/TaskTypeSelect";
import UserAssign from "../../../../../components/UserAssign/UserAssign";
import FlagAssign from "../../../../../components/FlagAssign/FlagAssign";
import LeadDatePicker from "../LeadDatePicker/LeadDatePicker";
import { useAuthUser } from "react-auth-kit";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { getCombinedAssignees } from "../../../../../components/CommonFunctions/CommonFunctions";

export default function AddTask(props: any) {
  const {
    CreateTask,
    employees,
    leadById,
    handleCancelClick,
    taskTypes,
    fromTaskEdit,
    taskForEditing,
    EditTask,
    type,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();

  const { createTaskLoading, editTaskLoading } = useSelector(
    (state: any) => state.nurture
  );

  const combinedEmployees = getCombinedAssignees(employees, leadById?.branchId);
  const activeEmployees = combinedEmployees?.filter((item: any) => {
    const isActiveAndHasLeadCardAccess =
      item.status === "ACTIVE" && item?.leadCardAccess === true;

    return isActiveAndHasLeadCardAccess;
  });

  const [taskDetails, setTaskDetails] = React.useState({
    description: fromTaskEdit ? taskForEditing?.description : "",
    enquiryId: "",
    swimlaneState: "",
    mentionedIds: [],
    createdById: "",
    createdByName: "",
    instituteId: "",
    instituteName: "",
  });

  const [addTask, setAddTask] = useState("");
  const [assigny, setAssigny] = useState<any>("");
  const [priority, setPriority] = useState<any>("NORMAL");
  const [date, setDate] = useState<any>();
  const [taskType, setTaskType] = useState<string>("");

  const [errorTaskType, setErrorTaskType] = useState<boolean>(false);
  const [errorAssigny, setErrorAssigny] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [errorPriority, setErrorPriority] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<boolean>(false);
  const [errorDatePast, setErrorDatePast] = useState<boolean>(false);

  const handleLeadPriority = React.useCallback(
    (leadId: any, leadPriority: any) => {
      setPriority(leadPriority);
    },

    [setPriority]
  );

  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      setAssigny(user);
    },

    [setAssigny]
  );

  const handleAddTask = (event: any) => {
    setAddTask(event.target.value);
  };

  const handleCancel = () => {
    setAddTask("");
  };

  const handleSaveClick = () => {
    taskType === "" ? setErrorTaskType(true) : setErrorTaskType(false);
    assigny === "" ? setErrorAssigny(true) : setErrorAssigny(false);
    priority === "" ? setErrorPriority(true) : setErrorPriority(false);
    date === "" || date === undefined
      ? setErrorDate(true)
      : setErrorDate(false);
    addTask === "" ? setErrorDescription(true) : setErrorDescription(false);

    let currentDate = new Date();
    let selectedDate = new Date(date ?? 0);

    selectedDate.setSeconds(0, 0);

    let currentEditedDate = new Date();
    let selectedEditedDate = new Date(date ?? 0);
    let isPast =
      selectedDate < currentDate || selectedEditedDate < currentEditedDate;
    if (isPast) {
      setErrorDatePast(true);
    } else {
      setErrorDatePast(false);
    }

    let unixTimestamp = selectedDate.getTime();

    let body = {
      description: addTask,
      taskType: taskType,
      studentName: `${leadById?.studentFirstName} ${leadById?.studentLastName}`,
      assignedToName: assigny?.firstName + " " + assigny?.lastName,
      assignedToId: assigny?.id,
      enquiryId: leadById?.id,
      swimlaneState: leadById?.enquirySwimlaneStatus,
      priority: priority,
      date: unixTimestamp,
      status: "TO_DO",
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      mentionedIds: taskDetails?.mentionedIds,
      instituteId: authUser?.institute[0]?.instituteDetails?.id,
      instituteName: authUser?.institute[0]?.instituteDetails?.instituteName,
      studentContact: leadById?.studentContact,
      studentEmail: leadById?.studentEmail,
    };
    let updateBody = {
      assignedToId: assigny?.id,
      studentName: `${leadById?.studentFirstName} ${leadById?.studentLastName}`,
      status: "TO_DO",
      activityId: taskForEditing?.activityId,
      entitytype: taskForEditing?.entitytype,
      date: unixTimestamp,
      enquiryId: taskForEditing?.enquiryId,
      taskCompletedDate: taskForEditing?.taskCompletedDate,
      id: taskForEditing?.id,
      enquirySwimlaneStatus: taskForEditing?.enquirySwimlaneStatus,
      priority: priority,
      creationDate: taskForEditing?.creationDate,
      taskType: taskType,
      assignedToName: assigny?.firstName + " " + assigny?.lastName,
      description: addTask,
      updatedById: authUser?.institute?.[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
      mentionedIds: taskDetails?.mentionedIds,
      instituteId: authUser?.institute[0]?.instituteDetails?.id,
      instituteName: authUser?.institute[0]?.instituteDetails?.instituteName,
      studentContact: leadById?.studentContact,
      studentEmail: leadById?.studentEmail,
    };

    if (fromTaskEdit) {
      addTask !== "" &&
        assigny !== "" &&
        priority !== "" &&
        date !== "" &&
        date !== undefined &&
        taskType !== "" &&
        selectedEditedDate > currentEditedDate &&
        EditTask(updateBody);
    } else {
      addTask !== "" &&
        assigny !== "" &&
        priority !== "" &&
        date !== "" &&
        date !== undefined &&
        taskType !== "" &&
        selectedDate > currentDate &&
        CreateTask(body);
    }
  };

  function getNameById(id: any) {
    const employee = employees?.find((e: any) => e.id === id);
    return employee
      ? `${
          employee.firstName.charAt(0).toUpperCase() +
          employee.firstName.slice(1)
        } ${
          employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)
        }`
      : "Unknown";
  }

  const modifiedActiveEmployees = activeEmployees?.map((employee: any) => ({
    id: employee.id,
    display: `${employee.firstName} ${employee.lastName}`,
  }));

  useEffect(() => {
    const userFromLocalStorage = authUser;
    if (fromTaskEdit) {
      const user = employees.find(
        (item: any) => item?.id === taskForEditing?.assignedToId
      );
      const task = taskTypes.find(
        (item: any) => item?.id === taskForEditing?.taskTypeId
      );
      setAssigny(user);
      setPriority(taskForEditing?.priority);
      setTaskType(task?.taskTypeName);
      setAddTask(taskForEditing?.description);
      const previousDate: any = taskForEditing?.date;
      setDate(new Date(previousDate));
      setTaskDetails({
        ...taskDetails,
        mentionedIds: taskForEditing?.mentionedIds,
      });
    } else {
      if (employees && userFromLocalStorage) {
        const user = employees.find(
          (item: any) =>
            item.email.toLowerCase() ===
            userFromLocalStorage.email.toLowerCase()
        );
        setAssigny(user);
      }
    }
  }, [setAssigny, employees, fromTaskEdit]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        marginBottom: "10px",
        paddingTop: "14px",
      }}
    >
      <div
        style={{
          borderRadius: "4px",
          border: "1px solid #E8E8E8",
          margin: "0px 24px 8px 24px",
        }}
      >
        <div
          style={{
            padding: "10px ",
            position: "relative",
          }}
        >
          <MentionsInput
            value={addTask}
            onChange={handleAddTask}
            placeholder="Add Description"
            style={{
              control: {
                backgroundColor: "#fff",
                fontSize: "14px",
                color: "#69696B",
                height: "auto",
                minHeight: type === "listview" ? "60px" : "160px",
                maxHeight: type === "listview" ? "60px" : "340px",
                overflow: "auto",
                border: "none",
              },
              highlighter: {
                overflow: "hidden",
              },
              input: {
                margin: 0,
                border: "none",
                outline: "none",
              },
              "&singleLine": {
                margin: 0,
              },
              suggestions: {
                width: "222px",
                zIndex: 2,
                overflow: "auto",
                maxHeight: "140px",
                backgroundColor: "#fff",
                border: "1px solid #F0F0F0",
                borderRadius: "6px",
                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
                fontSize: "14px",
                position: "absolute",
                padding: "10px",
                color: "#142C44",
                fontWeight: "500",
                lineHeight: "16px",
                top:
                  addTask?.toString()?.split("\n")?.length <= 1
                    ? 0
                    : addTask?.toString()?.split("\n")?.length === 2
                    ? "10%"
                    : addTask?.toString()?.split("\n")?.length === 3
                    ? "30%"
                    : addTask?.toString()?.split("\n")?.length === 4
                    ? "40%"
                    : addTask?.toString()?.split("\n")?.length === 5
                    ? "50%"
                    : addTask?.toString()?.split("\n")?.length === 6
                    ? "60%"
                    : "100%",
              },
            }}
          >
            <Mention
              data={modifiedActiveEmployees}
              trigger="@"
              markup="@{{__id__}}"
              style={{
                backgroundColor: "#F5F5F7",
                padding: "5px",
                borderRadius: "20px",
              }}
              displayTransform={(id: any, firstName: any) => {
                return "@" + getNameById(id);
              }}
              onAdd={(id: any) => {
                setTaskDetails((prevDetails: any) => {
                  if (!prevDetails.mentionedIds.includes(id)) {
                    return {
                      ...prevDetails,
                      mentionedIds: [...prevDetails.mentionedIds, id],
                    };
                  }
                  return prevDetails;
                });
              }}
              renderSuggestion={(suggestion: any) => (
                <div
                  style={{
                    paddingBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "34px",
                      height: "34px",
                      fontSize: "16px",
                      alignItems: "center",
                      textTransform: "capitalize",
                      fontWeight: "500",
                      background: "#F5F5F7",
                      border: "1px solid #EEEEF4",
                      color: "#667085",
                    }}
                  >
                    <Box>{suggestion?.display?.charAt(0)}</Box>
                  </Avatar>
                  {suggestion?.display}
                </div>
              )}
            />
          </MentionsInput>
        </div>

        <div className={styles.activityArea}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #F5F5F7",
              padding: "8px 8px",
            }}
          >
            <div className={styles.icons}>
              <TaskTypeSelect
                taskType={(type: string) => {
                  setTaskType(type);
                }}
                taskTypes={taskTypes}
                selectedTaskType={taskType}
              />

              <div className={styles.iconImage}>
                <UserAssign
                  users={combinedEmployees}
                  handleLeadAssignee={handleLeadAssignee}
                  selectedAssignyId={assigny?.id}
                />
              </div>
              <div className={styles.iconImage}>
                <FlagAssign
                  handleLeadPriority={handleLeadPriority}
                  selectedPriority={priority}
                />
              </div>
              <div className={styles.iconImage}>
                <LeadDatePicker
                  setDate={setDate}
                  date={date}
                  setErrorDatePast={setErrorDatePast}
                />
              </div>
            </div>
          </Box>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "end",
          gap: "10px",
          paddingBottom: "4px",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
          }}
        >
          {errorTaskType ||
          errorAssigny ||
          errorDate ||
          errorPriority ||
          errorDescription ? (
            <div
              style={{
                color: "red",
                padding: "5px 0 8px 0",
                fontSize: "14px",
              }}
            >
              Please fill required details
            </div>
          ) : (
            <></>
          )}
          {errorDatePast ? (
            <div
              style={{
                color: "red",
                padding: "5px 0 8px 0",
                fontSize: "14px",
              }}
            >
              &nbsp; Please select future time
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.cancelSave}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleCancel();
              handleCancelClick();
            }}
            sx={{
              textTransform: "capitalize",
              color: "#9A9EA6",
              border: "1px solid #E3E3E3",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            sx={{
              marginLeft: 1,
              textTransform: "capitalize",
            }}
            disabled={
              (!fromTaskEdit && createTaskLoading) ||
              (fromTaskEdit && editTaskLoading)
            }
          >
            {(!fromTaskEdit && createTaskLoading) ||
            (fromTaskEdit && editTaskLoading) ? (
              <CircularProgress size={20} color={"inherit"} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
