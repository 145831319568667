import {
  Box,
  FormGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./LeadReportCreation.module.css";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import CustomFilterAccordion from "../../../../../components/CustomFilterAccordion/CustomFilterAccordion";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import DonutChart from "../../../../../components/DonutChart/DonutChart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import greenDot from "../../../../../assets/images/Ellipse 2842.svg";
import redDot from "../../../../../assets/images/Ellipse 2843.svg";
import { useLocation, useNavigate } from "react-router-dom";
import IconDropdown from "../../../../../components/Dropdowns/IconDropdown";
import SaveReportPopup from "../SaveReportPopup/SaveReportPopup";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import absent from "../../../../../assets/images/abs.svg";
import present from "../../../../../assets/images/pres.svg";
import backIcon from "../../../../../assets/images/back-btn-arrow.svg";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import {
  getBatchById,
  resetBatchById,
} from "../../../Manage/ManageBatch/service/Batchslice";
import {
  resetBatchListAndSingleBatchData,
  resetDownloadAttendanceReportSuccess,
} from "../../service/attendanceReportCreationSlice";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ListPopover from "../../../../../components/ListPopover/ListPopover";
import CourseDetailSkeleton from "../../../../../components/Skeletons/Course/CourseDetailSkeleton";
import BatchSkeleton from "../../../../../components/Skeletons/BatchSkeleton";
import SplitSkeleton from "../../../../../components/Skeletons/SplitSkeleton";
import CourseDescriptionSkeleton from "../../../../../components/Skeletons/Course/CourseDescriptionSkeleton";
import AttendanceReportsSkeleton from "../../../../../components/Skeletons/Reports/AttendanceReportsSkeleton";
import AttendanceReportsStudentSkeleton from "../../../../../components/Skeletons/Reports/AttendanceReportsStudentSkeleton";
import { DownloadReport } from "../../service/reports.request";

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "18px",
  fontSize: "16px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: 150,
}));

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "12px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: '150px'
  paddingTop: "15px",
  paddingBottom: "10px",
}));

const AttendanceReportCreation = (props: any) => {
  const {
    GetBatchList,
    GetReportForSingleBatch,
    getAttendanceReportDataForSingleBatch,
    // DownloadReport,
    downloadAttendanceReportData,
    SaveReport,
    downloadAttendanceReportSuccess,
    getAttendanceReportDataForSingleBatchLoading,
  } = props;
  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const batchList = useSelector(
    (state: any) => state.attendanceReportCreation.batchListForAttendance
  );
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [branchSearchTerm, setBranchSearchTerm] = React.useState<string>("");
  const [courseSearchTerm, setCourseSearchTerm] = React.useState("");
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const [startDate, setStartDate] = React.useState<any>(dayjs().unix() * 1000);
  const [endDate, setEndDate] = React.useState<any>(dayjs().unix() * 1000);
  const navigate = useNavigate();
  const [openSaveReportPopup, setOpenSaveReportPopup] = useState(false);
  const [discardCancel, setDiscardCancel] = useState<boolean>(false);
  const [isViewBatchAttendanceVisible, setIsViewBatchAttendanceVisible] =
    useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const batch = useSelector((state: any) => state?.batch?.batchById);
  const [reportName, setReportName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [loading, setLoading] = useState(false);
  const { getLatestReports } = useSelector(
    (state: any) => state?.websocketNotifications
  );
  const [cancel, setCancel] = useState(false);
  const isCompress = useSelector((state: any) => state.compress.isCompress);
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [batchAnchorEl, setBatchAnchorEl] = useState(null);
  const batchFieldRef = useRef<HTMLInputElement | null>(null);
  const openBatch = Boolean(batchAnchorEl);
  const [searchBatch, setSearchBatch] = useState<any>("");
  const batchByIdLoading = useSelector(
    (state: any) => state?.batch?.batchByIdLoading
  );

  const handleCloseBranchPopover = () => {
    setBatchAnchorEl(null);
  };

  const auth = useAuthUser();
  const user = auth();
  const location = useLocation();
  const dispatch = useDispatch();

  const filteredBranches =
    user?.institute[0]?.role === "Admin"
      ? branches &&
        branches
          ?.filter((d: any) => d?.id === user?.institute[0]?.branchId)
          ?.filter((branch: any) => {
            const searchTermMatch =
              branch.branchName
                .toLowerCase()
                .indexOf(branchSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          })
      : branches?.filter((branch: any) => {
          const searchTermMatch =
            branch.branchName
              .toLowerCase()
              .indexOf(branchSearchTerm.toLowerCase()) !== -1;

          return searchTermMatch;
        });

  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName
          .toLowerCase()
          .indexOf(courseSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const filteredBranch = branches?.filter((employee: any) => {
    const searchTermMatch =
      employee.branchName
        .toLowerCase()
        .indexOf(branchSearchTerm.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const filteredBatch = batchList?.map((d: any) => {
    return {
      batchName: d?._source?.batchName,
      id: d?._source?.id,
      branchId: d?._source?.branchId,
    };
  });

  useEffect(() => {
    if (location?.state?.isFromView === true) {
      dispatch<any>(getBatchById(location?.state?.data?.batchId));
      setSelectedBatch(batch);
    }
  }, []);

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      const dateMilliseconds = dateUnix * 1000;
      if (id === "startDate") {
        setStartDate(dateMilliseconds);
      } else if (id === "endDate") {
        setEndDate(dateMilliseconds);
      }
    }
  };

  const onDiscardClose = () => {
    setDiscardCancel(false);
  };
  const noDiscardButtonClick = () => {
    setDiscardCancel(false);
  };
  const yesDiscardButtonClick = () => {
    setDiscardCancel(false);
  };

  useEffect(() => {
    if (getLatestReports) {
      setLoading(false);
      location?.state?.from === "RecentReports"
        ? navigate("/app/Reports/RecentReports")
        : location?.state?.from === "CreatedByMe"
        ? navigate("/app/Reports/CreatedByMe")
        : navigate("/app/Reports/AllReports");
    }
  }, [getLatestReports]);

  // const handleFilterBranchChecked = (id: string, branchName: string) => {
  //   if (branchName === "All") {
  //     if (
  //       checkedBranches.length ===
  //       [{ id: "0", branchName: "All" }, ...filteredBranches].length
  //     ) {
  //       setCheckedBranches([]);
  //     } else {
  //       const alllBranches = filteredBranches?.map((d: any) => {
  //         return { id: d?.id, branchName: d?.branchName };
  //       });
  //       setCheckedBranches([...alllBranches, { id: "0", branchName: "ALL" }]);
  //     }
  //   } else {
  //     const branchToAdd: CheckedBranch = { id, branchName };
  //     const isAllSelected = checkedBranches.some(
  //       (branch) => branch.branchName === "ALL"
  //     );

  //     if (checkedBranches.some((branch) => branch.id === id)) {
  //       const updatedBranches = checkedBranches.filter(
  //         (branch) => branch.id !== id
  //       );
  //       if (isAllSelected) {
  //         setCheckedBranches(
  //           updatedBranches.filter((branch) => branch.branchName !== "ALL")
  //         );
  //       } else {
  //         setCheckedBranches(updatedBranches);
  //       }
  //     } else {
  //       setCheckedBranches([
  //         ...checkedBranches,
  //         branchToAdd,
  //         // { id: "0", branchName: "ALL" },
  //       ]);
  //     }
  //   }
  // };

  // const handleFilterBranchChecked = (id: string, branchName: string) => {
  //   if (branchName === "All") {
  //     const allAvailableBranches = [
  //       { id: "0", branchName: "ALL" },
  //       ...branches.map((branch: any) => ({
  //         id: branch.id,
  //         branchName: branch.branchName,
  //       })),
  //     ];

  //     if (checkedBranches.length === allAvailableBranches.length) {
  //       setCheckedBranches([]); // Uncheck all
  //     } else {
  //       setCheckedBranches(allAvailableBranches); // Select all
  //     }
  //   } else {
  //     const branchToAdd: CheckedBranch = { id, branchName };
  //     const isAllSelected = checkedBranches.some(
  //       (branch) => branch.branchName === "ALL"
  //     );

  //     if (checkedBranches.some((branch) => branch.id === id)) {
  //       const updatedBranches = checkedBranches.filter(
  //         (branch) => branch.id !== id
  //       );
  //       if (isAllSelected) {
  //         setCheckedBranches(
  //           updatedBranches.filter((branch) => branch.branchName !== "ALL")
  //         );
  //       } else {
  //         setCheckedBranches(updatedBranches);
  //       }
  //     } else {
  //       setCheckedBranches([...checkedBranches, branchToAdd]);
  //     }
  //   }
  // };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    // Determine the branches to work with based on the user's role
    const isAdmin = user?.institute[0]?.role === "Admin";
    const filteredBranches = isAdmin
      ? branches?.filter(
          (branch: any) => branch?.id === user?.institute[0]?.branchId
        )
      : branches;

    if (branchName === "All") {
      const allAvailableBranches = [
        { id: "0", branchName: "ALL" },
        ...filteredBranches.map((branch: any) => ({
          id: branch.id,
          branchName: branch.branchName,
        })),
      ];

      if (checkedBranches.length === allAvailableBranches.length) {
        setCheckedBranches([]); // Uncheck all
      } else {
        setCheckedBranches(allAvailableBranches); // Select all
      }
    } else {
      const branchToAdd: CheckedBranch = { id, branchName };
      const isAllSelected = checkedBranches.some(
        (branch) => branch.branchName === "ALL"
      );

      if (checkedBranches.some((branch) => branch.id === id)) {
        const updatedBranches = checkedBranches.filter(
          (branch) => branch.id !== id
        );
        if (isAllSelected) {
          setCheckedBranches(
            updatedBranches.filter((branch) => branch.branchName !== "ALL")
          );
        } else {
          setCheckedBranches(updatedBranches);
        }
      } else {
        setCheckedBranches([...checkedBranches, branchToAdd]);
      }
    }
  };

  // const handleFilterCourseChecked = (id: string, courseName: string) => {
  //   if (courseName === "All") {
  //     if (
  //       checkedCourses.length ===
  //       [{ id: "0", courseName: "All" }, ...filteredCourses].length
  //     ) {
  //       setCheckedCourses([]);
  //     } else {
  //       const allCourse = filteredCourses?.map((d: any) => {
  //         return { id: d?.id, courseName: d?.branchName };
  //       });
  //       setCheckedCourses([...allCourse, { id: "0", courseName: "ALL" }]);
  //     }
  //   } else {
  //     const courseToAdd: CheckedCourse = { id, courseName };
  //     const isAllSelected = checkedCourses.some(
  //       (branch) => branch.courseName === "ALL"
  //     );

  //     if (checkedCourses.some((branch) => branch.id === id)) {
  //       const updatedCourses = checkedCourses.filter(
  //         (course) => course.id !== id
  //       );
  //       if (isAllSelected) {
  //         setCheckedCourses(
  //           updatedCourses.filter((course: any) => course.courseName !== "ALL")
  //         );
  //       } else {
  //         setCheckedCourses(updatedCourses);
  //       }
  //     } else {
  //       setCheckedCourses([...checkedCourses, courseToAdd]);
  //     }
  //   }
  // };

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    if (courseName === "All") {
      const allAvailableCourses = [
        { id: "0", courseName: "ALL" },
        ...courses.map((course: any) => ({
          id: course.id,
          courseName: course.branchName,
        })),
      ];

      if (checkedCourses.length === allAvailableCourses.length) {
        setCheckedCourses([]); // Uncheck all
      } else {
        setCheckedCourses(allAvailableCourses); // Select all
      }
    } else {
      const courseToAdd: CheckedCourse = { id, courseName };
      const isAllSelected = checkedCourses.some(
        (branch) => branch.courseName === "ALL"
      );

      if (checkedCourses.some((branch) => branch.id === id)) {
        const updatedCourses = checkedCourses.filter(
          (course) => course.id !== id
        );
        if (isAllSelected) {
          setCheckedCourses(
            updatedCourses.filter((course: any) => course.courseName !== "ALL")
          );
        } else {
          setCheckedCourses(updatedCourses);
        }
      } else {
        setCheckedCourses([...checkedCourses, courseToAdd]);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.data === null) {
      reset();
      dispatch(resetBatchListAndSingleBatchData());
      dispatch(resetBatchById());
    }
  }, [courses, branches]);

  const reset = () => {
    const alllBranches =
      (filteredBranches &&
        filteredBranches?.map((d: any) => {
          return { id: d?.id, branchName: d?.branchName };
        })) ||
      [];
    setCheckedBranches([...alllBranches, { id: "0", branchName: "ALL" }]);

    const alllCourse =
      (filteredCourses &&
        filteredCourses?.map((d: any) => {
          return { id: d?.id, courseName: d?.courseName };
        })) ||
      [];
    setCheckedCourses([...alllCourse, { id: "0", courseName: "ALL" }]);
  };

  const handleFilterApply = () => {
    console.log(checkedCourses);
    const hasAllBranches = checkedBranches?.some(
      (branch: any) => branch?.branchName === "ALL"
    );
    const hasAllCourses = checkedCourses.some(
      (course: any) => course.courseName === "ALL"
    );

    const startDateBatch: any = new Date(startDate);
    const startDateForBatch = startDateBatch.setUTCHours(0, 0, 0, 0);
    const endDateBatch: any = new Date(endDate);
    const endDateForBatch = endDateBatch.setUTCHours(0, 0, 0, 0);

    const data = {
      entityName: "BATCH",
      instituteId: user?.institute[0]?.instituteId,
      isFilter: true,
      courseId: hasAllCourses ? "" : checkedCourses?.map((d: any) => d?.id),
      branchId:
        user?.institute[0]?.role !== "Super Admin"
          ? checkedBranches
              ?.filter((d: any) => d?.id !== "0")
              ?.map((d: any) => d?.id)
          : hasAllBranches
          ? ""
          : checkedBranches?.map((d: any) => d?.id),
      startDate: startDateForBatch,
      endDate: endDateForBatch,
    };
    if (user?.institute[0]?.role === "Super Admin") {
      if (data?.branchId === null || data?.branchId === undefined) {
        dispatch(
          setToastNotification({
            message: "Please select branch filter",
            type: "error",
            snackOpen: true,
          })
        );
      } else if (data?.courseId === null || data?.courseId === undefined) {
        dispatch(
          setToastNotification({
            message: "Please select course filter",
            type: "error",
            snackOpen: true,
          })
        );
      } else {
        GetBatchList(data);
      }
    } else {
      if (data?.branchId?.length === 0) {
        dispatch(
          setToastNotification({
            message: "Please select branch filter",
            type: "error",
            snackOpen: true,
          })
        );
      } else if (
        hasAllCourses ? data?.courseId !== "" : data?.courseId?.length === 0
      ) {
        dispatch(
          setToastNotification({
            message: "Please select course filter",
            type: "error",
            snackOpen: true,
          })
        );
      } else {
        GetBatchList(data);
      }
    }
  };

  const handleDownload = () => {
    const data = {
      action: "DOWNLOAD",
      reportType: "ATTENDANCE",
      reportData: getAttendanceReportDataForSingleBatch,
    };
    DownloadReport(data)
      .then((res: any) => {
        console.log(res);
        const blob = base64ToBlob(
          res?.data?.data?.fileBase64String,
          "application/vnd.ms-excel"
        );
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "attendanceReport.xlsx";
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        dispatch(
          setToastNotification({
            message: "Congratulations! Report is successfully downloaded.",
            type: "success",
            snackOpen: true,
          })
        );
      })
      .catch((err: any) => {});
  };

  const base64ToBlob = (base64Data: string, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  // useEffect(() => {
  //   if (downloadAttendanceReportSuccess) {
  //     const blob = base64ToBlob(
  //       downloadAttendanceReportData?.fileBase64String,
  //       // "application/vnd.ms-excel"
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //     );
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "attendanceReport.xlsx";
  //     document.body.appendChild(link);
  //     link.click();
  //     window.URL.revokeObjectURL(url);
  //     dispatch(resetDownloadAttendanceReportSuccess());
  //   }
  // }, [downloadAttendanceReportSuccess]);

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    dispatch(resetDownloadAttendanceReportSuccess());
    setLoading(true);
    const data = {
      instituteId: user?.institute[0]?.instituteId,
      name: reportName,
      category: "ATTENDANCE",
      description: description,
      reportData: getAttendanceReportDataForSingleBatch,
      createdById: user?.institute[0]?.id,
      createdByName: `${user?.institute[0]?.firstName} ${user?.institute[0]?.lastName}`,
    };
    SaveReport(data);
  };

  const onCancel = () => {
    setCancel(false);
  };

  const noCancelModal = () => {
    setCancel(false);
  };

  const yesCancelModal = () => {
    setCancel(false);
    location?.state?.from === "RecentReports"
      ? navigate("/app/Reports/RecentReports")
      : location?.state?.from === "CreatedByMe"
      ? navigate("/app/Reports/CreatedByMe")
      : navigate("/app/Reports/AllReports");
  };

  const result =
    location?.state?.isFromView === true
      ? location?.state?.data
      : getAttendanceReportDataForSingleBatch;

  const showingInfo =
    location?.state?.isFromView === true
      ? location?.state?.data !== null
      : batch !== null;

  const handleOpenBranchPopover = (event: any) => {
    setBatchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleBatchSelect = (emp: any) => {
    setSelectedBatch(emp);
    handleCloseBranchPopover();
    const startDateBatch: any = new Date(startDate);
    const startDateForBatch = startDateBatch.setUTCHours(0, 0, 0, 0);
    const endDateBatch: any = new Date(endDate);
    const endDateForBatch = endDateBatch.setUTCHours(0, 0, 0, 0);
    const data = {
      reportType: "ATTENDANCE",
      instituteId: user?.institute[0]?.instituteId,
      generateReports: true,
      batchId: emp?.id,
      branchId: emp?.branchId,
      startDate: startDateForBatch,
      endDate: endDateForBatch,
    };

    GetReportForSingleBatch(data);
    dispatch<any>(getBatchById(emp?.id));
  };

  const handleBatchSearch = (event: any) => {
    setSearchBatch(event.target.value);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: "14px 16px",
        background: "#EEEEEE",
        // maxHeight: "calc(100vh - 65px)",
        // position: "relative",
        maxHeight: "90vh",
        position: "relative",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          display: "flex",
          width:
            !isViewBatchAttendanceVisible && isCompress
              ? "92vw"
              : !isViewBatchAttendanceVisible && !isCompress
              ? "84.5vw"
              : isViewBatchAttendanceVisible && isCompress
              ? "78vw"
              : "70vw",
          maxHeight: "100vh",
          minHeight: "100vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            minWidth: "219px",
            borderRight: "1px solid #E9E9E9",
            // overflowY: "hidden",
          }}
          className="datepicker-container"
        >
          <div className={styles.calendarHeader}>
            <div className={styles.headerText}>Custom</div>
          </div>
          <div className={styles.applyClearSection}>
            {location?.state?.isFromView === false && (
              <>
                <div
                  className={styles.clearFilterText}
                  onClick={() => {
                    setCheckedBranches([]);
                    setCheckedCourses([]);
                    dispatch(resetBatchListAndSingleBatchData());
                    dispatch(resetBatchById());
                    setStartDate(dayjs().unix() * 1000);
                    setEndDate(dayjs().unix() * 1000);
                    setSelectedBatch(null);
                  }}
                >
                  Clear All
                </div>
                <div
                  className={styles.applyFilterText}
                  onClick={() => {
                    handleFilterApply();
                    setIsBranchOpen(false);
                    setIsCourseOpen(false);
                    setIsEndDateOpen(false);
                    setIsStartDateOpen(false);
                    setSelectedBatch(null);
                    dispatch(resetBatchById());
                  }}
                >
                  Apply
                </div>
              </>
            )}
          </div>
          <div className={styles.filterContainer}>
            <CustomFilterAccordion
              title="Start Date"
              isOpen={isStartDateOpen}
              setIsOpen={setIsStartDateOpen}
              disabled={location?.state?.isFromView === true}
            >
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      zIndex: "999999999 !important",
                      minHeight: "20px",
                      ".MuiInputBase-root": {
                        fontSize: "12px",
                        minHeight: "20px",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "18px",
                      },
                      marginBottom: "10px",
                    }}
                    label="From"
                    format="DD/MM/YYYY"
                    onChange={(date) => handleDateChange(date, "startDate")}
                    value={
                      startDate !== null ? dayjs.unix(startDate / 1000) : null
                    }
                    maxDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </Box>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="End Date"
              isOpen={isEndDateOpen}
              setIsOpen={setIsEndDateOpen}
              disabled={location?.state?.isFromView === true}
            >
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      // zIndex: "99999999 !important",
                      minHeight: "20px",
                      ".MuiInputBase-root": {
                        fontSize: "12px",
                        minHeight: "20px",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "18px",
                      },
                    }}
                    label="To"
                    format="DD/MM/YYYY"
                    onChange={(date) => handleDateChange(date, "endDate")}
                    value={endDate !== null ? dayjs.unix(endDate / 1000) : null}
                    maxDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </Box>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="Branch"
              isOpen={isBranchOpen}
              setIsOpen={setIsBranchOpen}
              disabled={location?.state?.isFromView === true}
              count={checkedBranches?.length}
            >
              <FilterTypeWrap>
                <FilterSearchField
                  value={branchSearchTerm}
                  onChange={(e: any) => {
                    setBranchSearchTerm(e.target.value);
                  }}
                  placeholder="Search Branch"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {branches &&
                      [
                        { branchName: "All", id: "0" },
                        ...filteredBranches,
                      ]?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedBranches.some(
                            (data) => data.id === item.id
                          )}
                          checkboxFunction={() =>
                            handleFilterBranchChecked(item.id, item.branchName)
                          }
                          label={item.branchName.toLowerCase()}
                          fromReports={true}
                        />
                      ))}
                  </FormGroup>
                </FilterCheckBoxWrap>
              </FilterTypeWrap>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="Course"
              isOpen={isCourseOpen}
              setIsOpen={setIsCourseOpen}
              disabled={location?.state?.isFromView === true}
              count={checkedCourses?.length}
            >
              <FilterTypeWrap>
                <FilterSearchField
                  value={courseSearchTerm}
                  onChange={(e: any) => {
                    setCourseSearchTerm(e.target.value);
                  }}
                  placeholder="Search Course"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {courseList &&
                      [{ courseName: "All", id: "0" }, ...filteredCourses]?.map(
                        (item: any) => (
                          <FilterCheckbox
                            keyVal={item?.id}
                            checked={checkedCourses?.some(
                              (data) => data?.id === item?.id
                            )}
                            checkboxFunction={() =>
                              handleFilterCourseChecked(
                                item.id,
                                item.courseName
                              )
                            }
                            label={item?.courseName?.toLowerCase()}
                            fromReports={true}
                          />
                        )
                      )}
                  </FormGroup>
                </FilterCheckBoxWrap>
              </FilterTypeWrap>
            </CustomFilterAccordion>
          </div>
        </Box>

        <Box
          sx={{
            background: "#fff",
            width: "100%",
          }}
        >
          <>
            {!isViewBatchAttendanceVisible ? (
              <>
                {location?.state?.isFromView ? (
                  <div className={styles.attendanceBatchHeader}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingRight: "20px",
                        color: "#667085",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        location?.state?.from === "RecentReports"
                          ? navigate("/app/Reports/RecentReports")
                          : location?.state?.from === "CreatedByMe"
                          ? navigate("/app/Reports/CreatedByMe")
                          : navigate("/app/Reports/AllReports");
                      }}
                    >
                      <img src={backIcon} style={{ marginRight: "5px" }} /> Back
                    </div>
                    <div className={styles.headerText}>
                      {`${location?.state?.reportName}`}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          color: "rgba(102, 112, 133, 1)",
                          marginRight: "10px",
                        }}
                      >
                        Batch:{" "}
                      </div>

                      <TextField
                        onClick={handleOpenBranchPopover}
                        value={selectedBatch ? selectedBatch?.batchName : ""}
                        variant="outlined"
                        size="small"
                        placeholder="Batch"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root input": {
                            textTransform: "capitalize",
                            color: "#667085",
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          width: "250px",
                        }}
                        inputRef={batchFieldRef}
                        disabled={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.attendanceBatchHeader}>
                    <div className={styles.headerText}>Report-Attendance</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      {batchList?.length > 0 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              color: "rgba(102, 112, 133, 1)",
                              marginRight: "10px",
                            }}
                          >
                            Batch:{" "}
                          </div>

                          <TextField
                            onClick={handleOpenBranchPopover}
                            value={
                              selectedBatch ? selectedBatch?.batchName : ""
                            }
                            variant="outlined"
                            size="small"
                            placeholder="Batch"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root input": {
                                textTransform: "capitalize",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                              width: "250px",
                            }}
                            inputRef={batchFieldRef}
                            // disabled={batchList?.length > 0 ? false : true}
                          />
                          {/* {batchList?.length > 0 && ( */}
                          <ListPopover
                            openState={openBatch}
                            anchorState={batchAnchorEl}
                            handleClosePopover={handleCloseBranchPopover}
                            handleListSearch={handleBatchSearch}
                            popupList={filteredBatch?.filter((item: any) =>
                              item?.batchName
                                .toLowerCase()
                                .includes(searchBatch?.toLowerCase())
                            )}
                            handleItemSelect={handleBatchSelect}
                            listType={"BATCH"}
                          />
                          {/* )} */}
                        </div>
                      )}
                      <CancelBtn
                        onClick={() => {
                          setCancel(true);
                        }}
                      />
                      <SaveBtn
                        onClick={() => {
                          setOpenSaveReportPopup(true);
                        }}
                        disabled={
                          result === null || result?.students?.length === 0
                        }
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <IconDropdown
                          options={[
                            {
                              label: "Download",
                              onClick: () => {
                                handleDownload();
                              },
                              style: { color: "#667085" },
                            },
                          ]}
                          isDownloadButton={true}
                          disabled={
                            result === null || result?.students?.length === 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {showingInfo ? (
                  <div
                    style={{
                      // overflowY: "scroll",
                      height: "78vh",
                    }}
                  >
                    <div className={styles.attendanceContainer}>
                      {batchByIdLoading ? (
                        <div className={styles.leftAttendanceSection}>
                          <AttendanceReportsSkeleton />
                        </div>
                      ) : (
                        <div className={styles.leftAttendanceSection}>
                          <div className={styles.topSection}>
                            <div className={styles.titleContainer}>
                              <div className={styles.leftTitle}>
                                <div className={styles.mainTitle}>
                                  {batch?.batchName}
                                </div>
                                <div className={styles.subTitle}>
                                  {batch?.branchName}
                                </div>
                              </div>
                              <div
                                className={styles.rightTitle}
                                onClick={() => {
                                  setIsViewBatchAttendanceVisible(true);
                                }}
                              >
                                View Batch Attendance
                              </div>
                            </div>
                          </div>
                          <div className={styles.bottomSection}>
                            <div
                              style={{
                                padding: "20px",
                              }}
                            >
                              <div
                                className={styles.batchDetailInfoContainer}
                                style={{ width: "33%" }}
                              >
                                <div className={styles.batchInfoHeader}>
                                  Course
                                </div>
                                <div className={styles.courseName}>
                                  {batch?.courseName}
                                </div>
                              </div>
                              <div className={styles.batchDetailsSection}>
                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Type
                                  </div>
                                  <div className={styles.courseName}>
                                    {batch?.batchType?.map(
                                      (d: any) => d?.batchType?.courseTypeName
                                    )}
                                  </div>
                                </div>
                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Status
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#11C89B",
                                      padding: "2px",
                                      borderRadius: "8px",
                                      color: "#fff",
                                      fontSize: "12px",
                                      width: "70px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {batch?.batchStatus === "ONGOING"
                                      ? "Ongoing"
                                      : batch?.batchStatus === "UPCOMING"
                                      ? "Upcoming"
                                      : batch?.batchStatus === "COMPLETED"
                                      ? "Completed"
                                      : ""}
                                  </div>
                                </div>

                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Conduct Type
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#F2F4F7",
                                      padding: "2px",
                                      borderRadius: "8px",
                                      color: "#344054",
                                      fontSize: "12px",
                                      width: "70px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {batch?.batchConductType === "REGULAR_BATCH"
                                      ? "Regular"
                                      : "Demo"}
                                  </div>
                                </div>
                              </div>
                              <div className={styles.batchDetailsSection}>
                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Min Seats
                                  </div>
                                  <div className={styles.courseName}>
                                    {batch?.minimumSeats}
                                  </div>
                                </div>
                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Max Seats
                                  </div>
                                  <div className={styles.courseName}>
                                    {batch?.maximumSeats}
                                  </div>
                                </div>
                                <div
                                  className={styles.batchDetailInfoContainer}
                                  style={{ width: "33%" }}
                                >
                                  <div className={styles.batchInfoHeader}>
                                    Assignee
                                  </div>
                                  <div
                                    className={styles.courseName}
                                    style={{
                                      fontSize:
                                        // batchDetails?.assignees?.length > 2
                                        // ? "12px"
                                        // :
                                        "14px",
                                    }}
                                  >
                                    {batch?.assignees?.length > 1
                                      ? batch?.assignees
                                          ?.map(
                                            (item: any) => item?.assignedToName
                                          )
                                          .join(", ")
                                      : batch?.assignees[0]?.assignedToName}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.overviewContainer}>
                            <div className={styles.overViewTitle}>
                              Attendance Overview
                            </div>
                            <div className={styles.attendanceGraphContainer}>
                              <div className={styles.graph}>
                                <DonutChart value={result} />
                              </div>
                              <div style={{ display: "flex", gap: 10 }}>
                                <div className={styles.statusContainer}>
                                  <div className={styles.presentHeader}>
                                    <img
                                      src={greenDot}
                                      style={{ marginRight: "5px" }}
                                    />{" "}
                                    Total Present
                                  </div>
                                  <div className={styles.presentCount}>
                                    {result === null
                                      ? "0%"
                                      : `${Number(
                                          result?.totalPresentPercentage
                                        )}%`}
                                  </div>
                                </div>
                                <div className={styles.statusContainer}>
                                  <div className={styles.presentHeader}>
                                    <img
                                      src={redDot}
                                      style={{ marginRight: "5px" }}
                                    />
                                    Total Absent
                                  </div>
                                  <div className={styles.presentCount}>
                                    {result === null
                                      ? "0%"
                                      : `${Number(
                                          result?.totalAbsentPercentage
                                        )}%`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {getAttendanceReportDataForSingleBatchLoading ? (
                        <div className={styles.rightAttendanceSection}>
                          <AttendanceReportsStudentSkeleton />
                        </div>
                      ) : (
                        <div className={styles.rightAttendanceSection}>
                          <div className={styles.attendanceListHeader}>
                            Students(
                            {result?.students?.length > 0
                              ? result?.students?.length
                              : 0}
                            )
                          </div>
                          <div className={styles.attendanceListContainer}>
                            {result?.students?.map((d: any) => {
                              return (
                                <div className={styles.studentNameContainer}>
                                  <div className={styles.studentNameWithIcon}>
                                    <div className={styles.studentIcon}>
                                      {d?.studentName?.charAt(0)}
                                    </div>
                                    <div className={styles.studentName}>
                                      {d?.studentName}
                                    </div>
                                  </div>
                                  <div className={styles.progressBar}>
                                    <CircularProgressbar
                                      value={Number(d?.attendancePercentage)}
                                      text={`${Number(
                                        d?.attendancePercentage
                                      )}%`}
                                      styles={buildStyles({
                                        textSize: "25px",
                                        textColor: "#143C4C",
                                        pathColor: `rgba(59, 212, 175, 1), ${
                                          66 / 100
                                        })`,
                                      })}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <Box
                    sx={{
                      background: "#fff",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#142c44",
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "10px",
                        }}
                      >
                        Steps for Generating attendance report
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#667085",
                        }}
                      >
                        {" "}
                        1-Please Select the filter and apply.
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#667085",
                        }}
                      >
                        2-Select Batch in order to generate attendanceReport.
                      </div>
                    </div>
                  </Box>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color: "#667085",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsViewBatchAttendanceVisible(false);
                  }}
                >
                  <img src={backIcon} style={{ marginRight: "5px" }} /> Back
                </div>
                <div
                  style={{
                    padding: "5px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className={styles.headerText}>Batch Attendance</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SaveBtn
                      onClick={() => {
                        handleDownload();
                      }}
                      buttonText={"Download"}
                      isDownloadButton={true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    // overflowY: "scroll",
                    height: "78vh",
                  }}
                >
                  <div
                    style={{
                      margin: "10px 20px",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        // minWidth: 700,
                        borderRadius: "8px",
                        border: "1px solid #EAECF0",
                        // padding: ' 0px 12px 0px 12px',
                        // margin: " 0px 18px 0px 18px",
                        height: "calc(100vh - 100px)",
                        overflowY: "scroll",
                        boxShadow: "none",
                        flexGrow: 1,
                        width: "100%",
                        maxHeight: "calc(100vh - 220px)",
                        minHeight: "calc(100vh - 220px)",
                      }}
                    >
                      <Table sx={{ minWidth: 700 }}>
                        <TableHead sx={{ background: "#f5f5f5" }}>
                          <TableRow>
                            <StyledTableCellHead
                              sx={{
                                minWidth: 300,
                                padding: "16px",
                                position: "sticky",
                                top: 0,
                                background: "#f5f5f5",
                                zIndex: 1,
                              }}
                            >
                              Name
                            </StyledTableCellHead>
                            <StyledTableCellHead
                              sx={{
                                minWidth: 150,
                                position: "sticky",
                                top: 0,
                                background: "#f5f5f5",
                                zIndex: 1,
                              }}
                            >
                              Total
                            </StyledTableCellHead>
                            {result?.batchSessions?.map(
                              (item: any, index: any) => {
                                const [dateTimestamp, startTime, endTime] =
                                  item.split("_");
                                const date = new Date(
                                  parseInt(dateTimestamp)
                                ).toLocaleDateString("en-GB");
                                const start = moment
                                  .unix(startTime)
                                  .format("HH:mm");
                                const end = moment
                                  .unix(endTime)
                                  .format("HH:mm");

                                return (
                                  <StyledTableCellHead
                                    key={index}
                                    sx={{
                                      minWidth: 150,
                                      position: "sticky",
                                      top: 0,
                                      background: "#f5f5f5",
                                      zIndex: 1,
                                    }}
                                  >
                                    <div>{date}</div>
                                    <div style={{ marginTop: "5px" }}>
                                      {`${start}-
                                        ${end}`}
                                    </div>
                                  </StyledTableCellHead>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result?.students?.map((report: any, index: any) => (
                            <TableRow key={index}>
                              <StyledTableCellBody sx={{ color: "#142C44" }}>
                                {report.studentName}
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                {`${report.totalPresent} /
                                      ${result?.totalSessions}`}
                              </StyledTableCellBody>
                              {result?.batchSessions.map(
                                (sessionId: any, index: any) => (
                                  <StyledTableCellBody>
                                    <img
                                      src={
                                        report?.attendanceRecords[sessionId] ===
                                        "PRESENT"
                                          ? present
                                          : absent
                                      }
                                      alt={
                                        report?.attendanceRecords[sessionId] ===
                                        "PRESENT"
                                          ? "Present"
                                          : "Absent"
                                      }
                                    />
                                  </StyledTableCellBody>
                                )
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </>
            )}
          </>
        </Box>
      </Box>
      {openSaveReportPopup && (
        <SaveReportPopup
          openSaveReportPopup={openSaveReportPopup}
          setOpenSaveReportPopup={setOpenSaveReportPopup}
          setDiscardCancel={setDiscardCancel}
          description={description}
          setDescription={setDescription}
          reportName={reportName}
          setReportName={setReportName}
          handleSaveClick={handleSaveClick}
          saveReportLoading={loading}
        />
      )}
      <CancelModal
        open={discardCancel}
        onClose={onDiscardClose}
        noButtonClick={noDiscardButtonClick}
        yesButtonClick={yesDiscardButtonClick}
        subTitle="There are unsaved changes in your report. If you close the report, these changes are lost."
        title={`Are you sure you want to close?`}
      />
      <CancelModal
        open={cancel}
        onClose={onCancel}
        noButtonClick={noCancelModal}
        yesButtonClick={yesCancelModal}
        subTitle=""
        title={`Do you really want to close the report without saving it!!!`}
      />
    </Box>
  );
};

export default AttendanceReportCreation;
