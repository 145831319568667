import React, { useEffect } from "react";
import styles from "./LeadPopup.module.css";
import AddIcon from "@mui/icons-material/Add";
import closeIcon from "./Assets/popup-close.svg";
import {
  Autocomplete,
  FormControl,
  Paper,
  TextField,
  Button,
  FormHelperText,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { setToastNotification } from "../ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function LeadAddCourse(props: any) {
  const {
    courseList,
    branchList,
    batchList,
    selectedLeadData,
    employees,
    nurtureAddEnquiry,
    leadCreateSuccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );
  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );
  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );
  const leadCreateLoading = useSelector(
    (state: any) => state.nurture.leadCreateLoading
  );
  const isLeadCourseAdd = true;
  const [addCourseOpen, setAddCourseOpen] = React.useState<Boolean>(false);

  const [addCourse, setAddCourse] = React.useState<any>({
    instituteName: "",
    instituteId: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    batchName: "",
    batchId: "",
    checkDuplicate: false,
    studentContact: "",
    contactCountryCode: "91",
    enquiryPriority: "NORMAL",
    enquiryStatus: "NEW_ENQUIRY",
    studentEmail: "",
    enquirySource: "INSTITUTE_WEBSITE",
    messageId: "",
    studentFirstName: "",
    studentLastName: "",
    assignedToName: "",
    assignedToId: "",
    createdById: authUser?.institute?.[0]?.id,
    createdByName: authUser?.firstName + " " + authUser?.lastName,
  });

  const defaultCourse = courseList
    ? courseList.find((course: any) => course.id === addCourse.courseId)
    : "";

  const defaultBranch = branchList
    ? branchList.find((item: any) => item.id === addCourse.branchId)
    : "";

  const defaultBatch = batchList
    ? batchList.find((item: any) => item.id === addCourse.batchId)
    : "";

  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);

  const openAddCourseModal = () => {
    setAddCourseOpen(true);
  };

  const closeAddCourseModal = () => {
    setAddCourseOpen(false);
    setAddCourse({
      ...addCourse,
      branchId: "",
      branchName: "",
      batchId: "",
      batchName: "",
      courseId: "",
      courseName: "",
    });
    setValidStateCourse(true);
    setValidStateBranch(true);
  };

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setAddCourse({
          ...addCourse,
          courseId: value.id,
          courseName: value.courseName,
          batchId: "",
          batchName: "",
        });
        setValidStateCourse(true);
      } else {
        setAddCourse({
          ...addCourse,
          courseId: "",
          courseName: "",
          batchId: "",
          batchName: "",
        });
        setValidStateCourse(false);
      }
    },
    [addCourse]
  );

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setAddCourse({
          ...addCourse,
          branchId: value.id,
          branchName: value.branchName,
          batchId: "",
          batchName: "",
        });
        setValidStateBranch(true);
      } else {
        setAddCourse({
          ...addCourse,
          branchId: "",
          branchName: "",
          batchId: "",
          batchName: "",
        });
        setValidStateBranch(false);
      }
    },
    [addCourse]
  );

  const onBatchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setAddCourse({
          ...addCourse,
          batchId: value.id,
          batchName: value.batchName,
        });
      } else {
        setAddCourse({
          ...addCourse,
          batchId: "",
          batchName: "",
        });
      }
    },
    [addCourse]
  );

  const filteredBatchList =
    batchList &&
    batchList?.filter((batch: any) => {
      const courseIsChecked =
        addCourse.courseId === "" || addCourse.courseId === batch.courseId;

      const branchIsChecked =
        addCourse.branchId === "" || addCourse.branchId === batch.branchId;

      const isActive = batch.status === "ACTIVE";

      return courseIsChecked && branchIsChecked && isActive;
    });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !addCourse.courseId && setValidStateCourse(false);
      !addCourse.branchId && setValidStateBranch(false);

      if (!addCourse.studentFirstName) {
        dispatch(
          setToastNotification({
            message: String("Please enter first name"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.studentContact && !addCourse.studentEmail) {
        dispatch(
          setToastNotification({
            message: String("Please enter either phone number or email"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.courseId) {
        dispatch(
          setToastNotification({
            message: String("Please select course"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.branchId) {
        dispatch(
          setToastNotification({
            message: String("Please select branch"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.enquirySource) {
        dispatch(
          setToastNotification({
            message: String("Please select source"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.enquiryStatus) {
        dispatch(
          setToastNotification({
            message: String("Please select stage"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.assignedToId) {
        dispatch(
          setToastNotification({
            message: String("Please select assignee"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!addCourse.enquiryPriority) {
        dispatch(
          setToastNotification({
            message: String("Please select priority"),
            type: "error",
            snackOpen: true,
          })
        );
      } else {
        nurtureAddEnquiry({
          ...addCourse,
          contactCountryCode: addCourse?.studentContact?.startsWith("971")
            ? "971"
            : addCourse?.studentContact?.startsWith("91")
            ? "91"
            : "91",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const userFromLocalStorage = authUser;
    if (selectedLeadData && employees && userFromLocalStorage) {
      const user = employees.find(
        (item: any) =>
          item.email.toLowerCase() === userFromLocalStorage.email.toLowerCase()
      );
      setAddCourse({
        ...addCourse,
        studentContact: selectedLeadData
          ? selectedLeadData?.studentContact
          : "",
        studentEmail: selectedLeadData ? selectedLeadData?.studentEmail : "",
        studentFirstName: selectedLeadData
          ? selectedLeadData?.studentFirstName
          : "",
        studentLastName: selectedLeadData
          ? selectedLeadData?.studentLastName
          : "",
        instituteId: selectedLeadData ? selectedLeadData?.instituteId : "",
        instituteName: selectedLeadData ? selectedLeadData?.instituteName : "",
        assignedToId: user ? user?.id : "",
        assignedToName: user ? user?.firstName + " " + user?.lastName : "",
      });
    }
  }, [selectedLeadData, addCourseOpen]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const leadModalOpen = urlSearchParams.get("leadModal");

    if (leadCreateSuccess !== true && leadCreateSuccess !== false) {
      if (leadModalOpen) {
        setAddCourseOpen(false);
        setAddCourse({
          ...addCourse,
          branchId: "",
          branchName: "",
          batchId: "",
          batchName: "",
          courseId: "",
          courseName: "",
        });
      }
    }
  }, [leadCreateSuccess]);

  return (
    <div style={{ position: "relative" }}>
      <Tooltip
        arrow
        title={hasLeadAccess === false ? "Oops! No permission." : ""}
        PopperProps={{ style: { zIndex: 9999999 } }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#101828",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 12px",
              zIndex: "9999999",
              "& .MuiTooltip-arrow": {
                color: "#101828",
                zIndex: "9999999",
              },
            },
          },
        }}
      >
        <span
          style={{
            width: "100%",
            display: "block",
            textAlign: "center",
          }}
        >
          <button
            className={styles.addCourseBtn}
            onClick={() => openAddCourseModal()}
            disabled={hasLeadAccess === false ? true : false}
          >
            <AddIcon
              sx={{ marginRight: "10px", fontSize: "18px", color: "667085" }}
            />
            Add New
          </button>
        </span>
      </Tooltip>

      {addCourseOpen && (
        <div className={styles.leadAddCourseWrapper}>
          <div className={styles.leadAddCourseWrapperChild}>
            <div className={styles.popupClsWrap}>
              <button
                className={styles.clsBtn}
                onClick={() => closeAddCourseModal()}
              >
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <div className={styles.popupTitle}>Add New Course</div>
            <form
              className={styles.leadAddCourseFormWrap}
              onSubmit={handleSubmit}
            >
              <div style={{ position: "relative", display: "block" }}>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="formControl"
                  sx={{
                    mb: "14px",
                  }}
                  error={!validStateCourse}
                >
                  <div className={styles.defaultCourseDetailsLabel}>
                    Select Course <span style={{ color: "#EC3939" }}>*</span>
                  </div>

                  {courseList && (
                    <Autocomplete
                      disablePortal={true}
                      id=""
                      options={coursesList}
                      getOptionLabel={(option: any) => option.courseName || ""}
                      fullWidth
                      onChange={onCourseChange}
                      value={defaultCourse || null}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            background: "#FFF",
                            border: "1px solid #E4E4E7",
                            fontFamily: "poppins",
                            fontSize: "14px",
                            textTransform: "capitalize",
                            color: "142C44",
                            fontWeight: "400",
                            padding: "0 4px !important",
                            margin: "0",
                            boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                            maxHeight: "300px",
                            overflowY: "auto",
                          }}
                          {...props}
                        />
                      )}
                      disableClearable={
                        addCourse.courseId === "" ? true : false
                      }
                      sx={{
                        border: "1px solid  #D4D4D8",
                        borderRadius: "6px",
                        textTransform: "capitalize",

                        "&& fieldset": {
                          border: "none",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          {...params}
                          size="small"
                          sx={{
                            textTransform: "capitalize",
                          }}
                        />
                      )}
                      componentsProps={{
                        popper: {
                          sx: {
                            zIndex: 99999999,
                            maxHeight: "300px",
                            overflowY: "scroll",
                            overflowX: "none",
                            transform: "translateY(72px) !important",
                          },
                        },
                      }}
                    />
                  )}
                  {validStateCourse === false && (
                    <FormHelperText error={true}>Field required</FormHelperText>
                  )}
                </FormControl>
              </div>

              <FormControl
                fullWidth
                variant="standard"
                className="formControl"
                sx={{
                  mb: "14px",
                  "& .MuiOutlinedInput-root": {
                    borderColor: !validStateBranch ? "rgb(244 67 54)" : "",
                    "&:hover": {
                      borderColor: !validStateBranch
                        ? "rgb(244 67 54) !important"
                        : "",
                    },
                  },
                  "& .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline ":
                    {
                      borderColor: !validStateBranch ? "rgb(244 67 54) " : "",
                      ":hover": {
                        borderColor: !validStateBranch ? "rgb(244 67 54)" : "",
                      },
                    },
                  ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root":
                    {
                      ":hover": {
                        borderColor: !validStateBranch ? "rgb(244 67 54)" : "",
                      },
                    },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: !validStateBranch ? "rgb(244 67 54)" : "",
                  },
                }}
                error={!validStateBranch}
              >
                <div className={styles.defaultCourseDetailsLabel}>
                  Select Branch <span style={{ color: "#EC3939" }}>*</span>
                </div>

                {branchList && (
                  <Autocomplete
                    disablePortal
                    id=""
                    options={filteredBranchList}
                    getOptionLabel={(option: any) => option.branchName || ""}
                    fullWidth
                    onChange={onBranchChange}
                    value={defaultBranch || null}
                    PaperComponent={(props) => (
                      <Paper
                        sx={{
                          background: "#FFF",
                          border: "1px solid #E4E4E7",
                          fontFamily: "poppins",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          color: "142C44",
                          fontWeight: "400",
                          padding: "0 4px !important",
                          margin: "0",
                          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                          maxHeight: "300px",
                          overflowY: "auto",
                        }}
                        {...props}
                      />
                    )}
                    disableClearable={addCourse.branchId === "" ? true : false}
                    sx={{
                      border: "1px solid  #D4D4D8",
                      borderRadius: "6px",
                      textTransform: "capitalize",
                      "&& fieldset": {
                        border: "none",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                        error={!validStateBranch}
                      />
                    )}
                    componentsProps={{
                      popper: {
                        sx: {
                          zIndex: 99999999,
                          maxHeight: "300px",
                          overflowY: "scroll",
                          overflowX: "none",
                          transform: "translateY(72px) !important",
                        },
                      },
                    }}
                  />
                )}
                {validStateBranch === false && (
                  <FormHelperText error={true}>Field required</FormHelperText>
                )}
              </FormControl>

              {addCourse.courseId !== "" && addCourse.branchId !== "" && (
                <>
                  <FormControl
                    fullWidth
                    variant="standard"
                    className="formControl"
                    sx={{
                      mb: "14px",
                    }}
                  >
                    <div className={styles.defaultCourseDetailsLabel}>
                      Select Batch
                    </div>

                    {batchList && (
                      <Autocomplete
                        disablePortal
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              background: "#FFF",
                              border: "1px solid #E4E4E7",
                              fontFamily: "poppins",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              color: "142C44",
                              fontWeight: "400",
                              padding: "0 4px",
                              margin: "0",
                              boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                              maxHeight: "300px",
                              overflowY: "auto",
                            }}
                            {...props}
                          />
                        )}
                        id=""
                        options={filteredBatchList}
                        getOptionLabel={(option: any) => option.batchName || ""}
                        fullWidth
                        onChange={onBatchChange}
                        value={defaultBatch || null}
                        disableClearable={
                          addCourse.batchId === "" ? true : false
                        }
                        sx={{
                          border: "1px solid  #D4D4D8",
                          borderRadius: "6px",
                          "&& fieldset": {
                            border: "none",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            size="small"
                          />
                        )}
                        componentsProps={{
                          popper: {
                            sx: {
                              zIndex: 99999999,
                              maxHeight: "300px",
                              overflowY: "scroll",
                              overflowX: "none",
                              transform: "translateY(72px) !important",
                            },
                          },
                        }}
                      />
                    )}
                  </FormControl>
                </>
              )}

              <div className={styles.courseEditBtns}>
                <Button
                  fullWidth
                  onClick={() => closeAddCourseModal()}
                  sx={{
                    textTransform: "capitalize",
                    color: "#9A9EA6",
                    borderColor: "#D0D5DD",
                    border: "1px solid",
                    borderRadius: "4PX",
                    ":hover": {
                      color: "#9A9EA6",
                      borderColor: "#D0D5DD",
                      backgroundColor: "#9a9ea612",
                    },
                  }}
                  variant="outlined"
                >
                  cancel
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    border: "1px solid",
                    borderRadius: "4PX",
                    marginBottom: "0",
                    textTransform: "capitalize",
                  }}
                  disabled={leadCreateLoading && isLeadCourseAdd}
                >
                  {leadCreateLoading && isLeadCourseAdd ? (
                    <CircularProgress size={20} color={"inherit"} />
                  ) : (
                    "save"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeadAddCourse;
