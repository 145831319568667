import React, { useState } from "react";
import styles from "./AddComment.module.css";
import CommentEditPopup from "./CommentEditPopup";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { useSelector } from "react-redux";
import getTeamMember from "../../CommonFunctions/CommonFunctions";
import { getSubDomainAccessStatus } from "../../RolePermissionAccess/RolePermissionAccess";
import { getDescriptionWithMentionedNames } from "../../CommonFunctions/CommonFunctions";

function CommentListItem(props: any) {
  const {
    employees,
    commentData,
    setShowAddComment,
    setFromCommentEdit,
    setCommentForEditing,
    DeleteComment,
    leadById,
    setTaskIdForComment,
    taskId,
    commentIdFromUrl,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const { deleteCommentLoading } = useSelector((state: any) => state.nurture);

  const modifiedDescription = getDescriptionWithMentionedNames(
    commentData?.description,
    employees
  ).replace(/\n/g, "<br>");

  const handleEditButtonClick = (val: any) => {
    setShowAddComment(true);
    setCommentForEditing(val);
    setFromCommentEdit(true);
    setTaskIdForComment(taskId);
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [dataForDelete, setDataForDelete] = useState<any>();

  const handleDeleteButtonClick = () => {
    setShowDeletePopup(true);
    setDataForDelete(commentData);
  };

  const handlePopupYesButton = () => {
    if (authUser && authUser?.institute[0]) {
      const data = {
        idsToDelete: [dataForDelete?.id],
        instituteId: authUser?.institute[0]?.instituteId,
        enquiryId: leadById?.id,
        studentName: leadById?.studentFirstName + leadById?.studentLastName,
        studentEmail: leadById?.studentEmail,
        swimlaneState: leadById?.enquirySwimlaneStatus,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      DeleteComment(data);
    }
  };

  const handlePopupNoButton = () => {
    setShowDeletePopup(false);
  };
  const creatorId = authUser?.institute?.[0]?.id;

  return (
    <div className={styles.commentItemWrap} key={commentData?.id}>
      <div className={styles.commentItemBorder}></div>
      <div
        className={styles.commentItemRight}
        style={{
          background:
            commentIdFromUrl === commentData?.id ? "#e8faf6" : "transparent",
          padding:
            commentIdFromUrl === commentData?.id ? "5px 5px 5px 5px" : "0px",
          borderRadius: commentIdFromUrl === commentData?.id ? "4px" : "0px",
          transition: "0.1s ease-in-out",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "5px",
          }}
        >
          <div className={styles.commentItemCreatorDetails}>
            <div className={styles.commentItemCreatorAvatar}>
              {getTeamMember(
                commentData?.createdById,
                employees
              )?.firstName?.charAt(0)}
            </div>
            <div className={styles.commentItemCreatorDetailsRight}>
              <div className={styles.commentItemCreatorName}>
                {getTeamMember(commentData?.createdById, employees)?.firstName +
                  " " +
                  getTeamMember(commentData?.createdById, employees)?.lastName}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <div className={styles.commentItemCreatorDate}>
                  {moment(commentData?.creationDate).format("MMM DD, hh:mm A")}
                </div>
                {commentData?.updatedByName !== "" && (
                  <div className={styles.commentItemCreatorDate}>| Edited</div>
                )}
              </div>
            </div>
          </div>
          <div>
            {creatorId === commentData?.createdById && hasLeadAccess && (
              <>
                <CommentEditPopup
                  handleEditButtonClick={handleEditButtonClick}
                  handleDeleteButtonClick={handleDeleteButtonClick}
                  data={commentData}
                />
                {showDeletePopup && (
                  <DeletePopup
                    type={"comment"}
                    handlePopupYesButton={handlePopupYesButton}
                    handlePopupNoButton={handlePopupNoButton}
                    loading={deleteCommentLoading}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div
          className={styles.commentItemDesc}
          dangerouslySetInnerHTML={{ __html: modifiedDescription }}
        ></div>
      </div>
    </div>
  );
}

export default CommentListItem;
