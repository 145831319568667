import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  BranchEditor,
  BranchListing,
  StaffEditor,
  StaffListing,
  BatchEditor,
  InstituteProfile,
  CourseEditor,
  CourseListing,
  BatchListing,
  CourseDetail,
  ChangePassword,
  UserDetails,
  InstituteProfileDetails,
  NotificationPage,
  Billing,
  ManageSubscription,
  EditAddress,
  DemoBatchEditor,
} from "../pages";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { checkSubDomainAccess } from "../components/RolePermissionAccess/RolePermissionAccess";
import NoAccess from "../components/NoAccess/NoAccess";
import EditBatchTiming from "../pages/app/Manage/ManageBatch/components/Timing/EditBatchTiming";

export default function ManageRouter(props: any) {
  const { setIsActive, shouldDisableGeneralSetup } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { userById } = useSelector((state: any) => state.userDetails);

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const profileCompletedStatus = instituteData?.isProfileCompleted;
  const batchCreated = instituteData?.batchCreated;
  const courseCreated = instituteData?.courseCreated;
  const branchCreated = instituteData?.branchCreated;
  const teamCreated = instituteData?.teamAdded;
  const profileDetailsCompleted = instituteData?.profileDetailsCompleted;

  const instituteAccess = checkSubDomainAccess(
    "MANAGE",
    "INSTITUTE_PROFILE",
    userById?.rolePermission
  );
  const branchAccess = checkSubDomainAccess(
    "MANAGE",
    "BRANCHES",
    userById?.rolePermission
  );
  const courseAccess = checkSubDomainAccess(
    "MANAGE",
    "COURSES",
    userById?.rolePermission
  );
  const teamAccess = checkSubDomainAccess(
    "MANAGE",
    "TEAMS",
    userById?.rolePermission
  );
  const batchAccess = checkSubDomainAccess(
    "MANAGE",
    "BATCHES",
    userById?.rolePermission
  );
  const billingAccess = checkSubDomainAccess(
    "MANAGE",
    "BILLING",
    userById?.rolePermission
  );

  useEffect(() => {
    if (
      instituteData &&
      !location.pathname.includes("/app/home") &&
      !profileCompletedStatus
    ) {
      if (
        !courseCreated &&
        !batchCreated &&
        !branchCreated &&
        !teamCreated &&
        !profileDetailsCompleted
      ) {
        navigate("/app/manage/InstituteProfile/:id");
      }

      if (
        !location.pathname.includes("CreateBranch") &&
        !courseCreated &&
        !batchCreated &&
        !branchCreated &&
        !teamCreated &&
        profileDetailsCompleted
      ) {
        navigate("/app/manage/CreateBranch");
      }

      if (
        !location.pathname.includes("CreateTeam") &&
        courseCreated &&
        !batchCreated &&
        branchCreated &&
        !teamCreated &&
        profileDetailsCompleted
      ) {
        navigate("/app/manage/CreateTeam");
      }

      // if (
      //   !location.pathname.includes("CreateBatch") &&
      //   courseCreated &&
      //   !batchCreated &&
      //   branchCreated &&
      //   teamCreated &&
      //   profileDetailsCompleted
      // ) {
      //   navigate("/app/manage/CreateBatch");
      // }
    }
  }, []);

  // if (location.pathname.includes("Billing") && IsBillingAdmin !== true) {
  //   // Redirect when the URL contains "Billing" and IsBillingAdmin is not true
  //   navigate("/app/manage/InstituteProfile");
  //   return null;
  // }

  if (
    location.pathname.includes("Billing") &&
    billingAccess === false &&
    userById
  ) {
    navigate("/app/manage/InstituteProfile");
    return null;
  }

  if (!location.pathname.includes("Billing") && shouldDisableGeneralSetup) {
    // Redirect when the URL does not contain "Billing" and shouldDisableGeneralSetup is true
    navigate("/app/manage/Billing/ManageSubscription");
    return null;
  }

  return (
    <Routes>
      <Route
        path={"/"}
        element={instituteAccess ? <InstituteProfileDetails /> : <NoAccess />}
      />
      <Route
        path={"/InstituteProfile"}
        element={instituteAccess ? <InstituteProfileDetails /> : <NoAccess />}
      />
      <Route
        path={"/InstituteProfile/:id"}
        element={instituteAccess ? <InstituteProfile /> : <NoAccess />}
      />
      <Route
        path={"/Courses"}
        element={courseAccess ? <CourseListing /> : <NoAccess />}
      />
      <Route
        path={"/Courses/details/:id"}
        element={courseAccess ? <CourseDetail /> : <NoAccess />}
      />
      <Route
        path={"/Courses/:id"}
        element={courseAccess ? <CourseEditor edit={true} /> : <NoAccess />}
      />

      <Route
        path={"/Branches"}
        element={branchAccess ? <BranchListing /> : <NoAccess />}
      />
      <Route
        path={"/CreateBranch"}
        element={
          branchAccess ? (
            <BranchEditor
              add={true}
              edit={false}
              setIsActive={setIsActive}
              backBtn={false}
            />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Branches/:id"}
        element={
          branchAccess ? (
            <BranchEditor add={false} edit={true} backBtn={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Teams"}
        element={
          teamAccess ? (
            <StaffListing backBtn={false} inBilling={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/CreateTeam"}
        element={
          teamAccess ? (
            <StaffEditor add={true} setIsActive={setIsActive} backBtn={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Teams/:id"}
        element={
          teamAccess ? (
            <StaffEditor add={false} backBtn={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Batches"}
        element={batchAccess ? <BatchListing /> : <NoAccess />}
      />
      <Route
        path={"/CreateBatch"}
        element={
          batchAccess ? (
            <BatchEditor
              add={true}
              setIsActive={setIsActive}
              duplicate={false}
            />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/CreateDemoBatch"}
        element={
          batchAccess ? (
            <DemoBatchEditor
              add={true}
              setIsActive={setIsActive}
              duplicate={false}
            />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Batches/:id"}
        element={
          batchAccess ? (
            <BatchEditor add={false} duplicate={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Batches/Schedule/:id"}
        element={batchAccess ? <EditBatchTiming /> : <NoAccess />}
      />
      <Route
        path={"/DemoBatches/:id"}
        element={
          batchAccess ? (
            <DemoBatchEditor add={false} duplicate={false} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/DuplicateBatches/:id"}
        element={
          batchAccess ? (
            <BatchEditor add={true} duplicate={true} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/DuplicateDemoBatches/:id"}
        element={
          batchAccess ? (
            <DemoBatchEditor add={true} duplicate={true} />
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path={"/Billing"}
        element={billingAccess ? <Billing /> : <NoAccess />}
      />
      <Route
        path={"/Billing/EditAddress"}
        element={billingAccess ? <EditAddress /> : <NoAccess />}
      />
      <Route
        path={"/Billing/ManageSubscription"}
        element={billingAccess ? <ManageSubscription /> : <NoAccess />}
      />
      <Route
        path={"/Billing/ManageSubscription/Create-Branch"}
        element={
          branchAccess ? (
            <BranchEditor add={true} backBtn={true} />
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path={"/Billing/ManageSubscription/Create-Team"}
        element={
          teamAccess ? <StaffEditor add={true} backBtn={true} /> : <NoAccess />
        }
      />
      <Route
        path={"/Billing/ManageSubscription/Teams"}
        element={
          teamAccess ? (
            <StaffListing backBtn={true} inBilling={true} />
          ) : (
            <NoAccess />
          )
        }
      />

      <Route path={"/UserDetails"} element={<UserDetails />} />
      <Route path={"/ChangePassword"} element={<ChangePassword />} />
      <Route path={"/Notifications"} element={<NotificationPage />} />
    </Routes>
  );
}
