import {
  Box,
  FormGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./LeadReportCreation.module.css";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import LineChart from "../../../Dashboard/components/LineChart/LineChart";
import PieChart from "../../../../../components/PieChart/PieChart";
import CustomFilterAccordion from "../../../../../components/CustomFilterAccordion/CustomFilterAccordion";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import { useLocation, useNavigate } from "react-router-dom";
import IconDropdown from "../../../../../components/Dropdowns/IconDropdown";
import SaveReportPopup from "../SaveReportPopup/SaveReportPopup";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import backIcon from "../../../../../assets/images/back-btn-arrow.svg";
import CourseDescriptionSkeleton from "../../../../../components/Skeletons/Course/CourseDescriptionSkeleton";
import TableSkeleton from "../../../../../components/Skeletons/TableSkeleton";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import { resetDownloadReportSuccess } from "../../service/reportCreationSlice";
import { getBranchList } from "../../../Manage/ManageBranch/service/Branchslice";
import { getStaffList } from "../../../Manage/ManageStaff/service/Staffslice";
import { getAllCourseByInstituteRequest } from "../../../Manage/ManageCourse/service/courseSlice";
import { getBatchList } from "../../../Manage/ManageBatch/service/Batchslice";
import AttendanceSkeleton from "../../../../../components/Skeletons/Attendance/AttendanceSkeleton";
import accordionUp from "../../../../../assets/images/accordion-up.svg";
import accordionDown from "../../../../../assets/images/accordion-down.svg";
import { DownloadReport } from "../../service/reports.request";

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "18px",
  fontSize: "16px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: 150,
}));

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "12px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: '150px'
  paddingTop: "15px",
  paddingBottom: "10px",
}));

const LeadReportCreation = (props: any) => {
  const {
    GetDefaultReport,
    SaveReport,
    getDefaultReportData,
    getDefaultReportDataLoading,
    saveReportLoading,
    saveReportSuccess,
    // DownloadReport,
    downloadReportData,
    downloadReportSuccess,
    getDefaultReportDataSuccess,
  } = props;
  const [checkedCourses, setCheckedCourses] = useState<any[]>([]);
  const [checkedBranches, setCheckedBranches] = useState<any[]>([]);
  const [showAllCourses, setShowAllCourses] = useState<boolean>(false);
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const batchList = useSelector((state: any) => state.batch.batchList);
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isBatchOpen, setIsBatchOpen] = useState(false);
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [checkedTags, setCheckedTags] = useState<any[]>([]);
  const [assigneeSearchTerm, setAssigneeSearchTerm] =
    React.useState<string>("");
  const [batchSearchTerm, setBatchSearchTerm] = React.useState<string>("");
  const [branchSearchTerm, setBranchSearchTerm] = React.useState<string>("");
  const [showAllEmployees, setShowAllEmployees] =
    React.useState<boolean>(false);
  const [showAllBranch, setShowAllBranch] = React.useState<boolean>(false);
  const [showAllBatch, setShowAllBatch] = React.useState<boolean>(false);
  const [checkedBatches, setCheckedBatches] = useState<any[]>([]);
  const [courseSearchTerm, setCourseSearchTerm] = React.useState("");
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const employees = useSelector((state: any) => state.staff.staffList);
  const [checkedAssignees, setCheckedAssignees] = useState<any[]>([]);
  const [checkedDateRange, setCheckedDateRange] = useState<any[]>([]);
  const [startDate, setStartDate] = React.useState<any>(dayjs().unix() * 1000);
  const [endDate, setEndDate] = React.useState<number | null>(
    dayjs().unix() * 1000
  );
  const [openSaveReportPopup, setOpenSaveReportPopup] = useState(false);
  const [discardCancel, setDiscardCancel] = useState<boolean>(false);
  const [reportName, setReportName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const user = auth();
  const navigate = useNavigate();
  const location = useLocation();
  const [reportTableData, setReportTableData] = useState<any>();
  const [cancel, setCancel] = useState(false);
  const { getLatestReports } = useSelector(
    (state: any) => state?.websocketNotifications
  );
  const [loading, setLoading] = useState(false);
  const [collapseGraph, setCollapseGraph] = useState(false);
  const [showClearAll, setShowClearAll] = useState(false);
  const isCompress = useSelector((state: any) => state.compress.isCompress);
  const [isAdimnDataModified, setIsAdminDataModified] = useState(false);
  const [loadingForAdmin, setLoadingForAdmin] = useState(false);

  const subCategory = [
    { tag: "All", id: "0" },
    { tag: "Contact", id: "1" },
    { tag: "Lead", id: "2" },
    { tag: "Student", id: "3" },
  ];
  const customDurationArray = [
    { duration: "Today", id: "0" },
    { duration: "Last 7 days", id: "1" },
    { duration: "Last 15 Days", id: "2" },
    { duration: "Last 30 Days", id: "3" },
    { duration: "Custom Date", id: "4" },
  ];

  useEffect(() => {
    const userFromLocalStorage = user?.institute[0];
    if (userFromLocalStorage?.instituteId) {
      dispatch<any>(getBranchList(userFromLocalStorage?.instituteId));
      dispatch<any>(getStaffList(userFromLocalStorage?.instituteId));
      dispatch<any>(
        getAllCourseByInstituteRequest({
          id: userFromLocalStorage?.instituteId,
        })
      );
      dispatch<any>(
        getBatchList({
          id: userFromLocalStorage?.instituteId,
          batchConductType: "ALL",
        })
      );
    }
  }, []);

  const filteredBranches =
    user?.institute[0]?.role === "Admin"
      ? branches &&
        branches
          ?.filter((d: any) => d?.id === user?.institute[0]?.branchId)
          ?.filter((branch: any) => {
            const searchTermMatch =
              branch.branchName
                .toLowerCase()
                .indexOf(branchSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          })
      : branches?.filter((branch: any) => {
          const searchTermMatch =
            branch.branchName
              .toLowerCase()
              .indexOf(branchSearchTerm.toLowerCase()) !== -1;

          return searchTermMatch;
        });

  useEffect(() => {
    if (
      location?.state?.data === null &&
      user?.institute[0]?.role !== "Super Admin"
    ) {
      setLoadingForAdmin(true);
      if (batchList?.length > 0) {
        setIsAdminDataModified(true);
        setLoadingForAdmin(false);
      }
    }
  }, [batchList]);

  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName
          .toLowerCase()
          .indexOf(courseSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const handleFilterDateRangeChecked = (id: string, duration: string) => {
    const selectedRange = { id, duration };
    setCheckedDateRange([selectedRange]);
  };

  const filteredBatch =
    user?.institute[0]?.role === "Admin"
      ? batchList
          ?.filter((d: any) => d?.branchId === user?.institute[0]?.branchId)
          ?.filter(
            (item: any) =>
              item?.batchConductType === "REGULAR_BATCH" &&
              item?.batchStatus !== "COMPLETED" &&
              item?.batchStatus !== "CANCELLED" &&
              item?.status !== "INACTIVE"
          )
          ?.filter((batch: any) => {
            const searchTermMatch =
              batch.batchName
                .toLowerCase()
                .indexOf(batchSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          })
      : batchList
          ?.filter(
            (item: any) =>
              item?.batchConductType === "REGULAR_BATCH" &&
              item?.batchStatus !== "COMPLETED" &&
              item?.batchStatus !== "CANCELLED" &&
              item?.status !== "INACTIVE"
          )
          ?.filter((batch: any) => {
            const searchTermMatch =
              batch.batchName
                .toLowerCase()
                .indexOf(batchSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          });

  const handleAssigneeSearch = (event: any) => {
    setAssigneeSearchTerm(event.target.value);
  };

  const filteredEmployees =
    user?.institute[0]?.role === "Admin"
      ? employees
          ?.filter(
            (d: any) =>
              d?.branchId === user?.institute[0]?.branchId ||
              d?.allBranchAccess === true
          )
          ?.filter((item: any) => item.status === "ACTIVE")
          ?.filter((employee: any) => {
            const searchTermMatch =
              employee.firstName
                .toLowerCase()
                .indexOf(assigneeSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          })
      : employees
          ?.filter((item: any) => item.status === "ACTIVE")
          ?.filter((employee: any) => {
            const searchTermMatch =
              employee.firstName
                .toLowerCase()
                .indexOf(assigneeSearchTerm.toLowerCase()) !== -1;

            return searchTermMatch;
          });

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      const dateMilliseconds = dateUnix * 1000;
      if (id === "startDate") {
        setStartDate(dateMilliseconds);
      } else if (id === "endDate") {
        setEndDate(dateMilliseconds);
      }
    }
  };
  const isCustomDate = checkedDateRange?.filter((d: any) => d?.id === "4");

  const onDiscardClose = () => {
    setDiscardCancel(false);
  };
  const noDiscardButtonClick = () => {
    setDiscardCancel(false);
  };
  const yesDiscardButtonClick = () => {
    setDiscardCancel(false);
  };

  const onCancel = () => {
    setCancel(false);
  };

  const noCancelModal = () => {
    setCancel(false);
  };

  const yesCancelModal = () => {
    setCancel(false);
    location?.state?.from === "RecentReports"
      ? navigate("/app/Reports/RecentReports")
      : location?.state?.from === "CreatedByMe"
      ? navigate("/app/Reports/CreatedByMe")
      : navigate("/app/Reports/AllReports");
  };

  useEffect(() => {
    if (getLatestReports) {
      setLoading(false);
      location?.state?.from === "RecentReports"
        ? navigate("/app/Reports/RecentReports")
        : location?.state?.from === "CreatedByMe"
        ? navigate("/app/Reports/CreatedByMe")
        : navigate("/app/Reports/AllReports");
    }
  }, [getLatestReports]);

  useEffect(() => {
    if (location?.state?.data === null) {
      reset();
    }
  }, [courses, batchList, branches, employees]);

  useEffect(() => {
    setTimeout(() => {
      setShowClearAll(true);
    }, 8000);
  }, []);

  const reset = () => {
    const alllBranches =
      (filteredBranches &&
        filteredBranches?.map((d: any) => {
          return { id: d?.id, branchName: d?.branchName };
        })) ||
      [];
    setCheckedBranches([...alllBranches, { id: "0", branchName: "ALL" }]);
    const alllBatches =
      (filteredBatch &&
        filteredBatch?.map((d: any) => {
          return { id: d?.id, batchName: d?.batchName };
        })) ||
      [];
    setCheckedBatches([...alllBatches, { id: "0", batchName: "ALL" }]);
    const alllCourse =
      (filteredCourses &&
        filteredCourses?.map((d: any) => {
          return { id: d?.id, courseName: d?.courseName };
        })) ||
      [];
    setCheckedCourses([...alllCourse, { id: "0", courseName: "ALL" }]);
    const alllEmployees =
      (filteredEmployees &&
        filteredEmployees?.map((d: any) => {
          return { id: d?.id, firstName: d?.firstName, lastName: d?.lastName };
        })) ||
      [];
    setCheckedAssignees([
      ...alllEmployees,
      { id: "0", firstName: "ALL", lastName: "" },
    ]);
    const alllCategory =
      (subCategory &&
        subCategory?.map((d: any) => {
          return { id: d?.id, tagName: d?.tag };
        })) ||
      [];
    setCheckedTags(alllCategory);
    setCheckedDateRange([{ duration: "Last 30 Days", id: "3" }]);
  };

  useEffect(() => {
    if (location?.state?.data === null) {
      const data = {
        reportType: "LEAD",
        instituteId: user?.institute[0]?.instituteId,
        generateReports: true,
        courseId: "ALL",
        branchId: "ALL",
        batchId: "ALL",
        subCategory: "ALL",
        assignedToId: "ALL",
      };

      if (user?.institute[0]?.role === "Super Admin") {
        GetDefaultReport(data);
      } else if (isAdimnDataModified) {
        const adminData = {
          reportType: "LEAD",
          instituteId: user?.institute[0]?.instituteId,
          generateReports: true,
          courseId: "ALL",
          branchId:
            filteredBranches &&
            filteredBranches?.map((d: any) => {
              return d?.id;
            }),
          batchId:
            filteredBatch &&
            filteredBatch?.map((d: any) => {
              return d?.id;
            }),
          subCategory: "ALL",
          assignedToId:
            filteredEmployees &&
            filteredEmployees?.map((d: any) => {
              return d?.id;
            }),
        };
        GetDefaultReport(adminData);
      }
    } else {
      const dataFromNavigateion = location?.state?.data?.appliedFilter;
      const branchIdArray = dataFromNavigateion?.find(
        (item: any) => item.branchId
      )?.branchId;
      const batchIdArray = dataFromNavigateion?.find(
        (item: any) => item.batchId
      )?.batchId;
      const courseIdArray = dataFromNavigateion?.find(
        (item: any) => item.courseId
      )?.courseId;
      const assigneeIdArray = dataFromNavigateion?.find(
        (item: any) => item.assignedToId
      )?.assignedToId;
      const subCategoryArray = dataFromNavigateion?.find(
        (item: any) => item.subCategory
      )?.subCategory;
      const dateData = dataFromNavigateion?.find(
        (item: any) => item.date
      )?.date;

      if (dateData?.type === "today") {
        setCheckedDateRange([{ duration: "Today", id: "0" }]);
      } else if (dateData?.type === "day" && dateData?.value === 7) {
        setCheckedDateRange([{ duration: "Last 7 days", id: "1" }]);
      } else if (dateData?.type === "day" && dateData?.value === 15) {
        setCheckedDateRange([{ duration: "Last 15 Days", id: "2" }]);
      } else if (dateData?.type === "day" && dateData?.value === 30) {
        setCheckedDateRange([{ duration: "Last 30 Days", id: "3" }]);
      } else if (dateData?.type === "customDate") {
        setCheckedDateRange([{ duration: "Custom Date", id: "4" }]);
        setStartDate(dateData?.value);
      }

      if (
        branchIdArray === "ALL" ||
        branchIdArray?.length === filteredBranches?.length
      ) {
        const alllBranches =
          filteredBranches?.map((d: any) => {
            return { id: d?.id, branchName: d?.branchName };
          }) || [];
        setCheckedBranches([...alllBranches, { id: "0", branchName: "ALL" }]);
      } else {
        const aa = branchIdArray?.map((d: any) => {
          return { id: d, branchName: findNamesbyId("BRANCH", d) };
        });
        setCheckedBranches(aa);
      }
      if (
        batchIdArray === "ALL" ||
        batchIdArray?.length === filteredBatch?.length
      ) {
        const alllBatches =
          filteredBatch?.map((d: any) => {
            return { id: d?.id, batchName: d?.batchName };
          }) || [];
        setCheckedBatches([...alllBatches, { id: "0", batchName: "ALL" }]);
      } else {
        const aa = batchIdArray?.map((d: any) => {
          return { id: d, batchName: findNamesbyId("BATCH", d) };
        });
        setCheckedBatches(aa);
      }

      if (courseIdArray === "ALL") {
        const alllCourse =
          filteredCourses?.map((d: any) => {
            return { id: d?.id, courseName: d?.courseName };
          }) || [];
        setCheckedCourses([...alllCourse, { id: "0", courseName: "ALL" }]);
      } else {
        const aa = courseIdArray?.map((d: any) => {
          return { id: d, courseName: findNamesbyId("BATCH", d) };
        });
        setCheckedCourses(aa);
      }
      if (
        assigneeIdArray === "ALL" ||
        assigneeIdArray?.length === filteredEmployees?.length
      ) {
        const alllEmployees =
          filteredEmployees?.map((d: any) => {
            return {
              id: d?.id,
              firstName: d?.firstName,
              lastName: d?.lastName,
            };
          }) || [];
        setCheckedAssignees([
          ...alllEmployees,
          { id: "0", firstName: "ALL", lastName: "" },
        ]);
      } else {
        const aa = assigneeIdArray?.map((d: any) => {
          const name = findNamesbyId("ASSIGNEE", d);
          const [firstName, lastName] = name.split(" ");
          return { id: d, firstName: firstName, lastName: lastName };
        });
        setCheckedAssignees(aa);
      }

      if (subCategoryArray === "ALL") {
        const alllCategory =
          subCategory?.map((d: any) => {
            return { id: d?.id, tagName: d?.tag };
          }) || [];
        setCheckedTags(alllCategory);
      } else {
        const aa = subCategoryArray?.map((d: any) => {
          return {
            id: d === "STUDENT_CONTACT" ? "1" : d === "ENQUIRY" ? "2" : "3",
            tagName:
              d === "STUDENT_CONTACT"
                ? "Contact"
                : d === "ENQUIRY"
                ? "Lead"
                : "3",
          };
        });
        setCheckedTags(aa);
      }
    }
  }, [isAdimnDataModified]);

  const findNamesbyId = (fieldName: any, id: string) => {
    if (fieldName === "COURSE" && id) {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return courseName && courseName[0]?.courseName;
    } else if (fieldName === "BATCH" && id) {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );

      return batchName && batchName[0]?.batchName;
    } else if (fieldName === "BRANCH" && id) {
      const branchName =
        filteredBranches && filteredBranches?.filter((d: any) => d?.id === id);
      return branchName && branchName[0]?.branchName;
    } else if (fieldName === "ASSIGNEE") {
      const assigneeName =
        filteredEmployees &&
        filteredEmployees?.filter((d: any) => d?.id === id);
      return (
        assigneeName &&
        `${assigneeName[0]?.firstName} ${assigneeName[0]?.lastName} `
      );
    }
  };

  useEffect(() => {
    if (getDefaultReportData) {
      setReportTableData(getDefaultReportData?.result);
    }
  }, [getDefaultReportData]);

  const result = location?.state?.data
    ? location?.state?.data?.result
    : reportTableData;

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      instituteId: user?.institute[0]?.instituteId,
      name: reportName,
      category: "LEAD",
      description: description,
      reportData: {
        result: getDefaultReportData?.result,
        lineGraphData: getDefaultReportData?.lineGraphData,
        pieChartData: getDefaultReportData?.pieChartData,
        appliedFilter: getDefaultReportData?.appliedFilter,
      },
      createdById: user?.institute[0]?.id,
      createdByName: `${user?.institute[0]?.firstName} ${user?.institute[0]?.lastName}`,
    };
    SaveReport(data);
  };

  const handleFilterApply = () => {
    const hasAllBranches = checkedBranches?.some(
      (branch: any) => branch?.branchName === "ALL"
    );
    const hasAllBatches = checkedBatches?.some(
      (batch: any) => batch?.batchName === "ALL"
    );
    const hasAllCourses = checkedCourses.some(
      (course: any) => course.courseName === "ALL"
    );
    const hasAllCategory = checkedTags.some(
      (tags: any) => tags.tagName === "All"
    );
    const hasAllAssignees = checkedAssignees.some(
      (assignee: any) => assignee.firstName === "ALL"
    );
    const date: any = new Date(startDate);
    const dateForDefaultReport = date.setUTCHours(0, 0, 0, 0);

    const today = new Date();

    const timestamp = Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate()
    );

    const data = {
      reportType: "LEAD",
      instituteId: user?.institute[0]?.instituteId,
      generateReports: true,
      courseId: hasAllCourses ? "ALL" : checkedCourses?.map((d: any) => d?.id),
      branchId:
        user?.institute[0]?.role !== "Super Admin"
          ? checkedBranches
              ?.filter((d: any) => d?.id !== "0")
              ?.map((d: any) => d?.id)
          : hasAllBranches
          ? "ALL"
          : checkedBranches?.map((d: any) => d?.id),
      batchId:
        user?.institute[0]?.role !== "Super Admin"
          ? checkedBatches
              ?.filter((d: any) => d?.id !== "0")
              ?.map((d: any) => d?.id)
          : hasAllBatches
          ? "ALL"
          : checkedBatches?.map((d: any) => d?.id),
      subCategory: hasAllCategory
        ? "ALL"
        : checkedTags
            ?.filter((ds: any) => ds?.tagName !== "ALL")
            ?.map((d: any) => {
              return d?.tagName === "Contact"
                ? "STUDENT_CONTACT"
                : d?.tagName === "Lead"
                ? "ENQUIRY"
                : "BATCHSTUDENTMAPPING";
            }),
      assignedToId:
        user?.institute[0]?.role !== "Super Admin"
          ? checkedAssignees
              ?.filter((d: any) => d?.id !== "0")
              ?.map((d: any) => d?.id)
          : hasAllAssignees
          ? "ALL"
          : checkedAssignees?.map((d: any) => d?.id),
      date: checkedDateRange?.some((item: any) => item.duration === "Today")
        ? { type: "today", value: timestamp }
        : checkedDateRange?.some((item: any) => item.duration === "Custom Date")
        ? { type: "customDate", value: dateForDefaultReport }
        : checkedDateRange?.some((item: any) => item.duration === "Last 7 days")
        ? { type: "day", value: 7 }
        : checkedDateRange?.some(
            (item: any) => item.duration === "Last 15 Days"
          )
        ? { type: "day", value: 15 }
        : checkedDateRange?.some(
            (item: any) => item.duration === "Last 15 Days"
          )
        ? { type: "day", value: 30 }
        : "",
    };

    if (data?.subCategory === "" || data?.subCategory?.length === 0) {
      dispatch(
        setToastNotification({
          message: "Please select subcategory filter",
          type: "error",
          snackOpen: true,
        })
      );
    } else if (data?.branchId === "" || data?.branchId?.length === 0) {
      dispatch(
        setToastNotification({
          message: "Please select branch filter",
          type: "error",
          snackOpen: true,
        })
      );
    } else if (data?.courseId === "" || data?.courseId?.length === 0) {
      dispatch(
        setToastNotification({
          message: "Please select course filter",
          type: "error",
          snackOpen: true,
        })
      );
    } else if (data?.assignedToId === "" || data?.assignedToId?.length === 0) {
      dispatch(
        setToastNotification({
          message: "Please select assignee filter",
          type: "error",
          snackOpen: true,
        })
      );
    } else if (data?.subCategory === "" || data?.subCategory?.length === 0) {
      dispatch(
        setToastNotification({
          message: "Please select branch filter",
          type: "error",
          snackOpen: true,
        })
      );
    } else {
      GetDefaultReport(data);
    }
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    if (branchName === "All") {
      if (
        checkedBranches.length ===
        [{ id: "0", branchName: "All" }, ...filteredBranches].length
      ) {
        setCheckedBranches([]);
      } else {
        const alllBranches = filteredBranches?.map((d: any) => {
          return { id: d?.id, branchName: d?.branchName };
        });
        setCheckedBranches([...alllBranches, { id: "0", branchName: "ALL" }]);
      }
    } else {
      const branchToAdd: CheckedBranch = { id, branchName };
      const isAllSelected = checkedBranches.some(
        (branch) => branch.branchName === "ALL"
      );

      if (checkedBranches.some((branch) => branch.id === id)) {
        const updatedBranches = checkedBranches.filter(
          (branch) => branch.id !== id
        );
        if (isAllSelected) {
          setCheckedBranches(
            updatedBranches.filter((branch) => branch.branchName !== "ALL")
          );
        } else {
          setCheckedBranches(updatedBranches);
        }
      } else {
        setCheckedBranches([
          ...checkedBranches,
          branchToAdd,
          // { id: "0", branchName: "ALL" },
        ]);
      }
    }
  };
  const handleFilterCourseChecked = (id: string, courseName: string) => {
    if (courseName === "All") {
      if (
        checkedCourses.length ===
        [{ id: "0", courseName: "All" }, ...filteredCourses].length
      ) {
        setCheckedCourses([]);
      } else {
        const allCourse = filteredCourses?.map((d: any) => {
          return { id: d?.id, courseName: d?.branchName };
        });
        setCheckedCourses([...allCourse, { id: "0", courseName: "ALL" }]);
      }
    } else {
      const courseToAdd: CheckedCourse = { id, courseName };
      const isAllSelected = checkedCourses.some(
        (branch) => branch.courseName === "ALL"
      );

      if (checkedCourses.some((branch) => branch.id === id)) {
        const updatedCourses = checkedCourses.filter(
          (course) => course.id !== id
        );
        if (isAllSelected) {
          setCheckedCourses(
            updatedCourses.filter((course: any) => course.courseName !== "ALL")
          );
        } else {
          setCheckedCourses(updatedCourses);
        }
      } else {
        setCheckedCourses([...checkedCourses, courseToAdd]);
      }
    }
  };

  const handleFilterBatchChecked = (id: string, batchName: string) => {
    if (batchName === "All") {
      if (
        checkedBatches.length ===
        [{ id: "0", batchName: "All" }, ...filteredBatch].length
      ) {
        setCheckedBatches([]);
      } else {
        const allBatch = filteredBatch?.map((d: any) => {
          return { id: d?.id, batchName: d?.branchName };
        });
        setCheckedBatches([...allBatch, { id: "0", batchName: "ALL" }]);
      }
    } else {
      const batchToAdd: any = { id, batchName };
      const isAllSelected = checkedBatches.some(
        (batch) => batch.batchName === "ALL"
      );

      if (checkedBatches.some((branch) => branch.id === id)) {
        const updatedCourses = checkedBatches.filter(
          (course) => course.id !== id
        );
        if (isAllSelected) {
          setCheckedBatches(
            updatedCourses.filter((course: any) => course.batchName !== "ALL")
          );
        } else {
          setCheckedBatches(updatedCourses);
        }
      } else {
        setCheckedBatches([...checkedBatches, batchToAdd]);
      }
    }
  };

  const handleFilterAssigneeChecked = (
    id: string,
    firstName: string,
    lastName: string
  ) => {
    if (firstName === "All") {
      if (
        checkedAssignees.length ===
        [{ id: "0", firstName: "All", lastName: "" }, ...filteredEmployees]
          .length
      ) {
        setCheckedAssignees([]);
      } else {
        const allEmployees = filteredEmployees?.map((d: any) => {
          return { id: d?.id, firstName: d?.firstName, lastName: d?.lastName };
        });
        setCheckedAssignees([
          ...allEmployees,
          { id: "0", firstName: "ALL", lastName: "" },
        ]);
      }
    } else {
      const employeesToAdd: any = { id, firstName, lastName };
      const isAllSelected = checkedAssignees.some(
        (emp) => emp.firstName === "ALL"
      );

      if (checkedAssignees.some((emp) => emp.id === id)) {
        const updatedEmp = checkedAssignees.filter((emp) => emp.id !== id);
        if (isAllSelected) {
          setCheckedAssignees(
            updatedEmp.filter((emp: any) => emp.firstName !== "ALL")
          );
        } else {
          setCheckedAssignees(updatedEmp);
        }
      } else {
        setCheckedAssignees([...checkedAssignees, employeesToAdd]);
      }
    }
  };

  const handleFilterTagsChecked = (id: string, tagName: string) => {
    if (tagName === "All") {
      if (checkedTags.length === subCategory.length) {
        setCheckedTags([]);
      } else {
        const allCategory = subCategory?.map((d: any) => {
          return { id: d?.id, tagName: d?.tag };
        });
        setCheckedTags(allCategory);
      }
    } else {
      const tagsToAdd: any = { id, tagName };
      const isAllSelected = checkedTags.some((tag) => tag.tagName === "All");
      if (checkedTags.some((tag) => tag.id === id)) {
        const updatedTags = checkedTags.filter((tag) => tag.id !== id);
        if (isAllSelected) {
          setCheckedTags(
            updatedTags.filter((tag: any) => tag.tagName !== "All")
          );
        } else {
          setCheckedTags(updatedTags);
        }
      } else {
        setCheckedTags([...checkedTags, tagsToAdd]);
      }
    }
  };

  const handleDownload = () => {
    const flatttenedArray = getDefaultReportData?.result.flatMap(
      (item: any) => item
    );

    const data = {
      action: "DOWNLOAD",
      reportType: "LEAD",
      reportData: flatttenedArray,
    };

    DownloadReport(data)
      .then((res: any) => {
        console.log(res);
        const blob = base64ToBlob(
          res?.data?.data?.fileBase64String,
          "application/vnd.ms-excel"
        );
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "leadReport.xlsx";
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        dispatch(
          setToastNotification({
            message: "Congratulations! Report is successfully downloaded.",
            type: "success",
            snackOpen: true,
          })
        );
      })
      .catch((err: any) => {});
  };

  const base64ToBlob = (base64Data: string, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  useEffect(() => {
    if (downloadReportSuccess) {
      const blob = base64ToBlob(
        downloadReportData?.fileBase64String,
        "application/vnd.ms-excel"
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "leadReport.xlsx";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      dispatch(resetDownloadReportSuccess());
    }
  }, [downloadReportSuccess]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: "14px 16px",
        background: "#EEEEEE",
        // maxHeight: "calc(100vh - 65px)",
        // position: "relative",
        maxHeight: "90vh",
        position: "relative",
        scrollbarWidth: "none",
      }}
    >
      {getDefaultReportDataLoading || loadingForAdmin ? (
        <AttendanceSkeleton />
      ) : (
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            display: "flex",
            width: getDefaultReportDataLoading
              ? "84vw"
              : isCompress
              ? "78vw"
              : "70vw",
            maxHeight: "100vh",
            minHeight: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              minWidth: "219px",
              borderRight: "1px solid #E9E9E9",
              // overflowY: "hidden",
            }}
            className="datepicker-container"
          >
            <div className={styles.calendarHeader}>
              <div className={styles.headerText}>Custom</div>
            </div>
            <div className={styles.applyClearSection}>
              {location?.state?.isFromView === false && (
                <>
                  {showClearAll ? (
                    <div
                      className={styles.clearFilterText}
                      onClick={() => {
                        setCheckedTags([]);
                        setCheckedBranches([]);
                        setCheckedBatches([]);
                        setCheckedCourses([]);
                        setCheckedAssignees([]);
                        setCheckedDateRange([]);
                      }}
                    >
                      Clear All
                    </div>
                  ) : (
                    <div className={styles.clearFilterText}></div>
                  )}
                  <div
                    className={styles.applyFilterText}
                    onClick={() => {
                      handleFilterApply();
                      setIsBranchOpen(false);
                      setIsBatchOpen(false);
                      setIsCourseOpen(false);
                      setIsAssigneeOpen(false);
                      setIsTypeOpen(false);
                      setIsDateOpen(false);
                    }}
                  >
                    Apply
                  </div>
                </>
              )}
            </div>
            <div className={styles.filterContainer}>
              <CustomFilterAccordion
                title="Sub Category"
                isOpen={isTypeOpen}
                setIsOpen={setIsTypeOpen}
                count={checkedTags?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {subCategory?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedTags?.some(
                            (data: any) => data?.id === item?.id
                          )}
                          checkboxFunction={() =>
                            handleFilterTagsChecked(item.id, item.tag)
                          }
                          label={item?.tag?.toLowerCase()}
                          fromReports={true}
                          disable={
                            location?.state?.isFromView === true ? true : false
                          }
                        />
                      ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
              </CustomFilterAccordion>
              <CustomFilterAccordion
                title="Branch"
                isOpen={isBranchOpen}
                setIsOpen={setIsBranchOpen}
                count={checkedBranches?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterSearchField
                    value={branchSearchTerm}
                    onChange={(e: any) => {
                      setBranchSearchTerm(e.target.value);
                    }}
                    placeholder="Search Branch"
                  />
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {branches &&
                        [
                          { branchName: "All", id: "0" },
                          ...filteredBranches,
                        ]?.map((item: any) => (
                          <FilterCheckbox
                            keyVal={item?.id}
                            checked={checkedBranches?.some(
                              (data) => data?.id === item?.id
                            )}
                            checkboxFunction={() =>
                              handleFilterBranchChecked(
                                item.id,
                                item.branchName
                              )
                            }
                            label={item?.branchName?.toLowerCase()}
                            fromReports={true}
                            disable={location?.state?.isFromView === true}
                          />
                        ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
              </CustomFilterAccordion>
              <CustomFilterAccordion
                title="Course"
                isOpen={isCourseOpen}
                setIsOpen={setIsCourseOpen}
                count={checkedCourses?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterSearchField
                    value={courseSearchTerm}
                    onChange={(e: any) => {
                      setCourseSearchTerm(e.target.value);
                    }}
                    placeholder="Search Course"
                  />
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {courseList &&
                        [
                          { courseName: "All", id: "0" },
                          ...filteredCourses,
                        ]?.map((item: any) => (
                          <FilterCheckbox
                            keyVal={item?.id}
                            checked={checkedCourses?.some(
                              (data) => data?.id === item?.id
                            )}
                            checkboxFunction={() =>
                              handleFilterCourseChecked(
                                item.id,
                                item.courseName
                              )
                            }
                            label={item?.courseName?.toLowerCase()}
                            fromReports={true}
                            disable={location?.state?.isFromView === true}
                          />
                        ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
              </CustomFilterAccordion>
              <CustomFilterAccordion
                title="Batch"
                isOpen={isBatchOpen}
                setIsOpen={setIsBatchOpen}
                count={checkedBatches?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterSearchField
                    value={batchSearchTerm}
                    onChange={(e: any) => setBatchSearchTerm(e.target.value)}
                    placeholder="Search Batch"
                  />
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {batchList &&
                        [{ batchName: "All", id: "0" }, ...filteredBatch]?.map(
                          (item: any) => (
                            <FilterCheckbox
                              keyVal={item.id}
                              checked={checkedBatches?.some(
                                (data) => data?.id === item?.id
                              )}
                              checkboxFunction={() =>
                                handleFilterBatchChecked(
                                  item.id,
                                  item.batchName
                                )
                              }
                              label={item.batchName.toLowerCase()}
                              fromReports={true}
                              disable={location?.state?.isFromView === true}
                            />
                          )
                        )}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
              </CustomFilterAccordion>
              <CustomFilterAccordion
                title="Assignee"
                isOpen={isAssigneeOpen}
                setIsOpen={setIsAssigneeOpen}
                count={checkedAssignees?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterSearchField
                    value={assigneeSearchTerm}
                    onChange={handleAssigneeSearch}
                    placeholder="Search Assignee"
                  />
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {employees &&
                        [
                          { firstName: "All", lastName: "", id: "0" },
                          ...filteredEmployees,
                        ]?.map((item: any) => (
                          <FilterCheckbox
                            keyVal={item.id}
                            checked={checkedAssignees?.some(
                              (data) => data?.id === item?.id
                            )}
                            checkboxFunction={() =>
                              handleFilterAssigneeChecked(
                                item.id,
                                item.firstName,
                                item.lastName
                              )
                            }
                            label={
                              item.firstName.toLowerCase() +
                              " " +
                              item.lastName.toLowerCase()
                            }
                            fromReports={true}
                            disable={location?.state?.isFromView === true}
                          />
                        ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
              </CustomFilterAccordion>
              <CustomFilterAccordion
                title="Date"
                isOpen={isDateOpen}
                setIsOpen={setIsDateOpen}
                count={checkedDateRange?.length}
                disabled={location?.state?.isFromView === true}
              >
                <FilterTypeWrap>
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {customDurationArray?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedDateRange?.some(
                            (data: any) => data?.id === item?.id
                          )}
                          checkboxFunction={() =>
                            handleFilterDateRangeChecked(item.id, item.duration)
                          }
                          label={item?.duration?.toLowerCase()}
                          fromReports={true}
                          disable={location?.state?.isFromView === true}
                        />
                      ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </FilterTypeWrap>
                {isCustomDate?.length > 0 && (
                  <Box
                    sx={{
                      marginTop: "5px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          zIndex: "999999999 !important",
                          minHeight: "20px",
                          ".MuiInputBase-root": {
                            fontSize: "12px",
                            minHeight: "20px",
                          },
                          ".MuiFormLabel-root": {
                            fontSize: "12px",
                            lineHeight: "1",
                          },
                          ".MuiSvgIcon-root": {
                            fontSize: "18px",
                          },
                          marginBottom: "10px",
                        }}
                        label="From"
                        format="DD/MM/YYYY"
                        onChange={(date) => handleDateChange(date, "startDate")}
                        value={
                          startDate !== null
                            ? dayjs.unix(startDate / 1000)
                            : null
                        }
                        disableFuture={true}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          zIndex: "99999999 !important",
                          minHeight: "20px",
                          ".MuiInputBase-root": {
                            fontSize: "12px",
                            minHeight: "20px",
                          },
                          ".MuiFormLabel-root": {
                            fontSize: "12px",
                            lineHeight: "1",
                          },
                          ".MuiSvgIcon-root": {
                            fontSize: "18px",
                          },
                        }}
                        label="To"
                        format="DD/MM/YYYY"
                        onChange={(date) => handleDateChange(date, "endDate")}
                        value={
                          endDate !== null ? dayjs.unix(endDate / 1000) : null
                        }
                        disabled={true}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              </CustomFilterAccordion>
            </div>
          </Box>

          <Box
            sx={{
              background: "#fff",
              width: "100%",
            }}
          >
            {location?.state?.isFromView ? (
              <div className={styles.batchHeader}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingRight: "20px",
                    color: "#667085",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    location?.state?.from === "RecentReports"
                      ? navigate("/app/Reports/RecentReports")
                      : location?.state?.from === "CreatedByMe"
                      ? navigate("/app/Reports/CreatedByMe")
                      : navigate("/app/Reports/AllReports");
                  }}
                >
                  <img src={backIcon} style={{ marginRight: "5px" }} /> Back
                </div>
                <div className={styles.headerText}>
                  {`${location?.state?.reportName}`}
                </div>
                <div
                  onClick={() => setCollapseGraph(!collapseGraph)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#667085",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Graphs{" "}
                  <img
                    src={collapseGraph ? accordionUp : accordionDown}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.batchHeader}>
                <div className={styles.headerText}>
                  {location?.state?.isFromView
                    ? `${location?.state?.reportName}`
                    : "Report - Leads"}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <div
                    onClick={() => setCollapseGraph(!collapseGraph)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#667085",
                      fontSize: "14px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    Graphs{" "}
                    <img
                      src={collapseGraph ? accordionUp : accordionDown}
                      alt=""
                      style={{ marginLeft: "5px" }}
                    />
                  </div>
                  <CancelBtn
                    onClick={() => {
                      setCancel(true);
                    }}
                  />
                  <SaveBtn
                    onClick={() => {
                      setOpenSaveReportPopup(true);
                    }}
                    disabled={result?.length === 0}
                  />
                  <div style={{ marginLeft: "5px" }}>
                    <IconDropdown
                      options={[
                        {
                          label: "Download",
                          onClick: () => {
                            handleDownload();
                          },
                          style: { color: "#667085" },
                        },
                      ]}
                      isDownloadButton={true}
                      disabled={result?.length === 0}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                overflowY: "scroll",
                height: "78vh",
              }}
            >
              <div
                className={styles.graphContainer}
                style={{
                  margin: collapseGraph ? "20px 20px" : "10px 20px",
                }}
              >
                {getDefaultReportDataLoading ? (
                  <CourseDescriptionSkeleton />
                ) : (
                  <>
                    {collapseGraph && (
                      <div className={styles.lineGraphContainer}>
                        <LineChart
                          result={
                            location?.state?.data
                              ? location?.state?.data
                              : getDefaultReportData && getDefaultReportData
                          }
                          getDefaultReportDataLoading={
                            getDefaultReportDataLoading
                          }
                        />
                      </div>
                    )}
                  </>
                )}
                {getDefaultReportDataLoading ? (
                  <CourseDescriptionSkeleton />
                ) : (
                  <>
                    {collapseGraph && (
                      <div className={styles.pieGraphContainer}>
                        <PieChart
                          title={"lead"}
                          result={
                            location?.state?.data
                              ? location?.state?.data
                              : getDefaultReportData && getDefaultReportData
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  margin: collapseGraph ? "30px 20px" : "10px 20px",
                }}
              >
                {getDefaultReportDataLoading ? (
                  <TableSkeleton />
                ) : (
                  <TableContainer
                    component={Paper}
                    sx={{
                      // minWidth: 700,
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      // padding: ' 0px 12px 0px 12px',
                      // margin: " 0px 18px 0px 18px",
                      height: "calc(100vh - 100px)",
                      overflowY: "scroll",
                      boxShadow: "none",
                      flexGrow: 1,
                      width: "100%",
                      maxHeight: "calc(100vh - 220px)",
                      minHeight: "calc(100vh - 220px)",
                    }}
                  >
                    <Table sx={{ minWidth: 700 }}>
                      <TableHead sx={{ background: "#f5f5f5" }}>
                        <TableRow>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 50,
                              padding: "16px",
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            #
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 300,
                              padding: "16px",
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Name{" "}
                            {`${
                              result?.length > 0 ? `(${result?.length})` : 0
                            }`}
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 150,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Stage
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 150,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Assignee
                          </StyledTableCellHead>

                          <StyledTableCellHead
                            sx={{
                              minWidth: 150,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Course Name
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 200,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Batch
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 150,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Batch Status
                          </StyledTableCellHead>
                          <StyledTableCellHead
                            sx={{
                              minWidth: 200,
                              position: "sticky",
                              top: 0,
                              background: "#f5f5f5",
                              zIndex: 1,
                            }}
                          >
                            Branch
                          </StyledTableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result &&
                          result?.map((report: any, index: any) => (
                            <TableRow key={index}>
                              <StyledTableCellBody
                                sx={{
                                  color: "#142C44",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {index + 1}
                              </StyledTableCellBody>
                              <StyledTableCellBody
                                sx={{
                                  color: "#142C44",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {`${report?.firstName} ${report?.lastName}`}
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          width: "32px",
                                          height: "32px",
                                          borderRadius: "50%",
                                          fontSize: "12px",
                                          background: "#EEEEF4",
                                          border: "2px solid #FFF",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "#667085",
                                          position: "relative",
                                        }}
                                      >
                                        {report?.entityType === "ENQUIRY"
                                          ? "L"
                                          : report?.entityType ===
                                            "BATCHSTUDENTMAPPING"
                                          ? "S"
                                          : "C"}
                                      </Avatar>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      color: "#667085",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {report?.email}{" "}
                                    {report?.email && report?.contact
                                      ? "|"
                                      : ""}{" "}
                                    {report?.contact
                                      ? `+${report?.contact}`
                                      : ""}
                                  </div>
                                </div>
                              </StyledTableCellBody>
                              <StyledTableCellBody
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {report?.enquirySwimlaneStatus}
                              </StyledTableCellBody>
                              <StyledTableCellBody
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {report?.assignedToName}
                              </StyledTableCellBody>

                              <StyledTableCellBody
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {report?.courseName}
                              </StyledTableCellBody>
                              <StyledTableCellBody
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {report.batchName}
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "6px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Box
                                    ml={1}
                                    sx={{
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {report.batchStatus}
                                  </Box>
                                </Box>
                              </StyledTableCellBody>
                              <StyledTableCellBody
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {report.branchName}
                              </StyledTableCellBody>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          </Box>
        </Box>
      )}
      {openSaveReportPopup && (
        <SaveReportPopup
          openSaveReportPopup={openSaveReportPopup}
          setOpenSaveReportPopup={setOpenSaveReportPopup}
          setDiscardCancel={setDiscardCancel}
          handleSaveClick={handleSaveClick}
          saveReportLoading={loading}
          description={description}
          setDescription={setDescription}
          reportName={reportName}
          setReportName={setReportName}
        />
      )}
      <CancelModal
        open={discardCancel}
        onClose={onDiscardClose}
        noButtonClick={noDiscardButtonClick}
        yesButtonClick={yesDiscardButtonClick}
        subTitle="There are unsaved changes in your report. If you close the report, these changes are lost."
        title={`Are you sure you want to close?`}
      />
      <CancelModal
        open={cancel}
        onClose={onCancel}
        noButtonClick={noCancelModal}
        yesButtonClick={yesCancelModal}
        subTitle=""
        title={`Do you really want to close the report without saving it!!!`}
      />
    </Box>
  );
};

export default LeadReportCreation;
