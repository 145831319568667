import { MenuItem, Select, TextField } from "@mui/material";
import React from "react";

function DiscountInput(props: any) {
  const {
    discountMetric,
    discount,
    handleDiscountMetric,
    handleDiscount,
    totalPrice,
	  place,
	  selectedCurrency
  } = props;

	const maxDiscount = discountMetric === 'PERCENTAGE' ? 100 : totalPrice;

  return (
		<div
			style={{
				maxWidth: place === 'SESSION' ? '100%' : '160px',
				display: 'flex',
				alignItems: 'center',
				border: '1px solid #D9D9D9',
				borderRadius: '6PX',
				overflow: 'hidden',
			}}>
			<div
				style={{
					maxWidth: '60px',
					borderRight: '1px solid #D9D9D9',
					background: '#FAFAFA',
					width: '60px',
				}}>
				<Select
					id='emi-mode-select'
					fullWidth
					MenuProps={{
						style: {
							// zIndex: 999999,
							zIndex: 99999999,
							fontSize: '14px',
						},
						PaperProps: {
							style: {
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
							},
						},
					}}
					sx={{
						maxHeight: '39PX',

						textTransform: 'capitalize',
						fontFamily: 'Roboto',
						border: 'none',
						paddingRight: '25px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',

						'& .MuiSelect-outlined': {
							fontSize: '14px',
							color: '#142C44',
							border: 'none',
						},
						'&& fieldset': {
							border: 'none',
						},
					}}
					value={discountMetric}
					onChange={(event) =>
						handleDiscountMetric(event.target.value as string)
					}>
					{[
						{ discountType: 'AMOUNT', label: selectedCurrency },
						{ discountType: 'PERCENTAGE', label: '%' },
					]?.map((item, index) => (
						<MenuItem
							key={index}
							value={item.discountType}
							sx={{
								fontSize: '12PX',
								color: '#667085',
								textTransform: 'capitalize',
								fontWeight: '400',
								fontFamily: 'Roboto',
							}}>
							{item?.label}
						</MenuItem>
					))}
				</Select>
			</div>
			<div
				style={{
					maxWidth: place === 'SESSION' ? 'calc(100% - 60px)' : '100px',
					width: place === 'SESSION' ? 'calc(100% - 60px)' : '100px',
				}}>
				<TextField
					id='discount'
					variant='outlined'
					type='number'
					inputProps={{
						min: 0,
						max: 100,
						maxLength: 3,
						sx: {
							//   width: "240px",
							background: '#fff',
							'&::placeholder': {
								fontWeight: '400',
								fontSize: '14px',
							},

							'&.MuiOutlinedInput-input': {
								padding: '8px',
							},
						},
					}}
					sx={{
						'&& fieldset': {
							border: 'none ',
						},
					}}
					value={discount}
					onChange={(event) => handleDiscount(event.target.value)}
					// error={priceError}
					// helperText={priceError ? "Please fill valid amount" : ""}
					fullWidth
					disabled={discountMetric === ''}
				/>
			</div>
		</div>
	);
}

export default DiscountInput;
