import React from "react";
import { useAuthUser } from "react-auth-kit";
import { MentionsInput } from "react-mentions";
import { Mention } from "react-mentions";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../RolePermissionAccess/RolePermissionAccess";

function LeadCallDispositionNote(props: any) {
  const { setNoteDetails, noteDetails, employees } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );
  const branchEmployees = employees?.filter(
    (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
  );
  const otherBranchEmployees = employees?.filter(
    (item: any) =>
      item?.branchId !== authUser?.institute?.[0]?.branchId &&
      item?.allBranchAccess === true
  );
  const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];
  const activeEmployees = combinedEmployees?.filter((item: any) => {
    const isActiveAndHasLeadCardAccess =
      item.status === "ACTIVE" && item?.leadCardAccess === true;

    return isActiveAndHasLeadCardAccess;
  });
  const handleNoteChange = React.useCallback(
    (event: any) => {
      setNoteDetails({
        ...noteDetails,
        description: event.target.value,
      });
    },
    [noteDetails]
  );

  function getNameById(id: any) {
    const employee = activeEmployees?.find((e: any) => e.id === id);

    return employee
      ? `${
          employee.firstName.charAt(0).toUpperCase() +
          employee.firstName.slice(1)
        } ${
          employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)
        }`
      : "Unknown";
  }

  return (
    <div>
      <MentionsInput
        value={noteDetails.description}
        onChange={handleNoteChange}
        style={{
          control: {
            backgroundColor: "#fff",
            border: "1px solid #E8E8E8",
            fontSize: "14px",
            color: "#69696B",
            height: "auto",
            minHeight: "105px",
            maxHeight: "250px",
            overflow: "auto",
            borderRadius: "4px",
            padding: "10px 10px",
          },
          highlighter: {
            overflow: "hidden",
          },
          input: {
            margin: 0,
            border: "none",
            top: "10px",
            left: "10px",
            bottom: "10px",
            width: "calc(100% - 22px)",
            height: "auto",
            outline: "none",
          },
          "&singleLine": {
            margin: 0,
          },
          suggestions: {
            width: "222px",
            zIndex: 2,
            overflow: "auto",
            maxHeight: "140px",
            backgroundColor: "#fff",
            border: "1px solid #F0F0F0",
            borderRadius: "6px",
            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
            fontSize: "14px",
            position: "absolute",
            padding: "10px",
            color: "#142C44",
            fontWeight: "500",
            lineHeight: "16px",
          },
        }}
      >
        <Mention
          data={activeEmployees}
          trigger="@"
          markup="@{{__id__}}"
          // markup="@{{firstName}}"
          style={{
            backgroundColor: "#F5F5F7",
            // color: "#667085",
            padding: "5px",
            marginRight: "5px",
            borderRadius: "20px",
          }}
          displayTransform={(id: any, firstName: any) => {
            return "@" + getNameById(id);
          }}
          onAdd={(id: any) => {
            setNoteDetails((prevDetails: any) => {
              if (!prevDetails?.mentionedIds?.includes(id)) {
                return {
                  ...prevDetails,
                  mentionedIds: [...prevDetails?.mentionedIds, id],
                };
              }
              return prevDetails;
            });
          }}
          renderSuggestion={(suggestion: any) => (
            <div
              style={{
                paddingBottom: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
            >
              <div
                style={{
                  width: "34px",
                  height: "34px",
                  fontSize: "16px",
                  alignItems: "center",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  background: "#F5F5F7",
                  border: "1px solid #EEEEF4",
                  color: "#667085",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
              >
                {suggestion?.firstName?.charAt(0)}
              </div>
              {suggestion.firstName} {suggestion.lastName}
            </div>
          )}
        />
      </MentionsInput>
    </div>
  );
}

export default LeadCallDispositionNote;
