import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import AddLeadTab from "./Tabs/AddLead/AddLeadTab";
import CustomizeTab from "./Tabs/CustomizeTab/CustomizeTab";
import styles from "./Customize.module.css";

function Customize(props: any) {
  const { GetCustomFields, customFields, GetAddLeadForms } = props;
  const instituteFromLocalStorage = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const [value, setValue] = useState<number>(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClickQuery = (tabType: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tabOpen", tabType);
    if (tabType === "parameter") {
      queryParams.delete("filterCreator");
      queryParams.delete("filteredStatus");
      queryParams.delete("searchTerm");
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const modalTabOpen = urlSearchParams.get("tabOpen");
    if (instituteFromLocalStorage?.id) {
      if (modalTabOpen) {
        switch (modalTabOpen) {
          case "addLead":
            setValue(0);
            GetAddLeadForms(instituteFromLocalStorage?.id);
            break;
          case "parameter":
            GetCustomFields(instituteFromLocalStorage?.id);
            setValue(1);
            break;

          default:
            setValue(0);
            GetAddLeadForms(instituteFromLocalStorage?.id);
            break;
        }
      } else {
        setValue(0);
        GetAddLeadForms(instituteFromLocalStorage?.id);
      }
    }
  }, []);

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          mt: 1,
          mb: 1,
          ml: 4,
          backgroundColor: "white",
          borderBottom: "1px solid #E9E9E9",
          "& .MuiTabs-flexContainer": {
            gap: "24px",
          },
          "& .MuiTabs-indicator": {
            height: "1px",
          },
        }}
      >
        <Tab
          label="Add Lead"
          {...a11yProps(0)}
          onClick={() => {
            handleTabClickQuery("addLead");
            GetAddLeadForms(instituteFromLocalStorage?.id);
          }}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
            "& .Mui-selected": {
              color: "#11C89B",
            },
          }}
        />
        <Tab
          label="Customize parameter"
          {...a11yProps(1)}
          onClick={() => {
            GetCustomFields(instituteFromLocalStorage?.id);
            handleTabClickQuery("parameter");
          }}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
            "& .Mui-selected": {
              color: "#11C89B",
            },
          }}
        />
      </Tabs>
      <div
        className={styles.customizeTabContentWrap}
        style={{
          maxHeight: "calc(100vh - 180px)",
          minHeight: "calc(100vh - 180px)",
          overflowY: "auto",
        }}
      >
        {value === 0 && <AddLeadTab />}
        {value === 1 && <CustomizeTab existingFields={customFields} />}
      </div>
    </div>
  );
}

export default Customize;
