import React, { useCallback, useState } from "react";
import styles from "./CustomizedField.module.css";
import { TextField, Checkbox, FormControlLabel, Button } from "@mui/material";
import deleteImage from "./Images/delete.svg";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import CancelBtn from "../../../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../../../components/Buttons/SaveBtn";
import EditIconBtn from "../../../../../../../../components/Buttons/EditIconBtn";
import DeleteBtn from "../../../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../../../components/CommonPopups/DeletePopup";

function CustomizedCheckboxField(props: any) {
  const {
    field,
    onFieldChange,
    onDelete,
    isCheckboxFieldFocused,
    setIsCheckboxFieldFocused,
    leadsFromResponseChecked,
    edit = false,
    deleteAction = false,
    mandatory = false,
    isNameUniqueCheck,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [answers, setAnswers] = useState<any[]>([]);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddAnswer = () => {
    setAnswers([...answers, { answerId: "", answer: "", isValid: true }]);
  };

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const generateId = (name: any) => {
    const withoutSpaces = name.replace(/\s+/g, "_").toLowerCase();
    return withoutSpaces;
  };

  const handleDeleteConfirmed = () => {
    setShowDeletePopup(false);
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    setShowDeletePopup(false);
  };

  // const handleAnswerChange = (index: number, value: string) => {
  //   setAnswers((prevAnswers) => {
  //     const newAnswers = [...prevAnswers];
  //     newAnswers[index].answer = value;
  //     newAnswers[index].answerId = value;
  //     return newAnswers;
  //   });
  // };
  const handleAnswerChange = (index: number, value: string) => {
    const updatedAnswers = answers.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          answer: value,
          answerId: value,
          isValid: true,
        };
      }
      return item;
    });

    const uniqueAnswers = new Set<string>();
    const finalAnswers = updatedAnswers.map((item) => {
      const isDuplicate = uniqueAnswers.has(item.answer.toLowerCase());
      uniqueAnswers.add(item.answer.toLowerCase());
      return { ...item, isValid: !isDuplicate || item.answer === "" };
    });

    setAnswers(finalAnswers);
  };

  const handleRemoveAnswerField = (index: number) => {
    setAnswers((prevAnswers) => prevAnswers.filter((_, i) => i !== index));
  };

  const capitalizeSentences = (text: string) => {
    let lowercasedText = text.toLowerCase();

    let capitalizedText = lowercasedText.replace(
      /(^\s*\w|[\.\!\?]\s*\w)/g,
      function (match) {
        return match.toUpperCase();
      }
    );

    return capitalizedText;
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  const hasEmptyAnswer = answers?.some((item) => item.answer === "");

  const handleCancelClick = () => {
    setIsEditing(false);
    setQuestion("");
  };

  const handleSaveClick = () => {
    const newOptions = answers.map((answer) => ({
      name: answer.answer,
      id: generateId(answer.answer),
    }));

    onFieldChange({
      ...field,
      fieldName: question,
      options: newOptions,
    });

    setIsEditing(false);
  };

  React.useEffect(() => {
    setAnswers(
      field?.options?.map((d: any) => {
        return { answerId: d?.id, answer: d?.name, isValid: true };
      })
    );
    setQuestion(field?.fieldName);
  }, [field, isEditing]);
  return (
    <div className={styles.customizedFieldWrapper}>
      <div className={styles.customizedFieldTop}>
        <div className={styles.label}>
          {capitalizeSentences(field.fieldName)}
        </div>
        {!isEditing && (
          <div className={styles.customizedFieldTopRight}>
            {edit && <EditIconBtn onClick={handleEditClick} />}
            {deleteAction && (
              <DeleteBtn
                icon={deleteImage}
                onClick={() => setShowDeletePopup(!showDeletePopup)}
              />
            )}
            {showDeletePopup && (
              <DeletePopup
                open={showDeletePopup}
                onClose={handleDeleteCancelled}
                noBtnClick={handleDeleteCancelled}
                yesBtnClick={handleDeleteConfirmed}
                title={"Are you sure you want to delete this question?"}
              />
            )}
          </div>
        )}
      </div>
      {(field?.options?.length === 0 || isEditing) && (
        <TextField
          placeholder="Write a question"
          disabled={!isEditing}
          fullWidth
          variant="outlined"
          size="small"
          value={isEditing ? question : ""}
          onChange={handleQuestionChange}
          inputProps={{
            style: {
              padding: "8px 12px",
              backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
              borderRadius: "4px",
            },
          }}
          draggable={isCheckboxFieldFocused === false}
          onFocus={() => {
            setIsCheckboxFieldFocused(true);
          }}
          onBlur={() => {
            setIsCheckboxFieldFocused(false);
          }}
          error={isEditing && !isNameUniqueCheck(question, field?.index)}
          helperText={
            isEditing && !isNameUniqueCheck(question, field?.index)
              ? "Field label already exist"
              : ""
          }
        />
      )}

      {isEditing ? (
        <div>
          {answers?.map((item, index) => (
            <TextField
              fullWidth
              key={`answer-${index}`}
              variant="outlined"
              size="small"
              value={item.answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              InputProps={{
                endAdornment: (
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer", color: "#667085" }}
                    onClick={() => handleRemoveAnswerField(index)}
                  />
                ),
                style: {
                  backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
                  borderRadius: "4px",
                },
              }}
              sx={{
                "&.MuiTextField-root": {
                  marginTop: "12px",
                },
              }}
              error={!item.isValid}
              helperText={!item.isValid ? "Answer already exist" : ""}
            />
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              component="label"
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                display: "flex",
              }}
              startIcon={<AddIcon />}
              onClick={handleAddAnswer}
            >
              Add an answer
            </Button>

            <div className={styles.btnsWrap}>
              <CancelBtn onClick={handleCancelClick} />
              <SaveBtn
                onClick={handleSaveClick}
                disabled={
                  !question.trim() ||
                  !isNameUniqueCheck(question, field?.index) ||
                  answers?.length === 0 ||
                  hasEmptyAnswer ||
                  answers?.filter((item: any) => item.isValid === false)
                    ?.length > 0
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {field?.options?.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              label={
                <div style={{ fontSize: "12PX", color: "#667085" }}>
                  {item?.name}
                </div>
              }
              control={
                <Checkbox
                  size="small"
                  sx={{
                    strokeWidth: 1,
                    color: "#D0D5DD",
                  }}
                  disabled
                />
              }
            />
          ))}

          {mandatory && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={handleMandatoryChange}
                  checked={field?.required}
                  sx={{
                    strokeWidth: 1,
                    color: "#D0D5DD",
                  }}
                />
              }
              label="Mandatory"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  lineHeight: "20px",
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default CustomizedCheckboxField;
