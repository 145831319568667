import { Box, FormGroup, Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Attendance.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import getAccessStatus, {
  getSubDomainAccessStatus,
} from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import add from "../../../../assets/images/add.svg";
import locationIcon from "../../../../assets/images/location.svg";
import eye from "../../../../assets/images/eye.svg";
import clock from "../../../../assets/images/clockTask.svg";
import FilterDrawer from "../../../../components/FilterDrawer/FilterDrawer";
import FilterChipsWrap from "../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterTypeWrap from "../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterSearchField from "../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterCheckbox from "../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import ClearFilter from "../../../../components/ClearFilter/ClearFilter";
import AttendanceSkeleton from "../../../../components/Skeletons/Attendance/AttendanceSkeleton";
import noresult from "../../../../assets/images/no-result.svg";

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

const Attendance = (props: any) => {
  const {
    GetAttendanceList,
    attendanceListData,
    GetAttendanceMarkers,
    attendanceMarkerData,
    loading,
    attendanceMarkerLoading,
  } = props;
  const [newDate, setNewDate] = useState<any>(dayjs());
  const [selectedDate, setSelectedDate] = React.useState<any>(
    dayjs().unix() * 1000
  );
  const [monthString, setMonthString] = useState(dayjs().format("YYYY-MM"));
  const [dateDisable, setDateDisable] = useState<boolean>(false);
  const [searchBatch, setSearchBatch] = useState("");
  const [batchList, setBatchList] = useState<any>();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [markerDatesForCalendar, setMarkerDateForCalendar] = useState<any>();
  const navigate = useNavigate();
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [filterCheckedCourses, setFilterCheckedCourses] = useState<
    CheckedCourse[]
  >([]);
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);
  const [searchCourse, setSearchCourse] = useState<any>("");
  const [searchBranch, setSearchBranch] = useState<any>("");

  const [showAllCourses, setShowAllCourses] = useState<boolean>(false);
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const [courseOption, setCourseOption] = useState<any>(courses);
  const { userById } = useSelector((state: any) => state.userDetails);
  const location = useLocation();
  const [triggerApi, setTriggerApi] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );

  const hasAllBatchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasAllBatchViewAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "VIEW_ONLY",
    userById?.rolePermission
  );

  useEffect(() => {
    setCourseOption(courses);
  }, [courses]);

  const auth = useAuthUser();
  const user = auth();

  useEffect(() => {
    if (location?.state?.selectedDate) {
      const dateFromLocation = new Date(Number(location?.state?.selectedDate));
      const month = dateFromLocation.getMonth() + 1;
      const year = dateFromLocation.getFullYear();
      setCurrentMonth(month);
      setCurrentYear(year);
      setNewDate(Number(location?.state?.selectedDate));
      setSelectedDate(Number(location?.state?.selectedDate));
      // setTriggerApi(!triggerApi);
    } else {
      getAttendanceData(selectedDate, currentMonth, currentYear);
    }
    setIsInitialized(true);
  }, [location.state]);

  useEffect(() => {
    if (!isInitialized) return;
    getAttendanceData(selectedDate, currentMonth, currentYear);
  }, [
    GetAttendanceList,
    // newDate,
    GetAttendanceMarkers,
    currentMonth,
    currentYear,
    selectedDate,
    // triggerApi,
  ]);

  const getAttendanceData = (val1: any, val2: any, val3: any) => {
    const date = new Date(val1);
    const dateForAttendance = date.setUTCHours(0, 0, 0, 0);
    const privilagedUser = user?.institute[0]?.role === "Super Admin";
    const data = {
      userId: privilagedUser
        ? user?.institute[0]?.instituteId
        : user?.institute[0]?.role === "Admin"
        ? user?.institute[0]?.branchId
        : user?.institute[0]?.id,
      timestamp: dateForAttendance,
      isSuperAdmin: privilagedUser,
      isAdmin: user?.institute[0]?.role === "Admin",
    };
    GetAttendanceList(data);
    const { startOfMonth, endOfMonth } = getMonthStartAndEndDates(val2, val3);
    const dataForMarkers = {
      userId: privilagedUser
        ? user?.institute[0]?.instituteId
        : user?.institute[0]?.role === "Admin"
        ? user?.institute[0]?.branchId
        : user?.institute[0]?.id,
      startDate: startOfMonth?.timestamp,
      endDate: endOfMonth?.timestamp,
      isSuperAdmin: privilagedUser,
      isAdmin: user?.institute[0]?.role === "Admin",
    };
    GetAttendanceMarkers(dataForMarkers);
  };

  useEffect(() => {
    if (attendanceListData && Object.keys(attendanceListData).length > 0) {
      setBatchList(attendanceListData);
    } else {
      setBatchList([]);
    }
  }, [attendanceListData]);

  useEffect(() => {
    if (attendanceMarkerData && Object.keys(attendanceMarkerData).length > 0) {
      const calenderMarkersData =
        attendanceMarkerData &&
        attendanceMarkerData?.filter((data: any) => data?.batchCalender);
      const calendersDate =
        calenderMarkersData &&
        calenderMarkersData?.flatMap((batch: any) =>
          batch.batchCalender.map(
            (calendar: any) => calendar?.status === "TRUE" && calendar.date
          )
        );
      const removedDuplicates = Array.from(new Set(calendersDate));
      setMarkerDateForCalendar(
        removedDuplicates?.filter((d: any) => d !== false)
      );
    } else {
      setMarkerDateForCalendar([]);
    }
  }, [attendanceMarkerData]);

  const filteredBatchList =
    attendanceListData && batchList
      ? batchList?.filter((student: any) => {
          const courseIsChecked =
            (filterCheckedCourses && filterCheckedCourses?.length === 0) ||
            filterCheckedCourses?.some(
              (item) => item?.id === student?.courseId
            );

          const branchIsChecked =
            (filterCheckedBranches && filterCheckedBranches?.length === 0) ||
            filterCheckedBranches?.some(
              (item) => item?.id === student?.branchId
            );

          return courseIsChecked && branchIsChecked;
        })
      : [];

  const handleDateChange = (date: any) => {
    if (date !== null) {
      const dateUnix: any = date.unix() * 1000;
      setSelectedDate(dateUnix);
    }
  };

  const handleMonthChange = (newMonth: any) => {
    setMonthString(newMonth.format("YYYY-MM"));
  };

  const getMonthStartAndEndDates = (month: any, year: any) => {
    const startOfMonth = moment
      .utc(`${year}-${month}`, "YYYY-MM")
      .startOf("month");
    const endOfMonth = moment.utc(`${year}-${month}`, "YYYY-MM").endOf("month");

    const formatDate = (date: any) => ({
      timestamp: date.valueOf(),
    });

    return {
      startOfMonth: formatDate(startOfMonth),
      endOfMonth: formatDate(endOfMonth),
    };
  };

  const handleFilterApply = React.useCallback(() => {
    handleFilterStudents(checkedBranches, checkedCourses);

    setFilterApplied(
      checkedBranches?.length === 0 && checkedCourses?.length === 0
        ? false
        : true
    );
  }, [setFilterApplied, checkedBranches, checkedCourses]);

  const handleFilterStudents = React.useCallback(
    (branchArray: any, courseArray: any) => {
      setFilterCheckedCourses(courseArray);
      setFilterCheckedBranches(branchArray);
    },
    [setFilterCheckedCourses, setFilterCheckedBranches]
  );

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setCheckedBranches([]);
    setCheckedCourses([]);
    setFilterCheckedBranches([]);
    setFilterCheckedCourses([]);
  }, [
    setFilterApplied,
    setCheckedBranches,
    setCheckedCourses,
    setFilterCheckedBranches,
    setFilterCheckedCourses,
  ]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranches);
    setCheckedCourses(filterCheckedCourses);
  }, [filterCheckedBranches, filterCheckedCourses]);

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    const courseToAdd: CheckedCourse = { id, courseName };
    if (checkedCourses.some((item) => item.id === id)) {
      setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, courseToAdd]);
    }
  };

  const clearCheckedCourse = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const filteredBranches =
    branches &&
    branches?.filter((branch: any) => {
      const searchTermMatch =
        branch.branchName.toLowerCase().indexOf(searchBranch.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedBranch = showAllBranches
    ? branches && filteredBranches
    : branches && filteredBranches.slice(0, 5);

  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName.toLowerCase().indexOf(searchCourse.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedCourses = showAllCourses
    ? courses && filteredCourses
    : courses && filteredCourses.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const filteredValue = (
    <>
      {filterCheckedBranches?.length > 0 &&
        filterCheckedBranches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedCourses?.length > 0 &&
        filterCheckedCourses?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.courseName}
          </div>
        ))}
    </>
  );

  React.useEffect(() => {
    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10);
        const convertToDays = markerDatesForCalendar?.map((timestamp: any) => {
          const date = new Date(timestamp);
          return date.getDate();
        });
        if (
          convertToDays &&
          !isNaN(dayNumberAsNumber) &&
          convertToDays?.includes(dayNumberAsNumber)
        ) {
          dayElement.classList.add("demo-day");
        } else {
          dayElement.classList.remove("demo-day");
        }
      }
    });
  }, [markerDatesForCalendar]);

  const givenDate = new Date(selectedDate).toISOString().split("T")[0];

  const todayDate = new Date().toISOString().split("T")[0];

  const datesMatch = givenDate === todayDate;

  const renderBranch = (val: any) => {
    const result = branches && branches?.find((res: any) => res?.id === val);
    return result?.branchName?.length > 22
      ? `${result?.branchName?.slice(0, 22)}...`
      : result?.branchName;
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: "14px 16px",
        background: "#EEEEEE",
        // maxHeight: "calc(100vh - 65px)",
        // position: "relative",
        maxHeight: "90vh",
        position: "relative",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          display: "flex",
          width: "100%",
          maxHeight: "100vh",
          minHeight: "100vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            width: "350px",
            borderRight: "1px solid #E9E9E9",
            // overflowY: "hidden",
          }}
          className="datepicker-container"
        >
          <div className={styles.calendarHeader}>
            <div className={styles.headerText}>Calendar</div>
            <>
              {true ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    value={dayjs(newDate)}
                    onChange={(date: any) => {
                      setNewDate(date);
                      handleDateChange(date);
                      setSearchBatch("");
                      setTriggerApi(!triggerApi);
                      // const dateUnix: any = date.unix() * 1000;
                      // const queryParams = new URLSearchParams(
                      //   window.location.search
                      // );
                      // queryParams.set("selectedDate", dateUnix);
                      // const queryString = queryParams.toString();
                      // const newUrl = `${window.location.pathname}${
                      //   queryString ? `?${queryString}` : ""
                      // }`;

                      // window.history.pushState({}, "", newUrl);
                    }}
                    displayStaticWrapperAs="desktop"
                    disablePast={
                      user?.institute[0]?.role === "Super Admin" ||
                      user?.institute[0]?.role === "Admin" ||
                      hasAllBatchAccess ||
                      hasAllBatchViewAccess
                        ? false
                        : true
                    }
                    disableFuture={true}
                    disabled={dateDisable}
                    onMonthChange={(newMonth: any) => {
                      const date = new Date(newMonth);
                      const month = date.getMonth() + 1;
                      setCurrentMonth(month);
                      handleMonthChange(newMonth);
                      const yearChanged = new Date(newMonth);
                      const year = yearChanged.getFullYear();
                      setCurrentYear(year);
                      setTriggerApi(!triggerApi);
                    }}
                    className={`${styles.demoCalander} custom-calendar-button`}
                    sx={{
                      // "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                      //   backgroundColor: "#fff",
                      //   border: "1px solid #11C89B",
                      //   color: "#11C89B",
                      // },
                      // "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled":
                      //   {
                      //     backgroundColor: "#fff",
                      //     border: "1px solid #11C89B",
                      //     color: "#11C89B",
                      //   },
                      ".css-cwhad8-MuiDateCalendar-root": {
                        marginLeft: "-20px",
                      },
                      ".css-d8mzk3-MuiPickersLayout-root": {
                        minWidth: "300px",
                      },
                      "& .MuiButtonBase-root.MuiPickersDay-today": {
                        border: "0px",
                        color: "#11C89B",
                      },
                      "& .MuiButtonBase-root.MuiPickersDay-today.Mui-disabled":
                        {
                          color: "rgba(0, 0, 0, 0.38)",
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-today.Mui-selected":
                        {
                          border: "1px solid #11C89B",
                          color: "#fff",
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.batch-day":
                        {
                          color: "#11C89B",
                          backgroundColor: "rgba(17, 200, 155, 0.16)",
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.batch-day": {
                        backgroundColor: "rgba(17, 200, 155, 0.16)",
                      },
                      "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled":
                        {
                          backgroundColor: "#fff",
                          color: "rgba(0, 0, 0, 0.38)",
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled.Mui-selected":
                        {
                          backgroundColor: "#fff",
                          border: "1px solid #11C89B",
                          color: "#11C89B",
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.demo-day": {
                        position: "relative",
                      },
                      "& .MuiButtonBase-root.MuiPickersDay-root.demo-day.Mui-selected::after":
                        {
                          position: "absolute",
                          width: "3px",
                          height: " 3px",
                          background: "#fff",
                          borderRadius: "50%",
                          bottom: "4px",
                          content: '""',
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.demo-day::after":
                        {
                          position: "absolute",
                          width: "3px",
                          height: " 3px",
                          background: "#11c89b",
                          borderRadius: "50%",
                          bottom: "4px",
                          content: '""',
                        },
                      "& .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled.demo-day::after":
                        {
                          display: "none",
                        },
                    }}
                    onYearChange={(newYear: any) => {
                      const year = dayjs(newYear).year();
                      setCurrentYear(year);
                      setTriggerApi(!triggerApi);
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <>
                  <div style={{ padding: "10px" }}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height="40px"
                      style={{
                        borderRadius: "3px",
                        marginBottom: "10px",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height="290px"
                      style={{
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </>
              )}
            </>
          </div>
        </Box>
        <Box
          sx={{
            background: "#fff",
            width: "100%",
          }}
        >
          <div className={styles.batchHeader}>
            <div className={styles.headerText}>Batches</div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <div>
                <TextField
                  type="search"
                  value={searchBatch}
                  onChange={(e) => {
                    setSearchBatch(e.target.value);
                    const searchedBatches = attendanceListData?.filter(
                      (d: any) =>
                        d?.batchName?.toLowerCase().includes(e.target.value)
                    );
                    setBatchList(searchedBatches);
                  }}
                  placeholder="Search Batches"
                  sx={{
                    width: "380px",
                    borderRadius: "4px",
                  }}
                  size="small"
                  inputProps={{
                    sx: {
                      width: "380px",
                      "&::placeholder": {
                        fontWeight: "400",
                        fontSize: "12px",
                      },

                      "& .MuiOutlinedInput-input": {
                        padding: "10.5px 14px",
                      },
                    },
                  }}
                />{" "}
              </div>
              <div>
                <FilterDrawer
                  handleFilterApply={handleFilterApply}
                  handleResetClick={handleResetClick}
                  activeFilter={filterApplied}
                  cancelClick={cancelClick}
                  closeSetFilter={handleFilterClose}
                >
                  <div
                    style={{
                      padding: "22px 32px",
                      overflowY: "auto",
                      maxHeight: "calc(100% - 110px)",
                    }}
                  >
                    <FilterChipsWrap>
                      {checkedBranches.length > 0 &&
                        checkedBranches.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.branchName}
                            clearValue={item.id}
                            closeFunction={clearCheckedBranch}
                          />
                        ))}

                      {checkedCourses.length > 0 &&
                        checkedCourses.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.courseName}
                            clearValue={item.id}
                            closeFunction={clearCheckedCourse}
                          />
                        ))}
                    </FilterChipsWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Course" />
                      <FilterSearchField
                        value={searchCourse}
                        onChange={handleCourseSearch}
                        placeholder="Search Course"
                      />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {courseOption &&
                            displayedCourses?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedCourses.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterCourseChecked(
                                    item.id,
                                    item.courseName
                                  )
                                }
                                label={item.courseName.toLowerCase()}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMoreCourses}
                        showAll={showAllCourses}
                        filteredState={courseOption}
                      />
                    </FilterTypeWrap>

                    {hasAllBranchAccess && (
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Branch" />

                        <FilterSearchField
                          value={searchBranch}
                          onChange={handleBranchSearch}
                          placeholder="Search Branch"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branches &&
                              displayedBranch?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedBranches.some(
                                    (data) => data.id === item.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBranchChecked(
                                      item.id,
                                      item.branchName
                                    )
                                  }
                                  label={item.branchName.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreBranches}
                          showAll={showAllBranches}
                          filteredState={filteredBranches}
                        />
                      </FilterTypeWrap>
                    )}
                  </div>
                </FilterDrawer>
              </div>
            </div>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "78vh",
            }}
          >
            {loading || attendanceMarkerLoading ? (
              <AttendanceSkeleton />
            ) : (
              <>
                {filteredBatchList?.length > 0 ? (
                  filteredBatchList?.map((item: any) => {
                    return (
                      <div className={styles.batchBody}>
                        <div className={styles.bodyHeader}>
                          <div className={styles.batchTitle}>
                            {item?.batchName}
                          </div>
                          <div className={styles.courseTitle}>
                            {item?.courseName}
                          </div>
                        </div>
                        <div className={styles.bodyFooter}>
                          <div className={styles.location}>
                            <div className={styles.imageAlignmentWithText}>
                              <img
                                src={locationIcon}
                                alt="location"
                                className={styles.images}
                              />
                              <div className={styles.locationText}>
                                {renderBranch(item?.branchId)}
                              </div>
                            </div>
                            <div className={styles.imageAlignmentWithText}>
                              <img
                                src={clock}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  marginLeft: "5px",
                                }}
                              />
                              <div className={styles.locationText}>
                                {" "}
                                {moment
                                  .unix(item?.batchStartTime)
                                  ?.format("hh:mmA")}
                                -
                                {moment
                                  .unix(item?.batchEndTime)
                                  ?.format("hh:mmA")}
                              </div>
                            </div>
                          </div>
                          {!item?.attendanceDone &&
                          (user?.institute[0]?.role === "Super Admin" ||
                            user?.institute[0]?.role === "Admin" ||
                            ((hasAllBatchAccess || hasAllBatchViewAccess) &&
                              datesMatch)) ? (
                            <div
                              className={styles.imageAlignmentWithText}
                              onClick={() => {
                                navigate(
                                  `/app/Attendance/${item?.id}?selectedDate=${selectedDate}`,
                                  {
                                    state: {
                                      item: item,
                                      selectedDate: selectedDate,
                                    },
                                  }
                                );
                              }}
                            >
                              <img
                                src={add}
                                alt="add"
                                className={styles.images}
                              />
                              <div className={styles.addAttendanceText}>
                                {" "}
                                Add Attendance
                              </div>
                            </div>
                          ) : (
                            <>
                              {item?.attendanceDone && (
                                <div
                                  className={styles.imageAlignmentWithText}
                                  onClick={() => {
                                    navigate(
                                      `/app/Attendance/${item?.id}?selectedDate=${selectedDate}`,
                                      {
                                        state: {
                                          item: item,
                                          selectedDate: selectedDate,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    src={eye}
                                    alt="eye"
                                    className={styles.images}
                                  />
                                  <div className={styles.viewAttendanceText}>
                                    View Attendance
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <Box
                      sx={{
                        textAlign: "center",
                        position: "relative",
                        top: "30%",
                      }}
                    >
                      <div>
                        <img src={noresult} alt="no-result" />
                      </div>
                      <div className={styles.NoResult}>
                        No Schedules for this day
                      </div>
                    </Box>
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </Box>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </Box>
  );
};

export default Attendance;
