import React, { useState } from "react";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DemoWeekTimings from "./components/DemoWeekTimings";
import { Box, Grid } from "@mui/material";
import styles from "../Managebatches.module.css";
import {
  convertToAdjustedTimestamp,
  timeZoneConverter,
} from "../../../../../../components/Formatter/Formatter";
import dayjs from "dayjs";
import DeletePopup from "../../../../../../components/CommonPopups/DeletePopup";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import CancelPopup from "../../../../../../components/CommonPopups/CancelPopup";
import { checkObjectsEqual } from "../../../../../../components/CommonFunctions/CommonFunctions";
import { getBatchList, updateBatch } from "../../service/Batchslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";

function EditDemoBatchTiming(props: any) {
  const { setShowEdit, showEdit } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { batchById, batchUpdateSuccess, batchUpdateLoading } = useSelector(
    (state: any) => state?.batch
  );

  const [batchDetails, setBatchDetails] = React.useState<any>({
    instituteName: "",
    instituteId: "",
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    startDate: `${moment().unix()}`,
    endDate: `${moment().unix()}`,
    startTime: 0,
    endTime: 0,
    minimumSeats: "",
    maximumSeats: "",
    batchType: [],
    batchSchedule: [{ day: "", startTime: 0, endTime: 0 }],
    status: "ACTIVE",
    isFirstBatch: false,
    batchId: "",
    batchPrice: 0,
    coursePrice: 0,
    discountMetric: "",
    discount: 0,
    studentCount: 0,
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    assignees: [],
  });
  const [startDate, setStartDate] = React.useState<any>(moment().unix());
  const [demoDayName, setDemoDayName] = useState("");
  const [errorStartDateMszs, setErrorStartDateMszs] = useState<boolean>(false);
  const [errorStartDayMsg, setErrorStartDayMsg] = useState<boolean>(false);
  const [errorTimeMsg, setErrorTimeMsg] = useState<boolean>(false);
  const [deletePayment, setDeletePayment] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [isDateChanged, setIsDateChanged] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);
  const [newBatchRequest, setNewBatchRequest] = useState<any>([
    {
      day: "SUN",
      batchSessions: [],
    },
    {
      day: "MON",
      batchSessions: [],
    },
    {
      day: "TUE",
      batchSessions: [],
    },
    {
      day: "WED",
      batchSessions: [],
    },
    {
      day: "THU",
      batchSessions: [],
    },
    {
      day: "FRI",
      batchSessions: [],
    },
    {
      day: "SAT",
      batchSessions: [],
    },
  ]);
  const [errorEndDatePastMsg, setErrorEndDatePastMsg] =
    useState<boolean>(false);
  const [errorEndDateMsg, setErrorEndDateMsg] = useState<boolean>(false);

  const handleChange = React.useCallback(
    (event: any) => {
      setBatchDetails({
        ...batchDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [batchDetails]
  );

  const handleScheduleChange = React.useCallback(
    (batchSchedule: []) => {
      setBatchDetails({
        ...batchDetails,
        batchSchedule: batchSchedule,
      });
    },
    [batchDetails]
  );

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      if (id === "startDate") {
        const newStartDate: any = new Date(date).setHours(0, 0, 0, 0);
        const newEndDate: any = new Date(date).setHours(23, 59, 59, 0);

        setStartDate(date.unix());
        setBatchDetails({
          ...batchDetails,
          startDate: timeZoneConverter(newStartDate),
          endDate: timeZoneConverter(newEndDate),
        });
        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const dayOfWeek = new Date(date)?.getDay();
        const dayName = dayNames[dayOfWeek];
        setDemoDayName(dayName);
      } else if (id === "endDate") {
        const newEndDate = date.unix();
        setBatchDetails({
          ...batchDetails,
          endDate: `${newEndDate}`,
        });
      }
    }
  };

  const isScheduleSame = checkObjectsEqual(
    batchDetails?.batchSchedule,
    batchById?.batchSchedule
  );

  const cancelButtonClick = () => {
    if (isScheduleSame) {
      navigate(`/app/manage/Batches?batchId=${batchById.id}`);
    } else {
      setCancelModal(true);
    }
  };
  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    setShowEdit(false);
    navigate(`/app/manage/Batches?batchId=${batchById.id}`);
  };

  const handleDeleteClosePopup = () => {
    setDeletePayment("");
    setDeleteModal(false);
  };

  const handleDeleteOpenPopup = (batchId: any, demoId: any, enquiryId: any) => {
    setDeleteModal(true);
  };

  const handleDeletePayment = async (event: any) => {
    event.preventDefault();
    try {
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        dispatch<any>(
          getBatchList({
            id: userFromLocalStorage.id && userFromLocalStorage.id,
            batchConductType: "ALL",
          })
        );
      }
      setShowEdit(false);
    }
  }, [batchUpdateSuccess]);

  React.useEffect(() => {
    if (batchById) {
      const userFromLocalStorage = JSON.parse(
        localStorage.getItem("ACTIVE_INSTITUTE")!
      );
      const instituteId = authUser?.institute[0]?.instituteId;
      const { students, courseImage, ...filteredBatchById } = batchById;
      const batchNewTimings = batchById?.batchSchedule?.filter(
        (d: any) => d?.batchSessions?.length > 0
      );

      if (batchById?.startDate === 0) {
        setStartDate(moment().unix());
      } else {
        setStartDate(convertToAdjustedTimestamp(batchById?.startDate) / 1000);
      }
      setBatchDetails({
        ...filteredBatchById,
        batchSchedule: [
          {
            day: batchNewTimings[0]?.day,
            startTime: batchNewTimings[0]?.batchSessions[0]?.startTime,
            endTime: batchNewTimings[0]?.batchSessions[0]?.endTime,
          },
        ],
        batchName: batchById?.batchName,
        batchId: batchById?.id,
        batchType: batchById?.batchType,
        instituteId: instituteId && instituteId,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        startDate:
          batchById?.startDate === 0
            ? moment().unix()
            : convertToAdjustedTimestamp(batchById?.startDate) / 1000,
        endDate:
          batchById?.endDate === 0
            ? moment().unix()
            : convertToAdjustedTimestamp(batchById?.endDate) / 1000,
      });
    }
  }, [batchById, showEdit]);

  const selectedStartDate =
    batchDetails.startDate && new Date(parseInt(batchDetails.startDate) * 1000);
  const selectedEndDate =
    batchDetails.endDate && new Date(parseInt(batchDetails.endDate) * 1000);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newState = [...newBatchRequest];
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayOfWeek = new Date()?.getDay();
    const dayName = dayNames[dayOfWeek];

    const foundDay = newState.find(
      (item) => item.day === (demoDayName === "" ? dayName : demoDayName)
    );

    let updatedBatchRequests: any;

    if (foundDay && foundDay.batchSessions) {
      const newDemoObj = {
        startTime:
          batchDetails?.batchSchedule[0]?.startTime === 0
            ? Math.floor(Date.now() / 1000)
            : batchDetails?.batchSchedule[0]?.startTime,
        endTime: batchDetails?.batchSchedule[0]?.endTime,

        sessionName: "Demo",
      };
      const validDayName = demoDayName === "" ? dayName : demoDayName;
      updatedBatchRequests = newState.map((day: any) => {
        if (day.day !== validDayName) {
          return { ...day, batchSessions: [] };
        }
        return {
          ...day,
          batchSessions: [newDemoObj],
        };
      });
    }

    try {
      selectedStartDate > selectedEndDate
        ? setErrorEndDatePastMsg(true)
        : setErrorEndDatePastMsg(false);
      batchDetails.startDate === ""
        ? setErrorStartDateMszs(true)
        : setErrorStartDateMszs(false);
      batchDetails.endDate === ""
        ? setErrorEndDateMsg(true)
        : setErrorEndDateMsg(false);

      let daysName: any =
        batchDetails.startDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.startDate) / 1000
          : batchDetails?.startDate === 0
          ? moment().unix()
          : batchDetails?.startDate;

      const startDay = moment
        .unix(parseInt(daysName))
        .format("ddd")
        .toUpperCase();

      const matchingDay = updatedBatchRequests.find(
        (day: any) => day.day === startDay
      );

      let error: boolean = false;
      let errorTime: boolean = false;

      if (
        matchingDay &&
        (matchingDay.batchSessions[0]?.startTime === 0 ||
          matchingDay.batchSessions[0]?.endTime === 0)
      ) {
        setErrorStartDayMsg(true);
        error = true;
      } else {
        setErrorStartDayMsg(false);
        error = false;
      }
      const validBatchSessions =
        updatedBatchRequests &&
        updatedBatchRequests?.filter((item: any, index: any) => {
          if (
            item?.batchSessions?.length > 0 &&
            item?.batchSessions[0]?.startTime !== 0
          ) {
            return item;
          }
        });

      const getMatchingTime =
        validBatchSessions &&
        validBatchSessions?.filter(
          (obj: any) =>
            obj.batchSessions[0]?.startTime === obj.batchSessions[0]?.endTime ||
            obj.batchSessions[0]?.startTime >= obj.batchSessions[0]?.endTime
        );

      if (getMatchingTime?.length > 0) {
        setErrorTimeMsg(true);
        errorTime = true;
      } else {
        setErrorTimeMsg(false);
        errorTime = false;
      }

      const newArray: any = batchDetails;
      newArray.endTime =
        typeof batchDetails.endTime === "string"
          ? parseInt(batchDetails.endTime)
          : batchDetails.endTime;
      newArray.startTime =
        typeof batchDetails.startTime === "string"
          ? parseInt(batchDetails.startTime)
          : batchDetails.startTime;
      newArray.endDate = parseInt(batchDetails.endDate);
      newArray.startDate = parseInt(batchDetails.startDate);
      const dataArray: any = [];
      newArray.batchSchedule?.map((obj: any) => {
        const newObj = {
          day: obj.day,
          startTime:
            typeof obj.startTime === "string"
              ? parseInt(obj.startTime)
              : obj.startTime,
          endTime:
            typeof obj.endTime === "string"
              ? parseInt(obj.endTime)
              : obj.endTime,
        };
        dataArray.push(newObj);
        return null;
      });

      const currentDate = new Date().setHours(0, 0, 0, 0);
      const selectedDate = new Date(selectedStartDate).setHours(0, 0, 0, 0);
      let newstartDate: any = new Date(batchDetails?.startDate * 1000).setHours(
        0,
        0,
        0,
        0
      );
      let newEndDate: any = new Date(batchDetails?.endDate * 1000).setHours(
        23,
        59,
        59,
        0
      );
      newArray.batchSchedule = dataArray;
      delete newArray.batchStatus;
      batchDetails.batchName &&
        selectedStartDate <= selectedEndDate &&
        batchDetails.startDate !== "" &&
        batchDetails.endDate !== "" &&
        !error &&
        !errorTime &&
        selectedDate >= currentDate &&
        dispatch<any>(
          updateBatch({
            ...newArray,
            batchConductType: "DEMO_BATCH",
            batchSchedule: updatedBatchRequests,
            createDemo: false,
            demoRequest: {},
            updatedById: authUser?.institute[0]?.id,
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            batchScheduleChanged: true,
            basicInfoChanged: false,
            startDate:
              batchDetails?.startDate?.toString()?.length === 10
                ? timeZoneConverter(newstartDate)
                : batchDetails?.startDate === 0
                ? timeZoneConverter(moment().unix())
                : batchDetails?.startDate,
            endDate:
              batchDetails?.endDate?.toString()?.length === 10
                ? timeZoneConverter(newEndDate)
                : batchDetails?.endDate,
          })
        );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Grid>
        <div className={styles.durationLabel}>Batch Duration</div>

        <Box className={styles.durationWrapper}>
          <Grid
            container
            sx={{
              display: "flex",
            }}
            spacing={4}
          >
            <Grid item xs={12} lg={3} md={4}>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ zIndex: "99999999 !import" }}
                    label="Select Start Date"
                    format="DD MMM YYYY"
                    disablePast
                    onChange={(date: any) => {
                      handleDateChange(date, "startDate");
                    }}
                    value={startDate ? dayjs.unix(startDate) : dayjs()}
                  />
                </LocalizationProvider>
              </Box>
              {errorStartDateMszs ? (
                <div
                  style={{
                    color: "red",
                    padding: "5px 0",
                    fontSize: "12px",
                  }}
                >
                  Select start date
                </div>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs={12} lg={3} md={4}>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <DemoWeekTimings
                  handleChange={handleChange}
                  batchSchedule={batchDetails.batchSchedule}
                  handleScheduleChange={handleScheduleChange}
                  batchDetails={batchDetails}
                  setBatchDetails={setBatchDetails}
                  // add={add}
                  setIsDateChanged={setIsDateChanged}
                />
                {errorStartDayMsg && !errorTimeMsg && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "14px",
                    }}
                  >
                    Oopps! Request can not be processed
                    <br />
                    Seems like you have not scheduled time for the selected
                    start date.
                  </div>
                )}
                {errorTimeMsg && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "14px",
                    }}
                  >
                    Oopps! Request can not be processed
                    <br />
                    The start time and end time can not be the same and the end
                    time should be greater than the start time.
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid>
        {batchDetails && batchDetails?.demoStudents?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div className={styles.durationLabel}>Students</div>
              {batchDetails?.demoStudents?.map((d: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #F2F2F2",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "#989CA4",
                          color: "white",
                          width: "50px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "20px",
                        }}
                      >
                        {batchDetails?.batchConductType === "REGULAR_BATCH"
                          ? d?.studentFirstName?.charAt(0).toUpperCase()
                          : d?.studentFullName?.charAt(0).toUpperCase()}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            color: "#142C44",
                            fontSize: "13px",
                          }}
                        >
                          {batchDetails?.batchConductType === "REGULAR_BATCH"
                            ? `${d?.studentFirstName} ${d?.studentLastName}`
                            : d?.studentFullName}
                        </div>
                        <div
                          style={{
                            color: "#667085",
                            fontSize: "12px",
                          }}
                        >
                          New Enquiry
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        handleDeleteOpenPopup(
                          batchDetails?.id,
                          d?.id,
                          d?.enquiryId
                        )
                      }
                      style={{ cursor: "pointer" }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </Box>
        )}
        {deleteModal && (
          <DeletePopup
            open={deleteModal}
            onClose={handleDeleteClosePopup}
            noBtnClick={handleDeleteClosePopup}
            yesBtnClick={handleDeletePayment}
            title={"Do you really want to delete this student from the batch?"}
          />
        )}
      </Grid>
      <CancelPopup
        open={cancelModal}
        onClose={closeCancelModal}
        noBtnClick={closeCancelModal}
        yesBtnClick={cancelYesBtn}
      />
      <div className={styles.editSectionBtnWrap}>
        <CancelBtn onClick={() => cancelButtonClick()} />
        <SaveBtn onClick={handleSubmit} loader={batchUpdateLoading} />
      </div>
    </div>
  );
}

export default EditDemoBatchTiming;
