import React, { useCallback, useState, useEffect } from "react";
import styles from "./ActionableNotification.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { GetPaymentReminder } from "../../pages/app/Nurture/service/nurture.request";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { clearPaymentReminder } from "../../service/websocketNotificationsSlice";
import {
  getLeadListByInstituteAndContact,
  resetLeadById,
  resetLeadLEmiData,
  resetLeadListByContact,
  resetLeadModalState,
  resetLeadPaymentData,
  resetLeadPopupTabDatas,
  setLeadModalState,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { setToastNotification } from "./service/toastNotificationSlice";
import { checkSubDomainAccess } from "../RolePermissionAccess/RolePermissionAccess";

function PaymentReminder(props: any) {
  const {
    courses,
    setIsContentMinimized,
    active,
    onClick,
    setShowActionableNotification,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);
  const [selectedCurrency, setSelectedCurrency] = React.useState("₹");
  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const paymentReminders = useSelector(
    (state: any) => state?.websocketNotifications?.paymentReminderList
  );

  const branchList = useSelector((state: any) => state.branch.branchList);
  const instituteId = authUser?.institute[0]?.instituteId;
  const [expandedAccordion, setExpandedAccordion] = useState(true);
  const [reminderItems, setReminderItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(true);

  const findCurrency = (id: number) => {
    const currency =
      branchList.find((branch: any) => branch.id === id)?.currency || "₹";
    return currency;
  };
  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      reminderItems?.lastEvaluatedKeyReceived?.SK?.S
    );

    const lastEvaluatedSortKey = encodeURIComponent(
      reminderItems?.lastEvaluatedKeyReceived?.GSI5SK?.S
    );
    const userId = userFromLocalStorage.id;
    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetPaymentReminder(userId, lastEvaluatedKey, 2, lastEvaluatedSortKey)
        ?.then((res: any) => {
          setHasMoreData(true);
          setReminderItems((prevItems: any) => ({
            emiList: [...prevItems?.emiList, ...res?.data?.data?.emiList],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    setReminderItems(paymentReminders);
  }, [paymentReminders]);
  useEffect(() => {
    if (
      reminderItems &&
      reminderItems?.lastEvaluatedKeyReceived &&
      reminderItems?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [reminderItems]);

  const handleClearNotification = (emiId: string) => {
    emiId && dispatch<any>(clearPaymentReminder(emiId));
  };

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const ClearLeadPopupQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("leadModal");
    queryParams.delete("leadId");
    queryParams.delete("tabOpen");
    queryParams.delete("recordPayment");
    queryParams.delete("leadInstituteId");
    queryParams.delete("leadEmail");
    queryParams.delete("leadPhone");
    queryParams.delete("leadTaskId");
    queryParams.delete("leadNoteId");
    queryParams.delete("leadPaymentId");
    queryParams.delete("taskTabOpen");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetLeadListByContact());
    dispatch(resetLeadPopupTabDatas());
    dispatch(resetLeadLEmiData());
    dispatch(resetLeadPaymentData());
    dispatch(resetLeadById());
    dispatch(resetLeadModalState());
  };

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      leadId: string,
      phone: string,
      email: string,
      emiId: string
    ) => {
      ClearLeadPopupQuery();

      setIsContentMinimized(true);
      setExpandedAccordion(false);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", leadId);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "payment");

      queryParams.set("leadEmiId", emiId);

      // Update the URL with the modified query parameter
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
      handleClearNotification(emiId);
    },

    [ClearLeadPopupQuery, handleClearNotification]
  );
  return (
    <div style={{ maxHeight: "calc(100% - 68.8px) ", overflowY: "auto" }}>
      <Accordion
        sx={{
          color: "#142C44",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          borderBottom: "1px solid #e9e9e9 !important",

          "&.Mui-expanded": {
            marginBottom: "0",
            marginTop: "0",
          },
          "&:before": {
            display: "none",
          },
        }}
        expanded={active}
        onChange={onClick}
      >
        <AccordionSummary
          sx={{
            color: "#142C44",
            backgroundColor: "transparent",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "22px",
            flexDirection: "row-reverse",
            gap: "14px",
            "& .MuiAccordionSummary-content ": {
              margin: "0 !important",
            },
            "&.Mui-expanded": {
              minHeight: 42,
            },
          }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Payments (Due Today)
        </AccordionSummary>
        {paymentReminders && paymentReminders?.emiList?.length > 0 && (
          <AccordionDetails sx={{ maxHeight: "320px", overflowY: "auto" }}>
            <InfiniteScroll
              dataLength={
                reminderItems?.emiList?.length
                  ? reminderItems?.emiList?.length
                  : paymentReminders?.emiList?.length
              }
              next={handleNextPage}
              hasMore={hasMoreData}
              loader={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              }
              height="280px"
              style={{
                scrollBehavior: "smooth",
                paddingRight: "6px",
                paddingBottom: "6px",
              }}
            >
              {reminderItems?.emiList.map(
                (notification: any, index: number) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: "6px",
                      border: "1px solid #EAECF0",
                      backgroundColor: "#F5F7FB",
                      marginBottom: "9px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 16px 6px",
                      }}
                    >
                      <div>
                        <div className={styles.leadName}>
                          {notification?.studentName}
                        </div>
                        <div className={styles.courseName}>
                          {notification?.courseName}
                        </div>
                      </div>
                      <Box>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                            color: "#2664DC",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            "&:hover": {
                              textDecoration: "underline",
                              background: "none",
                            },
                          }}
                          color="primary"
                          onClick={() => {
                            hasLeadCardPermission
                              ? openLeadExpandModal(
                                  instituteId,
                                  notification?.leadId,
                                  notification?.studentContact,
                                  notification?.studentEmail,
                                  notification?.emiId
                                )
                              : dispatch(
                                  setToastNotification({
                                    message: String(
                                      "You do not have permission to access the lead card"
                                    ),
                                    type: "error",
                                    snackOpen: true,
                                  })
                                );
                          }}
                        >
                          View
                        </Button>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                            color: "#667085",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            "&:hover": {
                              textDecoration: "underline",
                              background: "none",
                            },
                          }}
                          color="primary"
                          onClick={() =>
                            handleClearNotification(notification?.emiId)
                          }
                        >
                          Clear
                        </Button>
                      </Box>
                    </div>

                    <div style={{ backgroundColor: "#FFF", padding: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          marginBottom: "6px",
                        }}
                      >
                        <div className={styles.header}>Batch: </div>
                        <div className={styles.batchName}>
                          {notification.batch}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div className={styles.header}>Due:</div>
                        <div className={styles.dueAmount}>
                          {findCurrency(notification?.branchId) +
                            " " +
                            notification.emiDueAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </InfiniteScroll>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}

export default PaymentReminder;
