import { Box, Button, Skeleton, Tooltip } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./EmailDetails.module.css";
import arrowLeft from "./assets/arrow-left.svg";
import arrowRight from "./assets/arrow-right.svg";
import { useAuthUser } from "react-auth-kit";
import PuffLoader from "react-spinners/PuffLoader";
import LeadCourseDetails from "../../../../../components/LeadPopup/LeadCourseDetails";
import { useDispatch } from "react-redux";
import LeadMainDetailsBox from "../../../../../components/LeadMainDetailsCard/LeadMainDetailsBox";
import {
  getLeadListByInstituteAndContact,
  resetLeadById,
  resetLeadListByContact,
  setLeadModalState,
} from "../../../Nurture/service/nurtureSlice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

const EmailDetails = (props: any) => {
  const {
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    GetMailDetailsById,
    mailById,
    GetMailEntriesByInstitute,
    mailList,
    GetLeadById,
    emailDetailsLoading,
    emailDetailsSuccess,
    emailDetailsError,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    CreateSource,
    GetLeadListByInstituteAndContact,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const branchList = useSelector((state: any) => state.branch.branchList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );
  const batchList = useSelector((state: any) => state.batch.batchList);
  const leadBoard = useSelector((state: any) => state.nurture.leadBoard);
  const leadSuccess = useSelector(
    (state: any) => state.nurture.leadCreateSuccess
  );
  const leadError = useSelector((state: any) => state.nurture.leadCreateError);
  const leadLoad = useSelector((state: any) => state.nurture.leadCreateLoading);
  const leadById = useSelector((state: any) => state.nurture.leadById);
  const {
    leadUpdateSuccess,
    leadByIdLoading,
    leadStatusUpdateSuccess,
    leadLostUpdateSuccess,
  } = useSelector((state: any) => state.nurture);
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardView = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>("");
  const [selectedLeadData, setSelectedLeadData] = React.useState<any>("");
  const [leadListArray, setLeadListArray] = React.useState<any>();

  const extractEmailFromText = (text: any) => {
    const emailRegex = /[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+/;
    const match = text?.match(emailRegex);
    return match ? match[0] : null;
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const mailId = pathname.substring(pathname.lastIndexOf("/") + 1);
    GetMailDetailsById(mailId);
  }, [GetMailDetailsById]);

  React.useEffect(() => {
    if (mailById && mailById?.entryStatus !== "PENDING") {
      GetLeadById(mailById?.leadId);
    }
  }, [mailById, GetLeadById]);

  useEffect(() => {
    if (
      (leadUpdateSuccess !== true && leadUpdateSuccess !== false) ||
      (leadStatusUpdateSuccess !== true && leadStatusUpdateSuccess !== false) ||
      (leadLostUpdateSuccess !== true && leadLostUpdateSuccess !== false)
    ) {
      if (mailById && mailById?.entryStatus !== "PENDING") {
        GetLeadById(mailById?.leadId);
      }
    }
  }, [
    leadUpdateSuccess,
    mailById,
    leadStatusUpdateSuccess,
    leadLostUpdateSuccess,
  ]);

  const sortedSwimlane = [
    { id: 1, displayStateName: "New Enquiry", stateName: "NEW_ENQUIRY" },
    { id: 2, displayStateName: "Contacted", stateName: "CONTACTED" },
    { id: 3, displayStateName: "Demo", stateName: "DEMO" },
    { id: 4, displayStateName: "Negotiation", stateName: "NEGOTIATION" },
    { id: 5, displayStateName: "Converted", stateName: "CONVERTED" },
  ];
  // leadBoard &&
  // [...leadBoard?.swimlaneData].sort((a: any, b: any) => {
  //   return a?.orderIndex - b?.orderIndex;
  // });

  function getFormattedDate(date: any) {
    return moment(date).format("MMM Do, hh:mm A");
  }

  React.useEffect(() => {
    if (leadSuccess !== true && leadSuccess !== false) {
      const pathname = window.location.pathname;
      const mailId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetMailDetailsById(mailId);
    }
  }, [leadSuccess, GetMailDetailsById]);

  const [leadMail, setLeadMail] = useState<any>("");

  React.useEffect(() => {
    if (mailById) {
      // const emailString = mailById.leadEmail;
      // const emailRegex = /<([^>]+)>/;
      // const emailRegex = /[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+/;
      // const matches = emailString.match(emailRegex);
      // const email = matches ? matches[1] : "";
      const emailVal = extractEmailFromText(mailById?.leadEmail);
      if (emailVal) {
        setLeadMail(emailVal);
      }
    }
  }, [mailById, setLeadMail]);

  const [previousMailId, setPreviousMailId] = useState<any>("");
  const [nextMailId, setNextMailId] = useState<any>("");
  const [currentMailIndex, setCurrentMailIndex] = useState<number>(0);

  useEffect(() => {
    const pathname = window.location.pathname;
    const mailId = pathname.substring(pathname.lastIndexOf("/") + 1);
    if (mailList) {
      // Find the index of the current mail
      const currentIndex = mailList?.returnData.findIndex(
        (mail: any) => mail.id === mailId
      );
      setCurrentMailIndex(currentIndex);

      // Get the previous mail ID if it exists
      if (currentIndex > 0) {
        const previousMail = mailList?.returnData[currentIndex - 1];
        setPreviousMailId(previousMail.id);
      } else {
        setPreviousMailId("");
      }

      // Get the next mail ID if it exists
      if (currentIndex < mailList?.returnData.length - 1) {
        const nextMail = mailList?.returnData[currentIndex + 1];
        setNextMailId(nextMail.id);
      } else {
        setNextMailId("");
      }
    }
  }, [
    mailList,
    setPreviousMailId,
    setNextMailId,
    setCurrentMailIndex,
    window.location.pathname,
  ]);

  const prevEmail = useCallback(() => {
    if (previousMailId) {
      const currentURL = window.location.href;
      const lastSlashIndex = currentURL.lastIndexOf("/");
      const newURL =
        currentURL.substring(0, lastSlashIndex + 1) + previousMailId;
      window.history.replaceState(null, "", newURL);
      GetMailDetailsById(previousMailId);
    }
  }, [previousMailId, GetMailDetailsById]);

  const nextEmail = useCallback(() => {
    if (nextMailId) {
      const currentURL = window.location.href;
      const lastSlashIndex = currentURL.lastIndexOf("/");
      const newURL = currentURL.substring(0, lastSlashIndex + 1) + nextMailId;

      window.history.replaceState(null, "", newURL);

      GetMailDetailsById(nextMailId);
    }
  }, [GetMailDetailsById, nextMailId]);

  const extractEmail = useCallback((emailString: any) => {
    const emailRegex = /<([^>]+)>/;
    const matches = emailString.match(emailRegex);
    const email = matches ? matches[1] : "";
    return email;
  }, []);

  useEffect(() => {
    if (leadById) {
      setSelectedLeadId(leadById?.id);
      setSelectedLeadData(leadById);
      setLeadListArray([leadById]);
    }
  }, [leadById]);

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      // Update the URL with the modified query parameter
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F5F5F5",
        padding: "14px 16px",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "8PX",
          border: "1px solid rgb(233, 233, 233)",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 93px)",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 430px)",
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            minHeight: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 15px",
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  paddingBottom: "19px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <button
                  className={styles.completedTaskPageBackBtn}
                  onClick={() => {
                    navigate("/app/inbound/Email", {
                      state: 1,
                    });
                    setSelectedLeadId("");
                    setSelectedLeadData("");
                    setLeadListArray([]);
                    dispatch(resetLeadListByContact());
                    dispatch(resetLeadById());
                  }}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </Box>

              <Box
                sx={{
                  border: "1px solid #E4E4E4",
                  borderRadius: "4px",
                  maxHeight: "calc(100VH - 190PX)",
                  overflowY: "auto",
                }}
              >
                {emailDetailsLoading ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                    }}
                  >
                    <PuffLoader color="#36d7b7" />
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        padding: "20px 20px 0",
                      }}
                    >
                      <Box
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontSize: "16px",
                            fontWeight: "500",
                            fontFamily: "poppins",
                            color: "#142C44",
                          }}
                        >
                          {mailById && mailById?.emailSubject}
                        </p>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #E4E4E4",
                        }}
                      >
                        <p
                          style={{
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "poppins",
                          }}
                        >
                          {mailById && extractEmailFromText(mailById.leadEmail)}
                        </p>
                        <p
                          style={{
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "poppins",
                          }}
                        >
                          {mailById &&
                            getFormattedDate(
                              parseInt(mailById.messageTimestamp)
                            )}
                        </p>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: "20px",
                        borderRadius: "5px",
                        marginTop: "16px",
                      }}
                    >
                      {mailById && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: mailById.emailBody,
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </div>
          </Box>
          <div className={styles.mailDetailsPagination}>
            {mailList && mailList?.returnData?.length > 0 && (
              <>
                {previousMailId !== "" && (
                  <button
                    className={styles.changeMailBtn}
                    onClick={() => prevEmail()}
                    // disabled={previousMailId === ""}
                  >
                    <img src={arrowLeft} alt="" />
                  </button>
                )}

                <div className={styles.mailsLength}>
                  {currentMailIndex !== null ? currentMailIndex + 1 : 1} of{" "}
                  {mailList?.returnData.length}{" "}
                </div>

                {nextMailId !== "" && (
                  <button
                    className={styles.changeMailBtn}
                    onClick={() => nextEmail()}
                    // disabled={nextMailId === ""}
                  >
                    <img src={arrowRight} alt="" />
                  </button>
                )}
              </>
            )}
          </div>
        </Box>

        <Box
          sx={{
            width: "430px",
            // height: "calc(100vh - 55px)",
            // maxHeight: "100%",
            height: "calc(100vh - 94px)",
            maxHeight: "calc(100vh - 94px)",
            overflowY: "hidden",
            backgroundColor: "#FFFFFF",
            paddingX:
              mailById && mailById?.entryStatus !== "PENDING" ? "0px" : "0",
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid rgb(233, 233, 233)",
          }}
        >
          {mailById && mailById?.entryStatus !== "PENDING" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      padding: "14px",
                      borderBottom: "1px solid #ebebeb",
                    }}
                  >
                    {leadListArray?.length > 0 &&
                    sortedSwimlane !== null &&
                    !leadByIdLoading ? (
                      <LeadMainDetailsBox
                        leadById={selectedLeadData && selectedLeadData}
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginBottom: "6px",
                          }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        </div>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ marginBottom: "6px" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={40}
                          sx={{ marginBottom: "6px" }}
                        />
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      padding: "14px",
                      maxHeight: "calc(100vh - 376px)",
                      overflowY: "auto",
                      zIndex: 999,
                    }}
                  >
                    {leadListArray?.length > 0 &&
                    sortedSwimlane !== null &&
                    !leadByIdLoading ? (
                      <LeadCourseDetails
                        leadById={leadListArray && selectedLeadData}
                        branchList={branchList}
                        batchList={batchList}
                        leadListByContact={leadListArray}
                        leadStages={sortedSwimlane}
                        isFromWebform={true}
                        setSelectedLeadId={setSelectedLeadId}
                        setSelectedLeadData={setSelectedLeadData}
                        selectedLeadId={selectedLeadId}
                        selectedLeadData={selectedLeadData && selectedLeadData}
                      />
                    ) : (
                      Array.from(new Array(3)).map((_, index) => (
                        <div key={index} style={{ marginBottom: "8px" }}>
                          <div className={styles.leadCourseSkeletonWrap}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={60}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  style={{
                    padding: "14px",
                    borderTop: "1px solid #ebebeb",
                  }}
                >
                  {leadListArray?.length > 0 &&
                  sortedSwimlane !== null &&
                  !leadByIdLoading ? (
                    <Tooltip
                      arrow
                      title={
                        hasLeadCardView === false ? "Oops! No permission." : ""
                      }
                      placement="top"
                      sx={{ width: "100%" }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                            },
                          },
                        },
                      }}
                    >
                      <span>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          sx={{ textTransform: "capitalize" }}
                          onClick={() =>
                            openLeadExpandModal(
                              selectedLeadData?.instituteId,
                              selectedLeadData?.studentContact,
                              selectedLeadData?.studentEmail,
                              selectedLeadData?.id
                            )
                          }
                          disabled={!hasLeadCardView}
                        >
                          View Lead Card
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={30}
                        sx={{ marginBottom: "6px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmailDetails;
