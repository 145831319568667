import React, { useState, createRef } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import editIcon from "../../../../assets/images/edit.png";
import "cropperjs/dist/cropper.css";
import CropperModal from "./CropperModal";
import { Box, Button } from "@mui/material";
const defaultImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPbSURBVHgB7ZjLSuRAFIZPt+0Nb1FQUUGigi5cTA+4FdJPoG9gP4I+ge0b6BPY8wTOrF04g4IuFFwo6EKNCroQ6YCKd53/D13S3ZOkO+mkYcQfQi6VSr6qc+rUqRL50idXzK3g6ekpHYvFpnCpSYR6f3838a+l1tbWXafyfwDv7+/1urq6FZyTFxcX8vDwIFGqs7NT+vv7CbrQ2NiYkXKAaM0JwPTDw0N5eXmRWqipqUkmJiYEgHO4XhQ3QJoVPbe8sbFh36vWRaXn52c5Pz+n1ex/AdKqr68fgmtZ6p1EYQX63NnZmX1NsPHxcQvPsri1JALBrHpvb296c3NTcrmc3NzcaB0dHfT7H46AkMZWUW1tbQTOokVzEqFgNaO5uVkHHAGlvb1dLyxPuFVMJOyiQD2HRhpvb28ajl383Cz3fv5fjopLiILJaIE1HjDZCqLBCXpoRqpQQkLU4+Nj+u7uztje3rYjQHd3tySTyUWA/yp0fD8KtQfRY1OMnSo8XV1dcYRqgNYloEIFhHY5uJTgf/bR0tJiSkD5MnHex3SYS8O11dDQUDQ9oeeWEJ6m4fQ6Y9vAwADrZIKa1xcgprxZwM0Dwg5FCD/0ORM/zwDUjlscsQBL9fT0TOG5jkc/8d4fqUIVAQIkC8CZ/f19O6Aq9fX16SMjI1mUD6l5NB9WliQklfVBBceRSTjGLM6d1OXlpfA5yufxXsbtG3QNHhJAnoD46KyCY1bDATA5OflxcADwuRsk7qfzcTHHAzGRxzIzJgkDkCGiEI4ZB0IJfS5Gk/LeDZLn19fXlYODA2N9fV1WV1f5joYwlI7H42u3t7dJqQaQ86ITHMAWWM6zGyR7jWcmAUw+VE5JF6EfHx8f62hkRZCugOrDTnBKbpDX19eGahyfszyVSsnY2JhdD4BydHSkEVLKZOyeJvaC84Lc2dkpgkOut4jE4fvg4KCJFK4IMjBgJXBukEqEwYj/jZ6a45oDPplCIC+FlECAaC1DSrYcnBfk3t4eB5qhBg5jpBMk/d03IIXZwBQf8ho4TpAI8nY9r7VP2MmCL8jh4eEPyJoBBoC0CjOgmgD6gcTJqlnKHwSynCIFlBAgXQE5shBcK5ovq4FEsa7eYxkT4cK6pcZnym4wjTo9PeWiZ5oZiIS0cFdbHIRjxs0zNwhUjplfixdl6UVbHwyqaMHa1taW/QGKWxJhi98u3ZTixDA6Omqip4fEDZCCX9i+oVpZCxGOiQR6zyhdIjjuDxKSaw1OQYSMapeL4aWrq4vzvYkcMe20fnHdwGTWiwrfYPJQBoqTOCDoc9UurL70X+svAA3IhVCu5YsAAAAASUVORK5CYII=";

export default function Previews(props) {
  const [files, setFiles] = useState([]);
  const [isCrop, setIsCrop] = useState(false);
  const [image, setImage] = useState();
  const cropperRef = createRef();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    disabled: props.disabled,
    onDrop: (acceptedFiles) => {
      setIsCrop(true);

      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
  });

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setFiles(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setIsCrop(false);
      props.setImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      props.handleImageUpload(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
        image
      );
    }
  };

  return (
    <section className="container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "120px",
            height: "120px",
            backgroundColor: "rgba(203, 203, 203, 0.1)",
            border: "1.125px solid rgba(152, 152, 152, 0.2)",
            borderRadius: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          className={!props.disabled && "imageDrop"}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <img
              src={files?.length !== 0 ? files : props.imgUrl || defaultImage}
              style={{
                width: !props.imgUrl && files?.length === 0 ? "auto" : "120px",
                height: !props.imgUrl && files?.length === 0 ? "auto" : "120px",
                borderRadius: "100px",
              }}
              alt="logo"
            />
            <Button
              disabled={false}
              component="label"
              color="secondary"
              sx={{ fontSize: "10px", textAlign: "center" }}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "0px",
                backgroundColor: "#FFF",
                border: "1px solid #E8E8E8",
                borderRadius: "100%",
                height: "26px",
                width: "26px",
                minWidth: "26px",
              }}
            >
              <img
                src={editIcon}
                alt=""
                style={{
                  height: "14px",
                  width: "14px",
                }}
              />
            </Button>
          </div>
        </Box>
      </div>

      {isCrop && (
        <>
          <CropperModal open={isCrop} setIsCrop={setIsCrop} id="profile">
            <Cropper
              ref={cropperRef}
              style={{
                height: 400,
                width: "100%",
              }}
              id="profileCroper"
              zoomTo={0.5}
              aspectRatio={1}
              preview=".img-preview"
              src={image}
              // viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              dragMode="move"
              guides={true}
              className="round-cropper"
            />
            <Box textAlign={"center"}>
              <Button
                variant="outlined"
                onClick={getCropData}
                sx={{ marginTop: "10px" }}
              >
                Crop Image
              </Button>
            </Box>
          </CropperModal>
        </>
      )}
      <Box
        textAlign={"center"}
        width={"150px"}
        boxShadow={
          "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
        }
      ></Box>
    </section>
  );
}
