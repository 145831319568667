import { Button, Modal, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import home_BG from "../../../../assets/images/home_BG.svg";
import { RegistrationStage } from "../../../../components/RegistrationStage/RegistrationStage";
import styles from "./Home.module.css";
import { setActiveInsitute } from "../../../../service/activeInstituteSlice";
import { useAuthUser } from "react-auth-kit";
import { useLocation } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { PrivacyPolicy, TermsOfServices } from "../../../../pages";
import { useSelector } from "react-redux";
import { getNurtureLeadBoard } from "../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";

const style = {
  position: "absolute" as "absolute",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  p: 2,
  top: "2%",
  left: "2%",
  right: "2%",
  bottom: "2%",
  overflow: "hidden",
  outline: "none",
};

export default function Home(props: any) {
  const {
    profileDetailsCompleted,
    isProfileComplete,
    isCourseSetup,
    isBranchAdded,
    isTeamAdded,
    isBatchAdded,
    registrationStep,
    AcceptTnC,
    AcceptPrivacyPolicy,
    loading,
  } = props;
  const auth = useAuthUser();
  const user = auth();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const termsAndConditionsSuccess = useSelector(
    (state: any) => state.dashboard.termsAndConditionsSuccess
  );
  const termsAndConditionsError = useSelector(
    (state: any) => state.dashboard.termsAndConditionsError
  );
  const privacyPolicySuccess = useSelector(
    (state: any) => state.dashboard.privacyPolicySuccess
  );
  const privacyPolicyError = useSelector(
    (state: any) => state.dashboard.privacyPolicyError
  );
  const profileDetails = useSelector((state: any) => state.profile.userDetails);
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  useEffect(() => {
    if (
      location?.state?.isVersionMatching?.tnc === true &&
      location?.state?.isVersionMatching?.privacy === false
    ) {
      setOpen(false);
      setOpenPrivacy(true);
    } else if (
      location?.state?.isVersionMatching?.tnc === false &&
      location?.state?.isVersionMatching?.privacy === true
    ) {
      setOpen(true);
      setOpenPrivacy(false);
    } else if (
      location?.state?.isVersionMatching?.tnc === false &&
      location?.state?.isVersionMatching?.privacy === false
    ) {
      setOpen(true);
      setOpenPrivacy(true);
    } else {
      setOpen(false);
      setOpenPrivacy(false);
    }
  }, [location]);

  const [pageTabState, setPageTabState] = React.useState([
    {
      id: 0,
      listText: "Complete Profile",
      endPoint: "/manage",
      status: activeInstitute?.profileDetailsCompleted,
    },
    {
      id: 2,
      listText: "Add Branch",
      endPoint: "/manage/Branches",
      status: activeInstitute?.branchCreated,
    },
  ]);

  const LetsExploreApiCall = useCallback(() => {
    const userFromLocalStorage = user?.institute[0]?.instituteDetails;

    dispatch<any>(getNurtureLeadBoard(userFromLocalStorage?.id));
  }, []);

  React.useEffect(() => {
    if (user !== null) {
      dispatch<any>(getInstituteById(activeInstitute?.id));
      // registrationStep(user && user.institute[0].instituteId);
      // setActiveInsitute(user && user.institute[0].instituteId);
    }
  }, [registrationStep]);

  React.useEffect(() => {
    setPageTabState([
      {
        id: 0,
        listText: "Complete Profile",
        endPoint: "/manage",
        status: activeInstitute?.profileDetailsCompleted,
      },
      {
        id: 2,
        listText: "Add Branch",
        endPoint: "/manage/Branches",
        status: activeInstitute?.branchCreated,
      },
    ]);
  }, [setPageTabState, profileDetailsCompleted, isBranchAdded]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${home_BG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "fixed",
        height: "100%",
        minHeight: "90vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box className={styles.profileCtagoryMainWrapper}>
        <Box>
          {loading ? (
            <>
              <Box className={styles.lockedProfilelist}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="35px"
                  width="100%"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="20px"
                  width="100%"
                  style={{
                    borderRadius: "3px",
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#142C44",
                }}
              >
                Get Started
              </div>
              <Box sx={{ textAlign: "center" }}>
                {!instituteFromRedux?.isProfileCompleted ? (
                  <>
                    <Grid item xs={5}>
                      {!activeInstitute?.profileDetailsCompleted &&
                        !activeInstitute?.branchCreated && (
                          <>
                            <Box>
                              <p
                                style={{
                                  width: "100%",
                                  lineHeight: "150%",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#585858",
                                  margin: 0,
                                  marginTop: "4px",
                                  padding: "0 16%",
                                }}
                              >
                                You are few steps away from getting your profile
                                completed.
                              </p>
                            </Box>
                          </>
                        )}

                      {activeInstitute?.profileDetailsCompleted &&
                        !activeInstitute?.branchCreated && (
                          <>
                            <Box>
                              <p
                                style={{
                                  width: "100%",
                                  lineHeight: "150%",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#585858",
                                  margin: 0,
                                  marginTop: "4px",
                                  padding: "0 16%",
                                }}
                              >
                                Add a new branch for your institution.
                              </p>
                            </Box>
                          </>
                        )}
                    </Grid>
                  </>
                ) : (
                  <Box>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "150%",
                        color: "#585858",
                        margin: 0,
                        marginTop: "4px",
                        padding: "0 16%",
                      }}
                    >
                      You have completed your profile let's explore the power of
                      Nurture
                    </p>
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box className={styles.lockedProfilelist}>
            {loading ? (
              <Box className={styles.lockedProfileSublist}>
                <List>
                  {Array.from(new Array(6)).map((_, index) => (
                    <div key={index}>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        height="48px"
                        width="100%"
                        style={{
                          marginBottom: "14px",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  ))}
                </List>
              </Box>
            ) : (
              <>
                <Box className={styles.lockedProfileSublist}>
                  <List>
                    {pageTabState.map((item, index) => {
                      return (
                        <RegistrationStage
                          key={Math.random()}
                          itemList={pageTabState}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                  </List>
                </Box>

                {!instituteFromRedux?.isProfileCompleted ? (
                  <>
                    <Grid item xs={5}>
                      {!activeInstitute?.profileDetailsCompleted &&
                        !activeInstitute?.branchCreated && (
                          <Box sx={{ textAlign: "center" }}>
                            <Button
                              fullWidth
                              className={styles.compleYourProfileBtn}
                              variant="contained"
                              color="primary"
                              sx={{
                                padding: "11px 24px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                navigate(`/app/manage/InstituteProfile/:id`, {
                                  state: 0,
                                });
                              }}
                            >
                              Let’s Start
                            </Button>
                          </Box>
                        )}
                      {activeInstitute?.profileDetailsCompleted &&
                        !activeInstitute?.branchCreated && (
                          <Box sx={{ textAlign: "center" }}>
                            <Button
                              fullWidth
                              className={styles.compleYourProfileBtn}
                              variant="contained"
                              color="primary"
                              sx={{
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                navigate(`/app/manage/createBranch`, {
                                  state: 1,
                                });
                              }}
                            >
                              Continue
                            </Button>
                          </Box>
                        )}
                      {activeInstitute?.profileDetailsCompleted &&
                        activeInstitute?.branchCreated &&
                        !activeInstitute?.courseCreated &&
                        !activeInstitute?.batchCreated && (
                          <Box sx={{ textAlign: "center" }}>
                            <Button
                              fullWidth
                              className={styles.compleYourProfileBtn}
                              variant="contained"
                              color="primary"
                              sx={{
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                navigate(`/app/manage/Courses`, { state: 3 });
                              }}
                            >
                              Manage Course
                            </Button>
                          </Box>
                        )}
                      {activeInstitute?.profileDetailsCompleted &&
                        activeInstitute?.branchCreated &&
                        activeInstitute?.courseCreated &&
                        !activeInstitute?.batchCreated && (
                          <Box sx={{ textAlign: "center" }}>
                            <Button
                              fullWidth
                              className={styles.compleYourProfileBtn}
                              variant="contained"
                              color="primary"
                              sx={{
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                navigate(`/app/manage/Batches`, { state: 4 });
                              }}
                            >
                              Manage Batch
                            </Button>
                          </Box>
                        )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box sx={{ textAlign: "center" }}>
                      <Link to="/app/dashboard" className={styles.link}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => {
                            LetsExploreApiCall();
                          }}
                        >
                          Let's Explore
                        </Button>
                      </Link>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Modal open={false} onClose={handleClosePrivacy}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {location?.state?.isVersionMatching?.privacy === true && (
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleClosePrivacy}
              >
                <CloseIcon />
              </button>
            )}
          </div>
          <PrivacyPolicy
            tnc={location?.state?.tnc}
            isVersionMatching={location?.state?.isVersionMatching}
            privacyPolicySuccess={privacyPolicySuccess}
            privacyPolicyError={privacyPolicyError}
            AcceptPrivacyPolicy={AcceptPrivacyPolicy}
          />
        </Box>
      </Modal>
      <Modal open={false} onClose={handleClose}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {location?.state?.isVersionMatching?.tnc === true && (
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            )}
          </div>
          <TermsOfServices
            tnc={location?.state?.tnc}
            isVersionMatching={location?.state?.isVersionMatching}
            termsAndConditionsSuccess={termsAndConditionsSuccess}
            termsAndConditionsError={termsAndConditionsError}
            AcceptTnC={AcceptTnC}
          />
        </Box>
      </Modal>
    </Box>
  );
}
