import React from "react";
import styles from "../../Dashboard.module.css";
import { formatAmount } from "../../../../../components/CommonFunctions/CommonFunctions";
import { RevenueDisplay } from "../../../../../components/CommonFunctions/CommonFunctions";
import ToolTip from "../../../../../components/ToolTip/ToolTip";

function SalesPipelineOverview(props: any) {
  const { salesData, selectedCurrency } = props;

  const newEnquiryLeadCount =
    salesData?.salesPipelineLeadData?.[0]?.datasets?.[0]?.data || 0;
  const contactedLeadCount =
    salesData?.salesPipelineLeadData?.[1]?.datasets?.[0]?.data || 0;
  const demoLeadCount =
    salesData?.salesPipelineLeadData?.[2]?.datasets?.[0]?.data || 0;
  const negotiationLeadCount =
    salesData?.salesPipelineLeadData?.[3]?.datasets?.[0]?.data || 0;
  const totalLeadCount =
    newEnquiryLeadCount +
    contactedLeadCount +
    demoLeadCount +
    negotiationLeadCount;

  let countsGreaterThanZero = 0;

  if (newEnquiryLeadCount > 0 && salesData) countsGreaterThanZero++;
  if (contactedLeadCount > 0 && salesData) countsGreaterThanZero++;
  if (demoLeadCount > 0 && salesData) countsGreaterThanZero++;
  if (negotiationLeadCount > 0 && salesData) countsGreaterThanZero++;

  let percent = 97;

  if (countsGreaterThanZero === 1) {
    percent = 100;
  } else if (countsGreaterThanZero === 2) {
    percent = 99;
  } else if (countsGreaterThanZero === 3) {
    percent = 98;
  }

  const newEnquiryLeadPercentage =
    (newEnquiryLeadCount / totalLeadCount) * percent;
  const contactedLeadPercentage =
    (contactedLeadCount / totalLeadCount) * percent;
  const demoLeadPercentage = (demoLeadCount / totalLeadCount) * percent;
  const negotiationLeadPercentage =
    (negotiationLeadCount / totalLeadCount) * percent;

  return (
    <div className={styles.salesPipelineBox}>
      <div className={styles.salesPipelineTitle}>Sales Pipeline Overview</div>
      <div className={styles.salesPipelineTotalBox}>
        <div className={styles.salesPipelineTotalBoxItem}>
          <div
            className={styles.salesPipelineTotalBoxItemValue}
            style={{ fontFamily: "Roboto" }}
          >
            {selectedCurrency +
              " " +
              formatAmount(salesData?.totalExpectedRevenue)}
            {RevenueDisplay(salesData?.totalExpectedRevenue) && (
              <ToolTip
                title={RevenueDisplay(salesData?.totalExpectedRevenue)}
              />
            )}
          </div>
          <div className={styles.salesPipelineTotalBoxItemLabel}>
            Total Expected Revenue
          </div>
        </div>
        <div className={styles.salesPipelineTotalBoxItem}>
          <div className={styles.salesPipelineTotalBoxItemValue}>
            {salesData?.totalLeads}
          </div>
          <div className={styles.salesPipelineTotalBoxItemLabel}>
            Total Leads
          </div>
        </div>
      </div>
      <div className={styles.salesPipelineStripBarWrap}>
        {newEnquiryLeadCount > 0 && (
          <div
            className={styles.salesPipelineStripBarItemBlue}
            style={{
              width: `${newEnquiryLeadPercentage}%`,
              borderTopRightRadius:
                negotiationLeadCount === 0 &&
                demoLeadCount === 0 &&
                contactedLeadCount === 0
                  ? "5px"
                  : "0PX",
              borderBottomRightRadius:
                negotiationLeadCount === 0 &&
                demoLeadCount === 0 &&
                contactedLeadCount === 0
                  ? "5px"
                  : "0PX",
            }}
          ></div>
        )}
        {contactedLeadCount > 0 && (
          <div
            className={styles.salesPipelineStripBarItemPink}
            style={{
              width: `${contactedLeadPercentage}%`,
              borderTopLeftRadius: newEnquiryLeadCount === 0 ? "5px" : "0PX",
              borderBottomLeftRadius: newEnquiryLeadCount === 0 ? "5px" : "0PX",
              borderTopRightRadius:
                negotiationLeadCount === 0 && demoLeadCount === 0
                  ? "5px"
                  : "0PX",
              borderBottomRightRadius:
                negotiationLeadCount === 0 && demoLeadCount === 0
                  ? "5px"
                  : "0PX",
            }}
          ></div>
        )}
        {demoLeadCount > 0 && (
          <div
            className={styles.salesPipelineStripBarItemYellow}
            style={{
              width: `${demoLeadPercentage}%`,
              borderTopLeftRadius:
                newEnquiryLeadCount === 0 && contactedLeadCount === 0
                  ? "5px"
                  : "0PX",
              borderBottomLeftRadius:
                newEnquiryLeadCount === 0 && contactedLeadCount === 0
                  ? "5px"
                  : "0PX",
              borderTopRightRadius: negotiationLeadCount === 0 ? "5px" : "0PX",
              borderBottomRightRadius:
                negotiationLeadCount === 0 ? "5px" : "0PX",
            }}
          ></div>
        )}
        {negotiationLeadCount > 0 && (
          <div
            className={styles.salesPipelineStripBarItemGreen}
            style={{
              width: `${negotiationLeadPercentage}%`,
              borderTopLeftRadius:
                newEnquiryLeadCount === 0 &&
                contactedLeadCount === 0 &&
                demoLeadCount === 0
                  ? "5px"
                  : "0PX",
              borderBottomLeftRadius:
                newEnquiryLeadCount === 0 &&
                contactedLeadCount === 0 &&
                demoLeadCount === 0
                  ? "5px"
                  : "0PX",
            }}
          ></div>
        )}
      </div>
      <div className={styles.salesPipelineStagesDataWrap}>
        {salesData?.salesPipelineLeadData?.map((item: any, index: any) => (
          <div className={styles.salesPipelineStagesDataItem} key={index}>
            <div className={styles.salesPipelineStagesDataItemStage}>
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background:
                    item?.labels === "New Enquiry"
                      ? "#697BE9"
                      : item?.labels === "Contacted"
                      ? "#FA898B"
                      : item?.labels === "Demo"
                      ? "#F6CF7D"
                      : item?.labels === "Negotiation"
                      ? "#40E4BC"
                      : "inherit",
                }}
              ></div>
              <div className={styles.salesPipelineStagesDataItemStageName}>
                {item?.labels}
              </div>
            </div>
            <div className={styles.salesPipelineStagesDataBottom}>
              <div className={styles.salesPipelineStagesDataBottomCount}>
                {item?.datasets?.[0]?.data}
              </div>
              <div className={styles.salesPipelineStagesDataBottomRight}>
                <div
                  className={styles.salesPipelineStagesDataBottomPrice}
                  style={{ fontFamily: "Roboto" }}
                >
                  {formatAmount(item?.datasets?.[1]?.data)}
                </div>
                <div className={styles.salesPipelineStagesDataBottomLabel}>
                  Expected Revenue
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SalesPipelineOverview;
