import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from "../Managebatches.module.css";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import moment from "moment";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import dayjs from "dayjs";
import { updateBatchPayment } from "../../service/Batchslice";
import toggleRadio from "../../../../../../assets/images/toggle-radio.svg";
import toggleRadioSelected from "../../../../../../assets/images/toggle-radio-selected.svg";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import SwitchInput from "../../../../../../components/Switch/SwitchInput";
import helpIcon from "../../../../../../assets/images/helpIcon.svg";

interface EmiType {
  emiNumber: number;
  emiName: string;
  emiAmount: number;
  dueDate: number | null;
  isNameValid: boolean;
  isPriceValid: boolean;
}

const emiModesItems = [
  {
    id: "1",
    emiModeValue: "MONTHLY",
    emiModeLabel: "Monthly",
  },
  {
    id: "2",
    emiModeValue: "QUARTERLY",
    emiModeLabel: "Quarterly",
  },
  {
    id: "3",
    emiModeValue: "HALF_YEARLY",
    emiModeLabel: "Half yearly",
  },
  {
    id: "4",
    emiModeValue: "YEARLY",
    emiModeLabel: "Yearly",
  },
  {
    id: "5",
    emiModeValue: "CUSTOM",
    emiModeLabel: "Custom",
  },
];

function EditBatchRegular(props: any) {
  const {
    batchData,
    setRegularPayEdit,
    regularPayEdit,
    batchPayUpdateLoading,
    selectedCurrency,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const batchStartDateMilliSeconds =
    batchData?.startDate?.toString()?.length !== 13
      ? batchData?.startDate * 1000
      : batchData?.startDate;
  const batchEndDateMilliSeconds =
    batchData?.endDate?.toString()?.length !== 13
      ? batchData?.endDate * 1000
      : batchData?.endDate;

  const currentDateInMilliseconds = new Date().getTime();

  const numberOfDays =
    Math.floor(
      (batchEndDateMilliSeconds - batchStartDateMilliSeconds) /
        (1000 * 60 * 60 * 24)
    ) + 1;

  const availableEmiModes = (() => {
    switch (true) {
      case numberOfDays > 360:
        return emiModesItems;

      case numberOfDays > 180:
        return emiModesItems.filter((item) => item.emiModeValue !== "YEARLY");

      case numberOfDays >= 60:
        return emiModesItems.filter(
          (item) =>
            item.emiModeValue === "MONTHLY" ||
            item.emiModeValue === "QUARTERLY" ||
            item.emiModeValue === "CUSTOM"
        );

      case numberOfDays >= 30:
        return emiModesItems.filter(
          (item) =>
            item.emiModeValue === "MONTHLY" || item.emiModeValue === "CUSTOM"
        );

      default:
        return [];
    }
  })();

  const [regularPrice, setRegularPrice] = React.useState<string>("");
  const [discountMetric, setDiscountMetric] = React.useState<string>("");
  const [discount, setDiscount] = React.useState<string>("");
  const [finalPrice, setFinalPrice] = React.useState<string>("");
  const [regularPriceError, setRegularPriceError] =
    React.useState<boolean>(false);
  const [enableInstallment, setEnableInstallment] =
    React.useState<boolean>(false);
  const [setupInstallment, setSetupInstallment] =
    React.useState<boolean>(false);

  const [installmentNumber, setInstallmentNumber] = React.useState<number>(0);
  const [selectedEmiMode, setSelectedEmiMode] = React.useState<string>("");
  const [selectedEmiModeDays, setSelectedEmiModeDays] =
    React.useState<number>(0);
  const [selectedEmiModeError, setSelectedEmiModeError] =
    React.useState<boolean>(false);
  const [emiError, setEmiError] = React.useState<string>("");

  const [startDate, setStartDate] = React.useState<any>(null);
  const [emiStartDate, setEmiStartDate] = React.useState<number>(
    moment().valueOf()
  );

  const [rows, setRows] = React.useState<any>([]);

  const handleTotalPrice = (value: string) => {
    setRegularPrice(value);
    if (value === "") {
      setRegularPriceError(true);
    } else {
      setRegularPriceError(false);
    }
    if (discountMetric === "AMOUNT") {
      setFinalPrice((parseFloat(value) - parseFloat(discount)).toString());
    } else if (discountMetric === "PERCENTAGE") {
      setFinalPrice(
        (parseFloat(value) * (1 - parseFloat(discount) / 100)).toString()
      );
    } else {
      setFinalPrice(value);
    }
  };

  const handleDiscountMetric = (value: string) => {
    setDiscountMetric(value);
    setDiscount("");
    setFinalPrice(batchData?.paymentDetails?.paymentData?.finalPrice);
  };

  const handleDiscount = (value: string) => {
    if (value !== "") {
      if (discountMetric === "AMOUNT") {
        if (parseFloat(value) > parseFloat(regularPrice)) {
          setFinalPrice(
            (parseFloat(regularPrice) - parseFloat(regularPrice)).toString()
          );
          setDiscount(regularPrice);
        } else {
          setDiscount(value);
          setFinalPrice(
            (parseFloat(regularPrice) - parseFloat(value)).toString()
          );
        }
      } else if (discountMetric === "PERCENTAGE") {
        if (parseFloat(value) > 100) {
          setFinalPrice(
            (parseFloat(regularPrice) * (1 - 100 / 100)).toString()
          );
          setDiscount("100");
        } else {
          setDiscount(value);
          setFinalPrice(
            (
              parseFloat(regularPrice) *
              (1 - parseFloat(value) / 100)
            ).toString()
          );
        }
      }
    } else {
      setFinalPrice(regularPrice);
      setDiscount("");
    }
  };

  const handleEnableInstallment = () => {
    setEnableInstallment(!enableInstallment);
    setSetupInstallment(false);
    setRows([]);
    setInstallmentNumber(0);
    setSelectedEmiMode("");
    setSelectedEmiModeDays(0);
    setStartDate(null);
  };

  const handleSetupEmi = (event: any) => {
    setSetupInstallment(event.target.checked);
    setRows([]);
    setInstallmentNumber(0);
    setSelectedEmiMode("");
    setSelectedEmiModeDays(0);
    setStartDate(null);
  };

  const handleEmiMode = React.useCallback(
    (emiMode: any) => {
      setSelectedEmiMode(emiMode);
      // setSelectedEmiModeError(false);
      setEmiError("");
      let emiDays: number;

      switch (emiMode) {
        case "MONTHLY":
          emiDays = 30;
          break;
        case "QUARTERLY":
          emiDays = 90;
          break;
        case "HALF_YEARLY":
          emiDays = 180;
          break;
        case "YEARLY":
          emiDays = 360;
          break;
        default:
          emiDays = 0;
      }
      setSelectedEmiModeDays(emiDays);
      if (rows?.length > 0 && emiDays > 0) {
        let dueDate = emiStartDate;
        const amountPerEmi = parseFloat(finalPrice) / installmentNumber;

        const newEmis: EmiType[] = [];

        for (let index = 0; index < installmentNumber; index++) {
          if (index > 0) {
            dueDate += emiDays * 24 * 60 * 60 * 1000;
          }
          newEmis.push({
            emiNumber: index + 1,
            emiName: `EMI ${index + 1}`,
            emiAmount: Number(amountPerEmi.toFixed(2)),
            dueDate: dueDate,
            isPriceValid: true,
            isNameValid: true,
          });
        }

        setRows(newEmis);
      } else {
        if (emiStartDate && startDate !== null && installmentNumber > 0) {
          let dueDate = emiStartDate;
          const amountPerEmi = parseFloat(finalPrice) / installmentNumber;

          const newEmis: EmiType[] = [];

          for (let index = 0; index < installmentNumber; index++) {
            if (index > 0) {
              dueDate = 0;
            } else {
              dueDate = new Date(emiStartDate).setHours(23, 59, 59, 0);
            }
            newEmis.push({
              emiNumber: index + 1,
              emiName: `EMI ${index + 1}`,
              emiAmount: Number(amountPerEmi.toFixed(2)),
              dueDate: dueDate === 0 ? null : dueDate,
              isPriceValid: true,
              isNameValid: true,
            });
          }
          setRows(newEmis);
        } else {
          setRows([]);
        }
      }
    },
    [selectedEmiModeDays, rows, emiStartDate, finalPrice, installmentNumber]
  );

  const handleDateChange = React.useCallback(
    (date: any) => {
      const dateUnix = date.unix() * 1000;
      setEmiStartDate(dateUnix);
      let dueDate = dateUnix;
      const amountPerEmi = parseFloat(finalPrice) / installmentNumber;
      const newEmis: EmiType[] = [];

      for (let index = 0; index < installmentNumber; index++) {
        if (index > 0) {
          if (selectedEmiMode === "CUSTOM") {
            dueDate = 0;
          } else {
            dueDate += selectedEmiModeDays * 24 * 60 * 60 * 1000;
          }
        } else {
          dueDate = new Date(dueDate).setHours(23, 59, 59, 0);
        }

        newEmis.push({
          emiNumber: index + 1,
          emiName: `EMI ${index + 1}`,
          emiAmount: Number(amountPerEmi.toFixed(2)),
          dueDate: dueDate === 0 ? null : dueDate,
          isPriceValid: true,
          isNameValid: true,
        });
      }

      setRows(newEmis);
    },
    [finalPrice, installmentNumber, selectedEmiModeDays]
  );

  const handleEmiCount = React.useCallback(
    (emiCount: any) => {
      setInstallmentNumber(emiCount);
      setEmiError("");

      const amountPerEmi = parseFloat(finalPrice) / emiCount;
      if (amountPerEmi !== 0) {
        let currentDueDateTimestamp = emiStartDate;

        const newEmis: EmiType[] = [];

        for (let index = 0; index < emiCount; index++) {
          if (index > 0) {
            if (selectedEmiMode === "CUSTOM") {
              currentDueDateTimestamp = 0;
            } else {
              currentDueDateTimestamp +=
                selectedEmiModeDays * 24 * 60 * 60 * 1000;
            }
          } else {
            currentDueDateTimestamp = new Date(emiStartDate).setHours(
              23,
              59,
              59,
              0
            );
          }
          newEmis.push({
            emiNumber: index + 1,
            emiName: `EMI ${index + 1}`,
            emiAmount: Number(amountPerEmi.toFixed(2)),
            dueDate:
              currentDueDateTimestamp === 0 ? null : currentDueDateTimestamp,
            isPriceValid: true,
            isNameValid: true,
          });
        }
        setRows(newEmis);
      }
    },
    [selectedEmiMode, selectedEmiModeDays, emiStartDate, finalPrice]
  );

  const handleEmiNameChange = React.useCallback(
    (index: number, event: any) => {
      const { value } = event.target;

      const updatedEmis = [...rows];

      const isNameUnique = !updatedEmis.some(
        (r, i) =>
          i !== index && r.emiName?.toLowerCase() === value?.toLowerCase()
      );

      updatedEmis[index].emiName = value;

      updatedEmis[index] = {
        ...updatedEmis[index],
        emiName: value,
        isNameValid: value !== "" && isNameUnique ? true : false,
      };

      setRows(updatedEmis);
    },
    [rows]
  );

  const handleEmiPriceChange = React.useCallback(
    (index: number, event: any) => {
      const { value } = event.target;

      const updatedEmis = [...rows];

      updatedEmis[index] = {
        ...updatedEmis[index],
        emiAmount: parseFloat(value),
        isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
      };

      setRows(updatedEmis);
    },
    [rows]
  );

  const handleEmiDueDate = React.useCallback(
    (index: number, date: any) => {
      const dateUnix = date.unix() * 1000;
      const newDate = new Date(dateUnix);
      newDate.setHours(23, 59, 59, 999);

      const updatedEmis = [...rows];

      updatedEmis[index] = {
        ...updatedEmis[index],
        dueDate: newDate.getTime(),
      };

      setRows(updatedEmis);
      if (index === 0) {
        setEmiStartDate(dateUnix);
        setStartDate(dateUnix);
      }
    },
    [rows]
  );

  const isNameUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) =>
        r?.emiNumber !== row?.emiNumber &&
        r?.emiName?.toLowerCase() === row?.emiName?.toLowerCase()
    );
  };

  const isDueDateAfterPrevious = (index: number, rows: any[]): boolean => {
    if (index > 0) {
      const currentDueDate = dayjs(rows[index]?.dueDate).startOf("day");
      const previousDueDate = dayjs(rows[index - 1]?.dueDate).startOf("day");
      return currentDueDate.isAfter(previousDueDate);
    }
    return true;
  };

  const cumulativeEmiAmount = rows
    ?.reduce((total: number, emiItem: any) => {
      return total + (emiItem.emiAmount || 0);
    }, 0)
    .toFixed(2);
  const balanceAmountInEmi = parseFloat(finalPrice) - cumulativeEmiAmount;

  const checkCumulativeEmiAmount = React.useCallback(() => {
    if (cumulativeEmiAmount < Number(finalPrice) - 1) {
      return "Cumulative EMI amount is less than the regular price.";
    } else if (cumulativeEmiAmount > Number(finalPrice) + 0.99) {
      return "Cumulative EMI amount is greater than the regular price.";
    } else {
      return null;
    }
  }, [rows, finalPrice]);

  const hasEmptyPaymentName = rows?.some((row: any) => row.emiName === "");
  const hasPriceNotGreaterThanZero = rows.every(
    (row: any) => row.emiAmount > 0
  );
  const hasDueDateEmpty = rows?.some((row: any) => row.dueDate === null);

  // const isDueDatesValid = (customEmis: any): boolean => {
  //   let prevDueDate = 0;

  //   for (const emi of customEmis) {
  //     const dueDate = emi.dueDate;
  //     if (dueDate <= prevDueDate) {
  //       return false;
  //     }

  //     prevDueDate = dueDate;
  //   }

  //   return true;
  // };

  const isDueDatesValid = (customEmis: any[]): boolean => {
    let prevDueDate: dayjs.Dayjs | null = null;

    for (const emi of customEmis) {
      const dueDate = dayjs(emi.dueDate).startOf("day");

      if (
        !dueDate.isValid() ||
        (prevDueDate && !dueDate.isAfter(prevDueDate))
      ) {
        return false;
      }

      prevDueDate = dueDate;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const emiStartDate = rows[0]?.dueDate;
      const emiEndDate = rows[rows.length - 1]?.dueDate;
      let emiModeError = false;
      if (selectedEmiMode === "") {
        setSelectedEmiModeError(true);
        emiModeError = true;
      } else {
        setSelectedEmiModeError(false);
        emiModeError = false;
      }

      let endDateError = false;
      if (emiEndDate > batchEndDateMilliSeconds) {
        //  setEndDateError(true);
        endDateError = true;
      } else if (emiEndDate < batchEndDateMilliSeconds) {
        //  setEndDateError(false);
        endDateError = false;
      }

      let discountError = false;
      if (discountMetric !== "" && parseFloat(discount) === 0) {
        discountError = true;
      } else if (emiEndDate < batchEndDateMilliSeconds) {
        discountError = false;
      }
      let emiDefinedError = false;
      if (setupInstallment && rows?.length === 0) {
        emiDefinedError = true;
        setEmiError("Please define EMI");
      } else {
        emiDefinedError = false;
        setEmiError("");
      }
      const updatedEmis = rows?.map((emi: any) => {
        const dueDate = new Date(emi.dueDate).setHours(23, 59, 59, 0);

        return {
          emiNumber: emi.emiNumber,
          emiName: emi.emiName,
          emiAmount: emi.emiAmount,
          dueDate: dueDate,
        };
      });
      const body = {
        batchId: batchData?.id,
        paymentType: "REGULAR",
        totalAmount: parseFloat(regularPrice),
        discountMetric:
          discountMetric !== "" && parseFloat(discount) > 0
            ? discountMetric
            : "",
        discount:
          discountMetric !== "" && parseFloat(discount) > 0
            ? parseFloat(discount)
            : 0,
        finalAmount: parseFloat(finalPrice),
        discountAdded: false,
        // enableInstallment: rows?.length > 0 ? enableInstallment : false,
        enableInstallment: enableInstallment,
        scheduleInstallment: setupInstallment,
        numberOfInstallment: installmentNumber,
        emiMode: selectedEmiMode,
        startDate: new Date(rows[0]?.dueDate).setHours(0, 0, 0, 0),
        paymentStructure: setupInstallment ? updatedEmis : [],
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };

      if (
        // enableInstallment &&
        setupInstallment &&
        !emiDefinedError &&
        updatedEmis?.length > 0 &&
        !hasEmptyPaymentName &&
        hasPriceNotGreaterThanZero &&
        checkCumulativeEmiAmount() === null &&
        !endDateError &&
        !discountError
      ) {
        dispatch<any>(updateBatchPayment(body));
      } else if (
        // !enableInstallment &&
        !setupInstallment &&
        !endDateError &&
        !discountError
      ) {
        dispatch<any>(updateBatchPayment(body));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchData) {
      setRegularPrice(batchData?.paymentDetails?.paymentData?.totalAmount);
      setFinalPrice(batchData?.paymentDetails?.paymentData?.finalPrice);
      if (
        batchData?.paymentDetails?.paymentData?.discountMetric !== "" &&
        batchData?.paymentDetails?.paymentData?.discount > 0
      ) {
        setDiscount(batchData?.paymentDetails?.paymentData?.discount);
        setDiscountMetric(
          batchData?.paymentDetails?.paymentData?.discountMetric
        );
      }
      setEnableInstallment(
        batchData?.paymentDetails?.paymentData?.enableInstallment
      );
      setSetupInstallment(
        batchData?.paymentDetails?.paymentData?.scheduleInstallment
      );
      const emiMode = batchData?.paymentDetails?.paymentData?.emiMode;
      setSelectedEmiMode(batchData?.paymentDetails?.paymentData?.emiMode);
      let emiDays: number;

      switch (emiMode) {
        case "MONTHLY":
          emiDays = 30;
          break;
        case "QUARTERLY":
          emiDays = 90;
          break;
        case "HALF_YEARLY":
          emiDays = 180;
          break;
        case "YEARLY":
          emiDays = 360;
          break;
        default:
          emiDays = 0;
      }
      setSelectedEmiModeDays(emiDays);
      setInstallmentNumber(
        batchData?.paymentDetails?.paymentData?.numberOfInstallment
      );
      setStartDate(batchData?.paymentDetails?.paymentData?.startDate);
      setEmiStartDate(batchData?.paymentDetails?.paymentData?.startDate);

      if (
        batchData?.paymentDetails?.paymentData?.paymentStructure?.length > 0
      ) {
        const setEmis =
          batchData?.paymentDetails?.paymentData?.paymentStructure?.map(
            (emi: any) => {
              return {
                emiNumber: emi.emiNumber,
                emiName: emi.emiName,
                emiAmount: emi.emiAmount,
                dueDate: emi.dueDate,
                isNameValid: true,
                isPriceValid: true,
              };
            }
          );
        setRows(setEmis);
      }
    }
  }, [batchData, regularPayEdit]);

  return (
    <>
      <div className={styles.batchEditRegularPayWrap}>
        <div
          className={styles.batchEditRegularPayRow}
          style={{ justifyContent: "space-between" }}
        >
          <div
            className={styles.batchEditRegularPayPrice}
            style={{ maxWidth: "200px" }}
          >
            <div className={styles.batchEmiEditMainRowLabel}>Total Price</div>
            <TextField
              id="totalPrice"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{
                sx: {
                  width: "200px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "14px",
                  },

                  "&.MuiOutlinedInput-input": {
                    padding: "8px",
                  },
                },
              }}
              value={regularPrice}
              onChange={(event) => handleTotalPrice(event.target.value)}
              error={regularPriceError}
              helperText={regularPriceError ? "Please fill valid amount" : ""}
              disabled
            />
          </div>
          <div>
            <div className={styles.batchEmiEditMainRowLabel}>Discount</div>
            <DiscountInput
              discountMetric={discountMetric}
              discount={discount}
              handleDiscountMetric={handleDiscountMetric}
              handleDiscount={handleDiscount}
              totalPrice={regularPrice}
              selectedCurrency={selectedCurrency}
            />
          </div>

          <div style={{ minWidth: "200px" }}>
            <div className={styles.batchEmiEditMainRowLabel}>Final Price</div>
            <div
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                color: "#11C89B",
                fontWeight: "500",
              }}
            >
              {selectedCurrency + " " + parseFloat(finalPrice)}
            </div>
          </div>
        </div>
        <div className={styles.batchEditRegularPayRow}>
          <div>
            <div className={styles.batchEmiEditMainRowLabel}>
              Installment
              <span
                style={{
                  fontSize: "12px",
                  color: "#667085",
                  fontWeight: "400",
                  marginLeft: "5px",
                }}
              >
                (Installment can be Configured on lead level as well)
              </span>
            </div>

            <div className={styles.batchEditRegularEmiStatus}>
              <SwitchInput
                label="Enable"
                switchStatus={enableInstallment}
                handleSwitch={handleEnableInstallment}
              />
              {enableInstallment && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={setupInstallment}
                      onChange={handleSetupEmi}
                      sx={{
                        strokeWidth: 1,
                        color: "#D0D5DD",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        "&:focus": {
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#667085",
                        lineHeight: "16px",
                      }}
                    >
                      Configure Installment
                    </Typography>
                  }
                />
              )}
            </div>
          </div>
        </div>
        {setupInstallment && (
          <>
            <div className={styles.batchEmiTableTitle}>Setup Installment</div>
            <div className={styles.batchEmiEditMainRow}>
              <div>
                <div className={styles.batchEmiEditMainRowLabel}>Mode</div>
                <div style={{ maxWidth: "180px", minWidth: "180px" }}>
                  <Select
                    id="emi-mode-select"
                    fullWidth
                    MenuProps={{
                      style: {
                        zIndex: 999999,
                        fontSize: "14px",
                      },
                      PaperProps: {
                        style: {
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                        },
                      },
                    }}
                    sx={{
                      maxHeight: "39PX",

                      "& .MuiSelect-outlined": {
                        fontSize: "14px",
                        color: "#142C44",
                      },
                    }}
                    value={selectedEmiMode}
                    onChange={(event) =>
                      handleEmiMode(event.target.value as string)
                    }
                  >
                    {availableEmiModes?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.emiModeValue}
                        sx={{
                          fontSize: "12PX",
                          color: "#667085",
                          textTransform: "capitalize",
                          fontWeight: "400",
                        }}
                      >
                        {item.emiModeLabel}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              {selectedEmiMode !== "" && (
                <>
                  <div style={{ minWidth: "180px", maxWidth: "180px" }}>
                    <div className={styles.batchEmiEditMainRowLabel}>
                      Start Date
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          sx={{
                            // zIndex: "99999999 !important",
                            width: "180px",
                            fontSize: "14px",
                            "& .MuiOutlinedInput-input": {
                              fontSize: "14px",
                              color: "#142C44",
                              padding: "9px",
                            },
                          }}
                          format="DD/MM/YYYY"
                          onChange={(date: any) => {
                            setStartDate(date);
                            handleDateChange(date);
                          }}
                          //   value={dayjs(startDate)}
                          value={startDate ? dayjs(startDate) : null}
                          shouldDisableDate={(date) => {
                            const isBeforeStart = dayjs(date).isBefore(
                              batchStartDateMilliSeconds,
                              "day"
                            );

                            const oneDayABeforeBatchEnd = dayjs(
                              batchEndDateMilliSeconds
                            ).subtract(1, "day");
                            const isAfterEnd = dayjs(date).isAfter(
                              oneDayABeforeBatchEnd
                            );

                            return isBeforeStart || isAfterEnd;
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div style={{ minWidth: "180px" }}>
                    {startDate !== null && (
                      <>
                        <div className={styles.batchEmiEditMainRowLabel}>
                          No. of Installments
                        </div>
                        <div style={{ maxWidth: "200px", overflow: "hidden" }}>
                          <TextField
                            id="totalPrice"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              sx: {
                                //   maxWidth: "200px",
                                //   width: "200px",
                                "&::placeholder": {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                },

                                "&.MuiOutlinedInput-input": {
                                  padding: "8px",
                                },
                              },
                            }}
                            value={installmentNumber}
                            onChange={(event: any) =>
                              handleEmiCount(event.target.value)
                            }
                            //   error={regularPriceError}
                            //   helperText={regularPriceError ? "Please fill valid amount" : ""}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {rows?.length > 0 && (
              <>
                <div>
                  <div className={styles.batchEmiTableTitle}>Breakdowns</div>
                  <div
                    className={styles.batchEmiTable}
                    style={{ border: "none" }}
                  >
                    <div className={styles.batchEmiTableHead}>
                      <div style={{ width: "5%" }}>#</div>
                      <div style={{ width: "37%" }}>Installment Name</div>
                      <div style={{ width: "36%" }}>Installment Amount</div>
                      <div style={{ width: "20%" }}>Due Date</div>
                    </div>
                    <div className={styles.batchEmiTableBody}>
                      {rows?.map((emiItem: any, index: number) => (
                        <div className={styles.batchEmiTableRow} key={index}>
                          <div style={{ width: "5%" }}>
                            {emiItem?.emiNumber}
                          </div>
                          <div style={{ width: "37%" }}>
                            <TextField
                              id="emiName"
                              variant="outlined"
                              type="text"
                              inputProps={{
                                sx: {
                                  width: "120px",
                                  maxWidth: "170px",
                                  "&::placeholder": {
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  },

                                  "&.MuiOutlinedInput-input": {
                                    padding: "8px",
                                  },
                                },
                              }}
                              value={emiItem?.emiName}
                              onChange={(event) =>
                                handleEmiNameChange(index, event)
                              }
                              error={
                                !emiItem?.isNameValid ||
                                !isNameUniqueCheck(rows, emiItem)
                              }
                              helperText={
                                !isNameUniqueCheck(rows, emiItem) &&
                                !emiItem?.isNameValid
                                  ? "Payment name already exist"
                                  : isNameUniqueCheck(rows, emiItem) &&
                                    !emiItem?.isNameValid
                                  ? "Please fill the field"
                                  : ""
                              }
                            />
                          </div>
                          <div style={{ width: "36%" }}>
                            <TextField
                              id="emiPrice"
                              variant="outlined"
                              type="number"
                              inputProps={{
                                sx: {
                                  width: "120px",
                                  maxWidth: "170px",
                                  "&::placeholder": {
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  },

                                  "&.MuiOutlinedInput-input": {
                                    padding: "8px",
                                  },
                                },
                              }}
                              value={emiItem?.emiAmount}
                              onChange={(event) =>
                                handleEmiPriceChange(index, event)
                              }
                              error={
                                !emiItem?.isPriceValid ||
                                emiItem?.emiAmount > regularPrice
                              }
                              helperText={
                                !emiItem?.isPriceValid
                                  ? "Please fill valid amount"
                                  : emiItem?.isPriceValid &&
                                    emiItem?.emiAmount > regularPrice
                                  ? "Amount greater than total price"
                                  : ""
                              }
                            />
                          </div>
                          <div style={{ width: "20%" }}>
                            {selectedEmiMode !== "CUSTOM" ? (
                              <>
                                {moment(emiItem?.dueDate).format("DD/MM/YYYY")}
                              </>
                            ) : (
                              <>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DesktopDatePicker
                                    sx={{
                                      // zIndex: "99999999 !important",
                                      width: "160px",
                                      fontSize: "14px",
                                      "& .MuiOutlinedInput-input": {
                                        fontSize: "14px",
                                        color: "#142C44",
                                        padding: "9px",
                                      },
                                    }}
                                    format="DD/MM/YYYY"
                                    onChange={(date: any) => {
                                      handleEmiDueDate(index, date);
                                    }}
                                    value={
                                      emiItem?.dueDate
                                        ? dayjs.unix(emiItem.dueDate / 1000)
                                        : null
                                    }
                                    // shouldDisableDate={(date) => {
                                    //   const isBeforeStart = dayjs(
                                    //     date
                                    //   ).isBefore(
                                    //     batchStartDateMilliSeconds,
                                    //     "day"
                                    //   );

                                    //   const oneDayABeforeBatchEnd = dayjs(
                                    //     batchEndDateMilliSeconds
                                    //   ).subtract(1, "day");
                                    //   const isAfterEnd = dayjs(date).isAfter(
                                    //     dayjs(batchEndDateMilliSeconds).startOf(
                                    //       "day"
                                    //     ),
                                    //     "day"
                                    //   );
                                    //   return isBeforeStart || isAfterEnd;
                                    // }}
                                    minDate={dayjs(batchStartDateMilliSeconds)}
                                    maxDate={dayjs(
                                      batchEndDateMilliSeconds
                                    ).subtract(1, "day")}
                                  />
                                </LocalizationProvider>
                                {!isDueDateAfterPrevious(index, rows) &&
                                  emiItem.dueDate !== null && (
                                    <FormHelperText error>
                                      Please select date past the previous due
                                      date
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.balance}>
                    <div>Balance:</div>
                    <div>
                      {balanceAmountInEmi >= -1 && balanceAmountInEmi <= 1
                        ? 0
                        : balanceAmountInEmi}
                    </div>
                  </div>
                </div>
                {rows?.[rows.length - 1]?.dueDate > batchEndDateMilliSeconds ? (
                  <FormHelperText error>
                    Last EMI due date exceeds batch end date
                  </FormHelperText>
                ) : (
                  <>
                    {checkCumulativeEmiAmount !== null && (
                      <FormHelperText error>
                        {checkCumulativeEmiAmount()}
                      </FormHelperText>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {emiError && <FormHelperText error>{emiError}</FormHelperText>}

        <div className={styles.editSectionBtnWrap}>
          <CancelBtn
            // onClick={() => cancelButtonClick()}
            onClick={() => setRegularPayEdit(false)}
          />
          <SaveBtn
            onClick={handleSubmit}
            disabled={
              batchPayUpdateLoading ||
              regularPrice === "" ||
              parseFloat(regularPrice) <= 0 ||
              parseFloat(finalPrice) <= 0 ||
              (setupInstallment && checkCumulativeEmiAmount() !== null) ||
              hasDueDateEmpty ||
              !isDueDatesValid(rows)
              // ((enableInstallment || setupInstallment) &&
              //   checkCumulativeEmiAmount() !== null)
            }
            loader={batchPayUpdateLoading}
          />
        </div>
      </div>
    </>
  );
}

export default EditBatchRegular;
