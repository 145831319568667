import { useEffect, useState, useCallback, useDeferredValue } from "react";
import {
  Box,
  CircularProgress,
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Popover,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpenSearchData,
  resetOpenSearch,
  setLeadModalState,
  getLeadListByInstituteAndContact,
} from "../../service/nurtureSlice";
import { useAuthUser } from "react-auth-kit";
import ClearIcon from "@mui/icons-material/Clear";

const OpenSearch = ({
  fromNurture,
  selectedCourse,
  placeholder = "Search Leads",
}: any) => {
  const [leadSearchTerm, setLeadSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabSize = useMediaQuery(theme.breakpoints.down("lg"));
  const deferredQuery = useDeferredValue(leadSearchTerm);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSearchData = useSelector(
    (state: any) => state.nurture.openSearchData
  );
  const openSearchLoading = useSelector(
    (state: any) => state.nurture.openSearchLoading
  );
  const courseList = useSelector((state: any) => state.courses.courses);

  const handleSearch = (event: any, newValue: string) => {
    setLeadSearchTerm(newValue);
  };
  useEffect(() => {
    const fetchSearchResults = () => {
      setLoading(true);
      setOptions([]);
      const queryParams = new URLSearchParams(window.location.search);
      const courseId = queryParams.get("selectedCourse");
      const leadStatus = queryParams.get("nurtureLeadStatus");
      const body = {
        domainIndex: "narture",
        entityName: "ENQUIRY",
        searchText: deferredQuery,
        instituteId: authUser?.institute[0]?.instituteId,
        courseId: courseId,
        branchId:
          authUser?.institute[0]?.role === "Super Admin"
            ? ""
            : authUser?.institute[0]?.branchId,
        enquiryStatus: leadStatus === "LOST" ? "LOST" : "ACTIVE",
      };
      const studentBody = {
        entityName: "BATCHSTUDENTMAPPING",
        searchText: deferredQuery,
        instituteId: authUser?.institute[0]?.instituteId,
        courseId: "",
        branchId:
          authUser?.institute[0]?.role === "Super Admin"
            ? ""
            : authUser?.institute[0]?.branchId,
      };
      dispatch(resetOpenSearch());
      if (deferredQuery !== "") {
        dispatch<any>(getOpenSearchData(fromNurture ? body : studentBody));
      }
      setLoading(false);
    };
    fetchSearchResults();
  }, [deferredQuery]);
  useEffect(() => {
    if (openSearchData?.hits?.hits) {
      setOptions(openSearchData?.hits?.hits);
    }
  }, [openSearchData, setOptions]);

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = { id: instituteId, phone: phone };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = { id: instituteId, email: email };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    [dispatch]
  );
  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    [leadPopupApiCall, dispatch]
  );
  useEffect(() => {
    return () => {
      setLeadSearchTerm("");
    };
  }, [selectedCourse]);
  const handleClearSearch = () => {
    setLeadSearchTerm("");
  };
  const open = Boolean(anchorEl);
  const id = open ? "search-results-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showCourseName = (id: string) => {
    const courseName =
      courseList && courseList?.filter((course: any) => course?.id === id);
    return courseName[0]?.courseName;
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      {" "}
      <Autocomplete
        freeSolo
        disableClearable={false}
        options={leadSearchTerm ? options.map((option) => option?._source) : []}
        getOptionLabel={(option: any) =>
          [
            option?.studentFirstName || "",
            option?.studentLastName || "",
            option?.studentContact || "",
            option?.studentEmail || "",
          ]
            ?.filter(Boolean)
            ?.join(" ")
        }
        size="small"
        inputValue={leadSearchTerm}
        onInputChange={handleSearch}
        loading={loading}
        onChange={(event, newValue) => {
          if (newValue) {
            openLeadExpandModal(
              newValue.instituteId,
              newValue.studentContact,
              newValue.studentEmail,
              fromNurture ? newValue?.id : newValue?.enquiryId
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {" "}
                  {leadSearchTerm && (
                    <InputAdornment position="end" sx={{ display: "flex" }}>
                      {" "}
                      <IconButton onClick={handleClearSearch} edge="end">
                        {" "}
                        <ClearIcon />{" "}
                      </IconButton>{" "}
                    </InputAdornment>
                  )}{" "}
                </>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": { paddingRight: "10px !important" },
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: "10px !important",
              },
            }}
          />
        )}
        sx={{
          width: isTabSize ? "150px" : "300px",
        }}
        renderOption={(props, option: any) => {
          return (
            <div
              key={option?.id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                padding: "0px 10px",
                cursor: "pointer",
                marginBottom: "5px",
              }}
              onClick={() => {
                openLeadExpandModal(
                  option?.instituteId,
                  option?.studentContact,
                  option?.studentEmail,
                  fromNurture ? option?.id : option?.enquiryId
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  cursor: "pointer",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  {`${option?.studentFirstName || ""} ${
                    option?.studentLastName || ""
                  }`}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    overflow: "hidden",
                  }}
                >
                  {`${option?.studentEmail ? option?.studentEmail : ""} ${
                    option?.studentContact ? "+" : ""
                  }${option?.studentContact ? option?.studentContact : ""}`}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    overflow: "hidden",
                  }}
                >
                  {showCourseName(option?.courseId)}
                </div>
              </div>
            </div>
          );
        }}
      />
      {leadSearchTerm && (openSearchLoading || options.length === 0) && (
        <Box
          padding={"1.25px 18px"}
          marginTop={"7px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"8px"}
          border={"1px solid #E4E4E7"}
          width={"calc(100% - 0px)"}
          position={"absolute"}
          top="35px"
          zIndex={options?.length > 0 ? "0" : "99999999"}
          bgcolor={"#fff"}
          height={"150px"}
        >
          {" "}
          {openSearchLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              {" "}
              <CircularProgress size={24} />{" "}
            </div>
          )}{" "}
          {leadSearchTerm && !openSearchLoading && options.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              {" "}
              No result found{" "}
            </div>
          )}{" "}
        </Box>
      )}{" "}
    </div>
  );
};
export default OpenSearch;
