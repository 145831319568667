import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { tableCellClasses } from "@mui/material/TableCell";
import styles from "../../Customize.module.css";
import getTeamMember from "../../../../../../../components/CommonFunctions/CommonFunctions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import editIcon from "../../../../../../../assets/images/edit-icon.svg";
import visibility from "../../../../../../../assets/images/visibility.svg";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import moment from "moment";
import ConfirmPopup from "../../../../../../../components/CommonPopups/ConfirmPopup";
import {
  editLeadForm,
  getAddLeadFormForInstitute,
  getAddLeadForms,
  resetSettingsStatuses,
} from "../../../../service/settingsSlice";
import { useDispatch } from "react-redux";
import { getInstituteById } from "../../../../../Manage/InstituteProfile/service/instituteSlice";
import activateIcon from "../../../../../../../assets/images/activateIcon.svg";
import DeletePopup from "../../../../../../../components/CommonPopups/DeletePopup";
import TableSkeleton from "../../../../../../../components/Skeletons/TableSkeleton";
import LeadFormPreview from "../../LeadFormPreview";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
    padding: "16px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

function AddLeadForms(props: any) {
  const { filteredForms, filterApplied, searchTerm, hasCustomizePermission } =
    props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const employees = useSelector((state: any) => state.staff.staffList);
  const {
    updateLeadFormSuccess,
    updateLeadFormLoading,
    leadForms,
    leadFormsLoading,
  } = useSelector((state: any) => state.appSettings);

  const [activatePopup, setActivatePopup] = useState<boolean>(false);
  const [deactivatePopup, setDeactivatePopup] = useState<boolean>(false);

  const menuOptions = [
    {
      label: "Edit",
      icon: editIcon,
      onClick: (item: any) => {
        navigate(`/app/Settings/Customize/EditLeadForm/${item.id}`);
      },
    },
    {
      label: "Activate",
      icon: activateIcon,
      onClick: (item: any) => {
        setActivatePopup(!activatePopup);
        // setRenameFormData(item);
      },
    },
    {
      label: "Deactivate",
      icon: activateIcon,
      onClick: (item: any) => {
        setDeactivatePopup(!deactivatePopup);
        // setRenameFormData(item);
      },
    },
  ];

  const [currentItem, setCurrentItem] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [previewItem, setPreviewItem] = useState<any>(null);
  const [previewModal, setPreviewModal] = useState<any>(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick =
    (item: any) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setCurrentItem(item);
      if (event.currentTarget.textContent === "Activate") {
        setActivatePopup(true);
      }
      //  else if (event.currentTarget.textContent === "Duplicate") {
      //   setShowDuplicatePopup(true);
      // } else if (event.currentTarget.textContent === "Archive") {
      //   setShowArchivePopup(true);
      // }
    };

  const handleViewOpen = (item: any) => {
    const updatedFields = item?.additionalFields?.map((item: any) => ({
      fieldType: item?.field?.fieldType,
      fieldName: item?.field.fieldName
        ? item?.field.fieldName.charAt(0).toUpperCase() +
          item?.field.fieldName.slice(1).toLowerCase()
        : "",
      fieldPlaceholder: item?.field?.fieldPlaceholder,
      options: item?.field?.options,
      required: item?.field?.required,
      isValid: true,
    }));
    setPreviewItem(updatedFields);
    setPreviewModal(true);
  };
  const handleViewClose = () => {
    setPreviewItem(null);
    setPreviewModal(false);
  };
  const handleActivateClose = () => {
    setActivatePopup(false);
    setCurrentItem(null);
  };

  const handleActivateConfirm = async (event: any) => {
    event.preventDefault();

    try {
      dispatch<any>(
        editLeadForm({
          ...currentItem,
          status: "ACTIVE",
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
        })
      );
    } catch (error) {}
  };

  const handleDeactivateClose = () => {
    setDeactivatePopup(false);
    setCurrentItem(null);
  };
  const handleDeactivateConfirm = async (event: any) => {
    event.preventDefault();

    try {
      dispatch<any>(
        editLeadForm({
          ...currentItem,
          status: "INACTIVE",
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
        })
      );
    } catch (error) {}
  };

  React.useEffect(() => {
    if (updateLeadFormSuccess !== true && updateLeadFormSuccess !== false) {
      setActivatePopup(false);
      setDeactivatePopup(false);
      dispatch<any>(getAddLeadForms(activeInstitute?.id));
      dispatch(resetSettingsStatuses());
      dispatch<any>(getInstituteById(activeInstitute?.id));
      dispatch<any>(getAddLeadFormForInstitute(activeInstitute.id));
    }
  }, [updateLeadFormSuccess]);
  return (
    <div>
      {leadForms?.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {leadFormsLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          ) : (
            <div
              style={{
                marginTop: "200px",
              }}
            >
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#667085",
                  marginBottom: "22px",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                No Forms Created Yet.
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {leadFormsLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableContainer
                className={styles.webformTableContainer}
                sx={{
                  minWidth: 700,
                  borderRadius: "8PX",
                  border: "1px solid  #EAECF0",
                  maxHeight: "calc(100vh - 300px)",
                  overflowY: "auto",
                }}
              >
                <Table
                  sx={{
                    minWidth: 700,
                  }}
                  aria-label="customized table"
                >
                  <TableHead
                    className={styles.batchPopupHead}
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 999,
                    }}
                  >
                    <TableRow>
                      <StyledTableCell className={styles.cellFormName}>
                        Form Name
                      </StyledTableCell>

                      <StyledTableCell
                        className={styles.batchPopupHeadCell}
                        align="left"
                        sx={{ maxWidth: "370px" }}
                      >
                        ID
                      </StyledTableCell>
                      <StyledTableCell
                        className={styles.cellCreatedBy}
                        align="left"
                      >
                        Created by
                      </StyledTableCell>

                      <StyledTableCell
                        className={styles.cellCreatedDate}
                        align="left"
                        style={{ width: "20%" }}
                      >
                        Created Date
                      </StyledTableCell>
                      <StyledTableCell
                        className={styles.batchPopupHeadCell}
                        align="left"
                        style={{ fontSize: "13px" }}
                      >
                        Status
                      </StyledTableCell>

                      <StyledTableCell
                        className={styles.batchPopupHeadCell}
                        align="left"
                        style={{ fontSize: "13px" }}
                        width={110}
                      >
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchTerm !== "" && filteredForms?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No forms found with the specified search term.
                        </TableCell>
                      </TableRow>
                    ) : searchTerm === "" &&
                      filterApplied &&
                      filteredForms?.length === 0 ? (
                      <TableCell colSpan={7} align="center">
                        No forms found with the specified filter.
                      </TableCell>
                    ) : (
                      <>
                        {filteredForms?.map((item: any, index: any) => (
                          <TableRow key={index}>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.formName}
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.formID}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {getTeamMember(item?.createdById, employees)
                                ?.firstName +
                                " " +
                                getTeamMember(item?.createdById, employees)
                                  ?.lastName}
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {moment(item?.creationDate).format("DD/MM/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.status?.toLowerCase()}
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                                padding: "0px",
                              }}
                              width={110}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item?.status !== "DRAFT" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2px",
                                      padding: "6px 8px",
                                      fontSize: "11px",
                                      color: "#667085",
                                      border:
                                        "1px solid rgba(238, 238, 238, 0.93)",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleViewOpen(item)}
                                  >
                                    <img
                                      src={visibility}
                                      style={{ marginRight: "4PX" }}
                                    />
                                    View
                                  </div>
                                )}
                                {hasCustomizePermission && (
                                  <IconButton
                                    aria-label="menu"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenuClick(item)}
                                  >
                                    <MoreVertIcon sx={{ color: "#BDBFC3" }} />
                                  </IconButton>
                                )}
                              </div>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        sx={{
          "& .MuiMenuItem-root": {
            color: "#667085",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "normal",
          },
        }}
        PaperProps={{
          style: {
            maxHeight: "216px",
            width: "132px",
            boxShadow: "none",
            borderRadius: "4px",
            border: "1px solid #F2F2F2",
            background: "#FFF",
          },
        }}
      >
        {menuOptions
          .filter((menuOption: any) => {
            if (menuOption.label === "Edit") {
              return true;
            }
            if (
              currentItem?.status === "INACTIVE" &&
              menuOption.label === "Activate"
            ) {
              return true;
            }
            if (
              currentItem?.status === "ACTIVE" &&
              menuOption.label === "Deactivate"
            ) {
              return true;
            }
            return false;
          })
          .map((menuOption, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                menuOption.onClick(currentItem);
                handleMenuClose();
              }}
              sx={{ gap: "8px" }}
            >
              <img src={menuOption.icon} alt="" />
              {menuOption.label}
            </MenuItem>
          ))}
      </Menu>
      <ConfirmPopup
        open={activatePopup}
        onClose={handleActivateClose}
        noBtnClick={handleActivateClose}
        yesBtnClick={handleActivateConfirm}
        title={"Are you sure you want to activate"}
        description=""
        loader={updateLeadFormLoading}
      />
      <DeletePopup
        open={deactivatePopup}
        onClose={handleDeactivateClose}
        noBtnClick={handleDeactivateClose}
        yesBtnClick={handleDeactivateConfirm}
        title={"Are you sure you want to deactivate"}
        loader={updateLeadFormLoading}
      />
      <LeadFormPreview
        open={previewModal}
        onClose={handleViewClose}
        fields={previewItem}
      />
    </div>
  );
}

export default AddLeadForms;
