// src/store/compressSlice.js
import { createSlice } from "@reduxjs/toolkit";

const attendanceDataUpdationSlice = createSlice({
  name: "attendanceData",
  initialState: {
    dateOfViewedItem: null,
  },
  reducers: {
    setDateOfViewedItem: (state, action) => {
      state.dateOfViewedItem = action.payload;
    },
  },
});

export const { setDateOfViewedItem } = attendanceDataUpdationSlice.actions;

export default attendanceDataUpdationSlice.reducer;
