import React, { useEffect, useState } from "react";
import styles from "./LeadPopup.module.css";
import highPriorityIcon from "../FlagAssign/assets/High.svg";
import urgentPriorityIcon from "../FlagAssign/assets/Urgent.svg";
import normalPriorityIcon from "../FlagAssign/assets/Normal.svg";
import lowPriorityIcon from "../FlagAssign/assets/Low.svg";
import editIcon from "./Assets/edit-icon.svg";
import LeadDetailsEdit from "../../pages/app/Nurture/components/LeadExpand/LeadDetailsEdit";
import { WhatsApp } from "@mui/icons-material";
import LeadCallPopup from "./LeadCallPopup";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import LeadPhoneChangeConfirmPopup from "./subComponents/LeadPhoneChangeConfirmPopup";
import LeadCallDispositionForm from "./subComponents/LeadCallDispositionForm";
import { Tooltip } from "@mui/material";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function LeadDetailsTop(props: any) {
  const {
    leadById,
    employees,
    GetLeadById,
    UpdateLead,
    selectedLeadData,
    CallLead,
    taskTypes,
    leadListByContact,
    leadPopupApiCall,
    CallDispositionForm,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [isLeadEditOpen, setLeadEditOpen] = React.useState<boolean>(false);
  const [assignyId, setAssignyId] = useState<any>();
  const [editPriority, setEditPriority] = useState<any>();
  const [callDispositionShowStatus, setCallDispositionShowStatus] =
    useState<any>("");
  const [callUcid, setCallUcid] = useState<any>("");
  const [callDID, setCallDID] = useState<any>("");

  const toggleLeadEditModal = () => {
    setLeadEditOpen(!isLeadEditOpen);
  };

  const openLeadEditModal = (
    leadId: number,
    assignyId: any,
    enquiryPriority: any
  ) => {
    setLeadEditOpen(true);
    GetLeadById(leadId && leadId);
    setAssignyId(assignyId);
    setEditPriority(enquiryPriority);
  };

  const formatForWhatsApp = (countryCode: any, contact: any) => {
    const formattedContact = contact.replace(/\D+/g, "");
    const countryCodeLength = countryCode.length;
    // const strippedContact = formattedContact.startsWith("91")
    //   ? formattedContact.slice(2)
    //   : formattedContact;
    const strippedContact = formattedContact?.slice(countryCodeLength);
    return `${countryCode}${strippedContact}`;
  };

  const ozonetelEmployee = employees?.find(
    (item: any) => item.employee === authUser?.institute[0]?.employee
  );
  const hasOzonetelConfig = ozonetelEmployee?.ozonetelConfig !== undefined;

  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const hasOzonetel =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("OZONETEL");

  const [phonePopup, setPhonePopup] = useState<boolean>(false);
  const [leadEdit, setLeadEdit] = useState<any>("");

  const onPhoneChangePopup = React.useCallback((leadData: any | null) => {
    setPhonePopup(true);
    setLeadEdit(leadData);
  }, []);

  const handlePhonePopupClose = () => {
    setPhonePopup(false);
    setLeadEdit("");
  };

  const handlePhoneChangeSubmit = async (event: any) => {
    event.preventDefault();

    try {
      UpdateLead(leadEdit);
    } catch (error) {}
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const addLeadPayment = urlSearchParams.get("addLeadPayment");
    if (addLeadPayment === "true") {
      openLeadEditModal(
        selectedLeadData?.id,
        selectedLeadData?.assignedToId,
        selectedLeadData?.enquiryPriority
      );
    }
  }, [selectedLeadData]);

  return (
    <>
      <div className={styles.leadDetailsHead}>
        {selectedLeadData?.hasOwnProperty("leadProfileImage") &&
        selectedLeadData?.leadProfileImage ? (
          <div className={styles.leadDetailsPic}>
            <img src={selectedLeadData.leadProfileImage} alt="profile" />
          </div>
        ) : (
          <div className={styles.leadDetailsAvatar}>
            {selectedLeadData?.studentFirstName?.charAt(0)}
          </div>
        )}
        <div className={styles.leadNameWrap}>
          {selectedLeadData?.studentFirstName !== undefined &&
          selectedLeadData?.studentLastName !== undefined ? (
            <div className={styles.leadName}>
              {`${selectedLeadData && selectedLeadData?.studentFirstName} ${
                selectedLeadData && selectedLeadData?.studentLastName
              }`}
            </div>
          ) : (
            ""
          )}

          <div>
            <Tooltip
              arrow
              title={hasLeadAccess === false ? "Oops! No permission." : ""}
              PopperProps={{ style: { zIndex: 9999999 } }}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    zIndex: "9999999",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                      zIndex: "9999999",
                    },
                  },
                },
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  textAlign: "center",
                }}
              >
                <button
                  className={styles.editBtn}
                  onClick={() =>
                    openLeadEditModal(
                      selectedLeadData.id,
                      selectedLeadData.assignedToId,
                      selectedLeadData.enquiryPriority
                    )
                  }
                  disabled={hasLeadAccess === false ? true : false}
                >
                  <img src={editIcon} alt="edit" />
                </button>
              </span>
            </Tooltip>
            {isLeadEditOpen && (
              <>
                <LeadDetailsEdit
                  toggleLeadEditModal={toggleLeadEditModal}
                  setLeadEditOpen={setLeadEditOpen}
                  isLeadEditOpen={isLeadEditOpen}
                  leadDetails={leadById && leadById}
                  users={employees && employees}
                  UpdateLead={UpdateLead}
                  assignyId={assignyId}
                  editDefaultPriority={editPriority}
                  onPhoneChangePopup={onPhoneChangePopup}
                  leadListByContact={leadListByContact}
                  leadPopupApiCall={leadPopupApiCall}
                />
              </>
            )}
            <LeadPhoneChangeConfirmPopup
              open={phonePopup}
              onClose={handlePhonePopupClose}
              yesButtonClick={handlePhoneChangeSubmit}
              leadEdit={leadEdit}
            />
          </div>
        </div>
        <div className={styles.leadStudentId}>
          {selectedLeadData?.studentId}
        </div>

        <div className={styles.leadContactDetails}>
          <div>
            {selectedLeadData?.studentEmail}{" "}
            {selectedLeadData?.studentEmail &&
              selectedLeadData?.studentContact &&
              " | "}
          </div>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <div>
              {selectedLeadData?.studentContact
                ? `+${selectedLeadData?.contactCountryCode || "91"} ${
                    selectedLeadData?.contactCountryCode
                      ? selectedLeadData?.studentContact?.replace(
                          selectedLeadData?.contactCountryCode,
                          ""
                        )
                      : selectedLeadData?.studentContact?.startsWith("91")
                      ? selectedLeadData?.studentContact?.replace("91", "")
                      : selectedLeadData?.studentContact
                  }`
                : null}
            </div>
            <div>
              {hasOzonetel &&
                hasOzonetelConfig &&
                selectedLeadData?.studentContact && (
                  <>
                    <LeadCallPopup
                      CallLead={CallLead}
                      selectedLeadData={selectedLeadData}
                      employees={employees}
                      setCallDispositionShowStatus={
                        setCallDispositionShowStatus
                      }
                      setCallUcid={setCallUcid}
                      setCallDID={setCallDID}
                    />
                  </>
                )}
              <LeadCallDispositionForm
                employees={employees}
                taskTypes={taskTypes}
                CallDispositionForm={CallDispositionForm}
                selectedLeadData={selectedLeadData}
                callDispositionShowStatus={callDispositionShowStatus}
                setCallDispositionShowStatus={setCallDispositionShowStatus}
                setCallUcid={setCallUcid}
                callUcid={callUcid}
                callDID={callDID}
                setCallDID={setCallDID}
              />
            </div>
          </div>
        </div>
        <div className={styles.leadOtherDetailsWrap}>
          <div className={styles.leadSourceDetails}>
            Source:{" "}
            {selectedLeadData?.enquirySource?.toLowerCase().replace(/_/g, " ")}
          </div>
          <div className={styles.leadAssigneeDetails}>
            {getTeamMember(selectedLeadData?.assignedToId, employees)
              ?.firstName?.charAt(0)
              ?.toUpperCase()}
            {getTeamMember(selectedLeadData?.assignedToId, employees)
              ?.lastName?.charAt(0)
              ?.toUpperCase()}
          </div>
          {selectedLeadData?.enquiryPriority !== "" && (
            <div className={styles.leadAssigneeDetails}>
              <img
                src={
                  selectedLeadData?.enquiryPriority === "URGENT"
                    ? urgentPriorityIcon
                    : selectedLeadData?.enquiryPriority === "HIGH"
                    ? highPriorityIcon
                    : selectedLeadData?.enquiryPriority === "NORMAL"
                    ? normalPriorityIcon
                    : selectedLeadData?.enquiryPriority === "LOW"
                    ? lowPriorityIcon
                    : lowPriorityIcon
                }
                alt="priority"
              />
            </div>
          )}
        </div>
        <div className={styles.leadWhatsAppWrap}>
          {selectedLeadData &&
          selectedLeadData?.studentContact &&
          hasLeadAccess === true ? (
            <a
              href={`https://wa.me/${formatForWhatsApp(
                selectedLeadData?.contactCountryCode || "91",
                selectedLeadData?.studentContact
              )}`}
              className={styles.leadWhatsApp}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
              Chat on WhatsApp
            </a>
          ) : (
            <Tooltip
              arrow
              title={
                hasLeadAccess === false
                  ? "Oops! No permission."
                  : "Add a valid phone number to use the WhatsApp services"
              }
              PopperProps={{ style: { zIndex: 9999999 } }}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    zIndex: "9999999",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                      zIndex: "9999999",
                    },
                  },
                },
              }}
            >
              <span>
                <div className={`${styles.leadWhatsApp} ${styles.disabled}`}>
                  <WhatsApp />
                  Chat on WhatsApp
                </div>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
}

export default LeadDetailsTop;
