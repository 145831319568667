import {
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import FlagAssign from "../../../../components/FlagAssign/FlagAssign";
import UserAssign from "../../../../components/UserAssign/UserAssign";
import styles from "./Nurture.module.css";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../components/FormTextInput/types";
import { useAuthUser } from "react-auth-kit";
import CountryCodePhoneInput from "../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import ViewCourseDetailsPopup from "./ViewCourseDetailsPopup/ViewCourseDetailsPopup";
import LeadBatchPopup from "./LeadBatchPopup/LeadBatchPopup";
import SourceManager from "../../../../components/SourceManager/SourceManager";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../../../components/CommonFunctions/CommonFunctions";
import AutoCompleteField from "../../../../components/AutoCompleteField/AutoCompleteField";
import {
  downloadPaymentReceipt,
  editRecordPayment,
  getEmiDataOfLead,
  getLeadById,
  getLeadListByInstituteAndContact,
  getLeadPaymentList,
  recordPayment,
  resetAddLeadPaymentSuccess,
  resetLeadCreateSuccess,
  resetLeadSession,
  resetLoadersForAdditionalPayments,
  sendPaymentLink,
  setLeadModalState,
  updateLead,
  updateLeadEmiDetails,
  updateNoPaymentCollected,
} from "../service/nurtureSlice";
import EditIconBtn from "../../../../components/Buttons/EditIconBtn";
import {
  getContactList,
  resetAddEnquirySuccess,
} from "../../Inbound/service/inboundImportSlice";
import Payment from "../../../../components/Payment/Payment";
import {
  DownloadReceiptDTO,
  NurtureLead,
  RecordPaymentDTO,
  leadEmiDTO,
  sendEmailDTO,
} from "../service/types";
import ViewLeadCardPopUp from "./ViewLeadCard";
import { UpdateNoPaymentsRecord } from "../service/nurture.request";
import { getWebformResponse } from "../../Inbound/Webform/service/webformSlice";
import { getMailEntriesByInstitute } from "../../Inbound/service/inboundSlice";
import AdditionalFields from "./AddLead/AdditionalFields";

const AddLeadPayment = (props: any) => {
  const {
    show,
    onHide,
    nurtureAddEnquiry,
    isEmailDetail = false,
    leadCreateSuccess,
    selectedValue,
    isSourceWidth = false,
    messageId,
    isFromContacts,
    selectedRowDetails,
    leadCreateLoading,
    page,
    isFromWebform,
    responseId,
    formId,
    formDetails,
    findItembyId,
    fieldsName,
    showAddLeadWideView,
    setAddLeadWideView,
    leadCreatedId,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { leadBoard } = useSelector((state: any) => state.nurture);
  const swimlaneData = leadBoard?.swimlaneData;
  const leadStages =
    swimlaneData &&
    [...swimlaneData]?.sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });
  const sourceList = leadBoard?.sourceList;
  const employees = useSelector((state: any) => state.staff.staffList);
  const batchListArray = useSelector((state: any) => state.batch.batchList);
  const courseList = useSelector((state: any) => state.courses.courses);
  const branchList = useSelector((state: any) => state.branch.branchList);
  const { userById } = useSelector((state: any) => state.userDetails);
  const { activeLeadForm } = useSelector((state: any) => state.appSettings);
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );
  const [selectedCurrency, setSelectedCurrency] = React.useState("₹");

  useEffect(() => {
    if (leadCreatedId) {
      const currency = branchList.find(
        (branch: any) => branch.id === leadCreatedId?.branchId
      )?.currency;
      setSelectedCurrency(currency || "₹");
    }
  }, [leadCreatedId]);

  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactCountryCode, setContactCountryCode] = useState("91");
  const [selectedBatch, setSelectedBatch] = useState<any>("");
  const [selectedCourse, setSelectedCourse] = useState<any>("");
  const [selectedBranch, setSelectedBranch] = useState<any>("");
  const [source, setSource] = useState<any>("");
  const [stage, setStage] = useState<any>("");
  const [priority, setPriority] = useState("NORMAL");
  const [assigny, setAssigny] = useState<any>("");
  const [instituteFormDisabled, setInstituteFormDisabled] =
    React.useState<boolean>(false);
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStateLastName, setValidStateLastName] =
    React.useState<boolean>(true);
  const [validStateFirstName, setValidStateFirstName] =
    React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);
  const [checkDuplicate, setCheckDuplicate] = useState<boolean>(true);
  // const filteredEmployees = employees?.filter(
  //   (item: any) => selectedBranch === "" || item.branchId === selectedBranch?.id
  // );
  const combinedAssignees = getCombinedAssignees(employees, selectedBranch?.id);
  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setContactCountryCode("91");
    setSelectedBatch("");
    setSelectedCourse("");
    setSelectedBranch("");
    setSource("");
    setStage("");
    setPriority("");
    setAssigny("");
    setInstituteFormDisabled(true);
    setValidStateEmail(true);
    setValidStateLastName(true);
    setValidStateFirstName(true);
    setShowErrMsz(true);
    setValidStateContact(true);
    setErrorType("client");
    setCheckDuplicate(true);
  };
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);
  const [validStateBatchName, setValidStateBatchName] =
    React.useState<boolean>(true);
  const isCompress = useSelector((state: any) => state.compress.isCompress);
  const [isValidSource, setIsValidSource] = useState(false);
  const [showStudentLeadCard, setShowStudentLeadCard] = useState(false);
  const [additionalFields, setAdditionalFields] = useState<any[]>([]);

  const leadPaymentList = useSelector(
    (state: any) => state.nurture.leadPaymentList
  );
  const filteredPaymentList = leadPaymentList?.filter(
    (item: any) => item?.paymentStatus === "PAID"
  );
  const {
    leadConvertionError,
    leadStatusUpdateError,
    leadEmiDetailsLoading,
    leadEmiData,
    leadById,
    createPaymentSuccess,
    leadEmiDetailsSuccess,
    recordPaymentUpdateSuccess,
    leadUpdateSuccess,
    deleteAdditionalPaySuccess,
    randomPaymentSuccess,
    sendPaymentLinkSuccess,
    leadDiscountSuccess,
    randomPaymentCollectSuccess,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
  } = useSelector((state: any) => state.nurture);

  const RecordPayment = (data: RecordPaymentDTO) => {
    dispatch<any>(recordPayment(data));
  };

  const UpdateLead = (data: NurtureLead) => {
    dispatch<any>(updateLead(data));
  };
  const UpdateLeadEmiDetails = (data: leadEmiDTO) => {
    dispatch<any>(updateLeadEmiDetails(data));
  };
  const GetEmiDataOfLead = (data: any) => {
    dispatch<any>(getEmiDataOfLead(data));
  };
  const SendPaymentLink = (data: sendEmailDTO) => {
    dispatch<any>(sendPaymentLink(data));
  };
  const DownloadPaymentReceipt = (data: DownloadReceiptDTO) => {
    dispatch<any>(downloadPaymentReceipt(data));
  };
  const EditRecordPayment = (data: RecordPaymentDTO) => {
    dispatch<any>(editRecordPayment(data));
  };
  const GetLeadPaymentList = (id: string) => {
    dispatch<any>(getLeadPaymentList(id));
  };

  const GetLeadById = (id: string) => {
    dispatch<any>(getLeadById(id));
  };

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  const setValidateStatusLastName = React.useCallback((val: boolean) => {
    setValidStateLastName(val);
  }, []);
  const setValidateStatusFirstName = React.useCallback((val: boolean) => {
    setValidStateFirstName(val);
  }, []);

  useEffect(() => {
    let allowRefresh = false;
    if (showAddLeadWideView) {
      const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
        if (!allowRefresh) {
          e.preventDefault();
          e.returnValue = ""; // Show default confirmation dialog

          // Safely access the modal and display it if it exists
          const modal = document.getElementById("confirmModal");
          if (modal) {
            modal.style.display = "block";
          }

          return "";
        }
      };

      const handleYesClick = () => {
        allowRefresh = true;
        window.location.reload(); // Perform the refresh manually
      };

      const handleNoClick = () => {
        const modal = document.getElementById("confirmModal");
        if (modal) {
          modal.style.display = "none";
        }
      };

      // Add the beforeunload event listener
      window.addEventListener("beforeunload", beforeUnloadHandler);

      // Add event listeners for Yes and No buttons (make sure they exist)
      const yesButton = document.getElementById("confirmYes");
      const noButton = document.getElementById("confirmNo");

      if (yesButton) {
        yesButton.addEventListener("click", handleYesClick);
      }

      if (noButton) {
        noButton.addEventListener("click", handleNoClick);
      }

      // Cleanup the event listeners when the component unmounts
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);

        if (yesButton) {
          yesButton.removeEventListener("click", handleYesClick);
        }

        if (noButton) {
          noButton.removeEventListener("click", handleNoClick);
        }
      };
    }
  }, [showAddLeadWideView]);

  useEffect(() => {
    if (isFromContacts && selectedRowDetails) {
      setFirstName(selectedRowDetails?.firstName?.replace(/[^a-zA-Z]/g, ""));
      setLastName(selectedRowDetails?.lastName?.replace(/[^a-zA-Z]/g, ""));
      if (selectedRowDetails?.contactNumber) {
        let phoneNumber = `${selectedRowDetails?.contactNoCountryCode}${selectedRowDetails?.contactNumber}`;
        setPhone(phoneNumber);
      }

      let defaultSelectedCourse = courseList?.filter(
        (course: any) => course?.id === selectedRowDetails?.courseId
      );
      if (defaultSelectedCourse?.length > 0) {
        setSelectedCourse(defaultSelectedCourse[0]);
      }
    } else if (isFromWebform) {
      setFirstName(selectedRowDetails?.firstName?.replace(/[^a-zA-Z]/g, ""));
      setLastName(selectedRowDetails?.lastName?.replace(/[^a-zA-Z]/g, ""));
      let phoneNumber = `${selectedRowDetails?.contactNumber}`;
      setPhone(phoneNumber);
      let defaultSelectedCourse = courseList?.filter(
        (course: any) => course?.id === selectedRowDetails?.courseId
      );
      if (defaultSelectedCourse?.length > 0) {
        setSelectedCourse(defaultSelectedCourse[0]);
      }
      const newFieldNames = fieldsName?.filter(
        (item: any) => !["name", "email", "phone"].includes(item.fieldName)
      );
      setContactCountryCode(selectedRowDetails?.contactNoCountryCode);
      if (formDetails && formDetails.length > 2) {
        formDetails[3]?.otherDetails?.map((details: any) => {
          Object.keys(details).forEach((key: any) => {
            // Determine the corresponding field type for the key
            const field = newFieldNames?.find((f: any) => f.fieldName === key);
            if (field) {
              switch (field.fieldType) {
                case "COURSE":
                  setSelectedCourse(
                    findItembyId(field.fieldType, details[key])
                  );
                  break;
                case "BATCH":
                  setSelectedBatch(findItembyId(field.fieldType, details[key]));
                  break;
                case "BRANCH":
                  setSelectedBranch(
                    findItembyId(field.fieldType, details[key])
                  );
                  break;
                default:
                  console.log("Unknown field type:", field.fieldType);
              }
            }
          });
        });
      }
    }
  }, [selectedRowDetails]);

  useEffect(() => {
    setEmail(selectedValue);
  }, [setEmail, selectedValue]);

  useEffect(() => {
    if (page === "CONTACT") {
      const sourceItem = sourceList?.find(
        (item: any) => item.key === "EXCEL_IMPORT"
      );
      setSource(sourceItem);
    } else if (page === "WEBFORM") {
      const sourceItem = sourceList?.find(
        (item: any) => item.key === "WEBFORM"
      );
      setSource(sourceItem);
    } else if (page === "ZAPIER") {
      const sourceItem = sourceList?.find((item: any) => item.key === "ZAPIER");
      setSource(sourceItem);
    } else if (page === "EMAIL") {
      const sourceItem = sourceList?.find((item: any) => item.key === "EMAIL");
      setSource(sourceItem);
    }
  }, [setSource]);

  useEffect(() => {
    if (leadBoard && swimlaneData && leadStages?.length > 0) {
      setStage(leadStages[0]);
    }
  }, [setStage, leadBoard]);

  useEffect(() => {
    if (
      createPaymentSuccess ||
      leadEmiDetailsSuccess ||
      recordPaymentUpdateSuccess ||
      leadUpdateSuccess ||
      deleteAdditionalPaySuccess ||
      randomPaymentSuccess ||
      leadCreatedId ||
      sendPaymentLinkSuccess ||
      leadDiscountSuccess ||
      randomPaymentCollectSuccess ||
      (sessionPayLinkSuccess !== true && sessionPayLinkSuccess !== false) ||
      (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
    ) {
      GetLeadById(leadCreatedId?.id);
      GetEmiDataOfLead({
        id: leadCreatedId?.id,
        loadSessionPayment:
          leadCreatedId?.paymentDetails?.paymentType === "SESSION"
            ? true
            : false,
      });
      GetLeadPaymentList(leadCreatedId?.id);
      dispatch<any>(resetLoadersForAdditionalPayments());
      dispatch(resetLeadSession());
      if (
        (recordPaymentUpdateSuccess !== false &&
          recordPaymentUpdateSuccess !== true &&
          recordPaymentUpdateSuccess !== "Payment link cancelled!") ||
        createPaymentSuccess ||
        randomPaymentCollectSuccess === "Payment Recorded successfully." ||
        (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
      ) {
        setShowStudentLeadCard(true);
      }
    }
  }, [
    leadCreatedId,
    createPaymentSuccess,
    leadEmiDetailsSuccess,
    recordPaymentUpdateSuccess,
    leadUpdateSuccess,
    deleteAdditionalPaySuccess,
    randomPaymentSuccess,
    sendPaymentLinkSuccess,
    leadDiscountSuccess,
    randomPaymentCollectSuccess,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
  ]);

  useEffect(() => {
    if (leadCreatedId) {
      const data = {
        id: leadCreatedId?.id,
        email: leadCreatedId?.studentEmail,
        phone: leadCreatedId?.studentContact,
      };
      dispatch<any>(resetLoadersForAdditionalPayments());
      dispatch<any>(getLeadListByInstituteAndContact(data));
      GetLeadById(leadCreatedId?.id);
      GetEmiDataOfLead({
        id: leadCreatedId?.id,
        loadSessionPayment:
          leadCreatedId?.paymentDetails?.paymentType === "SESSION"
            ? true
            : false,
      });
      GetLeadPaymentList(leadCreatedId?.id);
      setFirstName(leadCreatedId?.studentFirstName);
      setLastName(leadCreatedId?.studentLastName);
      setEmail(leadCreatedId?.studentEmail);
      setPhone(leadCreatedId?.studentContact);
      setSelectedCourse({
        id: leadCreatedId?.courseId,
        courseName: leadCreatedId?.courseName,
      });
      setSelectedBranch({
        branchName: leadCreatedId?.branchName,
        id: leadCreatedId?.branchId,
      });
      setSelectedBatch({
        batchName: leadCreatedId?.batchName,
        id: leadCreatedId?.batchId,
      });
      const sourceOfLead = sourceList?.find(
        (d: any) => d?.key === leadCreatedId?.enquirySource
      );
      setSource(sourceOfLead);
      const stageOfLead = leadStages?.find(
        (d: any) => d?.stateName === leadCreatedId?.enquirySwimlaneStatus
      );
      setStage(stageOfLead);
      setPriority(leadCreatedId?.enquiryPriority);
      setAssigny({
        firstName: leadCreatedId?.assignedToName,
        id: leadCreatedId?.assignedToId,
      });
    }
  }, [leadCreatedId]);

  const filteredBatches =
    batchListArray &&
    batchListArray?.filter((batch: any) => {
      const courseMatch =
        selectedCourse?.id === "" || batch.courseId === selectedCourse?.id;
      const branchMatch =
        selectedBranch?.id === "" || batch.branchId === selectedBranch?.id;
      const regularBatch =
        batch.batchConductType === "REGULAR_BATCH" &&
        batch.batchStatus !== "COMPLETED" &&
        batch.status === "ACTIVE";

      return courseMatch && branchMatch && regularBatch;
    });

  const handleLeadPriority = React.useCallback(
    (leadId: any, leadPriority: any) => {
      setPriority(leadPriority);
    },

    [setPriority]
  );

  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      setAssigny(user);
    },

    [setAssigny]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setSelectedCourse(value);
        setSelectedBatch("");
      } else {
        setSelectedCourse("");
        setSelectedBatch("");
      }
      setValidStateCourse(true);
    },
    []
  );

  const onStageChange = React.useCallback(
    (event: any) => {
      setStage(event.target.value);
    },
    [setStage]
  );

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setSelectedBranch(value);
        setSelectedBatch("");
        setValidStateBranch(true);
      } else {
        setSelectedBranch("");
        setSelectedBatch("");
        setValidStateBranch(false);
      }
    },
    []
  );

  const onBatchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setSelectedBatch(value);
        setValidStateBatchName(true);
      } else {
        setSelectedBatch("");
        setValidStateBatchName(false);
      }
    },
    []
  );

  const onSourceChange = React.useCallback(
    (value: any | null) => {
      if (value !== null) {
        setIsValidSource(false);
        setSource(value);
      } else {
        setSource("");
      }
    },
    [setSource]
  );

  const isValueInList = sourceList?.some(
    (item: any) => item.key === source?.key
  );

  const addenquiry = () => {
    let phoneFormated = phone;
    if (phone === contactCountryCode) {
      phoneFormated = "";
    }
    if (phone?.startsWith("971") && phone?.length !== 12) {
      setValidStateContact(false);
      return;
    }
    let body = {
      instituteId: authUser?.institute?.[0]?.instituteId,
      instituteName: authUser?.institute?.[0]?.instituteName,
      studentEmail: email,
      studentFirstName: firstName,
      studentLastName: lastName,
      studentContact: phoneFormated,
      enquirySource: source?.key,
      courseName: selectedCourse?.courseName,
      courseId: selectedCourse?.id,
      branchName: selectedBranch?.branchName,
      branchId: selectedBranch?.id,
      batchId: selectedBatch?.id ?? "",
      batchName: selectedBatch?.batchName ?? "",
      assignedToName: assigny?.firstName + " " + assigny?.lastName,
      assignedToId: assigny?.id,
      enquiryStatus: stage?.stateName,
      enquiryStatusId: stage?.id,
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      enquiryPriority: priority.toUpperCase(),
      contactCountryCode: contactCountryCode,
      messageId: messageId,
      checkDuplicate: checkDuplicate,
    };

    let webformBody = {
      instituteId: authUser?.institute?.[0]?.instituteId,
      instituteName: authUser?.institute?.[0]?.instituteName,
      studentEmail: email,
      studentFirstName: firstName,
      studentLastName: lastName,
      studentContact: phoneFormated,
      enquirySource: source?.key,
      courseName: selectedCourse?.courseName,
      courseId: selectedCourse?.id,
      branchName: selectedBranch?.branchName,
      branchId: selectedBranch?.id,
      batchId: selectedBatch?.id ?? "",
      batchName: selectedBatch?.batchName ?? "",
      assignedToName: assigny?.firstName + " " + assigny?.lastName,
      assignedToId: assigny?.id,
      enquiryStatus: stage?.stateName,
      enquiryStatusId: stage?.id,
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      enquiryPriority: priority.toUpperCase(),
      contactCountryCode: contactCountryCode,
      messageId: messageId,
      checkDuplicate: checkDuplicate,
      webformId: `WEBFORM_ID#${formId}#STUDENT_CONTACT_ID#${responseId}`,
      isCheckCourse: true,
    };

    const payloadForContactsAddEnquiry = {
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      assignedToId: assigny?.id,
      branchName: selectedBranch?.branchName,
      status: "CREATED",
      createdTime: 0,
      batchId: selectedBatch?.id ?? "",
      createdById: authUser?.institute?.[0]?.id,
      enquiryPriority: priority.toUpperCase(),
      messageId: "",
      courseId: selectedCourse?.id,
      enquirySource: source?.key,
      branchId: selectedBranch?.id,
      courseName: selectedCourse?.courseName,
      enquiryStatus: stage?.stateName,
      batchName: selectedBatch?.batchName ?? "",
      instituteId: authUser?.institute?.[0]?.instituteId,
      instituteName: authUser?.institute?.[0]?.instituteName,
      assignedToName: assigny?.firstName + " " + assigny?.lastName,
      studentList: [
        {
          studentFirstName: firstName,
          studentEmail: email,
          studentLastName: lastName,
          studentContact: phoneFormated,
          contactCountryCode: phoneFormated?.startsWith("971")
            ? "971"
            : contactCountryCode,
          studentContactId: selectedRowDetails?.id,
          otherDetails: [],
        },
      ],
      deleteContact: true,
    };
    let emailReg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/;
    let phoneNumReg =
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;

    try {
      if (!firstName) {
        dispatch(
          setToastNotification({
            message: String("Please enter first name"),
            type: "error",
            snackOpen: true,
          })
        );
        setValidStateFirstName(false);
      } else if (!phone && !email) {
        dispatch(
          setToastNotification({
            message: String("Please enter either phone number or email"),
            type: "error",
            snackOpen: true,
          })
        );
        setValidStateContact(false);
        setValidStateEmail(false);
      } else if (email && !emailReg?.test(email)) {
        dispatch(
          setToastNotification({
            message: String("Please enter valid email"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (phoneFormated && !phoneNumReg?.test(phoneFormated)) {
        dispatch(
          setToastNotification({
            message: "Please enter valid phone number",
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!selectedCourse) {
        dispatch(
          setToastNotification({
            message: String("Please select course"),
            type: "error",
            snackOpen: true,
          })
        );
        setValidStateCourse(false);
      } else if (!selectedBranch) {
        dispatch(
          setToastNotification({
            message: String("Please select branch"),
            type: "error",
            snackOpen: true,
          })
        );
        setValidStateBranch(false);
      } else if (!source) {
        setIsValidSource(true);
        dispatch(
          setToastNotification({
            message: String("Please select source"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!isValueInList) {
        dispatch(
          setToastNotification({
            message: String("Selected source does'nt exist in source list"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!stage) {
        dispatch(
          setToastNotification({
            message: String("Please select stage"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!assigny) {
        dispatch(
          setToastNotification({
            message: String("Please select assignee"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (!priority) {
        dispatch(
          setToastNotification({
            message: String("Please select priority"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (stage?.stateName === "CONVERTED" && !selectedBatch) {
        dispatch(
          setToastNotification({
            message: String("Please select batch"),
            type: "error",
            snackOpen: true,
          })
        );
        setValidStateBatchName(false);
      } else if (
        additionalFields?.length > 0 &&
        instituteFromRedux?.addLeadFormEnabled &&
        additionalFields.some((field) => field.required && !field.fieldValue)
      ) {
        const updatedFields = additionalFields?.map((field) => {
          if (field.required && !field.fieldValue) {
            return { ...field, isValid: false };
          }
          return field;
        });

        setAdditionalFields(updatedFields);
        dispatch(
          setToastNotification({
            message: String("Please fill required additional field"),
            type: "error",
            snackOpen: true,
          })
        );
      } else if (
        additionalFields.length > 0 &&
        instituteFromRedux?.addLeadFormEnabled &&
        additionalFields.some(
          (field) =>
            field.fieldType === "phone" && !field.isValid && !field.required
        )
      ) {
        dispatch(
          setToastNotification({
            message: String("Please fill valid phone number"),
            type: "error",
            snackOpen: true,
          })
        );
      } else {
        // nurtureAddEnquiry(
        //   isFromContacts
        //     ? payloadForContactsAddEnquiry
        //     : isFromWebform
        //     ? webformBody
        //     : body
        // );
        const payload = isFromContacts
          ? payloadForContactsAddEnquiry
          : isFromWebform
          ? webformBody
          : body;

        const enrichedPayload = {
          ...payload,
          ...(additionalFields.length > 0 &&
            instituteFromRedux?.addLeadFormEnabled && {
              additionalInfo: additionalFields?.map((field) => ({
                fieldType: field.fieldType,
                fieldLabel: field.fieldName,
                fieldValue: field.fieldValue,
                options: field.options,
                required: field.required,
              })),
            }),
        };

        nurtureAddEnquiry(enrichedPayload);
      }
    } catch (error) {
      setErrorType("server");
    }
  };

  React.useEffect(() => {
    if (
      leadCreateSuccess !== true &&
      leadCreateSuccess !== false &&
      (leadCreatedId === null || leadCreatedId === undefined)
    ) {
      reset();
    }
  }, [leadCreateSuccess]);

  useEffect(() => {
    if (page === "NURTURE" && courseList) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const selectedCourseUrl = urlSearchParams.get("selectedCourse");

      const course = courseList?.find(
        (item: any) => item.id === selectedCourseUrl
      );

      setSelectedCourse(course);
    }
  }, [courseList]);

  useEffect(() => {
    if (
      (page === "NURTURE" ||
        page === "CONTACT" ||
        page === "EMAIL" ||
        page === "ZAPIER") &&
      filteredBranchList &&
      userById
    ) {
      const branch = filteredBranchList?.find(
        (item: any) => item.id === userById?.branchId
      );
      setSelectedBranch(branch);
      if (branch) {
        const staffMember = employees?.find(
          (item: any) =>
            item.id === userById?.id && item.branchId === userById?.branchId
        );
        setAssigny(staffMember);
      }
    }
    //  else {
    //   const userFromLocalStorage = authUser;
    //   if (employees && userFromLocalStorage) {
    //     const user = employees?.find(
    //       (item: any) =>
    //         item.email.toLowerCase() ===
    //         userFromLocalStorage.email.toLowerCase()
    //     );
    //     setAssigny(user);
    //   }
    // }
  }, [branchList, userById, leadBoard]);

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    [dispatch]
  );

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      addLeadParam: boolean
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (addLeadParam) {
        queryParams.set("addLeadPayment", "true");
      }
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    [leadPopupApiCall, dispatch]
  );

  const getLatestData = () => {
    const userFromLocalStorage = authUser?.institute[0];

    if (page === "CONTACT") {
      const data = {
        id: userFromLocalStorage?.instituteId,
        lastEvaluatedKey: "",
        limit: 15,
        lastEvaluatedSortKey: "",
        enquirySource: "Excel Import",
      };
      dispatch<any>(getContactList(data));
    } else if (page === "ZAPIER") {
      const data = {
        id: userFromLocalStorage?.instituteId,
        lastEvaluatedKey: "",
        limit: 15,
        lastEvaluatedSortKey: "",
        enquirySource: "Zapier",
      };
      dispatch<any>(getContactList(data));
    } else if (page === "WEBFORM") {
      const data: any = {
        instituteId: userFromLocalStorage?.instituteId,
        formId: formId,
        branchId: "",
        limit: 15,
        lastEvaluatedKey: "",
        lastEvaluatedSortKey: "",
      };
      dispatch<any>(getWebformResponse(data));
    } else if (page === "EMAIL") {
      const data = {
        id: userFromLocalStorage?.instituteId,
        lastEvaluatedKey: "",
        limit: 15,
        lastEvaluatedSortKey: "",
      };
      dispatch<any>(getMailEntriesByInstitute(data));
    }
  };
  const handleViewLeadCard = () => {
    if (leadCreatedId) {
      openLeadExpandModal(
        leadCreatedId?.instituteId,
        leadCreatedId?.studentContact,
        leadCreatedId?.studentEmail,
        leadCreatedId?.id,
        false
      );
    }
    getLatestData();
    setShowStudentLeadCard(false);
    setStage("");
    onHide();
    reset();
    dispatch(resetLeadCreateSuccess());
    dispatch(resetAddEnquirySuccess());
    dispatch(resetAddLeadPaymentSuccess());
    setAddLeadWideView(false);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const isLeadModalOpened = queryParams.get("leadModal");

  useEffect(() => {
    if (isLeadModalOpened === "true") {
      dispatch<any>(updateNoPaymentCollected({ id: leadCreatedId?.id }));
      setShowStudentLeadCard(false);
      setStage("");
      onHide();
      reset();
      dispatch(resetLeadCreateSuccess());
      dispatch(resetAddEnquirySuccess());
      dispatch(resetAddLeadPaymentSuccess());
      setAddLeadWideView(false);
    }
  }, [isLeadModalOpened]);

  React.useEffect(() => {
    if (activeLeadForm?.[0]?.additionalFields?.length > 0) {
      const updatedFields = activeLeadForm?.[0]?.additionalFields?.map(
        (item: any, index: number) => ({
          fieldType: item?.field?.fieldType,
          fieldName: item?.field?.fieldName,
          fieldValue: "",
          options: item?.field?.options,
          required: item?.field?.required,
          index: index + 1,
          id: item?.id,
          isValid: true,
          countryCode: "",
        })
      );

      setAdditionalFields(updatedFields);
    }
  }, [activeLeadForm, show]);

  const handleFieldChange = (updatedFields: any[]) => {
    setAdditionalFields(updatedFields);
  };
  console.log(
    "instituteFromRedux?.addLeadFormEnabled",
    instituteFromRedux?.addLeadFormEnabled,
    activeLeadForm?.[0]?.additionalFields
  );
  const wholeContent = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isCompress
            ? showAddLeadWideView &&
              (page === "CONTACT" ||
                page === "ZAPIER" ||
                page === "WEBFORM" ||
                page === "EMAIL")
              ? "92vw"
              : showAddLeadWideView && page === "NURTURE"
              ? "92vw"
              : "100%"
            : showAddLeadWideView &&
              (page === "CONTACT" ||
                page === "ZAPIER" ||
                page === "WEBFORM" ||
                page === "EMAIL")
            ? "85vw"
            : showAddLeadWideView && page === "NURTURE"
            ? "85vw"
            : "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
          }}
        >
          <div
            style={{
              width: showAddLeadWideView ? "435px" : "100%",
              height: "100vh",
            }}
          >
            <div>
              <div
                className={styles.addLeadMainHeading}
                style={{
                  padding:
                    isEmailDetail && !isFromWebform
                      ? "12px 16px"
                      : isEmailDetail && isFromWebform
                      ? "18px 32px 12px 32px"
                      : "12px 32px",
                }}
              >
                Add Lead
              </div>
            </div>
            <Box
              sx={{
                width: isEmailDetail ? "100%" : "430px",
                padding: isEmailDetail ? "20px 2px" : "22px 2px",
              }}
            >
              <div className={styles.form}>
                <Box
                  sx={{
                    height: !showAddLeadWideView
                      ? isSourceWidth
                        ? "calc(100vh - 245px)"
                        : isFromContacts
                        ? "calc(100vh - 200px)"
                        : isFromWebform
                        ? "calc(100vh - 245px)"
                        : "calc(100vh - 218px)"
                      : "100vh",
                    maxHeight: !showAddLeadWideView
                      ? isSourceWidth
                        ? "calc(100vh - 245px)"
                        : isFromContacts
                        ? "calc(100vh - 202px)"
                        : isFromWebform
                        ? "calc(100vh - 245px)"
                        : "calc(100vh - 218px)"
                      : "78vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: isEmailDetail ? "0px 18px" : "0px 30px",
                  }}
                >
                  {leadCreatedId && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.subTitle}>Student Details</div>
                      <EditIconBtn
                        onClick={() => {
                          setStage("");
                          onHide();
                          reset();
                          dispatch(resetLeadCreateSuccess());
                          dispatch(resetAddEnquirySuccess());
                          dispatch(resetAddLeadPaymentSuccess());
                          setAddLeadWideView(false);
                          dispatch<any>(
                            updateNoPaymentCollected({ id: leadCreatedId?.id })
                          );
                          openLeadExpandModal(
                            leadCreatedId?.instituteId,
                            leadCreatedId?.studentContact,
                            leadCreatedId?.studentEmail,
                            leadCreatedId?.id,
                            true
                          );
                        }}
                      />
                    </div>
                  )}
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "11px",
                        marginBottom: "14px",
                      }}
                      className={styles.addFieldSplitWrap}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            color: "#142C44",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            display: "flex",
                          }}
                        >
                          First name
                          <span style={{ margin: "0px 4px", color: "#D32F2F" }}>
                            *
                          </span>
                        </InputLabel>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusFirstName}
                          type={"text"}
                          id="firstName"
                          value={firstName}
                          onChange={(e: any) => {
                            const pattern =
                              /^(?:[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*)?$/;
                            if (pattern.test(e?.target?.value)) {
                              setFirstName(e?.target?.value);
                            }
                          }}
                          error={!validStateFirstName}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          autoComplete="true"
                          inputProps={{
                            maxLength: 50,
                          }}
                          disabled={leadCreatedId ? true : false}
                        />
                      </div>

                      <div>
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            color: "#142C44",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            display: "flex",
                          }}
                        >
                          Last name
                        </InputLabel>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusLastName}
                          type={"text"}
                          id="lastName"
                          value={lastName}
                          onChange={(e: any) => {
                            const pattern =
                              /^(?:[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*)?$/;
                            if (pattern.test(e?.target?.value)) {
                              setLastName(e?.target?.value);
                            }
                          }}
                          error={lastName !== "" ? !validStateLastName : false}
                          showErrorMessage={
                            lastName !== "" ? showErrMsz : false
                          }
                          errorType={errorType}
                          autoComplete="true"
                          inputProps={{
                            maxLength: 50,
                          }}
                          disabled={leadCreatedId ? true : false}
                        />
                      </div>
                    </Box>

                    <Box sx={{ marginBottom: "14px" }}>
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "#142C44",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >
                        Email Address
                      </InputLabel>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusEmail}
                        type={"email"}
                        id="instituteEmail"
                        value={email}
                        onChange={(e: any) => {
                          setShowErrMsz(true);
                          setEmail(e?.target?.value.toLowerCase());
                          setValidStateContact(true);
                        }}
                        error={!validStateEmail}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={false}
                        inputProps={{
                          maxLength: 50,
                        }}
                        disabled={leadCreatedId ? true : false}
                      />
                    </Box>

                    <Box sx={{ marginBottom: "30px" }}>
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontFamily: "Poppins",
                          display: "flex",
                        }}
                      >
                        Phone Number
                      </InputLabel>
                      <CountryCodePhoneInput
                        value={phone}
                        onChange={(event: any, country: any) => {
                          setPhone(event);
                          setContactCountryCode(country.dialCode);
                          if (event === country.dialCode || event === "") {
                            setValidStateContact(true);
                          } else {
                            setValidStateContact(
                              isValidPhoneNumber(
                                `+${country.dialCode}${event.replace(
                                  country.dialCode,
                                  ""
                                )}`,
                                country.countryCode.toUpperCase()
                              )
                            );
                            setValidStateEmail(true);
                          }
                        }}
                        error={validStateContact}
                        disabled={leadCreatedId ? true : false}
                      />
                    </Box>
                  </Box>

                  <div className={styles.subTitle}>Course Details</div>

                  {/* Course */}
                  <Box sx={{ marginBottom: "12px" }}>
                    <AutoCompleteField
                      id="courseId"
                      label="Course"
                      options={courseList?.filter(
                        (item: any) => item.status === "ACTIVE"
                      )}
                      optionLabel={"courseName"}
                      onChange={onCourseChange}
                      value={selectedCourse}
                      disableClearable={selectedCourse !== "" ? false : true}
                      required={true}
                      validState={validStateCourse}
                      disabled={leadCreatedId ? true : false}
                    />
                    {selectedCourse !== "" && (
                      <div className={styles.blueBtnWrap}>
                        <ViewCourseDetailsPopup
                          addLead={true}
                          leadSelectedCourse={selectedCourse}
                          isFromContacts={isFromContacts}
                        />
                      </div>
                    )}
                  </Box>

                  {/* Branch */}
                  {selectedCourse !== "" && (
                    <AutoCompleteField
                      id="branchId"
                      label="Branch"
                      options={filteredBranchList}
                      optionLabel={"branchName"}
                      onChange={onBranchChange}
                      value={selectedBranch}
                      disableClearable={selectedBranch !== "" ? false : true}
                      required={true}
                      validState={validStateBranch}
                      disabled={leadCreatedId ? true : false}
                    />
                  )}
                  {selectedCourse !== "" && selectedBranch !== "" && (
                    <>
                      <Box sx={{ marginBottom: "12px", marginTop: "12px" }}>
                        <AutoCompleteField
                          id="batchId"
                          label="Batch"
                          options={filteredBatches}
                          optionLabel={"batchName"}
                          onChange={onBatchChange}
                          value={selectedBatch}
                          disableClearable={selectedBatch !== "" ? false : true}
                          required={true}
                          validState={validStateBatchName}
                          disabled={leadCreatedId ? true : false}
                        />
                        {selectedBatch !== "" && (
                          <LeadBatchPopup
                            selectedBatch={selectedBatch}
                            showAddLeadWideView={showAddLeadWideView}
                            selectedCurrency={
                              selectedBranch?.currency || selectedCurrency
                            }
                          />
                        )}
                      </Box>
                    </>
                  )}

                  <div className={styles.subTitle}>Choose stage</div>

                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ marginBottom: "12px" }}
                  >
                    <Select
                      onChange={onStageChange}
                      value={stage}
                      sx={{
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "5px",
                        },
                      }}
                      disabled={leadCreatedId ? true : false}
                    >
                      {leadStages &&
                        leadStages?.map((stage: any) => (
                          <MenuItem
                            key={stage.id}
                            value={stage}
                            sx={{
                              color: "#667085",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {stage.displayStateName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {instituteFromRedux?.addLeadFormEnabled &&
                    additionalFields?.length > 0 && (
                      <AdditionalFields
                        fields={additionalFields}
                        onFieldChange={handleFieldChange}
                      />
                    )}
                </Box>
              </div>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: showAddLeadWideView ? "auto" : 0,
                // width: "100%",
                width: "430px",

                backgroundColor: "#FFF",
                px: isSourceWidth || isFromContacts ? 0 : 0,
                marginTop: "auto",
              }}
            >
              <Stack
                sx={{
                  backgroundColor: "#fff",
                  padding: "15px 0px 13px 0px",
                  borderTop: "1PX SOLID #F1F1F1",
                  margin: isEmailDetail ? " 0 23PX" : "0 34PX",
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      width: 245,
                    }}
                  >
                    <SourceManager
                      sourceList={sourceList}
                      onSourceChange={onSourceChange}
                      source={source}
                      page={page}
                      addLead={true}
                      disabled={leadCreatedId ? true : false}
                      validSource={isValidSource}
                    />
                  </div>

                  <div className={styles.kanbanAssign}>
                    <UserAssign
                      users={employees && combinedAssignees}
                      isForEnquiryModal={true}
                      handleLeadAssignee={handleLeadAssignee}
                      selectedAssignyId={assigny?.id}
                      size="xl"
                      disabled={leadCreatedId ? true : false}
                    />
                    <FlagAssign
                      isForEnquiryModal={true}
                      handleLeadPriority={handleLeadPriority}
                      selectedPriority={priority}
                      size="xl"
                      disabled={leadCreatedId ? true : false}
                    />
                  </div>
                </Box>
              </Stack>

              {(leadCreatedId === null || leadCreatedId === undefined) && (
                <Box
                  sx={{
                    padding: isEmailDetail ? "13px 20px" : "13px 32px",
                    // boxShadow: isFromWebform
                    //   ? "none"
                    //   : "10px 4px 14px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      "&.Mui-disabled": {
                        backgroundColor: "#11C89B ",
                        color: "#fff",
                      },
                    }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={styles.instituteContact_save_btn}
                    onClick={addenquiry}
                    disabled={leadCreateLoading}
                  >
                    {leadCreateLoading ? (
                      <CircularProgress size={25} color={"inherit"} />
                    ) : (
                      <>
                        {stage?.stateName === "CONVERTED"
                          ? "Save and collect payment"
                          : "Save"}
                      </>
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          </div>
        </Box>
        {showAddLeadWideView && (
          <div
            style={{
              width: "67%",
              borderLeft: "1px solid #F4F4F4",
            }}
          >
            <div
              style={{
                padding: "20px 20px",
                color: "#142C44",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Payment Details
            </div>
            <div
              style={{
                height: "90vh",
                overflowY: "auto",
              }}
            >
              <Payment
                paymentList={filteredPaymentList}
                leadById={leadById}
                RecordPayment={RecordPayment}
                leadConvertionError={leadConvertionError}
                leadStatusUpdateError={leadStatusUpdateError}
                UpdateLead={UpdateLead}
                overviewTab={false}
                UpdateLeadEmiDetails={UpdateLeadEmiDetails}
                leadEmiDetailsLoading={leadEmiDetailsLoading}
                leadEmiData={leadEmiData}
                GetEmiDataOfLead={GetEmiDataOfLead}
                SendPaymentLink={SendPaymentLink}
                DownloadPaymentReceipt={DownloadPaymentReceipt}
                EditRecordPayment={EditRecordPayment}
                GetLeadPaymentList={GetLeadPaymentList}
                selectedCurrency={selectedCurrency}
              />
            </div>
          </div>
        )}
        {(leadCreatedId !== null || leadCreatedId !== undefined) && (
          <ViewLeadCardPopUp
            open={showStudentLeadCard}
            onClose={() => {
              getLatestData();
              setShowStudentLeadCard(false);
              setStage("");
              onHide();
              reset();
              dispatch(resetLeadCreateSuccess());
              dispatch(resetAddEnquirySuccess());
              dispatch(resetAddLeadPaymentSuccess());
              setAddLeadWideView(false);
            }}
            yesBtnClick={handleViewLeadCard}
            title={`${leadCreatedId?.studentFirstName} is converted to Student`}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {!isEmailDetail ? (
        <Drawer
          anchor={"right"}
          open={show}
          onClose={() => {
            setStage("");
            onHide();
            reset();
          }}
          sx={{
            height: "100vh",
            zIndex: 99999,
            position: "relative",
            "& .MuiDrawer-paper": {
              overflowY: "unset",
            },
          }}
        >
          <button
            className={styles.filterCloeBtn}
            onClick={() => {
              setStage("");
              onHide();
              reset();
              dispatch(resetLeadCreateSuccess());
              dispatch(resetAddEnquirySuccess());
              dispatch(resetAddLeadPaymentSuccess());
              setAddLeadWideView(false);
              if (leadCreatedId) {
                dispatch<any>(
                  updateNoPaymentCollected({ id: leadCreatedId?.id })
                );
              }
              getLatestData();
            }}
            style={{
              right: isCompress
                ? showAddLeadWideView && page === "NURTURE"
                  ? "1400px"
                  : showAddLeadWideView &&
                    (page === "CONTACT" ||
                      page === "ZAPIER" ||
                      page === "WEBFORM" ||
                      page === "EMAIL")
                  ? "1400px"
                  : "415px"
                : showAddLeadWideView && page === "NURTURE"
                ? "1295px"
                : showAddLeadWideView &&
                  (page === "CONTACT" ||
                    page === "ZAPIER" ||
                    page === "WEBFORM" ||
                    page === "EMAIL")
                ? "1295px"
                : "415px",
            }}
          >
            x
          </button>
          {wholeContent()}
        </Drawer>
      ) : (
        <>{wholeContent()}</>
      )}
    </>
  );
};

export default AddLeadPayment;
