import React, { useCallback, useState, useEffect } from "react";
import styles from "./ActionableNotification.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  resetTaskReminders,
  clearTaskReminders,
} from "../../service/websocketNotificationsSlice";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import getTeamMember, {
  getDescriptionWithMentionedNames,
} from "../CommonFunctions/CommonFunctions";
import {
  getLeadListByInstituteAndContact,
  resetLeadById,
  resetLeadLEmiData,
  resetLeadListByContact,
  resetLeadModalState,
  resetLeadPaymentData,
  resetLeadPopupTabDatas,
  setLeadModalState,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { checkSubDomainAccess } from "../RolePermissionAccess/RolePermissionAccess";
import { setToastNotification } from "./service/toastNotificationSlice";

function TaskReminder(props: any) {
  const { courses, setIsContentMinimized, paymentReminders, active, onClick } =
    props;
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );

  const [expandedAccordion, setExpandedAccordion] = useState(true);

  const { taskReminders } = useSelector(
    (state: any) => state.websocketNotifications
  );
  function getCourseName(courseId: string) {
    // Search for the course with the given courseId
    const course = courses?.find((course: any) => course.id === courseId);

    // If the course is found, return its name, otherwise return null or an appropriate default value
    return course ? course?.courseName : "-";
  }

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const ClearLeadPopupQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("leadModal");
    queryParams.delete("leadId");
    queryParams.delete("tabOpen");
    queryParams.delete("recordPayment");
    queryParams.delete("leadInstituteId");
    queryParams.delete("leadEmail");
    queryParams.delete("leadPhone");
    queryParams.delete("leadTaskId");
    queryParams.delete("leadNoteId");
    queryParams.delete("leadPaymentId");
    queryParams.delete("taskTabOpen");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetLeadListByContact());
    dispatch(resetLeadPopupTabDatas());
    dispatch(resetLeadLEmiData());
    dispatch(resetLeadPaymentData());
    dispatch(resetLeadById());
    dispatch(resetLeadModalState());
  };

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      taskId: string,
      taskTab: string
    ) => {
      ClearLeadPopupQuery();

      const queryParams = new URLSearchParams();
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "task");
      queryParams.set("taskTabOpen", taskTab);

      queryParams.set("leadTaskId", taskId);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
      const newTaskReminders = taskReminders?.filter(
        (item: any) => item.id !== taskId
      );
      dispatch(resetTaskReminders(newTaskReminders));
      setIsContentMinimized(true);
      setExpandedAccordion(false);
    },
    [taskReminders, ClearLeadPopupQuery]
  );

  const openDemoTaskPage = useCallback(
    (taskId: string) => {
      const newTaskReminders = taskReminders?.filter(
        (item: any) => item.id !== taskId
      );
      dispatch(resetTaskReminders(newTaskReminders));
      setExpandedAccordion(false);
      const newUrl = `/app/Tasks/DemoTaskDetails/${taskId}`;
      window.open(newUrl, "_blank");
    },
    [taskReminders]
  );

  // const { leadBoard } = useSelector((state: any) => state.nurture);
  // const employees = leadBoard?.employees;
  const employees = useSelector((state: any) => state.staff.staffList);

  const clearTaskReminder = (taskId: string) => {
    const newTaskReminders = taskReminders?.filter(
      (item: any) => item.id !== taskId
    );
    dispatch(resetTaskReminders(newTaskReminders));
  };

  useEffect(() => {
    if (taskReminders.length === 0 && active) {
      dispatch(resetTaskReminders([]));
    }
  }, [taskReminders, active, dispatch]);

  return (
    <div style={{ maxHeight: "calc(100% - 68.8px) ", overflowY: "auto" }}>
      <Accordion
        sx={{
          color: "#142C44",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          borderBottom: "1px solid #e9e9e9 !important",

          "&.Mui-expanded": {
            marginBottom: "0",
            marginTop: "0",
          },
          "&:before": {
            display: "none",
          },
        }}
        expanded={active}
        onChange={onClick}
      >
        <AccordionSummary
          sx={{
            color: "#142C44",
            backgroundColor: "transparent",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "22px",
            flexDirection: "row-reverse",
            gap: "14px",
            "& .MuiAccordionSummary-content ": {
              margin: "0 !important",
            },
            "&.Mui-expanded": {
              minHeight: 42,
            },
          }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Tasks (Due in 15 min)
        </AccordionSummary>
        {taskReminders && taskReminders.length > 0 && (
          <AccordionDetails
            sx={{
              overflowY: "auto",
              paddingBottom: "295px",
              maxHeight: "325px",
            }}
          >
            {taskReminders?.map((notification: any, index: number) => (
              <div
                key={index}
                style={{
                  borderRadius: "6px",
                  border: "1px solid #EAECF0",
                  backgroundColor: "#F5F7FB",
                  marginBottom: "9px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 16px 6px",
                  }}
                >
                  <div>
                    <div className={styles.studentName}>
                      {notification?.studentName}{" "}
                    </div>
                    <div className={styles.courseName}>
                      {getCourseName(notification?.enquiryData?.courseId)}
                    </div>
                  </div>
                  <Box>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        color: "#2664DC",
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        "&:hover": {
                          textDecoration: "underline",
                          background: "none",
                        },
                      }}
                      color="primary"
                      onClick={() =>
                        notification?.taskType === "DEMO"
                          ? openDemoTaskPage(notification?.id)
                          : hasLeadCardPermission
                          ? openLeadExpandModal(
                              notification?.instituteId,
                              notification?.studentContact,
                              notification?.studentEmail,
                              notification?.enquiryId,
                              notification?.id,
                              "INCOMPLETE"
                            )
                          : dispatch(
                              setToastNotification({
                                message: String(
                                  "You do not have permission to access the lead card"
                                ),
                                type: "error",
                                snackOpen: true,
                              })
                            )
                      }
                    >
                      View
                    </Button>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        color: "#667085",
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        "&:hover": {
                          textDecoration: "underline",
                          background: "none",
                        },
                      }}
                      color="primary"
                      onClick={() => clearTaskReminder(notification?.id)}
                    >
                      Clear
                    </Button>
                  </Box>
                </div>

                <div style={{ backgroundColor: "#FFF", padding: "16px" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "rgb(78, 85, 99)",
                      marginBottom: "8px",
                    }}
                    className={styles.reminderDesscription}
                    dangerouslySetInnerHTML={{
                      __html: getDescriptionWithMentionedNames(
                        notification?.description,
                        employees
                      ),
                    }}
                  ></div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div className={styles.overdueTaskAssignee}>
                      {getTeamMember(notification?.assignedToId, employees)
                        ?.firstName?.charAt(0)
                        ?.toUpperCase()}
                      {getTeamMember(notification?.assignedToId, employees)
                        ?.lastName?.charAt(0)
                        ?.toUpperCase()}
                    </div>
                    <div className={styles.taskType}>
                      {notification?.taskType?.toLowerCase()}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px",
                      }}
                    >
                      <div
                        className={styles.taskStatus}
                        style={{
                          color:
                            notification?.status === "TO_DO"
                              ? "#667085"
                              : notification?.status === "IN_PROGRESS"
                              ? "#2664DC"
                              : notification?.status === "BLOCKED"
                              ? "#EC3939"
                              : notification?.status === "COMPLETED"
                              ? "#23A67B"
                              : "#2664DC",
                          background:
                            notification?.status === "TO_DO"
                              ? "rgba(102, 112, 133, 0.10)"
                              : notification?.status === "IN_PROGRESS"
                              ? "rgba(38, 100, 220, 0.07)"
                              : notification?.status === "BLOCKED"
                              ? "rgba(236, 57, 57, 0.07)"
                              : notification?.status === "COMPLETED"
                              ? "rgba(35, 166, 123, 0.06)"
                              : "rgba(35, 166, 123, 0.06)",
                        }}
                      >
                        {notification?.status?.toLowerCase().replace(/_/g, " ")}
                      </div>
                    </div>
                    <div className={styles.taskDate}>
                      <AccessTimeIcon
                        style={{
                          color: "#667085",
                          fontSize: "medium",
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                      {moment(notification?.date).format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}

export default TaskReminder;
