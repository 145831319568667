import React, { useEffect, useState } from "react";
import SessionBoxes from "../../pages/app/Nurture/components/PaymentType/Session/SessionBoxes";
import ScheduleSessions from "../../pages/app/Nurture/components/PaymentType/Session/ScheduleSessions";
import SessionsTab from "../../pages/app/Nurture/components/PaymentType/Session/SessionsTab";
import styles from "..//../pages/app/Nurture/components/PaymentType/Session/LeadSession.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import CancelPopup from "../CommonPopups/CancelPopup";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import {
  editBookedSession,
  getBookedSessions,
  resetLeadSession,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { Tooltip } from "@mui/material";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";
import AttendanceCofirmPopup from "../../pages/app/Nurture/components/PaymentType/Session/AttendanceCofirmPopup";

function LeadSessionTab(props: any) {
  const { leadEmiData, leadData, selectedCurrency } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const {
    batchWithCalendar,
    sessionBookSuccess,
    bookedSessions,
    sessionBookLoading,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
  } = useSelector((state: any) => state.nurture);
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const allSessions = [
    ...bookedSessions?.completedSessionList,
    ...bookedSessions?.overdueSessionList,
    ...bookedSessions?.upcomingSessionList,
  ];

  const [schedule, setSchedule] = useState<boolean>(false);
  const [editSchedule, setEditSchedule] = useState<boolean>(false);
  const [editScheduleData, setEditScheduleData] = useState<any | null>(null);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const [attendancePopup, setAttendancePopup] = useState<boolean>(false);
  const [addMore, setAddMore] = useState<boolean>(false);

  const [commentDetails, setCommentDetails] = React.useState({
    description: "",
    mentionedIds: [],
  });

  const cancelBookedSession = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        id: editScheduleData?.id,
        enquiryId: editScheduleData?.enquiryId,
        instituteId: editScheduleData?.instituteId,
        batchId: editScheduleData?.batchId,
        sessionName: editScheduleData?.sessionName,
        sessionDate: editScheduleData?.sessionDate,
        startTime: editScheduleData?.startTime,
        endTime: editScheduleData?.endTime,
        isScheduled: false,
        createdById: editScheduleData?.createdById,
        createdByName: editScheduleData?.createdByName,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        sessionStatus: editScheduleData?.sessionStatus,
      };

      dispatch<any>(editBookedSession(body));
    } catch (error) {}
  };

  const cancelNoBtn = () => {
    setEditScheduleData(null);
  };

  const cancelAttendance = () => {
    setEditScheduleData(null);
    setAttendancePopup(false);
    setCommentDetails({
      ...commentDetails,
      description: "",
      mentionedIds: [],
    });
  };

  const markAttendance = async (sessionData: any) => {
    try {
      // const body = {
      //   id: sessionData?.id,
      //   enquiryId: sessionData?.enquiryId,
      //   instituteId: sessionData?.instituteId,
      //   batchId: sessionData?.batchId,
      //   sessionName: sessionData?.sessionName,
      //   sessionDate: sessionData?.sessionDate,
      //   startTime: sessionData?.startTime,
      //   endTime: sessionData?.endTime,
      //   isScheduled: sessionData?.isScheduled,
      //   createdById: sessionData?.createdById,
      //   createdByName: sessionData?.createdByName,
      //   updatedById: authUser?.institute[0]?.id,
      //   updatedByName: `${authUser?.firstName} ${authUser?.lastName}`,
      //   sessionStatus: "COMPLETED",
      // };
      const body = {
        id: editScheduleData?.id,
        enquiryId: editScheduleData?.enquiryId,
        instituteId: editScheduleData?.instituteId,
        batchId: editScheduleData?.batchId,
        sessionName: editScheduleData?.sessionName,
        sessionDate: editScheduleData?.sessionDate,
        startTime: editScheduleData?.startTime,
        endTime: editScheduleData?.endTime,
        isScheduled: editScheduleData?.isScheduled,
        createdById: editScheduleData?.createdById,
        createdByName: editScheduleData?.createdByName,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: `${authUser?.firstName} ${authUser?.lastName}`,
        sessionStatus: "COMPLETED",
        description:
          commentDetails?.description !== "" ? commentDetails?.description : "",
        mentionedIds:
          commentDetails?.mentionedIds?.length > 0
            ? commentDetails?.mentionedIds
            : [],
      };

      await dispatch<any>(editBookedSession(body));
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  useEffect(() => {
    if (sessionBookSuccess !== true && sessionBookSuccess !== false) {
      setSchedule(false);
      setEditSchedule(false);
      setEditScheduleData(null);
      setCancelPopup(false);
      setAttendancePopup(false);
      setCommentDetails({
        ...commentDetails,
        description: "",
        mentionedIds: [],
      });
      dispatch(resetLeadSession());
    }
  }, [sessionBookSuccess]);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);

  //   const addSession = queryParams.get("sessionAddMore");

  //   if (addSession) {
  //     setSchedule(true);
  //     setAddMore(false);
  //     queryParams.delete("sessionAddMore");
  //     const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  //     window.history.pushState({}, "", newUrl);
  //   } else {
  //     setSchedule(false);
  //   }
  // }, []);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const modalTabOpen = urlSearchParams.get("tabOpen");
    if (
      (sessionPayLinkSuccess !== true &&
        sessionPayLinkSuccess !== false &&
        modalTabOpen === "session") ||
      (sessionPayRecordSuccess !== true &&
        sessionPayRecordSuccess !== false &&
        modalTabOpen === "session")
    ) {
      if (leadData) dispatch<any>(getBookedSessions(leadData?.id));
      dispatch(resetLeadSession());
    }
  }, [sessionPayLinkSuccess, sessionPayRecordSuccess, leadData]);

  return (
    <div
      style={{
        overflowY: "auto",
        background: "#fff",
        maxHeight: "calc(98vh - 200px)",
        padding: "26px",
        borderRadius: "3px",
      }}
    >
      <SessionBoxes
        leadSessionInfo={bookedSessions?.userSessionData}
        leadData={leadData}
        hasLeadAccess={hasLeadAccess}
        selectedCurrency={selectedCurrency}
      />
      <div className={styles.sessionBoxHeadSplit} style={{ marginTop: "23PX" }}>
        <div className={styles.leadPayHeadTitle}>Schedule sessions</div>
        <div>
          {(bookedSessions?.upcomingSessionList?.length > 0 ||
            bookedSessions?.completedSessionList?.length > 0 ||
            bookedSessions?.overdueSessionList?.length > 0) &&
            hasLeadAccess &&
            !schedule &&
            !editSchedule && (
              <Tooltip
                arrow
                title={
                  bookedSessions?.userSessionData?.balanceSession === 0
                    ? "Please add session"
                    : ""
                }
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <button
                    className={styles.addMoreSessionBtn}
                    onClick={() => setSchedule(true)}
                    disabled={
                      bookedSessions?.userSessionData?.balanceSession === 0
                    }
                  >
                    <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />{" "}
                    Schedule Session
                  </button>
                </span>
              </Tooltip>
            )}
        </div>
      </div>

      {!schedule && !editSchedule ? (
        <SessionsTab
          bookedSessions={bookedSessions}
          setEditSchedule={setEditSchedule}
          setEditScheduleData={setEditScheduleData}
          setCancelPopup={setCancelPopup}
          markAttendance={markAttendance}
          setSchedule={setSchedule}
          hasLeadAccess={hasLeadAccess}
          schedule={schedule}
          editSchedule={editSchedule}
          setAttendancePopup={setAttendancePopup}
        />
      ) : (
        <ScheduleSessions
          setSchedule={setSchedule}
          batchCalendar={batchWithCalendar?.batchCalendar}
          customDays={batchWithCalendar?.customDays}
          leadData={leadData}
          editSchedule={editSchedule}
          editScheduleData={editScheduleData}
          setEditSchedule={setEditSchedule}
          allSessions={allSessions}
          setAddMore={setAddMore}
          addMore={addMore}
          bookedSessions={bookedSessions}
          sessionBookSuccess={sessionBookSuccess}
        />
      )}
      {cancelPopup && (
        <CancelPopup
          open={cancelPopup}
          onClose={() => setCancelPopup(false)}
          noBtnClick={cancelNoBtn}
          yesBtnClick={cancelBookedSession}
          loader={sessionBookLoading}
        />
      )}
      {attendancePopup && (
        <AttendanceCofirmPopup
          open={attendancePopup}
          onClose={() => {
            setAttendancePopup(false);
            setCommentDetails({
              ...commentDetails,
              description: "",
              mentionedIds: [],
            });
          }}
          noBtnClick={cancelAttendance}
          yesBtnClick={markAttendance}
          loader={sessionBookLoading}
          commentDetails={commentDetails}
          setCommentDetails={setCommentDetails}
          leadData={leadData}
          editScheduleDat={editScheduleData}
        />
      )}
    </div>
  );
}

export default LeadSessionTab;
