import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { AttendanceReportCreationInitialStateDTO } from "../service/types";
import ReportTable from "../components/RecentReport/ReportTable";
import {
  getDefaultReportData,
  getDownloadedReport,
  saveReport,
} from "../service/reportCreationSlice";
import AttendanceReportCreation from "../components/ReportCreation/AttendanceReportCreation";
import {
  getAttendanceReportDataForSingleBatch,
  getBatchListForAttendance,
} from "../service/attendanceReportCreationSlice";

interface StateProps extends AttendanceReportCreationInitialStateDTO {}
interface DispatchProps {
  GetBatchList(body: any): void;
  GetReportForSingleBatch(body: any): void;
  DownloadReport(body: any): void;
  SaveReport(body: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    batchListForAttendanceLoading:
      state.attendanceReportCreation.batchListForAttendanceLoading,
    batchListForAttendanceSuccess:
      state.attendanceReportCreation.batchListForAttendanceSuccess,
    batchListForAttendanceError:
      state.attendanceReportCreation.batchListForAttendanceError,
    batchListForAttendance:
      state.attendanceReportCreation.batchListForAttendance,
    getAttendanceReportDataForSingleBatchLoading:
      state.attendanceReportCreation
        .getAttendanceReportDataForSingleBatchLoading,
    getAttendanceReportDataForSingleBatchSuccess:
      state.attendanceReportCreation
        .getAttendanceReportDataForSingleBatchSuccess,
    getAttendanceReportDataForSingleBatchError:
      state.attendanceReportCreation.getAttendanceReportDataForSingleBatchError,
    getAttendanceReportDataForSingleBatch:
      state.attendanceReportCreation.getAttendanceReportDataForSingleBatch,
    saveAttendanceReportLoading:
      state.attendanceReportCreation.saveAttendanceReportLoading,
    saveAttendanceReportSuccess:
      state.attendanceReportCreation.saveAttendanceReportSuccess,
    saveAttendanceReportError:
      state.attendanceReportCreation.saveAttendanceReportError,
    downloadAttendanceReportLoading:
      state.attendanceReportCreation.downloadAttendanceReportLoading,
    downloadAttendanceReportSuccess:
      state.attendanceReportCreation.downloadAttendanceReportSuccess,
    downloadAttendanceReportError:
      state.attendanceReportCreation.downloadAttendanceReportError,
    downloadAttendanceReportData:
      state.attendanceReportCreation.downloadAttendanceReportData,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetBatchList: (body: any) => {
      dispatch(getBatchListForAttendance(body));
    },
    GetReportForSingleBatch: (id: string) => {
      dispatch(getAttendanceReportDataForSingleBatch(id));
    },
    DownloadReport: (body: any) => {
      dispatch(getDownloadedReport(body));
    },
    SaveReport: (id: string) => {
      dispatch(saveReport(id));
    },
  };
};

export interface AttendanceReportCreationProps
  extends StateProps,
    DispatchProps {}
export const AttendanceReportCreationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceReportCreation);
