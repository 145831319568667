import React, { useState } from "react";
import {
  Box,
  Switch,
  SwitchProps,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  CircularProgress,
  Skeleton,
  Tooltip,
} from "@mui/material";
import styles from "./TaxInvoices.module.css";
import { styled } from "@mui/material/styles";
import InvoiceDetails from "./InvoiceDetails";
import TaxInvoiceReceipt from "./TaxInvoiceReceipt";
import DisablePopup from "./DisablePopup/DisablePopup";
import DoneIcon from "@mui/icons-material/Done";
import { getInstituteById } from "../../../Manage/InstituteProfile/service/instituteSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { resetSettingsStatuses } from "../../service/settingsSlice";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import { getBillingAddress } from "../../../Manage/Billing/service/Billingslice";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function TaxInvoices(props: any) {
  const {
    GetStudentInvoiceSettings,
    CreateStudentInvoiceSettings,
    UpdateStudentInvoiceSettings,
    invoiceSettingLoading,
    invoiceSettingCreateLoading,
    invoiceSettings,
    invoiceSettingUpdateSuccess,
    invoiceSettingUpdateLoading,
    invoiceSettingCreateSuccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasTaxInvoiceAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "INVOICES",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [isEnabled, setIsEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [gstin, setGstin] = useState("");
  const [prefix, setPrefix] = useState("");
  const [nextNumber, setNextNumber] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  const handleSwitchToggle = (e: any) => {
    const value = e.target.checked;
    setIsEnabled(!isEnabled);
    if (invoiceSettings?.isStudentTaxInvoiceEnabled) {
      setShowDisablePopup(true);
    }
    if (
      invoiceSettings?.prefix !== "" &&
      !invoiceSettings?.isStudentTaxInvoiceEnabled &&
      value === true &&
      Object?.keys(invoiceSettings)?.length > 0
    ) {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: invoiceSettings?.nextNumber,
        prefix: invoiceSettings?.prefix,
        gstNumber: invoiceSettings?.gstNumber,
        isStudentTaxInvoiceEnabled: true,
      };
      UpdateStudentInvoiceSettings(body);
    }
  };

  const handleNoSwitchToggle = () => {
    setIsEnabled(invoiceSettings?.isStudentTaxInvoiceEnabled);
    setShowDisablePopup(false);
  };

  const handleYesSwitchToggle = () => {
    if (invoiceSettings?.isStudentTaxInvoiceEnabled) {
      setShowDisablePopup(false);
    }
  };

  const gstNumberRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  const PREFIX_REGEX = /^[a-zA-Z0-9_]+$/;
  const NUM_REGEX = /^[0-9]*$/;

  const [gstError, setGstError] = React.useState<boolean>(false);
  const [prefixError, setPrefixError] = React.useState<boolean>(false);
  const [numberError, setNumberError] = React.useState<boolean>(false);

  const handleChange = React.useCallback((event: any) => {
    setGstin(event.target.value);
    if (!gstNumberRegex.test(event.target.value)) {
      setGstError(true);
    } else {
      setGstError(false);
    }
  }, []);

  const handleNextClick = () => {
    gstin && !gstError && setCurrentStep(currentStep + 1);
  };

  const handlePrefixChange = (e: any) => {
    const inputValue = e.target.value;
    if (PREFIX_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setPrefixError(true);
      } else {
        setPrefixError(false);
      }
      setPrefix(inputValue);
    }
  };

  const handleNumberChange = (e: any) => {
    const inputValue = e.target.value;

    if (NUM_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setNumberError(true);
      } else {
        setNumberError(false);
      }
      setNextNumber(inputValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8) {
      return;
    }
    if (!NUM_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: nextNumber,
        prefix: prefix,
        gstNumber: gstin,
        isStudentTaxInvoiceEnabled: true,
      };
      CreateStudentInvoiceSettings(body);
    } catch (error) {}
  };

  const handleYesToggle = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: invoiceSettings?.nextNumber,
        prefix: invoiceSettings?.prefix,
        gstNumber: invoiceSettings?.gstNumber,
        isStudentTaxInvoiceEnabled: false,
      };
      UpdateStudentInvoiceSettings(body);
    } catch (error) {}
  };
  const blurrEffect =
    invoiceSettings?.prefix !== "" &&
    !invoiceSettings?.isStudentTaxInvoiceEnabled
      ? true
      : false;

  React.useEffect(() => {
    if (authUser)
      GetStudentInvoiceSettings(authUser?.institute?.[0]?.instituteId);
    dispatch<any>(getBillingAddress(authUser?.institute?.[0]?.instituteId));
  }, []);

  React.useEffect(() => {
    if (
      invoiceSettings &&
      invoiceSettings?.prefix !== "" &&
      Object?.keys(invoiceSettings)?.length > 0
    ) {
      setIsSaveClicked(true);
      setPrefix(invoiceSettings?.prefix);
      setNextNumber(invoiceSettings?.nextNumber);
      setIsEnabled(invoiceSettings?.isStudentTaxInvoiceEnabled);
      setGstin(invoiceSettings?.gstNumber);
    }
  }, [invoiceSettings]);

  React.useEffect(() => {
    if (
      invoiceSettingUpdateSuccess !== true &&
      invoiceSettingUpdateSuccess !== false
    ) {
      setIsSaveClicked(true);
      setShowDisablePopup(false);
      GetStudentInvoiceSettings(authUser?.institute?.[0]?.instituteId);
      dispatch<any>(getInstituteById(authUser?.institute?.[0]?.instituteId));
      dispatch(resetSettingsStatuses());
    }
  }, [invoiceSettingUpdateSuccess]);
  React.useEffect(() => {
    if (
      invoiceSettingCreateSuccess !== true &&
      invoiceSettingCreateSuccess !== false
    ) {
      setIsSaveClicked(true);
      setShowDisablePopup(false);
      GetStudentInvoiceSettings(authUser?.institute?.[0]?.instituteId);
      dispatch<any>(getInstituteById(authUser?.institute?.[0]?.instituteId));
      dispatch(resetSettingsStatuses());
    }
  }, [invoiceSettingCreateSuccess]);

  return (
    <Box sx={{ padding: " 24px 20px 20px 20px" }}>
      {!invoiceSettingLoading && invoiceSettings !== null ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              border: "1px solid rgba(119, 129, 126, 0.07)",
              background: "rgba(178, 178, 178, 0.06)",
              padding: "12px",
            }}
          >
            <div className={styles.enableText}>
              Want to send Tax Invoices to students?
            </div>
            <Tooltip
              title={
                hasTaxInvoiceAccess === false ? "Oops! No permission." : ""
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    zIndex: "9999999",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                      zIndex: "9999999",
                    },
                  },
                },
              }}
            >
              <FormControl
                fullWidth
                variant="standard"
                className="formControl"
                sx={{ width: "auto" }}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#142C44",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "500",
                      lineHeight: "normal",
                    },
                  }}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      id="status"
                      checked={isEnabled}
                      onClick={handleSwitchToggle}
                      disabled={hasTaxInvoiceAccess === false}
                    />
                  }
                  label="Enable Now"
                  labelPlacement="start"
                />
              </FormControl>
            </Tooltip>
          </Box>
          {(isEnabled || blurrEffect) && (
            <Box className={styles.taxInvoiceDetailsWrapper}>
              <Box className={styles.invoiceDetailsBox}>
                {!isSaveClicked ? (
                  <Box>
                    <Box className={styles.setupInvoiceHead}>Setup Invoice</Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: "1px solid #11C89B",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#11C89B",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            // border:
                            //   currentStep === 2
                            //     ? "1px solid #11C89B"
                            //     : "1px solid #D9D9D9",
                          }}
                        >
                          {/* {currentStep === 1 ? "1" : <DoneIcon />} */}1
                        </div>
                        <div className={styles.step}>Step 1</div>
                      </Box>
                      <div
                        style={{
                          color: "#E9E9E9",
                          width: "57px",
                          height: "1px",
                          background: "#E9E9E9",
                        }}
                      ></div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            // border: "1px solid #D9D9D9",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // color: "#667085",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            border:
                              currentStep === 2
                                ? "1px solid #11C89B"
                                : "1px solid #D9D9D9",
                            color: currentStep === 2 ? "#11C89B" : " #667085",
                          }}
                        >
                          2
                        </div>
                        <div className={styles.step}>Step 2</div>
                      </Box>
                    </Box>
                    {currentStep === 1 && (
                      <React.Fragment>
                        <Box className={styles.addGst}>Add GST</Box>
                        <div className={styles.gstIn}>
                          GSTIN{" "}
                          <span style={{ color: "#EC3939", fontWeight: "400" }}>
                            *
                          </span>
                        </div>

                        <TextField
                          id={"gstNumber"}
                          variant="outlined"
                          fullWidth
                          value={gstin}
                          inputProps={{
                            maxLength: 15,
                          }}
                          error={gstError}
                          helperText={gstError ? "Invalid GST Number" : ""}
                          onChange={handleChange}
                          size="small"
                          sx={{
                            width: "80%",
                            "& .MuiOutlinedInput-input": {
                              padding: "11px",
                            },
                          }}
                        />
                      </React.Fragment>
                    )}
                    {currentStep === 2 && (
                      <React.Fragment>
                        <div className={styles.invoiceSectionHead}>
                          Customize your Invoice number by setting a prefix and
                          starting number.
                        </div>
                        <div className={styles.invoiceSectionSubHead}>
                          The system will generate them sequentially, ensuring a
                          unique identification for each invoice.
                        </div>
                        <Box sx={{ display: "flex", maxWidth: "86%" }}>
                          <Box>
                            <div className={styles.prefix}>
                              Prefix{" "}
                              <span
                                style={{ color: "#EC3939", fontWeight: "400" }}
                              >
                                *
                              </span>
                            </div>
                            <TextField
                              value={prefix}
                              onChange={handlePrefixChange}
                              onKeyDown={(event: any) => {
                                if (!PREFIX_REGEX.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              sx={{
                                width: "72%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "11px",
                                },
                              }}
                              inputProps={{ maxLength: 10 }}
                              error={prefixError}
                              helperText={prefixError ? "Field Required" : ""}
                            />
                          </Box>
                          <Box>
                            <div className={styles.nextNumber}>
                              Next Number{" "}
                              <span
                                style={{ color: "#EC3939", fontWeight: "400" }}
                              >
                                *
                              </span>
                            </div>
                            <TextField
                              value={nextNumber}
                              onChange={handleNumberChange}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "11px",
                                },
                              }}
                              inputProps={{ maxLength: 10 }}
                              error={numberError}
                              helperText={numberError ? "Field Required" : ""}
                              onKeyDown={handleKeyDown}
                            />
                          </Box>
                        </Box>
                      </React.Fragment>
                    )}
                    <Box
                      sx={{ marginTop: "24px", display: "flex", gap: "12px" }}
                    >
                      <Button
                        sx={{
                          color: "#BFBFBF",
                          border: "1px solid #BFBFBF",
                          background: "#FFF",
                          textTransform: "capitalize",
                        }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                      <Tooltip
                        arrow
                        title={
                          hasTaxInvoiceAccess === false
                            ? "Oops! No permission."
                            : ""
                        }
                        PopperProps={{ style: { zIndex: 9999999 } }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              zIndex: "9999999",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                                zIndex: "9999999",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            variant="contained"
                            disabled={
                              (currentStep === 1
                                ? !gstin
                                : !prefix ||
                                  !nextNumber ||
                                  invoiceSettingCreateLoading) ||
                              hasTaxInvoiceAccess === false
                            }
                            onClick={
                              currentStep === 1
                                ? handleNextClick
                                : handleSaveClick
                            }
                          >
                            {invoiceSettingCreateLoading ? (
                              <CircularProgress size={20} color={"inherit"} />
                            ) : currentStep === 1 ? (
                              "Next"
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <InvoiceDetails
                    disabled={handleYesSwitchToggle}
                    UpdateStudentInvoiceSettings={UpdateStudentInvoiceSettings}
                    invoiceSettingUpdateSuccess={invoiceSettingUpdateSuccess}
                    invoiceSettings={invoiceSettings}
                    blurrEffect={blurrEffect}
                    hasTaxInvoiceAccess={hasTaxInvoiceAccess}
                  />
                )}
              </Box>

              <Box className={styles.invoicePreviewWrapper}>
                <div
                  className={styles.invoicePreview}
                  style={{ color: blurrEffect ? "#C5C5C5" : "#667085" }}
                >
                  Invoice Preview
                </div>
                <TaxInvoiceReceipt
                  invoiceSettings={invoiceSettings}
                  blurrEffect={blurrEffect}
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        Array.from(new Array(1)).map((_, index) => (
          <div key={index} style={{ marginBottom: "8px" }}>
            <div className={styles.leadCourseSkeletonWrap}>
              <Skeleton animation="wave" variant="rounded" height={60} />
            </div>
          </div>
        ))
      )}

      {showDisablePopup && (
        <DisablePopup
          handleYesSwitchToggle={handleYesToggle}
          onClose={handleNoSwitchToggle}
          showDisablePopup={showDisablePopup}
          loading={invoiceSettingUpdateLoading}
        />
      )}
    </Box>
  );
}

export default TaxInvoices;
