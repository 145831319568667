import React, { useRef, useState } from "react";
import styles from "./Customize.module.css";
import DefaultForm from "./DefaultForm";
import CustomizedProfilePicField from "./Tabs/CustomizeTab/CustomizedField/CustomizedProfilePicField";
import CustomizedDateField from "./Tabs/CustomizeTab/CustomizedField/CustomizedDateField";
import CustomizedRadioField from "./Tabs/CustomizeTab/CustomizedField/CustomizedRadioField";
import CustomizedDropdownField from "./Tabs/CustomizeTab/CustomizedField/CustomizedDropdownField";
import CustomizedCheckboxField from "./Tabs/CustomizeTab/CustomizedField/CustomizedCheckboxField";
import CustomizedEmailField from "./Tabs/CustomizeTab/CustomizedField/CustomizedEmailField";
import CustomizedPhoneField from "./Tabs/CustomizeTab/CustomizedField/CustomizedPhoneField";
import CustomizedTextField from "./Tabs/CustomizeTab/CustomizedField/CustomizedTextField";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { checkObjectsEqual } from "../../../../../components/CommonFunctions/CommonFunctions";
import {
  getAddLeadFormForInstitute,
  resetSettingsStatuses,
} from "../../service/settingsSlice";
import { useNavigate } from "react-router-dom";
import { getInstituteById } from "../../../Manage/InstituteProfile/service/instituteSlice";
import configureIcon from "../../../../../assets/images/configureIcon.svg";
import CancelPopup from "../../../../../components/CommonPopups/CancelPopup";
import { TextField } from "@mui/material";

function EditLeadForm(props: any) {
  const {
    add,
    GetCustomFields,
    customFields,
    EditLeadForm,
    GetLeadFormById,
    leadFormById,
    updateLeadFormSuccess,
    updateLeadFormLoading,
    createLeadFormLoading,
    createLeadFormSuccess,
    CreateLeadForm,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const [draggedField, setDraggedField] = useState<any>(null);
  const [fields, setFields] = useState<any[]>([]);
  const [additionalFields, setAdditionalFields] = useState<any[]>([]);
  const [oldAdditionalFields, setOldAdditionalFields] = useState<any[]>([]);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);

  const [formName, setFormName] = useState<string>("");

  console.log("draggedField", draggedField, additionalFields);
  const containerRef = useRef<any>(null);

  const [draggedFieldIndex, setDraggedFieldIndex] = useState<number | null>(
    null
  );
  const [dragActive, setDragActive] = useState<boolean>(false);

  const filteredFields = fields?.filter(
    (field) =>
      !additionalFields?.some((additional) => additional.id === field.id)
  );

  const handleChangeName = (e: any) => {
    setFormName(e.target.value);
  };

  function handleOnDrag(e: any, field: any) {
    setDraggedField(field);
  }

  function handleOnDrop(e: any) {
    e.preventDefault();
    if (draggedField) {
      let fieldExist: any;

      fieldExist = additionalFields?.filter(
        (item: any) => item?.fieldName === draggedField?.fieldName
      );
      if (fieldExist?.length > 0) {
        setDraggedField(null);
      } else {
        const lastIndex =
          additionalFields?.length > 0
            ? additionalFields[additionalFields.length - 1].index || 0
            : -1;

        const updatedDraggedField = {
          ...draggedField,
          index: additionalFields?.length === 0 ? 1 : lastIndex + 1,
        };
        setAdditionalFields([...additionalFields, updatedDraggedField]);
        setDraggedField(null);
      }
    }
  }

  function handleOnDragFields(e: any, field: any, index: number) {
    setDraggedField(field);
    setDraggedFieldIndex(index);
    setDragActive(true);
  }

  function handleOnDragEnter(e: any, field: any, index: number) {
    if (draggedField !== null && draggedFieldIndex !== null) {
      const updatedFields = [...additionalFields];
      updatedFields.splice(draggedFieldIndex, 1);
      updatedFields.splice(index, 0, draggedField);
      const updatedFieldsWithIndex = updatedFields?.map(
        (item, index: number) => ({
          ...item,
          index: index + 1,
        })
      );
      setAdditionalFields(updatedFieldsWithIndex);
      setDraggedField(null);
      setDraggedFieldIndex(null);
    }
  }

  function handleOnDragOver(e: any) {
    e.preventDefault();
    if (dragActive) {
      const y = e.clientY;
      const containerRect = containerRef.current.getBoundingClientRect();
      const scrollSpeed = 10;
      let scrollDirection = 0;

      if (y < containerRect.top + 100) {
        scrollDirection = -1;
      } else if (y > containerRect.bottom - 100) {
        scrollDirection = 1;
      }

      if (scrollDirection !== 0) {
        containerRef.current.scrollTop += scrollDirection * scrollSpeed;
      }
    }
  }

  const handleFieldChange = (updatedField: any, index: any) => {
    const newFields = [...additionalFields];
    newFields[index] = updatedField;
    setAdditionalFields(newFields);
  };

  const handleDeleteField = (fieldToDelete: any) => {
    const updatedFields = additionalFields
      ?.filter((field) => field !== fieldToDelete)
      ?.map((item: any, index: number) => ({
        ...item,
        index: index + 1,
      }));

    setAdditionalFields(updatedFields);
  };

  const isObjectsEqual = checkObjectsEqual(
    { formName: formName, fields: additionalFields },
    { formName: leadFormById?.formName, fields: oldAdditionalFields }
  );

  const cancelButtonClick = () => {
    if (isObjectsEqual) {
      navigate(`/app/Settings/Customize`);
      localStorage.removeItem("createLeadFormName");
    } else {
      setCancelModal(true);
    }
  };

  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    navigate(`/app/Settings/Customize`);
    localStorage.removeItem("createLeadFormName");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      if (formName !== "") {
        const updatedFields = additionalFields?.map(
          (item: any, index: number) => ({
            field: {
              isDeletable: true,
              fieldPlaceholder: item?.fieldPlaceholder || "",
              default: item?.default || "",
              fieldName: item?.fieldName || "",
              DBModel: item?.DBModel || "",
              options: item?.options || [],
              isCustom: item?.isCustom || false,
              index: index + 1,
              loadDatafromDB: item?.loadDatafromDB || false,
              fieldType: item?.fieldType || "text",
              required: item?.required || false,
            },
            id: item?.id || "",
          })
        );
        const body = {
          id: leadFormById?.id,
          formName: formName,
          status: updatedFields?.length > 0 ? "ACTIVE" : "INACTIVE",
          instituteId: activeInstitute?.id,
          additionalFields: updatedFields,
        };

        add
          ? CreateLeadForm({
              ...body,
              createdById: authUser?.institute[0]?.id,
              createdByName: authUser?.firstName + " " + authUser?.lastName,
            })
          : EditLeadForm({
              ...body,
              status:
                updatedFields?.length > 0 && !isObjectsEqual
                  ? "ACTIVE"
                  : "INACTIVE",
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
            });
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (activeInstitute?.id) GetCustomFields(activeInstitute?.id);
    if (!add) {
      const pathname = window.location.pathname;
      const formId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetLeadFormById(formId);
    }
  }, [add]);

  React.useEffect(() => {
    if (leadFormById)
      if (leadFormById?.additionalFields?.length > 0) {
        const updatedFields = leadFormById?.additionalFields?.map(
          (item: any, index: number) => ({
            fieldType: item?.field?.fieldType,
            fieldName: item?.field?.fieldName,
            fieldPlaceholder: item?.field?.fieldPlaceholder,
            options: item?.field?.options,
            default: item?.field?.default,
            required: item?.field?.required,
            index: index + 1,
            loadDatafromDB: item?.field?.loadDatafromDB,
            DBModel: item?.field?.DBModel,
            isCustom: item?.field?.isCustom,
            isFromCustom: item?.field?.isFromCustom,
            id: item?.id,
          })
        );

        setAdditionalFields(updatedFields);
        setOldAdditionalFields(updatedFields);
      } else {
        setAdditionalFields([]);
        setOldAdditionalFields([]);
      }

    if (add) {
      const savedFormName = localStorage.getItem("createLeadFormName") || "";
      setFormName(savedFormName);
    } else {
      setFormName(leadFormById?.formName);
    }
  }, [leadFormById, add]);

  React.useEffect(() => {
    if (customFields?.fields?.length > 0) {
      const updatedFields = customFields?.fields?.map(
        (item: any, index: number) => ({
          fieldType: item?.field?.fieldType,
          fieldName: item?.field?.fieldName,
          fieldPlaceholder: item?.field?.fieldPlaceholder,
          options: item?.field?.options,
          default: item?.field?.default,
          required: item?.field?.required,
          index: index + 1,
          loadDatafromDB: item?.field?.loadDatafromDB,
          DBModel: item?.field?.DBModel,
          isCustom: item?.field?.isCustom,
          isFromCustom: item?.field?.isFromCustom,
          id: item?.id,
        })
      );

      setFields(updatedFields);
    }
  }, [customFields]);

  React.useEffect(() => {
    if (
      (updateLeadFormSuccess !== true && updateLeadFormSuccess !== false) ||
      (createLeadFormSuccess !== true && createLeadFormSuccess !== false)
    ) {
      navigate(`/app/Settings/Customize`);
      dispatch(resetSettingsStatuses());
      dispatch<any>(getInstituteById(activeInstitute?.id));
      dispatch<any>(getAddLeadFormForInstitute(activeInstitute.id));
      localStorage.removeItem("createLeadFormName");
    }
  }, [updateLeadFormSuccess, createLeadFormSuccess]);

  console.log("formName", formName, fields, filteredFields);
  return (
    <div className={styles.editLeadFormWrap}>
      <div className={styles.editLeadFormLeft} style={{ width: "445px" }}>
        <div className={styles.territoryTitle}>Fields</div>
        <div>
          {filteredFields?.map((field: any, index: number) => (
            <div
              key={index}
              className={styles.primaryFields}
              draggable
              onDragStart={(e) => handleOnDrag(e, field)}
            >
              {field.fieldType === "text" && (
                <CustomizedTextField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "phone" && (
                <CustomizedPhoneField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "email" && (
                <CustomizedEmailField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}

              {field.fieldType === "checkbox" && (
                <CustomizedCheckboxField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "select" && (
                <CustomizedDropdownField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "radio" && (
                <CustomizedRadioField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "date" && (
                <CustomizedDateField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "file" && (
                <CustomizedProfilePicField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
              {field.fieldType === "profilePic" && (
                <CustomizedProfilePicField
                  field={field}
                  onFieldChange={() => {}}
                  onDelete={() => {}}
                  edit={false}
                  deleteAction={false}
                  mandatory={false}
                />
              )}
            </div>
          ))}
        </div>
        <div
          className={styles.configureBtn}
          onClick={() => navigate(`/app/Settings/Customize?tabOpen=parameter`)}
        >
          <img src={configureIcon} alt="icon" />
          Configure customizable parameter
        </div>
      </div>
      <div
        className={styles.editLeadFormRight}
        style={{ width: "calc(100% - 445px)", overflow: "hidden" }}
      >
        <div
          style={{
            width: "100%",
            overflowY: "auto",
            maxHeight: "calc(100vh - 166px)",
            minHeight: "calc(100vh - 166px)",
            padding: "30px 26px",
          }}
        >
          <div className={styles.primaryTitle}>Add Lead</div>
          <div className={styles.modalTitle} style={{ fontSize: "14px" }}>
            Form Name
          </div>
          <TextField
            variant="outlined"
            value={formName}
            placeholder="Enter name for your form"
            fullWidth
            onChange={handleChangeName}
            inputProps={{
              style: {
                padding: "10px 12px",
                backgroundColor: "#FFF",
                borderRadius: "4px",
              },
            }}
            sx={{ width: "300px", marginBottom: "16px" }}
            error={formName === ""}
            helperText={formName === "" ? "Please fill required field" : ""}
          />
          <DefaultForm />
          <div className={styles.secondoryTitle} style={{ marginTop: "30px" }}>
            Additional information
          </div>
          <div
            draggable
            onDrop={handleOnDrop}
            onDragOver={handleOnDragOver}
            className={styles.additionalBox}
            style={{
              background: additionalFields?.length > 0 ? "#fff" : "#F1F4F880",
              border: "1px dashed #E6E6E6",
              minHeight: "500px",
              width: "450px",
            }}
          >
            {additionalFields?.length > 0 ? (
              <>
                {additionalFields?.map((field: any, index: number) => (
                  <div
                    key={index}
                    className={styles.primaryFields}
                    draggable
                    onDragStart={(e) => handleOnDragFields(e, field, index)}
                    onDragOver={(e) => handleOnDragOver(e)}
                    onDropCapture={(e) => handleOnDragEnter(e, field, index)}
                  >
                    {field.fieldType === "text" && (
                      <CustomizedTextField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "phone" && (
                      <CustomizedPhoneField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "email" && (
                      <CustomizedEmailField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}

                    {field.fieldType === "checkbox" && (
                      <CustomizedCheckboxField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "select" && (
                      <CustomizedDropdownField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "radio" && (
                      <CustomizedRadioField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "date" && (
                      <CustomizedDateField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "file" && (
                      <CustomizedProfilePicField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                    {field.fieldType === "profilePic" && (
                      <CustomizedProfilePicField
                        field={field}
                        onFieldChange={(updatedField: any) =>
                          handleFieldChange(updatedField, index)
                        }
                        onDelete={handleDeleteField}
                        edit={false}
                        deleteAction={true}
                        mandatory={true}
                      />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.additionalBoxDrag}>
                Drag and drop fileds to build your custom Add Lead.
              </div>
            )}
          </div>
        </div>
        <div className={styles.parameterFieldBottom}>
          <div className={styles.parameterFieldBtnsWrap}>
            {cancelModal && (
              <CancelPopup
                open={cancelModal}
                onClose={closeCancelModal}
                noBtnClick={closeCancelModal}
                yesBtnClick={cancelYesBtn}
              />
            )}

            <CancelBtn onClick={cancelButtonClick} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={
                updateLeadFormLoading ||
                (!add && isObjectsEqual) ||
                (add && additionalFields?.length === 0) ||
                formName === "" ||
                createLeadFormLoading
              }
              loader={updateLeadFormLoading || createLeadFormLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditLeadForm;
