import React, { useState, createRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropperModalCom from "./CropperModalCom";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../ToastNotification/service/toastNotificationSlice";

export default function DropZoneCom(props: any) {
  const { inputRef } = props;
  const [files, setFiles] = useState<any>([]);
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const cropperRef = createRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    setFiles([]);
  }, []);

  useEffect(() => {
    setFiles(props.editImage);
  }, [props]);

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: {
  //     "image/*": [],
  //   },
  //   disabled: props?.hasCourseAccess ? false : true,
  //   onDrop: (acceptedFiles) => {
  //     setIsCrop(true);

  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setImage(reader.result);
  //     };
  //     reader.readAsDataURL(acceptedFiles[0]);
  //   },
  // });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    disabled: props?.hasCourseAccess ? false : true,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      // Check file size (2MB = 2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        dispatch(
          setToastNotification({
            message: String("File size must be less than 2mb"),
            type: "error",
            snackOpen: true,
          })
        );
        return;
      }

      // Check resolution of the image
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Validate image resolution
        if (width > 1280 || height > 720) {
          dispatch(
            setToastNotification({
              message: String("Upload below 1280*720 resolution"),
              type: "error",
              snackOpen: true,
            })
          );
          return;
        }

        // If both conditions pass, proceed to cropping
        setIsCrop(true);
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      };
    },
  });

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setFiles(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setIsCrop(false);
      props.setImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      props.handleImageUpload(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
        image
      );
    }
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        {props.image}
        <input
          // {...getInputProps()}
          {...(getInputProps() as any)} // Cast to 'any' to allow 'ref' attribute
          ref={inputRef}
        />
      </div>

      {isCrop && (
        <>
          <CropperModalCom open={isCrop} setIsCrop={setIsCrop}>
            <Cropper
              ref={cropperRef}
              style={{ height: 400, width: "100%", zIndex: 9999 }}
              zoomTo={0.5}
              aspectRatio={4 / 3}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              dragMode="move"
              guides={true}
              className="square-cropper"
            />
            <Box textAlign={"center"}>
              <Button
                variant="outlined"
                onClick={getCropData}
                sx={{ marginTop: "10px" }}
              >
                {" "}
                Crop Image
              </Button>
            </Box>
          </CropperModalCom>
        </>
      )}
      <Box
        textAlign={"center"}
        width={"150px"}
        boxShadow={
          "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
        }
      ></Box>
    </section>
  );
}
