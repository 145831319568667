import React, { useState } from "react";
import {
  Box,
  Dialog,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./PreviewFormPopup.module.css";
import CountryCodePhoneInput from "../../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import ButtonLog from "../../../../../../components/Button/ButtonLog";

interface PreviewWebformField {
  fieldName: string;
  default: string;
  fieldType: string;
  options?: Array<{ id: string; name: string }>;
}

const PreviewFormPopup = (props: any) => {
  const {
    rightSectionFields,
    showPreviewPopup,
    onClose,
    handleUpdateForm,
    isComingFromNextButton,
    setIscomingFromNextButton,
    updatedButtonName,
    UpdateWebformLoading,
  } = props;
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);
  const [formData, setFormData] = React.useState<any>({});

  const [contactNumberValid, setContactNumberValid] = useState(true);

  const handleClose = () => {
    onClose();
    setIscomingFromNextButton(false);
  };

  const handleChange = (event: any, fieldName: string) => {
    const value = event.target.value;
    setFormData((prevData: any) => {
      if (!["name", "email"].includes(fieldName)) {
        const otherDetails = [...(prevData?.otherDetails || [])];
        const existingDetailIndex = otherDetails.findIndex(
          (detail) => fieldName in detail
        );

        if (existingDetailIndex !== -1) {
          otherDetails[existingDetailIndex][fieldName] = value;
        } else {
          otherDetails.push({ [fieldName]: value });
        }
        return {
          ...prevData,
          otherDetails,
        };
      } else {
        return {
          ...prevData,
          [fieldName]: value,
        };
      }
    });
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    val: any
  ) => {
    const checked = event.target.checked;

    setFormData((prevData: any) => {
      if (!["name", "email"].includes(fieldName)) {
        const otherDetails = [...(prevData?.otherDetails || [])];
        const existingDetailIndex = otherDetails.findIndex(
          (detail) => fieldName in detail
        );

        if (existingDetailIndex !== -1) {
          otherDetails[existingDetailIndex][fieldName] = checked;
        } else {
          otherDetails.push({ [fieldName]: val });
        }

        return {
          ...prevData,
          otherDetails,
        };
      }
    });
  };

  const handleDateChange = (val: any, fieldName: string) => {
    const value = val?.unix();
    setFormData((prevData: any) => {
      const otherDetails = [...(prevData?.otherDetails || [])];
      const existingDetailIndex = otherDetails.findIndex(
        (detail) => fieldName in detail
      );

      if (existingDetailIndex !== -1) {
        otherDetails[existingDetailIndex][fieldName] = value;
      } else {
        otherDetails.push({ [fieldName]: value });
      }
      return {
        ...prevData,
        otherDetails,
      };
    });
  };

  const renderPreviewFields = () => {
    return rightSectionFields.map((field: any, index: number) => {
      switch (field.fieldType.toLowerCase()) {
        case "text":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <div style={{ textTransform: "capitalize", marginBottom: "5px" }}>
                {field?.fieldName}
              </div>

              <TextField
                fullWidth
                // placeholder={field.fieldPlaceholder}
                placeholder="Enter your answer"
                type={field.fieldType}
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "8px 12px",
                  },
                }}
                value={
                  formData?.otherDetails
                    ? formData?.otherDetails?.find(
                        (detail: any) => detail[field.fieldName]
                      )?.[field.fieldName]
                    : formData[field.fieldName] || ""
                }
                onChange={(e: any) => handleChange(e, field.fieldName)}
              />
            </Box>
          );
        case "email":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <div style={{ textTransform: "capitalize", marginBottom: "5px" }}>
                {field?.fieldName}
              </div>

              <TextField
                fullWidth
                // placeholder={field.fieldPlaceholder}
                placeholder="Enter your answer"
                type={field.fieldType}
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "8px 12px",
                  },
                }}
                value={
                  formData?.otherDetails
                    ? formData?.otherDetails?.find(
                        (detail: any) => detail[field.fieldName]
                      )?.[field.fieldName]
                    : formData[field.fieldName] || ""
                }
                onChange={(e: any) => handleChange(e, field.fieldName)}
              />
            </Box>
          );
        case "select":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <div style={{ textTransform: "capitalize", marginBottom: "5px" }}>
                {field?.fieldName}
              </div>

              <FormControl
                fullWidth
                key={index}
                margin="normal"
                sx={{ marginTop: 0 }}
              >
                <InputLabel>
                  <span style={{ background: "white", zIndex: "99999" }}>
                    {`${
                      field.fieldName.charAt(0).toUpperCase() +
                      field.fieldName.slice(1)
                    } `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                </InputLabel>
                <Select
                  value={
                    formData?.otherDetails
                      ? formData?.otherDetails?.find(
                          (detail: any) => detail[field.fieldName]
                        )?.[field.fieldName]
                      : formData[field.fieldName] || ""
                  }
                  onChange={(e) => handleChange(e, field.fieldName)}
                  label={field.fieldName.toUpperCase()}
                  placeholder={`Select ${field.fieldName}`}
                  MenuProps={{
                    style: { zIndex: 999999, fontSize: "14px" },
                    PaperProps: {
                      style: {
                        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                      },
                    },
                  }}
                >
                  {field.options?.map((option: any) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          );
        case "phone":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <div style={{ textTransform: "capitalize", marginBottom: "5px" }}>
                {field?.fieldName}
              </div>

              <CountryCodePhoneInput
                InputProps={{
                  readOnly: true,
                }}
                placeholder="Type phone number"
                value={phoneNumber}
                onChange={(event: any, country: any) => {
                  setFormData((prevData: any) => {
                    return {
                      ...prevData,
                      [field?.fieldName]: event,
                      contactNoCountryCode: country.dialCode,
                    };
                  });
                  if (event !== "") {
                    setValidStateContact(
                      isValidPhoneNumber(
                        event.replace(country.dialCode, ""),
                        country.countryCode.toUpperCase()
                      )
                    );
                  }
                }}
                error={validStateContact}
                fieldName={field.fieldName}
              />
            </Box>
          );

        case "radio":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <FormControl component="div">
                <div
                  style={{ textTransform: "capitalize", marginBottom: "5px" }}
                >
                  {field?.fieldName}
                </div>
                <RadioGroup
                  aria-label={field?.fieldName}
                  name={field?.fieldName}
                  value={
                    formData?.otherDetails
                      ? formData?.otherDetails?.find(
                          (detail: any) => detail[field.fieldName]
                        )?.[field.fieldName]
                      : formData[field.fieldName] || ""
                  }
                  onChange={(e) => handleChange(e, field.fieldName)}
                >
                  {field.options?.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      label={option.name}
                      value={option.name}
                      control={
                        <Radio size="small" inputProps={{ readOnly: true }} />
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          );

        case "checkbox":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <FormControl component="fieldset">
                <div
                  style={{ textTransform: "capitalize", marginBottom: "5px" }}
                >
                  {field?.fieldName}
                </div>
                <FormGroup>
                  {field.options?.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={
                            formData?.otherDetails
                              ? formData?.otherDetails?.find(
                                  (detail: any) =>
                                    detail[field.fieldName] === option?.name
                                )
                              : false
                          }
                          onChange={(e) =>
                            handleCheckBoxChange(
                              e,
                              field.fieldName,
                              option.name
                            )
                          }
                        />
                      }
                      label={option.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          );
        case "date":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <div style={{ textTransform: "capitalize", marginBottom: "5px" }}>
                {field?.fieldName}
              </div>

              <TextField
                fullWidth
                // placeholder={field.fieldPlaceholder}
                placeholder="Choose a date"
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "8px 12px",
                  },
                }}
              />
            </Box>
          );
        default:
          return null;
      }
    });
  };
  return (
    <Dialog
      open={showPreviewPopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          height: "auto",
          maxWidth: "400px",
          minHeight: "165px",
          // padding: "24px",
          borderRadius: "8px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "0",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ padding: "24px 24px 10px 24px" }}>
          <div className={styles.closeModalButton}>
            <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
          </div>
          <div className={styles.modalTitle}>Preview</div>
        </div>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "400px",
            padding: "10px 24px 10px 24px",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>{renderPreviewFields()}</Box>

          <Button
            fullWidth
            variant="outlined"
            sx={{ textTransform: "capitalize", marginBottom: "40px" }}
          >
            {updatedButtonName}
          </Button>
        </div>
        {isComingFromNextButton && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "12px 24px 24px 24px",
              boxShadow: "10px 4px 14px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Button
              sx={{
                width: "48%",
                textTransform: "capitalize",
                color: "#9A9EA6",
                borderColor: "#D0D5DD",
                border: "1px solid",
                borderRadius: "4PX",
                fontSize: "15px",
                fontWeight: "400",
                fontFamily: "poppins",
                ":hover": {
                  color: "#9A9EA6",
                  borderColor: "#D0D5DD",
                  backgroundColor: "#9a9ea612",
                },
              }}
              variant="outlined"
              onClick={() => {
                handleClose();
                setIscomingFromNextButton(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                width: "48%",
                fontSize: "15px",
                fontWeight: "400",
                fontFamily: "poppins",
              }}
              onClick={handleUpdateForm}
              disabled={UpdateWebformLoading}
            >
              {UpdateWebformLoading ? (
                <CircularProgress size={20} color={"inherit"} />
              ) : (
                "Publish Form"
              )}
            </Button>

            {/* {UpdateWebformLoading ? (
              <ButtonLog
                text={<CircularProgress size={25} color={"inherit"} />}
                variant="contained"
                type="submit"
                width="150px"
              />
            ) : (
              <Button
                // variant="contained"
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  width: "48%",
                  fontSize: "15px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                }}
                onClick={handleUpdateForm}
              >
                Publish Form
             
              </Button>
            )} */}
          </Box>
        )}
      </div>
    </Dialog>
  );
};
export default PreviewFormPopup;
