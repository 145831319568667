import React from "react";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import styles from "./LeadRegularPay.module.css";
import EmiDetails from "../../PaymentDetails/EmiDetails";
import moment from "moment";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import BasicRegularDetails from "../../PaymentDetails/BasicRegularDetails";
import LeadPaymentAutoDebit from "../LeadAutoDebit/LeadPaymentAutoDebit";

function LeadRegularPayDetails(props: any) {
  const {
    leadData,
    setIsPayEditOpen,
    leadEmiData,
    RecordPayment,
    SendPaymentLink,
    EditRecordPayment,
    filteredPaymentRequests,
    isFirstPaymentRequestedOrFailed,
    lastTransaction,
    activityData,
    hasLeadAccess,
    selectedCurrency,
  } = props;

  const discountAmount =
    leadData?.paymentDetails?.totalAmount -
    leadData?.paymentDetails?.finalAmount;
  return (
    <div>
      <div
        style={{
          border: "1px solid rgba(102, 112, 133, 0.12)",
          borderRadius: "6px",
        }}
      >
        <div className={styles.paymentDetailsHead}>
          <div className={styles.paymentDetailsHeadLeft}>
            <div className={styles.paymentDetailsHeadLeftItem}>
              <div className={styles.paymentDetailsHeadItemLabel}>Price</div>
              <div
                className={styles.paymentDetailsHeadItemValue}
                style={{ fontFamily: "Roboto" }}
              >
                {selectedCurrency} {leadData?.paymentDetails?.totalAmount}
              </div>
            </div>

            <div className={styles.paymentDetailsHeadLeftItem}>
              <div className={styles.paymentDetailsHeadItemLabel}>
                Installment
              </div>
              <div className={styles.paymentDetailsHeadItemValue}>
                {leadData?.paymentDetails?.enableInstallment
                  ? "Enabled"
                  : "Disabled"}
              </div>
            </div>
            <div className={styles.paymentDetailsHeadLeftItem}>
              <div className={styles.paymentDetailsHeadItemLabel}>Discount</div>
              <div
                className={styles.paymentDetailsHeadItemValue}
                style={{ fontFamily: "Roboto" }}
              >
                {selectedCurrency + " " + discountAmount}
              </div>
            </div>
          </div>
          <div className={styles.paymentDetailsHeadRight}>
            <div className={styles.paymentDetailsHeadFinalPriceWrap}>
              <div className={styles.paymentDetailsHeadItemLabel}>
                Final Price
              </div>
              <div
                className={styles.paymentDetailsHeadFinalPrice}
                style={{ fontFamily: "Roboto" }}
              >
                {selectedCurrency + " " + leadData?.paymentDetails?.finalAmount}
              </div>
            </div>
            <div>
              {hasLeadAccess && leadData?.balanceAmount > 0 && (
                <IconDropdown
                  options={[
                    {
                      label: "Edit",
                      onClick: () => {
                        setIsPayEditOpen(true);
                      },
                      icon: editIcon,
                      style: { color: "#667085" },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
        {leadData?.paymentDetails?.scheduleInstallment === false ? (
          <div style={{ margin: "0px 10px " }}>
            <BasicRegularDetails
              leadData={leadData}
              RecordPayment={RecordPayment}
              SendPaymentLink={SendPaymentLink}
              EditRecordPayment={EditRecordPayment}
              isFirstPaymentRequestedOrFailed={isFirstPaymentRequestedOrFailed}
              filteredPaymentRequests={filteredPaymentRequests}
              hasLeadAccess={hasLeadAccess}
              selectedCurrency={selectedCurrency}
            />
          </div>
        ) : (
          <>
            {leadData?.paymentDetails?.EMIDetails?.EMIS?.length > 0 && (
              <>
                <div className={styles.regularPayDetailsItems}>
                  <div className={styles.regularPayDetailsItem}>
                    <div className={styles.regularPayDetailsLabel}>
                      Start Date
                    </div>
                    <div className={styles.regularPayDetailsValue}>
                      {moment(
                        leadData?.paymentDetails?.EMIDetails?.EMIS[0]?.dueDate
                      ).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div className={styles.regularPayDetailsItem}>
                    <div className={styles.regularPayDetailsLabel}>Mode</div>
                    <div className={styles.regularPayDetailsValue}>
                      {leadData?.paymentDetails?.EMIDetails?.emiMode
                        ?.toLowerCase()
                        .replace(/_/g, " ")}
                    </div>
                  </div>
                  <div className={styles.regularPayDetailsItem}>
                    <div className={styles.regularPayDetailsLabel}>
                      No. of Installments
                    </div>
                    <div className={styles.regularPayDetailsValue}>
                      {leadData?.paymentDetails?.numberOfInstallment}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.regularPaySecTitle}
                  style={{ marginLeft: "28PX" }}
                >
                  Installments
                </div>
                {leadEmiData?.length > 0 && (
                  <EmiDetails
                    leadEmiData={leadEmiData}
                    finalBatchPrice={leadData?.paymentDetails?.finalAmount}
                    totalPaidAmount={leadData?.batchAmountPaid}
                    balanceAmountToBePaid={leadData?.balanceAmount}
                    discountMetric={leadData?.paymentDetails?.discountMetric}
                    discount={leadData?.paymentDetails?.discount}
                    leadData={leadData}
                    RecordPayment={RecordPayment}
                    SendPaymentLink={SendPaymentLink}
                    EditRecordPayment={EditRecordPayment}
                    filteredPaymentRequests={filteredPaymentRequests}
                    isFirstPaymentRequestedOrFailed={
                      isFirstPaymentRequestedOrFailed
                    }
                    lastTransaction={lastTransaction}
                    hasLeadAccess={hasLeadAccess}
                    selectedCurrency={selectedCurrency}
                  />
                )}
                <LeadPaymentAutoDebit
                  leadData={leadData}
                  paymentType={"REGULAR"}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LeadRegularPayDetails;
