import React, { useState, useCallback, useRef } from "react";
import styles from "../../../components/Settings/Settings.module.css";
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  FormGroup,
  FormControl,
  Checkbox,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import FilterDrawer from "../../../../../../components/FilterDrawer/FilterDrawer";
import FilterChipsWrap from "../../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterTypeWrap from "../../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterSearchField from "../../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterCheckBoxWrap from "../../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  resetBulkLeadCreationSuccess,
  resetBulkLeadDeleteSuccess,
  resetDeleteWebformResponseSuccess,
  resetSingleFormData,
} from "../../../Webform/service/webformSlice";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  GetAllWebforms,
  GetWebformResponse,
} from "../../../Webform/service/webform.request";
import deleteIcon from "../../../../../../assets/images/delete.svg";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import ClearFilter from "../../../../../../components/ClearFilter/ClearFilter";
import ListPopover from "../../../../../../components/ListPopover/ListPopover";
import RemoveContactsModal from "../Remove/RemoveContactsModal";
import ListPopoverPagination from "../../../../../../components/ListPopover/ListPopoverPagination";
import moment from "moment";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../../../../../components/CommonFunctions/CommonFunctions";
import AddLeadPayment from "../../../../Nurture/components/AddLeadPayment";
import { resetLeadCreateSuccess } from "../../../../Nurture/service/nurtureSlice";

type formResponseData = {
  instituteId: string;
  formId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

type webformsData = {
  instituteId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

function WebformTab(props: any) {
  const {
    GetWebformForInstitute,
    GetFormResponse,
    BulkLeadCreation,
    DeleteWebformResponse,
    page,
    getFormResponseLoading,
    GetAllWebform,
    CreateEnquiry,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);
  const branchList = useSelector((state: any) => state.branch.branchList);

  const hasWebformsAccess = getSubDomainAccessStatus(
    "INBOUND",
    "WEBFORMS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasContactsAccess = getSubDomainAccessStatus(
    "INBOUND",
    "CONTACTS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );
  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#667085",
      padding: hasWebformsAccess === true ? "10px" : "14px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: hasWebformsAccess === true ? "10px" : "18px",
    },
  }));

  const {
    bulkLeadCreationSuccess,
    bulkLeadCreationLoading,
    getFormByInstituteData,
    getFormByInstituteLoading,
    GetAllWebformLoading,
    getAllFormByInstituteData,
    getFormResponseData,
    deleteWebformResponseSuccess,
  } = useSelector((state: any) => state.webform);
  const teamMembers = useSelector((state: any) => state.staff.staffList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter(
    (item: any) =>
      item?.batchConductType === "REGULAR_BATCH" && item?.status === "ACTIVE"
  );
  const { deleteWebformResponseLoading } = useSelector(
    (state: any) => state.webform
  );

  const courseFieldRef = useRef<HTMLInputElement | null>(null);
  const branchFieldRef = useRef<HTMLInputElement | null>(null);
  const batchFieldRef = useRef<HTMLInputElement | null>(null);
  const assigneeFieldRef = useRef<HTMLInputElement | null>(null);
  const formFieldRef = useRef<HTMLInputElement | null>(null);

  const [filterArray, setFilterArray] = useState<any>([]);
  const webformContacts = filterArray?.returnData;

  const [selectWebform, setSelectWebform] = useState<any>();
  const [fieldsName, setFieldsName] = useState<any>();

  const [hasMoreData, setHasMoreData] = useState(true);

  const [filterFormArray, setFilterFormArray] = useState<any>();
  const webformForms = filterFormArray?.returnData;
  const [selectedFormName, setSelectedFormName] = useState<any>();
  const [selectedFormId, setSelectedFormId] = useState<any>();

  const [hasMoreForm, setHasMoreForm] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedCourseForMoving, setSelectedCourseForMoving] = useState<any>(
    []
  );
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [seletedAssignee, setSelectedAssignee] = useState<any>();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );

  const combinedAssignees = getCombinedAssignees(
    teamMembers,
    selectedBranch?.id
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchForm, setSearchForm] = useState("");
  const open = Boolean(anchorEl);

  //course
  const [courseAnchorEl, setCourseAnchorEl] = useState(null);
  const openCourse = Boolean(courseAnchorEl);
  const [searchCourse, setSearchCourse] = useState<string>("");

  //branch
  const [branchAnchorEl, setBranchAnchorEl] = useState(null);
  const openBranch = Boolean(branchAnchorEl);
  const [searchBranch, setSearchBranch] = useState<string>("");

  //batch
  const [batchAnchorEl, setBatchAnchorEl] = useState(null);
  const openBatch = Boolean(batchAnchorEl);
  const [searchBatch, setSearchBatch] = useState<string>("");

  //assignee
  const [assigneeAnchorEl, setAssigneeAnchorEl] = useState(null);
  const openAssignee = Boolean(assigneeAnchorEl);
  const [searchAssignee, setSearchAssignee] = useState<string>("");

  //Filter
  const [showAllCourses, setShowAllCourses] = React.useState<boolean>(false);
  const [courseSearchTerm, setCourseSearchTerm] = React.useState("");
  const [checkedCourses, setCheckedCourses] = useState<any[]>([]);
  const [showAllBranches, setShowAllBranches] = React.useState<boolean>(false);
  const [branchSearchTerm, setBranchSearchTerm] = React.useState("");
  const [checkedBranches, setCheckedBranches] = useState<any[]>([]);
  const [showAllBatches, setShowAllBatches] = React.useState<boolean>(false);
  const [batchSearchTerm, setBatchSearchTerm] = React.useState("");
  const [checkedBatches, setCheckedBatches] = useState<any[]>([]);
  const [checkedTags, setCheckedTags] = useState<any[]>([]);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [filterCheckedCourse, setFilterCheckedCourse] = useState<any>([]);
  const [filterCheckedBranch, setFilterCheckedBranch] = useState<any>([]);
  const [filterCheckedBatch, setFilterCheckedBatch] = useState<any>([]);
  const [filterCheckedTag, setFilterCheckedTag] = useState<any>([]);

  const [formTypeAnchorEl, setFormTypeAnchorEl] = useState(null);
  const openFormType = Boolean(formTypeAnchorEl);
  const [formTypeSelected, setFormTypeSelected] = useState<any>(null);
  const formTypeFieldRef = useRef<HTMLInputElement | null>(null);

  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const leadSuccess = useSelector(
    (state: any) => state.nurture.leadCreateSuccess
  );
  const leadLoading = useSelector(
    (state: any) => state.nurture.leadCreateLoading
  );
  const [dataForEnquiry, setDataForEnquiry] = useState<any>();
  const [formDetails, setFormDetails] = useState<any>();
  const [responseId, setResponseId] = useState("");
  const [emailForQuery, setEmailForQuery] = useState("");
  const [showAddLeadWideView, setAddLeadWideView] = useState(false);
  const { leadCreatedId } = useSelector((state: any) => state.nurture);
  const [formId, setFormId] = useState("");

  React.useEffect(() => {
    if (leadSuccess !== true && leadSuccess !== false) {
      if (leadCreatedId === null || leadCreatedId === undefined) {
        setShowEnquiryModal(false);
      }
    }
  }, [leadSuccess, setShowEnquiryModal]);

  React.useEffect(() => {
    if (leadCreatedId && leadSuccess === "Converted lead is created.") {
      setAddLeadWideView(true);
      dispatch(
        setToastNotification({
          message: String("Please add the payment details for conversion"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  }, [leadSuccess, setAddLeadWideView, leadCreatedId]);

  React.useEffect(() => {
    const regex = /^Congratulations! (.*) is a new Lead\.$/;
    if (regex.test(leadSuccess)) {
      const queryParams = new URLSearchParams(window.location.search);
      const formId = queryParams.get("webformId");
      const userFromLocalStorage = authUser?.institute[0];

      const data: any = {
        instituteId: userFromLocalStorage?.instituteId,
        formId: formId,
        branchId: "",
        limit: 15,
        lastEvaluatedKey: "",
        lastEvaluatedSortKey: "",
      };

      GetFormResponse(data);
      dispatch<any>(resetLeadCreateSuccess());
    }
  }, [leadSuccess]);

  const filteredBranchesList = hasAllBranchAccess
    ? branchList?.map((d: any) => {
        return {
          name: d?.branchName,
          branchId: d?.id,
          value: "BRANCH_SPECIFIC",
        };
      })
    : branchList
        ?.filter(
          (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
        )
        .map((d: any) => {
          return {
            name: d?.branchName,
            branchId: d?.id,
            value: "BRANCH_SPECIFIC",
          };
        });
  const safeFilteredBranchList = filteredBranchesList || [];
  const typeArray = [
    { name: "Universal", branchId: 0, value: "UNIVERSAL" },
    ...safeFilteredBranchList,
  ];

  const GetAllWebformsApiCall = useCallback(
    (instituteId: string, branchId: string) => {
      if (instituteId && branchId) {
        const data: webformsData = {
          instituteId: instituteId,
          branchId: branchId,
          limit: 8,
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        };

        GetAllWebform(data);
      }
    },
    []
  );

  const GetFormResponsesApiCall = useCallback(
    (instituteId: string, formId: string, branchId: string) => {
      if (instituteId && formId) {
        const data: formResponseData = {
          instituteId: instituteId,
          formId: formId,
          branchId: "",
          limit: 15,
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        };

        GetFormResponse(data);
      }
    },
    []
  );

  const handleOpenWebformResponse = useCallback((id: string) => {
    const userFromLocalStorage = authUser?.institute[0];

    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;

    if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else if (!hasAllBranchAccess) {
      branchId = authUser?.institute?.[0]?.branchId;
    } else {
      branchId = "";
    }
    if (userFromLocalStorage) {
      GetFormResponsesApiCall(userFromLocalStorage?.instituteId, id, "");
      GetWebformForInstitute(userFromLocalStorage?.instituteId, id);
    }
  }, []);

  const handleNextForms = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterFormArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterFormArray?.lastEvaluatedKeyReceived?.GSI1SK?.S
    );
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;
    if (webformType === "UNIVERSAL") {
      branchId = "ALL";
    } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else {
      branchId = "";
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: lastEvaluatedKey,
      lastEvaluatedSortKey: lastEvaluatedSortKey,
    };
    if (lastEvaluatedKey) {
      GetAllWebforms(data)
        .then((res: any) => {
          setHasMoreForm(true);
          setFilterFormArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  //Form respons

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.GSI6SK?.S
    );
    const formId = selectWebform?.id;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;
    if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else if (webformType === "UNIVERSAL" && !hasAllBranchAccess) {
      branchId = authUser?.institute?.[0]?.branchId;
    } else {
      branchId = "";
    }
    const data: formResponseData = {
      instituteId: userFromLocalStorage?.instituteId,
      formId: selectWebform?.id,
      limit: 15,
      branchId: "",
      lastEvaluatedKey: lastEvaluatedKey,
      lastEvaluatedSortKey: lastEvaluatedSortKey,
    };
    if (lastEvaluatedKey) {
      GetWebformResponse(data)
        .then((res: any) => {
          setHasMoreData(true);
          setFilterArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  const findNamesbyId = (fieldName: any, id: string) => {
    if (fieldName === "COURSE" && id) {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return courseName && courseName[0]?.courseName;
    } else if (fieldName === "BATCH" && id) {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );

      return batchName && batchName[0]?.batchName;
    } else if (fieldName === "BRANCH" && id) {
      const branchName =
        branchList &&
        branchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return branchName && branchName[0]?.branchName;
    }
  };

  const findItembyId = (fieldName: any, id: string) => {
    if (fieldName === "COURSE" && id) {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return courseName && courseName[0];
    } else if (fieldName === "BATCH" && id) {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return batchName && batchName[0];
    } else if (fieldName === "BRANCH" && id) {
      const branchName =
        branchList &&
        branchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return branchName && branchName[0];
    }
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    if (event.target.value.trim() === "") {
      queryParams.delete("webformSearchTerm");
    } else {
      queryParams.set("webformSearchTerm", event.target.value);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleCheckboxToggle = (contactId: string) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        setSelectedCourse(null);
        setSelectedBranch(null);
        setSelectedBatch(null);
        setSelectedAssignee(null);
        const updatedContacts = prevSelectedContacts.filter(
          (id) => id !== contactId
        );
        setIsCheckboxChecked(updatedContacts.length > 0);
        return updatedContacts;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const handleCourseToggle = (row: any) => {
    setSelectedCourseForMoving((prevSelectedCourse: any) => {
      if (prevSelectedCourse.includes(row)) {
        setSelectedCourse(null);
        setSelectedBranch(null);
        setSelectedBatch(null);
        setSelectedAssignee(null);
        const updatedCourses = prevSelectedCourse?.filter(
          (ds: any) => ds?.id !== row?.id
        );
        setIsCheckboxChecked(updatedCourses.length > 0);
        return updatedCourses;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedCourse, row];
      }
    });
  };

  const filteredBatches =
    batchList &&
    batchList?.filter((batch: any) => {
      const courseMatch =
        selectedCourse?.id === "" || batch.courseId === selectedCourse?.id;
      const branchMatch =
        selectedBranch?.id === "" || batch.branchId === selectedBranch?.id;
      const regularBatch =
        batch.batchConductType === "REGULAR_BATCH" &&
        batch.batchStatus !== "COMPLETED" &&
        batch.status !== "INACTIVE";

      return courseMatch && branchMatch && regularBatch;
    });

  const handleMoveCourse = () => {
    const studentData = selectedCourseForMoving?.map((item: any) => {
      return {
        studentFirstName: item?.firstName?.replace(/[^a-zA-Z]/g, ""),
        studentEmail: item?.email,
        studentLastName: item?.lastName?.replace(/[^a-zA-Z]/g, ""),
        studentContact:
          item?.contactNumber?.toString()?.length === 10
            ? `91${item?.contactNumber}`
            : `${item?.contactNumber}`,
        contactCountryCode: "91",
        studentContactId: "",
        otherDetails: [],
        webformId: `WEBFORM_ID#${item?.webformId}#STUDENT_CONTACT_ID#${item?.id}`,
      };
    });
    const userFromLocalStorage = authUser?.institute[0];
    const payloadForContactsAddEnquiry = {
      studentList: studentData,
      createdByName: `${authUser?.firstName} ${authUser?.lastName}`,
      status: "CREATED",
      createdById: authUser?.institute?.[0]?.id,
      messageId: "",
      courseId: selectedCourse?.id,
      courseName: selectedCourse?.courseName,
      instituteId: userFromLocalStorage?.instituteId,
      instituteName: userFromLocalStorage?.instituteName,
      assignedToId: seletedAssignee?.id,
      assignedToName: `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`,
      createdTime: 0,
      enquiryPriority: "NORMAL",
      enquirySource: "WEBFORM",
      enquiryStatus: "NEW_ENQUIRY",
      branchName: selectedBranch?.branchName,
      batchId: selectedBatch?.id,
      branchId: selectedBranch?.id,
      batchName: selectedBatch?.batchName,
    };
    BulkLeadCreation(payloadForContactsAddEnquiry);
  };

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenCoursePopover = (event: any) => {
    setCourseAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseCoursePopover = () => {
    setCourseAnchorEl(null);
    setSearchCourse("");
  };

  const handleOpenBranchPopover = (event: any) => {
    setBranchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBranchPopover = () => {
    setBranchAnchorEl(null);
    setSearchBranch("");
  };

  const handleOpenBatchPopover = (event: any) => {
    setBatchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBatchPopover = () => {
    setBatchAnchorEl(null);
    setSearchBatch("");
  };

  const handleOpenAssigneePopover = (event: any) => {
    setAssigneeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseAssigneePopover = () => {
    setAssigneeAnchorEl(null);
    setSearchAssignee("");
  };

  const handleFormSearch = (event: any) => {
    setSearchForm(event.target.value);
  };

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const handleBatchSearch = (event: any) => {
    setSearchBatch(event.target.value);
  };

  const handleAssigneeSearch = (event: any) => {
    setSearchAssignee(event.target.value);
  };

  const handleFormSelect = useCallback(
    (emp: any) => {
      setSelectWebform(emp);
      handleClosePopover();
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("webformId", emp?.id);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      handleOpenWebformResponse(emp.id);
    },
    [setSelectWebform, handleClosePopover]
  );

  const handleCloseFormTypePopover = () => {
    setFormTypeAnchorEl(null);
  };
  const handleFormTypeSelect = useCallback((item: any) => {
    setFormTypeSelected(item);
    setSelectWebform(null);
    setSelectedFormName(null);
    dispatch(resetSingleFormData());

    const queryParams = new URLSearchParams(window.location.search);

    if (item?.value) {
      queryParams.set("formType", item.value);
      if (item.value === "BRANCH_SPECIFIC" && item.branchId) {
        queryParams.set("formBranch", item.branchId);
      } else {
        queryParams.delete("formBranch");
      }
    }
    queryParams.delete("webformId");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);

    const userFromLocalStorage = authUser?.institute[0];

    let branchId: string;
    if (item?.value === "UNIVERSAL") {
      branchId = "ALL";
    } else if (item?.value === "BRANCH_SPECIFIC" && item?.branchId) {
      branchId = item?.branchId;
    } else {
      branchId = "";
    }

    GetAllWebformsApiCall(userFromLocalStorage?.instituteId, branchId);
    handleCloseFormTypePopover();
  }, []);

  const handleCourseSelect = (emp: any) => {
    setSelectedCourse(emp);
    handleCloseCoursePopover();
    if (selectedBatch && selectedBatch?.courseId !== emp.id) {
      setSelectedBatch(null);
    }
    if (selectedBranch && selectedBranch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBranchSelect = (emp: any) => {
    setSelectedBranch(emp);
    setSelectedAssignee(null);
    handleCloseBranchPopover();
    if (selectedBatch && selectedBatch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBatchSelect = (emp: any) => {
    setSelectedBatch(emp);
    handleCloseBatchPopover();
  };

  const handleAssigneeSelect = (emp: any) => {
    setSelectedAssignee(emp);
    handleCloseAssigneePopover();
  };

  const deleteContacts = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const contactsToBeDeleted = selectedContacts?.filter(
      (d: any) => d !== false
    );
    if (contactsToBeDeleted && contactsToBeDeleted?.length <= 25) {
      const data = {
        instituteId:
          userFromLocalStorage?.instituteId &&
          userFromLocalStorage?.instituteId,
        studentContactEntryIds: contactsToBeDeleted,
        isFromWebform: true,
        enquirySource: "WEBFORM",
      };
      DeleteWebformResponse(data);
    } else {
      dispatch(
        setToastNotification({
          message: String("You cannot Delete more than 25 contacts"),
          type: "error",
          snackOpen: true,
        })
      );
    }
  };

  //   const courseArray =
  //     filterArray &&
  //     webformContacts
  //       ?.flatMap((item: any) => item?.otherDetails || [])
  //       ?.filter((detail: any) => detail?.course);

  //   const branchArray =
  //     filterArray &&
  //     webformContacts
  //       ?.flatMap((item: any) => item?.otherDetails || [])
  //       ?.filter((detail: any) => detail?.branch);

  //   const batchArray =
  //     filterArray &&
  //     webformContacts
  //       ?.flatMap((item: any) => item?.otherDetails || [])
  //       ?.filter((detail: any) => detail?.batch);

  //   const courseListForFilter = courseList?.filter(
  //     (courseItem: any) =>
  //       courseArray &&
  //       courseArray?.some((detail: any) => detail.course === courseItem.id)
  //   );
  //   const branchListForFilter = branchList?.filter(
  //     (branchItem: any) =>
  //       branchArray &&
  //       branchArray?.some((detail: any) => detail.branch === branchItem.id)
  //   );
  //   const batchListForFilter = batchList?.filter(
  //     (batchItem: any) =>
  //       batchArray &&
  //       batchArray?.some((detail: any) => detail.batch === batchItem.id)
  //   );

  const filteredCourses = courseList?.filter((item: any) => {
    const searchTermMatch =
      item?.courseName
        ?.toLowerCase()
        ?.indexOf(courseSearchTerm?.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const filteredBranches = filteredBranchList?.filter((item: any) => {
    const searchTermMatch =
      item?.branchName
        ?.toLowerCase()
        ?.indexOf(branchSearchTerm?.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const filteredBatch = batchList?.filter((item: any) => {
    const searchTermMatch =
      item?.batchName
        ?.toLowerCase()
        ?.indexOf(batchSearchTerm?.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const displayedCourses = showAllCourses
    ? courseList && filteredCourses
    : courseList && filteredCourses?.slice(0, 5);

  const displayedBranches = showAllBranches
    ? branchList && filteredBranches
    : branchList && filteredBranches?.slice(0, 5);

  const displayedBatches = showAllBatches
    ? batchList && filteredBatch
    : batchList && filteredBatch?.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };
  const handleShowMoreBatches = () => {
    setShowAllBatches(!showAllBatches);
  };
  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  //   const filteredCourse =
  //     courseListForFilter &&
  //     courseListForFilter?.filter((item: any) => {
  //       if (
  //         item?.courseName?.toLowerCase().includes(courseSearchTerm.toLowerCase())
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });

  //   const filteredBranch =
  //     branchListForFilter &&
  //     branchListForFilter?.filter((item: any) => {
  //       if (
  //         item?.branchName?.toLowerCase().includes(branchSearchTerm.toLowerCase())
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });

  //   const filteredBatch =
  //     batchListForFilter &&
  //     batchListForFilter?.filter((item: any) => {
  //       if (
  //         item?.batchName?.toLowerCase().includes(batchSearchTerm.toLowerCase())
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });

  //   const displayedCourses = showAllCourses
  //     ? courseListForFilter && filteredCourse
  //     : courseListForFilter && filteredCourse?.slice(0, 5);

  //   const displayedBranches = showAllBranches
  //     ? branchListForFilter && filteredBranch
  //     : branchListForFilter && filteredBranch?.slice(0, 5);

  //   const displayedBatches = showAllBatches
  //     ? batchListForFilter && filteredBatch
  //     : batchListForFilter && filteredBatch?.slice(0, 5);

  const filteredContactList =
    getFormResponseData && filterArray?.returnData
      ? webformContacts?.filter((contact: any) => {
          const searchTermLower = searchText?.toLowerCase();
          const fullName = `${contact?.firstName} ${contact?.lastName}`;

          const nameMatch = fullName?.toLowerCase().includes(searchTermLower);
          const emailMatch = contact?.email
            .toLowerCase()
            .includes(searchTermLower);
          const phoneMatch =
            searchTermLower === "" ||
            (contact?.contactNumber &&
              contact?.contactNumber?.toString()?.includes(searchTermLower));

          const courseStatusIsChecked =
            filterCheckedCourse.length === 0 ||
            filterCheckedCourse.some((item: any) =>
              contact?.otherDetails?.some(
                (detail: any) => detail.course === item.id
              )
            );
          const branchStatusIsChecked =
            filterCheckedBranch.length === 0 ||
            filterCheckedBranch.some((item: any) =>
              contact?.otherDetails?.some(
                (detail: any) => detail.branch === item.id
              )
            );

          const batchStatusIsChecked =
            filterCheckedBatch.length === 0 ||
            filterCheckedBatch.some((item: any) =>
              contact?.otherDetails?.some(
                (detail: any) => detail.batch === item.id
              )
            );

          const tagIsChecked =
            filterCheckedTag.length === 0 ||
            filterCheckedTag.some((item: any) => {
              const lowercaseTag = (contact?.tag || "").toLowerCase();
              const lowercaseItemTag = item.tagName.toLowerCase();
              if (lowercaseItemTag === "lead") {
                return lowercaseTag === "lead";
              } else if (lowercaseItemTag === "contact") {
                return (
                  lowercaseTag === "contact" ||
                  lowercaseTag === "duplicate" ||
                  lowercaseTag === "lead_exists" ||
                  lowercaseTag === "twin_lead"
                );
              }
              return false;
            });

          return (
            (nameMatch || emailMatch || phoneMatch) &&
            courseStatusIsChecked &&
            branchStatusIsChecked &&
            batchStatusIsChecked &&
            tagIsChecked
          );
        })
      : [];

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    const courseToAdd: any = { id, courseName };
    if (checkedCourses.some((branch) => branch.id === id)) {
      setCheckedCourses(checkedCourses.filter((branch) => branch.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, courseToAdd]);
    }
  };

  const clearCheckedCourse = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: any = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleFilterBatchChecked = (id: string, batchName: string) => {
    const batchToAdd: any = { id, batchName };
    if (checkedBatches.some((branch) => branch.id === id)) {
      setCheckedBatches(checkedBatches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBatches([...checkedBatches, batchToAdd]);
    }
  };

  const clearCheckedBatch = (id: string) => {
    setCheckedBatches(checkedBatches.filter((item) => item.id !== id));
  };

  const uniqueTags = filterArray?.returnData?.filter(
    (obj: any, index: any, self: any) =>
      index === self?.findIndex((el: any) => el?.tag === obj?.tag)
  );

  const displayedTags = filterArray && uniqueTags;

  const handleFilterTagsChecked = (id: string, tagName: string) => {
    const branchToAdd: any = { id, tagName };
    if (checkedTags.some((branch: any) => branch.id === id)) {
      setCheckedTags(checkedTags.filter((branch: any) => branch.id !== id));
    } else {
      setCheckedTags([...checkedTags, branchToAdd]);
    }
  };

  const clearCheckedTags = (id: string) => {
    setCheckedTags(checkedTags.filter((item) => item.id !== id));
  };

  const handleFilterLeads = React.useCallback(
    (courseArray: any, branchArray: any, batchArray: any, tagsArray: any) => {
      setFilterCheckedCourse(courseArray);
      setFilterCheckedBranch(branchArray);
      setFilterCheckedBatch(batchArray);
      setFilterCheckedTag(tagsArray);

      const queryParams = new URLSearchParams(window.location.search);
      if (courseArray && courseArray.length > 0) {
        const courseParam = courseArray
          .map((course: any) => course.id)
          .join("|");
        queryParams.set("filteredCourses", courseParam);
      } else {
        queryParams.delete("filteredCourses");
      }

      if (branchArray && branchArray.length > 0) {
        const courseParam = branchArray
          .map((course: any) => course.id)
          .join("|");
        queryParams.set("filteredBranches", courseParam);
      } else {
        queryParams.delete("filteredBranches");
      }

      if (batchArray && batchArray.length > 0) {
        const courseParam = batchArray
          .map((course: any) => course.id)
          .join("|");
        queryParams.set("filteredBatches", courseParam);
      } else {
        queryParams.delete("filteredBatches");
      }

      if (tagsArray && tagsArray.length > 0) {
        const courseParam = tagsArray.map((course: any) => course.id).join("|");
        queryParams.set("filteredTags", courseParam);
      } else {
        queryParams.delete("filteredTags");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },

    [
      setFilterCheckedCourse,
      setFilterCheckedBranch,
      setFilterCheckedBatch,
      setFilterCheckedTag,
    ]
  );

  const handleFilterApply = () => {
    handleFilterLeads(
      checkedCourses,
      checkedBranches,
      checkedBatches,
      checkedTags
    );
    setFilterApplied(
      checkedCourses?.length === 0 &&
        checkedBatches?.length === 0 &&
        checkedBranches?.length === 0 &&
        checkedTags?.length === 0
        ? false
        : true
    );
  };

  const handleResetClick = React.useCallback(() => {
    handleFilterLeads([], [], [], []);
    setCheckedBatches([]);
    setCheckedBranches([]);
    setCheckedCourses([]);
    setCheckedTags([]);
    setFilterApplied(false);
  }, [
    setCheckedBatches,
    setCheckedBranches,
    setCheckedCourses,
    setCheckedTags,
    setFilterApplied,
    handleFilterLeads,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranch);
    setCheckedBatches(filterCheckedBatch);
    setCheckedCourses(filterCheckedCourse);
    setCheckedTags(filterCheckedTag);
  }, [
    filterCheckedBatch,
    filterCheckedTag,
    filterCheckedBranch,
    filterCheckedCourse,
  ]);

  const filteredValue = (
    <>
      {filterCheckedCourse?.length > 0 &&
        filterCheckedCourse?.map((item: any) => (
          <div key={item.id} className="filteredDiv">
            {item.courseName}
          </div>
        ))}
      {filterCheckedBranch?.length > 0 &&
        filterCheckedBranch?.map((item: any) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedBatch?.length > 0 &&
        filterCheckedBatch?.map((item: any) => (
          <div key={item.id} className="filteredDiv">
            {item.batchName}
          </div>
        ))}
      {filterCheckedTag?.length > 0 &&
        filterCheckedTag?.map((item: any) => (
          <div key={item.id} className="filteredDiv">
            {item.tagName}
          </div>
        ))}
    </>
  );

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleFilterLeads([], [], [], []);
    setCheckedBranches([]);
    setCheckedCourses([]);
    setCheckedBatches([]);
    setCheckedTags([]);
    setFilterApplied(false);
    setIsOpenFilter(false);
  };

  const handleOpenFormTypePopover = (event: any) => {
    setFormTypeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  React.useEffect(() => {
    setFilterFormArray(getAllFormByInstituteData);
    const userFromLocalStorage = authUser?.institute[0];
    const formId = getAllFormByInstituteData?.returnData?.[0]?.id;

    // if (userFromLocalStorage && formId) {
    //   GetFormResponsesApiCall(userFromLocalStorage?.instituteId, formId, "");
    //   GetWebformForInstitute(userFromLocalStorage?.instituteId, formId);
    // }
  }, [getAllFormByInstituteData]);

  React.useEffect(() => {
    setFilterArray(getFormResponseData);
  }, [getFormResponseData]);

  React.useEffect(() => {
    if (getFormByInstituteData) {
      setFieldsName(
        getFormByInstituteData?.fields?.map((d: any) => {
          return { fieldName: d?.fieldName, fieldType: d?.DBModel };
        })
      );
      setSelectedFormName(getFormByInstituteData?.formName);
      setSelectedFormId(getFormByInstituteData?.id);
    }
  }, [getFormByInstituteData]);
  React.useEffect(() => {
    if (
      filterFormArray &&
      filterFormArray?.lastEvaluatedKeyReceived &&
      filterFormArray?.lastEvaluatedKeyReceived?.SK !== null &&
      searchForm?.length === 0
    ) {
      setHasMoreForm(true);
    } else {
      setHasMoreForm(false);
    }
  }, [filterFormArray, searchForm]);

  React.useEffect(() => {
    if (
      filterArray &&
      filterArray?.lastEvaluatedKeyReceived &&
      filterArray?.lastEvaluatedKeyReceived?.SK !== null &&
      searchText.length === 0 &&
      checkedCourses?.length === 0 &&
      checkedBranches?.length === 0 &&
      checkedBatches?.length === 0 &&
      checkedTags?.length === 0
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [
    filterArray,
    searchText,
    checkedCourses,
    checkedBranches,
    checkedBatches,
    checkedTags,
  ]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformId = urlSearchParams.get("webformId");
    const courseParam = urlSearchParams.get("filteredCourses");
    const branchParam = urlSearchParams.get("filteredBranches");
    const batchParam = urlSearchParams.get("filteredBatches");
    const tagParam = urlSearchParams.get("filteredTags");
    const webformSearchParam = urlSearchParams.get("webformSearchTerm");

    const form = webformForms?.find((item: any) => item.id === webformId);

    if (webformId && form) {
      setSelectWebform(form);
      setSelectedFormId(form?.id);
    }

    if (webformSearchParam) {
      setSearchText(webformSearchParam);
    }

    if (courseParam && courseList) {
      const courseIds = courseParam?.split("|");

      const selectedCourse = courseIds?.map((id) => {
        const course = courseList?.find((course: any) => course.id === id);
        return course ? { id: course.id, courseName: course.courseName } : null;
      });

      setCheckedCourses(selectedCourse);
    }
    if (branchParam && branchList) {
      const branchIds = branchParam?.split("|");

      const selectedBranches = branchIds?.map((id) => {
        const branch = branchList?.find((branch: any) => branch?.id === id);
        return branch ? { id: branch.id, branchName: branch.branchName } : null;
      });

      setCheckedBranches(selectedBranches);
    }

    if (batchParam && batchList) {
      const batchIds = batchParam?.split("|");

      const selectedBatches = batchIds?.map((id) => {
        const batch = batchList?.find((batch: any) => batch?.id === id);
        return batch ? { id, batchName: batch.batchName } : null;
      });

      setCheckedBatches(selectedBatches);
    }
    if (tagParam) {
      const tagIds = tagParam?.split("|");

      const selectedTags = tagIds?.map((id) => {
        const tag = [
          { tag: "Contact", id: "1" },
          { tag: "Lead", id: "2" },
        ]?.find((tagItem: any) => tagItem?.id === id);

        return tag ? { id, tagName: tag.tag } : null;
      });
      setCheckedTags(selectedTags);
    }
    if (courseParam || branchParam || batchParam || tagParam) {
      setFilterApplied(true);
    }
  }, [filterFormArray, courseList, branchList]);

  React.useEffect(() => {
    if (bulkLeadCreationSuccess !== true && bulkLeadCreationSuccess !== false) {
      setIsCheckboxChecked(false);
      setSelectedContacts([]);
      setSelectedCourseForMoving([]);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedBatch(null);
      setSelectedAssignee(null);
      dispatch(resetBulkLeadCreationSuccess());
    }
  }, [bulkLeadCreationSuccess]);

  React.useEffect(() => {
    if (deleteWebformResponseSuccess) {
      setOpenDeleteModal(false);
      setIsCheckboxChecked(false);
      setSelectedContacts([]);
      setSelectedCourseForMoving([]);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedBatch(null);
      setSelectedAssignee(null);
      dispatch(resetDeleteWebformResponseSuccess());
      dispatch(resetBulkLeadDeleteSuccess());
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [deleteWebformResponseSuccess]);

  React.useEffect(() => {
    if (selectedCourseForMoving?.length === 0) {
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedBatch(null);
      setSelectedAssignee(null);
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [selectedCourseForMoving]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformId = urlSearchParams.get("webformId");
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;

    if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else if (!hasAllBranchAccess) {
      branchId = authUser?.institute?.[0]?.branchId;
    } else {
      branchId = "";
    }
    if (
      (bulkLeadCreationSuccess !== true && bulkLeadCreationSuccess !== false) ||
      deleteWebformResponseSuccess
    ) {
      if (webformId) {
        GetFormResponsesApiCall(
          userFromLocalStorage?.instituteId,
          webformId,
          ""
        );
      }
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [bulkLeadCreationSuccess, deleteWebformResponseSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    const webformTypes = urlSearchParams.get("webformType");
    const webformBranches = urlSearchParams.get("webformBranch");
    if (
      (webformType === "BRANCH_SPECIFIC" && webformBranch) ||
      (webformTypes === "BRANCH_SPECIFIC" && webformBranches)
    ) {
      const type = typeArray?.find(
        (item: any) =>
          item?.value === "BRANCH_SPECIFIC" &&
          (item?.branchId === webformBranch ||
            item?.branchId === webformBranches)
      );
      setFormTypeSelected(type);
    } else {
      const type = typeArray?.find((item: any) => item?.value === "UNIVERSAL");
      setFormTypeSelected(type);
    }
  }, [branchList]);

  // const reorderFields = (fieldsArray: any) => {
  //   const importantFields = ["name", "phone", "email"];

  //   const firstThree = fieldsArray.filter((field: any) =>
  //     importantFields.includes(field.fieldName)
  //   );

  //   const remainingFields = fieldsArray.filter(
  //     (field: any) => !importantFields.includes(field.fieldName)
  //   );

  //   return [...firstThree, ...remainingFields];
  // };

  const reorderFields = (fieldsArray: any) => {
    const importantFields = ["name", "phone", "email"];

    const firstThree = importantFields
      .map((fieldName) =>
        fieldsArray.find((field: any) => field.fieldName === fieldName)
      )
      .filter((field) => field);

    const remainingFields = fieldsArray.filter(
      (field: any) => !importantFields.includes(field.fieldName)
    );

    return [...firstThree, ...remainingFields];
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "0px 0px 30px 0px",
        }}
      >
        <>
          {!isCheckboxChecked ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "20px",
                padding: "0 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    marginRight: "20px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className={styles.funnelHeadSplitTitle}
                    style={{
                      marginBottom: "5PX",
                      color: "#667085",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Type{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="formType"
                      name="formType"
                      onClick={handleOpenFormTypePopover}
                      value={formTypeSelected ? formTypeSelected?.name : ""}
                      variant="outlined"
                      size="small"
                      placeholder="Select"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root input": {
                          textTransform: "capitalize",
                          color: "#667085",
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        width: "160px",
                      }}
                      inputRef={formTypeFieldRef}
                    />
                    <ListPopover
                      openState={openFormType}
                      anchorState={formTypeAnchorEl}
                      handleClosePopover={handleCloseFormTypePopover}
                      popupList={
                        hasAllBranchAccess
                          ? typeArray
                          : typeArray.filter((d) => d?.value !== "ALL")
                      }
                      handleItemSelect={handleFormTypeSelect}
                      listType={"FORMTYPE"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginRight: "10px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className={styles.funnelHeadSplitTitle}
                    style={{
                      marginBottom: "5PX",
                      color: "#667085",
                      fontSize: "13px",
                    }}
                  >
                    Please select a webform first to show the recorded response.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      className="formControl"
                      sx={{
                        width: isCheckboxChecked === false ? "220px" : "0px",
                      }}
                    >
                      <TextField
                        id="formSelected"
                        name="formSelected"
                        onClick={handleOpenPopover}
                        value={selectedFormName ? selectedFormName : ""}
                        variant="outlined"
                        size="small"
                        placeholder="Select Form"
                        inputRef={formFieldRef}
                        sx={{
                          minWidth: "220px",
                          "& .MuiOutlinedInput-root input": {
                            textTransform: "capitalize",
                            color: "#667085",
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiOutlinedInput-root ": {
                            textTransform: "capitalize",
                          },
                          "& .MuiOutlinedInput-root fieldset": {
                            padding: "8px 14px",
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ListPopoverPagination
                        openState={open}
                        anchorState={anchorEl}
                        handleClosePopover={handleClosePopover}
                        handleListSearch={handleFormSearch}
                        popupList={webformForms?.filter((item: any) =>
                          item?.formName
                            ?.toLowerCase()
                            ?.includes(searchForm?.toLowerCase())
                        )}
                        handleItemSelect={handleFormSelect}
                        listType={"COURSE"}
                        handleNext={handleNextForms}
                        hasMore={hasMoreForm}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <TextField
                  id="reponseSearch"
                  name="reponseSearch"
                  className={styles.webformSearch}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={(e: any) => {
                    handleSearch(e);
                  }}
                  size="small"
                  sx={{
                    width: "380px",
                    "&.MuiOutlinedInput-root": {
                      paddingLeft: "0 !important",
                    },
                    marginRight: "10px",
                  }}
                  type="search"
                />
                <FilterDrawer
                  handleFilterApply={handleFilterApply}
                  handleResetClick={handleResetClick}
                  activeFilter={filterApplied}
                  cancelClick={() => {
                    setIsOpenFilter(true);
                  }}
                  closeSetFilter={handleFilterClose}
                >
                  <div
                    style={{
                      padding: "22px 32px",
                      overflowY: "auto",
                      maxHeight: "calc(100% - 110px)",
                    }}
                  >
                    <FilterChipsWrap>
                      {checkedCourses?.length > 0 &&
                        checkedCourses?.map((item: any) => (
                          <>
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.courseName}
                              clearValue={item?.id}
                              closeFunction={clearCheckedCourse}
                            />
                          </>
                        ))}
                      {checkedBranches?.length > 0 &&
                        checkedBranches?.map((item: any) => (
                          <>
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.branchName}
                              clearValue={item?.id}
                              closeFunction={clearCheckedBranch}
                            />
                          </>
                        ))}
                      {checkedBatches?.length > 0 &&
                        checkedBatches?.map((item: any) => (
                          <>
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.batchName}
                              clearValue={item?.id}
                              closeFunction={clearCheckedBatch}
                            />
                          </>
                        ))}
                      {checkedTags?.length > 0 &&
                        checkedTags?.map((item) => (
                          <FilterChips
                            keyVal={item?.id}
                            chipName={item?.tagName?.toLowerCase()}
                            clearValue={item?.id}
                            closeFunction={clearCheckedTags}
                          />
                        ))}
                    </FilterChipsWrap>
                    {courseList && courseList?.length > 0 && (
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Course" />

                        <FilterSearchField
                          value={courseSearchTerm}
                          onChange={(e: any) => {
                            setCourseSearchTerm(e.target.value);
                          }}
                          placeholder="Search Course"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {courseList &&
                              displayedCourses?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item?.id}
                                  checked={checkedCourses?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterCourseChecked(
                                      item.id,
                                      item.courseName
                                    )
                                  }
                                  label={item?.courseName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreCourses}
                          showAll={showAllCourses}
                          filteredState={filteredCourses}
                        />
                      </FilterTypeWrap>
                    )}
                    {branchList && filteredBranchList?.length > 0 && (
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Branch" />

                        <FilterSearchField
                          value={branchSearchTerm}
                          onChange={(e: any) => {
                            setBranchSearchTerm(e.target.value);
                          }}
                          placeholder="Search Branch"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branchList &&
                              displayedBranches?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedBranches?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBranchChecked(
                                      item.id,
                                      item.branchName
                                    )
                                  }
                                  label={item?.branchName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreBranches}
                          showAll={showAllBranches}
                          filteredState={filteredBranches}
                        />
                      </FilterTypeWrap>
                    )}
                    {batchList && batchList?.length > 0 && (
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Batch" />

                        <FilterSearchField
                          value={batchSearchTerm}
                          onChange={(e: any) => {
                            setBatchSearchTerm(e.target.value);
                          }}
                          placeholder="Search Batch"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {batchList &&
                              displayedBatches?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedBatches?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBatchChecked(
                                      item.id,
                                      item.branchName
                                    )
                                  }
                                  label={item?.batchName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreBatches}
                          showAll={showAllBatches}
                          filteredState={filteredBatch}
                        />
                      </FilterTypeWrap>
                    )}
                    <FilterTypeWrap>
                      <FilterLabel labelValue="Type" />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {filterArray &&
                            [
                              { tag: "Contact", id: "1" },
                              { tag: "Lead", id: "2" },
                            ]?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedTags?.some(
                                  (data: any) => data?.id === item?.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterTagsChecked(item.id, item.tag)
                                }
                                label={item?.tag?.toLowerCase()}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                    </FilterTypeWrap>
                  </div>
                </FilterDrawer>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "20px",
                padding: "0 20px",
              }}
            >
              <div></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div style={{ color: "#667085", fontSize: "14px" }}>
                  Mapping:
                </div>
                <Box
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "4px",
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{
                      "&.MuiGrid-root": {
                        width: "calc(100% + 12px )",
                      },
                    }}
                  >
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenCoursePopover}
                          value={
                            selectedCourse ? selectedCourse?.courseName : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Course"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={courseFieldRef}
                        />
                        <ListPopover
                          openState={openCourse}
                          anchorState={courseAnchorEl}
                          handleClosePopover={handleCloseCoursePopover}
                          handleListSearch={handleCourseSearch}
                          popupList={coursesList?.filter((item: any) =>
                            item?.courseName
                              ?.toLowerCase()
                              ?.includes(searchCourse?.toLowerCase())
                          )}
                          handleItemSelect={handleCourseSelect}
                          listType={"COURSE"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenBranchPopover}
                          value={
                            selectedBranch ? selectedBranch?.branchName : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Branch"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={branchFieldRef}
                        />
                        <ListPopover
                          openState={openBranch}
                          anchorState={branchAnchorEl}
                          handleClosePopover={handleCloseBranchPopover}
                          handleListSearch={handleBranchSearch}
                          popupList={filteredBranchList?.filter((item: any) =>
                            item?.branchName
                              .toLowerCase()
                              .includes(searchBranch?.toLowerCase())
                          )}
                          handleItemSelect={handleBranchSelect}
                          listType={"BRANCH"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenBatchPopover}
                          value={selectedBatch ? selectedBatch?.batchName : ""}
                          variant="outlined"
                          size="small"
                          placeholder="Choose Batch"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={batchFieldRef}
                        />
                        <ListPopover
                          openState={openBatch}
                          anchorState={batchAnchorEl}
                          handleClosePopover={handleCloseBatchPopover}
                          handleListSearch={handleBatchSearch}
                          popupList={filteredBatches?.filter((item: any) =>
                            item?.batchName
                              .toLowerCase()
                              .includes(searchBatch.toLowerCase())
                          )}
                          handleItemSelect={handleBatchSelect}
                          listType={"BATCH"}
                        />
                      </>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "25px",
                        marginTop: "10px",
                      }}
                    />
                    <Grid item>
                      <>
                        <TextField
                          onClick={handleOpenAssigneePopover}
                          value={
                            seletedAssignee
                              ? `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`
                              : ""
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Choose Assignee"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root input": {
                              textTransform: "capitalize",
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                              border: "none",
                              outline: "none",
                              "&:hover fieldset": {
                                border: "none",
                              },
                            },
                            width: "150px",
                          }}
                          inputRef={assigneeFieldRef}
                        />
                        <ListPopover
                          openState={openAssignee}
                          anchorState={assigneeAnchorEl}
                          handleClosePopover={handleCloseAssigneePopover}
                          handleListSearch={handleAssigneeSearch}
                          popupList={
                            combinedAssignees?.filter((item: any) => {
                              const fullName = `${item?.firstName ?? ""} ${
                                item?.lastName ?? ""
                              }`.toLowerCase();
                              return fullName?.includes(
                                searchAssignee?.toLowerCase()
                              );
                            })
                            // ?.filter((user: any) => {
                            //   return (
                            //     user?.leadCardAccess === true &&
                            //     user?.status === "ACTIVE"
                            //   );
                            // })
                          }
                          handleItemSelect={handleAssigneeSelect}
                          listType={"ASSIGNEE"}
                        />
                      </>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleMoveCourse}
                        disabled={
                          selectedCourse === undefined ||
                          selectedBranch === undefined ||
                          selectedCourse === null ||
                          selectedBranch === null ||
                          seletedAssignee === undefined ||
                          seletedAssignee === null ||
                          bulkLeadCreationLoading
                        }
                        sx={{ textTransform: "capitalize" }}
                      >
                        {bulkLeadCreationLoading ? (
                          <CircularProgress size={18} color={"inherit"} />
                        ) : (
                          "Move"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Tooltip
                  arrow
                  title={
                    (page === "WEBFORM" && hasWebformsAccess === false) ||
                    (page === "CONTACTS" && hasContactsAccess === false)
                      ? "Oops! No permission."
                      : ""
                  }
                  sx={{ width: "100%" }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <button
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        border: "1px solid #D0D5DD",
                        padding: "4px",
                        borderRadius: "8px",
                        marginLeft: "10px",
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        background: "#fff",
                        alignItems: "center",
                      }}
                      disabled={
                        (page === "WEBFORM" && hasWebformsAccess === false) ||
                        (page === "CONTACTS" && hasContactsAccess === false)
                      }
                    >
                      <img src={deleteIcon} width="20px" />
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </>

        <>
          {GetAllWebformLoading ||
          getFormResponseLoading ||
          getFormByInstituteLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {filterFormArray?.returnData?.length > 0 ? (
                <>
                  {selectedFormName === null ||
                  selectedFormName === undefined ||
                  !selectedFormName ? (
                    <>
                      <Box
                        style={{
                          border: "1px solid #E3E3E3",
                          width: "100%",
                          padding: "30px",
                          boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                          borderRadius: "6px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Please Select Webform from above dropdown
                        </div>
                      </Box>
                    </>
                  ) : (
                    <>
                      {filterArray &&
                      filterArray?.returnData?.length > 0 &&
                      getFormResponseData?.returnData?.length > 0 &&
                      webformContacts?.length > 0 ? (
                        <TableContainer
                          className={styles.webformTableContainer}
                          sx={{
                            minWidth: 700,
                            borderRadius: "8PX",
                            border: "1px solid  #EAECF0",
                            overflowX: "scroll",
                          }}
                        >
                          <InfiniteScroll
                            //   dataLength={
                            //     filterArray && filterArray?.returnData?.length
                            //   }
                            dataLength={
                              filterArray?.returnData
                                ? webformContacts?.length
                                : getFormResponseData?.returnData?.length
                            }
                            next={handleNextPage}
                            hasMore={hasMoreData}
                            loader={
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <PuffLoader color="#36d7b7" />
                              </Box>
                            }
                            height={"calc(100vh - 200px)"}
                          >
                            <Table
                              sx={{
                                minWidth: 700,
                              }}
                              aria-label="customized table"
                            >
                              <TableHead
                                sx={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: openDeleteModal ? 1 : 999,
                                }}
                              >
                                <TableRow>
                                  <StyledTableCell
                                    className={styles.batchPopupHeadCell}
                                    sx={{ padding: "10px" }}
                                  >
                                    <Checkbox
                                      sx={{
                                        strokeWidth: 1,
                                        color: "rgba(102, 112, 133, 0.30)",
                                        padding: "4px",
                                      }}
                                      checked={
                                        selectedContacts?.length ===
                                        webformContacts?.length
                                      }
                                      onChange={() => {
                                        if (
                                          selectedContacts?.length ===
                                          webformContacts?.length
                                        ) {
                                          setSelectedContacts([]);
                                          setIsCheckboxChecked(false);
                                          setSelectedCourseForMoving([]);
                                          setSelectedCourse(null);
                                          setSelectedBranch(null);
                                          setSelectedBatch(null);
                                          setSelectedAssignee(null);
                                        } else {
                                          const allEmailIds =
                                            webformContacts?.map(
                                              (item: any) =>
                                                item.enquiryId === "" && item.id
                                            );
                                          const allContacts =
                                            webformContacts?.map(
                                              (item: any) =>
                                                item.enquiryId === "" && item
                                            );
                                          setSelectedContacts(allEmailIds);
                                          setIsCheckboxChecked(true);
                                          setSelectedCourseForMoving(
                                            allContacts.filter(
                                              (d: any) => d !== false
                                            )
                                          );
                                        }
                                      }}
                                      disabled={webformContacts?.every(
                                        (item: any) => item.enquiryId !== ""
                                      )}
                                    />
                                  </StyledTableCell>
                                  {reorderFields &&
                                    reorderFields(fieldsName)?.map((d: any) => {
                                      return (
                                        <StyledTableCell
                                          sx={{
                                            minWidth:
                                              d === "name" ? "250px" : "150px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {d?.fieldName?.length > 20
                                            ? `${d?.fieldName?.slice(0, 15)}...`
                                            : d?.fieldName}
                                        </StyledTableCell>
                                      );
                                    })}
                                  <StyledTableCell
                                    sx={{
                                      minWidth: "210px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Received On
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filterArray?.returnData &&
                                  filteredContactList?.map(
                                    (item: any, index: any) => (
                                      <TableRow key={index}>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          sx={{ padding: "10px" }}
                                        >
                                          <Checkbox
                                            name={item?.id}
                                            id={item?.id}
                                            sx={{
                                              strokeWidth: 1,
                                              color:
                                                "rgba(102, 112, 133, 0.30)",
                                              padding: "6px",
                                            }}
                                            checked={selectedContacts?.includes(
                                              item.id
                                            )}
                                            onChange={() => {
                                              handleCheckboxToggle(item.id);
                                              handleCourseToggle(item);
                                            }}
                                            disabled={item.enquiryId !== ""}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                            textTransform: "capitalize",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (item?.enquiryId) {
                                              page === "CONTACTS"
                                                ? navigate(
                                                    `/app/Inbound/Contacts/webformId/${selectedFormId}/response/responsedetail/${
                                                      item.id
                                                    }?email=${
                                                      item?.email
                                                    }&leadExist=${
                                                      item?.tag === "LEAD"
                                                        ? true
                                                        : false
                                                    }${
                                                      item?.contactNumber
                                                        ? `&leadPhone=${item?.contactNumber}`
                                                        : ""
                                                    }`
                                                  )
                                                : navigate(
                                                    `/app/Inbound/Webforms/webformId/${selectedFormId}/response/responsedetail/${
                                                      item.id
                                                    }?email=${
                                                      item?.email
                                                    }&leadExist=${
                                                      item?.tag === "LEAD"
                                                        ? true
                                                        : false
                                                    }${
                                                      item?.contactNumber
                                                        ? `&leadPhone=${item?.contactNumber}`
                                                        : ""
                                                    }`
                                                  );
                                            } else {
                                              setDataForEnquiry(item);
                                              setFormId(item?.webformId);
                                              setResponseId(item?.id);
                                              const isOtherDetailsEmpty =
                                                item &&
                                                Object.keys(item.otherDetails)
                                                  .length === 0;

                                              const transformedData = [
                                                {
                                                  type: "Name",
                                                  value:
                                                    item &&
                                                    item?.firstName +
                                                      " " +
                                                      item?.lastName,
                                                },
                                                {
                                                  type: "Email",
                                                  value: item && item?.email,
                                                },
                                                {
                                                  type: "Contact",
                                                  value:
                                                    item && item?.contactNumber,
                                                },
                                                {
                                                  otherDetails:
                                                    item && item?.otherDetails,
                                                },
                                              ];

                                              const transformedDataWithoutOtherDetails =
                                                [
                                                  {
                                                    type: "Name",
                                                    value:
                                                      item &&
                                                      item?.firstName +
                                                        " " +
                                                        item?.lastName,
                                                  },
                                                  {
                                                    type: "Email",
                                                    value: item && item?.email,
                                                  },
                                                  {
                                                    type: "Contact",
                                                    value:
                                                      item &&
                                                      item?.contactNumber,
                                                  },
                                                ];

                                              setFormDetails(
                                                isOtherDetailsEmpty
                                                  ? transformedDataWithoutOtherDetails
                                                  : transformedData
                                              );
                                              setShowEnquiryModal(true);
                                            }
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "200px !important",
                                              alignItems: "center",
                                            }}
                                          >
                                            {item?.firstName !== "" &&
                                            item?.lastName !== "" &&
                                            item?.firstName?.toString()
                                              ?.length > 15 &&
                                            item?.lastName?.toString()?.length >
                                              15
                                              ? `${item?.firstName?.slice(
                                                  0,
                                                  15
                                                )}... ${item?.lastName?.slice(
                                                  0,
                                                  15
                                                )}...`
                                              : item?.firstName !== "" &&
                                                item?.lastName === "" &&
                                                item?.firstName?.toString()
                                                  ?.length > 15
                                              ? `${item?.firstName?.slice(
                                                  0,
                                                  15
                                                )}...`
                                              : item?.firstName !== "" &&
                                                item?.lastName !== "" &&
                                                item?.firstName?.toString()
                                                  ?.length < 15 &&
                                                item?.lastName?.toString()
                                                  ?.length < 15
                                              ? item?.firstName +
                                                " " +
                                                item?.lastName
                                              : item?.firstName !== "" &&
                                                item?.lastName === "" &&
                                                item?.firstName?.toString()
                                                  ?.length > 15
                                              ? `${item?.firstName?.slice(
                                                  0,
                                                  15
                                                )}...`
                                              : item?.firstName !== "" &&
                                                item?.lastName === "" &&
                                                item?.firstName?.toString()
                                                  ?.length <= 15
                                              ? `${item?.firstName}`
                                              : item?.firstName !== "" &&
                                                item?.lastName !== "" &&
                                                item?.firstName?.toString()
                                                  ?.length <= 15 &&
                                                item?.lastName?.toString()
                                                  ?.length > 15
                                              ? `${
                                                  item?.firstName +
                                                  "  " +
                                                  item?.lastName?.slice(0, 15)
                                                }...`
                                              : ""}
                                            {item?.tag === "LEAD" ? (
                                              <span
                                                style={{
                                                  color: "#11C89B",
                                                  fontSize: "10px",
                                                  background:
                                                    "rgba(17, 200, 155, 0.08)",
                                                  padding: "3px",
                                                  borderRadius: "4px",
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                Lead
                                              </span>
                                            ) : item?.tag === "LEAD_EXISTS" ? (
                                              <span
                                                style={{
                                                  color: "#11C89B",
                                                  fontSize: "10px",
                                                  background:
                                                    "rgba(17, 200, 155, 0.08)",
                                                  padding: "3px",
                                                  borderRadius: "4px",
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                Lead Exists
                                              </span>
                                            ) : item?.tag === "DUPLICATE" ? (
                                              <span
                                                style={{
                                                  color: "#EBA418",
                                                  fontSize: "10px",
                                                  background: "#FBECD0",
                                                  padding: "3px",
                                                  borderRadius: "4px",
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                Duplicate
                                              </span>
                                            ) : item?.tag === "TWIN_LEAD" ? (
                                              <span
                                                style={{
                                                  color: "#11C89B",
                                                  fontSize: "10px",
                                                  background:
                                                    "rgba(17, 200, 155, 0.08)",
                                                  padding: "3px",
                                                  borderRadius: "4px",
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                Twin Lead
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                          }}
                                        >
                                          {/* {item.contactNumber.length === 10
                                            ? `+91 ${item.contactNumber}`
                                            : item.contactNumber.length > 10
                                            ? `+${item.contactNumber.substring(
                                                0,
                                                2
                                              )} ${item.contactNumber.substring(
                                                2
                                              )}`
                                            : "-"} */}
                                          {item.contactNumber
                                            ? `+${item.contactNumber}`
                                            : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                          }}
                                        >
                                          {item?.email?.toString()?.length > 15
                                            ? `${item?.email?.slice(0, 15)}...`
                                            : item?.email
                                            ? item?.email
                                            : "-"}
                                        </StyledTableCell>
                                        {/* <>
                                          {item?.otherDetails &&
                                          [
                                            ...item?.otherDetails,
                                            {
                                              Received: item?.createdTime,
                                            },
                                          ]?.length > 0
                                            ? fieldsName
                                                ?.filter((extras: any) => {
                                                  return ![
                                                    "name",
                                                    "phone",
                                                    "email",
                                                  ].includes(extras?.fieldName);
                                                })
                                                ?.map(
                                                  (
                                                    fieldName: any,
                                                    index: number
                                                  ) => (
                                                    <StyledTableCell
                                                      key={index}
                                                      align="left"
                                                      sx={{
                                                        color: "#667085",
                                                        textTransform:
                                                          "capitalize",
                                                        // padding: "10px",
                                                      }}
                                                    >
                                                      {[
                                                        ...item?.otherDetails,
                                                        {
                                                          Received:
                                                            item?.createdTime,
                                                        },
                                                      ]?.map((d: any) => {
                                                        return d[
                                                          fieldName?.fieldName
                                                        ]?.toString()?.length >
                                                          15 &&
                                                          (fieldName?.fieldType ===
                                                            "COURSE" ||
                                                            fieldName?.fieldType ===
                                                              "BRANCH" ||
                                                            fieldName?.fieldType ===
                                                              "BATCH") ? (
                                                          // `a${findNamesbyId(
                                                          //     fieldName?.fieldType,
                                                          //     d[
                                                          //       fieldName
                                                          //         ?.fieldName
                                                          //     ]
                                                          //   )
                                                          //     ?.toString()
                                                          //     ?.slice(
                                                          //       0,
                                                          //       15
                                                          //     )}...`
                                                          <>
                                                            {fieldName &&
                                                            d[
                                                              fieldName
                                                                .fieldName
                                                            ]
                                                              ? (() => {
                                                                  const result =
                                                                    findNamesbyId(
                                                                      fieldName.fieldType,
                                                                      d[
                                                                        fieldName
                                                                          .fieldName
                                                                      ]
                                                                    )?.toString();

                                                                  return result &&
                                                                    result.length >
                                                                      15
                                                                    ? result?.slice(
                                                                        0,
                                                                        15
                                                                      ) + "..."
                                                                    : result ||
                                                                        "-";
                                                                })()
                                                              : "-"}
                                                          </>
                                                        ) : d[
                                                            fieldName?.fieldName
                                                          ]?.toString()
                                                            ?.length <= 15 &&
                                                          (fieldName?.fieldType ===
                                                            "COURSE" ||
                                                            fieldName?.fieldType ===
                                                              "BRANCH" ||
                                                            fieldName?.fieldType ===
                                                              "BATCH") ? (
                                                          `${
                                                            findNamesbyId(
                                                              fieldName?.fieldType,
                                                              d[
                                                                fieldName
                                                                  ?.fieldType
                                                              ]
                                                            ) !== undefined
                                                              ? findNamesbyId(
                                                                  fieldName?.fieldType,
                                                                  d[
                                                                    fieldName
                                                                      ?.fieldType
                                                                  ]
                                                                )
                                                              : "-"
                                                          }`
                                                        ) : d[
                                                            fieldName?.fieldName
                                                          ]?.toString()
                                                            ?.length > 15 ? (
                                                          `${d[
                                                            fieldName?.fieldName
                                                          ]
                                                            ?.toString()
                                                            ?.slice(0, 15)}...`
                                                        ) : d[
                                                            fieldName?.fieldName
                                                          ]?.toString()
                                                            ?.length <= 15 &&
                                                          (fieldName?.fieldType ===
                                                            "COURSE" ||
                                                            fieldName?.fieldType ===
                                                              "BRANCH" ||
                                                            fieldName?.fieldType ===
                                                              "BATCH") ? (
                                                          `"d"${findNamesbyId(
                                                            fieldName?.fieldType,
                                                            d[
                                                              fieldName
                                                                ?.fieldName
                                                            ]
                                                          )}`
                                                        ) : fieldName?.fieldType ===
                                                          "time" ? (
                                                          moment(
                                                            d[
                                                              fieldName
                                                                ?.fieldName
                                                            ]
                                                          )?.format(
                                                            "MMM Do YYYY, hh:mm A"
                                                          )
                                                        ) : (
                                                          d[
                                                            fieldName?.fieldName
                                                          ]
                                                        );
                                                      })}
                                                    </StyledTableCell>
                                                  )
                                                )
                                            : ""}
                                        </> */}
                                        <>
                                          {item?.otherDetails &&
                                          [
                                            ...item?.otherDetails,
                                            {
                                              Received: item?.createdTime,
                                            },
                                          ]?.length > 0
                                            ? fieldsName
                                                ?.filter((extras: any) => {
                                                  return ![
                                                    "name",
                                                    "phone",
                                                    "email",
                                                  ].includes(extras?.fieldName);
                                                })
                                                ?.map(
                                                  (
                                                    fieldName: any,
                                                    index: number
                                                  ) => (
                                                    <>
                                                      <StyledTableCell
                                                        key={index}
                                                        align="left"
                                                        sx={{
                                                          color: "#667085",
                                                          textTransform:
                                                            "capitalize",
                                                          padding: "10px",
                                                        }}
                                                      >
                                                        {[
                                                          ...item?.otherDetails,
                                                          {
                                                            Received:
                                                              item?.createdTime,
                                                          },
                                                        ]?.map((d: any) => {
                                                          if (
                                                            d[
                                                              fieldName
                                                                ?.fieldName
                                                            ]?.toString()
                                                              ?.length > 15 &&
                                                            (fieldName?.fieldType ===
                                                              "COURSE" ||
                                                              fieldName?.fieldType ===
                                                                "BRANCH" ||
                                                              fieldName?.fieldType ===
                                                                "BATCH")
                                                          ) {
                                                            return (
                                                              <>
                                                                {fieldName &&
                                                                  d[
                                                                    fieldName
                                                                      .fieldName
                                                                  ] &&
                                                                  (() => {
                                                                    const result =
                                                                      findNamesbyId(
                                                                        fieldName.fieldType,
                                                                        d[
                                                                          fieldName
                                                                            .fieldName
                                                                        ]
                                                                      )?.toString();
                                                                    return result &&
                                                                      result.length >
                                                                        15
                                                                      ? result?.slice(
                                                                          0,
                                                                          15
                                                                        ) +
                                                                          "..."
                                                                      : result ||
                                                                          "-";
                                                                  })()}
                                                              </>
                                                            );
                                                          } else if (
                                                            d[
                                                              fieldName
                                                                ?.fieldName
                                                            ]?.toString()
                                                              ?.length <= 15 &&
                                                            (fieldName?.fieldType ===
                                                              "COURSE" ||
                                                              fieldName?.fieldType ===
                                                                "BRANCH" ||
                                                              fieldName?.fieldType ===
                                                                "BATCH")
                                                          ) {
                                                            return `${
                                                              findNamesbyId(
                                                                fieldName?.fieldType,
                                                                d[
                                                                  fieldName
                                                                    ?.fieldType
                                                                ]
                                                              ) !== undefined
                                                                ? findNamesbyId(
                                                                    fieldName?.fieldType,
                                                                    d[
                                                                      fieldName
                                                                        ?.fieldType
                                                                    ]
                                                                  )
                                                                : "-"
                                                            }`;
                                                          } else {
                                                            return d[
                                                              fieldName
                                                                ?.fieldName
                                                            ]?.toString()
                                                              ?.length > 15
                                                              ? `${d[
                                                                  fieldName
                                                                    ?.fieldName
                                                                ]
                                                                  ?.toString()
                                                                  ?.slice(
                                                                    0,
                                                                    15
                                                                  )}...`
                                                              : d[
                                                                  fieldName
                                                                    ?.fieldName
                                                                ];
                                                          }
                                                        })}
                                                      </StyledTableCell>
                                                    </>
                                                  )
                                                )
                                            : ""}
                                        </>

                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          sx={{
                                            color: "#667085",
                                          }}
                                        >
                                          {item?.createdTime
                                            ? getFormattedDate(
                                                item?.createdTime
                                              )
                                            : "-"}
                                        </StyledTableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                            {filteredContactList &&
                            filteredContactList?.length <= 0 ? (
                              <>
                                {searchText?.length > 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",
                                        color: " #667085",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                        justifyContent: "center",
                                        marginTop: "50px",
                                      }}
                                    >
                                      <div style={{ color: " #142C44" }}>
                                        No results found.
                                      </div>
                                      Try another search
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      color: " #667085",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "2px",
                                      justifyContent: "center",
                                      marginTop: "50px",
                                    }}
                                  >
                                    <div style={{ color: " #142C44" }}>
                                      No results found.
                                    </div>
                                    Try another Filter
                                  </div>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                          </InfiniteScroll>
                        </TableContainer>
                      ) : (
                        <>
                          <Box
                            style={{
                              marginTop: "200px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#667085",
                                marginBottom: "22px",
                                fontFamily: "Poppins",
                                textAlign: "center",
                              }}
                            >
                              No contacts created yet.
                            </div>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Box
                    style={{
                      marginTop: "200px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                        marginBottom: "22px",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      No Forms Created Yet.
                    </div>
                  </Box>
                </>
              )}
            </>
          )}
        </>

        <RemoveContactsModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          deleteConfirm={deleteContacts}
          loading={deleteWebformResponseLoading}
        />

        <ClearFilter
          open={isOpenFilter}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
          onClose={handleResetClick}
          filteredValue={filteredValue}
        />
      </div>
      {showEnquiryModal && (
        <AddLeadPayment
          leadCreateLoading={leadLoading}
          show={showEnquiryModal}
          selectedValue={dataForEnquiry && dataForEnquiry?.email}
          messageId={""}
          onHide={() => {
            setShowEnquiryModal(false);
          }}
          nurtureAddEnquiry={CreateEnquiry}
          isEmailDetail={false}
          leadCreateSuccess={leadSuccess && leadSuccess}
          isSourceWidth={false}
          isFromWebform={true}
          isFromContacts={false}
          selectedRowDetails={dataForEnquiry && dataForEnquiry}
          responseId={responseId}
          formId={formId}
          formDetails={formDetails}
          findItembyId={findItembyId}
          setEmailForQuery={setEmailForQuery}
          fieldsName={fieldsName}
          page={"WEBFORM"}
          showAddLeadWideView={showAddLeadWideView}
          setAddLeadWideView={setAddLeadWideView}
          leadCreatedId={leadCreatedId}
        />
      )}
    </>
  );
}

export default WebformTab;
