import { Box, Button, Skeleton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./Tasks.module.css";
import CompletedCard from "./CompletedCard/CompletedCard";
import { useNavigate } from "react-router-dom";
import TaskKanbanHeader from "./TaskKanbanHeader/TaskKanbanHeader";
import High from "./Assets/High.svg";
import Normal from "./Assets/Normal.svg";
import Low from "./Assets/Low.svg";
import Urgent from "./Assets/Urgent.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";
import getAccessStatus, {
  checkSubDomainAccess,
} from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { setLeadModalState } from "../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import getTeamMember from "../../../../components/CommonFunctions/CommonFunctions";
import { GetTaskBoard } from "../service/tasks.request";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDescriptionWithMentionedNames } from "../../../../components/CommonFunctions/CommonFunctions";
import {
  setTrigger,
  toggleTaskTrigger,
} from "../../Main/service/taskBoardUpdationSlice";

interface Task {
  id: number;
  title: string;
  state: string;
  date: string;
  taskType: string;
  leadName: string;
  priority: string;
}

interface TaskKanbanProps {
  tasks: Task[];
}

interface CheckedPriorities {
  priority: string;
}
interface CheckedTypes {
  type: string;
}

type Data = {
  instituteId: string;
  startDate: any;
  endDate: any;
  limit: number;
  userId?: any;
  lanesToFetch?: {
    status: string;
    lastEvaluatedKey: string;
    lastEvaluatedSortKey: string;
  }[];
};

export default function TasksKanban(props: any) {
  const {
    tasks,
    UpdateTaskStatus,
    tasksByAssignee,
    GetTasksBoard,
    tasksTypes,
    taskStatusUpdateSuccess,
    GetLeadListByInstituteAndContact,
    CreateTask,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [taskItems, setTaskItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const { userById } = useSelector((state: any) => state.userDetails);

  const hasAllTaskAccess = getAccessStatus(
    "TASKS",
    "ALL_OTHER_USERS'_TASK",
    userById?.rolePermission
  );

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );

  const employees = useSelector((state: any) => state.staff.staffList);

  const {
    editTaskSuccess,
    createTaskSuccess,
    deleteTaskSuccess,
    leadUpdateSuccess,
  } = useSelector((state: any) => state.nurture);

  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    dayjs().unix() * 1000
  );
  const [selectedTeamMember, setSelectedTeamMember] = React.useState<any>([]);
  const [draggedLead, setdraggedLead] = useState<string>("");
  const [draggedLeadTaskType, setdraggedLeadTaskType] = useState<string>("");
  const [draggedTask, setdraggedTask] = useState<any>("");
  const [dropZoneActive, setDropZoneActive] = useState<boolean>(false);

  const [draggedColumn, setdraggedColumn] = useState<string>("");
  const [newDate, setNewDate] = useState<any>(dayjs());

  const filteredTasks =
    tasks && taskItems?.filter((task: any) => task.status !== "COMPLETED");
  // ?.map((task: any) => ({
  //   ...task,
  //   tasks: [...(task?.tasks || [])].sort(
  //     (a: any, b: any) => a.date - b.date
  //   ), // Create a new array and sort it by date
  // }));
  // const completedTasks =
  //   tasksByAssignee &&
  //   tasksByAssignee?.returnData?.filter(
  //     (task: any) => task.status === "COMPLETED"
  //   );
  const completedTasks =
    tasks && taskItems?.find((task: any) => task.status === "COMPLETED");

  const [isDragging, setIsDragging] = useState(false);

  const allTasksEmpty =
    tasks &&
    filteredTasks?.every((item: any) => item.tasks && item.tasks.length === 0);
  // const completedTasksEmpty =
  //   tasks &&
  //   completedTasks?.every((item: any) => item.tasks && item.tasks.length === 0);
  const completedTasksEmpty: boolean = completedTasks
    ? completedTasks?.tasks?.length === 0
    : false;
  const handleDragStart = React.useCallback(
    (
      event: React.DragEvent<HTMLDivElement>,
      columnStatus: any,
      taskId: any,
      taskType: string,
      task: string
    ) => {
      setdraggedLead(taskId);
      setdraggedLeadTaskType(taskType);
      setdraggedTask(task);
      event.dataTransfer.setData("columnStatus", columnStatus.toString());
      event.dataTransfer.setData("taskId", taskId.toString());
      setdraggedColumn(columnStatus);

      setIsDragging(true);
      event.currentTarget.style.zIndex = "999";
      // setDropZoneActive(true);
    },
    [setdraggedLead]
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add(styles.dragoverColumn);
    setDropZoneActive(true);
  };

  const handleCompletedDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (draggedLeadTaskType === "DEMO") {
      navigate(`/app/Tasks/DemoTaskDetails/${draggedLead}`);
    } else {
      const draggedColumnStatus = draggedColumn;
      if (draggedColumnStatus !== "COMPLETED") {
        UpdateTaskStatus({
          taskId: draggedLead,
          taskStatus: "COMPLETED",
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          taskType: draggedLeadTaskType,
          instituteId: authUser?.institute[0]?.instituteId,
        });
      }
    }
    setDropZoneActive(false);
    event.currentTarget.classList.remove(styles.dragoverColumn);
    setIsDragging(false);
    setdraggedColumn("");
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.remove(styles.dragoverColumn);
    setIsDragging(false);
    setDropZoneActive(false);
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    columnStatus: any,
    stateName: any
  ) => {
    const draggedColumnStatus = draggedColumn;

    stateName = stateName.toUpperCase().replace(" ", "_");
    setDropZoneActive(false);
    if (draggedLeadTaskType === "DEMO") {
      const demoStudentUpdate = draggedTask?.demoData?.map((item: any) => {
        return {
          demoStudentId: item.id,
          status: item?.status,
        };
      });
      if (draggedColumnStatus !== columnStatus) {
        UpdateTaskStatus({
          taskId: draggedLead,
          taskStatus: stateName,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.firstName,
          taskType: draggedLeadTaskType,
          instituteId: authUser?.institute[0]?.instituteId,
          demoStudentUpdate: demoStudentUpdate,
        });
      }
    } else {
      if (draggedColumnStatus !== columnStatus) {
        UpdateTaskStatus({
          taskId: draggedLead,
          taskStatus: stateName,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.firstName,
          taskType: draggedLeadTaskType,
          instituteId: authUser?.institute[0]?.instituteId,
        });
      }
    }

    event.currentTarget.classList.remove(styles.dragoverColumn);
    setIsDragging(false);
    setdraggedColumn("");
  };
  const [filterPriority, setFilterPriority] = React.useState("All");

  const [filterType, setFilterType] = useState("All");
  const [leadSearchTerm, setLeadSearchTerm] = React.useState("");

  const handleLeadSearch = (event: any) => {
    setLeadSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("taskLeadSearch", event.target.value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  function getFormattedDate(date: any) {
    return moment(date).format("DD MMM YYYY, hh:mm A");
  }

  const [filterCheckedPriorities, setFilterCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);

  const [filterCheckedTypes, setFilterCheckedTypes] = useState<CheckedTypes[]>(
    []
  );

  const filteredValue = (
    <>
      {filterCheckedPriorities?.length > 0 &&
        filterCheckedPriorities?.map((item) => (
          <div key={item.priority} className="filteredDiv">
            {item.priority.toLowerCase()}
          </div>
        ))}

      {filterCheckedTypes?.length > 0 &&
        filterCheckedTypes?.map((item) => (
          <div key={item.type} className="filteredDiv">
            {item.type?.toLowerCase()}
          </div>
        ))}
    </>
  );

  const handleFilterTasks = React.useCallback(
    (prioritiesArray: any, typesArray: any) => {
      setFilterCheckedPriorities(prioritiesArray);
      setFilterCheckedTypes(typesArray);

      const queryParams = new URLSearchParams();

      if (prioritiesArray && prioritiesArray.length > 0) {
        const priorityParam = prioritiesArray
          .map((item: any) => item.priority)
          .join("|");
        queryParams.set("taskPriorities", priorityParam);
      } else {
        queryParams.delete("taskPriorities");
      }

      if (typesArray && typesArray.length > 0) {
        const statusParam = typesArray.map((item: any) => item.type).join("|");
        queryParams.set("taskTypes", statusParam);
      } else {
        queryParams.delete("taskTypes");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [setFilterCheckedPriorities, setFilterCheckedTypes]
  );

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;

      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      taskId: string,
      taskTab: string
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "task");
      queryParams.set("taskTabOpen", taskTab);

      queryParams.set("leadTaskId", taskId);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());

      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  const isTriggered = useSelector(
    (state: any) => state.taskBoardUpdation?.isTriggered
  );

  console.log("TRIGGER", isTriggered);

  const taskBoardApiCall = useCallback(
    (
      instituteId: string,
      taskStartDate: any,
      taskEndDate: any,
      userId: any
    ) => {
      const data: Data = {
        instituteId: instituteId,
        startDate: taskStartDate,
        endDate: taskEndDate,
        limit: 15,
      };

      if (hasAllTaskAccess && userId !== "") {
        data.userId = userId;
      } else if (!hasAllTaskAccess) {
        data.userId = authUser?.institute[0]?.id;
      }

      data.lanesToFetch = [
        {
          status: "TO_DO",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        },
        {
          status: "IN_PROGRESS",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        },
        {
          status: "BLOCKED",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        },
        {
          status: "COMPLETED",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        },
      ];
      GetTasksBoard(data);
    },
    [userById, hasAllTaskAccess]
  );
  const [rangeStartDate, setRangeStartDate] = useState<any>(new Date());
  const [rangeEndDate, setRangeEndDate] = useState<any>(null);

  // useEffect(() => {
  //   if (isTriggered === true) {
  //     const urlSearchParams = new URLSearchParams(window.location.search);
  //     const taskStartDate = urlSearchParams.get("taskStartDate");
  //     const taskEndDate = urlSearchParams.get("taskEndDate");
  //     const selectedUsersParam = urlSearchParams.get("selectedUsers");
  //     taskBoardApiCall(
  //       authUser?.institute[0]?.instituteDetails?.id,
  //       taskStartDate,
  //       taskEndDate,
  //       selectedUsersParam ? selectedUsersParam : ""
  //     );
  //     setTimeout(() => {
  //       dispatch(setTrigger(false));
  //     }, 5000);
  //   }
  // }, [isTriggered]);

  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setRangeStartDate(start);
    setRangeEndDate(end);
    const selectedDateFirstMinute = moment(start).startOf("day").unix() * 1000;
    const selectedStartDateLastMinute =
      moment(start).endOf("day").unix() * 1000;

    const selectedDateLastMinute = moment(end).endOf("day").unix() * 1000;

    const queryParams = new URLSearchParams(window.location.search);

    if (end !== null && authUser) {
      taskBoardApiCall(
        authUser?.institute[0]?.instituteDetails?.id,
        selectedDateFirstMinute,
        selectedDateLastMinute,
        selectedTeamMember?.length > 0 ? selectedTeamMember?.[0]?.id : ""
      );
      queryParams.set("taskStartDate", selectedDateFirstMinute.toString());
      queryParams.set("taskEndDate", selectedDateLastMinute.toString());

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    } else {
      taskBoardApiCall(
        authUser?.institute[0]?.instituteDetails?.id,
        selectedDateFirstMinute,
        selectedStartDateLastMinute,
        selectedTeamMember?.length > 0 ? selectedTeamMember?.[0]?.id : ""
      );
      queryParams.set("taskStartDate", selectedDateFirstMinute.toString());
      queryParams.set("taskEndDate", selectedStartDateLastMinute.toString());

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  };

  const handleResetDate = useCallback(() => {
    setSelectedDate(dayjs().unix() * 1000);
    setNewDate(dayjs());
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("taskDate");
    queryParams.delete("taskStartDate");
    queryParams.delete("taskEndDate");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);

    setRangeStartDate(new Date());
    setRangeEndDate(new Date());

    const selectedDateFirstMinute = moment().startOf("day").unix() * 1000;
    const selectedDateLastMinute = moment().endOf("day").unix() * 1000;

    taskBoardApiCall(
      authUser?.institute[0]?.instituteDetails?.id,
      selectedDateFirstMinute,
      selectedDateLastMinute,
      selectedTeamMember?.length > 0 ? selectedTeamMember?.[0]?.id : ""
    );
  }, [setSelectedDate, setRangeStartDate, setRangeEndDate, selectedTeamMember]);

  const handleNextPage = (currentLaneStatus: any) => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskStartDate = urlSearchParams.get("taskStartDate");
    const taskEndDate = urlSearchParams.get("taskEndDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");

    let startingDate = null;
    let endingDate = null;
    if (taskStartDate && taskStartDate !== null && taskEndDate !== null) {
      startingDate = taskStartDate;
      endingDate = taskEndDate;
    } else {
      startingDate = moment(rangeStartDate).startOf("day").unix() * 1000;

      endingDate = moment(rangeStartDate).endOf("day").unix() * 1000;
    }
    const evaluatedKeysForLanes: any =
      taskItems &&
      taskItems
        ?.filter((d: any) => d.status === currentLaneStatus)
        ?.map((d: any) => {
          return {
            status: d?.status,
            lastEvaluatedKey: d?.lastEvaluatedKey ? d?.lastEvaluatedKey : "",
            lastEvaluatedSortKey: d?.lastEvaluatedSortKey
              ? d?.lastEvaluatedSortKey
              : "",
          };
        });

    const hasEvaluatedKey = evaluatedKeysForLanes?.filter(
      (ds: any) => ds?.lastEvaluatedKey !== ""
    );
    const endOfView = window.scrollY + window.innerHeight;
    type Data = {
      instituteId: string;
      startDate: any;
      endDate: any;
      limit: number;
      userId?: any;
      lanesToFetch?: {
        status: string;
        lastEvaluatedKey: string;
        lastEvaluatedSortKey: string;
      }[];
    };

    const data: Data = {
      instituteId: userFromLocalStorage?.id,
      startDate: startingDate,
      endDate: endingDate,
      limit: 15,
      lanesToFetch: evaluatedKeysForLanes,
    };

    if (hasAllTaskAccess && selectedUsersParam) {
      data.userId = selectedUsersParam;
    } else if (!hasAllTaskAccess) {
      data.userId = authUser?.institute[0]?.id;
    }

    if (hasEvaluatedKey?.length > 0 && endOfView) {
      GetTaskBoard(data).then((res: any) => {
        const result = res?.data?.data;
        const updatedState = taskItems?.map((oldObject: any) => {
          // Find the corresponding result for the current lane being processed
          const correspondingResult = result?.find(
            (r: any) => r.status === oldObject.status
          );

          if (correspondingResult) {
            const updatedTasks = [...oldObject.tasks];
            correspondingResult?.tasks?.forEach((newTask: any) => {
              if (!updatedTasks?.find((task) => task.id === newTask.id)) {
                updatedTasks.push(newTask);
              }
            });

            return {
              ...oldObject,
              tasks: updatedTasks,
              lastEvaluatedKey: correspondingResult?.lastEvaluatedKey,
              lastEvaluatedSortKey: correspondingResult?.lastEvaluatedSortKey,
            };
          } else {
            return oldObject;
          }
        });
        setTaskItems(updatedState);
      });
    }
  };

  useEffect(() => {
    if (tasks) {
      setTaskItems(tasks);
    } else {
      setTaskItems([]);
    }
  }, [tasks]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskDate = urlSearchParams.get("taskDate");
    const taskLeadSearchInUrl = urlSearchParams.get("taskLeadSearch");

    if (taskLeadSearchInUrl && taskLeadSearchInUrl !== "") {
      setLeadSearchTerm(taskLeadSearchInUrl);
    }

    if (taskDate && taskDate !== null) {
      setSelectedDate(parseInt(taskDate));
    }
  }, [setSelectedDate, setLeadSearchTerm]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const priorityParam = urlSearchParams.get("taskPriorities");
    const typeParam = urlSearchParams.get("taskTypes");

    if (priorityParam) {
      const priorities = priorityParam.split("|");

      const selectedPriorities = priorities
        .map((value) => {
          const priority = [
            { priority: "URGENT" },
            { priority: "HIGH" },
            { priority: "NORMAL" },
            { priority: "LOW" },
          ].find(
            (type: any) => type.priority.toLowerCase() === value.toLowerCase()
          );
          return priority
            ? {
                priority: priority.priority,
              }
            : null;
        })
        .filter(Boolean) as CheckedPriorities[];

      setFilterCheckedPriorities(selectedPriorities);
    }

    if (typeParam && tasksTypes) {
      const types = typeParam.split("|");

      const selectedTypes = types
        .map((value) => {
          const type = tasksTypes?.find(
            (taskType: any) =>
              taskType.taskTypeName.toLowerCase() === value.toLowerCase()
          );
          return type
            ? {
                type: type.taskTypeName,
              }
            : null;
        })
        .filter(Boolean) as CheckedTypes[];

      setFilterCheckedTypes(selectedTypes);
    }
  }, [tasksTypes]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskStartDate = urlSearchParams.get("taskStartDate");
    const taskEndDate = urlSearchParams.get("taskEndDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (userById) {
      if (taskStartDate && taskStartDate !== null && taskEndDate !== null) {
        const startDate = new Date(parseInt(taskStartDate));
        const endDate = new Date(parseInt(taskEndDate));

        setRangeStartDate(startDate);
        setRangeEndDate(endDate);

        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          taskStartDate,
          taskEndDate,
          selectedUsersParam ? selectedUsersParam : ""
        );
      } else {
        const selectedDateFirstMinute =
          moment(rangeStartDate).startOf("day").unix() * 1000;

        const selectedDateLastMinute =
          moment(rangeStartDate).endOf("day").unix() * 1000;

        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          selectedDateFirstMinute,
          selectedDateLastMinute,
          selectedUsersParam ? selectedUsersParam : ""
        );
      }
    }
  }, [userById]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskStartDate = urlSearchParams.get("taskStartDate");
    const taskEndDate = urlSearchParams.get("taskEndDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (rangeStartDate && authUser && authUser.institute) {
      if (taskStatusUpdateSuccess) {
        if (taskStartDate && taskEndDate) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            taskStartDate,
            taskEndDate,
            selectedUsersParam ? selectedUsersParam : ""
          );
        } else {
          const selectedDateFirstMinute =
            moment(rangeStartDate).startOf("day").unix() * 1000;

          const selectedDateLastMinute =
            moment(rangeStartDate).endOf("day").unix() * 1000;

          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            selectedDateFirstMinute,
            selectedDateLastMinute,
            selectedUsersParam ? selectedUsersParam : ""
          );
        }
      }
    }
  }, [taskStatusUpdateSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskStartDate = urlSearchParams.get("taskStartDate");
    const taskEndDate = urlSearchParams.get("taskEndDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (rangeStartDate && authUser) {
      if (
        (editTaskSuccess !== true && editTaskSuccess !== false) ||
        (createTaskSuccess !== true && createTaskSuccess !== false) ||
        (deleteTaskSuccess !== true && deleteTaskSuccess !== false) ||
        (leadUpdateSuccess !== true && leadUpdateSuccess !== false)
      ) {
        if (taskStartDate && taskEndDate) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            taskStartDate,
            taskEndDate,
            selectedUsersParam ? selectedUsersParam : ""
          );
        } else {
          const selectedDateFirstMinute =
            moment(rangeStartDate).startOf("day").unix() * 1000;

          const selectedDateLastMinute =
            moment(rangeStartDate).endOf("day").unix() * 1000;

          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            selectedDateFirstMinute,
            selectedDateLastMinute,
            selectedUsersParam ? selectedUsersParam : ""
          );
        }
      }
    }
  }, [
    createTaskSuccess,
    editTaskSuccess,
    deleteTaskSuccess,
    leadUpdateSuccess,
  ]);
  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (employees?.length > 0) {
      const staff = employees
        ?.filter((item: any) => item?.status === "ACTIVE")
        ?.find((item: any) => item.id === selectedUsersParam);
      if (staff) {
        setSelectedTeamMember([staff]);
      } else {
        setSelectedTeamMember([]);
      }
    }
  }, [employees]);

  React.useEffect(() => {
    if (Array.isArray(taskItems)) {
      const evaluatedKeysForLanes = taskItems?.map((d) => {
        return {
          status: d?.status,
          lastEvaluatedKey: d?.lastEvaluatedKey ? d?.lastEvaluatedKey : "",
          lastEvaluatedSortKey: d?.lastEvaluatedSortKey
            ? d?.lastEvaluatedSortKey
            : "",
        };
      });

      const hasEvaluatedKey = evaluatedKeysForLanes.some(
        (ds) => ds.lastEvaluatedKey !== ""
      );

      setHasMoreData(hasEvaluatedKey);
    } else {
      setHasMoreData(false);
    }
  }, [taskItems]);

  return (
    <Box sx={{ backgroundColor: "#F5F5F5", display: "flex", width: "100%" }}>
      <Box className={styles.taskKanbanBoard}>
        <TaskKanbanHeader
          handleFilterTasks={handleFilterTasks}
          handleLeadSearch={handleLeadSearch}
          leadSearchTerm={leadSearchTerm}
          tasksTypes={tasksTypes}
          filteredValue={filteredValue}
          filterCheckedPriorities={filterCheckedPriorities}
          filterCheckedTypes={filterCheckedTypes}
          setSelectedTeamMember={setSelectedTeamMember}
          selectedTeamMember={selectedTeamMember}
          rangeStartDate={rangeStartDate}
          taskBoardApiCall={taskBoardApiCall}
        />
        <div
          style={{
            padding: "16px 11px 0px 11px",
            overflowX: "auto",
            position: "relative",
          }}
        >
          {tasks && allTasksEmpty === true && draggedColumn === "" ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                  textTransform: "capitalize",
                }}
              >
                No data available
              </div>
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              minHeight: "calc(100vh - 127px)",
              minWidth: "max-content",
            }}
          >
            <div className={styles.kanbanBoard}>
              {tasks ? (
                <>
                  {tasks &&
                    filteredTasks?.map((column: any) => (
                      <div key={column.id} className={styles.kanbanColumn}>
                        <div className={styles.kanbanColumnHead}>
                          <div className={styles.kanbanColumnHeadTitle}>
                            {column?.status?.toLowerCase()?.replace(/_/g, " ")}
                          </div>
                          <div className={styles.kanbanColumnHeadCount}>
                            {
                              column?.tasks
                                ?.filter(
                                  (task: any) => task.status === column.status
                                )
                                ?.filter((task: any) => {
                                  if (leadSearchTerm === "") {
                                    return true;
                                  }
                                  const fullName = task.studentName;
                                  return fullName
                                    .toLowerCase()
                                    .includes(leadSearchTerm.toLowerCase());
                                })
                                ?.filter((task: any) => {
                                  if (filterCheckedPriorities.length === 0) {
                                    return true;
                                  }
                                  return filterCheckedPriorities.some(
                                    (item) =>
                                      task.priority.toLowerCase() ===
                                      item.priority.toLowerCase()
                                  );
                                })
                                ?.filter((task: any) => {
                                  if (filterCheckedTypes.length === 0) {
                                    return true;
                                  }
                                  return filterCheckedTypes.some(
                                    (item) => task.taskType === item.type
                                  );
                                })?.length
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                Array.from(new Array(3)).map((_, index) => (
                  <div
                    key={index}
                    className={styles.kanbanColumn}
                    style={{ marginBottom: "8px" }}
                  >
                    <div className={styles.kanbanColumnHead}>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width={210}
                        height={25}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
            <div
              className={styles.kanbanBoardLeadsWrap}
              style={{
                maxHeight: `calc(100vh - 206px)`,
                overflowY: "auto",
                minHeight: `calc(78vh - 45.6px)`,
                overflowX: "hidden",
                minWidth: "max-content",
              }}
            >
              {tasks !== null ? (
                <>
                  {tasks &&
                    filteredTasks?.map((column: any) => (
                      <div
                        key={column.id}
                        className={styles.kanbanColumnLeads}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={(event) =>
                          handleDrop(event, column.status, column.status)
                        }
                      >
                        {dropZoneActive ? (
                          <div className={styles.dropZoneBox}></div>
                        ) : (
                          <InfiniteScroll
                            dataLength={column?.tasks.length}
                            next={() => handleNextPage(column.status)}
                            hasMore={hasMoreData}
                            loader={
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {
                                  // <PuffLoader color="#36d7b7" />
                                }
                              </Box>
                            }
                            height={1400}
                          >
                            <div className={styles.kanbanColumnTasks}>
                              {column?.tasks
                                ?.filter(
                                  (task: any) => task.status === column.status
                                )
                                ?.filter((task: any) => {
                                  if (leadSearchTerm === "") {
                                    return true;
                                  }
                                  const fullName = task.studentName;
                                  const isDemoTask = task.taskType === "DEMO";

                                  if (isDemoTask) {
                                    const batchName =
                                      task?.demoData?.[0]?.batchName;
                                    return batchName
                                      ? batchName
                                          .toLowerCase()
                                          .includes(
                                            leadSearchTerm.toLowerCase()
                                          )
                                      : false;
                                  }

                                  return fullName
                                    .toLowerCase()
                                    .includes(leadSearchTerm.toLowerCase());
                                })
                                ?.filter((task: any) => {
                                  if (filterCheckedPriorities.length === 0) {
                                    return true;
                                  }
                                  return filterCheckedPriorities.some(
                                    (item) =>
                                      task.priority.toLowerCase() ===
                                      item.priority.toLowerCase()
                                  );
                                })
                                ?.filter((task: any) => {
                                  if (filterCheckedTypes.length === 0) {
                                    return true;
                                  }
                                  return filterCheckedTypes.some(
                                    (item) => task.taskType === item.type
                                  );
                                })
                                ?.map((task: any) => (
                                  <div
                                    key={task.id}
                                    className={styles.kanbanTask}
                                    draggable
                                    onDragStart={(event) =>
                                      handleDragStart(
                                        event,
                                        column.status,
                                        task.id,
                                        task.taskType,
                                        task
                                      )
                                    }
                                    onClick={() =>
                                      task.taskType === "DEMO"
                                        ? navigate(
                                            `/app/Tasks/DemoTaskDetails/${task.id}`
                                          )
                                        : hasLeadCardPermission
                                        ? openLeadExpandModal(
                                            task?.instituteId
                                              ? task?.instituteId
                                              : task?.enquiryData?.instituteId,
                                            task?.studentContact
                                              ? task?.studentContact
                                              : task?.enquiryData
                                                  ?.studentContact,
                                            task?.studentEmail
                                              ? task?.studentEmail
                                              : task?.enquiryData?.studentEmail,
                                            task?.enquiryId,
                                            task.id,
                                            "INCOMPLETE"
                                          )
                                        : dispatch(
                                            setToastNotification({
                                              message: String(
                                                "You do not have permission to access the lead card"
                                              ),
                                              type: "error",
                                              snackOpen: true,
                                            })
                                          )
                                    }
                                  >
                                    <div className={styles.taskType}>
                                      <div
                                        className={styles.dateSelect}
                                        style={{
                                          color:
                                            moment(task?.date) < moment()
                                              ? "red"
                                              : "inherit",
                                        }}
                                      >
                                        {getFormattedDate(task?.date)}
                                      </div>
                                      <div className={styles.taskFlag}>
                                        <div className={styles.taskTypeSelect}>
                                          {task.taskType?.toLowerCase()}
                                        </div>
                                        <div className={styles.userCircle}>
                                          {getTeamMember(
                                            task?.assignedToId,
                                            employees
                                          )?.firstName === undefined
                                            ? ""
                                            : getTeamMember(
                                                task?.assignedToId,
                                                employees
                                              )?.firstName?.charAt(0) +
                                              "" +
                                              getTeamMember(
                                                task?.assignedToId,
                                                employees
                                              )?.lastName?.charAt(0)}
                                        </div>
                                        <div className={styles.flagAssign}>
                                          {task.priority === "HIGH" && (
                                            <img src={High} alt="High" />
                                          )}
                                          {task.priority === "NORMAL" && (
                                            <img src={Normal} alt="Normal" />
                                          )}
                                          {task.priority === "LOW" && (
                                            <img src={Low} alt="Low" />
                                          )}
                                          {task.priority === "URGENT" && (
                                            <img src={Urgent} alt="Urgent" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.lead}>
                                      {task?.taskType === "DEMO" ? (
                                        <>{task?.demoData?.[0]?.batchName}</>
                                      ) : (
                                        <>{task.studentName}</>
                                      )}
                                    </div>
                                    {task?.taskType === "DEMO" ? (
                                      <>
                                        <div
                                          className={styles.taskTitle}
                                          style={{
                                            display: "flex",
                                            gap: "2px",
                                          }}
                                        >
                                          <div
                                            className={styles.taskDemoItemLabel}
                                          >
                                            Time:
                                          </div>
                                          <div
                                            className={styles.taskDemoItemValue}
                                          >
                                            {moment(
                                              task?.demoData?.[0]?.demoStartTime
                                            ).format(" hh:mm A") +
                                              " to " +
                                              moment(
                                                task?.demoData?.[0]?.demoEndTime
                                              ).format(" hh:mm A")}
                                          </div>
                                        </div>
                                        <div
                                          className={styles.taskTitle}
                                          style={{
                                            display: "flex",
                                            gap: "2px",
                                          }}
                                        >
                                          <div>Course:</div>
                                          <div>
                                            {task?.enquiryData?.courseName}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className={styles.taskDesc}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            getDescriptionWithMentionedNames(
                                              task?.description,
                                              employees
                                            ).replace(/\n/g, "<br>"),
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </InfiniteScroll>
                        )}
                      </div>
                    ))}
                </>
              ) : (
                Array.from(new Array(3)).map((_, index) => (
                  <div key={index} className={styles.kanbanColumn}>
                    <div className={styles.kanbanColumnTasks}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="60vh"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          background: "#fff",
          width: "360px",
          borderLeft: "1px solid #E9E9E9",
        }}
        className="datepicker-container"
      >
        {tasks ? (
          <>
            <DateRangePicker
              startDate={rangeStartDate}
              endDate={rangeEndDate}
              onDateChange={onDateChange}
              minDate={moment().toDate()}
            />

            {rangeEndDate &&
              moment(rangeEndDate).startOf("day").unix() * 1000 >
                moment().startOf("day").unix() * 1000 && (
                <div
                  style={{
                    padding: "0 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleResetDate}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    size="small"
                  >
                    Reset Date
                  </Button>
                </div>
              )}
          </>
        ) : (
          <>
            <div style={{ padding: "10px" }}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height="40px"
                style={{
                  borderRadius: "3px",
                  marginBottom: "10px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                height="290px"
                style={{
                  borderRadius: "3px",
                }}
              />
            </div>
          </>
        )}

        <div style={{ padding: "0px 20px 20px 20px" }}>
          {tasks ? (
            <>
              <div className={styles.taskCompletedWrap}>
                <div className={styles.taskCompletedWrapHead}>Completed</div>
                <div
                  className={styles.taskCompletedWrapViewAll}
                  onClick={() =>
                    navigate(
                      selectedTeamMember?.length > 0
                        ? `/app/Tasks/TaskCompleted?selectedUsers=${selectedTeamMember[0]?.id}`
                        : `/app/Tasks/TaskCompleted`,
                      { state: { taskSelectedId: selectedTeamMember[0]?.id } }
                    )
                  }
                >
                  View all
                </div>
              </div>
              <div
                className={styles.taskCompletedListWrap}
                onDragOver={handleDragOver}
                // onDragLeave={handleDragLeave}
                onDrop={(event) => handleCompletedDrop(event)}
                style={{
                  position: "relative",
                  maxHeight:
                    rangeEndDate &&
                    moment(rangeEndDate).startOf("day").unix() * 1000 >
                      moment().startOf("day").unix() * 1000
                      ? "calc(100vh - 460px)"
                      : "calc(100vh - 440px) ",
                  minHeight:
                    rangeEndDate &&
                    moment(rangeEndDate).startOf("day").unix() * 1000 >
                      moment().startOf("day").unix() * 1000
                      ? "calc(100vh - 460px)"
                      : "calc(100vh - 440px)",
                }}
              >
                {dropZoneActive ? (
                  <div
                    style={{
                      border: "2px dashed #11c89b",
                      width: "100%",
                      maxHeight:
                        rangeEndDate &&
                        moment(rangeEndDate).startOf("day").unix() * 1000 >
                          moment().startOf("day").unix() * 1000
                          ? "calc(100vh - 480px)"
                          : "calc(100vh - 460px) ",
                      minHeight:
                        rangeEndDate &&
                        moment(rangeEndDate).startOf("day").unix() * 1000 >
                          moment().startOf("day").unix() * 1000
                          ? "calc(100vh - 480px)"
                          : "calc(100vh - 460px)",
                    }}
                  ></div>
                ) : (
                  <>
                    {tasksByAssignee &&
                      completedTasks &&
                      completedTasksEmpty === true && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              color: "#667085",
                              fontWeight: "600",
                              marginBottom: "6PX",
                            }}
                          >
                            No data available
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              textAlign: "center",
                              color: "#667085",
                            }}
                          >
                            Drag and drop the completed task after completion
                          </div>
                        </div>
                      )}
                    {tasks && completedTasks && (
                      <InfiniteScroll
                        dataLength={completedTasks?.tasks.length}
                        next={() => handleNextPage(completedTasks?.status)}
                        hasMore={hasMoreData}
                        loader={
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {
                              // <PuffLoader color="#36d7b7" />
                            }
                          </Box>
                        }
                        height={
                          rangeEndDate &&
                          moment(rangeEndDate).startOf("day").unix() * 1000 >
                            moment().startOf("day").unix() * 1000
                            ? "calc(100vh - 460px)"
                            : "calc(100vh - 440px)"
                        }
                      >
                        {tasks &&
                          completedTasks?.tasks?.map((task: any) => (
                            <CompletedCard
                              taskCompletedPage={false}
                              task={task}
                              expandDisabled={false}
                              employees={employees}
                              openLeadExpandModal={openLeadExpandModal}
                              page="TASK_BOARD"
                              handleDragStart={handleDragStart}
                            />
                          ))}
                      </InfiniteScroll>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {Array.from(new Array(4)).map((_, index) => (
                <div key={index} className={styles.tableSkeletonBodyItem}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width="100%"
                    height={50}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </Box>
    </Box>
  );
}
