import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  styled,
  TableBody,
  TableCell,
  Avatar,
  IconButton,
  Collapse,
  Tabs,
  Tab,
  Box,
  Skeleton,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import styles from "./Nurture.module.css";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlagAssign from "../../../../components/FlagAssign/FlagAssign";
import LeadNotesTab from "../../../../components/ListTab/LeadNotesTab";
import ListViewTasksTab from "../../../../components/ListTab/ListViewTasksTab";
import { useAuthUser } from "react-auth-kit";
import LeadCardDropdown from "./LeadCardDropdown/LeadCardDropdown";
import noFilteredLeadsImg from "../components/Assets/noLeadsImg.svg";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import {
  getLeadListByInstituteAndContact,
  setLeadModalState,
} from "../service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import getTeamMember from "../../../../components/CommonFunctions/CommonFunctions";
import { TableComponents, TableVirtuoso } from "react-virtuoso";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F5F5",
    color: "#667085",
    borderBottom: "10px solid #f5f5f5",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "5px solid #f5f5f5",
  },
}));

const nurtureList = [
  {
    name: "Olivia Rhye",
    id: "l1",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
  {
    name: "Olivia Rhye",
    id: "l2",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
  {
    name: "Olivia Rhye",
    id: "l3",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
  {
    name: "Olivia Rhye",
    id: "l4",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
  {
    name: "Olivia Rhye",
    id: "l5",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
  {
    name: "Olivia Rhye",
    id: "l6",
    email: "oliviarhye@gmail.com",
    phoneNumber: 85459575855,
    stage: "New Enquiry",
    assignee: "Davidson",
    priority: "Urgent",
    source: "WhatsApp",
  },
];

const NurtureList = (props: any) => {
  const {
    handleDragStart,
    hasMoreData,
    setHasMoreData,
    GetLeadNoteList,
    CreateTask,
    CreateNote,
    EditTask,
    DeleteTask,
    UpdateTaskStatus,
    leadTaskList,
    employees,
    taskTypes,
    EditNote,
    DeleteNote,
    leadNoteList,
    leadItems,
    GetLeadTaskList,

    lead,

    UpdateLeadPriority,
    setValue,
    setExpandedLead,
    setLeadSearchTerm,
    leadSearchTerm,
    filterCheckedBranches,
    filterCheckedBatches,
    filterCheckedPriorities,
    filterCheckedSources,
    filterCheckedAssignees,
    selectedCourse,
    courseLeadCount,
    UpdateLeadSwimlaneStatus,
    UpdateLeadEnquiyStatus,
    filterCheckedStages,
    leadPaginationLoad,
    paginatedLeadId,
    setPaginatedLeadId,
    lastEvaluatedKey,
    leadListItems,
    allLeadsEmpty,
    nurtureLeadStatus,
    filterApplied,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const { onLeadsLoading, onLeadsData } = useSelector(
    (state: any) => state.nurture
  );

  const [openRows, setOpenRows] = useState(
    Array(nurtureList.length).fill(false)
  );
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleRowToggle = (index: number) => {
    const newOpenRows = Array(nurtureList.length).fill(false);
    newOpenRows[index] = !openRows[index];
    setOpenRows(newOpenRows);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    [getLeadListByInstituteAndContact]
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());

      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  const handleLeadPriority = React.useCallback(
    (leadId: any, leadPriority: any) => {
      if (leadId && leadPriority) {
        UpdateLeadPriority({
          enquiryId: leadId,
          enquiryPriority: leadPriority,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          updatedById: authUser?.institute[0]?.id,
          instituteId: authUser?.institute[0]?.instituteId,
        });
      }
    },

    [UpdateLeadPriority]
  );

  const filteredLeads =
    leadListItems &&
    leadListItems?.filter((data: any) => {
      if (selectedCourse === null) {
        return true;
      }
      return data.courseId === selectedCourse.courseId;
    });

  React.useEffect(() => {
    if (paginatedLeadId) {
      const element = document.getElementById(paginatedLeadId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }

      const timeoutId = setTimeout(() => {
        setPaginatedLeadId("");
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [leadListItems]);

  React.useEffect(() => {
    if (onLeadsLoading === true) {
      setExpandedLead(null);
      setOpenRows(Array(nurtureList.length).fill(false));
    }
  }, [onLeadsLoading]);

  interface CustomTableComponents extends TableComponents<any> {
    Table: React.FC<any>;
    TableHead: typeof TableHead;
    TableRow: React.FC<any>;
    TableBody: React.ForwardRefExoticComponent<any>;
  }
  const VirtuosoTableComponents: CustomTableComponents = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        //  component={Paper}
        {...props}
        ref={ref}
        className={styles.listTableContainer}
      />
    )),
    Table: (props) => (
      <Table {...props} style={{ borderCollapse: "separate" }} />
    ),
    TableHead: (props: any) => (
      <TableHead {...props} className={styles.batchPopupHead} />
    ),
    TableRow: ({ item, ...props }) => {
      return (
        <>
          <TableRow
            draggable
            onDragStart={(event: any) =>
              handleDragStart(event, "contacted", item.id, item)
            }
            id={item?.id}
            sx={{
              borderRadius: "2px",
              backgroundColor: item?.status === "LOST" ? "#F4EDED" : "#FFFFFF",
              border:
                item?.status === "LOST"
                  ? "1px solid #F1B3B3"
                  : "1px solid #F0F0F0",
              margin: "10px 0 !important",
            }}
            {...props}
          />
          {item &&
            props["data-index"] !== undefined &&
            openRows[props["data-index"]] && (
              <TableRow
                sx={{
                  backgroundColor: "#FFF",
                  borderBottom: "6px solid #F5F5F5",
                }}
              >
                <TableCell
                  colSpan={8}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <Collapse in={true} timeout="auto">
                    <Box
                      sx={{ margin: "10px", borderBottom: "1px solid #E9E9E9" }}
                    >
                      <Tabs
                        value={activeTab}
                        onChange={(event, newValue) => setActiveTab(newValue)}
                      >
                        <Tab label="Tasks" />
                        <Tab label="Notes" />
                      </Tabs>
                    </Box>
                    {activeTab === 0 && (
                      <ListViewTasksTab
                        CreateTask={CreateTask}
                        EditTask={EditTask}
                        DeleteTask={DeleteTask}
                        UpdateTaskStatus={UpdateTaskStatus}
                        selectedLeadData={item}
                        leadTaskList={leadTaskList}
                        employees={employees}
                        taskTypes={taskTypes}
                      />
                    )}
                    {activeTab === 1 && (
                      <LeadNotesTab
                        CreateNote={CreateNote}
                        EditNote={EditNote}
                        DeleteNote={DeleteNote}
                        selectedLeadData={item}
                        leadNoteList={leadNoteList}
                        employees={employees}
                      />
                    )}
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
        </>
      );
    },
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) =>
      leadSearchTerm === "" &&
      leadListItems?.length === 0 &&
      filterApplied === true &&
      courseLeadCount !== 0 &&
      onLeadsData !== null ? (
        <TableBody {...props} ref={ref}>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "56px", marginBottom: "10px" }}
                src={noFilteredLeadsImg}
                alt="noFilteredLeadsImg"
              />
              <div
                style={{
                  color: "#667085",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginBottom: "8px",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                No data available
              </div>
              <div
                style={{
                  color: "#667085",
                  fontSize: "13px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Try applying a different filter
              </div>
            </div>
          </div>
        </TableBody>
      ) : (
        <TableBody {...props} ref={ref} />
      )
    ),
  };

  return (
    <div style={{ overflow: "auto", height: "100%" }}>
      {leadListItems && onLeadsData !== null ? (
        <>
          <TableVirtuoso
            style={{
              height: "calc(100vh - 180px)",
              background: "#f5f5f5",
            }}
            totalCount={filteredLeads?.length || 0}
            components={VirtuosoTableComponents}
            data={filteredLeads || []}
            fixedHeaderContent={() => (
              <TableRow>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  sx={{ borderBottom: "none", width: "5%", maxWidth: "5%" }}
                ></StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  sx={{
                    borderBottom: "none",
                    // maxWidth: "318px",
                    // minWidth: "318px",
                    width: "26%",
                    maxWidth: "26%",
                  }}
                >
                  Name
                </StyledTableCell>

                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  align="left"
                  sx={{
                    // minWidth: "170px",
                    // maxWidth: "170px",
                    borderBottom: "none",
                    width: "12%",
                    maxWidth: "12%",
                  }}
                >
                  Phone Number
                </StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  align="left"
                  sx={{ borderBottom: "none", width: "10%", maxWidth: "10%" }}
                >
                  Stage
                </StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  align="left"
                  sx={{ borderBottom: "none", width: "15%", maxWidth: "15%" }}
                >
                  Assignee
                </StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  align="left"
                  sx={{ borderBottom: "none", width: "10%", maxWidth: "10%" }}
                >
                  Priority
                </StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  align="left"
                  // style={{ fontSize: "13px", borderBottom: "none" }}
                  style={{ width: "15%", maxWidth: "15%" }}
                >
                  Source
                </StyledTableCell>
                <StyledTableCell
                  className={styles.batchPopupHeadCell}
                  sx={{
                    borderBottom: "none",
                    textAlign: "center",
                    width: "5%",
                    maxWidth: "5%",
                  }}
                >
                  {filteredLeads?.length}
                </StyledTableCell>
              </TableRow>
            )}
            itemContent={(index: number, item: any) => {
              return (
                <React.Fragment key={item?.id || index}>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      textTransform: "capitalize",
                      borderBottom: "none",
                      width: "5%",
                      maxWidth: "5%",
                    }}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        GetLeadTaskList(item.id);
                        GetLeadNoteList(item.id);
                        handleRowToggle(index);
                        setExpandedLead(item);
                      }}
                    >
                      {openRows[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      // maxWidth: "318px",
                      // minWidth: "318px",
                      width: "26%",
                      maxWidth: "26%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <div className={styles.agentCardImg}>
                        <Avatar
                          alt="avatar"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            fontSize: "17px",
                            background: "#F5F5F7",
                            border: "1px solid #EEEEF4",
                            color: "#667085",
                          }}
                        >
                          {item?.studentFirstName?.charAt(0)}
                        </Avatar>
                      </div>
                      <div>
                        <Box
                          sx={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                            ":hover": {
                              color: "#11c89b",
                              transition: "0.3s",
                            },
                          }}
                          onClick={() =>
                            hasLeadCardPermission
                              ? openLeadExpandModal(
                                  item?.instituteId,
                                  item?.studentContact,
                                  item?.studentEmail,
                                  item?.id
                                )
                              : dispatch(
                                  setToastNotification({
                                    message:
                                      "You do not have permission to access the lead card",
                                    type: "error",
                                    snackOpen: true,
                                  })
                                )
                          }
                        >
                          {`${item?.studentFirstName} ${item?.studentLastName}`}
                        </Box>
                        <div>{item?.studentEmail}</div>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      // minWidth: "170px",
                      // maxWidth: "170px",
                      width: "12%",
                      maxWidth: "12%",
                    }}
                  >
                    {item?.studentContact?.length === 10
                      ? `+91 ${item?.studentContact}`
                      : item?.studentContact?.length > 10
                      ? `+${item?.studentContact.substring(
                          0,
                          2
                        )} ${item?.studentContact.substring(2)}`
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      width: "10%",
                      maxWidth: "10%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F2F4F7",
                        padding: "8px",
                        borderRadius: "16px",
                        fontSize: "12px",
                        textAlign: "center",
                        width: "fit-content",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.enquirySwimlaneStatus
                        ?.replace(/_/g, " ")
                        .toLowerCase()}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      width: "15%",
                      maxWidth: "15%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <div className={styles.agentCardImg}>
                        <Avatar
                          alt="avatar"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            fontSize: "17px",
                            background: "#F5F5F7",
                            border: "1px solid #EEEEF4",
                            color: "#667085",
                          }}
                        >
                          {getTeamMember(item?.assignedToId, employees)
                            ?.firstName?.charAt(0)
                            ?.toUpperCase()}
                        </Avatar>
                      </div>

                      <div style={{ textTransform: "capitalize" }}>
                        {
                          getTeamMember(item?.assignedToId, employees)
                            ?.firstName
                        }{" "}
                        {getTeamMember(item?.assignedToId, employees)?.lastName}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      width: "10%",
                      maxWidth: "10%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        textTransform: "capitalize",
                      }}
                    >
                      <FlagAssign
                        disabled
                        selectedPriority={item?.enquiryPriority}
                        leadId={item?.id}
                        handleLeadPriority={handleLeadPriority}
                      />
                      {item?.enquiryPriority?.toLowerCase()}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      textTransform: "capitalize",
                      width: "15%",
                      minWidth: "15%",
                    }}
                  >
                    {item?.enquirySource?.replace(/_/g, " ").toLowerCase()}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "#667085",
                      borderBottom: "none",
                      width: "5%",
                      maxWidth: "5%",
                    }}
                  >
                    {hasLeadAccess &&
                      item?.enquirySwimlaneStatus !== "CONVERTED" && (
                        <LeadCardDropdown
                          UpdateLeadSwimlaneStatus={UpdateLeadSwimlaneStatus}
                          leadDetails={item}
                          UpdateLeadEnquiyStatus={UpdateLeadEnquiyStatus}
                          authUser={authUser}
                        />
                      )}
                  </StyledTableCell>

                  {leadListItems &&
                    leadSearchTerm !== "" &&
                    courseLeadCount !== 0 &&
                    filteredLeads?.length === 0 && (
                      // <TableRow>
                      <TableCell colSpan={7} align="center">
                        No leads found with the specified search term.
                      </TableCell>
                      // </TableRow>
                    )}
                </React.Fragment>
              );
            }}
          />
        </>
      ) : (
        <div className={styles.kanbanColumn} style={{ marginBottom: "8px" }}>
          <div
            className={styles.kanbanColumnHead}
            style={{ gap: "60px", padding: "16px" }}
          >
            <Skeleton animation="wave" variant="text" width={115} height={25} />
            <Skeleton animation="wave" variant="text" width={210} height={25} />
            <Skeleton animation="wave" variant="text" width={115} height={25} />
            <Skeleton animation="wave" variant="text" width={155} height={25} />
            <Skeleton animation="wave" variant="text" width={112} height={25} />
            <Skeleton animation="wave" variant="text" width={115} height={25} />
          </div>
          {Array.from({ length: 8 }, (_, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#FFF",
                borderRadius: "2px",
                marginTop: "10px",
                height: "72px",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                gap: "60px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={30}
                  height={30}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={80}
                  height={25}
                />
              </div>

              <Skeleton
                animation="wave"
                variant="text"
                width={210}
                height={25}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={110}
                height={25}
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={25}
                  height={25}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={125}
                  height={25}
                />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={25}
                  height={25}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={85}
                  height={25}
                />
              </div>

              <Skeleton
                animation="wave"
                variant="text"
                width={110}
                height={25}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={15}
                height={25}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NurtureList;
