import { Box, Grid, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ManageRouter from "../../../../router/ManageRouter";
import { GeneralSetup } from "./../../../../components/GeneralSetup/GeneralSetup";
import CompleteProfileHeader from "./CompleteProfileHeader";
import styles from "./ManageRoot.module.css";
import { useLocation } from "react-router-dom";
import InactiveAccountPopup from "../../../../components/CustomPopupBilling/InactiveAccountPopup";
import { checkSubDomainAccess } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";

export default function ManageRoot(props: any) {
  const { registrationStep, activeInstitute } = props;
  const location = useLocation();
  const { pathname } = useLocation();

  const { userById } = useSelector((state: any) => state.userDetails);

  const userFromLocalStorage = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const shouldDisableGeneralSetup =
    accountStatus === "ARCHIVED" ||
    accountStatus === "LOCKED" ||
    accountStatus === "UNARCHIVED" ||
    accountStatus === "INACTIVE";

  const [isActive, setIsActive] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const handleStatus = React.useCallback(
    (index: number) => {
      setIsActive(index);
    },
    [setIsActive]
  );

  useEffect(() => {
    if (window.location.href.includes("InstituteProfile")) {
      setIsActive(0);
    } else if (window.location.href.includes("Branches")) {
      setIsActive(1);
    } else if (window.location.href.includes("CreateBranch")) {
      setIsActive(1);
    } else if (window.location.href.includes("Courses")) {
      setIsActive(3);
    } else if (window.location.href.includes("Courses")) {
      setIsActive(3);
    } else if (window.location.href.includes("Teams")) {
      setIsActive(2);
    } else if (window.location.href.includes("CreateTeam")) {
      setIsActive(2);
    } else if (window.location.href.includes("Batches")) {
      setIsActive(4);
    }
    // else if (
    //   window.location.href.includes("CreateBatch") ||
    //   window.location.href.includes("CreateDemoBatch")
    // ) {
    //   setIsActive(4);
    // }
    else if (window.location.href.includes("Billing")) {
      setIsActive(5);
    } else if (window.location.href.includes("/UserDetails")) {
      setIsActive(6);
    } else if (window.location.href.includes("/ChangePassword")) {
      setIsActive(7);
    } else if (window.location.href.includes("/Notifications")) {
      setIsActive(8);
    }

    if (location?.state?.menuItemNumber && location?.state?.menuItemNumber) {
      setIsActive(location?.state?.menuItemNumber);
    } else if (location?.state) {
      setIsActive(location?.state);
    }
  }, [setIsActive, location, window.location.href]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      userFromLocalStorage &&
      userFromLocalStorage.isProfileCompleted === false
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [setButtonDisabled, userFromLocalStorage]);

  useEffect(() => {
    const element = document.getElementById("manage");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        overflow: "auto",
        padding: "14px 16px",
        background: "#EEEEEE",
        maxHeight: "calc(100vh - 65px)",
        position: "relative",
      }}
    >
      {accountStatus === "INACTIVE" && (
        <InactiveAccountPopup type={accountStatus} />
      )}

      <Box
        sx={{ flexGrow: 1 }}
        style={{
          overflow: "hidden",
          background: "#fff",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          minHeight: "calc(100vh - 94px)",
        }}
        id="manage"
      >
        {!userFromLocalStorage.isProfileCompleted &&
          !userFromLocalStorage?.instituteDetails?.isProfileCompleted && (
            <Box sx={{ margin: "10px 10px 0 10px" }}>
              <CompleteProfileHeader activeInstitute={activeInstitute} />
            </Box>
          )}

        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              className={styles.manageGridLeft}
              item
              xs={12}
              sm={12}
              md={3}
              lg={2.5}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                  md: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
                overflowY: "auto",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    paddingX: "34px",
                    fontSize: "18px",
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontWeight: "500",
                  }}
                >
                  Manage
                </Typography>
                <Box
                  className={styles.manageList}
                  sx={{
                    width: { xs: "95vw", sm: 550, lg: "auto", md: "auto" },
                    height: {
                      lg: "auto",
                    },
                    overflowY: {
                      xs: "auto",
                      lg: "hidden",
                      md: "auto",
                      sm: "auto",
                    },
                    paddingX: "20px",
                  }}
                >
                  <List
                    sx={{
                      fontSize: { xs: 2 },

                      display: {
                        xs: "-webkit-box",
                        sm: "flex",
                        md: "block",
                        lg: "block",
                      },
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                      },
                      overflowY: {
                        xs: "auto",
                        lg: "hidden",
                      },
                      padding: { xs: 0 },
                      pointerEvents: buttonDisabled ? "none" : "auto",
                      ".MuiTypography-root": {
                        fontSize: "0.8rem !important",
                      },
                    }}
                  >
                    {[
                      {
                        sectionName: "Institute Profile",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "INSTITUTE_PROFILE",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Branches",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "BRANCHES",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Teams",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "TEAMS",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Courses",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "COURSES",
                          userById?.rolePermission
                        ),
                      },

                      {
                        sectionName: "Batches",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "BATCHES",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Billing",
                        allAccess: checkSubDomainAccess(
                          "MANAGE",
                          "BILLING",
                          userById?.rolePermission
                        ),
                      },
                    ]
                      .filter((item) => item !== null)
                      .map((item, index) => (
                        <GeneralSetup
                          key={index}
                          handleStatus={handleStatus}
                          index={index}
                          isActive={isActive}
                          disabled={
                            instituteData?.profileDetailsCompleted === false &&
                            instituteData?.branchCreated === false
                              ? true
                              : instituteData?.profileDetailsCompleted ===
                                  true && instituteData?.branchCreated === false
                              ? true
                              : instituteData?.courseCreated === false &&
                                instituteData?.batchCreated === false &&
                                item?.sectionName === "Courses"
                              ? false
                              : instituteData?.courseCreated === true &&
                                instituteData?.batchCreated === false &&
                                item?.sectionName === "Batches"
                              ? false
                              : buttonDisabled ||
                                (shouldDisableGeneralSetup &&
                                  item?.sectionName !== "Billing")
                          }
                          item={item?.sectionName}
                          itemAllAccess={item?.allAccess}
                        />
                      ))}
                  </List>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    paddingX: "34px",
                    fontSize: "18px",
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontWeight: "500",
                  }}
                >
                  My Profile
                </Typography>
                <Box
                  sx={{
                    width: { xs: "95vw", sm: 550, lg: "auto", md: "auto" },
                    height: {
                      lg: "auto",
                    },
                    overflowY: {
                      xs: "auto",
                      lg: "hidden",
                      md: "auto",
                      sm: "auto",
                    },
                    paddingX: "20px",
                  }}
                >
                  <List
                    sx={{
                      fontSize: { xs: 2 },

                      display: {
                        xs: "-webkit-box",
                        sm: "flex",
                        md: "block",
                        lg: "block",
                      },
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                      },
                      overflowY: {
                        xs: "auto",
                        lg: "hidden",
                      },
                      padding: { xs: 0 },
                      pointerEvents: buttonDisabled ? "none" : "auto",
                      ".MuiTypography-root": {
                        fontSize: "0.8rem !important",
                      },
                    }}
                  >
                    {["User Details", "Change Password", "Notifications"].map(
                      (item, index) => (
                        <GeneralSetup
                          key={index + 6}
                          item={item}
                          handleStatus={handleStatus}
                          index={index + 6}
                          isActive={isActive}
                          disabled={buttonDisabled}
                        />
                      )
                    )}
                  </List>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              lg={9.5}
              md={9}
              xs={12}
              sm={12}
              className={styles.grid_border}
              style={{
                overflowY: "hidden",
                paddingLeft: "0px",
                paddingRight: "15px",
                padding: !userFromLocalStorage.isProfileCompleted ? "" : "0px ",
              }}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                  md: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
              }}
            >
              <ManageRouter
                setIsActive={setIsActive}
                shouldDisableGeneralSetup={shouldDisableGeneralSetup}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
