import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  CircularProgress,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import styles from "./Payment.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import FilterChipsWrap from "../FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../FilterDrawer/FilterChips/FilterChips";
import FilterTypeWrap from "../FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../FilterDrawer/FilterCheckbox/FilterCheckbox";
import ClearFilter from "../ClearFilter/ClearFilter";
import FilterLabel from "../FilterDrawer/FilterLabel/FilterLabel";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";
import EditLeadMembership from "../../pages/app/Nurture/components/PaymentType/Membership/EditLeadMembership";
import LeadRandomPayments from "../../pages/app/Nurture/components/PaymentType/RandomPayments/LeadRandomPayments";
import LeadMembershipDetails from "../../pages/app/Nurture/components/PaymentType/Membership/LeadMembershipDetails";
import LeadRegularPayDetails from "../../pages/app/Nurture/components/PaymentType/Regular/LeadRegularPayDetails";
import EditRegularPayDetails from "../../pages/app/Nurture/components/PaymentType/Regular/EditRegularPayDetails";
import LeadPaySkeleton from "../Skeletons/LeadPay/LeadPaySkeleton";
import LeadTransactionSkeleton from "../Skeletons/LeadPay/LeadTransactionSkeleton";
import TruncatedText from "../TruncatedText/TruncatedText";
import {
  downloadPaymentReceipt,
  getEmiDataOfLead,
  getLeadPaymentList,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import LeadSessionDetail from "../../pages/app/Nurture/components/PaymentType/Session/LeadSessionDetail";
import EditLeadSession from "../../pages/app/Nurture/components/PaymentType/Session/EditLeadSession";

const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: "16px 12px",
  fontSize: "14px",
  color: "#69696B",
  fontWeight: "500",
}));

interface Column {
  id:
    | "generationDate"
    | "description"
    | "invoice"
    | "amount"
    | "status"
    | "serialNumber"
    | "paymentId"
    | "transactionDate"
    | "source";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const paymentStatusFilters = [
  {
    transactionStatus: "PAID",
    labelName: "Success",
  },
  {
    transactionStatus: "FAILED",
    labelName: "Failed",
  },
  {
    transactionStatus: "REQUESTED",
    labelName: "pending",
  },
  {
    transactionStatus: "CANCELLED",
    labelName: "cancelled",
  },
  {
    transactionStatus: "EXPIRED",
    labelName: "expired",
  },
];

const Payment = (props: any) => {
  const {
    leadById,
    overviewTab,
    RecordPayment,
    SendPaymentLink,
    EditRecordPayment,
    selectedCurrency,
  } = props;
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);
  const {
    leadEmiDetailsSuccess,
    leadListLoading,
    leadListByContact,
    getEmiDataloading,
    paymentListLoading,
    downloadPaymentReceiptLoading,
    downloadPaymentReceiptSuccess,
    downloadPaymentReceiptError,
    leadEmiDetailsLoading,
    leadEmiData,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
  } = useSelector((state: any) => state.nurture);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const leadAllPaymentList = useSelector(
    (state: any) => state.nurture.leadPaymentList
  );
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const filteredPaymentRequests = leadAllPaymentList?.filter(
    (item: any) =>
      (item?.paymentStatus === "REQUESTED" ||
        (item?.paymentStatus === "FAILED" &&
          item?.autoDebitPayment === false)) &&
      item.paymentType !== "DOWNPAYMENT" &&
      item.paymentType !== "RANDOM_PAYMENT"
  );
  const filteredNonDownPaymentRequests = leadAllPaymentList?.filter(
    (item: any) =>
      item.paymentType !== "DOWNPAYMENT" &&
      item.paymentType !== "RANDOM_PAYMENT"
  );

  const isFirstPaymentRequestedOrFailed =
    filteredNonDownPaymentRequests?.[0]?.paymentStatus === "REQUESTED" ||
    filteredNonDownPaymentRequests?.[0]?.paymentStatus === "FAILED";

  const filteredRandomPayRequests = leadAllPaymentList?.filter(
    (item: any) =>
      (item?.paymentStatus === "REQUESTED" ||
        item?.paymentStatus === "FAILED") &&
      item.paymentType === "RANDOM_PAYMENT"
  );
  const lastTransaction = filteredNonDownPaymentRequests?.[0];

  const columns: readonly Column[] = [
    { id: "serialNumber", label: "#", minWidth: 50 },
    { id: "paymentId", label: "Payment ID", minWidth: 130 },
    { id: "generationDate", label: "Generation Date", minWidth: 180 },
    { id: "description", label: "Description", minWidth: 180 },
    {
      id: "amount",
      label: "Amount",
      minWidth: 130,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 140,
    },
    { id: "transactionDate", label: "Transaction  Date", minWidth: 180 },

    {
      id: "source",
      label: "source",
      minWidth: 120,
    },
    {
      id: "invoice",
      label: instituteFromRedux?.isStudentTaxInvoiceEnabled
        ? "invoice"
        : "receipt",
      minWidth: 140,
    },
  ];

  const [isPayEditOpen, setIsPayEditOpen] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>("");
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const [checkedPaymentStatuses, setCheckedPaymentStatuses] = useState<
    CheckedPaymentStatuses[]
  >([]);
  const [filterPaymentStatuses, setFilterPaymentStatuses] = useState<
    CheckedPaymentStatuses[]
  >([]);

  const handleDownloadReceipt = async (
    event: any,
    paymentId: string,
    type: any
  ) => {
    event.preventDefault();

    try {
      const body = {
        paymentId: paymentId,
        documentType: type ? "INVOICE" : "RECEIPT",
        actionToPerform: "DOWNLOAD",
        sendReceipt: true,
      };
      if (body?.paymentId) {
        dispatch<any>(downloadPaymentReceipt(body));
      }
      setPaymentId(paymentId);
    } catch (error) {}
  };

  interface CheckedPaymentStatuses {
    transactionStatus: string;
  }

  const filteredLeadAllPaymentList = leadAllPaymentList?.filter((item: any) => {
    const transactionStatusIsChecked =
      filterPaymentStatuses.length === 0 ||
      filterPaymentStatuses?.some(
        (filterItem) =>
          filterItem.transactionStatus.toLowerCase() ===
          item.paymentStatus.toLowerCase()
      );

    return transactionStatusIsChecked;
  });

  const handleFilterTransactionStatusChecked = (transactionStatus: string) => {
    const paymentStatusToAdd: CheckedPaymentStatuses = {
      transactionStatus,
    };

    if (
      checkedPaymentStatuses.some(
        (item) =>
          item.transactionStatus.toLowerCase() ===
          transactionStatus.toLowerCase()
      )
    ) {
      setCheckedPaymentStatuses(
        checkedPaymentStatuses.filter(
          (item) =>
            item.transactionStatus.toLowerCase() !==
            transactionStatus.toLowerCase()
        )
      );
    } else {
      setCheckedPaymentStatuses([
        ...checkedPaymentStatuses,
        paymentStatusToAdd,
      ]);
    }
  };

  const clearCheckedTransactionStatus = (transactionStatus: string) => {
    setCheckedPaymentStatuses(
      checkedPaymentStatuses.filter(
        (item) => item.transactionStatus !== transactionStatus
      )
    );
  };

  const handleFilterTransactionHistory = React.useCallback(
    (typeArray: any) => {
      setFilterPaymentStatuses(typeArray);
    },
    [setFilterPaymentStatuses]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterTransactionHistory(checkedPaymentStatuses);

    setFilterApplied(checkedPaymentStatuses?.length === 0 ? false : true);
  }, [
    handleFilterTransactionHistory,
    setFilterApplied,
    checkedPaymentStatuses,
  ]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setFilterPaymentStatuses([]);
    setCheckedPaymentStatuses([]);
  }, [setFilterApplied, setCheckedPaymentStatuses, setFilterPaymentStatuses]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedPaymentStatuses(filterPaymentStatuses);
  }, [filterPaymentStatuses]);

  const filteredValue = (
    <>
      {filterPaymentStatuses?.length > 0 &&
        filterPaymentStatuses?.map((item) => (
          <div key={item.transactionStatus} className="filteredDiv">
            {item.transactionStatus?.toLowerCase()}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const handleRefresh = () => {
    leadById && dispatch<any>(getLeadPaymentList(leadById?.id));
    setRotate(!rotate);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const leadModalOpen = urlSearchParams.get("leadModal");
    const modalTabOpen = urlSearchParams.get("tabOpen");

    const leadId = urlSearchParams.get("leadId");

    if (leadModalOpen && leadId && modalTabOpen === "payment" && leadById) {
      dispatch<any>(
        getEmiDataOfLead({
          id: leadId,
          loadSessionPayment:
            leadById?.paymentDetails?.paymentType === "SESSION" ? true : false,
        })
      );
    }
  }, [window.location.search, leadById]);

  React.useEffect(() => {
    if (
      (downloadPaymentReceiptSuccess !== true &&
        downloadPaymentReceiptSuccess !== false) ||
      (downloadPaymentReceiptError !== true &&
        downloadPaymentReceiptError !== false)
    ) {
      setPaymentId("");
    }
  }, [downloadPaymentReceiptSuccess, downloadPaymentReceiptError]);

  React.useEffect(() => {
    if (leadEmiDetailsSuccess !== true && leadEmiDetailsSuccess !== false) {
      setIsPayEditOpen(false);
    }
  }, [leadEmiDetailsSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const modalTabOpen = urlSearchParams.get("tabOpen");
    if (
      (sessionPayLinkSuccess !== true &&
        sessionPayLinkSuccess !== false &&
        modalTabOpen === "payment") ||
      (sessionPayRecordSuccess !== true &&
        sessionPayRecordSuccess !== false &&
        modalTabOpen === "payment")
    ) {
      if (leadById) dispatch<any>(getLeadPaymentList(leadById?.id));
    }
  }, [sessionPayLinkSuccess, sessionPayRecordSuccess, leadById]);

  return (
    <Box>
      <div style={{ background: "#fff", padding: "30px 26px" }}>
        {leadById !== "" &&
        leadListByContact !== null &&
        !getEmiDataloading &&
        !leadListLoading &&
        leadEmiData !== null ? (
          <>
            <LeadRandomPayments
              leadById={leadById}
              randomPayments={leadEmiData?.randomPayments}
              filteredRandomPayRequests={filteredRandomPayRequests}
              hasLeadAccess={hasLeadAccess}
              selectedCurrency={selectedCurrency}
            />
            <div className={styles.leadPayHeadSplit}>
              <div className={styles.leadPayHeadLeft}>
                <div className={styles.leadPayHeadTitle}>Payment Details</div>
                {leadById?.paymentDetails?.paymentType && (
                  <div className={styles.leadPayHeadType}>
                    {leadById?.paymentDetails?.paymentType?.toLowerCase()}
                  </div>
                )}
              </div>
            </div>
            {leadById?.batchId === "" ? (
              <div className={styles.noBatch}>
                To avail the payment options, Please select a batch first.
              </div>
            ) : (
              <>
                {leadById?.paymentDetails?.paymentType === "REGULAR" && (
                  <>
                    {!isPayEditOpen ? (
                      <LeadRegularPayDetails
                        leadData={leadById}
                        setIsPayEditOpen={setIsPayEditOpen}
                        leadEmiData={leadEmiData?.emiToReturn}
                        RecordPayment={RecordPayment}
                        SendPaymentLink={SendPaymentLink}
                        EditRecordPayment={EditRecordPayment}
                        filteredPaymentRequests={filteredPaymentRequests}
                        isFirstPaymentRequestedOrFailed={
                          isFirstPaymentRequestedOrFailed
                        }
                        lastTransaction={lastTransaction}
                        activityData={leadEmiData?.paymentUpdateActivity}
                        hasLeadAccess={hasLeadAccess}
                        selectedCurrency={selectedCurrency}
                      />
                    ) : (
                      <EditRegularPayDetails
                        leadData={leadById}
                        setIsPayEditOpen={setIsPayEditOpen}
                        leadEmiDetailsLoading={leadEmiDetailsLoading}
                        isPayEditOpen={isPayEditOpen}
                        selectedCurrency={selectedCurrency}
                      />
                    )}
                  </>
                )}
                {leadById?.paymentDetails?.paymentType === "MEMBERSHIP" && (
                  <>
                    {leadById?.paymentDetails?.paymentStructure?.some(
                      (item: any) =>
                        item.hasOwnProperty("selected") &&
                        item.selected === true
                    ) ? (
                      !isPayEditOpen ? (
                        <LeadMembershipDetails
                          leadReccuringPaymentData={leadEmiData}
                          leadData={leadById}
                          setIsPayEditOpen={setIsPayEditOpen}
                          leadEmiData={leadEmiData?.emiToReturn}
                          RecordPayment={RecordPayment}
                          SendPaymentLink={SendPaymentLink}
                          filteredPaymentRequests={filteredPaymentRequests}
                          EditRecordPayment={EditRecordPayment}
                          isFirstPaymentRequestedOrFailed={
                            isFirstPaymentRequestedOrFailed
                          }
                          lastTransaction={lastTransaction}
                          activityData={leadEmiData?.paymentUpdateActivity}
                          hasLeadAccess={hasLeadAccess}
                          selectedCurrency={selectedCurrency}
                        />
                      ) : (
                        <EditLeadMembership
                          leadData={leadById}
                          setIsPayEditOpen={setIsPayEditOpen}
                          leadEmiDetailsLoading={leadEmiDetailsLoading}
                          selectedCurrency={selectedCurrency}
                        />
                      )
                    ) : (
                      <>
                        {hasLeadAccess ? (
                          <EditLeadMembership
                            leadData={leadById}
                            leadEmiDetailsLoading={leadEmiDetailsLoading}
                            selectedCurrency={selectedCurrency}
                          />
                        ) : (
                          <div>No access</div>
                        )}
                      </>
                    )}
                  </>
                )}

                {leadById?.paymentDetails?.paymentType === "SESSION" && (
                  <>
                    {!isPayEditOpen ? (
                      <LeadSessionDetail
                        leadData={leadById}
                        setIsPayEditOpen={setIsPayEditOpen}
                        hasLeadAccess={hasLeadAccess}
                        leadEmiData={leadEmiData}
                        SendPaymentLink={SendPaymentLink}
                        EditRecordPayment={EditRecordPayment}
                        isFirstPaymentRequestedOrFailed={
                          isFirstPaymentRequestedOrFailed
                        }
                        filteredPaymentRequests={filteredPaymentRequests}
                        selectedCurrency={selectedCurrency}
                      />
                    ) : (
                      <EditLeadSession
                        leadData={leadById}
                        isPayEditOpen={isPayEditOpen}
                        setIsPayEditOpen={setIsPayEditOpen}
                        leadEmiDetailsLoading={leadEmiDetailsLoading}
                        selectedCurrency={selectedCurrency}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <LeadPaySkeleton />
          </>
        )}
      </div>

      {leadById !== "" &&
      leadListByContact !== null &&
      !paymentListLoading &&
      !leadListLoading &&
      leadAllPaymentList !== null ? (
        <>
          {leadAllPaymentList?.length === 0 && !overviewTab && (
            <Box
              sx={{
                background: "#fff",
                padding: "20px 24px",
                borderRadius: "3PX",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  color: "#142C44",
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  marginBottom: "10px",
                }}
              >
                Transaction History
              </div>
              <div
                style={{
                  color: "#142C44",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  padding: "40px 0",
                  textAlign: "center",
                }}
              >
                No payment has been recorded yet
              </div>
            </Box>
          )}

          {leadAllPaymentList?.length > 0 && !overviewTab ? (
            <Box
              sx={{
                background: "#fff",
                padding: "20px 24px",
                borderRadius: "3PX",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#142C44",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  Transaction History
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={handleRefresh}
                    style={{
                      border: "1px solid #d0d5dd",
                      marginRight: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <RefreshIcon
                      style={{
                        color: "grey",
                        transform: rotate
                          ? "rotate(360deg)"
                          : "rotate(-360deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </div>
                  <FilterDrawer
                    handleFilterApply={handleFilterApply}
                    handleResetClick={handleResetClick}
                    activeFilter={filterApplied}
                    cancelClick={cancelClick}
                    closeSetFilter={handleFilterClose}
                  >
                    <div
                      style={{
                        padding: "25px 30px",
                        overflowY: "auto",
                        maxHeight: "calc(100% - 110px) ",
                      }}
                    >
                      <FilterChipsWrap>
                        {checkedPaymentStatuses?.length > 0 &&
                          checkedPaymentStatuses?.map((item) => (
                            <FilterChips
                              keyVal={item.transactionStatus}
                              chipName={item.transactionStatus?.toLowerCase()}
                              clearValue={item.transactionStatus}
                              closeFunction={clearCheckedTransactionStatus}
                            />
                          ))}
                      </FilterChipsWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Status" />
                        <FilterCheckBoxWrap scroll={false}>
                          <FormGroup>
                            {paymentStatusFilters?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.transactionStatus}
                                checked={checkedPaymentStatuses?.some(
                                  (data) =>
                                    data.transactionStatus.toLowerCase() ===
                                    item.transactionStatus.toLowerCase()
                                )}
                                checkboxFunction={() =>
                                  handleFilterTransactionStatusChecked(
                                    item.transactionStatus
                                  )
                                }
                                label={item?.labelName?.toLowerCase()}
                              />
                            ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                    </div>
                  </FilterDrawer>
                  <ClearFilter
                    open={isOpenFilter}
                    noButtonClick={noButtonClick}
                    yesButtonClick={yesButtonClick}
                    onClose={closeClearFilter}
                    filteredValue={filteredValue}
                  />
                </div>
              </div>

              {leadAllPaymentList && (
                <div
                  style={{
                    border: "1px solid #EAECF0",
                    borderRadius: "8PX",
                    overflow: "hidden",
                  }}
                >
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650, borderRadius: 0 }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          {columns?.map((column) => (
                            <CustomTableHeaderCell
                              key={column.id}
                              align={column?.align}
                              style={{ minWidth: column.minWidth }}
                              sx={{
                                background: "#F9FAFB",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {column.label}
                            </CustomTableHeaderCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filteredLeadAllPaymentList?.length === 0 &&
                          filterApplied && (
                            <TableRow>
                              <TableCell
                                colSpan={columns.length}
                                style={{
                                  lineHeight: "24px",
                                  textAlign: "center",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  color: " #667085",
                                }}
                              >
                                No results found.
                              </TableCell>
                            </TableRow>
                          )}
                        {leadAllPaymentList &&
                          filteredLeadAllPaymentList?.map(
                            (row: any, index: number) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {`T${row?.id?.slice(-8)}`}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {moment(row.createdDate).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </TableCell>
                                <TableCell
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {row?.description ? (
                                    <>
                                      {row?.description.length > 30 ? (
                                        <Tooltip
                                          arrow
                                          title={row?.description}
                                          PopperProps={{
                                            style: { zIndex: 9999999 },
                                          }}
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                bgcolor: "#101828",
                                                color: "#fff",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "8px 12px",
                                                zIndex: "9999999",
                                                "& .MuiTooltip-arrow": {
                                                  color: "#101828",
                                                  zIndex: "9999999",
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <span>
                                            <TruncatedText
                                              text={row?.description}
                                              maxLength={30}
                                            />
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        row?.description
                                      )}
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        width: "70px",
                                        textAlign: "center",
                                      }}
                                    >
                                      -
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={styles.paymenyBodyRowItem}
                                  style={{ fontFamily: "Roboto" }}
                                >
                                  {selectedCurrency + " " + row?.amountReceived}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.paymenyBodyRowItem}
                                >
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      padding: "2px 8px",
                                      borderRadius: "16PX",
                                      width: "fit-content",
                                      textTransform: "capitalize",
                                      color:
                                        row?.paymentStatus === "PAID"
                                          ? "#3DB985"
                                          : row?.paymentStatus === "REQUESTED"
                                          ? "#EBA418"
                                          : row?.paymentStatus === "CANCELLED"
                                          ? "#E86427"
                                          : row?.paymentStatus === "EXPIRED"
                                          ? "#2664DC"
                                          : row?.paymentStatus === "FAILED"
                                          ? "#EA2D1F"
                                          : "",
                                      background:
                                        row?.paymentStatus === "PAID"
                                          ? "#ECFDF3"
                                          : row?.paymentStatus === "REQUESTED"
                                          ? "#FFFAEB"
                                          : row?.paymentStatus === "CANCELLED"
                                          ? "rgba(232, 100, 39, 0.08)"
                                          : row?.paymentStatus === "EXPIRED"
                                          ? "rgba(38, 100, 220, 0.12)"
                                          : row?.paymentStatus === "FAILED"
                                          ? "#FEF3F2"
                                          : "",
                                    }}
                                  >
                                    {row?.paymentStatus === "PAID"
                                      ? "success"
                                      : row?.paymentStatus === "REQUESTED"
                                      ? "pending"
                                      : row?.paymentStatus === "CANCELLED"
                                      ? "cancelled"
                                      : row?.paymentStatus === "EXPIRED"
                                      ? "expired"
                                      : row?.paymentStatus === "FAILED"
                                      ? `failed`
                                      : ""}
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {row?.paymentDate ? (
                                    <>
                                      {row?.paymentDate?.toString()?.length ===
                                      10
                                        ? moment
                                            ?.unix(row?.paymentDate)
                                            .format("DD MMM YYYY, hh:mm A")
                                        : moment(row?.paymentDate).format(
                                            "DD MMM YYYY, hh:mm A"
                                          )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>

                                <TableCell
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {row?.paymentStatus === "PAID" ||
                                  row?.paymentStatus === "FAILED"
                                    ? row?.paymentSource
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={styles.paymenyBodyRowItem}
                                >
                                  {row?.paymentStatus === "PAID" ? (
                                    <Tooltip
                                      arrow
                                      title={
                                        hasLeadAccess === false
                                          ? "Oops! No permission."
                                          : ""
                                      }
                                      PopperProps={{
                                        style: { zIndex: 9999999 },
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: "#101828",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            padding: "8px 12px",
                                            zIndex: "9999999",
                                            "& .MuiTooltip-arrow": {
                                              color: "#101828",
                                              zIndex: "9999999",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <span>
                                        <div
                                          style={{
                                            color: "#2664DC",
                                            textDecoration: "underline",
                                            fontSize: "13px",
                                            cursor:
                                              hasLeadAccess === true
                                                ? "pointer"
                                                : "auto",
                                          }}
                                          onClick={(event) =>
                                            hasLeadAccess === true
                                              ? handleDownloadReceipt(
                                                  event,
                                                  row.id,
                                                  row?.invoiceNumber
                                                    ? true
                                                    : false
                                                )
                                              : null
                                          }
                                        >
                                          {paymentId === row.id &&
                                          downloadPaymentReceiptLoading ? (
                                            <CircularProgress
                                              size={25}
                                              color={"inherit"}
                                            />
                                          ) : (
                                            ` ${
                                              row?.invoiceNumber
                                                ? row?.invoiceNumber
                                                : row?.receiptNumber
                                                ? row?.receiptNumber
                                                : row?.id.slice(-8)
                                            }`
                                          )}
                                        </div>
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <LeadTransactionSkeleton />
      )}
    </Box>
  );
};
export default Payment;
