import React from "react";
import { Box, Button } from "@mui/material";
import styles from "./Integrations.module.css";
import ozonetel from "./Assets/ozonetel.svg";
import { useNavigate } from "react-router-dom";
import nurturePay from "./Assets/nurturePay.svg";
import { useAuthUser } from "react-auth-kit";
import zapierLogo from "./Assets/zapierFav.svg";
import { useSelector } from "react-redux";
import getAccessStatus from "../../../../components/RolePermissionAccess/RolePermissionAccess";

export default function Integrations(props: any) {
  const { GetAppsByInstitute, appsList } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const { userById } = useSelector((state: any) => state.userDetails);

  const instituteDetails = authUser?.institute?.[0]?.instituteDetails;

  const instituteFromLocal = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const hasOzonetel =
    instituteFromLocal &&
    Array?.isArray(instituteFromLocal?.integrationsOpted) &&
    instituteFromLocal?.integrationsOpted?.includes("OZONETEL");

  const hasZapier =
    instituteFromLocal &&
    Array?.isArray(instituteFromLocal?.integrationsOpted) &&
    instituteFromLocal?.integrationsOpted?.includes("ZAPIER");

  const integrationPermissions = userById?.rolePermission?.find(
    (item: any) => item.roleDomainName === "Integrations"
  );

  const nurturePayApp = appsList?.find(
    (app: any) => app.appName === "NURTURE_PAY_EASEBUZZ"
  );

  const integrationItems = [
    {
      id: "1",
      logo: nurturePay,
      name: "Nurture Pay",
      description:
        "nurturePay provides a smooth and hassle-free online payment process, ensuring data security and reducing the risk of fraud.",
      btnText: "Setup Now",
      btnUrl: "/app/Integrations/NurturePayDetails",
    },
    {
      id: "2",
      logo: ozonetel,
      name: "Ozonetel",
      description:
        "Integrate Ozonetel's cloud communication solutions into nurture to elevate lead engagement, leveraging call center tools.",
      btnText: hasOzonetel ? "View" : "Setup Now",
      btnUrl: hasOzonetel
        ? "/app/Integrations/OzonetelStages"
        : "/app/Integrations/OzonetelDetails",
    },
    {
      id: "3",
      logo: zapierLogo,
      name: "Zapier",
      description:
        "Zapier is essential for streamlining workflows through automation and integration. By connecting various web applications and...",
      btnText: hasZapier ? "View" : "Setup Now",
      btnUrl: hasZapier
        ? "/app/Integrations/ZapierIntegration"
        : "/app/Integrations/ZapierDetails",
    },
  ];

  React.useEffect(() => {
    if (instituteDetails) {
      GetAppsByInstitute(instituteDetails?.id);
    }
  }, [GetAppsByInstitute]);

  return (
    <Box
      sx={{
        overflow: "auto",
        padding: "14px 16px",
        background: "#EEEEEE",
        maxHeight: "calc(100vh - 65px)",
        position: "relative",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          background: "#fff",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          minHeight: "calc(100vh - 94px)",
        }}
      >
        <Box sx={{ padding: "30px" }}>
          <div className={styles.integrationHead}>Integrations</div>
          <div className={styles.integrationItemsWrapper}>
            {integrationItems?.map((item: any, index: any) => (
              <div className={styles.integrationItemWrap} key={index}>
                <div
                  style={{
                    display: "flex",
                    width: "43px",
                    padding: "6px 6px 7px 7px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    background: "rgba(177, 177, 177, 0.10)",
                    marginBottom: "10px",
                    height: "43px",
                  }}
                >
                  <img
                    src={item?.logo}
                    alt="logo"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <div className={styles.integrationItemHead}>{item?.name}</div>
                <div className={styles.integrationItemDescription}>
                  {item?.description}
                </div>
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize", minWidth: "110px" }}
                  onClick={() => navigate(`${item?.btnUrl}`)}
                  disabled={
                    getAccessStatus(
                      "INTEGRATIONS",
                      "ALL_ACCESS",
                      userById?.rolePermission
                    ) === false
                      ? true
                      : false
                  }
                >
                  {item?.name === "Nurture Pay" &&
                  (nurturePayApp?.status === "UNDER_REVIEW" ||
                    nurturePayApp?.status === "SUBMITTED_FOR_KYC" ||
                    nurturePayApp?.status === "ACTIVE")
                    ? "View"
                    : item?.btnText}
                </Button>
              </div>
            ))}
          </div>
        </Box>
      </Box>
    </Box>
  );
}
