import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileUploadRequest } from "../../pages/app/Main/components/FileUpload/service/fileUpload.request";
import styles from "./FileDrop.module.css";
import { CircularProgress } from "@mui/material";
import { setToastNotification } from "../ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import CropperModalCom from "../DragCrop/CropperModalCom";
import Cropper from "react-cropper";
import SaveBtn from "../Buttons/SaveBtn";
import CancelBtn from "../Buttons/CancelBtn";

function FieldFileDropZone(props: any) {
  const {
    accept = { "image/*": [] },
    maxSize = 2 * 1024 * 1024, // Default 2MB
    maxResolution = { width: 1280, height: 720 },
    field,
    onFieldChange,
    disabled = false,
    crop = false,
  } = props;
  const dispatch = useDispatch();

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const cropperRef = useRef<any>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [convertedPreview, setConvertedPreview] = useState<string | null>(null);

  const handleChange = async (file: File) => {
    if (!file) {
      dispatch(
        setToastNotification({
          message: "No file selected.",
          type: "error",
          snackOpen: true,
        })
      );
      return;
    }

    if (file.size > maxSize) {
      dispatch(
        setToastNotification({
          message: `File size must be less than ${maxSize / (1024 * 1024)} MB.`,
          type: "error",
          snackOpen: true,
        })
      );
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const { width, height } = img;

      if (width > maxResolution.width || height > maxResolution.height) {
        dispatch(
          setToastNotification({
            message: `File resolution must not exceed ${maxResolution.width}x${maxResolution.height}.`,
            type: "error",
            snackOpen: true,
          })
        );
        return;
      }

      setPreview(img.src);
      if (crop) {
        setIsCrop(true);
      } else {
        uploadImage(img.src, file.name);
      }
    };

    img.onerror = () => {
      setToastNotification({
        message: "Failed to load image. Please try again.",
        type: "error",
        snackOpen: true,
      });
    };
  };

  const uploadImage = async (base64String: string, fileName: string) => {
    const body = {
      fileBase64String: base64String,
      fileName,
      fileType: "ADDITIONAL_LEAD_DOCUMENT",
      instituteId: activeInstitute?.id,
    };

    try {
      setLoader(true);
      const response = await FileUploadRequest(body);
      const fileBase64 = response.data?.data?.location;

      onFieldChange(field.id, fileBase64, true); // Update field value
      dispatch(
        setToastNotification({
          message: response?.data?.message || "File uploaded successfully",
          type: "success",
          snackOpen: true,
        })
      );
      setConvertedPreview(fileBase64);
    } catch (error: any) {
      dispatch(
        setToastNotification({
          message: error?.response?.data?.message || "An error occurred",
          type: "error",
          snackOpen: true,
        })
      );
      setConvertedPreview(null);
    } finally {
      setLoader(false);
    }
  };

  const getCropData = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedBase64 = cropper.getCroppedCanvas().toDataURL();
      setPreview(croppedBase64);
      setIsCrop(false);
      uploadImage(croppedBase64, "cropped-image.png");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      handleChange(file);
    },
  });

  return (
    <div>
      <div
        {...getRootProps({
          style: {
            border: "2px dashed #ccc",
            borderRadius: "8px",
            padding: "16px",
            textAlign: "center",
            cursor: disabled ? "not-allowed" : "pointer",
            backgroundColor: disabled ? "#f9f9f9" : "#fff",
          },
        })}
      >
        <input {...getInputProps()} />
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
              width: "100%",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            <div className={styles.dragDropText}>
              Drag and drop an image, or{" "}
              <span style={{ color: "#1A73E8" }}>Browse</span>
            </div>
            <div className={styles.dragDropRequirement}>
              JPG or PNG (Max size: 2MB)
            </div>
            {convertedPreview && (
              <img
                src={convertedPreview}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: crop ? "50%" : "8px",
                }}
              />
            )}
          </>
        )}
      </div>
      {isCrop && (
        <>
          <CropperModalCom open={isCrop} setIsCrop={setIsCrop}>
            <Cropper
              ref={cropperRef}
              style={{ height: 400, width: "100%", zIndex: 9999999999 }}
              aspectRatio={1}
              src={preview || ""}
              viewMode={1}
              zoomTo={0.5}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              dragMode="move"
              guides={true}
              className="round-cropper"
            />
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <CancelBtn
                onClick={() => {
                  setIsCrop(false);
                  setPreview(null);
                }}
              />
              <SaveBtn buttonText={"Crop"} onClick={getCropData} />
            </div>
          </CropperModalCom>
        </>
      )}
    </div>
  );
}

export default FieldFileDropZone;
