import React, { useCallback, useEffect, useState } from "react";
import styles from "../../Dashboard.module.css";
import { Link } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { GetOverduePayments } from "../../service/dashboard.request";
import moment from "moment";
import { getCourse } from "../../../../../components/CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";
import {
  getLeadListByInstituteAndContact,
  setLeadModalState,
} from "../../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import noResults from "../../../../../assets/images/no-results.svg";

type overdueData = {
  instituteId: any;
  branchId: any;
  limit: number;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

function OverduePayments(props: any) {
  const { overduePaymentsData, selectedBranch, selectedCurrency } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const courseList = useSelector((state: any) => state.courses.courses);

  const [overduePaymentItems, setOverduePaymentItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(true);

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      overduePaymentItems?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      overduePaymentItems?.lastEvaluatedKeyReceived?.GSI6SK?.S
    );
    const data: overdueData = {
      instituteId: userFromLocalStorage?.instituteId,
      branchId: selectedBranch?.id,
      limit: 15,
      lastEvaluatedKey: lastEvaluatedKey,
      lastEvaluatedSortKey: lastEvaluatedSortKey,
    };

    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetOverduePayments(data)
        ?.then((res: any) => {
          setHasMoreData(true);
          setOverduePaymentItems((prevItems: any) => ({
            overdueEmiToReturn: [
              ...prevItems?.overdueEmiToReturn,
              ...res?.data?.data?.overdueEmiToReturn,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;

      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      leadId: string,
      phone: string,
      email: string,
      emiId: string
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", leadId);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "payment");

      queryParams.set("leadEmiId", emiId);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },

    []
  );
  React.useEffect(() => {
    if (overduePaymentsData) {
      setOverduePaymentItems(overduePaymentsData);
    }
  }, [overduePaymentsData]);

  useEffect(() => {
    if (
      overduePaymentItems &&
      overduePaymentItems?.lastEvaluatedKeyReceived &&
      overduePaymentItems?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [overduePaymentItems]);

  return (
    <div className={styles.overduePaymentsWrapper}>
      <div className={styles.overduePaymentsHead}>
        <div className={styles.overduePaymentsHeadTitle}>Overdue Payments</div>
        {/* <div className={styles.overduePaymentsHeadBtn}>
          <Link
            style={{
              width: "100%",
              display: "block",
              color: "#667085",
              fontSize: "13px",
              fontWeight: "500",
              textDecorationLine: "underline",
            }}
            to="/app/Tasks"
          >
            View All
          </Link>
        </div> */}
      </div>
      <div className={styles.overduePaymentsTable}>
        <div className={styles.overduePaymentsTableHead}>
          <div
            className={styles.overduePaymentsTableHeadItem}
            style={{ width: "35%", maxWidth: "35%" }}
          >
            Name
          </div>
          <div
            className={styles.overduePaymentsTableHeadItem}
            style={{ width: "35%", maxWidth: "35%" }}
          >
            Course Name
          </div>
          <div
            className={styles.overduePaymentsTableHeadItem}
            style={{ width: "12%", maxWidth: "12%" }}
          >
            Amount
          </div>
          <div
            className={styles.overduePaymentsTableHeadItem}
            style={{ width: "15%", maxWidth: "15%" }}
          >
            Due Date
          </div>
        </div>

        <div
          className={styles.overduePaymentsTableBody}
          style={{ height: "305px", overflowY: "auto" }}
        >
          {overduePaymentItems?.overdueEmiToReturn?.length > 0 ? (
            <>
              {overduePaymentItems?.overdueEmiToReturn?.length > 0 && (
                <InfiniteScroll
                  dataLength={
                    overduePaymentItems?.overdueEmiToReturn?.length
                      ? overduePaymentItems?.overdueEmiToReturn?.length
                      : overduePaymentsData?.returnData?.length
                  }
                  next={handleNextPage}
                  hasMore={hasMoreData}
                  loader={
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <PuffLoader color="#36d7b7" /> */}
                    </Box>
                  }
                  height={"305px"}
                  style={{ scrollBehavior: "smooth" }}
                >
                  {overduePaymentItems?.overdueEmiToReturn?.map((item: any) => {
                    return (
                      <div
                        className={styles.overduePaymentsTableBodyRow}
                        key={item?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          hasLeadCardPermission
                            ? openLeadExpandModal(
                                authUser?.institute[0]?.instituteId,
                                item?.enquiryData?.id,
                                item?.enquiryData?.studentContact,
                                item?.enquiryData?.studentEmail,
                                item?.id
                              )
                            : dispatch(
                                setToastNotification({
                                  message: String(
                                    "You do not have permission to access the lead card"
                                  ),
                                  type: "error",
                                  snackOpen: true,
                                })
                              );
                        }}
                      >
                        <div
                          className={styles.overduePaymentsTableBodyItem}
                          style={{ width: "35%", maxWidth: "35%" }}
                        >
                          <div
                            className={styles.overduePaymentsTableBodyItemName}
                          >
                            {item?.enquiryData?.studentFirstName +
                              " " +
                              item?.enquiryData?.studentLastName}
                          </div>
                          <div
                            className={
                              styles.overduePaymentsTableBodyItemContacts
                            }
                          >
                            {item?.enquiryData?.studentEmail && (
                              <div>{item?.enquiryData?.studentEmail}</div>
                            )}
                            {item?.enquiryData?.studentEmail &&
                              item?.enquiryData?.studentContact && (
                                <div>| </div>
                              )}

                            {item?.enquiryData?.studentContact && (
                              <div>{item?.enquiryData?.studentContact}</div>
                            )}
                          </div>
                        </div>
                        <div
                          className={styles.overduePaymentsTableBodyItemCourse}
                          style={{ width: "35%", maxWidth: "35%" }}
                        >
                          {
                            getCourse(item?.enquiryData?.courseId, courseList)
                              ?.courseName
                          }
                        </div>

                        <div
                          className={styles.overduePaymentsTableBodyItemAmount}
                          style={{ width: "12%", maxWidth: "12%" }}
                        >
                          {/*   {item?.emiAmount?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {selectedCurrency + " " + item?.emiAmount}
                        </div>
                        <div
                          className={styles.overduePaymentsTableBodyItemDate}
                          style={{ width: "15%", maxWidth: "15%" }}
                        >
                          {moment(item?.dueDate).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                margin: "auto",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <img src={noResults} alt="noResults" />
              <div
                style={{
                  fontSize: "13px",
                  color: "#667085",
                  fontWeight: "500",
                  marginTop: "12PX",
                }}
              >
                No data available
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OverduePayments;
