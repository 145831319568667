import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
import { checkSubDomainAccess } from "../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import NoAccess from "../components/NoAccess/NoAccess";
import { CircularProgress } from "@mui/material";

const Contact = lazy(() =>
  import("../pages").then((module) => ({ default: module.Contact }))
);

const ImportData = lazy(() =>
  import("../pages").then((module) => ({ default: module.ImportData }))
);
const EmailData = lazy(() =>
  import("../pages").then((module) => ({ default: module.EmailData }))
);
const Webform = lazy(() =>
  import("../pages").then((module) => ({ default: module.Webform }))
);
const WebFormBuilding = lazy(() =>
  import("../pages").then((module) => ({ default: module.WebFormBuilding }))
);
const WebFormResponse = lazy(() =>
  import("../pages").then((module) => ({ default: module.WebFormResponse }))
);
const ResponseDetail = lazy(() =>
  import("../pages").then((module) => ({ default: module.ResponseDetail }))
);
const ZapierData = lazy(() =>
  import("../pages").then((module) => ({ default: module.ZapierData }))
);
const Whatsapp = lazy(() =>
  import("../pages").then((module) => ({ default: module.Whatsapp }))
);

export default function ManageInboundRouter(props: any) {
  const { setIsActive, shouldDisableGeneralSetup, GetInstituteById, isActive } =
    props;

  const location = useLocation();
  const navigate = useNavigate();
  const importString: any = localStorage?.getItem("ImportedContacts")!;
  const result = importString && JSON.parse(importString);
  const { userById } = useSelector((state: any) => state.userDetails);

  const contactAccess = checkSubDomainAccess(
    "INBOUND",
    "CONTACTS",
    userById?.rolePermission
  );
  const importAccess = checkSubDomainAccess(
    "INBOUND",
    "IMPORT",
    userById?.rolePermission
  );
  const webformAccess = checkSubDomainAccess(
    "INBOUND",
    "WEBFORMS",
    userById?.rolePermission
  );
  const zapierAccess = checkSubDomainAccess(
    "INBOUND",
    "ZAPIER",
    userById?.rolePermission
  );
  const emailAccess = checkSubDomainAccess(
    "INBOUND",
    "EMAIL",
    userById?.rolePermission
  );

  // useEffect(() => {
  //   if (location.pathname.includes("/app/Inbound")) {
  //     if (result?.fileName) {
  //       navigate("/app/inbound/ImportPreview");
  //     } else if (location.pathname.includes("/app/Inbound/Email")) {
  //       navigate("Email", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Webforms")) {
  //       // navigate("Webforms", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Contacts")) {
  //       navigate("Contacts", { replace: true });
  //     } else if (location.pathname.includes("/app/Inbound/Import")) {
  //       navigate("Import", { replace: true });
  //     }
  //   }
  // }, [isActive, result]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          contactAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <Contact />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Contacts"
        element={
          contactAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <Contact />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Import"
        element={
          importAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ImportData />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Email"
        element={
          emailAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <EmailData />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Webforms"
        element={
          webformAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <Webform />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Webforms/editwebform/:id"
        element={
          webformAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <WebFormBuilding />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Webforms/response/:id"
        element={
          webformAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <WebFormResponse />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Webforms/webformId/:formId/response/responsedetail/:id"
        element={
          webformAccess || contactAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ResponseDetail />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Zapier"
        element={
          zapierAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ZapierData />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Contacts/webformId/:formId/response/responsedetail/:id"
        element={
          contactAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ResponseDetail />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/WhatsApp"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Whatsapp />
          </Suspense>
        }
      />
    </Routes>
  );
}
