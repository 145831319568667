import styles from "./SaveReport.module.css";
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Receipt from "../../../../../components/Receipt/Receipt";
import { useAuthUser } from "react-auth-kit";
import AddIcon from "@mui/icons-material/Add";
import ViewInvoice from "../../../../../components/ViewInvoice/ViewInvoice";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";

function SaveReportPopup(props: any) {
  const {
    openSaveReportPopup,
    setOpenSaveReportPopup,
    setDiscardCancel,
    reportName,
    setReportName,
    description,
    setDescription,
    handleSaveClick,
    saveReportLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const [reportNameError, setReportNameError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");

  const handleReportNameChange = (event: any) => {
    const text = event.target.value;
    setReportName(text);
    if (text?.length === 0) {
      setReportNameError("Please enter report name");
    } else {
      setReportNameError("");
    }
  };

  const handleChange = React.useCallback((event: any) => {
    setDescription(event.target.value);
  }, []);

  const closePaymentRecordPopup = () => {
    setOpenSaveReportPopup(false);
    setDiscardCancel(true);
    setDescriptionError("");
    setReportName("");
  };

  return (
    <>
      <Dialog
        open={openSaveReportPopup}
        onClose={closePaymentRecordPopup}
        sx={{
          zIndex: "9999999",
          minWidth: "408px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "400px",
            maxHeight: "535PX",
            borderRadius: "12px",
            boxShadow:
              "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
            left: 0,
            background: "#FFFFFF",
            overflow: "hidden",
            padding: "0px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            className={styles.recordPaymentForm}
            style={{ padding: "28px 28px 20px 28px" }}
          >
            <div className={styles.cross_button}>
              <Button
                sx={{
                  color: "#667085",
                  padding: "0px",
                  display: "flex",
                  width: "fit-content",
                  minWidth: "0px",
                }}
                onClick={() => closePaymentRecordPopup()}
              >
                <CloseOutlinedIcon />
              </Button>
            </div>
            <div className={styles.formHeading}>Save Report</div>
            <form action="">
              <div
                style={{
                  maxHeight: "370px",
                  overflowY: "auto",
                  paddingRight: "10px",
                }}
                className={styles.recordPayWrap}
              >
                <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                  <Typography
                    sx={{
                      color: "#142C44",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      marginBottom: "6px",
                    }}
                  >
                    Report Name
                  </Typography>
                  <TextField
                    type="text"
                    id="amountReceived"
                    required
                    placeholder="Enter report name"
                    variant="outlined"
                    onChange={handleReportNameChange}
                    error={!!reportNameError}
                    helperText={reportNameError || ""}
                    inputProps={{ min: "0" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#B1B1B1",
                        fontWeight: "300",
                        fontSize: "12px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 16px",
                      },
                    }}
                    value={reportName}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                  <Typography
                    sx={{
                      color: "#142C44",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      marginBottom: "6px",
                    }}
                  >
                    Description
                  </Typography>
                  <TextField
                    placeholder="Enter here"
                    id="description"
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    inputProps={{ maxLength: 200 }}
                    error={!!descriptionError}
                    helperText={descriptionError || ""}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#B1B1B1",
                        fontWeight: "300",
                        fontSize: "12px",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "14px 10px 58px 14px",
                      },
                    }}
                    value={description}
                  />
                </FormControl>
              </div>
            </form>
          </div>
          <div className={styles.buttonSave}>
            <CancelBtn onClick={() => closePaymentRecordPopup()} />
            <SaveBtn
              onClick={handleSaveClick}
              disabled={reportName === ""}
              loader={saveReportLoading}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SaveReportPopup;
