import { ReactElement } from "react";

// Mui Icons
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

type Props = {
  title: string | undefined;
  children?: ReactElement;
};
const ToolTip = ({ title, children }: Props) => {
  return (
    <Tooltip
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#101828",
            color: "#fff",
            fontSize: "12px",
            fontWeight: "500",
            padding: "8px 12px",
            zIndex: "9999999",
            "& .MuiTooltip-arrow": {
              color: "#101828",
              zIndex: "9999999",
            },
          },
        },
      }}
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <InfoIcon
          sx={{ color: "#7d8592", fontSize: "18px", marginLeft: "6px" }}
        />
      )}
    </Tooltip>
  );
};
export default ToolTip;
