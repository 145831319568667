import React, { useState, useEffect, useRef } from "react";
import styles from "./TaskEditPopup.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function TaskEditPopup(props: any) {
  const { handleDeleteButtonClick, handleEditButtonClick, data } = props;
  const popupWrapRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupWrapRef.current &&
        !popupWrapRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popupWrapRef]);

  return (
    <div className={styles.taskEditPopupWrap} ref={popupWrapRef}>
      <button
        className={styles.taskEditPopupBtnOption}
        onClick={toggleDropdown}
      >
        <MoreHorizIcon sx={{ color: isOpen ? "#69696B" : "#BDBFC3" }} />
      </button>
      {isOpen && (
        <div className={styles.taskEditPopup}>
          <div>
            <button
              className={styles.taskEditPopupBtn}
              onClick={() => {
                handleEditButtonClick(data);
                setIsOpen(false);
              }}
            >
              Edit
            </button>
          </div>
          <div>
            <button
              className={styles.taskEditPopupBtn}
              style={{ color: "#EC3939" }}
              onClick={() => {
                handleDeleteButtonClick();
                setIsOpen(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskEditPopup;
