import React from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styles from "../Managebatches.module.css";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { updateBatchPayment } from "../../service/Batchslice";

const sessioniModes = [
  {
    id: 1,
    emiModeValue: "PER_SESSION",
    emiModeLabel: "Per Session",
  },
];

function EditBatchPaymentSession(props: any) {
  const {
    setSessionPayEdit,
    batchData,
    sessionPayEdit,
    batchPayUpdateLoading,
    selectedCurrency,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const [selectedSession, setSelectedSession] = React.useState<any>({
    sessionMode: "PER_SESSION",
    sessionPrice: 0,
    isEmiModeValid: true,
    isPriceValid: true,
  });
  const [discountMetric, setDiscountMetric] = React.useState<string>("");
  const [discount, setDiscount] = React.useState<string>("");
  const [discountError, setDiscountError] = React.useState<boolean>(false);
  const [finalPrice, setFinalPrice] = React.useState<string>("");

  const handleEmiModeChange = (value: string) => {
    setSelectedSession((prevSession: any) => ({
      ...prevSession,
      sessionMode: value,
    }));
  };

  const handlePriceChange = (value: string) => {
    setSelectedSession((prevSession: any) => ({
      ...prevSession,
      sessionPrice: value !== "" ? parseFloat(value) : value,
      isPriceValid: parseFloat(value) > 0 ? true : false,
    }));

    if (discountMetric === "AMOUNT") {
      setFinalPrice((parseFloat(value) - parseFloat(discount)).toString());
      if (parseFloat(value) <= parseFloat(discount)) {
        setDiscountError(true);
      } else {
        setDiscountError(false);
      }
    } else if (discountMetric === "PERCENTAGE") {
      setFinalPrice(
        (parseFloat(value) * (1 - parseFloat(discount) / 100)).toString()
      );
    } else {
      setFinalPrice(value);
    }
  };

  const handleDiscountMetric = (value: string) => {
    setDiscountMetric(value);
    setDiscount("");
    setFinalPrice(batchData?.paymentDetails?.paymentData?.finalPrice);
  };

  const handleDiscount = (value: string) => {
    if (value !== "") {
      if (discountMetric === "AMOUNT") {
        if (parseFloat(value) > parseFloat(selectedSession?.sessionPrice)) {
          setFinalPrice(
            (
              parseFloat(selectedSession?.sessionPrice) -
              parseFloat(selectedSession?.sessionPrice)
            ).toString()
          );
          setDiscount(selectedSession?.sessionPrice);
        } else {
          setDiscount(value);
          setFinalPrice(
            (
              parseFloat(selectedSession?.sessionPrice) - parseFloat(value)
            ).toString()
          );
        }
      } else if (discountMetric === "PERCENTAGE") {
        if (parseFloat(value) > 100) {
          setFinalPrice(
            (
              parseFloat(selectedSession?.sessionPrice) *
              (1 - 100 / 100)
            ).toString()
          );
          setDiscount("100");
        } else {
          setDiscount(value);
          setFinalPrice(
            (
              parseFloat(selectedSession?.sessionPrice) *
              (1 - parseFloat(value) / 100)
            ).toString()
          );
        }
      }
    } else {
      setFinalPrice(selectedSession?.sessionPrice);
      setDiscount("");
    }
  };

  // const handleEmiModeChange = (id: number, value: string) => {
  //   const updatedRows = sessionRows?.map((row: any) =>
  //     row.id === id
  //       ? { ...row, emiModeValue: value, isEmiModeValid: true }
  //       : row
  //   );
  //   setSessionRows(updatedRows);
  //   setSelectedSessionModes(updatedRows?.map((row: any) => row.emiModeValue));
  // };

  const handleSessionPayment = async (event: any) => {
    event.preventDefault();

    try {
      let discountError = false;
      if (discountMetric !== "" && parseFloat(discount) === 0) {
        discountError = true;
      }
      const body = {
        batchId: batchData?.id,
        paymentType: "SESSION",
        totalAmount: selectedSession?.sessionPrice,
        discountMetric:
          discountMetric !== "" && parseFloat(discount) > 0
            ? discountMetric
            : "",
        discount:
          discountMetric !== "" && parseFloat(discount) > 0
            ? parseFloat(discount)
            : 0,
        finalAmount: parseFloat(finalPrice),
        discountAdded: false,
        frequency: selectedSession?.sessionMode,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (
        !discountError &&
        selectedSession?.sessionPrice > 0 &&
        selectedSession?.sessionMode !== ""
      ) {
        dispatch<any>(updateBatchPayment(body));
      }
    } catch (error) {}
  };

  const cancelBtnClick = () => {
    setSessionPayEdit(false);
  };

  React.useEffect(() => {
    if (batchData) {
      setSelectedSession({
        ...selectedSession,
        sessionMode: batchData?.paymentDetails?.paymentData?.frequency,
        sessionPrice: batchData?.paymentDetails?.paymentData?.totalAmount,
        isEmiModeValid: true,
        isPriceValid: true,
      });
      setFinalPrice(batchData?.paymentDetails?.paymentData?.finalPrice);
      if (
        batchData?.paymentDetails?.paymentData?.discountMetric !== "" &&
        batchData?.paymentDetails?.paymentData?.discount > 0
      ) {
        setDiscount(batchData?.paymentDetails?.paymentData?.discount);
        setDiscountMetric(
          batchData?.paymentDetails?.paymentData?.discountMetric
        );
      }
    }
  }, [batchData, sessionPayEdit]);

  return (
    <div>
      <div className={styles.membershipTypeRow}>
        <div>
          <div className={styles.membershipTypeFieldLabel}>Frequency</div>
          <FormControl
            sx={{
              minWidth: 200,
              ".MuiOutlinedInput-input": {
                padding: "7px 14px",
              },
            }}
          >
            <Select
              id={`emi-mode-select-session}`}
              MenuProps={{
                style: {
                  zIndex: 999999,
                  fontSize: "14px",
                },
                PaperProps: {
                  style: {
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                  },
                },
              }}
              sx={{
                "& .MuiSelect-outlined": {
                  fontSize: "14px",
                  color: "#142C44",
                },
              }}
              value={selectedSession.sessionMode}
              onChange={(event) =>
                handleEmiModeChange(event.target.value as string)
              }
              // error={
              //   !item?.isEmiModeValid || !isModeUniqueCheck(sessionRows, item)
              // }
              disabled
            >
              {sessioniModes?.map((modeType) => (
                <MenuItem
                  key={modeType.id}
                  value={modeType.emiModeValue}
                  sx={{
                    fontSize: "12PX",
                    color: "#667085",
                    textTransform: "capitalize",
                    fontWeight: "400",
                  }}
                >
                  {modeType.emiModeLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <div className={styles.membershipTypeFieldLabel}>Total Price</div>

          <TextField
            id="emiPrice"
            variant="outlined"
            type="number"
            inputProps={{
              sx: {
                width: "200px",
                "&::placeholder": {
                  fontWeight: "400",
                  fontSize: "14px",
                },

                "&.MuiOutlinedInput-input": {
                  padding: "8px",
                },
              },
            }}
            value={selectedSession?.sessionPrice}
            onChange={(event) => handlePriceChange(event.target.value)}
            error={!selectedSession?.isPriceValid}
            helperText={
              !selectedSession?.isPriceValid ? "Please fill valid amount" : ""
            }
          />
        </div>
      </div>

      <div className={styles.membershipTypeRow}>
        <div style={{ width: "200px" }}>
          <div className={styles.membershipTypeFieldLabel}>Discount</div>

          <DiscountInput
            discountMetric={discountMetric}
            discount={discount}
            handleDiscountMetric={handleDiscountMetric}
            handleDiscount={handleDiscount}
            totalPrice={selectedSession?.sessionPrice}
            selectedCurrency={selectedCurrency}
          />
          {discountError && (
            <FormHelperText error>
              Discount more than total price
            </FormHelperText>
          )}
        </div>

        <div>
          <div className={styles.membershipTypeFieldLabel}>Final Price</div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(17, 200, 155)",
            }}
          >
            {/*      {parseFloat(finalPrice)?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })} */}
            {selectedCurrency + " " + parseFloat(finalPrice)}
          </div>
        </div>
      </div>

      <div className={styles.editSectionBtnWrap}>
        <CancelBtn onClick={() => cancelBtnClick()} />
        <SaveBtn
          onClick={handleSessionPayment}
          disabled={
            batchPayUpdateLoading ||
            selectedSession?.sessionPrice === "" ||
            parseFloat(selectedSession?.sessionPrice) <= 0 ||
            parseFloat(finalPrice) <= 0
          }
          loader={batchPayUpdateLoading}
        />
      </div>
    </div>
  );
}

export default EditBatchPaymentSession;
