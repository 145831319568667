import React, { useState } from "react";
import {
  Box,
  Modal,
  FormControl,
  Typography,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./PaymentLinkPopup.module.css";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { resetPaymentLinkStatus } from "../../service/nurtureSlice";
import { useDispatch } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function PaymentLinkPopup(props: any) {
  const {
    finalBatchPrice,
    totalPaidAmount,
    isOneTime,
    amountToBePaid,
    isTable,
    isDownPayment,
    SendPaymentLink,
    leadData,
    emiId,
    paymentType,
    autoDebitRaised,
    remainingEmiAmount,
    selectedCurrency,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );

  const {
    sendPaymentLinkLoading,
    sendPaymentLinkSuccess,
    sendPaymentLinkError,
  } = useSelector((state: any) => state.nurture);
  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const hasNurturePay =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("NURTURE_PAY_EASEBUZZ");

  // const totalDueAmount = finalBatchPrice - totalPaidAmount?.toFixed(2);
  const totalDueAmount = finalBatchPrice - totalPaidAmount;

  const [open, setOpen] = useState<boolean>(false);

  const [paymentLinkDetails, setPaymentLinkDetails] = React.useState<any>({
    leadId: "",
    contact: "",
    emiId: "",
    paymentType: "",
    amount: 0,
    subMerchantId: "",
    module: "",
    email: "",
  });

  const [enteredAmount, setEnteredAmount] = React.useState<
    number | undefined
  >();
  const [amountError, setAmountError] = React.useState<string>("");

  const openPaymentLinkPopup = () => {
    setOpen(true);
  };

  const handleAmountChange = (event: any) => {
    const enteredAmount = parseFloat(event.target.value);
    setEnteredAmount(enteredAmount);
    if (enteredAmount < 0) {
      setAmountError("Amount Received cannot be negative");
    } else if (enteredAmount === 0) {
      setAmountError("Please enter a number greater than zero");
    } else if (
      enteredAmount >
      (paymentType === "MEMBERSHIP" ? remainingEmiAmount : totalDueAmount)
    ) {
      setAmountError("Entered amount exceeds the available balance");
    } else {
      setAmountError("");
    }
  };

  const closePaymentLinkPopup = () => {
    setOpen(false);
    setAmountError("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      enteredAmount !== undefined &&
        enteredAmount > 0 &&
        amountError === "" &&
        SendPaymentLink({
          ...paymentLinkDetails,
          amount: enteredAmount,
          actionToPerform: hasNurturePay
            ? "SEND_PAYMENT_LINK"
            : "SEND_REMAINDER_EMAIL",
          paymentStatus: "REQUESTED",
          createdById: authUser?.institute?.[0]?.id,
          createdByName: authUser?.firstName + " " + authUser?.lastName,
          emiId: emiId,
        });
    } catch (error) {}
  };

  React.useEffect(() => {
    if (!isOneTime) {
      setEnteredAmount(amountToBePaid);
    }
  }, [isOneTime, amountToBePaid, open]);

  React.useEffect(() => {
    if (leadData) {
      setPaymentLinkDetails({
        ...paymentLinkDetails,
        leadId: leadData?.id,
        contact: leadData?.studentContact,
        emiId: emiId,
        paymentType: paymentType,
        amount: amountToBePaid,
        subMerchantId: "",
        module: "ENQUIRY",
        email: leadData?.studentEmail,
        actionToPerform: hasNurturePay
          ? "SEND_PAYMENT_LINK"
          : "SEND_REMAINDER_EMAIL",
      });
    }
  }, [leadData, amountToBePaid, paymentType]);

  React.useEffect(() => {
    if (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) {
      setOpen(false);
      dispatch(resetPaymentLinkStatus());
    }
  }, [sendPaymentLinkSuccess]);

  React.useEffect(() => {
    if (sendPaymentLinkError !== true && sendPaymentLinkError !== false) {
      dispatch(resetPaymentLinkStatus());
    }
  }, [sendPaymentLinkError]);

  return (
    <div
      style={{
        width: paymentType === "ONE_TIME" ? "216px" : "inherit",
        maxWidth: paymentType === "ONE_TIME" ? "216px" : "inherit",
        textAlign: paymentType === "ONE_TIME" ? "right" : "inherit",
      }}
    >
      <Tooltip
        arrow
        title={
          hasLeadAccess === false
            ? "Oops! No permission."
            : autoDebitRaised === true
            ? "Auto debit raised"
            : ""
        }
        PopperProps={{ style: { zIndex: 9999999 } }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#101828",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 12px",
              zIndex: "9999999",
              "& .MuiTooltip-arrow": {
                color: "#101828",
                zIndex: "9999999",
              },
            },
          },
        }}
      >
        <span>
          <Button
            color="primary"
            variant="contained"
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              textTransform: "capitalize",
              boxShadow: "none",
              lineHeight: "24px",
              padding: "7px 12px",
              borderRadius: "4PX",
            }}
            size={"small"}
            onClick={openPaymentLinkPopup}
            disabled={
              hasLeadAccess === false
                ? true
                : false || autoDebitRaised
                ? autoDebitRaised
                : false
            }
          >
            <NearMeOutlinedIcon
              sx={{ marginRight: "10px", fontSize: "small" }}
            />
            {hasNurturePay ? "Send Payment Link" : "Send Payment Reminder"}{" "}
          </Button>
        </span>
      </Tooltip>
      {/* {!isTable ? (
        <Tooltip
          arrow
          title={
            hasLeadAccess === false
              ? "Oops! No permission."
              : autoDebitRaised === true
              ? "Auto debit raised"
              : ""
          }
          PopperProps={{ style: { zIndex: 9999999 } }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                zIndex: "9999999",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                  zIndex: "9999999",
                },
              },
            },
          }}
        >
          <span>
            <Button
              color="primary"
              sx={{
                fontSize: "13px",
                fontWeight: "500",
                textTransform: "capitalize",
                boxShadow: "none",
                lineHeight: "24px",
                width: paymentType === "ONE_TIME" ? "216px" : "inherit",
                background: !isOneTime ? "#F9F9FD" : "inherit",
              
                color: !isOneTime ? "#667085" : "#11c89b",
                padding: !isOneTime ? "7px 12px" : "4px 10px",
                borderRadius: !isOneTime ? "6PX" : "4px",
                border: isOneTime ? "1px  solid #11c89b" : "none",
              }}
              size={"small"}
              onClick={openPaymentLinkPopup}
              disabled={
                hasLeadAccess === false
                  ? true
                  : false || autoDebitRaised
                  ? autoDebitRaised
                  : false
              }
            >
              <NearMeOutlinedIcon
                sx={{ marginRight: "10px", fontSize: "small" }}
              />
              {hasNurturePay ? "Send Payment Link" : "Send Payment Reminder"}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title={
            hasLeadAccess === false
              ? "Oops! No permission."
              : autoDebitRaised === true
              ? "Auto debit raised"
              : ""
          }
          PopperProps={{ style: { zIndex: 9999999 } }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                zIndex: "9999999",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                  zIndex: "9999999",
                },
              },
            },
          }}
        >
          <span>
            <Button
              color="primary"
              sx={{
                fontSize: "13px",
                fontWeight: "500",
                textTransform: "capitalize",
                boxShadow: "none",
                lineHeight: "24px",
                border: isTable ? "none" : "1px  solid #11c89b",

                background: !isOneTime ? "#F9F9FD" : "inherit",
                color: !isOneTime ? "#667085" : "inherit",
                padding: !isOneTime ? "7px 12px" : "6px 10px",
                borderRadius: !isOneTime ? "6PX" : "inherit",

                "&:hover": {
                  border: isTable ? "none" : "inherit",
                },
              }}
              size={"small"}
              onClick={openPaymentLinkPopup}
              disabled={
                hasLeadAccess === false
                  ? true
                  : false || autoDebitRaised
                  ? autoDebitRaised
                  : false
              }
            >
              <NearMeOutlinedIcon
                sx={{ marginRight: "10px", fontSize: "small" }}
              />
              {hasNurturePay ? "Send Payment Link" : "Send Payment Reminder"}
            </Button>
          </span>
        </Tooltip>
      )} */}
      <Modal
        open={open}
        onClose={closePaymentLinkPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box>
          <Box sx={style}>
            <div className={styles.PaymentLinkPopupHeadWrap}>
              <div className={styles.PaymentLinkPopupHead}>
                Send payment link
              </div>
              <div
                onClick={closePaymentLinkPopup}
                className={styles.closeModalButton}
              >
                <CloseIcon />
              </div>
            </div>
            <div className={styles.PaymentLinkPopupBodyWrap}>
              <div className={styles.PaymentLinkPopupBodyHead}>
                Payment Details
              </div>
              <div className={styles.PaymentLinkPopupBodyItem}>
                <div className={styles.PaymentLinkPopupBodyItemLabel}>
                  Batch Price
                </div>
                <div className={styles.PaymentLinkPopupBodyItemValue}>
                  {selectedCurrency ? selectedCurrency : "₹"}{" "}
                  {finalBatchPrice?.toFixed(2)}
                </div>
              </div>
              <div className={styles.PaymentLinkPopupBodyItem}>
                <div className={styles.PaymentLinkPopupBodyItemLabel}>Paid</div>
                <div className={styles.PaymentLinkPopupBodyItemValue}>
                  {/* ₹ {totalPaidAmount?.toFixed(2)} */}
                  {selectedCurrency ? selectedCurrency : "₹"}
                  {totalPaidAmount}
                </div>
              </div>
              <div className={styles.PaymentLinkPopupBodyItem}>
                <div className={styles.PaymentLinkPopupBodyItemDueLabel}>
                  Total Due Amount
                </div>
                <div className={styles.PaymentLinkPopupBodyItemDueValue}>
                  {selectedCurrency ? selectedCurrency : "₹"}{" "}
                  {paymentType === "MEMBERSHIP"
                    ? finalBatchPrice?.toFixed(2)
                    : totalDueAmount?.toFixed(2)}
                </div>
              </div>
            </div>
            <div>
              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <Typography
                  sx={{
                    color: "#142C44",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "22px",
                    marginBottom: "6px",
                  }}
                >
                  Amount
                </Typography>
                <TextField
                  type="number"
                  id="amountReceived"
                  required
                  placeholder="₹ Enter here"
                  variant="outlined"
                  value={enteredAmount}
                  onChange={handleAmountChange}
                  error={!!amountError}
                  helperText={amountError || ""}
                  inputProps={{ min: "0" }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#B1B1B1",
                      fontWeight: "300",
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "&::placeholder": {
                        color: "red",
                      },
                    },
                  }}
                  disabled={isDownPayment === true ? true : false}
                />
              </FormControl>
            </div>
            <div className={styles.linkBtnSplit}>
              <CancelBtn onClick={() => closePaymentLinkPopup()} />
              <SaveBtn
                onClick={handleSubmit}
                disabled={sendPaymentLinkLoading || amountError !== ""}
                loader={sendPaymentLinkLoading}
                buttonText={"Send"}
              />
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default PaymentLinkPopup;
