import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./CustomRoleDetail.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Users from "../Users/Users";
import { FiEdit } from "react-icons/fi";
import CustomRolePermissions from "../CustomRolePermissions/CustomRolePermissions";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import RoleCancelPopup from "../RoleCancelPopup/RoleCancelPopup";
import { useDispatch } from "react-redux";
import {
  resetRoleById,
  resetSettingsStatuses,
} from "../../../../service/settingsSlice";
import { getInstituteById } from "../../../../../Manage/InstituteProfile/service/instituteSlice";
import { getSubDomainAccessStatus } from "../../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import RoleBackPopup from "../RoleCancelPopup/RoleBackPopup";

function CustomRoleDetail(props: any) {
  const {
    GetRoleById,
    roleById,
    UpdateCustomRole,
    GetStaffList,
    updateCustomRoleLoading,
    updateCustomRoleSuccess,
    rolesList,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roleByIdLoading } = useSelector((state: any) => state.appSettings);
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasRolesAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "ROLES_&_PERMISSIONS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const combinedRoles = rolesList?.systemRoles?.concat(rolesList?.customRoles);

  const { staffList } = useSelector((state: any) => state.staff);

  const [editMode, setEditMode] = React.useState(false);

  const [roleDetails, setRoleDetails] = React.useState({
    id: "",
    roleName: "",
    description: "",
    roleStatus: "ACTIVE",
    roleFor: "NURTURE", //MICO,NURTURE,MARKET_PLACE
    roleType: "CUSTOM", // DEFAULT,CUSTOM,
    permissions: [],
    instituteId: "",
    createdByName: "",
    createdById: "",
    updatedByName: "",
    updatedById: "",
    roleIndex: "",
  });
  const [treeStructure, setTreeStructure] = useState<any>();
  const [roleError, setRoleError] = React.useState<boolean>(false);
  const [roleErrorDesc, setRoleErrorDesc] = React.useState<boolean>(false);

  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const areObjectsEqual = () => {
    const str1 = JSON.stringify(treeStructure);
    const str2 = JSON.stringify(roleById?.permissions);
    return str1 === str2;
  };
  const isEqual = areObjectsEqual();

  const isEqualCheck = (prop1: any, prop2: any) => {
    const str1 = JSON.stringify(prop1);
    const str2 = JSON.stringify(prop2);
    return str1 === str2;
  };

  const isRoleEqual = isEqualCheck(roleDetails?.roleName, roleById?.roleName);
  const isRoleDescEqual = isEqualCheck(
    roleDetails?.description,
    roleById?.description
  );

  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showBackPopup, setShowBackPopup] = useState<boolean>(false);

  const handleCancelConfirmed = () => {
    setShowCancelPopup(false);
    setEditMode(false);
    setTreeStructure(roleById?.permissions);
  };

  const handleCancelCancelled = () => {
    setShowCancelPopup(false);
  };
  const handleBackConfirmed = () => {
    setShowBackPopup(false);
    navigate(`/app/Settings/RolesandPermission`);
  };

  const handleBackCancelled = () => {
    setShowBackPopup(false);
  };
  const handleBackOpenClick = () => {
    if (!isEqual || !isRoleEqual || !isRoleDescEqual) {
      setShowBackPopup(true);
    } else {
      dispatch(resetRoleById());
      navigate(`/app/Settings/RolesandPermission`);
    }
  };

  const handleChange = React.useCallback(
    (event: any) => {
      const modifiedValue = event.target.value
        .toUpperCase()
        .replace(/\s/g, "_");

      setRoleDetails({
        ...roleDetails,
        [event.target.id]: event.target.value,
      });
      if (event.target.id === "roleName") {
        const filterCombinedRole = combinedRoles?.filter(
          (item: any) => item.id !== roleById?.id
        );
        const matchedRole = filterCombinedRole?.find(
          (role: any) => modifiedValue === role.roleCode
        );
        if (matchedRole) {
          setRoleError(true);
        } else {
          setRoleError(false);
        }
      }
      if (event.target.id === "description") {
        if (event.target.value === "") {
          setRoleErrorDesc(true);
        } else {
          setRoleErrorDesc(false);
        }
      }
    },
    [roleDetails, combinedRoles]
  );

  const handleDomainCheckBoxChange = useCallback(
    (
      checked: any,
      domainId: any,
      permissionAttributeId: any,
      permissionAttributeCode: any
    ) => {
      const updatedData = treeStructure?.map((item: any) => {
        if (item.roleDomainId === domainId) {
          const updatedAccessPermissions = item?.accessPermissions?.map(
            (permission: any) => {
              if (permission.permissionAttributeId === permissionAttributeId) {
                return {
                  ...permission,
                  accessStatus: checked,
                };
              }
              return permission;
            }
          );

          let newData: any;

          if (checked && permissionAttributeCode === "ALL_ACCESS") {
            newData = updatedAccessPermissions?.map((permission: any) => {
              if (permission.permissionAttributeCode === "VIEW_ONLY") {
                return {
                  ...permission,
                  accessStatus: true,
                };
              }

              return permission;
            });
          } else if (checked && permissionAttributeCode === "VIEW_ONLY") {
            newData = updatedAccessPermissions?.map((permission: any) => {
              if (permission.permissionAttributeCode === "ALL_ACCESS") {
                return {
                  ...permission,
                  accessStatus: false,
                };
              }

              return permission;
            });
          } else {
            newData = updatedAccessPermissions;
          }

          return {
            ...item,
            accessPermissions: newData,
          };
        }
        return item;
      });

      setTreeStructure(updatedData);
    },
    [treeStructure]
  );

  const handleSubDomainCheckBoxChange = useCallback(
    (
      checked: any,
      domainId: any,
      subDomainId: any,
      permissionAttributeId: any,
      permissionAttributeCode: any
    ) => {
      const updatedData = treeStructure?.map((item: any) => {
        if (item.roleDomainId === domainId) {
          const updatedSubDomainData = item?.subDomain?.map((subItem: any) => {
            if (subItem.roleDomainId === subDomainId) {
              const updatedAccessPermissions = subItem?.accessPermissions?.map(
                (permission: any) => {
                  if (
                    permission.permissionAttributeId === permissionAttributeId
                  ) {
                    return {
                      ...permission,
                      accessStatus: checked,
                    };
                  }
                  return permission;
                }
              );

              let newData: any;

              if (checked && permissionAttributeCode === "ALL_ACCESS") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "VIEW_ONLY") {
                    return {
                      ...permission,
                      accessStatus: true,
                    };
                  }

                  return permission;
                });
              } else if (checked && permissionAttributeCode === "VIEW_ONLY") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "ALL_ACCESS") {
                    return {
                      ...permission,
                      accessStatus: false,
                    };
                  }

                  return permission;
                });
              } else if (checked && permissionAttributeCode === "OWN_BRANCH") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "ALL_BRANCHES") {
                    return {
                      ...permission,
                      accessStatus: false,
                    };
                  }

                  return permission;
                });
              } else if (
                checked &&
                permissionAttributeCode === "ALL_BRANCHES"
              ) {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "OWN_BRANCH") {
                    return {
                      ...permission,
                      accessStatus: true,
                    };
                  }

                  return permission;
                });
              } else {
                newData = updatedAccessPermissions;
              }

              return {
                ...subItem,
                accessPermissions: newData,
              };
            }
            return subItem;
          });

          return {
            ...item,
            subDomain: updatedSubDomainData,
          };
        }
        return item;
      });

      setTreeStructure(updatedData);
    },
    [treeStructure]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const updatedTreeStructure = treeStructure?.map((item: any) => ({
        roleDomainId: item.roleDomainId,
        roleDomainName: item.roleDomainName,
        roleDomainParentId: item?.roleDomainParentId,
        roleDomainParentName: item.roleDomainParentName,
        accessPermissions: item?.accessPermissions,
        roleDomainCode: item?.roleDomainCode,
        domainIndex: item?.domainIndex,
        subDomain:
          item?.subDomain?.length > 0
            ? item?.subDomain?.map((subItem: any) => ({
                roleDomainId: subItem.roleDomainId,
                roleDomainName: subItem.roleDomainName,
                roleDomainParentId: subItem?.roleDomainParentId,
                roleDomainParentName: subItem.roleDomainParentName,
                roleDomainCode: subItem?.roleDomainCode,
                accessPermissions: subItem?.accessPermissions,
                domainIndex: subItem?.domainIndex,
              }))
            : [],
      }));
      let descriptionError = false;
      if (roleDetails?.description === "") {
        descriptionError = true;
        setRoleErrorDesc(true);
      } else {
        descriptionError = false;
        setRoleErrorDesc(false);
      }
      !descriptionError &&
        roleDetails.roleName &&
        !roleError &&
        UpdateCustomRole({
          ...roleDetails,
          updatedById: authUser?.institute?.[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          instituteId: authUser?.institute[0]?.instituteDetails?.id,
          permissions: updatedTreeStructure,
        });
    } catch (error) {}
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const roleId = pathname.substring(pathname.lastIndexOf("/") + 1);
    GetRoleById(roleId);
    if (authUser) {
      const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;
      GetStaffList(userFromLocalStorage?.id);
      dispatch<any>(getInstituteById(userFromLocalStorage?.id));
    }
  }, []);

  React.useEffect(() => {
    if (roleById) {
      setRoleDetails({
        ...roleDetails,
        roleName: roleById?.roleName,
        description: roleById?.description,
        roleStatus: roleById?.roleStatus,
        roleFor: "NURTURE",
        roleType: "CUSTOM",
        permissions: roleById?.permissions,
        instituteId: roleById?.instituteId,
        createdByName: roleById?.createdByName,
        createdById: roleById?.createdById,
        updatedByName: roleById?.updatedByName,
        updatedById: roleById?.updatedById,
        id: roleById?.id,
        roleIndex: roleById?.roleIndex,
      });
      setTreeStructure(roleById?.permissions);
    }
  }, [roleById]);

  React.useEffect(() => {
    if (updateCustomRoleSuccess !== true && updateCustomRoleSuccess !== false) {
      const pathname = window.location.pathname;
      const roleId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetRoleById(roleId);
      setEditMode(false);
      dispatch(resetSettingsStatuses());
    }
  }, [updateCustomRoleSuccess]);

  return (
    <Box sx={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
      <div className={styles.PageBackBtnWrap}>
        {!roleByIdLoading && (
          <button className={styles.PageBackBtn} onClick={handleBackOpenClick}>
            <ArrowBackIcon
              sx={{
                marginRight: "8px",
                fontSize: "15PX",
                color: "#667085",
              }}
            />
            Back
          </button>
        )}
      </div>
      <Box
        sx={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        {roleByIdLoading ? (
          <>
            <Box>
              <Skeleton
                animation="wave"
                variant="text"
                height={30}
                width={150}
                sx={{ marginBottom: "6px" }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                height={15}
                width={250}
                sx={{ marginBottom: "10px" }}
              />
            </Box>
            <Box>
              <Skeleton
                animation="wave"
                variant="text"
                height={30}
                width={200}
                sx={{ marginBottom: "6px" }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box>
              {!editMode && (
                <>
                  <div className={styles.roleName}>{roleDetails?.roleName}</div>
                  <div className={styles.roleDescription}>
                    {roleDetails?.description}
                  </div>
                </>
              )}
            </Box>
            {!editMode && (
              <Tooltip
                arrow
                title={hasRolesAccess === false ? "Oops! No permission." : ""}
                sx={{ width: "100%" }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      minWidth: "130PX",
                    }}
                    onClick={() =>
                      hasRolesAccess === true ? handleEditClick() : null
                    }
                  >
                    <div className={styles.editHead}>Edit Permission</div>
                    <div onClick={() => {}} style={{ display: "flex" }}>
                      <FiEdit size={16} color="#667085" />
                    </div>
                  </Box>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      {roleByIdLoading ? (
        <Box sx={{ padding: "0px 20px 0px 20px" }}>
          {Array.from(new Array(4)).map((_, index) => (
            <div key={index} style={{ height: "70px" }}>
              <Skeleton
                animation="wave"
                variant="text"
                height="70px"
                width="100%"
                style={{
                  marginBottom: "10px",
                  borderRadius: "3px",
                }}
              />
            </div>
          ))}
        </Box>
      ) : (
        <>
          {!editMode ? (
            <Users staffList={staffList} roleById={roleById} />
          ) : (
            <>
              <Box sx={{ padding: "0px 20px 0px 20px", maxWidth: "40%" }}>
                <div className={styles.roleHead}>Edit Permissions</div>
                <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                  <Typography
                    sx={{
                      color: "#142C44",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      marginBottom: "6px",
                    }}
                  >
                    Role Name
                  </Typography>
                  <TextField
                    id="roleName"
                    variant="outlined"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#B1B1B1",
                        fontWeight: "300",
                        fontSize: "12px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 16px",
                      },
                    }}
                    value={roleDetails.roleName}
                    inputProps={{
                      maxLength: 50,
                    }}
                    error={roleError}
                    helperText={
                      roleError
                        ? "The role name already exists, change it to continue."
                        : ""
                    }
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                  <Typography
                    sx={{
                      color: "#142C44",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      marginBottom: "6px",
                    }}
                  >
                    Description
                  </Typography>
                  <TextField
                    id="description"
                    placeholder="Max 200 Characters"
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    inputProps={{ maxLength: 200 }}
                    value={roleDetails.description}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#B1B1B1",
                        fontWeight: "300",
                        fontSize: "12px",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "14px 10px 58px 14px",
                      },
                    }}
                    error={roleErrorDesc}
                    helperText={roleErrorDesc ? "Please add description." : ""}
                  />
                </FormControl>
              </Box>
            </>
          )}
          <CustomRolePermissions
            editMode={editMode}
            treeStructure={treeStructure}
            handleDomainCheckBoxChange={handleDomainCheckBoxChange}
            handleSubDomainCheckBoxChange={handleSubDomainCheckBoxChange}
            editTreeStructure={true}
          />
          {editMode && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
                gap: "6px",
              }}
            >
              {!isEqual && (
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #BFBFBF",
                    color: "#BFBFBF",
                    textTransform: "capitalize",
                    "&:hover": {
                      border: "1px solid #BFBFBF",
                      backgroundColor: "#FFF",
                    },
                  }}
                  onClick={() => setShowCancelPopup(!showCancelPopup)}
                >
                  Cancel
                </Button>
              )}

              {showCancelPopup && (
                <RoleCancelPopup
                  onClose={handleCancelCancelled}
                  showDeletePopup={showCancelPopup}
                  onConfirm={handleCancelConfirmed}
                />
              )}
              {showBackPopup && (
                <RoleBackPopup
                  onClose={handleBackCancelled}
                  showDeletePopup={showBackPopup}
                  onConfirm={handleBackConfirmed}
                />
              )}
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={handleSubmit}
                disabled={
                  updateCustomRoleLoading ||
                  (isEqual && isRoleDescEqual && isRoleEqual)
                }
              >
                {updateCustomRoleLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default CustomRoleDetail;
