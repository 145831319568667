import { IconButton, MenuItem, Popover, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import checkCircle from "../../../../../assets/images/payCheckCircle.svg";
import dots from "../../../../../assets/images/unionDots.svg";
import CancelPaymentLinkPopup from "./CancelPaymentLinkPopup";
import ResendPaymentLinkPopup from "./ResendPaymentLinkPopup";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { resetCancelPaymentStatuses } from "../../service/nurtureSlice";
import { useDispatch } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

function PaymentLinkSent(props: any) {
  const {
    filteredPaymentRequests,
    EditRecordPayment,
    finalBatchPrice,
    totalPaidAmount,
    isOneTime,
    amountToBePaid,
    SendPaymentLink,
    leadData,
    emiId,
    paymentType,
    isFirstPaymentRequestedOrFailed,
    autoDebitRaised,
    sessionPaymentId,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const { sendPaymentLinkSuccess, recordPaymentUpdateSuccess } = useSelector(
    (state: any) => state.nurture
  );

  const [requestedPaymentData, setRequestedPaymentData] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleCancelOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCancelClose = () => {
    setAnchorEl(null);
  };
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const openCancelPopup = () => {
    setCancelPopup(true);
  };
  const closeCancelPopup = () => {
    setCancelPopup(false);
  };

  const cancelConfirm = () => {
    EditRecordPayment({
      ...requestedPaymentData,
      paymentStatus: "CANCELLED",
      updatedById: authUser?.institute[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
    });
  };

  useEffect(() => {
    if (filteredPaymentRequests) {
      setRequestedPaymentData(filteredPaymentRequests?.[0]);
    }
  }, [isFirstPaymentRequestedOrFailed, filteredPaymentRequests]);
  const [resendPopup, setResendPopup] = useState<boolean>(false);
  const openResendPopup = () => {
    setResendPopup(true);
  };

  const totalDueAmount = finalBatchPrice - totalPaidAmount;

  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const hasNurturePay =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("NURTURE_PAY");

  const [paymentLinkDetails, setPaymentLinkDetails] = React.useState<any>({
    leadId: "",
    contact: "",
    emiId: "",
    paymentType: "",
    amount: 0,
    subMerchantId: "",
    module: "",
    email: "",
  });
  const closeResendPopup = () => {
    setResendPopup(false);
  };
  const [enteredAmount, setEnteredAmount] = React.useState<number>();

  React.useEffect(() => {
    if (!isOneTime) {
      setEnteredAmount(amountToBePaid);
    }
  }, [isOneTime, amountToBePaid]);

  React.useEffect(() => {
    if (leadData) {
      setPaymentLinkDetails({
        ...paymentLinkDetails,
        leadId: leadData?.id,
        contact: leadData?.studentContact,
        emiId: emiId,
        paymentType: paymentType,
        amount: amountToBePaid,
        subMerchantId: "",
        module: "ENQUIRY",
        email: leadData?.studentEmail,
      });
    }
  }, [leadData, amountToBePaid, paymentType]);

  const resendConfirm = () => {
    SendPaymentLink({
      ...paymentLinkDetails,
      amount: requestedPaymentData?.amountReceived,
      actionToPerform: "SEND_PAYMENT_LINK",
      paymentStatus: "REQUEST_RESEND",
      createdById: authUser?.institute[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      emiId: emiId,
      ...(paymentType === "SESSION_PAYMENT" && {
        sessionPaymentId: sessionPaymentId,
      }),
    });
  };

  useEffect(() => {
    if (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) {
      setResendPopup(false);
    }
  }, [sendPaymentLinkSuccess]);

  useEffect(() => {
    if (
      recordPaymentUpdateSuccess !== true &&
      recordPaymentUpdateSuccess !== false
    ) {
      setCancelPopup(false);
      dispatch(resetCancelPaymentStatuses());
    }
  }, [recordPaymentUpdateSuccess]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",

        justifyContent: paymentType === "ONE_TIME" ? "right" : "unset",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          width: paymentType === "ONE_TIME" ? "216px" : "inherit",
          maxWidth: paymentType === "ONE_TIME" ? "216px" : "inherit",
          borderRadius: paymentType === "ONE_TIME" ? "4PX" : "0PX",
          background:
            paymentType === "ONE_TIME"
              ? "rgba(17, 200, 155, 0.10)"
              : "transparent",
          padding: paymentType === "ONE_TIME" ? "7px 14PX" : "0PX",
          justifyContent: paymentType === "ONE_TIME" ? "center" : "inherit",
        }}
      >
        <div style={{ display: "flex" }}>
          <img src={checkCircle} alt="checkCircle" />
        </div>
        <div
          style={{
            color: "#11C89B",
            fontSize: "13px",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          Payment link sent
        </div>
      </div>
      <div>
        <Tooltip
          arrow
          title={
            hasLeadAccess === false
              ? "Oops! No permission."
              : autoDebitRaised === true
              ? "Auto debit raised"
              : ""
          }
          PopperProps={{ style: { zIndex: 9999999 } }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                zIndex: "9999999",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                  zIndex: "9999999",
                },
              },
            },
          }}
        >
          <span>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleCancelOpen}
              sx={{
                width: "20px",
                height: "20px",
                border: "none",
                borderRadius: "4PX",
                background: "trasnarent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              disabled={
                hasLeadAccess === false
                  ? true
                  : false || autoDebitRaised
                  ? autoDebitRaised
                  : false
              }
            >
              <img src={dots} alt="" />
            </IconButton>
          </span>
        </Tooltip>

        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleCancelClose}
          sx={{ zIndex: 99999999 }}
          PaperProps={{
            style: {
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.06)",
              borderRadius: "4PX",
              border: "1px solid #E4E4E7",
              zIndex: 99999999,
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "right",
          }}
        >
          <MenuItem
            sx={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#EC3939",
            }}
            onClick={() => {
              handleCancelClose();
              openCancelPopup();
            }}
          >
            Cancel Payment link
          </MenuItem>
          <MenuItem
            sx={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
            }}
            onClick={() => {
              handleCancelClose();
              openResendPopup();
            }}
          >
            Resend Payment link
          </MenuItem>
        </Popover>

        <CancelPaymentLinkPopup
          open={cancelPopup}
          onClose={closeCancelPopup}
          noButtonClick={closeCancelPopup}
          yesButtonClick={cancelConfirm}
        />
        <ResendPaymentLinkPopup
          open={resendPopup}
          onClose={closeResendPopup}
          noButtonClick={closeResendPopup}
          yesButtonClick={resendConfirm}
        />
      </div>
    </div>
  );
}

export default PaymentLinkSent;
