import React, { useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Button } from "@mui/material";
import styles from "./CustomPopupBilling.module.css";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function InactiveAccountPopup(props: any) {
  const { type } = props;
  const { userById } = useSelector((state: any) => state.userDetails);
  const billingAdmin = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {};

  const renderPopupHeading = () => {
    if (type === "ARCHIVED") {
      return "your account has been Archived";
    } else if (type === "UNARCHIVED") {
      return "your account has been Unarchived";
    } else if (type === "LOCKED") {
      return "Your account is temporarily locked.";
    } else if (type === "INACTIVE") {
      return "Your account is Inactive.";
    }
  };

  const renderPopupPara = () => {
    if ((type === "ARCHIVED" || type === "LOCKED") && billingAdmin === false) {
      return "Contact your admin to renew Subscription";
    } else if (type === "ARCHIVED" && billingAdmin === true) {
      return "Please settle the outstanding payment to continue using nurture.";
    } else if (type === "UNARCHIVED" && billingAdmin === true) {
      return "Your account will be activated soon.";
    } else if (type === "UNARCHIVED" && billingAdmin !== true) {
      return "Your account will be activated soon. Contact your admin for more info.";
    } else if (type === "LOCKED" && billingAdmin === true) {
      return "Please make a payment to reactivate the account.";
    } else if (type === "INACTIVE") {
      return "Please contact our customer care for more information.";
    } else if (billingAdmin === false) {
      return "Please make a payment to reactivate the account.";
    }
  };

  const renderIcon = () => {
    if (type === "ARCHIVED") {
      return (
        <Inventory2OutlinedIcon className={styles.iconRed} fontSize="large" />
      );
    } else if (type === "UNARCHIVED") {
      return (
        <CheckCircleOutlinedIcon
          className={styles.iconGreen}
          fontSize="large"
        />
      );
    } else if (type === "LOCKED") {
      return <LockOutlinedIcon className={styles.iconRed} fontSize="large" />;
    } else if (type === "INACTIVE") {
      return <LockOutlinedIcon className={styles.iconRed} fontSize="large" />;
    }
  };

  React.useEffect(() => {
    if (
      type === "ARCHIVED" ||
      type === "LOCKED" ||
      type === "UNARCHIVED" ||
      type === "INACTIVE"
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [type]);

  return (
    <div>
      {isOpen && (
        <div className={styles.inactiveAccountPopupParent}>
          <div className={styles.inactiveAccountPopupCard}>
            <div className={styles.inactiveAccountPopupCardIcon}>
              {renderIcon()}
            </div>

            <div
              className={
                type === "UNARCHIVED"
                  ? styles.popupHeadGreen
                  : styles.popupHeadRed
              }
            >
              {renderPopupHeading()}
            </div>
            <div>
              <p className={styles.popupPara}>{renderPopupPara()}</p>
            </div>
            {billingAdmin === true &&
            (type === "ARCHIVED" || type === "LOCKED") ? (
              <div className={styles.popupButtonDiv}>
                <Link
                  style={{ width: "100%", display: "block" }}
                  className={styles.link}
                  to="/app/manage/Billing/ManageSubscription"
                >
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleButtonClick}
                    variant="contained"
                    color="primary"
                  >
                    Pay Now
                  </Button>
                </Link>
              </div>
            ) : billingAdmin === true && type === "UNARCHIVED" ? (
              <div className={styles.popupButtonDiv}>
                <Link
                  style={{ width: "100%", display: "block" }}
                  className={styles.link}
                  to="/app/manage/Billing/ManageSubscription"
                >
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleButtonClick}
                    variant="contained"
                    color="primary"
                  >
                    Go to Billing
                  </Button>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InactiveAccountPopup;
