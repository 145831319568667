import React, { useEffect, useState } from "react";
import styles from "../components/Managebranches.module.css";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  Skeleton,
  Tooltip,
} from "@mui/material";
import MapAutocomplete from "../../../../../components/googleMap/MapAutocomplete";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import { FiEdit } from "react-icons/fi";
import { useAuthUser } from "react-auth-kit";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import BranchLimitPopup from "./BranchLimitPopup";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import edit from "./edit.svg";

interface CheckedValue {
  stateName: string;
}
interface CheckedCity {
  cityName: string;
}

export default function BranchListing(props: any) {
  const {
    GetBranchList,
    branchList,
    success,
    GetInstituteById,
    branchListLoading,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const location = useLocation();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasBranchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BRANCHES",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [searchText, setSearchText] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);

  useEffect(() => {
    setDefaultSelectedStaffId(location?.state?.branchId);
  }, []);

  const [defaultSelectedStaffId, setDefaultSelectedStaffId] = useState<
    string | null
  >(null);

  useEffect(() => {
    setSelectedBranchId(defaultSelectedStaffId);
  }, [defaultSelectedStaffId]);

  useEffect(() => {
    if (authUser) {
      GetBranchList(authUser.institute && authUser.institute[0].instituteId);
      GetInstituteById(authUser.institute && authUser.institute[0].instituteId);
    }
  }, [GetBranchList, GetInstituteById]);

  const BranchDetails: React.FC<{ item: any }> = ({ item }) => {
    return (
      <div className={styles.rightSubSection}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #EBEBED",
            padding: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              <div className={styles.branchNameRight}>
                {item?.branchName} {item?.state ? `,${item?.state}` : ""}
              </div>
              <div className={styles.branchDesignation}>
                {item?.city && `${item?.city}`}
              </div>
            </Box>
          </Box>
          <Tooltip
            arrow
            title={hasBranchAccess === false ? "Oops! No permission." : ""}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#101828",
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "8px 12px",
                  "& .MuiTooltip-arrow": {
                    color: "#101828",
                  },
                },
              },
            }}
          >
            <span>
              {hasBranchAccess === false ? (
                <Button
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#667085",
                    textTransform: "capitalize",
                    gap: "10px",
                  }}
                  disabled={hasBranchAccess === false}
                >
                  Edit
                  <img src={edit} alt="edit" />
                  {/* <FiEdit size={14} color="#142c44" /> */}
                </Button>
              ) : (
                <Link to={`/app/manage/Branches/${item && item?.id}`}>
                  <Button
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#667085",
                      textTransform: "capitalize",
                      gap: "10px",
                    }}
                  >
                    Edit
                    <FiEdit size={14} color="#142c44" />
                  </Button>
                </Link>
              )}
            </span>
          </Tooltip>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <div style={{ display: "flex", gap: "40px" }}>
            <div>
              <div className={styles.key}>Email</div>
              <div className={styles.value}>{item && item.email}</div>
            </div>
            <div>
              <div className={styles.key}>Phone number</div>
              <div className={styles.value}>
                {`+${item?.contactCountryCode || "91"} ${
                  item?.contactCountryCode
                    ? item?.contactNumber.replace(item?.contactCountryCode, "")
                    : item?.contactNumber.startsWith("91")
                    ? item?.contactNumber.replace("91", "")
                    : item?.contactNumber
                }`}
                {/* {item?.contactNumber} */}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className={styles.key}>Address</div>
            <div
              style={{ textTransform: "capitalize" }}
              className={styles.value}
            >
              {item?.buildingNumber && `${item.buildingNumber}`}
              {item?.landmark && `, ${item.landmark}`}
              {item?.city && `, ${item.city},`}
              <br />
              {item?.state && `${item.state}`}
              {item?.pin && `, ${item.pin}`}
            </div>
          </div>
          <div>
            <MapAutocomplete
              add={false}
              latitude={item?.latitude !== null && item?.latitude}
              longitude={item?.longitude !== null && item?.longitude}
              disabled={true}
            />
          </div>
        </Box>
      </div>
    );
  };

  const [branchLimitPopup, setBranchLimitPopup] = useState<boolean>(false);

  const closeBranchLimitPopup = () => {
    setBranchLimitPopup(false);
  };

  const openBranchLimitPopup = () => {
    setBranchLimitPopup(true);
  };

  const [searchCity, setSearchCity] = useState("");
  const [searchState, setSearchState] = useState("");
  const [showAllCities, setShowAllCities] = useState<boolean>(false);
  const [showAllStates, setShowAllStates] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

  const [checkedStates, setCheckedStates] = useState<CheckedValue[]>([]);
  const [filterCheckedStates, setFilterCheckedStates] = useState<
    CheckedValue[]
  >([]);
  const [checkedCity, setCheckedCity] = useState<CheckedCity[]>([]);
  const [filterCheckedCity, setFilterCheckedCity] = useState<CheckedCity[]>([]);

  // City
  const filterCitiesWithoutSearch =
    branchList &&
    branchList
      .filter(
        (branch: any, index: number, self: any) =>
          index === self.findIndex((b: any) => b.city === branch.city)
      )
      ?.filter((branch: any) => branch.city !== "")
      .map((branch: any) => ({
        name: branch.city,
        id: branch.id,
      }));

  const filteredCities =
    filterCitiesWithoutSearch &&
    filterCitiesWithoutSearch.filter((branch: any) => {
      const searchCityMatch =
        branch.name.toLowerCase().indexOf(searchCity.toLowerCase()) !== -1;
      return searchCityMatch;
    });

  const displayedCities = showAllCities
    ? branchList && filteredCities
    : branchList && filteredCities.slice(0, 5);

  const handleShowMoreCities = () => {
    setShowAllCities(!showAllCities);
  };

  const filterStateWithoutSearch =
    branchList &&
    branchList
      ?.filter(
        (branch: any, index: number, self: any) =>
          index === self.findIndex((b: any) => b.state === branch.state)
      )
      ?.filter((branch: any) => branch.state !== "")
      .map((branch: any) => ({
        name: branch.state,
        id: branch.id,
      }));

  const filteredState =
    filterStateWithoutSearch &&
    filterStateWithoutSearch?.filter((branch: any) => {
      const searchStateMatch =
        branch.name.toLowerCase().indexOf(searchState.toLowerCase()) !== -1;
      return searchStateMatch;
    });

  const displayedStates = showAllStates
    ? branchList && filteredState
    : branchList && filteredState.slice(0, 5);
  const handleShowMoreStates = () => {
    setShowAllStates(!showAllStates);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterStatesChecked = (stateName: string) => {
    const stateToAdd: CheckedValue = { stateName };
    if (
      checkedStates.some(
        (item) => item.stateName.toLowerCase() === stateName.toLowerCase()
      )
    ) {
      setCheckedStates(
        checkedStates.filter(
          (item) => item.stateName.toLowerCase() !== stateName.toLowerCase()
        )
      );
    } else {
      setCheckedStates([...checkedStates, stateToAdd]);
    }
  };

  const clearCheckedState = (data: string) => {
    setCheckedStates(
      checkedStates.filter(
        (item) => item.stateName.toLowerCase() !== data.toLowerCase()
      )
    );
  };

  const handleFilterCityChecked = (cityName: string) => {
    const cityToAdd: CheckedCity = { cityName };
    if (
      checkedCity.some(
        (item) => item.cityName.toLowerCase() === cityName.toLowerCase()
      )
    ) {
      setCheckedCity(
        checkedCity.filter(
          (item) => item.cityName.toLowerCase() !== cityName.toLowerCase()
        )
      );
    } else {
      setCheckedCity([...checkedCity, cityToAdd]);
    }
  };

  const clearCheckedCities = (data: string) => {
    setCheckedCity(
      checkedCity.filter(
        (item) => item.cityName.toLowerCase() !== data.toLowerCase()
      )
    );
  };

  const filteredBranchList =
    branchList &&
    branchList?.filter((branch: any) => {
      const nameMatch = branch.branchName
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const cityIsChecked =
        filterCheckedCity.length === 0 ||
        filterCheckedCity.some(
          (item) => item.cityName.toLowerCase() === branch.city.toLowerCase()
        );

      const stateIsChecked =
        filterCheckedStates.length === 0 ||
        filterCheckedStates.some(
          (item) => item.stateName.toLowerCase() === branch.state.toLowerCase()
        );

      return nameMatch && cityIsChecked && stateIsChecked;
    });

  const handleFilterBranches = React.useCallback(
    (cityArray: any, stateArray: any) => {
      setFilterCheckedStates(stateArray);
      setFilterCheckedCity(cityArray);
      const queryParams = new URLSearchParams();

      if (stateArray && stateArray.length > 0) {
        const stateParam = stateArray
          .map((item: any) => item.stateName)
          .join("|");
        queryParams.set("filteredStates", stateParam);
      } else {
        queryParams.delete("filteredStates");
      }

      if (cityArray && cityArray.length > 0) {
        const cityParam = cityArray.map((item: any) => item.cityName).join("|");
        queryParams.set("filteredCity", cityParam);
      } else {
        queryParams.delete("filteredCity");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [setFilterCheckedStates, setFilterCheckedCity]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterBranches(checkedCity, checkedStates);

    setFilterApplied(
      checkedCity?.length === 0 && checkedStates?.length === 0 ? false : true
    );
  }, [handleFilterBranches, checkedCity, checkedStates]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setFilterCheckedStates([]);
    setFilterCheckedCity([]);
    setCheckedStates([]);
    setCheckedCity([]);
    handleFilterBranches([], []);
  }, [
    setFilterApplied,
    setCheckedCity,
    setCheckedStates,
    setFilterCheckedCity,
    setFilterCheckedStates,
    handleFilterBranches,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedStates(filterCheckedStates);
    setCheckedCity(filterCheckedCity);
  }, [filterCheckedCity, filterCheckedStates]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const areObjectsEqual = () => {
    const str1 = JSON.stringify(filteredBranchList);
    const str2 = JSON.stringify(branchList);

    return str1 === str2;
  };
  const isEqual = areObjectsEqual();

  const filteredValue = (
    <>
      {filterCheckedStates?.length > 0 &&
        filterCheckedStates?.map((item) => (
          <div key={item.stateName} className="filteredDiv">
            {item.stateName}
          </div>
        ))}
      {filterCheckedCity?.length > 0 &&
        filterCheckedCity?.map((item) => (
          <div key={item.cityName} className="filteredDiv">
            {item.cityName}
          </div>
        ))}
    </>
  );

  useEffect(() => {
    if (filteredBranchList && filteredBranchList?.length > 0) {
      setDefaultSelectedStaffId(
        location?.state?.branchId
          ? location?.state?.branchId
          : filteredBranchList[0]?.id
      );
    }
  }, [filteredBranchList]);

  const instituteDetails = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const branchLicenseCount = instituteDetails?.totalBranchLicense;
  const branchCount = instituteDetails?.branchCount;
  const isBillingAdmin = authUser?.institute[0]?.isBillingAdmin;

  const handleTabClickQuery = (tabId: any) => {
    setSelectedBranchId(tabId);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("selectedBranch", tabId);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedBranchUrlId = urlSearchParams.get("selectedBranch");

  useEffect(() => {
    if (selectedBranchUrlId) {
      setSelectedBranchId(selectedBranchUrlId);
    }
  }, [selectedBranchUrlId]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const stateParam = urlSearchParams.get("filteredStates");
    const cityParam = urlSearchParams.get("filteredCity");

    if (stateParam && filterStateWithoutSearch) {
      const states = stateParam.split("|");

      const selectedStates = states
        .map((value) => {
          const state = filterStateWithoutSearch?.find(
            (type: any) => type.name.toLowerCase() === value.toLowerCase()
          );
          return state
            ? {
                stateName: state.name,
              }
            : null;
        })
        .filter(Boolean) as CheckedValue[];

      setFilterCheckedStates(selectedStates);
      setCheckedStates(selectedStates);
    }

    if (cityParam && filterCitiesWithoutSearch) {
      const cities = cityParam.split("|");

      const selectedCities = cities
        .map((value) => {
          const city = filterCitiesWithoutSearch?.find(
            (type: any) => type.name.toLowerCase() === value.toLowerCase()
          );
          return city
            ? {
                cityName: city.name,
              }
            : null;
        })
        .filter(Boolean) as CheckedCity[];

      setFilterCheckedCity(selectedCities);
      setCheckedCity(selectedCities);
    }

    if (stateParam || cityParam) {
      setFilterApplied(true);
    }
  }, [filterCitiesWithoutSearch]);

  return (
    <div>
      <BranchLimitPopup
        isOpen={branchLimitPopup}
        onClose={closeBranchLimitPopup}
      />
      <Box className={styles.branchMainWrapper}>
        <Box className={styles.leftSection}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "20px",
              marginBottom: "14px",
              marginTop: "30px",
            }}
          >
            {branchListLoading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height="40px"
                style={{
                  borderRadius: "2px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <>
                <Box className={styles.branchHead}>Branches</Box>

                {branchLicenseCount > branchCount ? (
                  <>
                    <Tooltip
                      arrow
                      title={
                        hasBranchAccess === false ? "Oops! No permission." : ""
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                            },
                          },
                        },
                      }}
                    >
                      <span>
                        {hasBranchAccess === false ? (
                          <Button
                            component="label"
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "500",
                              display: "flex",
                              gap: "4px",
                              paddingRight: 0,
                            }}
                            startIcon={<AddIcon />}
                            disabled={hasBranchAccess === false}
                          >
                            Add new
                          </Button>
                        ) : (
                          <Link
                            className={styles.link}
                            to="/app/manage/CreateBranch"
                          >
                            <Button
                              component="label"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                display: "flex",
                                gap: "4px",
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add new
                            </Button>
                          </Link>
                        )}
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip
                    arrow
                    title={
                      hasBranchAccess === false ? "Oops! No permission." : ""
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <Button
                        component="label"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "12px",
                          fontWeight: "500",
                          display: "flex",
                          gap: "4px",
                        }}
                        startIcon={<AddIcon />}
                        onClick={openBranchLimitPopup}
                        disabled={hasBranchAccess === false}
                      >
                        Add new
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              gap: "4px",
            }}
          >
            {branchListLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="40px"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  placeholder="Search Branches"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={handleSearch}
                  size="small"
                  sx={{
                    width: "75%",
                    "&.MuiOutlinedInput-root": {
                      paddingLeft: "0 !important",
                    },
                  }}
                />
                <div style={{ paddingRight: "20px" }}>
                  <FilterDrawer
                    handleFilterApply={handleFilterApply}
                    handleResetClick={handleResetClick}
                    activeFilter={filterApplied}
                    cancelClick={cancelClick}
                    closeSetFilter={handleFilterClose}
                  >
                    <div
                      style={{
                        padding: "22px 32px",
                        overflowY: "auto",
                        maxHeight: "calc(100% - 110px)",
                      }}
                    >
                      <FilterChipsWrap>
                        {checkedCity.length > 0 &&
                          checkedCity.map((item) => (
                            <FilterChips
                              keyVal={item.cityName}
                              chipName={item.cityName}
                              clearValue={item.cityName}
                              closeFunction={clearCheckedCities}
                            />
                          ))}
                        {checkedStates.length > 0 &&
                          checkedStates.map((item) => (
                            <FilterChips
                              keyVal={item.stateName}
                              chipName={item.stateName}
                              clearValue={item.stateName}
                              closeFunction={clearCheckedState}
                            />
                          ))}
                      </FilterChipsWrap>

                      <FilterTypeWrap>
                        <FilterLabel labelValue="City" />
                        <FilterSearchField
                          value={searchCity}
                          onChange={(e: any) => setSearchCity(e.target.value)}
                          placeholder="Search City"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branchList &&
                              displayedCities.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedCity.some(
                                    (data) =>
                                      data.cityName.toLowerCase() ===
                                      item.name.toLowerCase()
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterCityChecked(
                                      item.name.toLowerCase()
                                    )
                                  }
                                  label={item.name.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreCities}
                          showAll={showAllCities}
                          filteredState={filteredCities}
                        />
                      </FilterTypeWrap>

                      <FilterTypeWrap>
                        <FilterLabel labelValue="State" />
                        <FilterSearchField
                          value={searchState}
                          onChange={(e: any) => setSearchState(e.target.value)}
                          placeholder="Search State"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branchList &&
                              displayedStates.map(
                                (item: any, index: number) => (
                                  <FilterCheckbox
                                    keyVal={item.id}
                                    checked={checkedStates.some(
                                      (data) =>
                                        data.stateName.toLowerCase() ===
                                        item.name.toLowerCase()
                                    )}
                                    checkboxFunction={() =>
                                      handleFilterStatesChecked(item.name)
                                    }
                                    label={item.name.toLowerCase()}
                                  />
                                )
                              )}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreStates}
                          showAll={showAllStates}
                          filteredState={filteredState}
                        />
                      </FilterTypeWrap>
                    </div>
                  </FilterDrawer>
                </div>
              </>
            )}
          </Box>

          {branchListLoading ? (
            Array.from(new Array(4)).map((_, index) => (
              <div key={index} className={styles.branchCard}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="25px"
                  width="100%"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="15px"
                  width="100%"
                  style={{
                    borderRadius: "3px",
                  }}
                />
              </div>
            ))
          ) : (
            <>
              {branchList && filteredBranchList?.length === 0 && (
                <Box
                  sx={{ textAlign: "center", position: "relative", top: "40%" }}
                >
                  <div className={styles.NoBranch}>No Branches Found</div>
                  <div className={styles.TryAnother}>Try another search</div>
                </Box>
              )}{" "}
              <Box className={styles.branchListWrapper}>
                {filteredBranchList &&
                  filteredBranchList?.map((item: any, index: any) => (
                    <div
                      key={index}
                      className={`${styles.branchCard} ${
                        selectedBranchUrlId && item?.id === selectedBranchUrlId
                          ? styles.selectedBranchCard
                          : !selectedBranchUrlId && item.id === selectedBranchId
                          ? styles.selectedBranchCard
                          : ""
                      }`}
                      onClick={() => handleTabClickQuery(item.id)}
                    >
                      <Box className={styles.branchName}>
                        {item.branchName} {item.state ? `,${item.state}` : ""}
                      </Box>
                      <Box className={styles.branchDesignation}>
                        <p className={styles.addressDetails}>
                          {item.city && `${item.city}`}
                        </p>
                      </Box>
                    </div>
                  ))}
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.rightSection}>
          {branchListLoading ? (
            <div className={styles.rightSubSection}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #EBEBED",
                  padding: "20px",
                }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width="47px"
                    height="47px"
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                </Box>
              </Box>
              <Box sx={{ padding: "20px" }}>
                <div style={{ display: "flex", gap: "40px" }}>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="120px"
                    height="25px"
                    style={{
                      borderRadius: "2px",
                      marginBottom: "10px",
                      display: "block",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="120px"
                    height="15px"
                    style={{
                      borderRadius: "2px",
                    }}
                  />
                </div>
              </Box>
            </div>
          ) : (
            <Box>
              {selectedBranchId &&
              filteredBranchList &&
              filteredBranchList.length > 0 ? (
                <BranchDetails
                  item={filteredBranchList.find((item: any) =>
                    selectedBranchUrlId
                      ? item?.id === selectedBranchUrlId
                      : item.id === selectedBranchId
                  )}
                />
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </div>
  );
}
