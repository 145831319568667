import { Box } from "@mui/material";
import React, { useState } from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import DeletePopup from "../DeletePopup/DeletePopup";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { resetDeleteNoteSuccess } from "../../pages/app/Nurture/service/nurtureSlice";
import { useSelector } from "react-redux";
import TaskEditPopup from "./TaskEditPopup/TaskEditPopup";
import getTeamMember, {
  getDescriptionWithMentionedNames,
} from "../CommonFunctions/CommonFunctions";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function ActivityNote(props: any) {
  const {
    data,
    activityList,
    employees,
    showAddNote,
    setShowAddNote,
    DeleteNote,
    selectedLeadData,
    setFromNotesEdit,
    setNotesForEditing,
    isNoteTab,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const { deleteNoteLoading } = useSelector((state: any) => state.nurture);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [dataForDelete, setDataForDelete] = useState<any>();

  const handleDeleteButtonClick = () => {
    setShowDeletePopup(true);
    setDataForDelete(data);
  };

  const handleEditButtonClick = (val: any) => {
    setShowAddNote(!showAddNote);
    setNotesForEditing(val);
    setFromNotesEdit(true);
  };

  const handlePopupYesButton = () => {
    if (authUser && authUser?.institute[0]) {
      const data = {
        idsToDelete: [dataForDelete?.id],
        instituteId: authUser?.institute[0]?.instituteId,
        enquiryId: selectedLeadData?.id,
        studentName:
          selectedLeadData?.studentFirstName +
          selectedLeadData?.studentLastName,
        studentEmail: selectedLeadData?.studentEmail,
        swimlaneState: selectedLeadData?.enquirySwimlaneStatus,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      DeleteNote(data);
      setTimeout(() => {
        dispatch(resetDeleteNoteSuccess());
      }, 3000);
    }
    setShowDeletePopup(false);
  };

  const handlePopupNoButton = () => {
    setShowDeletePopup(false);
  };

  const activityData =
    data && data?.activityData && Object?.keys(data?.activityData).length !== 0
      ? data?.activityData
      : data?.noteData;

  const noteDescription = activityList
    ? activityData?.description
    : data?.description;

  const modifiedDescription = getDescriptionWithMentionedNames(
    noteDescription,
    employees
  ).replace(/\n/g, "<br>");

  const creatorId = authUser?.institute?.[0]?.id;
  const urlParams = new URLSearchParams(window.location.search);
  const leadNoteId = urlParams.get("leadNoteId");
  const leadActivityId = urlParams.get("leadActivityId");

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
          background:
            leadNoteId === data?.id
              ? "#e8faf6"
              : leadActivityId === data?.id
              ? "#e8faf6"
              : "transparent",
          padding:
            leadNoteId === data?.id
              ? "0 5px 5px 5px"
              : leadActivityId === data?.id
              ? "0 5px 5px 5px"
              : "0px",
          borderRadius:
            leadNoteId === data?.id
              ? "4px"
              : leadActivityId === data?.id
              ? "4px"
              : "0px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityHead}>
            <div className={styles.activityTop}>
              <div className={styles.activityDateStyle}>
                {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
              </div>
              <div className={styles.activityHeadStage}>
                <div className={styles.activityHeadStageIcon}>
                  <img src={stageDot} alt="" />
                </div>
                <div>
                  {activityList
                    ? data?.currentStage
                    : data?.enquirySwimlaneStatus
                        ?.toLowerCase()
                        .replace(/_/g, " ")}
                </div>
              </div>
              <div className={styles.activityHeadName}>
                <div className={styles.activityHeadStageIcon}>
                  <img src={nameLine} alt="" />
                </div>
                <div style={{ textTransform: "capitalize" }}>
                  {isNoteTab ? (
                    <>
                      {data?.updatedByName === ""
                        ? getTeamMember(data?.createdById, employees)
                            ?.firstName +
                          " " +
                          getTeamMember(data?.createdById, employees)?.lastName
                        : getTeamMember(data?.updatedById, employees)
                            ?.firstName +
                          " " +
                          getTeamMember(data?.updatedById, employees)?.lastName}
                    </>
                  ) : (
                    <>
                      {data.activityType === "NOTE" ||
                      data.activityType === "NOTE_ADDED"
                        ? getTeamMember(data?.createdById, employees)
                            ?.firstName +
                          " " +
                          getTeamMember(data?.createdById, employees)?.lastName
                        : getTeamMember(data?.updatedById, employees)
                            ?.firstName +
                          " " +
                          getTeamMember(data?.updatedById, employees)?.lastName}
                    </>
                  )}
                </div>
              </div>
            </div>

            {!activityList && (
              <>
                {creatorId === data?.createdById && hasLeadAccess && (
                  <>
                    {showDeletePopup && (
                      <DeletePopup
                        type={"note"}
                        handlePopupYesButton={handlePopupYesButton}
                        handlePopupNoButton={handlePopupNoButton}
                        loading={deleteNoteLoading}
                      />
                    )}
                    <TaskEditPopup
                      handleDeleteButtonClick={handleDeleteButtonClick}
                      handleEditButtonClick={handleEditButtonClick}
                      data={data}
                    />
                  </>
                )}
              </>
            )}
          </div>

          <div
            className={styles.activityDemoTitle}
            style={{
              color:
                data.activityType === "NOTE_DELETED" ? "#EC3939" : "#142C44",
            }}
          >
            {data.activityType === "NOTE_ADDED"
              ? "Note Added"
              : data.activityType === "NOTE_EDITED"
              ? "Note Edited"
              : data.activityType === "NOTE_DELETED"
              ? "Note Deleted"
              : data.activityType === "NOTE_ADDED_POST_CALL_FEEDBACK"
              ? "Note Added - Post Call Feedback"
              : ""}
          </div>
          <div
            className={styles.activityDescription}
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />
        </Box>
      </Box>
    </>
  );
}

export default ActivityNote;
