import React, { useCallback, useEffect, useState } from "react";
import styles from "./CompletedTasks.module.css";
import { TextField, Button, FormGroup, Box, Skeleton } from "@mui/material";
import CompletedCard from "../CompletedCard/CompletedCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useLocation, useNavigate } from "react-router-dom";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import getAccessStatus from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { GetTaskBoard } from "../../service/tasks.request";
import InfiniteScroll from "react-infinite-scroll-component";
import UserAvatarGroup from "../../../../../components/UserAssign/UserAvatarGroup";
import { PuffLoader } from "react-spinners";
import { resetTaskBoard } from "../../service/tasksSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  getLeadListByInstituteAndContact,
  setLeadModalState,
} from "../../../Nurture/service/nurtureSlice";

type Data = {
  instituteId: string;
  startDate: any;
  endDate: any;
  limit: number;
  userId?: any;
  lanesToFetch?: {
    status: string;
    lastEvaluatedKey: string;
    lastEvaluatedSortKey: string;
  }[];
};

function CompletedTasks(props: any) {
  const { GetTasksByDateForAssignee, GetTasksBoard } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userById } = useSelector((state: any) => state.userDetails);
  const { taskStatusUpdateSuccess } = useSelector((state: any) => state.tasks);

  const hasAllTaskAccess = getAccessStatus(
    "TASKS",
    "ALL_OTHER_USERS'_TASK",
    userById?.rolePermission
  );

  const taskList = useSelector((state: any) => state.tasks.tasksboard);
  const { leadBoard } = useSelector((state: any) => state.nurture);
  const tasksTypes = leadBoard?.taskType;

  const taskBoardloading = useSelector(
    (state: any) => state.tasks.taskBoardloading
  );
  const employees = useSelector((state: any) => state.staff.staffList);
  const [taskItems, setTaskItems] = useState<any>();
  const [selectedTeamMember, setSelectedTeamMember] = React.useState<any>([]);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const [leadSearchTerm, setLeadSearchTerm] = React.useState<any>("");

  const [selectedDate, setSelectedDate] = React.useState<number | null>(null);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const completedTasks =
    taskList && taskItems?.find((task: any) => task.status === "COMPLETED");

  function handleGoBack() {
    dispatch(resetTaskBoard());
    if (location?.state?.taskSelectedId) {
      navigate(`/app/Tasks?selectedUsers=${location?.state?.taskSelectedId}`);
    } else {
      navigate(`/app/Tasks`);
    }
  }

  const handleLeadSearch = (event: any) => {
    setLeadSearchTerm(event.target.value);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("taskLeadSearch", event.target.value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const taskBoardApiCall = useCallback(
    (
      instituteId: string,
      taskStartDate: any,
      taskEndDate: any,
      userId: any
    ) => {
      const data: Data = {
        instituteId: instituteId,
        startDate: taskStartDate,
        endDate: taskEndDate,
        limit: 15,
      };

      if (hasAllTaskAccess && userId !== "") {
        data.userId = userId;
      } else if (!hasAllTaskAccess) {
        data.userId = authUser?.institute[0]?.id;
      }

      data.lanesToFetch = [
        {
          status: "COMPLETED",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        },
      ];
      GetTasksBoard(data);
    },
    [hasAllTaskAccess]
  );

  const handleDateChange = (date: any) => {
    if (date !== null) {
      const dateUnix = date.unix() * 1000;
      setSelectedDate(dateUnix);

      const selectedDateFirstMinute = moment(dateUnix).startOf("day").valueOf();
      const selectedDateLastMinute = moment(dateUnix).endOf("day").valueOf();

      taskBoardApiCall(
        authUser?.institute[0]?.instituteDetails?.id,
        selectedDateFirstMinute,
        selectedDateLastMinute,
        selectedTeamMember?.length > 0 ? selectedTeamMember?.[0]?.id : ""
      );

      const queryParams = new URLSearchParams(window.location.search);
      if (dateUnix) {
        queryParams.set("taskDate", dateUnix.toString());

        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }
  };

  const handleResetDate = useCallback(() => {
    setSelectedDate(null);
    taskBoardApiCall(
      authUser?.institute[0]?.instituteDetails?.id,
      0,
      0,
      selectedTeamMember?.length > 0 ? selectedTeamMember?.[0]?.id : ""
    );
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("taskDate");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  }, [setSelectedDate]);

  const handleNextPage = (currentLaneStatus: any) => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    const taskDate = urlSearchParams.get("taskDate");

    let startingDate = null;
    let endingDate = null;
    if (taskDate && taskDate !== null) {
      startingDate = moment(parseInt(taskDate)).startOf("day").valueOf();
      endingDate = moment(parseInt(taskDate)).endOf("day").valueOf();
    } else {
      startingDate = 0;
      endingDate = 0;
    }

    const evaluatedKeysForLanes: any =
      taskItems &&
      taskItems
        ?.filter((d: any) => d.status === currentLaneStatus)
        ?.map((d: any) => {
          return {
            status: d?.status,
            lastEvaluatedKey: d?.lastEvaluatedKey ? d?.lastEvaluatedKey : "",
            lastEvaluatedSortKey: d?.lastEvaluatedSortKey
              ? d?.lastEvaluatedSortKey
              : "",
          };
        });

    const hasEvaluatedKey = evaluatedKeysForLanes?.filter(
      (ds: any) => ds?.lastEvaluatedKey !== ""
    );
    const endOfView = window.scrollY + window.innerHeight;

    const data: Data = {
      instituteId: userFromLocalStorage?.id,
      startDate: startingDate,
      endDate: endingDate,
      limit: 15,
      lanesToFetch: evaluatedKeysForLanes,
    };

    if (hasAllTaskAccess && selectedUsersParam) {
      data.userId = selectedUsersParam;
    } else if (!hasAllTaskAccess) {
      data.userId = authUser?.institute[0]?.id;
    }

    if (hasEvaluatedKey?.length > 0 && endOfView) {
      GetTaskBoard(data).then((res: any) => {
        const result = res?.data?.data;
        const updatedState = taskItems?.map((oldObject: any) => {
          const correspondingResult = result?.find(
            (r: any) => r.status === oldObject.status
          );

          if (correspondingResult) {
            const updatedTasks = [...oldObject.tasks];
            correspondingResult?.tasks?.forEach((newTask: any) => {
              if (!updatedTasks?.find((task) => task.id === newTask.id)) {
                updatedTasks.push(newTask);
              }
            });

            return {
              ...oldObject,
              tasks: updatedTasks,
              lastEvaluatedKey: correspondingResult?.lastEvaluatedKey,
              lastEvaluatedSortKey: correspondingResult?.lastEvaluatedSortKey,
            };
          } else {
            return oldObject;
          }
        });
        setTaskItems(updatedState);
      });
    }
  };

  const handleTeamMemberSelect = (emp: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskDate = urlSearchParams.get("taskDate");

    let startingDate: any | null = null;
    let endingDate: any | null = null;

    if (taskDate && taskDate !== null) {
      startingDate = moment(parseInt(taskDate)).startOf("day").valueOf();
      endingDate = moment(parseInt(taskDate)).endOf("day").valueOf();
    } else {
      startingDate = 0;
      endingDate = 0;
    }

    setSelectedTeamMember((prevSelected: any) => {
      if (prevSelected?.length > 0 && prevSelected?.[0].id === emp?.id) {
        if (startingDate !== null && endingDate !== null) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            startingDate,
            endingDate,
            ""
          );
        }

        return [];
      } else {
        if (startingDate !== null && endingDate !== null && emp?.id) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            startingDate,
            endingDate,
            emp?.id
          );
        }
        return [emp];
      }
    });

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("selectedUsers") === emp.id) {
      queryParams.delete("selectedUsers");
    } else {
      queryParams.set("selectedUsers", emp.id);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;

      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      taskId: string,
      taskTab: string
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "task");
      queryParams.set("taskTabOpen", taskTab);

      queryParams.set("leadTaskId", taskId);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());

      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  interface CheckedPriorities {
    priority: string;
  }
  interface CheckedTypes {
    type: string;
  }
  const [checkedPriorities, setCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);
  const [filterCheckedPriorities, setFilterCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);
  const [checkedTypes, setCheckedTypes] = useState<CheckedTypes[]>([]);
  const [filterCheckedTypes, setFilterCheckedTypes] = useState<CheckedTypes[]>(
    []
  );

  const handleFilterPriorityChecked = (priority: string) => {
    const priorityToAdd: CheckedPriorities = { priority };

    if (
      checkedPriorities.some(
        (item) => item.priority.toLowerCase() === priority.toLowerCase()
      )
    ) {
      setCheckedPriorities(
        checkedPriorities.filter(
          (item) => item.priority.toLowerCase() !== priority.toLowerCase()
        )
      );
    } else {
      setCheckedPriorities([...checkedPriorities, priorityToAdd]);
    }
  };

  const clearCheckedPriority = (priority: string) => {
    setCheckedPriorities(
      checkedPriorities.filter(
        (item) => item.priority.toLowerCase() !== priority.toLowerCase()
      )
    );
  };

  const handleFilterTypeChecked = (type: string) => {
    const typeToAdd: CheckedTypes = { type };

    if (
      checkedTypes.some(
        (item) => item.type.toLowerCase() === type.toLowerCase()
      )
    ) {
      setCheckedTypes(
        checkedTypes.filter(
          (item) => item.type.toLowerCase() !== type.toLowerCase()
        )
      );
    } else {
      setCheckedTypes([...checkedTypes, typeToAdd]);
    }
  };

  const clearCheckedType = (type: string) => {
    setCheckedTypes(
      checkedTypes.filter(
        (item) => item.type.toLowerCase() !== type.toLowerCase()
      )
    );
  };

  const filteredTasks =
    taskList &&
    completedTasks?.tasks?.filter((task: any) => {
      const searchTermMatch =
        task.studentName.toLowerCase().indexOf(leadSearchTerm.toLowerCase()) !==
        -1;

      const typeIsChecked =
        filterCheckedTypes.length === 0 ||
        filterCheckedTypes.some(
          (item) => item.type.toLowerCase() === task.taskType.toLowerCase()
        );

      const priorityIsChecked =
        filterCheckedPriorities.length === 0 ||
        filterCheckedPriorities.some(
          (item) => item.priority.toLowerCase() === task.priority.toLowerCase()
        );

      const additionalSearchTermCondition =
        task?.taskType === "DEMO" && task?.demoData?.[0]?.batchName
          ? task?.demoData?.[0]?.batchName
              .toLowerCase()
              .includes(leadSearchTerm.toLowerCase())
          : true;

      return (
        searchTermMatch &&
        typeIsChecked &&
        additionalSearchTermCondition &&
        priorityIsChecked
      );
    });

  const handleFilterTasks = React.useCallback(
    (typesArray: any, prioritiesArray: any) => {
      setFilterCheckedTypes(typesArray);
      setFilterCheckedPriorities(prioritiesArray);

      const queryParams = new URLSearchParams();
      if (typesArray && typesArray.length > 0) {
        const statusParam = typesArray.map((item: any) => item.type).join("|");
        queryParams.set("completedTaskType", statusParam);
      } else {
        queryParams.delete("completedTaskType");
      }

      if (prioritiesArray && prioritiesArray.length > 0) {
        const priorityParam = prioritiesArray
          .map((item: any) => item.priority)
          .join("|");
        queryParams.set("taskPriorities", priorityParam);
      } else {
        queryParams.delete("taskPriorities");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [setFilterCheckedTypes, setFilterCheckedPriorities]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterTasks(checkedTypes, checkedPriorities);

    setFilterApplied(
      checkedPriorities?.length === 0 && checkedTypes?.length === 0
        ? false
        : true
    );
  }, [handleFilterTasks, checkedTypes, setFilterApplied, checkedPriorities]);

  const handleResetClick = React.useCallback(() => {
    handleFilterTasks([], []);
    setCheckedTypes([]);
    setCheckedPriorities([]);
    setFilterApplied(false);
  }, [
    handleFilterTasks,
    setFilterApplied,
    setCheckedTypes,
    setCheckedPriorities,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedTypes(filterCheckedTypes);
    setCheckedPriorities(filterCheckedPriorities);
  }, [filterCheckedTypes, filterCheckedPriorities]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
    setFilterApplied(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const filteredValue = (
    <>
      {filterCheckedPriorities?.length > 0 &&
        filterCheckedPriorities?.map((item) => (
          <div key={item.priority} className="filteredDiv">
            {item.priority.toLowerCase()}
          </div>
        ))}
      {filterCheckedTypes?.length > 0 &&
        filterCheckedTypes?.map((item) => (
          <div key={item.type} className="filteredDiv">
            {item.type?.toLowerCase()}
          </div>
        ))}
    </>
  );

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const typeParam = urlSearchParams.get("completedTaskType");
    const priorityParam = urlSearchParams.get("taskPriorities");

    if (priorityParam) {
      const priorities = priorityParam.split("|");

      const selectedPriorities = priorities
        .map((value) => {
          const priority = [
            { priority: "URGENT" },
            { priority: "HIGH" },
            { priority: "NORMAL" },
            { priority: "LOW" },
          ].find(
            (type: any) => type.priority.toLowerCase() === value.toLowerCase()
          );
          return priority
            ? {
                priority: priority.priority,
              }
            : null;
        })
        .filter(Boolean) as CheckedPriorities[];

      setFilterCheckedPriorities(selectedPriorities);
      setCheckedPriorities(selectedPriorities);
    }
    if (typeParam && tasksTypes) {
      const types = typeParam.split("|");

      const selectedTypes = types
        .map((value) => {
          const type = tasksTypes.find(
            (taskType: any) =>
              taskType.taskTypeName.toLowerCase() === value.toLowerCase()
          );
          return type
            ? {
                type: type.taskTypeName,
              }
            : null;
        })
        .filter(Boolean) as CheckedTypes[];

      setCheckedTypes(selectedTypes);
      setFilterCheckedTypes(selectedTypes);
    }
    if (typeParam || priorityParam) {
      setFilterApplied(true);
    }
  }, [tasksTypes]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    // const taskDate = urlSearchParams.get("taskDate");

    const taskLeadSearchInUrl = urlSearchParams.get("taskLeadSearch");

    if (taskLeadSearchInUrl && taskLeadSearchInUrl !== "") {
      setLeadSearchTerm(taskLeadSearchInUrl);
    }

    // if (taskDate && taskDate !== null) {
    //   setSelectedDate(parseInt(taskDate));
    // }
  }, [setSelectedDate, setLeadSearchTerm]);

  useEffect(() => {
    if (taskList) {
      setTaskItems(taskList);
    } else {
      setTaskItems([]);
    }
  }, [taskList]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (employees?.length > 0) {
      const staff = employees
        ?.filter((item: any) => item?.status === "ACTIVE")
        ?.find((item: any) => item.id === selectedUsersParam);
      if (staff) {
        setSelectedTeamMember([staff]);
      } else {
        setSelectedTeamMember([]);
      }
    }
  }, [employees]);

  React.useEffect(() => {
    if (Array.isArray(taskItems)) {
      const evaluatedKeysForLanes = taskItems?.map((d) => {
        return {
          status: d?.status,
          lastEvaluatedKey: d?.lastEvaluatedKey ? d?.lastEvaluatedKey : "",
          lastEvaluatedSortKey: d?.lastEvaluatedSortKey
            ? d?.lastEvaluatedSortKey
            : "",
        };
      });

      const hasEvaluatedKey = evaluatedKeysForLanes.some(
        (ds) => ds.lastEvaluatedKey !== ""
      );

      setHasMoreData(hasEvaluatedKey);
    } else {
      setHasMoreData(false);
    }
  }, [taskItems]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskDate = urlSearchParams.get("taskDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (userById) {
      if (taskDate && taskDate !== null) {
        const startDate = moment(parseInt(taskDate)).startOf("day").valueOf();
        const endDate = moment(parseInt(taskDate)).endOf("day").valueOf();
        setSelectedDate(parseInt(taskDate));

        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          startDate,
          endDate,
          selectedUsersParam ? selectedUsersParam : ""
        );
      } else {
        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          0,
          0,
          selectedUsersParam ? selectedUsersParam : ""
        );
      }
    }
  }, [userById]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskDate = urlSearchParams.get("taskDate");
    const selectedUsersParam = urlSearchParams.get("selectedUsers");
    if (taskStatusUpdateSuccess !== true && taskStatusUpdateSuccess !== false) {
      if (taskDate && taskDate !== null) {
        const startDate = moment(parseInt(taskDate)).startOf("day").valueOf();
        const endDate = moment(parseInt(taskDate)).endOf("day").valueOf();

        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          startDate,
          endDate,
          selectedUsersParam ? selectedUsersParam : ""
        );
      } else {
        taskBoardApiCall(
          authUser?.institute[0]?.instituteDetails?.id,
          0,
          0,
          selectedUsersParam ? selectedUsersParam : ""
        );
      }
    }
  }, [taskStatusUpdateSuccess]);

  return (
    <div className={styles.pageWrap}>
      <div className={styles.pageWrapChild} style={{ display: "flex" }}>
        <div style={{ width: "calc( 100% - 360px)", padding: "22PX 30PX" }}>
          {completedTasks !== null ? (
            <>
              <div>
                <button
                  className={styles.completedTaskPageBackBtn}
                  onClick={handleGoBack}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </div>
              <h5 className={styles.completedTaskHead}>Completed tasks</h5>
              <div className={styles.completedTaskTopSplit}>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      type="search"
                      value={leadSearchTerm}
                      onChange={handleLeadSearch}
                      placeholder="Search Tasks"
                      style={{
                        padding: "0px",
                        borderRadius: "4px",
                        border: "none",
                        outline: "none",
                        height: "38px",
                      }}
                      size="small"
                      inputProps={{
                        sx: {
                          width: "250px",
                          "&::placeholder": {
                            fontWeight: "400",
                            fontSize: "14px",
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10.5px 14px",
                          },
                        },
                      }}
                    />
                    {hasAllTaskAccess && employees?.length > 0 && (
                      <UserAvatarGroup
                        handleItemSelect={handleTeamMemberSelect}
                        selectedItem={selectedTeamMember}
                      />
                    )}
                  </div>
                  <FilterDrawer
                    handleFilterApply={handleFilterApply}
                    handleResetClick={handleResetClick}
                    activeFilter={filterApplied}
                    cancelClick={cancelClick}
                    closeSetFilter={handleFilterClose}
                  >
                    <div
                      style={{
                        padding: "22px 32px",
                        overflowY: "auto",
                        maxHeight: "calc(100% - 110px)",
                      }}
                    >
                      <FilterChipsWrap>
                        {checkedPriorities.length > 0 &&
                          checkedPriorities.map((item) => (
                            <FilterChips
                              keyVal={item.priority}
                              chipName={item.priority.toLowerCase()}
                              clearValue={item.priority}
                              closeFunction={clearCheckedPriority}
                            />
                          ))}
                        {checkedTypes.length > 0 &&
                          checkedTypes.map((item) => (
                            <FilterChips
                              keyVal={item.type}
                              chipName={item.type.toLowerCase()}
                              clearValue={item.type}
                              closeFunction={clearCheckedType}
                            />
                          ))}
                      </FilterChipsWrap>

                      <FilterTypeWrap>
                        <FilterLabel labelValue="Priority" />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {[
                              { priority: "URGENT" },
                              { priority: "HIGH" },
                              { priority: "NORMAL" },
                              { priority: "LOW" },
                            ].map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.priority}
                                checked={checkedPriorities.some(
                                  (data) =>
                                    data.priority.toLowerCase() ===
                                    item.priority.toLowerCase()
                                )}
                                checkboxFunction={() =>
                                  handleFilterPriorityChecked(item.priority)
                                }
                                label={item.priority.toLowerCase()}
                              />
                            ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>

                      <FilterTypeWrap>
                        <FilterLabel labelValue="Type" />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {tasksTypes &&
                              tasksTypes.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedTypes.some(
                                    (data) =>
                                      data.type?.toLowerCase() ===
                                      item?.taskTypeName?.toLowerCase()
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterTypeChecked(item.taskTypeName)
                                  }
                                  label={item?.taskTypeName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                    </div>
                  </FilterDrawer>
                </div>
              </div>
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="100px"
                height={15}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="200px"
                height={25}
                style={{ marginBottom: "8PX" }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width="99.4%"
                height={40}
                style={{ marginBottom: "15PX" }}
              />
            </>
          )}

          <div
            style={{
              maxHeight: "calc(100vh - 250px)",
              paddingRight: "5px",
              minHeight: "calc(100vh - 250px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
              overflowY: "hidden",
            }}
          >
            {completedTasks !== null && !taskBoardloading ? (
              <>
                {completedTasks && (
                  <InfiniteScroll
                    dataLength={completedTasks?.tasks?.length}
                    next={() => handleNextPage(completedTasks?.status)}
                    hasMore={hasMoreData}
                    loader={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* {<PuffLoader color="#36d7b7" />} */}
                      </Box>
                    }
                    height={"calc(100vh - 250px)"}
                  >
                    {taskList &&
                      filteredTasks?.map((task: any) => (
                        <CompletedCard
                          taskCompletedPage={true}
                          task={task}
                          expandDisabled={false}
                          employees={employees}
                          openLeadExpandModal={openLeadExpandModal}
                          page="COMPLETED_TASKS"
                        />
                      ))}
                  </InfiniteScroll>
                )}
                {taskList && filteredTasks && filteredTasks?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#667085",
                        textTransform: "capitalize",
                      }}
                    >
                      No data available
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {Array.from(new Array(4)).map((_, index) => (
                  <div
                    key={index}
                    className={styles.tableSkeletonBodyItem}
                    style={{ marginBottom: "10px" }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width="100%"
                      height={100}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div
          style={{ width: "360px", borderLeft: "1px solid #E9E9E9" }}
          className="datepicker-container"
        >
          {taskList ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={dayjs(selectedDate)}
                  onChange={(date: any) => handleDateChange(date)}
                  displayStaticWrapperAs="desktop"
                  disableFuture
                />
              </LocalizationProvider>
              {selectedDate !== null && (
                <div
                  style={{
                    margin: "0 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                    width: "310px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleResetDate}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    size="small"
                  >
                    Reset Date
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <div style={{ padding: "10px" }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height="40px"
                  style={{
                    borderRadius: "3px",
                    marginBottom: "10px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height="290px"
                  style={{
                    borderRadius: "3px",
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </div>
  );
}

export default CompletedTasks;
