import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./SideBar.module.css";
import { useEffect } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  resetLeadModalState,
  resetLeadsOnNurture,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setToastNotification } from "../ToastNotification/service/toastNotificationSlice";

export function SideBar(props: any) {
  const { item, setIsActive, index, setMenuData, isActive, isCompress } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const profileCompletedStatus = instituteData?.isProfileCompleted;

  const shouldDisableGeneralSetup =
    accountStatus === "ARCHIVED" ||
    accountStatus === "LOCKED" ||
    accountStatus === "UNARCHIVED" ||
    accountStatus === "INACTIVE" ||
    !profileCompletedStatus;

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "#11C89B",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  useEffect(() => {
    const { href } = window.location;
    if (href.includes("inbound")) {
      setIsActive(1);
    } else if (href.includes("Tasks")) {
      setIsActive(3);
    } else if (href.includes("Students")) {
      setIsActive(4);
    } else if (
      href.includes("Reports") ||
      href.includes("Reports/AttendanceReportCreation")
    ) {
      setIsActive(6);
    } else if (href.includes("Attendance")) {
      setIsActive(5);
    } else if (href.includes("Integrations")) {
      setIsActive(7);
    } else if (href.includes("Settings")) {
      setIsActive(8);
    } else if (href.includes("manage") || href.includes("dashboard")) {
      setIsActive(0);
    }
  }, [setIsActive, window.location.href]);

  return (
    <ListItem
      button
      onClick={() => {
        if (item?.access === false) {
          return;
        }
        if (
          instituteData?.branchCreated === false ||
          instituteData?.profileCreated === false
        ) {
          navigate(`/app/Home`);
          return;
        } else if (
          (instituteData?.courseCreated === false &&
            instituteData?.batchCreated === false) ||
          (instituteData?.courseCreated === true &&
            instituteData?.batchCreated === false)
        ) {
          dispatch(
            setToastNotification({
              message:
                instituteData?.courseCreated === false &&
                instituteData?.batchCreated === false
                  ? "Please add Course and Batch to continuee"
                  : instituteData?.courseCreated === true &&
                    instituteData?.batchCreated === false
                  ? "Please add Batch to continue"
                  : instituteData?.courseCreated === false &&
                    instituteData?.batchCreated === true
                  ? "Please add Course to continue"
                  : "",
              type: "error",
              snackOpen: true,
            })
          );
          if (
            instituteData?.courseCreated === false &&
            instituteData?.batchCreated === false
          ) {
            navigate(`/app/manage/Courses`);
          } else if (
            instituteData?.courseCreated === true &&
            instituteData?.batchCreated === false
          ) {
            navigate(`/app/manage/Batches`);
          }

          return;
        }
        setIsActive(index);
        setMenuData(item.text);
        dispatch(resetLeadModalState());
        navigate(`/app/${item.to}`);
        if (item.to !== "Nurture") {
          dispatch(resetLeadsOnNurture());
        }
      }}
      key={item.text}
      disablePadding
      style={{
        paddingLeft: isCompress ? "" : "10px",
        borderRadius: isActive !== index ? "9px !important" : "",
        pointerEvents:
          shouldDisableGeneralSetup || userById === null ? "none" : "auto",

        cursor: item?.access === false ? "auto" : "pointer",
      }}
      sx={{
        borderLeft:
          isActive === index ? "3px solid #11c89b" : "3px solid #142C44",
        backgroundColor: isActive === index ? "#143c4c" : "#142C44",
      }}
      disabled={
        instituteData?.isProfileCompleted === false ||
        (instituteData?.courseCreated === false &&
          instituteData?.batchCreated === false) ||
        (instituteData?.courseCreated === true &&
          instituteData?.batchCreated === false)
          ? true
          : instituteData?.courseCreated === false &&
            instituteData?.batchCreated === false
      }
    >
      <Tooltip
        arrow
        placement="right"
        title={
          item?.access === false
            ? "You don’t have permission to view this menu."
            : ""
        }
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#101828",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 12px",
              maxWidth: "230px",
              "& .MuiTooltip-arrow": {
                color: "#101828",
              },
            },
          },
        }}
      >
        <span
          style={{
            width: "100%",
          }}
        >
          <ListItemButton
            sx={{
              padding: isCompress === false ? "8px 16px" : "14px 6px",
              alignItems: "center",
              justifyContent: "center",
              pointerEvents: item?.access === false ? "none" : "auto",
            }}
            disabled={item?.access === false}
          >
            <ListItemIcon
              className={styles.sidebar_list_icon}
              sx={{
                minWidth: isCompress === false ? "0px" : "100%",
                alignItems: "center",
              }}
            >
              {isCompress === true ? (
                <LightTooltip title={item?.text} placement="right">
                  <img
                    src={item?.icon}
                    height={item?.text === "Students" ? "25px" : "20px"}
                    width={item?.text === "Students" ? "25px" : "20px"}
                    alt=""
                  />
                </LightTooltip>
              ) : (
                <img
                  src={item?.icon}
                  height={item?.text === "Students" ? "25px" : "20px"}
                  width={item?.text === "Students" ? "25px" : "20px"}
                  alt=""
                />
              )}
            </ListItemIcon>
            {isCompress === false && (
              <ListItemText
                primary={item.text}
                sx={{
                  marginLeft: "13px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: item?.color,
                  ".MuiTypography-root": {
                    fontSize: "0.8rem !important",
                  },
                }}
              />
            )}
          </ListItemButton>
        </span>
      </Tooltip>
    </ListItem>
  );
}
