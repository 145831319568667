import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useCallback } from "react";
import {
  ErrorMessageBasedOnType,
  RequiredEmptyFieldText,
  InvalidNameText,
} from "./FormTextInput.utils";
import { ErrorType, InputType, inputProps } from "./types";
export default function NewFormTextInput(props: inputProps & any) {
  const {
    label = "",
    type,
    value,
    onChange,
    error = false,
    showErrorMessage = true,
    validate = false,
    setValidStatus,
    errorType = "client",
    placeholder = "",
    require = false,
    addStartAdornment = false,
    disabled,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [OnceFocusedFlag, setOnceFocusedFlag] = React.useState<boolean>(false);
  const [inputValue, setInputVlaue] = React.useState<string>("");
  const [focused, setFocused] = React.useState<boolean>(false);
  const [passwordType, setPasswordType] = React.useState<InputType>(type);
  const [localErrorType, setLocalErrorType] =
    React.useState<ErrorType>("client");
  React.useEffect(() => {
    if (error) {
      setOnceFocusedFlag(true);
    }
  }, [error]);
  React.useEffect(() => {
    if (errorType) {
      setLocalErrorType(errorType);
    }
  }, [errorType]);
  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
    !showPassword ? setPasswordType("text") : setPasswordType("password");
  }, [showPassword]);
  const onChangeHandle = useCallback(
    (e: any) => {
      setLocalErrorType("client");
      onChange(e);
      setInputVlaue(e.target.value);
      if (validate && e?.target) {
        switch (type) {
          case "contactName":
            const isValidName = /^[a-zA-Z\s]+$/.test(e.target.value);
            if (require) {
              setValidStatus(isValidName);
            } else {
              setValidStatus(e.target.value === "" ? true : isValidName);
            }
            break;
          case "email":
            const isVlaidEmail =
              /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/.test(
                e.target.value
              );
            if (require) {
              setValidStatus(isVlaidEmail);
            } else {
              setValidStatus(e.target.value === "" ? true : isVlaidEmail);
            }
            break;
          case "phone":
            const isVlaidPhone =
              /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(
                e.target.value
              );
            setValidStatus(isVlaidPhone);
            break;
          default:
            setValidStatus(e.target.value !== "");
        }
      }
    },
    [onChange, setValidStatus, type, validate]
  );

  return (
    <>
      <InputLabel
        sx={{
          fontSize: "12px",
          color: "#142C44",
          fontWeight: "500",
          marginBottom: "5px",
          fontFamily: "Poppins",
          display: "flex",
        }}
      >
        {label}
        {require && <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>}
      </InputLabel>
      <TextField
        id="text-field"
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          fontFamily: "Poppins",
          "#contactNumber": {
            paddingLeft: "14px",
          },
          "#contact": {
            paddingLeft: "14px",
          },
          "#instituteContact": {
            paddingLeft: "14px",
          },
        }}
        {...rest}
        variant={"outlined"}
        placeholder={placeholder}
        fullWidth
        type={type !== "password" ? type : passwordType}
        value={value}
        onChange={onChangeHandle}
        error={error ? true : false}
        InputProps={{
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null,
          startAdornment: addStartAdornment && (
            <InputAdornment
              position="start"
              sx={{
                padding: "8.5px 30px 8.5px 14px",
                marginRight: "0px",
                background: "#FAFAFA",
                borderRadius: "8px 0 0px 8px",
                height: "unset",
                maxHeight: "unset",
                borderRight: "1px solid #E9E9E9",
                marginLeft: "-14px",
              }}
            >
              +91
            </InputAdornment>
          ),
          autoComplete: "new-password",
        }}
        size="small"
        disabled={disabled}
      />
      {error &&
        showErrorMessage &&
        !focused &&
        OnceFocusedFlag &&
        localErrorType !== "server" && (
          <FormHelperText error={true}>
            {inputValue !== ""
              ? ErrorMessageBasedOnType(type)
              : RequiredEmptyFieldText}
          </FormHelperText>
        )}
    </>
  );
}
