import React from "react";
import Box from "@mui/material/Box";
import TasksKanban from "./TasksKanban";
import { useAuthUser } from "react-auth-kit";
import InactiveAccountPopup from "../../../../components/CustomPopupBilling/InactiveAccountPopup";
import { useSelector } from "react-redux";

export default function Tasks(props: any) {
  const {
    GetTasksByAssignee,
    GetAllTasksType,
    // tasksTypes,
    UpdateTaskStatus,
    GetTasksBoard,
    tasksboard,
    taskStatusUpdateSuccess,
    tasksByAssignee,
    GetLeadListByInstituteAndContact,
    CreateTask,

    GetTasksById,
    taskById,
    GetStaffsByInstitute,
    UpdateTaskPriority,
    UpdateTaskAssignee,
    taskPriorityUpdateSuccess,
    taskAssigneeUpdateSuccess,
    taskStatusUpdateError,
    taskAssigneeUpdateError,
    taskPriorityUpdateError,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetLeadById,
    GetInboxByLeadId,
    GetLeadNoteList,
    GetLeadTaskList,
    GetLeadActivityList,
    GetLeadPaymentList,
    GetDemoCalanderByCourseAndInstitute,
    GetEmiDataOfLead,
    GetNurtureLeadBoard,
    UpdateLead,
    UpdateLeadSwimlaneStatus,
    UpdateLeadEnquiyStatus,
    UpdateLeadEmiDetails,
    nurtureAddEnquiry,
    CreateNote,
    RecordPayment,
    CreateDemo,
    ComposeEmailSent,
    CreateBatch,
    SendPaymentLink,
    DownloadPaymentReceipt,
    EditTask,
    EditNote,
    EditDemo,
    DeleteNote,
    DeleteTask,
    DeleteDemoBookedForLead,
    leadListByContact,
    EditRecordPayment,
    CallLead,
    GetAppsByInstitute,
    CreateSource,
    CallDispositionForm,
    CreateComment,
    DeleteComment,
    EditComment,
    taskBoardloading,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const isBillingAdmin = authUser?.institute[0]?.isBillingAdmin;

  const { leadBoard } = useSelector((state: any) => state.nurture);
  const tasksTypes = leadBoard?.taskType;

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <div
        style={{
          minHeight: "calc( 100vh - 65px )",
          maxHeight: "calc( 100vh - 65px )",
          backgroundColor: "#F5F5F5",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <InactiveAccountPopup type={accountStatus} />
        <TasksKanban
          tasks={tasksboard && tasksboard}
          UpdateTaskStatus={UpdateTaskStatus}
          tasksByAssignee={tasksByAssignee}
          GetTasksBoard={GetTasksBoard}
          tasksTypes={tasksTypes}
          taskStatusUpdateSuccess={taskStatusUpdateSuccess}
          GetLeadListByInstituteAndContact={GetLeadListByInstituteAndContact}
          CreateTask={CreateTask}
        />
      </div>
    </Box>
  );
}
