import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Typography, Button, Box, Modal } from "@mui/material";
import styles from "./NewReportModal.module.css";
import reportGrowth from "../../../../../assets/images/report-growth.svg";
import reportLeadership from "../../../../../assets/images/report-leadership.svg";
import reportEvent from "../../../../../assets/images/report-event.svg";
import { useNavigate } from "react-router-dom";

interface NewReportModalProps {
  open: boolean;
  onClose: () => void;
  page: string;
}
const NewReportModal: React.FC<NewReportModalProps> = ({
  open,
  onClose,
  page,
}) => {
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState("");

  const handleSelect = (type: string) => {
    setSelectedReport(type);
  };

  const handleSubmit = () => {
    if (selectedReport === "leads") {
      navigate("/app/Reports/LeadReportCreation", {
        state: {
          from:
            page === "RECENTREPORTS"
              ? "RecentReports"
              : page === "CREATEDBYME"
              ? "CreatedByMe"
              : "AllReports",
          data: null,
          isFromView: false,
        },
      });
    } else if (selectedReport === "revenue") {
      navigate("/app/Reports/RevenueReportCreation", {
        state: {
          from:
            page === "RECENTREPORTS"
              ? "RecentReports"
              : page === "CREATEDBYME"
              ? "CreatedByMe"
              : "AllReports",
          data: null,
          isFromView: false,
        },
      });
    } else {
      navigate("/app/Reports/AttendanceReportCreation", {
        state: {
          from:
            page === "RECENTREPORTS"
              ? "RecentReports"
              : page === "CREATEDBYME"
              ? "CreatedByMe"
              : "AllReports",
          data: null,
          isFromView: false,
        },
      });
    }
    onClose();
    setSelectedReport("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          zIndex: "99999",
          "& .MuiDialog-paper": {
            maxHeight: "290px",
            minHeight: "165px",
            padding: "24px",
            borderRadius: "12px",
            boxShadow:
              "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
            left: "5%",
            top: "2%",
            background: "#FFFFFF",
            overflow: "hidden",
          },
        }}
      >
        <div>
          <div className={styles.closeModalButton}>
            <CloseIcon
              sx={{ color: "#555555" }}
              onClick={() => {
                onClose();
                setSelectedReport("");
              }}
            />
          </div>
          <Typography variant="h6" className={styles.modalTitle}>
            Create New Report
          </Typography>
          <Box className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() => handleSelect("leads")}
              className={styles.reportButton}
              style={{
                backgroundColor:
                  selectedReport === "leads" ? "#e8faf5" : "transparent",
                color: "black",
              }}
            >
              <div className={styles.iconContainer}>
                <img
                  src={reportLeadership}
                  alt="Leads"
                  className={styles.icon}
                />{" "}
                Leads
              </div>
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSelect("attendance")}
              className={styles.reportButton}
              style={{
                backgroundColor:
                  selectedReport === "attendance" ? "#e8faf5" : "transparent",
                color: "black",
                // opacity: "0.5",
              }}
              // disabled={true}
            >
              <div className={styles.iconContainer}>
                <img src={reportEvent} alt="Leads" className={styles.icon} />{" "}
                Attendance
              </div>
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSelect("revenue")}
              className={styles.reportButton}
              style={{
                backgroundColor:
                  selectedReport === "revenue" ? "#e8faf5" : "transparent",
                color: "black",
                opacity: "0.5",
              }}
              disabled={true}
            >
              <div className={styles.iconContainer}>
                <img src={reportGrowth} alt="Leads" className={styles.icon} />{" "}
                Revenue
              </div>
            </Button>
          </Box>
        </div>
        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            sx={{
              color: "#BFBFBF",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              border: "1px solid #BFBFBF",
              backgroundColor: "#FFF",
              "&:hover": {
                backgroundColor: "#FFF",
                border: "1px solid #BFBFBF",
              },
            }}
            onClick={() => {
              onClose();
              setSelectedReport("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", marginLeft: "10px" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={selectedReport === ""}
          >
            Create
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};
export default NewReportModal;
