import React from "react";
import styles from "./LeadBatchPopup.module.css";
import close from "./closeIcon.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Box, Dialog, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getBatchById,
  resetBatchById,
} from "../../../Manage/ManageBatch/service/Batchslice";
import { useDispatch } from "react-redux";

function LeadBatchPopup(props: any) {
  const { selectedBatch, showAddLeadWideView, selectedCurrency } = props;
	const dispatch = useDispatch();

	const batch = useSelector((state: any) => state?.batch?.batchById);
	const batchByIdLoading = useSelector(
		(state: any) => state?.batch?.batchByIdLoading
	);

	const [open, setOpen] = React.useState<boolean>(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		dispatch(resetBatchById());
	};

	const validBatchSessions = showAddLeadWideView
		? batch?.batchSchedule &&
		  batch?.batchSchedule?.filter((item: any, index: any) => {
				if (
					item?.batchSessions?.length > 0 &&
					item?.batchSessions[0]?.startTime !== 0
				) {
					return item;
				}
		  })
		: selectedBatch?.batchSchedule &&
		  selectedBatch?.batchSchedule?.filter((item: any, index: any) => {
				if (
					item?.batchSessions?.length > 0 &&
					item?.batchSessions[0]?.startTime !== 0
				) {
					return item;
				}
		  });

	return (
		<div>
			<div className={styles.blueBtnWrap}>
				<button
					className={styles.blueBtn}
					onClick={() => {
						handleOpen();
						dispatch<any>(getBatchById(selectedBatch?.id));
					}}>
					View Batch Details
				</button>
			</div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				sx={{
					zIndex: '99999',
					minWidth: {
						xs: '85%',
						sm: '85%',
						md: '450px',
						lg: '450px',
						xl: '450px',
					},

					'& .MuiDialog-paper': {
						width: '100%',

						padding: '0',
						overflow: 'hidden',
						maxWidth: {
							xs: '85%',
							sm: '85%',
							md: '450px',
							lg: '450px',
							xl: '450px',
						},
						maxHeight: {
							xs: '412px',
							sm: '412px',
							md: '412px',
							lg: '412px',
							xl: '412px',
						},
						minHeight: {
							xs: '412px',
							sm: '412px',
							md: '412px',
							lg: '412px',
							xl: '412px',
						},
					},
				}}>
				<div>
					<div className={styles.leadBatchPopupHeadWrap}>
						<div>
							{batchByIdLoading ? (
								<Skeleton
									animation='wave'
									variant='text'
									height={15}
									width='80px'
								/>
							) : (
								<div className={styles.leadBatchPopupHead}>
									{batch?.batchName}
								</div>
							)}

							{batchByIdLoading ? (
								<Skeleton
									animation='wave'
									variant='text'
									height={10}
									width='50px'
								/>
							) : (
								<>
									<div>
										{batch?.paymentDetails?.paymentType === 'REGULAR' ? (
											<div className={styles.leadBatchPrice}>
												{selectedCurrency +
													' ' +
													batch?.paymentDetails?.paymentData?.totalAmount}
											</div>
										) : batch?.paymentDetails?.paymentType === 'SESSION' ? (
											<div className={styles.leadBatchPrice}>
												{selectedCurrency +
													' ' +
													batch?.paymentDetails?.paymentData?.finalPrice}
											</div>
										) : (
											<div className={styles.membershipBatchPriceWrap}>
												{batch?.paymentDetails?.paymentData?.paymentStructure?.map(
													(item: any) => (
														<div className={styles.membershipBatchPriceItem}>
															<div
																className={
																	styles.membershipBatchPriceItemLabel
																}>
																{item?.frequency
																	?.toLowerCase()
																	?.replace(/_/g, ' ')}
															</div>
															<div
																className={
																	styles.membershipBatchPriceItemValue
																}>
																{/* 	{item?.finalEmiAmount?.toLocaleString('en-IN', {
																	style: 'currency',
																	currency: 'INR',
																})} */}
																{selectedCurrency + ' ' + item?.finalEmiAmount}
															</div>
														</div>
													)
												)}
											</div>
										)}

										{/* ₹ {batch?.batchPrice} */}
									</div>
								</>
							)}
						</div>
						<div>
							<button
								className={styles.leadBatchPopupClsBtn}
								onClick={handleClose}>
								<img src={close} alt='close' />
							</button>
						</div>
					</div>
					<div className={styles.leadBatchBox}>
						{batchByIdLoading ? (
							<Skeleton
								animation='wave'
								variant='rounded'
								height={60}
								width='100%'
							/>
						) : (
							<>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Box
										sx={{
											marginBottom: '7px',
											color: '#667085',
											fontSize: '14px',
											fontWeight: '500',
											lineHeight: '22px',
										}}>
										Seats
									</Box>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: '7px',
										}}>
										<Box
											sx={{
												margin: '0px',
												color: ' #667085',
												fontSize: '11px',
												fontWeight: '400',
												lineHeight: '14PX',
												textTransform: 'capitalize',
											}}>
											Min - {batch?.minimumSeats}
										</Box>
										<Box
											sx={{
												color: ' rgba(102, 112, 133, 0.3)',
												fontSize: '11px',
												fontWeight: '500',
												lineHeight: '15PX',
												marginRight: '10px',
												marginLeft: '10px',
												textTransform: 'capitalize',
												marginBottom: '0px',
											}}>
											|
										</Box>
										<Box sx={{ display: 'flex' }}>
											<Box>
												<h1
													style={{
														margin: '0px',
														color:
															batch?.studentCount < batch?.minimumSeats
																? ' #EC3939'
																: '#11C89B',
														fontSize: '14px',
														fontWeight: '400',
														lineHeight: '22PX',
													}}>
													{batch?.studentCount}
												</h1>
											</Box>
											<Box>
												<h1
													style={{
														fontSize: '14px',
														fontWeight: '400',
														lineHeight: '22PX',
														color: '#667085',
														marginBottom: '0',
													}}>
													/
												</h1>
											</Box>
											<Box>
												<h1
													style={{
														margin: '0px',
														color: '#667085',
														fontSize: '14px',
														fontWeight: '400',
														lineHeight: '22PX',
														marginBottom: '0',
													}}>
													{batch?.maximumSeats}
												</h1>
											</Box>
										</Box>
									</Box>
								</Box>

								<ProgressBar
									minimumSeats={batch?.minimumSeats}
									maximumSeats={batch?.maximumSeats}
									enrolledSeats={batch?.studentCount}
								/>
							</>
						)}
					</div>
					<div className={styles.leadBatchBox}>
						{batchByIdLoading ? (
							<Skeleton
								animation='wave'
								variant='rounded'
								height={60}
								width='100%'
							/>
						) : (
							<>
								<div className={styles.leadBatchDatesWrap}>
									<div className={styles.leadBatchDatesWrapItem}>
										<div className={styles.leadBatchDatesWrapItemLabel}>
											Start Date
										</div>
										<div className={styles.leadBatchDatesWrapItemValue}>
											{batch?.startDate?.toString()?.length === 13
												? moment(batch?.startDate).format('DD/MM/YYYY')
												: moment.unix(batch?.startDate).format('DD/MM/YYYY')}
										</div>
									</div>
									<div className={styles.leadBatchDatesWrapItem}>
										<div className={styles.leadBatchDatesWrapItemLabel}>
											End Date
										</div>
										<div className={styles.leadBatchDatesWrapItemValue}>
											{batch?.endDate?.toString()?.length === 13
												? moment(batch?.endDate).format('DD/MM/YYYY')
												: moment.unix(batch?.endDate).format('DD/MM/YYYY')}
										</div>
									</div>
								</div>{' '}
							</>
						)}
					</div>
					<div className={styles.leadBatchScheduleWrap}>
						{batchByIdLoading ? (
							<Skeleton
								animation='wave'
								variant='rounded'
								height={100}
								width='100%'
							/>
						) : (
							<>
								<div className={styles.leadBatchScheduleTitle}>
									Scheduled Timing
								</div>

								{validBatchSessions &&
									validBatchSessions?.map((item: any, index: number) => (
										<>
											<div
												key={index}
												className={styles.leadBatchScheduleWrapItem}>
												<div className={styles.leadBatchScheduleWrapItemDay}>
													{item?.day && moment(item?.day, 'ddd').format('dddd')}
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}>
													{item?.batchSessions?.map((d: any) => {
														return (
															<div
																className={
																	styles.leadBatchScheduleWrapItemTime
																}>
																{moment.unix(d?.startTime)?.format('hh:mm A') +
																	' ' +
																	'to' +
																	' ' +
																	moment.unix(d?.endTime)?.format('hh:mm A')}
															</div>
														);
													})}
												</div>
											</div>
										</>
									))}
							</>
						)}
					</div>
				</div>
			</Dialog>
		</div>
	);
}

export default LeadBatchPopup;
