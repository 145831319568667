import React, { useState } from "react";
import { Box, Stack, TextField, FormGroup } from "@mui/material";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import styles from "./TaskKanbanHeader.module.css";
import UserAvatarGroup from "../../../../../components/UserAssign/UserAvatarGroup";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import getAccessStatus from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import OpenSearch from "../../../Nurture/components/OpenSearch/OpenSearch";

function TaskKanbanHeader(props: any) {
  const {
    handleFilterTasks,
    handleLeadSearch,
    leadSearchTerm,
    tasksTypes,
    filteredValue,
    filterCheckedTypes,
    filterCheckedPriorities,
    selectedTeamMember,
    setSelectedTeamMember,
    taskBoardApiCall,
    rangeStartDate,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasAllTaskAccess = getAccessStatus(
    "TASKS",
    "ALL_OTHER_USERS'_TASK",
    userById?.rolePermission
  );
  const staffList = useSelector((state: any) => state.staff.staffList);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  interface CheckedPriorities {
    priority: string;
  }
  interface CheckedTypes {
    type: string;
  }

  const [checkedPriorities, setCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);

  const [checkedTypes, setCheckedTypes] = useState<CheckedTypes[]>([]);

  const handleFilterPriorityChecked = (priority: string) => {
    const priorityToAdd: CheckedPriorities = { priority };

    if (
      checkedPriorities.some(
        (item) => item.priority.toLowerCase() === priority.toLowerCase()
      )
    ) {
      setCheckedPriorities(
        checkedPriorities.filter(
          (item) => item.priority.toLowerCase() !== priority.toLowerCase()
        )
      );
    } else {
      setCheckedPriorities([...checkedPriorities, priorityToAdd]);
    }
  };

  const clearCheckedPriority = (priority: string) => {
    setCheckedPriorities(
      checkedPriorities.filter(
        (item) => item.priority.toLowerCase() !== priority.toLowerCase()
      )
    );
  };

  const handleFilterTypeChecked = (type: string) => {
    const typeToAdd: CheckedTypes = { type };

    if (
      checkedTypes.some(
        (item) => item.type.toLowerCase() === type.toLowerCase()
      )
    ) {
      setCheckedTypes(
        checkedTypes.filter(
          (item) => item.type.toLowerCase() !== type.toLowerCase()
        )
      );
    } else {
      setCheckedTypes([...checkedTypes, typeToAdd]);
    }
  };

  const clearCheckedType = (type: string) => {
    setCheckedTypes(
      checkedTypes.filter(
        (item) => item.type.toLowerCase() !== type.toLowerCase()
      )
    );
  };

  const handleFilterApply = React.useCallback(() => {
    handleFilterTasks(checkedPriorities, checkedTypes);

    setFilterApplied(
      checkedPriorities?.length === 0 && checkedTypes?.length === 0
        ? false
        : true
    );
  }, [handleFilterTasks, checkedPriorities, checkedTypes, setFilterApplied]);

  const handleResetClick = React.useCallback(() => {
    handleFilterTasks([], []);
    setCheckedTypes([]);
    setCheckedPriorities([]);
    setFilterApplied(false);
  }, [
    handleFilterTasks,
    setFilterApplied,
    setCheckedPriorities,
    setCheckedTypes,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedTypes(filterCheckedTypes);
    setCheckedPriorities(filterCheckedPriorities);
  }, [filterCheckedPriorities, filterCheckedTypes]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
    setFilterApplied(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  // const handleTeamMemberSelect = (emp: any) => {
  //   setSelectedTeamMember([emp]);
  //   const queryParams = new URLSearchParams(window.location.search);
  //   queryParams.set("selectedUsers", emp?.id);
  //   const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  //   window.history.pushState({}, "", newUrl);
  // };
  const handleTeamMemberSelect = (emp: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const taskStartDate = urlSearchParams.get("taskStartDate");
    const taskEndDate = urlSearchParams.get("taskEndDate");
    let startingDate: any | null = null;
    let endingDate: any | null = null;
    if (taskStartDate && taskStartDate !== null && taskEndDate !== null) {
      startingDate = taskStartDate;
      endingDate = taskEndDate;
    } else {
      startingDate = moment(rangeStartDate).startOf("day").unix() * 1000;
      endingDate = moment(rangeStartDate).endOf("day").unix() * 1000;
    }

    setSelectedTeamMember((prevSelected: any) => {
      if (prevSelected?.length > 0 && prevSelected?.[0].id === emp?.id) {
        // If the selected item is already in the array, remove it
        if (startingDate !== null && endingDate !== null) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            startingDate,
            endingDate,
            ""
          );
        }

        return [];
      } else {
        // Otherwise, add the selected item
        if (startingDate !== null && endingDate !== null && emp?.id) {
          taskBoardApiCall(
            authUser?.institute[0]?.instituteDetails?.id,
            startingDate,
            endingDate,
            emp?.id
          );
        }
        return [emp];
      }
    });

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("selectedUsers") === emp.id) {
      queryParams.delete("selectedUsers");
    } else {
      queryParams.set("selectedUsers", emp.id);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const priorityParam = urlSearchParams.get("taskPriorities");
    const typeParam = urlSearchParams.get("taskTypes");

    if (priorityParam) {
      const priorities = priorityParam.split("|");

      const selectedPriorities = priorities
        .map((value) => {
          const priority = [
            { priority: "URGENT" },
            { priority: "HIGH" },
            { priority: "NORMAL" },
            { priority: "LOW" },
          ].find(
            (type: any) => type.priority.toLowerCase() === value.toLowerCase()
          );
          return priority
            ? {
                priority: priority.priority,
              }
            : null;
        })
        .filter(Boolean) as CheckedPriorities[];

      setCheckedPriorities(selectedPriorities);
    }

    if (typeParam && tasksTypes) {
      const types = typeParam.split("|");

      const selectedTypes = types
        .map((value) => {
          const type = tasksTypes.find(
            (taskType: any) =>
              taskType.taskTypeName.toLowerCase() === value.toLowerCase()
          );
          return type
            ? {
                type: type.taskTypeName,
              }
            : null;
        })
        .filter(Boolean) as CheckedTypes[];

      setCheckedTypes(selectedTypes);
    }

    if (priorityParam || typeParam) {
      setFilterApplied(true);
    }
  }, [tasksTypes]);

  return (
    <Stack
      sx={{
        backgroundColor: "#fff",
        padding: "12px 24px",
      }}
    >
      <Box
        display="flex"
        flexGrow={1}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className={styles.taskHeaderWrapper}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              type="search"
              value={leadSearchTerm}
              onChange={handleLeadSearch}
              placeholder="Search Tasks"
              style={{
                padding: "0px",
                borderRadius: "4px",
                border: "none",
                outline: "none",
                height: "38px",
              }}
              size="small"
              inputProps={{
                sx: {
                  width: "250px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "12px",
                  },

                  "& .MuiOutlinedInput-input": {
                    padding: "10.5px 14px",
                  },
                },
              }}
            />
            {hasAllTaskAccess && staffList?.length > 0 && (
              <UserAvatarGroup
                handleItemSelect={handleTeamMemberSelect}
                selectedItem={selectedTeamMember}
              />
            )}
          </div>

          <FilterDrawer
            handleFilterApply={handleFilterApply}
            handleResetClick={handleResetClick}
            activeFilter={filterApplied}
            cancelClick={cancelClick}
            closeSetFilter={handleFilterClose}
          >
            <div
              style={{
                padding: "22px 32px",
                overflowY: "auto",
                maxHeight: "calc(100% - 110px)",
              }}
            >
              <FilterChipsWrap>
                {checkedPriorities.length > 0 &&
                  checkedPriorities.map((item) => (
                    <FilterChips
                      keyVal={item.priority}
                      chipName={item.priority.toLowerCase()}
                      clearValue={item.priority}
                      closeFunction={clearCheckedPriority}
                    />
                  ))}

                {checkedTypes.length > 0 &&
                  checkedTypes.map((item) => (
                    <FilterChips
                      keyVal={item.type}
                      chipName={item.type.toLowerCase()}
                      clearValue={item.type}
                      closeFunction={clearCheckedType}
                    />
                  ))}
              </FilterChipsWrap>

              <FilterTypeWrap>
                <FilterLabel labelValue="Priority" />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {[
                      { priority: "URGENT" },
                      { priority: "HIGH" },
                      { priority: "NORMAL" },
                      { priority: "LOW" },
                    ].map((item: any) => (
                      <FilterCheckbox
                        keyVal={item.priority}
                        checked={checkedPriorities.some(
                          (data) =>
                            data.priority.toLowerCase() ===
                            item.priority.toLowerCase()
                        )}
                        checkboxFunction={() =>
                          handleFilterPriorityChecked(item.priority)
                        }
                        label={item.priority.toLowerCase()}
                      />
                    ))}
                  </FormGroup>
                </FilterCheckBoxWrap>
              </FilterTypeWrap>

              <FilterTypeWrap>
                <FilterLabel labelValue="Type" />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {tasksTypes &&
                      tasksTypes?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedTypes.some(
                            (data) =>
                              data.type?.toLowerCase() ===
                              item?.taskTypeName?.toLowerCase()
                          )}
                          checkboxFunction={() =>
                            handleFilterTypeChecked(item.taskTypeName)
                          }
                          label={item?.taskTypeName?.toLowerCase()}
                        />
                      ))}
                  </FormGroup>
                </FilterCheckBoxWrap>
              </FilterTypeWrap>
            </div>
          </FilterDrawer>
        </div>
      </Box>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </Stack>
  );
}

export default TaskKanbanHeader;
