import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorType } from "../../../../components/FormTextInput/types";
import { INSTITUTE_LOG, SAVECHANGES_BTN_TEX } from "./InstituteProfileString";
import { InstitutionsProps as IProps } from "./container/instituteConatiner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MapAutocomplete from "../../../../components/googleMap/MapAutocomplete";
import { reset as resetFileUploadState } from "../../Main/components/FileUpload/service/fileUploadSlice";
import { useDispatch } from "react-redux";
import galleryIcon from "../../../../assets/images/image.png";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";
import Previews from "./DropZoneFile";
import CancelModal from "../../../../components/CancelModal/CancelModal";
import CountryCodePhoneInput from "../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { updateInstituteProfile } from "../../Main/service/instituteReducer";
import InstituteSkeleton from "../../../../components/Skeletons/InstituteSkeleton";
import { GetInstituteByIdRequest } from "./service/institute.request";
import { getUserById } from "../UserDetails/service/userDetailsSlice";
import { resetInstitiuteStatuses } from "./service/instituteSlice";

export default function InstituteProfile(props: IProps) {
  const {
    institute,
    error,
    success,
    loading,
    getInstituteById,
    instituteDetails,
    UpdateInstituteInLocal,
    InstituteImageUpload,
    fileUploadSuccess,
    instituteUpdateloading,
    instituteUpdateSuccess,
    instituteByIdLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.login.userDetails);
  // const userFromLocalStorage = JSON.parse(
  //   localStorage.getItem("ACTIVE_INSTITUTE")!
  // );
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  useEffect(() => {
    setImage(activeInstitute?.logo);
  }, [activeInstitute]);
  const [instituteName, setInstituteName] = React.useState<boolean>(true);
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);
  const [validStateBuilding, setValidStateBuilding] =
    React.useState<boolean>(true);
  const [validStateLocality, setValidStateLocality] =
    React.useState<boolean>(true);

  const [image, setImage] = React.useState<any>(galleryIcon);

  const [isBranch, setIsBranch] = React.useState(false);
  const [instituteDetailsLocal, setInstituteDetails] = React.useState<any>({
    buildingNumber: "",
    city: "",
    id: "",
    instituteContact: "",
    contactCountryCode: "",
    instituteEmail: "",
    instituteName: "",
    landmark: "",
    latitude: "",
    locality: "",
    logo: "",
    longitude: "",
    pin: "",
    state: "",
    status: "",
    subdomain: "",
    website: "",
    isInstitute: true,
    isBranch: false,
    banner: "",
    isProfileCompleted: false,
    about: "",
    socialMediaHandles: [],
  });

  const [logoDetails, setLogoDetails] = React.useState<any>({
    fileBase64String: "",
    fileName: "",
    fileType: "INSTITUTE_PROFILE_IMAGE",
    instituteId: "",
    userId: "",
  });

  const [bannerDetails, setBannerDetails] = React.useState<any>({
    fileBase64String: "",
    fileName: "",
    fileType: "INSTITUTE_COVER_IMAGE",
    instituteId: "",
    userId: "",
  });

  const [displaySocialMedia, setDisplaySocialMedia] = React.useState<any>([
    {
      socialMedia: "FACEBOOK",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "INSTAGRAM",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "TWITTER",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "LINKEDIN",
      url: "",
      isVisible: false,
    },
  ]);

  const pinRegex = /^\d{6}$/;
  const [pinError, setPinError] = React.useState<boolean>(true);

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [contactCountryCode, setContactCountryCode] = React.useState("91");

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalClose = () => setOpenModal(false);

  const [data, setData] = useState<any>();

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setData(res?.data?.data);
      })
      .catch((err: any) => {});
  }, []);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setData(res?.data?.data);
      })
      .catch((err: any) => {});
  }, []);

  const handleImageUpload = React.useCallback(
    async (cropImage: any, image: any) => {
      handleLogoUpload(cropImage, image.name);
    },
    [logoDetails]
  );

  useEffect(() => {
    const element = document.getElementById("Institute Profile");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  const handleLogoUpload = React.useCallback(
    async (imageUrl: any, fileName: any) => {
      if (imageUrl) {
        setLogoDetails({
          ...logoDetails,
          fileBase64String: imageUrl,
        });
        handleImageSubmit(fileName, imageUrl);
      }
    },
    [setImage, logoDetails, InstituteImageUpload]
  );

  const handleImageSubmit = React.useCallback(
    (fileName: any, convertedFile: any) => {
      let logo_Detail = {
        fileBase64String: convertedFile,
        fileName: fileName,
        fileType: logoDetails?.fileType,
        instituteId: logoDetails?.instituteId,
        userId: logoDetails?.userId,
      };
      setLogoDetails(logo_Detail);
      try {
        logo_Detail.fileBase64String !== "" &&
          InstituteImageUpload(logo_Detail);
      } catch (error) {}
    },
    [logoDetails, InstituteImageUpload]
  );

  const fetchPincodeData = async (pin: string) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pin}`
      );
      if (response.data && response.data.length > 0) {
        const pinData = response.data[0];

        const firstPostOffice = pinData?.PostOffice?.[0];
        if (firstPostOffice !== null) {
          setInstituteDetails((prevDetails: any) => ({
            ...prevDetails,
            city: firstPostOffice?.District,
            state: firstPostOffice?.State,
            locality: firstPostOffice?.Name,
          }));
        } else {
          setInstituteDetails((prevDetails: any) => ({
            ...prevDetails,
            city: "",
            state: "",
            locality: "",
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };

  const handleGeoChange = React.useCallback(
    (geoLocation: any) => {
      let templocality: any, tempPin: any, tempCity: any, tempStat: any;
      geoLocation.address_components.map((ele: any) => {
        if (ele.types[0] === "locality") {
          templocality = ele?.long_name;
        } else if (ele.types[0] === "postal_code") {
          tempPin = ele?.long_name;
          fetchPincodeData(ele?.long_name);
        } else if (ele.types[0] === "administrative_area_level_3") {
          tempCity = ele?.long_name;
        } else if (ele.types[0] === "administrative_area_level_1") {
          tempStat = ele?.long_name;
        }
      });

      setInstituteDetails({
        ...instituteDetailsLocal,
        latitude: `${geoLocation.geometry.location.lat()}`,
        longitude: `${geoLocation.geometry.location.lng()}`,
        locality: templocality,
        city: tempCity === templocality ? tempCity : templocality,
        pin: tempPin,
        state: tempStat,
      });
    },
    [instituteDetailsLocal]
  );

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      setErrorType("client");
      if (event.target.id === "pin") {
        if (event.target.value.trimStart() !== "") {
          if (pinRegex.test(event.target.value)) {
            setPinError(true);
          } else {
            setPinError(false);
          }
        } else {
          setPinError(true);
        }
      }

      const { id, value } = event.target;
      const lowercaseValue =
        id === "instituteEmail"
          ? value.toLowerCase().trimStart()
          : value.trimStart();

      setInstituteDetails({
        ...instituteDetailsLocal,
        [id]: lowercaseValue,
      });
    },
    [instituteDetailsLocal, setInstituteDetails, pinRegex]
  );

  const handlePinChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowErrMsz(true);
      setPinError(true);
      setErrorType("client");

      const { id, value } = event.target;
      const lowercaseValue =
        id === "email" ? value.toLowerCase().trimStart() : value.trimStart();

      setInstituteDetails((prevDetails: any) => ({
        ...prevDetails,
        [id]: lowercaseValue,
      }));

      if (event.target.id === "pin") {
        if (!pinRegex.test(event.target.value)) {
          setPinError(false);
        } else {
          setPinError(true);
          const pin = event.target.value;

          if (pin?.length >= 6) {
            fetchPincodeData(pin);
          }
        }
      }
    },
    [pinRegex]
  );

  const setValidateInstituteName = React.useCallback((val: boolean) => {
    setInstituteName(val);
  }, []);
  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const setValidateStatusBuilding = React.useCallback((val: boolean) => {
    setValidStateBuilding(val);
  }, []);
  const setValidateStatusLocality = React.useCallback((val: boolean) => {
    setValidStateLocality(val);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      let formIsValid = true;
      if (!instituteDetailsLocal.instituteName) {
        setInstituteName(false);
        formIsValid = false;
      }
      if (!instituteDetailsLocal.instituteEmail) {
        setValidStateEmail(false);
        formIsValid = false;
      }
      if (!instituteDetailsLocal.instituteContact) {
        setValidStateContact(false);
        formIsValid = false;
      }

      if (!instituteDetailsLocal.buildingNumber) {
        setValidStateBuilding(false);
        formIsValid = false;
      }
      if (instituteDetailsLocal.buildingNumber.trim() === "") {
        setValidStateBuilding(false);
        formIsValid = false;
      }
      if (!instituteDetailsLocal.locality) {
        setValidStateLocality(false);
        formIsValid = false;
      }

      if (
        instituteDetailsLocal &&
        !instituteDetailsLocal.updatedById &&
        !instituteDetailsLocal.updatedByName
      ) {
        // You can set these properties to some default values or get them from some source
        instituteDetailsLocal.updatedById = authUser?.institute[0]?.id;
        instituteDetailsLocal.updatedByName =
          authUser?.firstName + " " + authUser?.lastName;
        instituteDetailsLocal.createdById = instituteDetailsLocal?.isBranch
          ? authUser?.institute[0]?.id
          : "";
        instituteDetailsLocal.createdByName = instituteDetailsLocal?.isBranch
          ? authUser?.firstName + " " + authUser?.lastName
          : "";
      }

      if (
        formIsValid &&
        instituteDetailsLocal.instituteName.trim() !== "" &&
        instituteDetailsLocal.instituteEmail.trim() !== "" &&
        instituteDetailsLocal.instituteContact.trim() !== "" &&
        instituteDetailsLocal.buildingNumber.trim() !== "" &&
        instituteDetailsLocal.locality.trim() !== "" &&
        pinError &&
        validStateContact &&
        validStateEmail
      ) {
        institute(instituteDetailsLocal);
        dispatch(
          updateInstituteProfile({
            instituteProfileImage: `${
              instituteDetailsLocal?.logo
            }?timestamp=${Date.now()}`,
            instituteName: instituteDetailsLocal?.instituteName,
          })
        );
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    const instituteFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const userFromLocalStorage = authUser;

    if (instituteFromLocalStorage && userFromLocalStorage) {
      setLogoDetails({
        ...logoDetails,
        instituteId:
          instituteFromLocalStorage.id && instituteFromLocalStorage.id,
        userId:
          userFromLocalStorage.institute &&
          userFromLocalStorage.institute[0].id,
      });

      setBannerDetails({
        ...bannerDetails,
        instituteId:
          instituteFromLocalStorage.id && instituteFromLocalStorage.id,
        userId:
          userFromLocalStorage.institute &&
          userFromLocalStorage.institute[0].id,
      });
    }
  }, [setLogoDetails, setBannerDetails]);

  React.useEffect(() => {
    if (instituteDetailsLocal?.logo) {
      setImage(instituteDetailsLocal.logo);
    }
  }, [instituteDetailsLocal.logo, setImage]);

  React.useEffect(() => {
    if (
      activeInstitute !== null &&
      activeInstitute?.profileDetailsCompleted === false
    )
      setInstituteDetails({
        ...instituteDetailsLocal,
        instituteName: activeInstitute?.instituteName,
        website: activeInstitute?.website,
        instituteEmail: activeInstitute?.instituteEmail,
        id: activeInstitute?.id,
      });
  }, []);

  React.useEffect(() => {
    if (activeInstitute.profileDetailsCompleted) {
      setInstituteDetails({
        ...instituteDetailsLocal,
        isProfileCompleted: activeInstitute.isProfileCompleted,
        subdomain: activeInstitute.subdomain,
        logo: activeInstitute.logo,
        status: activeInstitute.status,
        id: activeInstitute.id,
        instituteName: activeInstitute.instituteName,
        website: activeInstitute.website,
        instituteEmail: activeInstitute.instituteEmail,
        instituteContact: !activeInstitute?.contactCountryCode
          ? `91${activeInstitute.instituteContact}`
          : activeInstitute.instituteContact,
        landmark: activeInstitute.landmark,
        pin: activeInstitute.pin,
        state: activeInstitute.state,
        city: activeInstitute.city,
        buildingNumber: activeInstitute.buildingNumber,
        locality: activeInstitute.locality,
        isBranch: activeInstitute.branchCreated === false ? true : false,
        // socialMediaHandles: userFromLocalStorage?.socialMediaHandles || [],
        socialMediaHandles: displaySocialMedia,
        about: activeInstitute?.about || "",
        contactCountryCode: activeInstitute?.contactCountryCode || "91",
      });
    }
  }, [setInstituteDetails]);

  const imgUrl = useSelector(
    (state: any) =>
      state.fileUpload.imageUrl &&
      fileUploadSuccess &&
      state.fileUpload.imageUrl
  );

  React.useEffect(() => {
    if (fileUploadSuccess) {
      if (imgUrl.fileType === "INSTITUTE_PROFILE_IMAGE") {
        setInstituteDetails({
          ...instituteDetailsLocal,
          logo: `${imgUrl.location}?timestamp=${Date.now()}`,
        });
      }
      if (imgUrl.fileType === "INSTITUTE_COVER_IMAGE") {
        setInstituteDetails({
          ...instituteDetailsLocal,
          banner: imgUrl.location,
        });
      }
    }
  }, [fileUploadSuccess, setInstituteDetails]);

  React.useEffect(() => {
    if (error && typeof error === "string" && error !== "false") {
      setShowErrMsz(false);
      setErrorType("server");
    }
  }, [error]);

  React.useEffect(() => {
    if (instituteUpdateSuccess !== true && instituteUpdateSuccess !== false) {
      if (activeInstitute) {
        // UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
        getInstituteById(activeInstitute?.id);
      }
      dispatch(resetFileUploadState());
      dispatch(resetInstitiuteStatuses());
      if (activeInstitute && activeInstitute?.isProfileCompleted !== true) {
        if (isBranch === true) {
          navigate("/app/manage/Courses");
          dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
        } else if (isBranch === false) {
          navigate("/app/manage/CreateBranch");
        }
      } else {
        navigate(`/app/manage/InstituteProfile`);
      }
    }
  }, [
    instituteUpdateSuccess,
    navigate,
    UpdateInstituteInLocal,
    instituteDetailsLocal,
  ]);

  React.useEffect(() => {
    if (instituteDetails === null && user) {
      getInstituteById(user.institute[0].instituteId);
    }
    if (instituteDetails != null) {
      setInstituteDetails({
        ...instituteDetails,
        about: instituteDetails?.about || "",
        socialMediaHandles: instituteDetails?.socialMediaHandles || [],
        instituteContact: !instituteDetails?.contactCountryCode
          ? `91${instituteDetails.instituteContact}`
          : instituteDetails.instituteContact,
      });
    }
  }, [user, instituteDetails]);

  const areObjectsEqual = (obj1: any, obj2: any) => {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  };

  const cancelButtonClick = () => {
    const isSame = areObjectsEqual(instituteDetailsLocal, instituteDetails);
    if (isSame) {
      navigate(`/app/manage/InstituteProfile`);
    } else {
      setOpenModal(true);
    }
  };

  const noButtonClick = () => {
    setOpenModal(false);
  };

  const yesButtonClick = () => {
    setOpenModal(false);
    navigate(`/app/manage/InstituteProfile`);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        overflowY: "auto",
        maxHeight: "100%",
      }}
    >
      <Box>
        {instituteByIdLoading ? (
          <InstituteSkeleton />
        ) : (
          <div>
            <Box
              sx={{
                marginBottom: "20px",
                borderBottom: "1px solid #EDEDED",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "32px",
              }}
              id="Institute Profile"
            >
              <p
                style={{
                  margin: "0px 0px 5px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  color: "#142C44",
                }}
              >
                Institute Profile
              </p>
            </Box>

            <div>
              <p
                style={{
                  color: "#142C44",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "10px 0px",
                }}
              >
                {INSTITUTE_LOG}
              </p>
            </div>

            <Previews
              setImage={setImage}
              handleImageUpload={handleImageUpload}
              imgUrl={image}
            />
            <Box sx={{ mt: 4 }}>
              <Grid
                container
                spacing={{ lg: 2, md: 2, xs: 1, sm: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={6}>
                  <NewFormTextInput
                    onChange={handleChange}
                    required
                    value={instituteDetailsLocal.instituteName}
                    id="instituteName"
                    name="institute"
                    label="Name"
                    variant="standard"
                    fullWidth
                    require={true}
                    error={!instituteName}
                    setValidStatus={setValidateInstituteName}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    inputProps={{ maxLength: 1000 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NewFormTextInput
                    onChange={handleChange}
                    value={instituteDetailsLocal.website}
                    id={"website"}
                    label="Website"
                    variant="standard"
                    fullWidth
                    inputProps={{ maxLength: 1000 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NewFormTextInput
                    disabled={true}
                    validate={true}
                    label="Email Address"
                    setValidStatus={setValidateStatusEmail}
                    type={"email"}
                    id="instituteEmail"
                    value={instituteDetailsLocal.instituteEmail}
                    onChange={handleChange}
                    error={!validStateEmail}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    autoComplete="true"
                    require={true}
                    inputProps={{
                      style: {
                        backgroundColor: "#F2F2F2",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontSize: "12px",
                      color: "#142C44",
                      fontWeight: "500",
                      marginBottom: "5px",
                      fontFamily: "Poppins",
                      display: "flex",
                    }}
                  >
                    Phone Number
                    <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                  </InputLabel>
                  <CountryCodePhoneInput
                    value={instituteDetailsLocal.instituteContact}
                    onChange={(event: any, country: any) => {
                      if (event !== "") {
                        setValidStateContact(
                          isValidPhoneNumber(
                            event.replace(country.dialCode, ""),
                            country.countryCode.toUpperCase()
                          )
                        );
                      }

                      setContactCountryCode(country.dialCode);
                      setInstituteDetails({
                        ...instituteDetailsLocal,
                        instituteContact: event,
                        contactCountryCode: country.dialCode,
                      });
                    }}
                    error={validStateContact}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{}}>
              <p
                style={{
                  color: "#142C44",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                }}
              >
                Institute Location
              </p>
              <Box sx={{ my: 3 }}>
                <MapAutocomplete
                  handleGeoChange={handleGeoChange}
                  latitude={instituteDetailsLocal.latitude}
                  longitude={instituteDetailsLocal.longitude}
                  placeholder={"Find your institute"}
                />
              </Box>
              <form onSubmit={handleSubmit}>
                <Box style={{ marginBottom: "50px" }}>
                  <Grid
                    container
                    spacing={{ lg: 2, md: 2, xs: 1, sm: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item xs={6}>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusBuilding}
                        label="Building No / Block No"
                        type={"text"}
                        error={!validStateBuilding}
                        id={"buildingNumber"}
                        value={instituteDetailsLocal.buildingNumber}
                        onChange={handleChange}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        maxRows={1}
                        inputProps={{ maxLength: 50 }}
                        require={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusLocality}
                        label="Locality"
                        type={"text"}
                        id={"locality"}
                        value={instituteDetailsLocal.locality}
                        onChange={handleChange}
                        error={
                          !instituteDetailsLocal.locality && !validStateLocality
                        }
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        maxRows={1}
                        inputProps={{
                          maxLength: 50,
                        }}
                        require={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "6px",
                        }}
                      >
                        Pin
                      </InputLabel>
                      <TextField
                        style={{ width: "100%" }}
                        size="small"
                        id={"pin"}
                        value={instituteDetailsLocal.pin}
                        inputProps={{
                          maxLength: 6,
                        }}
                        // onChange={handleChange}
                        onChange={handlePinChange}
                        fullWidth
                        variant="outlined"
                        error={!pinError}
                        type="text"
                        helperText={!pinError ? "Invalid PIN code" : ""}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NewFormTextInput
                        onChange={handleChange}
                        id={"state"}
                        label="State"
                        variant="standard"
                        fullWidth
                        value={instituteDetailsLocal.state}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NewFormTextInput
                        onChange={handleChange}
                        id={"city"}
                        label="City"
                        variant="standard"
                        fullWidth
                        value={instituteDetailsLocal.city}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NewFormTextInput
                        onChange={handleChange}
                        id={"landmark"}
                        label="Landmark"
                        fullWidth
                        value={instituteDetailsLocal.landmark}
                        maxRows={1}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    {activeInstitute && !activeInstitute?.branchCreated && (
                      <FormControlLabel
                        sx={{
                          ".css-1f2kvjf-MuiFormControlLabel-root": {
                            margin: "0px ",
                          },
                        }}
                        control={
                          <Switch
                            value={instituteDetailsLocal?.isBranch}
                            id="isBranch"
                            onChange={(event) => {
                              setInstituteDetails({
                                ...instituteDetailsLocal,
                                isBranch: event.target.checked,
                              });
                              setIsBranch(event.target.checked);
                            }}
                          />
                        }
                        label="Do You Conduct Classes Here?"
                        labelPlacement="start"
                      />
                    )}
                  </Box>
                  <Box style={{ textAlign: "end" }} sx={{ mt: 1 }}>
                    {activeInstitute?.isProfileCompleted && (
                      <Button
                        variant="outlined"
                        type="reset"
                        sx={{
                          marginRight: "8px",
                          color: "#BFBFBF",
                          borderColor: "#BFBFBF",
                        }}
                        onClick={() => cancelButtonClick()}
                      >
                        Cancel
                      </Button>
                    )}

                    <Button
                      sx={{
                        width: "175px",
                        padding: "8px 20px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      {instituteUpdateloading ? (
                        <CircularProgress size={25} color={"inherit"} />
                      ) : (
                        SAVECHANGES_BTN_TEX
                      )}
                    </Button>
                  </Box>
                </Box>
              </form>
              {openModal && (
                <CancelModal
                  open={openModal}
                  onClose={handleModalClose}
                  noButtonClick={noButtonClick}
                  yesButtonClick={yesButtonClick}
                />
              )}
            </Box>
          </div>
        )}
      </Box>
    </Container>
  );
}
