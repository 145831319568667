import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  DownloadReport,
  GetAttendanceReportDataForSingleBatch,
  GetBatchListForAttendanceReportCreation,
  SaveReport,
} from "./reports.request";

import { AttendanceReportCreationInitialStateDTO } from "./types";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: AttendanceReportCreationInitialStateDTO = {
  batchListForAttendanceLoading: false,
  batchListForAttendanceSuccess: false,
  batchListForAttendanceError: false,
  batchListForAttendance: null,
  getAttendanceReportDataForSingleBatchLoading: false,
  getAttendanceReportDataForSingleBatchSuccess: false,
  getAttendanceReportDataForSingleBatchError: false,
  getAttendanceReportDataForSingleBatch: null,
  saveAttendanceReportLoading: false,
  saveAttendanceReportSuccess: false,
  saveAttendanceReportError: false,
  downloadAttendanceReportLoading: false,
  downloadAttendanceReportSuccess: false,
  downloadAttendanceReportError: false,
  downloadAttendanceReportData: null,
};

export const getBatchListForAttendance = createAsyncThunk(
  "reports/getBatchListForAttendance",
  async (body: any, thunkAPI: any) => {
    return GetBatchListForAttendanceReportCreation(body)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getAttendanceReportDataForSingleBatch = createAsyncThunk(
  "reports/getAttendanceReportDataForSingleBatch",
  async (body: any, thunkAPI: any) => {
    return GetAttendanceReportDataForSingleBatch(body)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const saveReport = createAsyncThunk(
  "reports/saveReport",
  async (body: any, thunkAPI: any) => {
    return SaveReport(body)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getDownloadedReport = createAsyncThunk(
  "reports/getDownloadedReport",
  async (body: any, thunkAPI: any) => {
    return DownloadReport(body)
      .then((response: any) => {
        thunkAPI.dispatch(
          setToastNotification({
            message: "Congratulations! Report is successfully downloaded.",
            type: "success",
            snackOpen: true,
          })
        );
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const attendanceReportCreationSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: () => initialState,
    resetDownloadAttendanceReportSuccess: (state) => {
      state.downloadAttendanceReportSuccess = false;
    },
    resetBatchListAndSingleBatchData: (state) => {
      state.batchListForAttendance = null;
      state.getAttendanceReportDataForSingleBatch = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBatchListForAttendance.pending, (state) => {
      state.batchListForAttendanceLoading = true;
      state.batchListForAttendanceSuccess = false;
      state.batchListForAttendanceError = false;
    });
    builder.addCase(
      getBatchListForAttendance.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.batchListForAttendanceLoading = false;
        state.batchListForAttendanceSuccess = true;
        state.batchListForAttendanceError = false;
        state.batchListForAttendance = action?.payload;
      }
    );
    builder.addCase(
      getBatchListForAttendance.rejected,
      (state, action: AnyAction) => {
        state.batchListForAttendanceLoading = false;
        state.batchListForAttendanceSuccess = false;
        state.batchListForAttendanceError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getAttendanceReportDataForSingleBatch.pending, (state) => {
      state.getAttendanceReportDataForSingleBatchLoading = true;
      state.getAttendanceReportDataForSingleBatchSuccess = false;
      state.getAttendanceReportDataForSingleBatchError = false;
    });
    builder.addCase(
      getAttendanceReportDataForSingleBatch.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAttendanceReportDataForSingleBatchLoading = false;
        state.getAttendanceReportDataForSingleBatchSuccess = true;
        state.getAttendanceReportDataForSingleBatchError = false;
        state.getAttendanceReportDataForSingleBatch = action?.payload;
      }
    );
    builder.addCase(
      getAttendanceReportDataForSingleBatch.rejected,
      (state, action: AnyAction) => {
        state.getAttendanceReportDataForSingleBatchLoading = false;
        state.getAttendanceReportDataForSingleBatchSuccess = false;
        state.getAttendanceReportDataForSingleBatchError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(saveReport.pending, (state) => {
      state.saveAttendanceReportLoading = true;
      state.saveAttendanceReportSuccess = false;
      state.saveAttendanceReportError = false;
    });
    builder.addCase(
      saveReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.saveAttendanceReportLoading = false;
        state.saveAttendanceReportSuccess = true;
        state.saveAttendanceReportError = false;
      }
    );
    builder.addCase(saveReport.rejected, (state, action: AnyAction) => {
      state.saveAttendanceReportLoading = false;
      state.saveAttendanceReportSuccess = false;
      state.saveAttendanceReportError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getDownloadedReport.pending, (state) => {
      state.downloadAttendanceReportLoading = true;
      state.downloadAttendanceReportSuccess = false;
      state.downloadAttendanceReportError = false;
    });
    builder.addCase(
      getDownloadedReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.downloadAttendanceReportLoading = false;
        state.downloadAttendanceReportSuccess = true;
        state.downloadAttendanceReportError = false;
        state.downloadAttendanceReportData = action?.payload;
      }
    );
    builder.addCase(
      getDownloadedReport.rejected,
      (state, action: AnyAction) => {
        state.downloadAttendanceReportLoading = false;
        state.downloadAttendanceReportSuccess = false;
        state.downloadAttendanceReportError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default attendanceReportCreationSlice.reducer;
export const {
  reset,
  resetDownloadAttendanceReportSuccess,
  resetBatchListAndSingleBatchData,
} = attendanceReportCreationSlice.actions;
