import React, { useEffect, useState } from "react";
import styles from "./SyncedDataTab.module.css";
import { Box, Tab, Tabs } from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import ImportTab from "./ContactTabs/ImportTab";
import WebformTab from "./ContactTabs/WebformTab";
import ZapierTab from "./ContactTabs/ZapierTab";
import { useDispatch } from "react-redux";
import { resetSingleFormData } from "../../Webform/service/webformSlice";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type webformsData = {
  instituteId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};
type formResponseData = {
  instituteId: string;
  formId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

function Contact(props: any) {
  const {
    GetAllWebform,
    GetWebformForInstitute,
    GetFormResponse,
    BulkLeadCreation,
    DeleteWebformResponse,
    contactList,
    contactListLoading,
    DeleteContactList,
    deleteContactLoading,
    deleteContactSuccess,
    AddEnquiryForContact,
    addedEnquiryContactError,
    GetContactLists,
    deleteContactError,

    CreateEnquiry,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    CreateWebformSuccess,
    GetAllWebformLoading,
    getAllFormByInstituteData,
    getFormByInstituteData,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    GetMailEntriesByInstitute,
    GetLeadListByInstituteAndContact,
    CreateSource,
    getStudentContactByIdData,
    GetStudentContactById,
    getFormResponseSuccess,
    getFormResponseData,
    bulkLeadCreationSuccess,
    deleteWebformResponseSuccess,
    bulkLeadCreationError,
    deleteWebformResponseError,
    AddEnquiryForZapier,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const [value, setValue] = useState<number>(0);
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabSelect = (value: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("ContactTab", value);
    if (value === "import" || value === "zapier") {
      queryParams.delete("webformId");
      queryParams.delete("filteredCourses");
      queryParams.delete("filteredImportedBy");
      queryParams.delete("formType");
      queryParams.delete("formBranch");
    }
    if (value !== "zapier") {
      queryParams.delete("zapierSearchTerm");
    }
    if (value !== "import") {
      queryParams.delete("importSearchTerm");
    }
    if (value !== "webform") {
      queryParams.delete("webformSearchTerm");
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  const selectContactListBySource = (enquirySource: string) => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 10;
    const lastEvaluatedSortKey = "";

    if (userFromLocalStorage) {
      if (enquirySource === "Excel Import") {
        GetContactLists(
          userFromLocalStorage.instituteId,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          enquirySource
        );
        dispatch(resetSingleFormData());
      } else if (enquirySource === "webform") {
        const data: webformsData = {
          instituteId: userFromLocalStorage?.instituteId,
          limit: 8,
          branchId: "ALL",
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        };
        GetAllWebform(data);
      } else if (enquirySource === "zapier") {
        GetContactLists(
          userFromLocalStorage.instituteId,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          "Zapier"
        );
        dispatch(resetSingleFormData());
      }
    }
  };

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const contactTab = urlSearchParams.get("ContactTab");
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    if (userFromLocalStorage) {
      const lastEvaluatedKey = "";
      const limit = 10;
      const lastEvaluatedSortKey = "";
      if (contactTab === "webform") {
        let branchId: string;
        if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
          branchId = webformBranch;
        } else {
          branchId = "ALL";
        }
        const data: webformsData = {
          instituteId: userFromLocalStorage?.instituteId,
          limit: 8,
          branchId: branchId,
          lastEvaluatedKey: "",
          lastEvaluatedSortKey: "",
        };
        GetAllWebform(data);

        setValue(1);
      } else if (contactTab === "zapier") {
        GetContactLists(
          userFromLocalStorage?.instituteId,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          "Zapier"
        );
        setValue(2);
        dispatch(resetSingleFormData());
      } else {
        GetContactLists(
          userFromLocalStorage?.instituteId,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          "Excel Import"
        );
        setValue(0);
        dispatch(resetSingleFormData());
      }
    }
  }, [setValue]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const contactTab = urlSearchParams.get("ContactTab");
    const webformId = urlSearchParams.get("webformId");
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;
    if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else if (!hasAllBranchAccess) {
      branchId = authUser?.institute?.[0]?.branchId;
    } else {
      branchId = "";
    }
    const data: formResponseData = {
      instituteId: userFromLocalStorage?.instituteId,
      formId: webformId ? webformId : "",
      limit: 15,
      branchId: "",
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };

    if (userFromLocalStorage && webformId && contactTab === "webform") {
      GetWebformForInstitute(userFromLocalStorage?.instituteId, webformId);
      GetFormResponse(data);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px 0",
        overflow: "hidden",
        height: "calc(100vh - 89px)",
      }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="billing tabs"
        sx={{
          mt: 1,
          mb: 1,
          ml: 2,
          backgroundColor: "white",
          borderBottom: "1px solid #E9E9E9",
          "& .MuiTabs-flexContainer": {
            gap: "24px",
          },
          "& .MuiTabs-indicator": {
            height: "1px",
          },
        }}
      >
        <Tab
          label="Import"
          {...a11yProps(0)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",

            "& .Mui-selected": {
              color: "#11C89B",
            },
          }}
          onClick={() => {
            handleTabSelect("import");
            selectContactListBySource("Excel Import");
          }}
        />
        <Tab
          label="Webform"
          {...a11yProps(1)}
          sx={{
            textTransform: "capitalize",
            padding: "0",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("webform");
            selectContactListBySource("webform");
          }}
        />
        <Tab
          label="Zapier"
          {...a11yProps(2)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("zapier");
            selectContactListBySource("zapier");
          }}
        />
      </Tabs>

      <div
        style={{
          maxHeight: "calc(100vh - 180px)",
          overflowY: "hidden",
        }}
      >
        {value === 0 && (
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
              // maxHeight: "calc(100vh - 162px)",
              pr: 2,
              pl: 2,
            }}
          >
            <ImportTab
              contactList={contactList}
              contactListLoading={contactListLoading}
              GetContactLists={GetContactLists}
              DeleteContactList={DeleteContactList}
              deleteContactLoading={deleteContactLoading}
              deleteContactSuccess={deleteContactSuccess}
              AddEnquiryForContact={AddEnquiryForContact}
              page="CONTACTS"
              addedEnquiryContactError={addedEnquiryContactError}
            />
          </Box>
        )}
        {value === 1 && (
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
              maxHeight: "calc(100vh - 162px)",
              pr: 2,
              pl: 2,
            }}
          >
            <WebformTab
              GetWebformForInstitute={GetWebformForInstitute}
              GetFormResponse={GetFormResponse}
              BulkLeadCreation={BulkLeadCreation}
              DeleteWebformResponse={DeleteWebformResponse}
              page={"CONTACTS"}
              GetAllWebform={GetAllWebform}
              CreateEnquiry={CreateEnquiry}
            />
          </Box>
        )}
        {value === 2 && (
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
              // maxHeight: "calc(100vh - 165px)",
              pr: 2,
              pl: 2,
            }}
          >
            <ZapierTab
              contactList={contactList}
              contactListLoading={contactListLoading}
              GetContactLists={GetContactLists}
              DeleteContactList={DeleteContactList}
              deleteContactLoading={deleteContactLoading}
              deleteContactSuccess={deleteContactSuccess}
              AddEnquiryForContact={AddEnquiryForContact}
              page={"CONTACTS"}
              addedEnquiryContactError={addedEnquiryContactError}
              AddEnquiryForZapier={AddEnquiryForZapier}
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Contact;
