import React from "react";
import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function WidgetPopup({
  setOpenWidgtPopup,
  formName,
  setWidgetData,
  widgetData,
  getCodeContent,
  handleSaveChanges,
}: any) {
  const handleDropdownClick = () => {
    document.getElementById("colorPickerInput")?.click();
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#667085",
            fontSize: "13px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <KeyboardBackspaceIcon onClick={() => setOpenWidgtPopup(false)} />
        </div>
        <CloseIcon
          onClick={() => setOpenWidgtPopup(false)}
          style={{ cursor: "pointer", color: "#667085" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{ color: "#142C44", fontSize: "18px", fontWeight: "600" }}
        >
          Popup Widget
        </Typography>
        <Typography
          sx={{ color: "#667085", fontSize: "13px", fontWeight: "500" }}
        >
          {formName}
        </Typography>
      </Box>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <div
          style={{
            color: "#323B4B",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          Popup Widget Settings
        </div>
        <div style={{ color: "#667085", fontSize: "14px", fontWeight: "400" }}>
          Customize the display of the floating button that will launch your
          Webform.
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              color: "#667085",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Button Text
          </div>
          <div>
            <NewFormTextInput
              validate={false}
              disabled={false}
              value={widgetData?.buttonText}
              onChange={(event: any) => {
                setWidgetData({
                  ...widgetData,
                  buttonText: event?.target.value,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              color: "#667085",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Button Background Color
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #D6D6D6",
            }}
          >
            <input
              type="color"
              id="buttonBgColorPickerInput"
              name="colorPicker"
              style={{
                border: "none",
                outline: "none",
              }}
              value={widgetData?.buttonBgColor}
              onChange={(event: any) => {
                setWidgetData({
                  ...widgetData,
                  buttonBgColor: event?.target.value,
                });
              }}
            />
            <label
              htmlFor="buttonBgColorPickerInput"
              style={{ cursor: "pointer", color: "#BDBFC3", marginTop: "5px" }}
              onClick={handleDropdownClick}
            >
              <ArrowDropDownIcon />
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              color: "#667085",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Button Text Color
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #D6D6D6",
            }}
          >
            <input
              type="color"
              id="buttonTextColorPickerInput"
              name="colorPicker"
              style={{
                border: "none",
                outline: "none",
              }}
              value={widgetData?.buttonTextColor}
              onChange={(event: any) => {
                setWidgetData({
                  ...widgetData,
                  buttonTextColor: event?.target.value,
                });
              }}
            />
            <label
              htmlFor="buttonTextColorPickerInput"
              style={{ cursor: "pointer", color: "#BDBFC3", marginTop: "5px" }}
              onClick={handleDropdownClick}
            >
              <ArrowDropDownIcon />
            </label>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <div
          style={{
            color: "#323B4B",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          Webform Settings
        </div>
        <div style={{ color: "#667085", fontSize: "14px", fontWeight: "400" }}>
          Customize the look of the Webform to be displayed on your site.
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              color: "#667085",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Background Color
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #D6D6D6",
            }}
          >
            <input
              type="color"
              id="bgColorPickerInput"
              name="colorPicker"
              style={{
                border: "none",
                outline: "none",
              }}
              value={widgetData?.bgColor}
              onChange={(event: any) => {
                setWidgetData({
                  ...widgetData,
                  bgColor: event?.target.value,
                });
              }}
            />
            <label
              htmlFor="bgColorPickerInput"
              style={{ cursor: "pointer", color: "#BDBFC3", marginTop: "5px" }}
              onClick={handleDropdownClick}
            >
              <ArrowDropDownIcon />
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              color: "#667085",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            Text Color
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #D6D6D6",
            }}
          >
            <input
              type="color"
              id="textColorPickerInput"
              name="colorPicker"
              style={{
                border: "none",
                outline: "none",
              }}
              value={widgetData?.textColor}
              onChange={(event: any) => {
                setWidgetData({
                  ...widgetData,
                  textColor: event?.target.value,
                });
              }}
            />
            <label
              htmlFor="textColorPickerInput"
              style={{ cursor: "pointer", color: "#BDBFC3", marginTop: "5px" }}
              onClick={handleDropdownClick}
            >
              <ArrowDropDownIcon />
            </label>
          </div>
        </div>
      </div>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 0px",
        }}
      >
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Box>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <div
          style={{
            color: "#323B4B",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          Embed Code
        </div>
        <div
          style={{
            color: "#667085",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Place this code in your HTML on the pages you want your floating
          button to appear. When a visitor clicks the button, your Webform
          widget will load as a popup.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            overflow: "auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            padding: "10px",
            fontFamily: "monospace",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            margin: "10px 0",
            fontSize: "12px",
            height: "100px",
          }}
        >
          <pre>{getCodeContent()}</pre>
        </div>
      </div>
    </div>
  );
}

export default WidgetPopup;
