import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  InputLabel,
} from "@mui/material";
import styles from "../components/Managebranches.module.css";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { SAVECHANGES_BTN_TEX } from "../container/BranchString";
import MapAutocomplete from "../../../../../components/googleMap/MapAutocomplete";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { reset } from "../service/Branchslice";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import BranchSkeleton from "../../../../../components/Skeletons/BranchSkeleton";
import { getUserById } from "../../UserDetails/service/userDetailsSlice";
import { useDispatch } from "react-redux";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";
import CurrencyDropDown from "../../../../../components/CurrencyDropDown/CurrencyDropDown";

// Style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 490,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

// Component
export default function BranchEditor(props: any) {
  const {
    CreateBranch,
    edit,
    add,
    GetBranchById,
    branchById,
    UpdateBranch,
    success,
    error,
    UpdateInstituteInLocal,
    setIsActive,
    backBtn,
    branchByIdLoading,
  } = props;
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [branchDetails, setBranchDetails] = React.useState({
    instituteId: "",
    branchName: "",
    buildingNumber: "",
    locality: "",
    pin: "",
    state: "",
    city: "",
    landmark: "",
    latitude: "",
    longitude: "",
    contactNumber: "",
    contactCountryCode: "91",
    email: "",
    isFirstBranch: true,
    status: "ACTIVE",
    id: "",
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    currency: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const pinRegex = /^\d{6}$/;
  const [pinError, setPinError] = React.useState<boolean>(false);

  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
  const [oldData, setOldData] = React.useState<any>({
    instituteId: "",
    branchName: "",
    buildingNumber: "",
    locality: "",
    pin: "",
    state: "",
    city: "",
    landmark: "",
    latitude: "",
    longitude: "",
    contactNumber: "91",
    contactCountryCode: "",
    email: "",
    isFirstBranch: true,
    status: "ACTIVE",
    id: "",
    currency: "",
  });
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePhone, setValidStatePhone] = React.useState<boolean>(true);
  const [validStateLocality, setValidStateLocality] =
    React.useState<boolean>(true);
  const [validStateBuildingNumber, setValidStateBuildingNumber] =
    React.useState<boolean>(true);
  const [validStateBranchName, setValidStateBranchName] =
    React.useState<boolean>(true);

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const setValidateStatusLocality = React.useCallback((val: boolean) => {
    setValidStateLocality(val);
  }, []);
  const setValidateStatusBuildingNumber = React.useCallback((val: boolean) => {
    setValidStateBuildingNumber(val);
  }, []);
  const setValidateStatusBranchName = React.useCallback((val: boolean) => {
    setValidStateBranchName(val);
  }, []);
  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();

  React.useEffect(() => {
    // const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    if (activeInstitute?.isProfileCompleted === false && activeInstitute?.id) {
      GetInstituteByIdRequest(activeInstitute?.id)
        ?.then((res: any) => {
          setValidatingFirstTimeBranchCreation(res?.data?.data);
        })
        .catch((err) => {});
    } else {
      setValidatingFirstTimeBranchCreation(activeInstitute);
    }
  }, []);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (userFromLocalStorage) {
      setBranchDetails({
        ...branchDetails,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
        isFirstBranch:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.branchCreated === true
            ? false
            : true,
      });
      setOldData({
        ...oldData,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
        isFirstBranch:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.branchCreated === true
            ? false
            : true,
      });
    }
  }, [validatingFirstTimeBranchCreation]);

  //Shedule
  const handleGeoChange = React.useCallback(
    (geoLocation: any) => {
      let templocality: any, tempPin: any, tempCity: any, tempStat: any;

      geoLocation.address_components.map((ele: any) => {
        if (ele.types[0] === "locality") {
          templocality = ele?.long_name;
        } else if (ele.types[0] === "postal_code") {
          tempPin = ele?.long_name;
          fetchPincodeData(ele?.long_name);
        } else if (ele.types[0] === "administrative_area_level_3") {
          tempCity = ele?.long_name;
        } else if (ele.types[0] === "administrative_area_level_1") {
          tempStat = ele?.long_name;
        }
      });

      setBranchDetails({
        ...branchDetails,
        latitude:
          geoLocation !== null ? `${geoLocation.geometry.location.lat()}` : "",
        longitude:
          geoLocation !== null ? `${geoLocation.geometry.location.lng()}` : "",
        locality: templocality,
        city: tempCity === templocality ? tempCity : templocality,
        state: tempStat,
        pin: tempPin,
      });
    },
    [branchDetails]
  );

  // Pincode related
  interface PincodeData {
    Message: string;
    Status: string;
    PostOffice: { Name: string; District: string; State: string }[];
  }
  const [pincodeData, setPincodeData] = useState<PincodeData | null>(null);
  const fetchPincodeData = async (pin: string) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pin}`
      );
      if (response.data && response.data.length > 0) {
        const pinData = response.data[0];
        setPincodeData(pinData);

        const firstPostOffice = pinData?.PostOffice?.[0];
        if (firstPostOffice !== null || firstPostOffice !== undefined) {
          setBranchDetails((prevDetails) => ({
            ...prevDetails,
            city: firstPostOffice?.District,
            state: firstPostOffice?.State,
            locality: firstPostOffice?.Name,
          }));
        } else {
          setBranchDetails((prevDetails) => ({
            ...prevDetails,
            city: "",
            state: "",
            locality: "",
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };
  const handlePinChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowErrMsz(true);
      setPinError(false);
      setErrorType("client");

      const { id, value } = event.target;
      const lowercaseValue =
        id === "email" ? value.toLowerCase().trimStart() : value.trimStart();

      setBranchDetails((prevDetails) => ({
        ...prevDetails,
        [id]: lowercaseValue,
      }));

      if (event.target.id === "pin") {
        if (!pinRegex.test(event.target.value)) {
          setPinError(true);
        } else {
          setPinError(false);
          const pin = event.target.value;

          if (pin?.length >= 6) {
            fetchPincodeData(pin);
          }
        }
      }
    },
    [pinRegex]
  );

  // Handle change
  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
      }
      setErrorType("client");
      const { id, value } = event.target;
      const lowercaseValue =
        id === "email" ? value.toLowerCase().trimStart() : value.trimStart();

      setBranchDetails({
        ...branchDetails,
        [id]: lowercaseValue,
      });
    },
    [pinRegex, branchDetails, errorType]
  );
  const handleUpdateCurrency = (currency: string = "₹") => {
    setBranchDetails({ ...branchDetails, currency });
  };
  useEffect(() => {
    if (branchDetails?.currency == "")
      setBranchDetails({ ...branchDetails, currency: "₹" });
  }, [branchDetails.currency]);
  // Handle submit
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      let formIsValid = true;
      if (!branchDetails.branchName) {
        setValidStateBranchName(false);
        formIsValid = false;
      }
      if (!branchDetails.buildingNumber) {
        setValidStateBuildingNumber(false);
        formIsValid = false;
      }
      if (!branchDetails.locality) {
        setValidStateLocality(false);
        formIsValid = false;
      }
      if (!branchDetails.email) {
        setValidStateEmail(false);
        formIsValid = false;
      }
      if (!branchDetails.contactNumber) {
        setValidStatePhone(false);
        formIsValid = false;
      }

      if (
        formIsValid &&
        validStateEmail &&
        validStatePhone &&
        validStateBranchName &&
        validStateBuildingNumber &&
        validStateLocality
      ) {
        add
          ? CreateBranch({
              ...branchDetails,
              instituteId: activeInstitute?.id,
              createdByName: authUser?.firstName + " " + authUser?.lastName,
              createdById: authUser?.institute[0]?.id,
            })
          : UpdateBranch({
              ...branchDetails,
              instituteId: activeInstitute?.id,
              createdByName: branchById?.createdByName,
              createdById: branchById?.createdById,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
            });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleUnload = () => {
      setOpen(true);
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  // Get the batchid from the url if edit is true
  React.useEffect(() => {
    if (edit) {
      const pathname = window.location.pathname;
      const branchId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetBranchById(branchId);
      setFormDisabled(true);
    }
  }, [edit, GetBranchById, window.location]);

  const [editVal, setEditVal] = useState<any>(null);

  React.useEffect(() => {
    if (!add && branchById) {
      setBranchDetails(branchById);
      setEditVal({
        position: {
          lat: parseFloat(branchById.latitude),
          lng: parseFloat(branchById.longitude),
        },
      });
    }
  }, [branchById, setBranchDetails, add]);

  React.useEffect(() => {
    if (edit && branchById) {
      setBranchDetails({
        ...branchById,
        contactNumber: `${branchById?.contactCountryCode || "91"}${
          branchById?.contactCountryCode
            ? branchById?.contactNumber.replace(
                branchById?.contactCountryCode,
                ""
              )
            : branchById?.contactNumber.startsWith("91")
            ? branchById?.contactNumber.replace("91", "")
            : branchById?.contactNumber
        }`,
      });
      setOldData({
        ...branchById,
        contactNumber: `${branchById?.contactCountryCode || "91"}${
          branchById?.contactCountryCode
            ? branchById?.contactNumber.replace(
                branchById?.contactCountryCode,
                ""
              )
            : branchById?.contactNumber.startsWith("91")
            ? branchById?.contactNumber.replace("91", "")
            : branchById?.contactNumber
        }`,
      });

      setEditVal({
        position: {
          lat: parseFloat(branchById.latitude),
          lng: parseFloat(branchById.longitude),
        },
      });
    } else {
    }
  }, [edit, branchById]);

  useEffect(() => {
    if (success !== true && success !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (activeInstitute?.id) {
        getInstituteById(activeInstitute?.id);
        // UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }
      if (window.location.pathname.includes("Billing")) {
        // If the URL contains "Billing"
        navigate("/app/manage/Billing/ManageSubscription");
      } else {
        if (branchDetails.isFirstBranch === true) {
          navigate("/app/manage/Courses");
          dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
        } else {
          if (!add) {
            navigate(
              `/app/manage/Branches?selectedBranch=${branchDetails?.id}`
            );
          } else {
            navigate(`/app/manage/Branches`);
          }
        }
      }
    }
  }, [success, navigate]);

  useEffect(() => {
    if (window.location.href.includes("CreateBranch")) {
      setIsActive(1);
    }
  }, [setIsActive, add, setFormDisabled]);

  useEffect(() => {
    const element = document.getElementById("branches");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  const handleModalClose = () => setOpenModal(false);

  const noButtonClick = () => {
    setOpenModal(false);
  };

  const yesButtonClick = () => {
    reset();
    setOpenModal(false);
    const pathname = window.location.pathname;
    const branchId = pathname.substring(pathname.lastIndexOf("/") + 1);
    add
      ? navigate(`/app/manage/Branches`)
      : navigate(`/app/manage/Branches?selectedBranch=${branchDetails?.id}`, {
          state: { branchId: branchId, menuItemNumber: 1 },
        });
  };

  const areObjectsEqual = (obj1: any, obj2: any) => {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  };

  const cancelButtonClick = () => {
    const isSame = areObjectsEqual(branchDetails, oldData);

    if (isSame) {
      const pathname = window.location.pathname;
      const branchId = pathname.substring(pathname.lastIndexOf("/") + 1);
      add
        ? navigate(`/app/manage/Branches`)
        : navigate(`/app/manage/Branches`, {
            state: { branchId: branchId, menuItemNumber: 1 },
          });
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      <Container
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <Box sx={{ paddingTop: "22px" }}>
          {backBtn ? (
            <button className={styles.PageBackBtn} onClick={() => navigate(-1)}>
              <ArrowBackIcon
                sx={{
                  marginRight: "8px",
                  fontSize: "15PX",
                  color: "#667085",
                }}
              />
              Back
            </button>
          ) : (
            <></>
          )}
          {edit && branchByIdLoading ? (
            <BranchSkeleton />
          ) : (
            <div>
              <div
                className={""}
                style={{
                  borderBottom: "1px solid #EDEDED",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                id="branches"
              >
                <p
                  className={""}
                  style={{
                    color: "#142C44",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    margin: "10px 0px",
                  }}
                >
                  {add ? "Add" : "Edit"} Branch
                </p>
              </div>

              <Box className={styles.contacDetailsGrid}>
                <p
                  style={{
                    color: "#142C44",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Institute Location
                </p>

                <form id="form" onSubmit={handleSubmit}>
                  <Grid
                    item
                    xs={12}
                    spacing={{ lg: 2, md: 2, xs: 1, sm: 1 }}
                    className={styles.mapGrid}
                  >
                    <MapAutocomplete
                      handleGeoChange={handleGeoChange}
                      latitude={
                        branchDetails.latitude !== null &&
                        branchDetails.latitude
                      }
                      longitude={
                        branchDetails.longitude !== null &&
                        branchDetails.longitude
                      }
                      add={add}
                      disabled={formDisabled}
                      placeholder={"Name of the place"}
                      editVal={editVal !== null && editVal}
                    />

                    <Box className={styles.googleMap}></Box>
                  </Grid>

                  <Box sx={{ my: 4 }}>
                    <Grid
                      container
                      spacing={{ lg: 3, md: 3, xs: 1, sm: 1 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={6}>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusBranchName}
                          label="Branch Name"
                          type="text"
                          id={"branchName"}
                          error={
                            !validStateBranchName ||
                            branchDetails.branchName.length > 50
                          }
                          value={branchDetails.branchName}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          autoComplete="true"
                          require={true}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusBuildingNumber}
                          label="Building No / Block No"
                          type="text"
                          id={"buildingNumber"}
                          error={!validStateBuildingNumber}
                          value={branchDetails.buildingNumber}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          autoComplete="true"
                          require={true}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusLocality}
                          label="Locality"
                          type="text"
                          id={"locality"}
                          error={!validStateLocality}
                          value={branchDetails.locality}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          autoComplete="true"
                          require={true}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel
                          sx={{
                            fontSize: "12px",
                            color: "#142C44",
                            fontWeight: "500",
                            marginBottom: "5px",
                            fontFamily: "Poppins",
                            display: "flex",
                          }}
                        >
                          Pin
                        </InputLabel>
                        <TextField
                          id={"pin"}
                          variant="outlined"
                          fullWidth
                          value={branchDetails.pin}
                          inputProps={{
                            maxLength: 6,
                          }}
                          error={pinError}
                          helperText={pinError ? "Invalid PIN code" : ""}
                          // onChange={handleChange}
                          onChange={handlePinChange}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          onChange={handleChange}
                          id={"city"}
                          label="City"
                          variant="standard"
                          fullWidth
                          value={branchDetails.city}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          onChange={handleChange}
                          id={"state"}
                          label="State"
                          variant="standard"
                          fullWidth
                          value={branchDetails.state}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          onChange={handleChange}
                          id={"landmark"}
                          label="Landmark"
                          fullWidth
                          value={branchDetails.landmark}
                          maxRows={1}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NewFormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusEmail}
                          label="Email Address"
                          type="email"
                          id={"email"}
                          error={!validStateEmail}
                          value={branchDetails.email}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          autoComplete="true"
                          require={true}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel
                          sx={{
                            fontSize: "12px",
                            color: "#142C44",
                            fontWeight: "500",
                            marginBottom: "5px",
                            fontFamily: "Poppins",
                            display: "flex",
                          }}
                        >
                          Phone Number
                          <p style={{ margin: "0px 4px", color: "#D32F2F" }}>
                            *
                          </p>
                        </InputLabel>
                        <CountryCodePhoneInput
                          value={branchDetails.contactNumber}
                          onChange={(event: any, country: any) => {
                            if (event !== "") {
                              setValidStatePhone(
                                isValidPhoneNumber(
                                  event.replace(country.dialCode, ""),
                                  country.countryCode.toUpperCase()
                                )
                              );
                            }

                            setBranchDetails({
                              ...branchDetails,
                              contactNumber: event,
                              contactCountryCode: country.dialCode,
                            });
                          }}
                          error={validStatePhone}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          sx={{
                            fontSize: "12px",
                            color: "#142C44",
                            fontWeight: "500",
                            marginBottom: "5px",
                            fontFamily: "Poppins",
                            display: "flex",
                          }}
                        >
                          Select Currency
                          <p style={{ margin: "0px 4px", color: "#D32F2F" }}>
                            *
                          </p>
                        </InputLabel>
                        <CurrencyDropDown
                          initialCurrency={
                            branchDetails?.currency
                              ? branchDetails?.currency
                              : ""
                          }
                          handleUpdateCurrency={handleUpdateCurrency}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mb: "50px" }}>
                      <Box
                        sx={{
                          textAlign: "right",
                          marginBottom: "30px",
                          marginTop: "30px",
                        }}
                      >
                        {validatingFirstTimeBranchCreation?.isProfileCompleted && (
                          <Button
                            variant="outlined"
                            type="reset"
                            sx={{
                              marginRight: "8px",
                              color: "#BFBFBF",
                              borderColor: "#BFBFBF",
                              textTransform: "capitalize",
                            }}
                            onClick={() => cancelButtonClick()}
                          >
                            Cancel
                          </Button>
                        )}

                        {add && (
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            variant="contained"
                            type="submit"
                          >
                            Save
                          </Button>
                        )}

                        {!add && (
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ textTransform: "capitalize" }}
                          >
                            {SAVECHANGES_BTN_TEX}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </div>
          )}

          {openModal && (
            <CancelModal
              open={openModal}
              onClose={handleModalClose}
              noButtonClick={noButtonClick}
              yesButtonClick={yesButtonClick}
            />
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <div className={styles.editFormPopupCloseWrap}>
                  <button
                    className={styles.editFormPopupCloseBtn}
                    onClick={handleClose}
                  >
                    <CancelIcon />
                  </button>
                </div>

                <div className={styles.editFormPopupTitle}>
                  Leave this page ?
                </div>
                <div className={styles.editFormPopupDesc}>
                  Changes you made may not be saved.
                </div>
                <div className={styles.editFormPopupBtnsSplit}>
                  <button
                    className={styles.editFormPopupSaveBtn}
                    onClick={() => {
                      handleSubmit("submit");
                      setOpen(false);
                    }}
                  >
                    save
                  </button>
                  <button className={styles.editFormPopupDiscardBtn}>
                    discard
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </Box>
      </Container>
    </>
  );
}
