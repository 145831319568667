import React, { useCallback, useState } from "react";
import { Tab, Tabs, Box, Avatar, Badge } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import styles from "./NotificationPopover.module.css";
import notificationIcon from "./notificationIcon.svg";
import announcement from "./announcement.svg";
import {
  markAllAsRead,
  markAsRead,
} from "../../../Manage/Notification/service/Notificationslice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { GetNotificationsByPaginationPopover } from "../../../Manage/Notification/service/Notification.request";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { formatRelativeTime } from "../../../../../components/Formatter/Formatter";
import { useNavigate } from "react-router";
import {
  getLeadListByInstituteAndContact,
  resetLeadById,
  resetLeadLEmiData,
  resetLeadListByContact,
  resetLeadModalState,
  resetLeadPaymentData,
  resetLeadPopupTabDatas,
  setLeadModalState,
} from "../../../Nurture/service/nurtureSlice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export default function NotificationPopover({ setOpenNotificationPopup }: any) {
  const auth = useAuthUser();
  const authUser = auth();
  const userFromLocalStorage = authUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const [value, setValue] = React.useState("0");

  const whatsNew = [
    {
      id: 1,
      heading: "Introducing seamless Email integration for nurture",
      email: "Simplify and streamline your communication today!",
      time: "2h ago",
      status: "unread",
    },
    {
      id: 2,
      heading:
        "Effortlessly import contacts with our enhanced Bulk Import feature on nurture.",
      email: "Try it now!",
      time: "4h ago",
      status: "unread",
    },
    {
      id: 3,
      heading:
        "Boost customer engagement with our new Whatsapp integration on nurture.",
      email: "Connect and communicate seamlessly!",
      time: "3h ago",
      status: "read",
    },
  ];

  function handleChange(event: React.SyntheticEvent, newValue: string) {
    setValue(newValue);
  }

  const [notificationItems, setNotificationItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(false);

  const notifications = useSelector(
    (state: any) => state.notification.notifications
  );

  React.useEffect(() => {
    setNotificationItems(notifications);
  }, [notifications]);

  const handleNextPage = () => {
    const lastEvaluatedKey = encodeURIComponent(
      notificationItems?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      notificationItems?.lastEvaluatedKeyReceived?.GSI1SK?.S
    );
    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetNotificationsByPaginationPopover(
        userFromLocalStorage?.institute[0]?.id,
        lastEvaluatedKey,
        10,
        lastEvaluatedSortKey
      )
        .then((res: any) => {
          setHasMoreData(true);
          setNotificationItems((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],

            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    if (
      notificationItems &&
      notificationItems?.lastEvaluatedKeyReceived &&
      notificationItems?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [notificationItems]);

  // const handleMarkAsRead = (val: any) => {
  //   const data = { id: val };
  //   if (val) {
  //     dispatch<any>(markAsRead(data));
  //   }
  //   setOpenNotificationPopup(false);
  // };

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      tab: string
    ) => {
      if (hasLeadCardPermission) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("leadModal", "true");
        queryParams.set("leadInstituteId", instituteId);
        queryParams.set("leadId", id);
        if (email) {
          queryParams.set("leadEmail", email);
        } else {
          queryParams.delete("leadEmail");
        }
        if (phone) {
          queryParams.set("leadPhone", phone);
        } else {
          queryParams.delete("leadPhone");
        }
        queryParams.set("tabOpen", tab);

        // Update the URL with the modified query parameter
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
        dispatch(setLeadModalState());
        if (instituteId && (phone || email)) {
          leadPopupApiCall(instituteId, phone, email);
        }
      } else {
        dispatch(
          setToastNotification({
            message: String(
              "You do not have permission to access the lead card"
            ),
            type: "error",
            snackOpen: true,
          })
        );
      }
    },
    []
  );

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );
  const ClearLeadPopupQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("leadModal");
    queryParams.delete("leadId");
    queryParams.delete("tabOpen");
    queryParams.delete("recordPayment");
    queryParams.delete("leadInstituteId");
    queryParams.delete("leadEmail");
    queryParams.delete("leadPhone");
    queryParams.delete("leadTaskId");
    queryParams.delete("leadNoteId");
    queryParams.delete("leadPaymentId");
    queryParams.delete("taskTabOpen");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetLeadListByContact());
    dispatch(resetLeadPopupTabDatas());
    dispatch(resetLeadLEmiData());
    dispatch(resetLeadPaymentData());
    dispatch(resetLeadById());
    dispatch(resetLeadModalState());
  };

  const handleMarkAsRead = (val: any, notificationData: any) => {
    const data = { id: val };
    if (val) {
      dispatch<any>(markAsRead(data));
    }
    const urlParams = new URLSearchParams(window.location.search);
    const leadModal = urlParams.get("leadModal");
    if (leadModal) {
      ClearLeadPopupQuery();
    }

    if (notificationData?.navigationScreen === "EXPANDED_LEAD_CARD") {
      const { leadContact, leadEmail, leadId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "overview"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_TASK_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, taskId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = new URLSearchParams(window.location.search);
      if (taskId) {
        queryParams.set("leadTaskId", taskId);
      } else {
        queryParams.delete("leadTaskId");
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "task"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_NOTE_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, noteId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = new URLSearchParams(window.location.search);
      if (noteId) {
        queryParams.set("leadNoteId", noteId);
      } else {
        queryParams.delete("leadNoteId");
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "notes"
      );
    } else if (
      notificationData?.navigationScreen ===
        "EXPANDED_LEAD_CARD_PAYMENT_SECTION" ||
      notificationData?.navigationScreen ===
        "EXPANDED_LEAD_CARD_NEGOTIATION_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, paymentId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=payment`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (paymentId) queryParams += `&leadPaymentId=${paymentId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "payment"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_DEMO_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, demoId } =
        notificationData?.otherDetails;

      let queryParams = `Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (demoId) queryParams += `&leadDemoId=${demoId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "overview"
      );
    } else if (notificationData?.navigationScreen === "BATCH") {
      const { batchId } = notificationData?.otherDetails?.impactedDetails;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }

      let queryParams = `manage/Batches?batchId=${batchId}`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "COURSE") {
      const { courseId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `manage/Courses/details/${courseId}`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BRANCH") {
      const { branchId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `manage/Branches?selectedBranch=${branchId}`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "INSTITUTE_PROFILE") {
      let queryParams = `manage/InstituteProfile`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "EXPANDED_SUBSCRIPTION") {
      let queryParams = `manage/Billing/ManageSubscription`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_SUBSCRIPTION") {
      let queryParams = `manage/Billing`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_ADDRESS") {
      let queryParams = `manage/Billing?billingTab=address`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_ADMIN") {
      let queryParams = `manage/Billing?billingTab=billingAdmins`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "TEAM_MEMBER") {
      const { userId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `manage/Teams?selectedStaff=${userId}`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_ACTIVITY"
    ) {
      const { leadContact, leadEmail, leadId, activityId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=activity`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (activityId) queryParams += `&leadActivityId=${activityId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "activity"
      );
    } else if (notificationData?.navigationScreen === "INBOUND_CONTACT") {
      let queryParams = `Inbound/Contacts`;
      if (leadModal) {
        dispatch(resetLeadModalState());
        dispatch(resetLeadListByContact());
      }
      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "TASK_COMMENT") {
      const { leadContact, leadEmail, leadId, taskId, commentId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=tasks`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (taskId) queryParams += `&leadTaskId=${taskId}`;
      if (commentId) queryParams += `&leadTaskCommentId=${commentId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "task"
      );
    }

    setOpenNotificationPopup(false);
  };

  const handleMarkAllAsRead = () => {
    const userId = userFromLocalStorage?.institute[0]?.id;
    const data = { id: userId };

    if (userId) {
      dispatch<any>(markAllAsRead(data));
    }
    setOpenNotificationPopup(false);
  };

  return (
    <div className={styles.notificationWrapper}>
      <div>
        <div className={styles.notification}>
          <div className={styles.notificationHead}>Notifications</div>
          <div className={styles.markRead} onClick={handleMarkAllAsRead}>
            Mark all as read
          </div>
        </div>

        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab value="0" label="All" sx={{ textTransform: "capitalize" }} />
            {false && (
              <Tab
                value="1"
                label="What’s New"
                sx={{ textTransform: "capitalize" }}
              />
            )}
          </Tabs>

          <TabPanel sx={{ padding: "0" }} value="0">
            <InfiniteScroll
              dataLength={
                notificationItems && notificationItems?.returnData?.length
                  ? notificationItems?.returnData?.length
                  : notifications && notifications?.returnData?.length
              }
              next={handleNextPage}
              hasMore={hasMoreData}
              loader={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              }
              height={500}
              style={{ scrollBehavior: "smooth" }}
            >
              {notificationItems?.returnData?.map((notification: any) => (
                <div
                  key={notification.id}
                  className={`${styles.notificationItem} ${
                    notification.readStatus === "UNREAD" ? styles.unread : ""
                  }`}
                  // onClick={() => handleMarkAsRead(notification?.id)}
                  onClick={() =>
                    handleMarkAsRead(notification?.id, notification)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      {notification.iconType === "ALPHABET" ? (
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          color="primary"
                          overlap="circular"
                          badgeContent={
                            notification.readStatus === "UNREAD" ? (
                              <span />
                            ) : null
                          }
                          variant={
                            notification.readStatus === "UNREAD"
                              ? "dot"
                              : "standard"
                          }
                        >
                          <Avatar
                            alt="avatar"
                            sx={{
                              width: "30px",
                              height: "30px",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                              textTransform: "capitalize",
                              background: "#667085",
                              color: " #fff",
                              fontWeight: "400",
                            }}
                          >
                            <Box>{notification.name.charAt(0)}</Box>
                          </Avatar>
                        </Badge>
                      ) : (
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          color="primary"
                          overlap="circular"
                          badgeContent={
                            notification.readStatus === "UNREAD" ? (
                              <span />
                            ) : null
                          }
                          variant={
                            notification.readStatus === "UNREAD"
                              ? "dot"
                              : "standard"
                          }
                        >
                          <Avatar
                            alt="avatar"
                            sx={{
                              width: "30px",
                              height: "30px",
                              background: "rgba(102, 112, 133, 0.11)",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                src={notificationIcon}
                                width="18px"
                                height="18px"
                                alt="notification"
                              />
                            </Box>
                          </Avatar>
                        </Badge>
                      )}
                    </div>
                    <div>
                      <div className={styles.email}>
                        {notification.notificationTitle}
                      </div>
                      <div className={styles.timeDisplay}>
                        {formatRelativeTime(notification.createdTime)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </TabPanel>

          <TabPanel sx={{ padding: "0" }} value="1">
            {whatsNew.map((item) => (
              <div
                key={item.id}
                className={`${styles.notificationItem} ${
                  item.status === "unread" ? styles.unread : ""
                }`}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      color="primary"
                      overlap="circular"
                      badgeContent={item.status === "unread" ? <span /> : null}
                      variant={item.status === "unread" ? "dot" : "standard"}
                    >
                      <Avatar
                        alt="avatar"
                        sx={{
                          width: "30px",
                          height: "30px",
                          background: "rgba(102, 112, 133, 0.11)",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={announcement}
                            width="20px"
                            height="20px"
                            alt="notification"
                          />
                        </Box>
                      </Avatar>
                    </Badge>
                  </div>
                  <div>
                    <div className={styles.itemHeading}>{item.heading}</div>
                    <div className={styles.email}>{item.email}</div>
                    <div className={styles.timeDisplay}>{item.time}</div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
        </TabContext>
      </div>
      <div
        className={styles.viewAll}
        onClick={() => {
          navigate("/app/manage/Notifications", { state: 8 });
          dispatch(resetLeadModalState());
          setOpenNotificationPopup(false);
        }}
      >
        View all notifications
      </div>
    </div>
  );
}
