import { store } from "../redux/store";
import {
  setGetLatestReports,
  setLeadBoardRefresh,
  setLeadFormRefresh,
  setTaskReminders,
} from "../service/websocketNotificationsSlice";

const bellSoundUrl =
  "https://commons-image.s3.ap-south-1.amazonaws.com/bellSound.mp3";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || "";

interface CustomWebSocket extends WebSocket {
  onopen: ((this: WebSocket, ev: Event) => any) | null;
  onmessage: ((this: WebSocket, ev: MessageEvent) => any) | null;
  onerror: ((this: WebSocket, ev: Event) => any) | null;
  onclose: ((this: WebSocket, ev: CloseEvent) => any) | null;
}

let heartbeatTimeout: ReturnType<typeof setTimeout>;
let reconnectAttempts = 0;
const maxReconnectAttempts = 5;
let socket: CustomWebSocket;

let bellSound = new Audio(bellSoundUrl);
let isLoggedIn = true;

const stopHeartbeat = () => {
  clearTimeout(heartbeatTimeout);
};

export const initSocket = (
  userId: string,
  instituteId: string
): CustomWebSocket => {
  socket = new WebSocket(
    `${SOCKET_SERVER_URL}/?userId=${userId}&instituteId=${instituteId}`
  ) as CustomWebSocket;

  const startHeartbeat = () => {
    clearTimeout(heartbeatTimeout);
    heartbeatTimeout = setTimeout(() => {
      if (socket.readyState === WebSocket.OPEN) {
        const currentTime = new Date().toLocaleTimeString();
        socket.send(JSON.stringify({ action: "heartbeat" }));
      }
      startHeartbeat(); // Reset heartbeat
    }, 240000);
  };

  const resetHeartbeat = () => {
    clearTimeout(heartbeatTimeout);
    startHeartbeat();
  };

  socket.onopen = () => {
    const currentTime = new Date().toLocaleTimeString();
    reconnectAttempts = 0; // Reset reconnect attempts on successful connection
    startHeartbeat();
  };

  socket.onmessage = (event) => {
    resetHeartbeat();
    const message = JSON.parse(event.data);
    const notificationData = JSON.parse(message.data.message.data);
    if (notificationData.type === "TASK_REMINDER") {
      store.dispatch(setTaskReminders(notificationData.otherDetails));
      bellSound
        .play()
        .catch((error) => console.error("Error playing bell sound:", error));
    } else if (notificationData.type === "ENQUIRY_ADDED_OR_UPDATED") {
      store.dispatch(setLeadBoardRefresh("REFRESH_BOARD"));
    } else if (notificationData.type === "REPORT_ADDED") {
      store.dispatch(setGetLatestReports("REFRESH_REPORT"));
    } else if (notificationData.type === "ADD_LEAD_FORM") {
      store.dispatch(setLeadFormRefresh("ADD_LEAD_FORM"));
    } else {
      console.log("Received an unknown type of notification");
    }
  };

  socket.onerror = (event) => {
    console.error("WebSocket error:", event);
  };
  console.log("isLoggedIn", isLoggedIn);

  socket.onclose = (event) => {
    const currentTime = new Date().toLocaleTimeString();
    stopHeartbeat();
    // if (!isLoggedIn) {
    //   console.log("User logged out, no reconnection attempt");
    //   return;
    // }

    if (reconnectAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        reconnectAttempts++;
        initSocket(userId, instituteId);
      }, Math.pow(2, reconnectAttempts) * 1000); // Exponential backoff
    } else {
      console.error("Max reconnection attempts reached.");
    }
  };

  return socket;
};

export const closeSocket = () => {
  if (socket) {
    // isLoggedIn = false;
    stopHeartbeat(); // Stop the heartbeat timer
    reconnectAttempts = maxReconnectAttempts; // Prevent further reconnection attempts
    socket.close(); // Close the WebSocket connection
    console.log("WebSocket connection closed");
  }
};
