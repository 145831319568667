import React, { useEffect, useState } from "react";
import PublicWebformForm from "./webForm";
import { CircularProgress, Box } from "@mui/material";
import SuccessPage from "./successPage";
import { IconButton, Avatar, Snackbar, Alert } from "@mui/material";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import ToastNotification from "../../../../../../components/ToastNotification/ToastNotification";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

const PublicWebForm = (props: any) => {
  const {
    success,
    loading,
    error,
    contactUpdateSuccess,
    contactUpdateLoading,
    contactUpdateError,
    contactDetails,
    CreateContact,
    GetPublicWebformById,
    publicWebform,
    contactCreateLoading,
  } = props;
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "info",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [specialCharacterWarning, setSpecialCharacterWarning] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Details Submitted Successfully",
        snackOpen: true,
        snackSeverity: "success",
      }));
    }
  }, [success]);

  React.useEffect(() => {
    if (error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(props?.error),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
  }, [error]);

  React.useEffect(() => {
    if (specialCharacterWarning) {
      dispatch(
        setToastNotification({
          message: "Special Characters not allowed",
          type: "error",
          snackOpen: true,
        })
      );
    }
  }, [specialCharacterWarning]);

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color={snackSeverity}
      onClick={closeSnack}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <ToastNotification />
      {success === false && (
        <div style={{ overflowY: "auto", height: "100%" }}>
          <PublicWebformForm
            CreateContact={CreateContact}
            error={error}
            loading={loading}
            GetPublicWebformById={GetPublicWebformById}
            publicWebform={publicWebform}
            setSpecialCharacterWarning={setSpecialCharacterWarning}
            contactCreateLoading={contactCreateLoading}
          />
        </div>
      )}
      {success === true && <SuccessPage />}
    </>
  );
};

export default PublicWebForm;
