import React, { useState } from "react";
import styles from "../StudentSettings.module.css";
import { Button, CircularProgress, TextField, Tooltip } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function StudentPrefix(props: any) {
  const {
    UpdateStudentId,
    studentIdData,
    updateStudentIdLoading,
    updateStudentIdSuccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasStudentIdAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "STUDENT_ID",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [prefix, setPrefix] = useState<String>("");
  const [nextNumber, setNextNumber] = useState<String>("");
  const PREFIX_REGEX = /^[a-zA-Z_]+$/;
  const NUM_REGEX = /^[0-9]*$/;

  const [prefixError, setPrefixError] = React.useState<boolean>(false);
  const [numberError, setNumberError] = React.useState<boolean>(false);

  const handleCancelEdit = () => {
    setIsEditing(false);
  };
  const handlePrefixChange = (e: any) => {
    const inputValue = e.target.value;
    if (PREFIX_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setPrefixError(true);
      } else {
        setPrefixError(false);
      }
      setPrefix(inputValue);
    }
  };

  const handleNumberChange = (e: any) => {
    const inputValue = e.target.value;

    if (NUM_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setNumberError(true);
      } else {
        setNumberError(false);
      }
      setNextNumber(inputValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8) {
      return;
    }
    if (!NUM_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: nextNumber,
        prefix: prefix?.toUpperCase(),
      };
      UpdateStudentId(body);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (
      studentIdData &&
      studentIdData?.prefix !== "" &&
      Object?.keys(studentIdData)?.length > 0
    ) {
      setPrefix(studentIdData?.prefix);
      setNextNumber(studentIdData?.nextNumber);
    }
  }, [studentIdData, isEditing]);

  React.useEffect(() => {
    if (updateStudentIdSuccess !== true && updateStudentIdSuccess !== false) {
      setIsEditing(false);
    }
  }, [updateStudentIdSuccess]);

  return (
    <div>
      {!isEditing ? (
        <div className={styles.prefixDetailsWrap}>
          <div className={styles.prefixDetailsTitle}>Student ID</div>
          <div className={styles.prefixDetailsChild}>
            <div className={styles.prefixDetailsChildLeft}>
              <div className={styles.prefixDetailsItem}>
                <div className={styles.prefixDetailsItemLabel}>Prefix</div>
                <div className={styles.prefixDetailsItemValue}>
                  {studentIdData?.prefix}
                </div>
              </div>
              <div className={styles.prefixDetailsItem}>
                <div className={styles.prefixDetailsItemLabel}>Suffix</div>
                <div className={styles.prefixDetailsItemValue}>
                  {studentIdData?.nextNumber}
                </div>
              </div>
            </div>
            <div>
              <Tooltip
                arrow
                title={
                  hasStudentIdAccess === false ? "Oops! No permission." : ""
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      zIndex: "9999999",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                        zIndex: "9999999",
                      },
                    },
                  },
                }}
              >
                <span>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    variant="contained"
                    disabled={hasStudentIdAccess === false}
                    onClick={() => setIsEditing(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#667085",
                      background: "transparent",
                    }}
                  >
                    <div style={{ marginTop: "2px" }}>Edit</div>
                    <FiEdit size={14} color="#667085" />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.editPrefixWrap}>
          <div className={styles.editPrefixTitle}>Edit Prefix</div>
          <div className={styles.editPrefixDesc}>
            The system will generate them sequentially, ensuring a unique
            identification for each student.
          </div>
          <div style={{ display: "flex", gap: "18px" }}>
            <div>
              <div className={styles.editPrefixLabel}>
                Prefix{" "}
                <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
              </div>
              <TextField
                sx={{
                  // width: "72%",
                  maxWidth: "138px",
                  "& .MuiOutlinedInput-input": {
                    padding: "11px",
                  },
                }}
                value={prefix?.toUpperCase()}
                onChange={handlePrefixChange}
                onKeyDown={(event: any) => {
                  if (!PREFIX_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 4 }}
                error={prefixError}
                helperText={prefixError ? "Field Required" : ""}
              />
            </div>
            <div>
              <div className={styles.editPrefixLabel}>
                Suffix{" "}
                <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
              </div>
              <TextField
                sx={{
                  // width: "100%",
                  maxWidth: "200PX",
                  "& .MuiOutlinedInput-input": {
                    padding: "11px",
                  },
                }}
                value={nextNumber}
                onChange={handleNumberChange}
                inputProps={{ maxLength: 4 }}
                error={numberError}
                helperText={numberError ? "Field Required" : ""}
                onKeyDown={handleKeyDown}
                disabled
              />
            </div>
          </div>
          <div style={{ marginTop: "24px", display: "flex", gap: "12px" }}>
            <Button
              sx={{
                color: "#BFBFBF",
                border: "1px solid #BFBFBF",
                background: "#FFF",
                textTransform: "capitalize",
              }}
              variant="outlined"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={handleSaveClick}
              disabled={numberError || prefixError || updateStudentIdLoading}
            >
              {updateStudentIdLoading ? (
                <CircularProgress size={20} color={"inherit"} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentPrefix;
