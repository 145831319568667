import React, { useCallback, useState } from "react";
import styles from "./Notifications.module.css";
import {
  FormControl,
  FormControlLabel,
  Switch,
  SwitchProps,
  IconButton,
  Checkbox,
  FormGroup,
  Typography,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { resetSettingsStatuses } from "../../service/settingsSlice";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Notifications(props: any) {
  const {
    GetNotificationSettings,
    UpdateNotificationSettings,
    notifySettings,
    notifySettingUpdateSuccess,
    notifySettingUpdateError,
    notifySettingLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasNotificationAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "NOTIFICATIONS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasNurturePay =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("NURTURE_PAY_EASEBUZZ");

  const [openReminders, setOpenReminders] = useState(false);

  const [notificationDetails, setNotificationDetails] = useState({
    id: "",
    sms: false,
    email: false,
    whatsapp: false,
    instituteId: "",
    status: "ACTIVE",
    updatedByName: "",
    updatedById: "",
  });

  const generateNotificationString = () => {
    let notificationString = "";

    if (notificationDetails.sms) {
      notificationString += "SMS";
    }
    if (notificationDetails.email) {
      notificationString += notificationString ? ", Email" : "Email";
    }
    if (notificationDetails.whatsapp) {
      notificationString += notificationString ? ", Whatsapp" : "Whatsapp";
    }

    return notificationString;
  };

  // Call the function to get the notification string
  const notificationString = generateNotificationString();

  const handleCheckboxChange = useCallback(
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const updatedNotificationDetails = {
        ...notificationDetails,
        [id.toLowerCase()]: checked,
      };

      // Determine status based on the latest notification settings
      const newStatus =
        updatedNotificationDetails.sms ||
        updatedNotificationDetails.email ||
        updatedNotificationDetails.whatsapp
          ? "ACTIVE"
          : "INACTIVE";

      setNotificationDetails((prevState) => ({
        ...updatedNotificationDetails,
        status: newStatus,
      }));

      UpdateNotificationSettings({
        ...updatedNotificationDetails,
        instituteId: authUser?.institute?.[0]?.instituteId,
        status: newStatus,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        updatedById: authUser?.institute[0]?.id,
      });
    },
    [authUser, UpdateNotificationSettings, notificationDetails]
  );

  const [paymentNotifiyStatus, setPaymentNotifiyStatus] =
    React.useState<boolean>(false);
  const [allTypeFalse, setAllTypeFalse] = React.useState<boolean>(true);
  const [studentReminderStatus, setStudentReminderStatus] =
    React.useState<string>("");

  const handleChangeCheck = React.useCallback(
    async (event: any) => {
      setPaymentNotifiyStatus(!paymentNotifiyStatus);
      const value = event.target.checked;
      if (!value) {
        setOpenReminders(false);
        setNotificationDetails({
          ...notificationDetails,
          status: "INACTIVE",
        });
        if (studentReminderStatus === "ACTIVE") {
          UpdateNotificationSettings({
            ...notificationDetails,
            instituteId: authUser?.institute?.[0]?.instituteId,
            status: "INACTIVE",
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            updatedById: authUser?.institute[0]?.id,
            sms: false,
            email: false,
            whatsapp: false,
          });
        }
      } else {
        setOpenReminders(true);
        setNotificationDetails({
          ...notificationDetails,
          status: "ACTIVE",
        });
        if (!allTypeFalse) {
          UpdateNotificationSettings({
            ...notificationDetails,
            instituteId: authUser?.institute?.[0]?.instituteId,
            status: "ACTIVE",
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            updatedById: authUser?.institute[0]?.id,
            sms: true,
            email: true,
            whatsapp: true,
          });
        }
      }
    },

    [paymentNotifiyStatus, notificationDetails, studentReminderStatus]
  );

  React.useEffect(() => {
    if (authUser)
      GetNotificationSettings(authUser?.institute?.[0]?.instituteId);
  }, []);

  React.useEffect(() => {
    if (notifySettings) {
      const studentPaymentReminder = notifySettings?.find(
        (item: any) =>
          item.notificationSettingsType === "STUDENT_PAYMENT_REMINDER"
      );
      if (studentPaymentReminder) {
        setNotificationDetails({
          ...notificationDetails,
          sms: studentPaymentReminder?.sms,
          email: studentPaymentReminder?.email,
          whatsapp: studentPaymentReminder?.whatsapp,
          id: studentPaymentReminder?.id,
        });
        setPaymentNotifiyStatus(
          studentPaymentReminder?.status === "ACTIVE" ? true : false
        );
        setStudentReminderStatus(studentPaymentReminder?.status);
        if (
          !studentPaymentReminder?.sms &&
          !studentPaymentReminder?.email &&
          !studentPaymentReminder?.whatsapp
        ) {
          setAllTypeFalse(true);
        } else {
          setAllTypeFalse(false);
        }
      }
    }
  }, [notifySettings]);

  React.useEffect(() => {
    if (
      notifySettingUpdateSuccess !== true &&
      notifySettingUpdateSuccess !== false
    )
      GetNotificationSettings(authUser?.institute?.[0]?.instituteId);
    dispatch(resetSettingsStatuses());
  }, [notifySettingUpdateSuccess]);

  React.useEffect(() => {
    if (
      notifySettingUpdateError !== true &&
      notifySettingUpdateError !== false
    ) {
      dispatch(resetSettingsStatuses());
    }
  }, [notifySettingUpdateError]);

  return (
    <div
      style={{
        width: "100%",
        padding: "30px 20px",
      }}
    >
      {!notifySettingLoading && notifySettings !== null ? (
        <>
          <div className={styles.notificationTitle}>Notifications</div>
          <div className={styles.notificationContent}>
            Manage all the notifications sent from the Institute to Students or
            Leads.
          </div>
        </>
      ) : (
        <>
          <Skeleton
            animation="wave"
            variant="text"
            width="100px"
            height="24px"
            style={{
              borderRadius: "2px",
              marginBottom: "8px",
            }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height="20px"
            width="200px"
            style={{
              borderRadius: "2px",
              marginBottom: "22px",
            }}
          />
        </>
      )}

      {!notifySettingLoading && notifySettings !== null ? (
        <>
          <div
            style={{
              borderRadius: "6px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: openReminders ? "16px" : "unset",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenReminders((prev) => !prev)}
                  style={{
                    background: "#F8F8F8",
                    borderRadius: "10%",
                    width: "28px",
                    height: "28px",
                  }}
                >
                  {openReminders ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div className={styles.paymentReminder}>
                  Payment reminders to students
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <div className={styles.type}>Type</div>

                <div className={styles.value}>
                  {notificationString ? notificationString : "-"}
                </div>
              </div>
              <Tooltip
                title={
                  hasNotificationAccess === false ? "Oops! No permission." : ""
                }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      zIndex: "9999999",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                        zIndex: "9999999",
                      },
                    },
                  },
                }}
              >
                <FormControl
                  fullWidth
                  variant="standard"
                  className="formControl"
                  sx={{ width: "auto" }}
                >
                  <FormControlLabel
                    style={{
                      justifyContent: "flex-end",
                      marginLeft: 0,
                      color: "#3F3E43",
                      marginTop: "0px",
                      gap: 20,
                    }}
                    control={
                      <IOSSwitch
                        sx={{ margin: "1px" }}
                        checked={paymentNotifiyStatus}
                        id="status"
                        onChange={(e) => {
                          handleChangeCheck(e);
                        }}
                        disabled={hasNotificationAccess === false}
                      />
                    }
                    label=""
                    labelPlacement="start"
                  />
                </FormControl>
              </Tooltip>
            </div>

            {openReminders && (
              <div
                style={{ borderTop: "1px solid #EAECF0", paddingTop: "16px" }}
              >
                <div className={styles.type} style={{ color: "#667085" }}>
                  Type{" "}
                  {allTypeFalse && paymentNotifiyStatus && (
                    <span
                      style={{
                        color: "#EC3939",
                        fontSize: "13px",
                        fontWeight: "400",
                        marginLeft: "5px",
                      }}
                    >
                      (Select at least one type)
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "22px",
                  }}
                >
                  <FormGroup style={{ flexDirection: "row" }}>
                    {hasNurturePay && (
                      <Tooltip
                        title={
                          hasNotificationAccess === false
                            ? "Oops! No permission."
                            : ""
                        }
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              zIndex: "9999999",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                                zIndex: "9999999",
                              },
                            },
                          },
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="SMS"
                              checked={notificationDetails.sms}
                              onChange={handleCheckboxChange("SMS")}
                              id="SMS"
                              disabled={hasNotificationAccess === false}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: "#667085",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "20px",
                              }}
                            >
                              SMS
                            </Typography>
                          }
                        />
                      </Tooltip>
                    )}

                    <Tooltip
                      title={
                        hasNotificationAccess === false
                          ? "Oops! No permission."
                          : ""
                      }
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            zIndex: "9999999",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                              zIndex: "9999999",
                            },
                          },
                        },
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Email"
                            id="Email"
                            checked={notificationDetails.email}
                            onChange={handleCheckboxChange("Email")}
                            disabled={hasNotificationAccess === false}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              color: "#667085",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            Email
                          </Typography>
                        }
                      />
                    </Tooltip>

                    {hasNurturePay && (
                      <Tooltip
                        title={
                          hasNotificationAccess === false
                            ? "Oops! No permission."
                            : ""
                        }
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              zIndex: "9999999",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                                zIndex: "9999999",
                              },
                            },
                          },
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Whatsapp"
                              id="Whatsapp"
                              checked={notificationDetails.whatsapp}
                              onChange={handleCheckboxChange("Whatsapp")}
                              disabled={hasNotificationAccess === false}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: "#667085",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "20px",
                              }}
                            >
                              Whatsapp
                            </Typography>
                          }
                        />
                      </Tooltip>
                    )}
                  </FormGroup>
                </div>
                <div className={styles.scheduleHead}>Schedule</div>
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className={styles.scheduleWrapper}>
                    <div className={styles.schedule}>
                      2 days before due date
                    </div>
                  </div>
                  <div className={styles.scheduleWrapper}>
                    <div className={styles.schedule}>1 day before due date</div>
                  </div>
                  <div className={styles.scheduleWrapper}>
                    <div className={styles.schedule}>On due date</div>
                  </div>

                  <div className={styles.scheduleWrapper}>
                    <div className={styles.schedule}>1 day after due date</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        Array.from(new Array(1)).map((_, index) => (
          <div key={index} style={{ marginBottom: "8px" }}>
            <div className={styles.leadCourseSkeletonWrap}>
              <Skeleton animation="wave" variant="rounded" height={60} />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default Notifications;
