import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Box, Drawer } from "@mui/material";
import React, { useEffect, useState, CSSProperties } from "react";
import EmailIntegrationIcon from "../../../../../assets/images/EmailIntegration.svg";
import gmail from "../../../../../assets/images/gmail.svg";
import office360 from "../../../../../assets/images/office360.svg";
import yahoo from "../../../../../assets/images/yahoo.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getGoogleUrl } from "../test/getGoogleUrl";
import { useAuthUser } from "react-auth-kit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import styles from "./EmailIntegration.module.css";
import { Link } from "react-router-dom";
import otherEmail from "../../../../../assets/images/other-mail-icon.svg";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import ClipLoader from "react-spinners/ClipLoader";
import checkCircle from "../../../../../assets/images/check-circle.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getMailConfigsList,
  getMailEntriesByInstitute,
  resetOtherEmailStatuses,
  saveOtherEmailConfigDetails,
} from "../../service/inboundSlice";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#11C89B",
};

const EmailIntegration = (props: any) => {
  const { SaveImapConfigDeatils } = props;
  const {
    imapConfigLoading,
    otherEmailConfigSuccess,
    otherEmailConfigError,
    otherEmailConfigLoading,
  } = useSelector((state: any) => state.inbound);
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasEmailAccess = getSubDomainAccessStatus(
    "INBOUND",
    "EMAIL",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);
  const [userConfigData, setUserConfigData] = useState({
    email: "",
    userName: "",
    password: "",
  });
  const [incomingImapSettings, setIncomingImapSettings] = useState({
    host: "",
    port: 993,
    tls: true,
    authenticationType: "USERNAME/PASSWORD",
    user: "",
    password: "",
    authTimeout: 3000,
    tlsOptions: {
      rejectUnauthorized: false,
    },
  });
  const [outgoingImapSettings, setOutgoingImapSettings] = useState({
    host: "",
    port: 993,
    tls: true,
    authenticationType: "USERNAME/PASSWORD",
    user: "",
    password: "",
    authTimeout: 3000,
    tlsOptions: {
      rejectUnauthorized: false,
    },
  });

  const [incomingHostName, setIncomingHostName] = React.useState<boolean>(true);
  const [incomingPort, setIncomingPort] = React.useState<boolean>(true);
  const [incomingAuthenticationType, setIncomingAuthenticationType] =
    React.useState<boolean>(true);
  const [outgoingHostName, setOutgoingHostName] = React.useState<boolean>(true);
  const [outgoingPort, setOutgoingPort] = React.useState<boolean>(true);
  const [outgoingAuthenticationType, setOutgoingAuthenticationType] =
    React.useState<boolean>(true);
  const [syncTime, setSyncTime] = useState<number>(0);
  const [showDisclaimerScreen, setShowDisclaimerScreen] = useState(false);

  const auth = useAuthUser();
  const authUser = auth();

  const navigate = useNavigate();

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);
  const setValidateIncomingHost = React.useCallback((val: boolean) => {
    setIncomingHostName(val);
  }, []);
  const setValidateIncomingPort = React.useCallback((val: boolean) => {
    setIncomingPort(val);
  }, []);
  const setValidateIncomingAuthenticationType = React.useCallback(
    (val: boolean) => {
      setIncomingAuthenticationType(val);
    },
    []
  );
  const setValidateOutcomingHost = React.useCallback((val: boolean) => {
    setOutgoingHostName(val);
  }, []);
  const setValidateOutcomingPort = React.useCallback((val: boolean) => {
    setOutgoingPort(val);
  }, []);
  const setValidateOutcomingAuthenticationType = React.useCallback(
    (val: boolean) => {
      setOutgoingAuthenticationType(val);
    },
    []
  );

  useEffect(() => {
    if (otherEmailConfigSuccess !== true && otherEmailConfigSuccess !== false) {
      setOtherMailOpen(false);
      const id = authUser?.institute[0]?.instituteId;
      const lastEvaluatedKey = "";
      const limit = 15;
      const lastEvaluatedSortKey = "";
      dispatch(resetOtherEmailStatuses());
      if (id) {
        dispatch<any>(getMailConfigsList(id));
        dispatch<any>(
          getMailEntriesByInstitute({
            id,
            lastEvaluatedKey,
            limit,
            lastEvaluatedSortKey,
          })
        );
      }
    }
  }, [otherEmailConfigSuccess]);

  useEffect(() => {
    if (otherEmailConfigError !== true && otherEmailConfigError !== false) {
      // navigate("/app/inbound");
      dispatch(resetOtherEmailStatuses());
    }
  }, [otherEmailConfigError]);

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const gmailData = [
    {
      id: 1,
      description: "Click on the Gmail icon.",
    },
    {
      id: 2,
      description: "This will redirect you to your Gmail Sign-in Page.",
    },
    {
      id: 3,
      description:
        "You can choose an existing account or add another account as your need be.",
    },
    {
      id: 4,
      description:
        "You will be redirected to the next page to give access to Nurture.",
    },
    {
      id: 5,
      description: "Click Allow to allow Nurture's integration with Gmail.",
    },
    {
      id: 6,
      description:
        "Once the integration completes, you will be redirected to the Funnel Tab where you can view all the synced emails.",
    },
  ];

  const otherEmailData = [
    {
      id: 1,
      description: "Click on Other Mail icon.",
    },
    {
      id: 2,
      description: "This will open a window.",
    },
    {
      id: 3,
      description:
        "Fill the Username, Password, Incoming and Outgoing Mail settings.",
    },
    {
      id: 4,
      description: "Click on Continue.",
    },
    {
      id: 5,
      description:
        "Once the email channel is integrated, you will be redirected to Funnel Tab in Inbound where you can view all the synced emails.",
    },
  ];

  const officeData = [
    {
      id: 1,
      description: "Click on the Office 365 icon.",
    },
    {
      id: 2,
      description: "This will redirect you to your Microsoft Sign-in Page.",
    },
    {
      id: 3,
      description:
        "You can choose an existing account or add another one as needed.",
    },
    {
      id: 4,
      description:
        "You will be redirected to the next page to give access to Nurture.",
    },
    {
      id: 5,
      description:
        "Click Allow to allow Nurture's integration with Office 365.",
    },
    {
      id: 6,
      description:
        "Once the integration completes, you will be redirected to the Inbound Inbox where you can view all the synced mails.",
    },
  ];

  const yahooData = [
    {
      id: 1,
      description: "Click on the Yahoo icon.",
    },
    {
      id: 2,
      description: "This will redirect you to your Yahoo Sign-in Page.",
    },
    {
      id: 3,
      description:
        "You can choose an existing account or add another one as needed.",
    },
    {
      id: 4,
      description:
        "You will be redirected to the next page to give access to Nurture.",
    },
    {
      id: 5,
      description: "Click Allow to allow Nurture's integration with Yahoo.",
    },
    {
      id: 6,
      description:
        "Once the integration completes, you will be redirected to the Inbound Inbox where you can view all the synced mails.",
    },
  ];

  const [otherMailOpen, setOtherMailOpen] = useState<boolean>(false);

  const handleGmailNavigate = () => {
    window.location.href = getGoogleUrl(targetUrl);
    // navigate(getGoogleUrl(targetUrl));
  };

  const handleOtherMailOpen = () => {
    setOtherMailOpen(true);
  };

  const mailService = [
    {
      id: 1,
      icon: gmail,
      name: "Gmail",
      onClick: handleGmailNavigate,
    },
    // {
    //   id: 2,
    //   icon: office360,
    //   name: "Office 365",
    //   onClick: handleGmailNavigate,
    // },
    // {
    //   id: 3,
    //   icon: yahoo,
    //   name: "Yahoo mail",
    //   onClick: handleGmailNavigate,
    // },
    {
      id: 4,
      icon: otherEmail,
      name: "Other Mail",
      onClick: handleOtherMailOpen,
    },
  ];

  const GmailDataList = ({ data }: any) => {
    return (
      <ul style={{ padding: "0 0 0 14px" }}>
        <li
          style={{
            color: "#667085",
            fontFamily: "poppins",
            fontSize: "13px",
            fontWeight: "400",
          }}
        >
          {data?.description}
        </li>
      </ul>
    );
  };

  const OfficeDataList = ({ data }: any) => {
    return (
      <ul style={{ padding: "0 0 0 14px" }}>
        <li
          style={{
            color: "#667085",
            fontFamily: "poppins",
            fontSize: "13px",
            fontWeight: "400",
          }}
        >
          {data?.description}
        </li>
      </ul>
    );
  };

  const YahooDataList = ({ data }: any) => {
    return (
      <ul style={{ padding: "0 0 0 14px" }}>
        <li
          style={{
            color: "#667085",
            fontFamily: "poppins",
            fontSize: "13px",
            fontWeight: "400",
          }}
        >
          {data?.description}
        </li>
      </ul>
    );
  };

  const MailServiceList = ({ data }: any) => {
    return (
      <>
        {/* <Link to="EmailSyncing"> */}
        <Tooltip
          arrow
          title={hasEmailAccess === false ? "Oops! No permission." : ""}
          sx={{ width: "100%" }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                },
              },
            },
          }}
        >
          <span
            style={{
              // width: "100%",
              // display: "block",
              textAlign: "center",
            }}
          >
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                data?.name === "Gmail"
                  ? setShowDisclaimerScreen(true)
                  : setOtherMailOpen(true);
              }}
              disabled={!hasEmailAccess}
            >
              <Box
                sx={{
                  height: "117px",
                  width: "133px",
                  borderRadius: "6px",
                  border: "1px solid #E3E3E3",
                  boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  background: "#FFFFFF",
                }}
              >
                <img src={data?.icon} height="28px" width="28px" alt="" />

                <p
                  style={{
                    color: "#3F3E43",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    fontWeight: "500",
                    margin: "0px 0px",
                    marginTop: "5px",
                    textAlign: "center",
                  }}
                >
                  {data?.name}
                </p>
              </Box>
            </Button>
          </span>
        </Tooltip>

        {/* </Link> */}
      </>
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const domain = window.location.host;
  const targetUrl = `https://${domain}/app/Inbound/EmailIntegration/EmailSyncing`;

  const handleSSLCheckBox = (event: any) => {
    setIncomingImapSettings({
      ...incomingImapSettings,
      tls: event?.target?.checked,
    });
  };
  const handleTLSCheckBox = (event: any) => {
    setOutgoingImapSettings({
      ...outgoingImapSettings,
      tls: event?.target?.checked,
    });
  };

  const incomingAuthenticationChange = (event: any) => {
    setIncomingImapSettings({
      ...incomingImapSettings,
      authenticationType: event.target.value,
    });
  };

  const outgoingAuthenticationChange = (event: any) => {
    setOutgoingImapSettings({
      ...outgoingImapSettings,
      authenticationType: event.target.value,
    });
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const data = {
      instituteId: userFromLocalStorage?.id,
      emailProvider: "IMAP",
      gmailCode: "",
      incomingImapObject: incomingImapSettings,
      outgoingImapObject: outgoingImapSettings,
      status: "ACTIVE",
      connectionStatus: "AUTHENTICATED",
      scopes: [],
      syncTime: syncTime,
    };
    // SaveImapConfigDeatils(data);
    dispatch<any>(saveOtherEmailConfigDetails(data));
  };

  const durations = [
    {
      duration: 0,
      durationLabel: "Now",
    },
    {
      duration: 30,
      durationLabel: "1 Month ago",
    },
    {
      duration: 90,
      durationLabel: "3 Months ago",
    },
    {
      duration: 180,
      durationLabel: "6 Months ago",
    },
  ];

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSyncTime = Number(event.target.value.split(" ")[0]);
    setSyncTime(selectedSyncTime);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
        // minHeight: "calc(100vh - 65px)",
        // maxHeight: "calc(100vh - 65px)",
        minHeight: "calc(100vh - 91px)",
        maxHeight: "calc(100vh - 91px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxHeight: "calc(100vh - 91px)",
          justifyContent: "space-between",
          backgroundColor: "#FFFFFF",
          // overflowY: "auto",
          width: "100%",
        }}
      >
        {!showDisclaimerScreen ? (
          <>
            <Box
              sx={{
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FFFFFF",
                overflowY: "auto",
                maxHeight: "calc(100vh - 91px)",
                overflowX: "HIDDEN",
              }}
            >
              <Box sx={{ width: "100%", overflow: "hidden" }}>
                {/* <Box
                  sx={{
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <button
                    className={styles.BackBtn}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon
                      sx={{
                        marginRight: "8px",
                        fontSize: "15PX",
                        color: "#667085",
                      }}
                    />
                    Back
                  </button>
                </Box> */}
                <Box sx={{ padding: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxWidth: "160px",
                      overflow: "hidden",
                      margin: "0 auto 4% auto",
                    }}
                  >
                    <img
                      src={EmailIntegrationIcon}
                      style={{
                        maxWidth: "100%",
                      }}
                      alt=""
                    />
                  </Box>
                  <p
                    style={{
                      color: "#3F3E43",
                      fontFamily: "poppins",
                      fontSize: "28px",
                      fontWeight: "600",
                      margin: "0px 0px",
                      textAlign: "center",
                    }}
                  >
                    Email Integration
                  </p>

                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      margin: "5px auto",
                      width: "60%",
                      textAlign: "center",
                    }}
                  >
                    Connect your first channel and start bringing conversations
                    to your inbox.
                  </p>

                  <p
                    style={{
                      textAlign: "center",
                      color: "#142C44",
                      fontFamily: "poppins",
                      fontSize: "18px",
                      fontWeight: "500",
                      margin: "0px 0px",
                      marginTop: "50px",
                    }}
                  >
                    Popular Email Services
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: "20px",
                    }}
                  >
                    {/* <a
                  href={getGoogleUrl(targetUrl)}
                >
                  <img
                    className="pr-2"
                    src={"/"}
                    alt=""
                    style={{ height: "2rem" }}
                  />
                  Continue with Google
                 
                </a> */}
                    {
                      // <GLogin />
                    }
                    {mailService.map((res: any) => (
                      <MailServiceList data={res} />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40%",
                padding: "20px",
                borderLeft: "1px solid #E9E9E9",
                maxHeight: "calc(100vh - 90px)",
                overflowY: "auto",
              }}
            >
              <p
                style={{
                  color: "#142C44",
                  fontFamily: "poppins",
                  fontSize: "20px",
                  fontWeight: "500",
                  margin: "0px 0px",
                }}
              >
                Email Integration
              </p>

              <p
                style={{
                  color: "#667085",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  margin: "8px 0px 18px 0px",
                  lineHeight: "22px",
                }}
              >
                No more switching between email clients and Nurture. Never lose
                a lead because of missed email threads and responses.
              </p>

              <Box sx={{ mt: "15px" }}>
                <Accordion
                  style={{
                    background: "#ffffff",
                    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                    border: "1px solid #E3E3E3 ",
                  }}
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#142C44",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "22px",
                          height: "17px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={gmail} width="100%" alt="gmail" />
                      </div>
                      Gmail
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <p
                        style={{
                          color: "#142C44",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: "500",
                          margin: "0px 0px",
                          marginTop: 5,
                        }}
                      >
                        {`Steps for Gmail integration`}
                      </p>

                      <Box>
                        {gmailData.map((res: any) => (
                          <GmailDataList data={res} />
                        ))}
                      </Box>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box sx={{ mt: "15px" }}>
                <Accordion
                  style={{
                    background: "#ffffff",
                    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                    border: "1px solid #E3E3E3 ",
                  }}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#142C44",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "22px",
                          height: "17px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={otherEmail} width="100%" alt="yahoo" />
                      </div>
                      Other mail
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <p
                        style={{
                          color: "#142C44",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: "500",
                          margin: "0px 0px",
                          marginTop: 5,
                        }}
                      >
                        {`Steps for Other Mail Integration`}
                      </p>

                      <Box>
                        {otherEmailData.map((res: any) => (
                          <YahooDataList data={res} />
                        ))}
                      </Box>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              overflowY: "auto",
              overflowX: "HIDDEN",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "#3F3E43",
                fontFamily: "poppins",
                fontSize: "28px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "30px",
              }}
            >
              Instruction for Integration of Email to nurture
              <p
                style={{
                  color: "#667085",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  margin: "5px auto",
                  width: "60%",
                  textAlign: "center",
                }}
              >
                To enable the integration of email services with the Nurture
                Platform, it is necessary to ensure that the following
                permissions are checked.
              </p>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "30px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: "#142C44",
                    fontFamily: "poppins",
                    fontSize: "18px",
                    fontWeight: "500",
                    margin: "0px 10px 10px 0px",
                  }}
                >
                  First time integrating your Mail
                </div>
                <div>
                  <img
                    src={require("../../../../../assets/images/First time.gif")}
                    alt="GIF image"
                    height={"400px"}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: "#142C44",
                    fontFamily: "poppins",
                    fontSize: "18px",
                    fontWeight: "500",
                    margin: "0px 0px 10px 0px",
                  }}
                >
                  Syncing already integrated Mail
                </div>
                <div>
                  <img
                    src={require("../../../../../assets/images/Repeater.gif")}
                    alt="GIF image"
                    height={"400px"}
                  />
                </div>
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "45%",
                  padding: "15px 32px 30px 0px",
                  background: "#fff",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                  marginRight: "30px",
                  marginBottom: "30px",
                }}
              >
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  sx={{
                    boxShadow: "none",
                    textTransform: "capitalize",
                    color: "#4285f4",
                    borderColor: "#4285f4",
                    ":hover": {
                      borderColor: "#4285f4",
                    },
                  }}
                  onClick={() => {
                    setShowDisclaimerScreen(false);
                  }}
                >
                  Cancel
                </Button>

                <button
                  className={styles.googleSign}
                  style={{ width: "100%" }}
                  onClick={handleGmailNavigate}
                >
                  Sign in with Google
                </button>
              </div>
            </Box>
          </Box>
        )}
      </Box>

      <Drawer
        className="filter_drawer"
        anchor="right"
        open={otherMailOpen}
        onClose={() => setOtherMailOpen(false)}
        PaperProps={{
          sx: {
            width: 502,
            backgroundColor: "#ffff",
            zIndex: "999999",
          },
        }}
      >
        <button
          className={styles.filterCloeBtn}
          onClick={() => setOtherMailOpen(false)}
        >
          x
        </button>
        <div className={styles.filterDrawerHead}>
          <div className={styles.filterDrawerTilte}>Other Mail</div>
        </div>
        {imapConfigLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              background: "rgba(1,1,1,0.1)",
            }}
          >
            <ClipLoader
              color={"#11C89B"}
              loading={imapConfigLoading}
              cssOverride={override}
              size={80}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        ) : (
          <form
            style={{
              padding: "20px 26px",
              overflowY: "auto",
              maxHeight: "calc(100vh - 132px)",
            }}
          >
            <div style={{ marginBottom: "20PX" }}>
              <NewFormTextInput
                validate={true}
                label="Email address"
                setValidStatus={setValidateStatusEmail}
                type={"email"}
                id="email"
                value={incomingImapSettings.user}
                onChange={(e: any) => {
                  setIncomingImapSettings({
                    ...incomingImapSettings,
                    user: e?.target?.value,
                  });
                  setOutgoingImapSettings({
                    ...outgoingImapSettings,
                    user: e?.target?.value,
                  });
                }}
                error={!validStateEmail}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                // autoComplete="true"
                require={true}
              />
            </div>
            <div style={{ marginBottom: "20PX" }}>
              <NewFormTextInput
                validate={true}
                label="Password"
                setValidStatus={setValidateStatusPassword}
                type={"password"}
                id="password"
                value={incomingImapSettings?.password}
                onChange={(e: any) => {
                  setIncomingImapSettings({
                    ...incomingImapSettings,
                    password: e?.target?.value,
                  });
                  setOutgoingImapSettings({
                    ...outgoingImapSettings,
                    password: e?.target?.value,
                  });
                }}
                error={!validStatePassword}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                // autoComplete="true"
                require={true}
              />
            </div>
            <div style={{ marginBottom: "20PX" }}>
              <div className={styles.imapServerWrapMainLabel}>
                Incoming mail settings
              </div>
              <div className={styles.imapServerWrap}>
                <div>
                  <NewFormTextInput
                    disabled={false}
                    onChange={(e: any) => {
                      setIncomingImapSettings({
                        ...incomingImapSettings,
                        host: e?.target?.value,
                      });
                    }}
                    required
                    value={incomingImapSettings.host}
                    type="text"
                    label="IMAP server"
                    variant="standard"
                    fullWidth
                    require={true}
                    error={!incomingHostName}
                    setValidStatus={setValidateIncomingHost}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    inputProps={{ maxLength: 1000 }}
                    validate={true}
                    placeholder="eg: imap.nurture.com"
                  />
                </div>
                <div className={styles.imapServerWrapRight}>
                  <div>
                    <NewFormTextInput
                      validate={true}
                      disabled={false}
                      onChange={(e: any) => {
                        // setIncomingImapSettings({
                        //   ...incomingImapSettings,
                        //   port: e?.target?.value,
                        // });
                        const newPort = parseInt(e?.target?.value);
                        setIncomingImapSettings({
                          ...incomingImapSettings,
                          port: isNaN(newPort) ? 0 : newPort,
                        });
                      }}
                      value={incomingImapSettings.port}
                      type="text"
                      variant="standard"
                      fullWidth
                      error={!incomingPort}
                      setValidStatus={setValidateIncomingPort}
                      showErrorMessage={showErrMsz}
                      errorType={errorType}
                      inputProps={{ maxLength: 1000 }}
                      placeholder="eg: 123"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      control={
                        <Checkbox
                          size="small"
                          sx={{
                            px: 1,
                            py: 0,
                            strokeWidth: 1,
                            color: "#B0BABF",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          onChange={handleSSLCheckBox}
                          value={incomingImapSettings?.tls}
                          checked={incomingImapSettings?.tls}
                        />
                      }
                      label={
                        <Typography
                          style={{ fontSize: "12px", color: "142C44" }}
                        >
                          Use SSL/TLS
                        </Typography>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "20PX" }}>
              <div className={styles.imapServerWrapMainLabel}>
                Outgoing mail settings
              </div>
              <div className={styles.imapServerWrap}>
                <div>
                  <NewFormTextInput
                    disabled={false}
                    onChange={(e: any) => {
                      setOutgoingImapSettings({
                        ...outgoingImapSettings,
                        host: e?.target?.value,
                      });
                    }}
                    required
                    value={outgoingImapSettings?.host}
                    type="text"
                    label="SMTP server"
                    variant="standard"
                    fullWidth
                    require={true}
                    error={!outgoingHostName}
                    setValidStatus={setValidateOutcomingHost}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    inputProps={{ maxLength: 50 }}
                    validate={true}
                    placeholder="eg: smtp.nurture.com"
                  />
                </div>
                <div className={styles.imapServerWrapRight}>
                  <div>
                    <NewFormTextInput
                      validate={true}
                      disabled={false}
                      onChange={(e: any) => {
                        // setOutgoingImapSettings({
                        //   ...outgoingImapSettings,
                        //   port: e?.target?.value,
                        // });
                        const newPort = parseInt(e?.target?.value);
                        setOutgoingImapSettings({
                          ...outgoingImapSettings,
                          port: isNaN(newPort) ? 0 : newPort,
                        });
                      }}
                      value={outgoingImapSettings.port}
                      type="text"
                      variant="standard"
                      fullWidth
                      error={!outgoingPort}
                      setValidStatus={setValidateOutcomingPort}
                      showErrorMessage={showErrMsz}
                      errorType={errorType}
                      inputProps={{ maxLength: 50 }}
                      placeholder="eg: 123"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      control={
                        <Checkbox
                          size="small"
                          sx={{
                            px: 1,
                            py: 0,
                            strokeWidth: 1,
                            color: "#B0BABF",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          onChange={handleTLSCheckBox}
                          value={outgoingImapSettings?.tls}
                          checked={outgoingImapSettings?.tls}
                        />
                      }
                      label={
                        <Typography
                          style={{ fontSize: "12px", color: "142C44" }}
                        >
                          Use SSL/TLS
                        </Typography>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.emsilSyncBoxRadioHead}>Sync From</div>
              <RadioGroup
                aria-labelledby="status-label"
                name="radio-buttons-group"
                style={{
                  color: "#667085",
                  marginBottom: "10px",
                  position: "relative",
                }}
                value={`${syncTime} Months ago`}
                onChange={handleRadioChange}
              >
                {durations.map((item: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={`${item.duration} Months ago`}
                    control={
                      <Radio sx={{ strokeWidth: 1, color: "#D0D5DD" }} />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#667085",
                          lineHeight: "20px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.durationLabel}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </div>
          </form>
        )}
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "15px 32px",
            borderTop: "1px solid #e4e4e4",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Button
            color="primary"
            fullWidth
            variant="outlined"
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
            }}
            onClick={() => {
              setOtherMailOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              boxShadow: "none",
              textTransform: "capitalize",
            }}
            onClick={handleSubmit}
            disabled={otherEmailConfigLoading}
          >
            {otherEmailConfigLoading ? (
              <CircularProgress size={22} color={"inherit"} />
            ) : (
              "Continue"
            )}
          </Button>
        </div>
      </Drawer>
    </Box>
  );
};

export default EmailIntegration;
