import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

const AttendanceReportsStudentSkeleton = () => {
  return (
    <div className={styles.reportsLeftSection}>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>

      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
      </div>
    </div>
  );
};

export default AttendanceReportsStudentSkeleton;
