// src/store/compressSlice.js
import { createSlice } from "@reduxjs/toolkit";

const taskBoardUpdationSlice = createSlice({
  name: "taskBoard",
  initialState: {
    isTriggered: false,
  },
  reducers: {
    toggleTaskTrigger: (state) => {
      state.isTriggered = !state.isTriggered;
    },
    setTrigger: (state, action) => {
      state.isTriggered = action.payload;
    },
  },
});

export const { setTrigger, toggleTaskTrigger } = taskBoardUpdationSlice.actions;

export default taskBoardUpdationSlice.reducer;
