import React, { useCallback } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLeadModalState } from "../../../Nurture/service/nurtureSlice";
import styles from "../../Dashboard.module.css";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import getAccessStatus, {
  checkSubDomainAccess,
} from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getDescriptionWithMentionedNames } from "../../../../../components/CommonFunctions/CommonFunctions";
import { useAuthUser } from "react-auth-kit";
import noResults from "../../../../../assets/images/no-results.svg";

const MyTask = (props: any) => {
  const { data, sideIcon, GetLeadListByInstituteAndContact } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    userById?.rolePermission
  );
  const employees = useSelector((state: any) => state.staff.staffList);

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;

      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      taskId: string,
      taskTab: string
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "task");
      queryParams.set("taskTabOpen", taskTab);
      queryParams.set("leadTaskId", taskId);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  return (
    <div style={{}} className={styles.myTasksWrapper}>
      <div className={styles.myTasksHeadWrap}>
        <div className={styles.myTasksHeadTitle}>My Tasks</div>
        <div className={styles.myTasksHeadBtn}>
          <div
            style={{
              margin: "0px",
              fontSize: "12px",
              fontWeight: "500",
              color: "#11C89B",
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(
                getAccessStatus(
                  "TASKS",
                  "ALL_OTHER_USERS'_TASK",
                  userById?.rolePermission
                )
                  ? `/app/Tasks?selectedUsers=${authUser?.institute?.[0]?.id}`
                  : `/app/Tasks`
              );
            }}
          >
            View All
          </div>
        </div>
      </div>
      <div className={styles.myTasksPendingTaskCount}>{data?.length}</div>
      <div className={styles.myTasksPendingTaskTitle}>Pending Tasks</div>

      <Box
        sx={{
          height: "300px",
          overflowY: "auto",
          width: "100%",
          padding: "0 8px",
          overflowX: "hidden",
        }}
      >
        {data && data?.length !== 0 ? (
          data?.map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() =>
                item.taskType === "DEMO"
                  ? navigate(`/app/Tasks/DemoTaskDetails/${item.id}`)
                  : hasLeadCardPermission
                  ? openLeadExpandModal(
                      item?.instituteId
                        ? item?.instituteId
                        : item?.enquiryData?.instituteId,
                      item?.studentContact
                        ? item?.studentContact
                        : item?.enquiryData?.studentContact,
                      item?.studentEmail
                        ? item?.studentEmail
                        : item?.enquiryData?.studentEmail,
                      item?.enquiryId,
                      item.id,
                      "INCOMPLETE"
                    )
                  : dispatch(
                      setToastNotification({
                        message: String(
                          "You do not have permission to access the lead card"
                        ),
                        type: "error",
                        snackOpen: true,
                      })
                    )
              }
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box>
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      padding: "5px",
                      textAlign: "center",
                      backgroundColor: "#FFF",
                      borderRadius: "50%",
                      filter:
                        "drop-shadow(0px 4px 6px rgba(16, 24, 40, 0.03)) drop-shadow(0px 12px 16px rgba(16, 24, 40, 0.03))",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={sideIcon(item?.taskType)}
                      height={"22px"}
                      width={"22px"}
                      alt="?"
                    />
                  </Box>
                  {index < data?.length - 1 && (
                    <Box
                      sx={{
                        height: "100%",
                        width: "1px",
                        margin: "0 auto",
                        border: "1px dashed #D3D3D3",
                      }}
                    ></Box>
                  )}
                </Box>

                <Box
                  sx={{
                    pl: "14px",
                    width: "calc(100% - 29px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div className={styles.myTasksStatus}>
                      {item?.status?.toLowerCase()?.replace(/_/g, " ")}
                    </div>

                    <div
                      style={{
                        textAlign: "right",
                        margin: "0px",
                        fontSize: "12px",
                        fontWeight: "400",
                        color:
                          moment(item?.date) < moment() ? "#EC3939" : "#667085",
                        width: "150px",
                        paddingRight: "5px",
                      }}
                    >
                      {getFormattedDate(item?.date)}
                    </div>
                  </Box>

                  <div className={styles.myTasksStudentName}>
                    {item?.studentName}
                  </div>

                  <div
                    className={styles.myTasksPendingTaskDesc}
                    dangerouslySetInnerHTML={{
                      __html: getDescriptionWithMentionedNames(
                        item?.description,
                        employees
                      ).replace(/\n/g, "<br>"),
                    }}
                  ></div>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              margin: "auto",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <img src={noResults} alt="noResults" />
            <div
              style={{
                fontSize: "13px",
                color: "#667085",
                fontWeight: "500",
                marginTop: "12PX",
              }}
            >
              No data available
            </div>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MyTask;
