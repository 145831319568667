import { Box, Grid, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GeneralSetup } from "./../../../../../components/GeneralSetup/GeneralSetup";
import { useLocation } from "react-router-dom";
import ManageInboundRouter from "../../../../../router/ManageInboundRouter";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import styles from "../../../Manage/ManageRoot/ManageRoot.module.css";

export default function ManageInboundRoot(props: any) {
  const { registrationStep, activeInstitute } = props;

  const location = useLocation();
  const { userById } = useSelector((state: any) => state.userDetails);

  const [isActive, setIsActive] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const userFromLocalStorage = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  useEffect(() => {
    if (window.location.href.includes("Contacts")) {
      setIsActive(0);
    } else if (window.location.href.includes("Email")) {
      setIsActive(1);
    } else if (window.location.href.includes("Import")) {
      setIsActive(2);
    } else if (window.location.href.includes("Webforms")) {
      setIsActive(3);
    } else if (window.location.href.includes("Zapier")) {
      setIsActive(4);
    }
    // if (location?.state?.menuItemNumber && location?.state?.menuItemNumber) {
    //   setIsActive(location?.state?.menuItemNumber);
    // } else if (location?.state) {
    //   setIsActive(location?.state);
    // }
  }, [setIsActive, location]);

  const handleStatus = React.useCallback(
    (index: number) => {
      setIsActive(index);
    },
    [setIsActive]
  );

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      userFromLocalStorage &&
      userFromLocalStorage.isProfileCompleted === false
    ) {
      setButtonDisabled(true);
    }
  }, [setButtonDisabled, userFromLocalStorage]);

  useEffect(() => {
    const element = document.getElementById("manage");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const shouldDisableGeneralSetup =
    accountStatus === "ARCHIVED" ||
    accountStatus === "LOCKED" ||
    accountStatus === "UNARCHIVED" ||
    accountStatus === "INACTIVE";

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        overflow: "auto",
        padding: "14px 16px",
        background: "#F5F5F5",
        maxHeight: "calc(100vh - 65px)",
        position: "relative",
      }}
    >
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          overflow: "hidden",
          background: "#fff",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          minHeight: "calc(100vh - 94px)",
        }}
        id="manage"
      >
        <Box>
          <Grid
            className={styles.gridInboundContainer}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          >
            <Grid
              className={styles.gridInboundRoot}
              item
              xs={12}
              md={2.5}
              sm={12}
              lg={2}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                  md: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
                overflowY: "auto",
                borderRight: "1px solid #c7c7c7",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    paddingLeft: "34px",
                    fontSize: "18px",
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontWeight: "500",
                    paddingRight: "45px",
                  }}
                >
                  Inbound
                </Typography>
                <Box
                  sx={{
                    width: { xs: "95vw", sm: 550, lg: "auto", md: "auto" },
                    height: {
                      lg: "auto",
                    },
                    overflowY: {
                      xs: "auto",
                      lg: "hidden",
                      md: "auto",
                      sm: "auto",
                    },
                    paddingLeft: "20px",
                    paddingRight: "30px",
                  }}
                >
                  <List
                    sx={{
                      fontSize: { xs: 2 },

                      display: {
                        xs: "-webkit-box",
                        sm: "flex",
                        md: "block",
                        lg: "block",
                      },
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                      },
                      overflowY: {
                        xs: "auto",
                        lg: "hidden",
                      },
                      padding: { xs: 0 },
                      pointerEvents: buttonDisabled ? "none" : "auto",
                      ".MuiTypography-root": {
                        fontSize: "0.8rem !important",
                      },
                    }}
                  >
                    {[
                      {
                        sectionName: "Contacts",
                        allAccess: checkSubDomainAccess(
                          "INBOUND",
                          "CONTACTS",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Email Integration",
                        allAccess: checkSubDomainAccess(
                          "INBOUND",
                          "EMAIL",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Import",
                        allAccess: checkSubDomainAccess(
                          "INBOUND",
                          "IMPORT",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Webforms",
                        allAccess: checkSubDomainAccess(
                          "INBOUND",
                          "WEBFORMS",
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Zapier",
                        allAccess: checkSubDomainAccess(
                          "INBOUND",
                          "ZAPIER",
                          userById?.rolePermission
                        ),
                      },
                    ]
                      .filter((item) => item !== null)
                      .map((item, index) => (
                        <GeneralSetup
                          key={index}
                          item={item?.sectionName}
                          handleStatus={handleStatus}
                          index={index}
                          isActive={isActive}
                          itemAllAccess={item?.allAccess}
                        />
                      ))}
                  </List>
                </Box>
              </Box>
            </Grid>
            <Grid
              className={styles.gridInboundRootBottom}
              item
              // lg={
              //   window.innerWidth === 1707
              //     ? 10.2
              //     : window.innerWidth === 1600
              //     ? 10.1
              //     : window.innerWidth === 1422
              //     ? 9.8
              //     : 9.5
              // }
              md={9.5}
              xs={12}
              sm={12}
              lg={10}
              xl={10}
              style={{
                // overflowY: "hidden",
                paddingLeft: "0px",
                paddingRight: "15px",
                padding: !userFromLocalStorage.isProfileCompleted ? "" : "0px ",
                overflow: "auto",
              }}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
              }}
            >
              <ManageInboundRouter
                setIsActive={setIsActive}
                shouldDisableGeneralSetup={shouldDisableGeneralSetup}
                isActive={isActive}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
