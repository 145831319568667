import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React from "react";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import styles from "../Nurture.module.css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FieldFileDropZone from "../../../../../components/FileDropZone/FieldFileDropZone";

function AdditionalFields(props: any) {
  const { fields, onFieldChange } = props;
  console.log("fieldsa", fields);
  const handleChange = (fieldId: string, value: any, isValid: boolean) => {
    const updatedFields = fields.map((field: any) =>
      field.id === fieldId
        ? { ...field, fieldValue: value, isValid: isValid }
        : field
    );
    onFieldChange(updatedFields);
  };

  const handlePhoneChange = (
    fieldId: string,
    value: any,
    code: any,
    isValid: boolean
  ) => {
    const updatedFields = fields.map((field: any) =>
      field.id === fieldId
        ? { ...field, fieldValue: value, isValid: isValid, countryCode: code }
        : field
    );
    onFieldChange(updatedFields);
  };

  const renderField = (field: any) => {
    switch (field.fieldType) {
      case "text":
        return (
          <div className={styles.additionalFieldWrap}>
            <NewFormTextInput
              onChange={(e: any) =>
                handleChange(
                  field.id,
                  e.target.value,
                  field.required && e.target.value.trim() === "" ? false : true
                )
              }
              label={
                field.fieldName
                  ? field.fieldName.charAt(0).toUpperCase() +
                    field.fieldName.slice(1).toLowerCase()
                  : ""
              }
              require={field.required}
              value={field.fieldValue || ""}
              id="instituteName"
              variant="standard"
              fullWidth
              error={field.required && !field.isValid}
            />
          </div>
        );

      case "phone":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <CountryCodePhoneInput
              key={field.id}
              value={field.fieldValue || ""}
              onChange={(event: any, country: any) => {
                const isValid = isValidPhoneNumber(
                  `+${country.dialCode}${event.replace(country.dialCode, "")}`,
                  country.countryCode.toUpperCase()
                );

                handlePhoneChange(
                  field.id,
                  event,
                  country.dialCode,
                  field.required || (!isValid && event !== country.dialCode)
                    ? false
                    : true
                );
              }}
              error={field.isValid}
            />
          </div>
        );

      case "radio":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FormControl component="fieldset" key={field.id}>
              <RadioGroup
                value={field.fieldValue || ""}
                onChange={(e) =>
                  handleChange(
                    field.id,
                    e.target.value,
                    field.required && e.target.value.trim() === ""
                      ? false
                      : true
                  )
                }
              >
                {field?.options?.map((option: any) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={
                      <Radio
                        sx={{
                          strokeWidth: 1,
                          color: "#D0D5DD",
                        }}
                      />
                    }
                    label={
                      <div
                        style={{
                          fontSize: "13PX",
                          textTransform: "capitalize",
                        }}
                      >
                        {option?.name}
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "date":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} key={field.id}>
              <DesktopDatePicker
                sx={{
                  zIndex: "9999999 !important",
                  width: "100%",
                  fontSize: "14px",
                  "& .MuiOutlinedInput-input": {
                    fontSize: "14px",
                    color: "#142C44",
                    padding: "9px",
                  },
                }}
                format="DD/MM/YYYY"
                onChange={(date: any) => {
                  const timestamp = date ? dayjs(date).valueOf() : null;
                  handleChange(
                    field.id,
                    timestamp,
                    field.required && timestamp === 0 ? false : true
                  );
                }}
                value={field.fieldValue ? dayjs(field.fieldValue) : null}
              />
            </LocalizationProvider>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FormControl component="fieldset" key={field.id}>
              {field?.options?.map((option: any) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={field.fieldValue?.includes(option.id) || false}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...(field.fieldValue || []), option.id]
                          : field.fieldValue.filter(
                              (id: any) => id !== option.id
                            );
                        handleChange(
                          field.id,
                          newValue,
                          field.required && newValue?.length === 0
                            ? false
                            : true
                        );
                      }}
                      sx={{
                        strokeWidth: 1,
                        color: "#D0D5DD",
                      }}
                    />
                  }
                  label={
                    <div
                      style={{
                        fontSize: "13PX",
                        textTransform: "capitalize",
                      }}
                    >
                      {option?.name}
                    </div>
                  }
                />
              ))}
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "select":
        return (
          <div className={styles.additionalFieldWrap}>
            <FormControl key={field.id} fullWidth size="small">
              <div className={styles.additionalFieldLabel}>
                {field.fieldName}
                {field.required === true && (
                  <span style={{ color: "red", margin: "0 4PX" }}>*</span>
                )}
              </div>
              <Select
                value={field.fieldValue || ""}
                onChange={(e) =>
                  handleChange(
                    field.id,
                    e.target.value,
                    field.required && e.target.value.trim() === ""
                      ? false
                      : true
                  )
                }
                sx={{
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                  },
                }}
                error={!field.isValid}
              >
                {field?.options?.map((option: any) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      color: "#667085",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "file":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FieldFileDropZone field={field} onFieldChange={handleChange} />
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "profilePic":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.additionalFieldLabel}>
              {field.fieldName}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FieldFileDropZone
              field={field}
              onFieldChange={handleChange}
              crop={true}
            />
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <div>
      <div className={styles.subTitle}>Additional Details</div>
      <div>{fields?.map((field: any) => renderField(field))}</div>
    </div>
  );
}

export default AdditionalFields;
