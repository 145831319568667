import WelcomeWebForm from "../WelcomeWebForm";
import SharePopup from "../SharePopup/SharePopup";
import RenameFormPopup from "../RenameFormPopup/RenameFormPopup";
import DuplicateFormPopup from "../DuplicateFormPopup/DuplicateFormPopup";
import ArchiveFormPopup from "../ArchiveFormPopup/ArchiveFormPopup";
import React, { useEffect, useRef, useState } from "react";
import styles from "../Webform.module.css";
import {
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TextField,
  styled,
  Button,
  IconButton,
  Menu,
  Box,
  MenuItem,
  CircularProgress,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { GetAllWebforms } from "../../service/webform.request";
import { useNavigate } from "react-router";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import archive from "../Assets/archive.svg";
import clone from "../Assets/clone.svg";
import edit from "../Assets/edit.svg";
import rename from "../Assets/rename.svg";
import visibility from "../../../../../../assets/images/visibility.svg";
import {
  resetAllWebforms,
  resetCreateWebformSuccess,
  resetDeleteWebformSuccess,
  resetUpdateWebformSuccess,
} from "../../service/webformSlice";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../../../../../../components/CommonFunctions/CommonFunctions";
import ListPopover from "../../../../../../components/ListPopover/ListPopover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

type formResponseData = {
  instituteId: string;
  formId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

type webformsData = {
  instituteId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

function FormsTab(props: any) {
  const {
    CreateWebform,
    CreateWebformSuccess,
    CreateWebformId,
    getAllFormByInstituteData,
    GetAllWebformLoading,
    UpdateWebform,
    GetWebformForInstitute,
    getFormByInstituteData,
    setCopied,
    setValue,
    GetFormResponse,
    UpdateWebformSuccess,
    GetAllWebform,
    deleteWebformSuccess,
  } = props;
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasWebformsAccess = getSubDomainAccessStatus(
    "INBOUND",
    "WEBFORMS",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    userById?.rolePermission
  );

  const { UpdateWebformLoading } = useSelector((state: any) => state.webform);
  const teamMembers = useSelector((state: any) => state.staff.staffList);

  const [filterArray, setFilterArray] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [sharingFormData, setSharingFormData] = useState("");
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [renameFormData, setRenameFormData] = useState("");
  const [duplicateFormData, setDuplicateFormData] = useState("");
  const [archiveFormData, setArchiveFormData] = useState("");
  const [formType, setFormType] = useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentItem, setCurrentItem] = useState<any>(null); // State to keep track of the current item
  const open = Boolean(anchorEl);
  const formTypeFieldRef = useRef<HTMLInputElement | null>(null);
  const [formTypeAnchorEl, setFormTypeAnchorEl] = useState(null);
  const openFormType = Boolean(formTypeAnchorEl);
  const branchList = useSelector((state: any) => state.branch.branchList);
  const safeBranchList = branchList || [];

  const filteredBranchList = hasAllBranchAccess
    ? safeBranchList.map((d: any) => {
        return {
          name: d?.branchName,
          branchId: d?.id,
          value: "BRANCH_SPECIFIC",
        };
      })
    : safeBranchList
        .filter(
          (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
        )
        .map((d: any) => {
          return {
            name: d?.branchName,
            branchId: d?.id,
            value: "BRANCH_SPECIFIC",
          };
        });

  const safeFilteredBranchList = filteredBranchList || [];

  const typeArray = [
    { name: "All", branchId: 0, value: "ALL" },
    { name: "Universal", branchId: 1, value: "UNIVERSAL" },
    ...safeFilteredBranchList,
  ];

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  const openSharePopup = (event: any, data: any) => {
    if (data?.status === "PUBLISHED") {
      setSharingFormData(data);
      const userFromLocalStorage = authUser?.institute[0];
      GetWebformForInstitute(userFromLocalStorage?.instituteId, data?.id);

      setShowSharePopup(true);
    }
  };
  const closeSharePopup = (event: any) => {
    setSharingFormData("");
    setShowSharePopup(false);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("webformSharePopup");
    const queryString = queryParams.toString();
    const newUrl = `${window.location.pathname}${
      queryString ? `?${queryString}` : ""
    }`;
    window.history.pushState({}, "", newUrl);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("searchTerm", event.target.value);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleViewClick = (item: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("webformTab", "webformResponses");
    queryParams.set("webformId", item?.id);
    queryParams.set("webformType", item?.type);
    if (item?.type === "BRANCH_SPECIFIC") {
      queryParams.set("webformBranch", item?.branchId);
    }

    setValue(1);
    const userFromLocalStorage = authUser?.institute[0];

    if (userFromLocalStorage && item?.id) {
      let branchId: string;
      if (item?.type === "BRANCH_SPECIFIC") {
        branchId = item?.branchId;
      } else if (item?.type === "UNIVERSAL" && !hasAllBranchAccess) {
        branchId = authUser?.institute?.[0]?.branchId;
      } else {
        branchId = "";
      }
      const data: formResponseData = {
        instituteId: userFromLocalStorage?.instituteId,
        formId: item?.id,
        limit: 15,
        branchId: "",
        lastEvaluatedKey: "",
        lastEvaluatedSortKey: "",
      };
      GetFormResponse(data);
      GetWebformForInstitute(userFromLocalStorage?.instituteId, item?.id);
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleMenuClick =
    (item: any) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setCurrentItem(item);
      if (event.currentTarget.textContent === "Rename") {
        setShowRenamePopup(true);
      } else if (event.currentTarget.textContent === "Duplicate") {
        setShowDuplicatePopup(true);
      } else if (event.currentTarget.textContent === "Archive") {
        setShowArchivePopup(true);
      }
    };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.GSI1SK?.S
    );
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("webformType");
    const webformBranch = urlSearchParams.get("webformBranch");
    let branchId: string;
    if (webformType === "UNIVERSAL") {
      branchId = "ALL";
    } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else {
      branchId = "";
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: lastEvaluatedKey,
      lastEvaluatedSortKey: lastEvaluatedSortKey,
    };
    if (lastEvaluatedKey) {
      GetAllWebforms(data)
        .then((res: any) => {
          setHasMoreData(true);
          setFilterArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  const menuOptions = [
    {
      label: "Edit",
      icon: edit,
      onClick: (item: any) => {
        dispatch(resetAllWebforms());
        navigate(`/app/Inbound/Webforms/editwebform/${item.id}`);
      },
    },
    {
      label: "Rename",
      icon: rename,
      onClick: (item: any) => {
        setShowRenamePopup(!showRenamePopup);
        setRenameFormData(item);
      },
    },
    {
      label: "Duplicate",
      icon: clone,
      onClick: (item: any) => {
        setShowDuplicatePopup(true);
        setDuplicateFormData(item);
      },
    },
    {
      label: "Archive",
      icon: archive,
      onClick: (item: any) => {
        setShowArchivePopup(true);
        setArchiveFormData(item);
      },
    },
  ];

  const handleOpenFormTypePopover = (event: any) => {
    setFormTypeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseFormTypePopover = () => {
    setFormTypeAnchorEl(null);
  };

  const handleFormTypeSelect = (item: any) => {
    setFormType(item);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("webformType", item?.value);
    if (item?.value === "BRANCH_SPECIFIC") {
      queryParams.set("webformBranch", item?.branchId);
    } else {
      queryParams.delete("webformBranch");
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);

    const userFromLocalStorage = authUser?.institute[0];

    let branchId: string;
    if (item?.value === "UNIVERSAL") {
      branchId = "ALL";
    } else if (item?.value === "BRANCH_SPECIFIC" && item?.branchId) {
      branchId = item?.branchId;
    } else {
      branchId = "";
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };
    GetAllWebform(data);

    handleCloseFormTypePopover();
  };

  React.useEffect(() => {
    if (
      filterArray &&
      filterArray?.lastEvaluatedKeyReceived &&
      filterArray?.lastEvaluatedKeyReceived?.SK !== null &&
      searchTerm.length === 0
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [filterArray, searchTerm]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("webformType");
    const webformBranch = urlSearchParams.get("webformBranch");
    let branchId: string;
    if (webformType === "UNIVERSAL") {
      branchId = "ALL";
    } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else {
      branchId = "";
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };

    if (UpdateWebformSuccess !== false) {
      GetAllWebform(data);
      setShowArchivePopup(false);
      dispatch(resetUpdateWebformSuccess());
    }
  }, [UpdateWebformSuccess]);

  React.useEffect(() => {
    if (CreateWebformSuccess) {
      navigate(`./editwebform/${CreateWebformId?.id}`);
    }
    dispatch(resetCreateWebformSuccess());
  }, [CreateWebformSuccess, CreateWebformId]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];

    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("webformType");
    const webformBranch = urlSearchParams.get("webformBranch");
    let branchId: string;
    if (webformType === "UNIVERSAL") {
      branchId = "ALL";
    } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else {
      branchId = "";
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };

    if (deleteWebformSuccess === true) {
      GetAllWebform(data);
      dispatch(resetDeleteWebformSuccess());
    }
  }, [deleteWebformSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformType = urlSearchParams.get("webformType");
    const webformBranch = urlSearchParams.get("webformBranch");

    if (webformType === "UNIVERSAL") {
      const type = typeArray?.find((item: any) => item?.value === "UNIVERSAL");
      setFormType(type);
    } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      const type = typeArray?.find(
        (item: any) =>
          item?.value === "BRANCH_SPECIFIC" && item?.branchId === webformBranch
      );
      setFormType(type);
    } else {
      if (!hasAllBranchAccess) {
        const type = typeArray?.find(
          (item: any) =>
            item?.value === "BRANCH_SPECIFIC" &&
            item?.branchId === safeFilteredBranchList[0]?.branchId
        );
        setFormType(type);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("webformType", "BRANCH_SPECIFIC");
        queryParams.set("webformBranch", safeFilteredBranchList[0]?.branchId);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      } else {
        const type = typeArray?.find((item: any) => item?.value === "ALL");
        setFormType(type);
      }
    }
  }, [typeArray]);

  const formsArray = getAllFormByInstituteData?.returnData;

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const webformSharePopup = queryParams.get("webformSharePopup");

    if (formsArray?.length > 0 && webformSharePopup) {
      const formData = formsArray?.find(
        (item: any) => item.id === webformSharePopup
      );
      if (formData?.status === "PUBLISHED") {
        setSharingFormData(formData);
        setShowSharePopup(true);
      }
    }
  }, [formsArray]);

  React.useEffect(() => {
    setFilterArray(getAllFormByInstituteData);
  }, [getAllFormByInstituteData]);

  return (
    <div>
      <WelcomeWebForm
        CreateWebform={CreateWebform}
        CreateWebformSuccess={CreateWebformSuccess}
        CreateWebformId={CreateWebformId}
        hasWebformsAccess={hasWebformsAccess}
      />
      <div>
        <div className={styles.funnelHeadSplit}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.funnelHeadSplitTitle}
              style={{ fontSize: "16PX" }}
            >
              Form Type{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <TextField
                onClick={handleOpenFormTypePopover}
                value={formType ? formType?.name : ""}
                variant="outlined"
                size="small"
                placeholder="Select"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root input": {
                    textTransform: "capitalize",
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                  width: "160px",
                }}
                inputRef={formTypeFieldRef}
              />
              <ListPopover
                openState={openFormType}
                anchorState={formTypeAnchorEl}
                handleClosePopover={handleCloseFormTypePopover}
                popupList={
                  hasAllBranchAccess
                    ? typeArray
                    : typeArray.filter((d) => d?.value !== "ALL")
                }
                handleItemSelect={handleFormTypeSelect}
                listType={"FORMTYPE"}
              />
            </div>
          </div>
          <div className={styles.funnelHeadSplitSearch}>
            <TextField
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{
                style: {
                  background: "#ffffff",
                  padding: "8px 12px",
                  width: "300px",
                },
              }}
              type="search"
            />
          </div>
        </div>
        <>
          {getAllFormByInstituteData &&
          getAllFormByInstituteData?.returnData?.length === 0 &&
          filterArray &&
          filterArray?.returnData?.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              {GetAllWebformLoading ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Box>
              ) : (
                <Box
                  style={{
                    marginTop: "200px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#667085",
                      marginBottom: "22px",
                      fontFamily: "Poppins",
                      textAlign: "center",
                    }}
                  >
                    No Forms Created Yet.
                  </div>
                </Box>
              )}
            </div>
          ) : (
            <>
              {GetAllWebformLoading ? (
                <TableSkeleton />
              ) : (
                <>
                  {filterArray &&
                    filterArray?.returnData.length > 0 &&
                    getAllFormByInstituteData?.returnData?.length > 0 && (
                      <TableContainer
                        className={styles.webformTableContainer}
                        sx={{
                          minWidth: 700,
                          borderRadius: "8PX",
                          border: "1px solid  #EAECF0",
                        }}
                      >
                        <InfiniteScroll
                          dataLength={
                            filterArray?.returnData
                              ? filterArray?.returnData?.length
                              : getAllFormByInstituteData?.returnData?.length
                          }
                          next={handleNextPage}
                          hasMore={hasMoreData}
                          loader={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <PuffLoader color="#36d7b7" />
                            </Box>
                          }
                          height={"calc(100vh - 192px)"}
                        >
                          <Table
                            sx={{
                              minWidth: 700,
                            }}
                            aria-label="customized table"
                          >
                            <TableHead
                              className={styles.batchPopupHead}
                              sx={{
                                position: "sticky",
                                top: 0,
                                zIndex: 999,
                              }}
                            >
                              <TableRow>
                                <StyledTableCell
                                  className={styles.cellFormName}
                                >
                                  Form Name
                                </StyledTableCell>
                                <StyledTableCell
                                  className={styles.cellFormName}
                                >
                                  Type
                                </StyledTableCell>

                                <StyledTableCell
                                  className={styles.batchPopupHeadCell}
                                  align="left"
                                  sx={{ maxWidth: "370px" }}
                                >
                                  ID
                                </StyledTableCell>
                                <StyledTableCell
                                  className={styles.cellCreatedBy}
                                  align="left"
                                >
                                  Created by
                                </StyledTableCell>

                                <StyledTableCell
                                  className={styles.cellCreatedDate}
                                  align="left"
                                  style={{ width: "20%" }}
                                >
                                  Created Date
                                </StyledTableCell>
                                <StyledTableCell
                                  className={styles.batchPopupHeadCell}
                                  align="left"
                                  style={{ fontSize: "13px" }}
                                >
                                  Status
                                </StyledTableCell>
                                <StyledTableCell
                                  className={styles.batchPopupHeadCell}
                                  align="left"
                                >
                                  Responses
                                </StyledTableCell>
                                <StyledTableCell
                                  className={styles.batchPopupHeadCell}
                                  align="left"
                                  style={{ fontSize: "13px" }}
                                ></StyledTableCell>
                                <StyledTableCell
                                  className={styles.batchPopupHeadCell}
                                  align="left"
                                  style={{ fontSize: "13px" }}
                                ></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filterArray?.returnData &&
                                filterArray?.returnData
                                  ?.filter((item: any) => {
                                    if (searchTerm === "") {
                                      return true;
                                    }
                                    return (
                                      item?.formName
                                        ?.toLowerCase()
                                        ?.includes(searchTerm?.toLowerCase()) ||
                                      item?.formId
                                        ?.toLowerCase()
                                        ?.includes(searchTerm) ||
                                      item?.createdByName
                                        ?.toLowerCase()
                                        ?.includes(searchTerm)
                                    );
                                  })
                                  .map((item: any, index: any) => (
                                    <TableRow key={index}>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.formName}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.type
                                          ?.toLowerCase()
                                          ?.replace(/_/g, " ")}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.formID}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {getTeamMember(
                                          item?.createdById,
                                          teamMembers
                                        )?.firstName +
                                          " " +
                                          getTeamMember(
                                            item?.createdById,
                                            teamMembers
                                          )?.lastName}
                                      </StyledTableCell>

                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.creationDate
                                          ? getFormattedDate(item.creationDate)
                                          : ""}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.status?.toLowerCase()}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleViewClick(item)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border:
                                              "1px solid rgba(238, 238, 238, 0.93)",
                                            borderRadius: "4px",
                                            width: "fit-content",
                                          }}
                                        >
                                          <div
                                            style={{
                                              padding: "6px 8px",
                                              fontSize: "12px",
                                              color: "#667085",
                                              borderRight:
                                                "1px solid rgba(238, 238, 238, 0.93)",
                                              width: "44px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {item.responses}
                                          </div>{" "}
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "2px",
                                              padding: "6px 8px",
                                              fontSize: "11px",
                                              color: "#667085",
                                            }}
                                          >
                                            <img
                                              src={visibility}
                                              style={{ marginRight: "4PX" }}
                                            />{" "}
                                            View
                                          </div>
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        <Tooltip
                                          arrow
                                          title={
                                            (item.status === "DRAFT" ||
                                              item.status === "ARCHIVED" ||
                                              item.status === "DRAFT") &&
                                            hasWebformsAccess === false
                                              ? "Oops! No permission."
                                              : ""
                                          }
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                bgcolor: "#101828",
                                                color: "#fff",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "8px 12px",
                                                "& .MuiTooltip-arrow": {
                                                  color: "#101828",
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <span>
                                            {item.status === "ARCHIVED" ? (
                                              <Button
                                                variant={"outlined"}
                                                sx={{
                                                  textTransform: "capitalize",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                }}
                                                onClick={() => {
                                                  const updatedData = {
                                                    ...item,
                                                    status: "PUBLISHED",
                                                  };
                                                  UpdateWebform(updatedData);
                                                }}
                                                disabled={
                                                  (!showRenamePopup &&
                                                    !showArchivePopup &&
                                                    UpdateWebformLoading) ||
                                                  hasWebformsAccess === false
                                                }
                                              >
                                                {!showRenamePopup &&
                                                !showArchivePopup &&
                                                UpdateWebformLoading ? (
                                                  <CircularProgress
                                                    size={18}
                                                    color={"inherit"}
                                                  />
                                                ) : (
                                                  "REPUBLISH"
                                                )}
                                              </Button>
                                            ) : item.status === "DRAFT" ? (
                                              <Button
                                                variant={"outlined"}
                                                sx={{
                                                  textTransform: "capitalize",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                }}
                                                onClick={() => {
                                                  dispatch(resetAllWebforms());
                                                  navigate(
                                                    `/app/Inbound/Webforms/editwebform/${item.id}`
                                                  );
                                                }}
                                                disabled={
                                                  hasWebformsAccess === false
                                                }
                                              >
                                                Edit
                                              </Button>
                                            ) : (
                                              <Button
                                                variant={"contained"}
                                                sx={{
                                                  textTransform: "capitalize",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                }}
                                                onClick={(e) =>
                                                  openSharePopup(e, item)
                                                }
                                              >
                                                Share
                                              </Button>
                                            )}
                                          </span>
                                        </Tooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="left"
                                        sx={{
                                          color: "#667085",
                                          textTransform: "capitalize",
                                          padding: "0px",
                                        }}
                                      >
                                        <Tooltip
                                          arrow
                                          title={
                                            hasWebformsAccess === false
                                              ? "Oops! No permission."
                                              : ""
                                          }
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                bgcolor: "#101828",
                                                color: "#fff",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "8px 12px",
                                                "& .MuiTooltip-arrow": {
                                                  color: "#101828",
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <span>
                                            {item?.status !== "ARCHIVED" && (
                                              <IconButton
                                                aria-label="menu"
                                                id="long-button"
                                                aria-controls={
                                                  open ? "long-menu" : undefined
                                                }
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={handleMenuClick(item)}
                                                disabled={
                                                  hasWebformsAccess === false
                                                }
                                              >
                                                <MoreVertIcon
                                                  sx={{ color: "#BDBFC3" }}
                                                />
                                              </IconButton>
                                            )}
                                          </span>
                                        </Tooltip>

                                        <Menu
                                          anchorEl={anchorEl}
                                          open={open && currentItem === item}
                                          onClose={handleMenuClose}
                                          sx={{
                                            "& .MuiMenuItem-root": {
                                              color: "#667085",
                                              fontFamily: "Poppins",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              lineHeight: "normal",
                                            },
                                          }}
                                          PaperProps={{
                                            style: {
                                              maxHeight: "216px",
                                              width: "132px",
                                              boxShadow: "none",
                                              borderRadius: "4px",
                                              border: "1px solid #F2F2F2",
                                              background: "#FFF",
                                              // left: "700px",
                                            },
                                          }}
                                        >
                                          {menuOptions.map(
                                            (menuOption, index) => (
                                              <MenuItem
                                                key={index}
                                                onClick={() => {
                                                  menuOption.onClick(
                                                    currentItem
                                                  ); // Use currentItem
                                                  handleMenuClose();
                                                }}
                                                sx={{ gap: "8px" }}
                                              >
                                                <img
                                                  src={menuOption.icon}
                                                  alt=""
                                                />
                                                {menuOption.label}
                                              </MenuItem>
                                            )
                                          )}
                                        </Menu>
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </InfiniteScroll>
                      </TableContainer>
                    )}
                </>
              )}
            </>
          )}

          {showRenamePopup && (
            <RenameFormPopup
              showRenamePopup={showRenamePopup}
              renameFormData={renameFormData}
              onClose={() => setShowRenamePopup(false)}
              UpdateWebform={UpdateWebform}
              setShowRenamePopup={setShowRenamePopup}
            />
          )}

          {showDuplicatePopup && (
            <DuplicateFormPopup
              showDuplicatePopup={showDuplicatePopup}
              onClose={() => setShowDuplicatePopup(false)}
              CreateWebform={CreateWebform}
              duplicateFormData={duplicateFormData}
            />
          )}

          {showArchivePopup && (
            <ArchiveFormPopup
              showArchivePopup={showArchivePopup}
              onClose={() => setShowArchivePopup(false)}
              UpdateWebform={UpdateWebform}
              archiveFormData={archiveFormData}
            />
          )}
        </>
      </div>
      {showSharePopup && (
        <SharePopup
          showSharePopup={showSharePopup}
          sharingFormData={sharingFormData}
          onClose={closeSharePopup}
          GetWebformForInstitute={GetWebformForInstitute}
          getFormByInstituteData={getFormByInstituteData}
          UpdateWebform={UpdateWebform}
          setCopied={setCopied}
          setShowSharePopup={setShowSharePopup}
        />
      )}
    </div>
  );
}

export default FormsTab;
