import React from "react";
import styles from "./AutoDebit.module.css";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  SwitchProps,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import autoDebitImg from "../Assets/autoDebit.svg";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function AutoDebit(props: any) {
  const { BillingAutoDebit } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { autoDebitLoading } = useSelector((state: any) => state.billing);
  const billingAutoDebit = instituteFromRedux?.autoDebit;
  const [autoDebitStatus, setAutoDebitStatus] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      billingAutoDebit === "UNDER_PROCESSING" ||
      billingAutoDebit === "ENABLED"
    ) {
      setAutoDebitStatus(true);
    } else {
      setAutoDebitStatus(false);
    }
  }, [instituteFromRedux]);

  const handleChangeCheck = React.useCallback(async (event: any) => {
    event.preventDefault();

    const value = event.target.checked;
    setAutoDebitStatus(value);

    const data = {
      instituteId: authUser?.institute?.[0]?.instituteId,
    };
    if (value) {
      await BillingAutoDebit(data, "enabled");
    } else {
      await BillingAutoDebit(data, "disabled");
    }
  }, []);

  return (
    <div className={styles.autoDebitWrapper}>
      <div className={styles.autoDebitHead}>Auto Debit</div>
      <p className={styles.autoDebitDesc}>
        Auto-debit is a facility that Banks issuers offer to allow{" "}
        <span className={styles.autoDebitDescHighlighted}>
          scheduling recurring payments for auto-deduction
        </span>{" "}
        on a set date. Just like the subscription amount, it will automatically
        deduct an amount that you pre-set. Therefore, this facility lets{" "}
        <span className={styles.autoDebitDescHighlighted}>
          you transfer the stress of remembering multiple payment dates
        </span>{" "}
        every month.
      </p>
      <p className={styles.autoDebitDesc}>
        The auto-debit facility allows you{" "}
        <span className={styles.autoDebitDescHighlighted}>
          to set a limit above which no amount deduction can happen
        </span>{" "}
        from your cards even if you consented to the auto-debit. Thus, it
        restricts transfers beyond your limits{" "}
        <span className={styles.autoDebitDescHighlighted}>
          to prevent unauthorised debits
        </span>
        .
      </p>
      <div className={styles.autoDebitBox}>
        <img src={autoDebitImg} alt="autoDebit" />
        <p className={styles.autoDebitDesc}>
          You need to first{" "}
          <span className={styles.autoDebitDescHighlighted}>
            activate the auto debit facility
          </span>{" "}
          from the payment issuer’s app.
        </p>
        <p className={styles.autoDebitDesc}>
          Once you activate the facility,
          <span className={styles.autoDebitDescHighlighted}>
            the payment deduction will take place without OTPs
          </span>{" "}
          (One-Time Passwords) at its scheduled monthly date.
        </p>
        <p className={styles.autoDebitDesc}>
          However, make sure you have{" "}
          <span className={styles.autoDebitDescHighlighted}>
            enough balance in your bank account to avoid overdraft charges
          </span>{" "}
          of insufficient balance. This is necessary to make a successful
          transaction of automatic deduction. You can also{" "}
          <span className={styles.autoDebitDescHighlighted}>
            set the maximum amount to be debited
          </span>{" "}
          . Hence, beyond that limit, no auto-deduction will be done from your
          bank.
        </p>
        <div>
          <FormControl
            fullWidth
            variant="standard"
            className="formControl"
            sx={{
              width: "auto",
            }}
          >
            {billingAutoDebit === "UNDER_PROCESSING" ? (
              <Tooltip
                title={
                  hasBillingAccess === false
                    ? "Oops! No permission."
                    : "Auto debit mandate request sent your mail. Please complete the procedure."
                }
                placement="bottom"
                arrow
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <FormControlLabel
                  style={{
                    justifyContent: "flex-end",
                    marginLeft: 0,
                    // color: "#3F3E43",
                    marginTop: "0px",
                    gap: 20,
                  }}
                  control={
                    <IOSSwitch
                      sx={{
                        m: 1,
                      }}
                      checked={autoDebitStatus}
                      id="status"
                      onChange={(e) => {
                        handleChangeCheck(e);
                      }}
                      disabled={
                        hasBillingAccess === false
                          ? true
                          : false || billingAutoDebit === "UNDER_PROCESSING"
                      }
                    />
                  }
                  label={
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#142C44",
                      }}
                    >
                      Enable auto debit
                    </div>
                  }
                  labelPlacement="start"
                />
              </Tooltip>
            ) : (
              <>
                {autoDebitLoading ? (
                  <CircularProgress size={25} color={"primary"} />
                ) : (
                  <Tooltip
                    title={
                      hasBillingAccess === false ? "Oops! No permission." : ""
                    }
                    placement="bottom"
                    arrow
                    PopperProps={{ style: { zIndex: 9999999 } }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <FormControlLabel
                      style={{
                        justifyContent: "flex-end",
                        marginLeft: 0,
                        // color: "#3F3E43",
                        marginTop: "0px",
                        gap: 20,
                      }}
                      control={
                        <IOSSwitch
                          sx={{
                            m: 1,
                          }}
                          checked={autoDebitStatus}
                          id="status"
                          onChange={(e) => {
                            handleChangeCheck(e);
                          }}
                          disabled={
                            hasBillingAccess === false
                              ? true
                              : false || billingAutoDebit === "UNDER_PROCESSING"
                          }
                        />
                      }
                      label={
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#142C44",
                          }}
                        >
                          Enable auto debit
                        </div>
                      }
                      labelPlacement="start"
                    />
                  </Tooltip>
                )}
              </>
            )}
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default AutoDebit;
