import React from "react";
import styles from "./EmailSettings.module.css";
import { Box, Modal, Button, CircularProgress, Tooltip } from "@mui/material";
import gmail from "../../../../../../assets/images/gmail.svg";
import { useAuthUser } from "react-auth-kit";
import { useLocation, useNavigate } from "react-router-dom";
import otherEmail from "../../../../../../assets/images/other-mail-icon.svg";
import { resetInboundDeactivateSuccess } from "../../../service/deactivateInboundSlice";
import { useSelector } from "react-redux";
import {
  deactivateInbound,
  getMailConfigsList,
} from "../../../service/inboundSlice";
import { useDispatch } from "react-redux";
import emailIcon from "../../Assets/emailIcon.svg";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

const style = {
  position: "absolute" as "absolute",
  top: "57%",
  left: "58%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};

const EmailSettings = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { inboundDeactivateSuccess, inboundDeactivateLoading } = useSelector(
    (state: any) => state.settings
  );
  const { mailConfigsList } = useSelector((state: any) => state.inbound);
  const { userById } = useSelector((state: any) => state.userDetails);
  const hasEmailAccess = getSubDomainAccessStatus(
    "INBOUND",
    "EMAIL",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [selectedEmailProvider, setSelectedEmailProvider] =
    React.useState<any>("");
  const [openLogoutModal, setOpenLogoutModal] = React.useState<boolean>(false);
  const handleLogoutModalOpen = () => setOpenLogoutModal(true);
  const handleLogoutModalClose = () => {
    setOpenLogoutModal(false);
    setSelectedEmailProvider("");
  };
  const auth = useAuthUser();
  const authUser = auth();

  React.useEffect(() => {
    if (inboundDeactivateSuccess) {
      setSelectedEmailProvider("");
      setOpenLogoutModal(false);

      setTimeout(() => {
        navigate("/app/inbound/Email", { state: 1 });
      }, 500);
    }
  }, [inboundDeactivateSuccess]);

  const handleDeactivate = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const data = {
      instituteId: userFromLocalStorage && userFromLocalStorage?.instituteId,

      emailProvider: selectedEmailProvider,
    };
    if (userFromLocalStorage && userFromLocalStorage?.instituteId) {
      dispatch<any>(deactivateInbound(data));
    }
  };

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      dispatch<any>(getMailConfigsList(userFromLocalStorage?.instituteId));
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "55px 30px 20px 30px",
        height: "100%",
      }}
    >
      {/* location?.state !== null && location?.state?.length ? */}
      {mailConfigsList?.length > 0 ? (
        <>
          <div
            className={styles.emailSettingsTitle}
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            Integration
          </div>
          {mailConfigsList?.map((item: any) => {
            return (
              <Box
                style={{
                  border: "1px solid #E3E3E3",
                  width: "50%",
                  padding: "20px",
                  boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                  borderRadius: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  {/* <img
              src={
                location?.state[0]?.emailProvider === "IMAP"
                  ? otherEmail
                  : gmail
              }
              alt="gmail"
            /> */}
                  <img
                    src={item?.emailProvider === "IMAP" ? otherEmail : gmail}
                    alt="gmail"
                  />
                  {/* <div>{location?.state[0]?.emailProvider}</div> */}
                  <div>{item?.emailProvider}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      color: "#667085",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    {/* <div>Status: {location?.state[0]?.connectionStatus}</div>
                    <div>Email: {location?.state[0]?.emailId}</div> */}
                    <div>Status: {item?.connectionStatus}</div>
                    <div>Email: {item?.emailId}</div>
                  </div>
                </div>
                <Tooltip
                  arrow
                  title={hasEmailAccess === false ? "Oops! No permission." : ""}
                  sx={{ width: "100%" }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <button
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#E53D3D",
                        textTransform: "capitalize",
                        border: "none",
                        background: "transparent",
                        padding: "0",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                      onClick={() => {
                        handleLogoutModalOpen();
                        setSelectedEmailProvider(item?.emailProvider);
                      }}
                      disabled={!hasEmailAccess}
                    >
                      Remove
                    </button>
                  </span>
                </Tooltip>
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => {
                navigate("/app/inbound/Email");
              }}
              style={{
                cursor: "pointer",
                width: "387px",
                borderRadius: "6PX",
                border: " 1px solid #E3E3E3",
                boxShadow: "0px 20px 60px 0px rgba(241, 244, 248, 0.50)",
                padding: "24px 30px",
                textAlign: "center",
              }}
            >
              <img src={emailIcon} alt="Import" />
              <div
                style={{
                  fontSize: "15PX",
                  fontWeight: "600",
                  color: "#3F3E43",
                  marginBottom: "4px",
                  marginTop: "16px",
                }}
              >
                Email Integration
              </div>
              <div
                style={{
                  fontSize: "14PX",
                  color: "#667085",
                }}
              >
                Say goodbye to the hassle of managing emails separately and
                embrace the power of seamless email integration within nurture.
              </div>
            </div>
          </div>
          {/* <Box
            style={{
              border: "1px solid #E3E3E3",
              width: "50%",
              padding: "30px",
              boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
              borderRadius: "6px",
            }}
          >
            <div className={styles.noEmailsBox}>
              Currently you don't have any emails integrated.
              <br />
              Please click on below button to navigate to Email Integration
            </div>
            <div
              className={styles.editFormPopupBtnsSplit}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={{
                  width: "50%",
                  boxShadow: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate("/app/inbound/Email");
                }}
              >
                Continue
              </Button>
            </div>
          </Box> */}
        </>
      )}
      <Modal
        open={openLogoutModal}
        onClose={handleLogoutModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.editFormPopupTitle}>Remove Gmail </div>
            <div className={styles.editFormPopupContent}>
              Are you sure you want to remove this channel? All the existing
              mails related to this channel will be removed.
            </div>
            <div className={styles.editFormPopupBtnsSplit}>
              <button
                className={styles.editFormPopupCancelBtn}
                onClick={handleLogoutModalClose}
              >
                cancel
              </button>
              <button
                className={styles.editFormPopupRemoveBtn}
                onClick={handleDeactivate}
                disabled={inboundDeactivateLoading}
              >
                {inboundDeactivateLoading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  "Remove"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default EmailSettings;
