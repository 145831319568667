import React, { useState } from "react";
import styles from "./CustomizedField.module.css";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import deleteImage from "./Images/delete.svg";
import CancelBtn from "../../../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../../../components/Buttons/SaveBtn";
import EditIconBtn from "../../../../../../../../components/Buttons/EditIconBtn";
import DeleteBtn from "../../../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../../../components/CommonPopups/DeletePopup";

function CustomizedDateField(props: any) {
  const {
    field,
    onDelete,
    onFieldChange,
    isDateFieldFocused,
    setIsDateFieldFocused,
    leadsFromResponseChecked,
    edit = false,
    deleteAction = false,
    mandatory = false,
    isNameUniqueCheck,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteConfirmed = () => {
    setShowDeletePopup(false);
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    setShowDeletePopup(false);
  };

  const handleSaveClick = (e: any) => {
    onFieldChange({
      ...field,
      fieldName: question,
    });

    setIsEditing(false);
  };

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const capitalizeSentences = (text: string) => {
    let lowercasedText = text.toLowerCase();

    let capitalizedText = lowercasedText.replace(
      /(^\s*\w|[\.\!\?]\s*\w)/g,
      function (match) {
        return match.toUpperCase();
      }
    );

    return capitalizedText;
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  React.useEffect(() => {
    setQuestion(field?.fieldName);
  }, [field]);

  return (
    <div className={styles.customizedFieldWrapper}>
      <div className={styles.customizedFieldTop}>
        <div className={styles.label}>
          {capitalizeSentences(field.fieldName)}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </div>
        {!isEditing && (
          <div className={styles.customizedFieldTopRight}>
            {edit && <EditIconBtn onClick={handleEditClick} />}
            {deleteAction && (
              <DeleteBtn
                icon={deleteImage}
                onClick={() => setShowDeletePopup(!showDeletePopup)}
              />
            )}
            {showDeletePopup && (
              <DeletePopup
                open={showDeletePopup}
                onClose={handleDeleteCancelled}
                noBtnClick={handleDeleteCancelled}
                yesBtnClick={handleDeleteConfirmed}
                title={"Are you sure you want to delete this question?"}
              />
            )}
          </div>
        )}
      </div>
      <TextField
        placeholder={isEditing ? "Write a question" : "--/--/----"}
        value={isEditing ? question : ""}
        fullWidth
        variant="outlined"
        disabled={!isEditing}
        onChange={handleQuestionChange}
        size="small"
        inputProps={{
          style: {
            padding: "8px 12px",
            backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
            borderRadius: "4px",
          },
        }}
        draggable={isDateFieldFocused === false}
        onFocus={() => {
          setIsDateFieldFocused(true);
        }}
        onBlur={() => {
          setIsDateFieldFocused(false);
        }}
        error={isEditing && !isNameUniqueCheck(question, field?.index)}
        helperText={
          isEditing && !isNameUniqueCheck(question, field?.index)
            ? "Field label already exist"
            : ""
        }
      />
      {mandatory && (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={handleMandatoryChange}
              checked={field?.required}
              sx={{
                strokeWidth: 1,
                color: "#D0D5DD",
              }}
            />
          }
          label="Mandatory"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "13px",
              color: "#667085",
              lineHeight: "20px",
            },
          }}
        />
      )}

      {isEditing && (
        <div className={styles.btnsWrap}>
          <CancelBtn onClick={handleCancelClick} />
          <SaveBtn
            onClick={handleSaveClick}
            disabled={
              !question.trim() || !isNameUniqueCheck(question, field?.index)
            }
          />
        </div>
      )}
    </div>
  );
}
export default CustomizedDateField;
